import {Dialog} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import "../semaConnectChargeFee.scss";
import {manageApplyPricingPlan} from "services/pricingPlanService";
import {ACCESS_TO_STATIONS} from "../pricingPlanHelpers";
import {useDispatch} from "react-redux";
import {showErrorMessages} from "redux/actions/errorMessages";
import Access from "./access";
import Pricing from "./pricing";
import {ApplyPricingList} from "../../../../../common/interfaces/pricingPlan";
import { cloneDeep } from "lodash";

export const ApplyPricing = (props: any) => {
    const {organizationName, hostId, locationId, applyPricingOpen, setApplyPricingOpen, tariffForDropDown, appliedPricingDetails} = props;
    let initialValue: ApplyPricingList = {
        accessToStations: "",
        publicPricing: "",
        memberPricing: "",
    };
    const ORG_NAME = organizationName;
    const dispatch = useDispatch();
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState<any>();
    const [applyPricingSecondPopup, setApplyPricingSecondPopup] = useState(false);
    const [selectedAccessDescription, setAccessDescription] = useState<any>("");
    const [selectedCustomPricing, setCustomPricing] = useState<any>(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [selectedSave, setSelectedSave] = useState(false);
    const [accessToStation, setAccessToStation] = useState<any>({});
    const [displayValue, setDisplayValue] = useState(false);
    const [disableAccessSaveButton, setDisableAccessSaveButton] = useState(true);
    const [applyPricingDetails, setApplyPricingDetails] = useState(initialValue);
    const [disablePricingSaveButton, setDisablePricingSaveButton] = useState(true);


    useEffect(() => {
        if (hostId) {
            setAccessToStation(ACCESS_TO_STATIONS);
            if (appliedPricingDetails) {
                setDisableAccessSaveButton(false);
                setDisablePricingSaveButton(false);
                setSelectedSave(appliedPricingDetails?.stations?.access_type_id === ACCESS_TO_STATIONS[2]['id']);
                setApplyPricingDetails(
                    cloneDeep({
                        accessToStations: appliedPricingDetails?.stations?.access_type_id,
                        publicPricing: appliedPricingDetails?.publicPricing?.id,
                        memberPricing: appliedPricingDetails?.memberPricing?.id
                    }));
            }
        }
    }, [props]);


    const handleChangeCancel = () => {
        resetFormData();
        setDisablePricingSaveButton(true);
        setDisableAccessSaveButton(true);
        setApplyPricingSecondPopup(false);
        props.applyPricingClose();
    };

    const resetFormData = () => {
        setSelectedSave(false);
        setAccessDescription(" ");
        setApplyPricingDetails(initialValue);
        simpleValidator.current.hideMessages();
        forceUpdate(0);
    };

    const applyPricingFirstPopupClose = () => {
        if (!applyPricingDetails?.accessToStations) {
            props.applyPricingClose();
        }
    };

    const applyPricingSecondPopupClose = () => {
        if (!applyPricingDetails?.accessToStations) {
            setApplyPricingSecondPopup(false);
        }
    };


    const navigateBackToFirstStep = () => {
        setDisableAccessSaveButton(false);
        setApplyPricingOpen(true);
        setApplyPricingSecondPopup(false);
    };


    const navigateToApplyPricingSecondStepSave = () => {
        if (simpleValidator.current.fields[t("pricing.accesstostation")]) {
            setDisplayValue(true);
            submitApplyPricing();
        } else {
            showErrors();
        }
    };

    const setSecondPopupProperties = () => {
        hideErrors();
        if (!applyPricingDetails?.accessToStations) {
            props.applyPricingClose();
        }
        if ((applyPricingDetails?.memberPricing) || (applyPricingDetails?.publicPricing && applyPricingDetails?.memberPricing)) {
            setDisablePricingSaveButton(false);
        } else {
            setDisablePricingSaveButton(true);
        }
        setApplyPricingSecondPopup(true);
    };

    const navigateToApplyPricingSecondStepNext = () => {
        if (simpleValidator.current.fields[t("pricing.accesstostation")]) {
            setSecondPopupProperties();
        } else {
            showErrors();
        }
    };

    const navigateToApplyPricingSecondStepNextSave = () => {
        if ((simpleValidator.current.fields[t("pricing.privateaccessplan")] &&
            simpleValidator.current.fields[t("pricing.publicpricingplan")]) ||
            simpleValidator.current.fields[t("pricing.privateaccessplan")]
        ) {
            hideErrors();
            submitApplyPricing();
        } else {
            showErrors();
        }
    };

    const showErrors = () => {
        simpleValidator.current.showMessages();
        forceUpdate(1);
    };
    const hideErrors = () => {
        simpleValidator.current.hideMessages();
        forceUpdate(0);
    };

    const handleChange = (event: any) => {
        setApplyPricingDetails({
            ...applyPricingDetails,
            ["publicPricing"]: "",
            ["memberPricing"]: "",
            ["accessToStations"]: event.target.value,
        });
        setCustomPricing(false);
        setSelectedSave(event.target.value === ACCESS_TO_STATIONS[2]['id']);
        setDisableAccessSaveButton(false);
        functionToSetDescription(event.target.value);
    };

    const functionToSetDescription = (value: any) => {
        switch (value) {
            case 0:
                setAccessDescription(t("pricing.accessnone"));
                break;
            case 1:
                setAccessDescription(t("pricing.accessmemeberonly", {ORG_NAME}));
                break;
            case 2:
                setAccessDescription(t("pricing.accessmemberpublic"));
                break;
            case 3:
                setAccessDescription(t("pricing.accessopenaccess"));
                break;
            default:
                setAccessDescription("");
        }
    };

    const handleChangePlanMember = (event: any) => {
        setApplyPricingDetails({...applyPricingDetails, ["memberPricing"]: event.target.value,});
        if (applyPricingDetails?.accessToStations === 1 || (applyPricingDetails?.accessToStations === 2 && applyPricingDetails?.publicPricing)) {
            setDisablePricingSaveButton(false);
        }
    };

    const handleChangePlanPublic = (event: any) => {
        setApplyPricingDetails({...applyPricingDetails, ["publicPricing"]: event.target.value,});
        if (applyPricingDetails?.accessToStations === 1 || (applyPricingDetails?.accessToStations === 2 && applyPricingDetails.memberPricing)) {
            setDisablePricingSaveButton(false);
        }
    };

    const submitApplyPricing = () => {
        setLoadingPage(true);
        let body = {
            location_id: locationId,
            accessToStations: applyPricingDetails?.accessToStations,
            publicPricing: applyPricingDetails?.publicPricing ? applyPricingDetails?.publicPricing : '',
            memberPricing: applyPricingDetails?.memberPricing ? applyPricingDetails?.memberPricing : '',
        };
        let qParam = "";
        manageApplyPricingPlan(body, qParam).then(
            (res) => {
                if (res.status) {
                    setLoadingPage(false);
                    handleChangeCancel();
                    props.applyPricingClose(true);
                } else {
                    setLoadingPage(false);
                    dispatch(showErrorMessages({error: true, message: res.message, type: "error", operation: "",}));
                }
            }
        );
    };

    const {t} = useTranslation();

    return (
        <>
            {/* first Dialog Apply Pricing Start */}
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info md dialog_container_center"
                    {...props}
                    open={applyPricingOpen}
                    onClose={applyPricingFirstPopupClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <Access
                        selectedEdit={!!applyPricingDetails}
                        applyPricingDetails={applyPricingDetails}
                        setApplyPricingDetails={setApplyPricingDetails}
                        accessToStation={accessToStation}
                        selectedAccessDescription={selectedAccessDescription}
                        setApplyPricingOpen={setApplyPricingOpen}
                        handleChange={handleChange}
                        handlechangeCancel={handleChangeCancel}
                        selectedSave={selectedSave}
                        navigateToApplyPricingSecondStepSave={navigateToApplyPricingSecondStepSave}
                        navigateToApplyPricingSecondStepNext={navigateToApplyPricingSecondStepNext}
                        loadingPage={loadingPage}
                        simpleValidator={simpleValidator}
                        disableAccessSaveButton={disableAccessSaveButton}
                        setDisableAccessSaveButton={setDisableAccessSaveButton}
                        displayValue={displayValue}
                    />
                </Dialog>
            </div>
            {/* first Dialog Apply Pricing End */}

            {/* Second Dialog Apply Pricing Start */}
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info lg dialog_container_center"
                    open={applyPricingSecondPopup}
                    onClose={applyPricingSecondPopupClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <Pricing
                        selectedEdit={!!appliedPricingDetails}
                        applyPricingDetails={applyPricingDetails}
                        setApplyPricingDetails={setApplyPricingDetails}
                        tariffForDropDown={tariffForDropDown}
                        selectedCustomPricing={selectedCustomPricing}
                        handlechangeCancel={handleChangeCancel}
                        navigateBacktoFirstStep={navigateBackToFirstStep}
                        navigateToApplyPricingSecondStepNextSave={navigateToApplyPricingSecondStepNextSave}
                        simpleValidator={simpleValidator}
                        handleChangePlanMember={handleChangePlanMember}
                        handleChangePlanPublic={handleChangePlanPublic}
                        loadingPage={loadingPage}
                        disablePricingSaveButton={disablePricingSaveButton}
                        setDisablePricingSaveButton={setDisablePricingSaveButton}
                    />
                </Dialog>
            </div>
            {/* Second Dialog Apply Pricing End */}
        </>
    );
};

export default ApplyPricing;
