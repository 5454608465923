import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import Images from "assets/images";
import { Button, CardContent, Card, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DragDragImages } from "common/interfaces/dragdropimages";
import { bytesToSize, defaultVariable } from "common";

const DragDropImagesSingle = (props: DragDragImages) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<any>([]);
  const bytes = props.fileSize ? props.fileSize : defaultVariable.DELEGATE_FILESIZE;
  const fileMessage = props.fileMessage ? props.fileMessage : t("dragimage.filesizetype");
  const imageType = ["jpeg", "png"];
  const SIZE = bytesToSize(bytes, t);
  const [fileErr, setfileError] = useState<any>();

  useEffect(() => {
    setImages([])
      if(props?.receivedImagePath?.imageReference) {
          setImages([{"id": props?.receivedImagePath?.id, "imageReference": props?.receivedImagePath?.imageReference}])
      }
  }, [props?.receivedImagePath?.imageReference])

  const onChange = (imageList: any) => {
    for (let i = 0; i < imageList.length; i++) {
      imageList[i].id = "id-" + i;
      imageList[i].order = i;
    }
    props.selectedImagesDetails(imageList);

    setImages(imageList);
  };


  const onError = (errors: any, files: any) => {
    if (errors.maxFileSize) {
      setfileError({
        name: files[0].file.name,
        size: bytesToSize(files[0].file.size, t),
      });
    }
  };

  return (
    <>
      <div data-testid="dragDropImagesSingle_dialog">
        <ImageUploading
          value={images}
          onChange={onChange}
          maxFileSize={bytes}
          acceptType={imageType}
          dataURLKey="imageReference"
          onError={onError}
        >
          {({
            imageList,
            onImageUpload,
            isDragging,
            dragProps,
            errors,
          }) => (
            // write your building UI
            <>
              <Card
                style={{
                  border: isDragging ? "1px dashed #000" : "1px dashed #25556E",
                }}
                onClick={onImageUpload}
                {...dragProps}
              >
                <CardContent>
                  {errors && (
                    <div>
                      {errors?.maxNumber && (
                        <div className="error">
                          {" "}
                          <span> {t("dragimage.maxphotos")}</span>{" "}
                        </div>
                      )}
                      {errors?.acceptType && (
                        <div className="error">
                          {" "}
                          <span> {t("dragimage.filetype")}</span>{" "}
                        </div>
                      )}
                      {errors?.maxFileSize && (
                        <div className="error">
                          {" "}
                          <span>
                            {" "}
                            {t("dragimage.file")} "{fileErr?.name}" (
                            {fileErr?.size}) {" "} {t("dragimage.filesize", {SIZE})}
                          </span>{" "}
                        </div>
                      )}
                      {errors?.resolution && (
                        <div className="error">
                          {" "}
                          <span>{t("dragimage.fileresolution")}</span>{" "}
                        </div>
                      )}
                    </div>
                  )}
                  {onError}

                  <div className="dragdrop">
                    <img src={Images.up_image} alt="upimg" className="up-icon" />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        {t("dragimage.dragdroporclick")}{" "}
                        <cite>{fileMessage}</cite>
                      </Button>
                    </label>
                  </div>
                </CardContent>
              </Card>

              <Grid container direction="row" spacing={3} alignItems="center">
                {imageList
                  .sort((a: any, b: any) => a.order - b.order)
                  .map((image, index) => (
                    <Grid item key={image.id}>
                      <div className="items">
                        <img
                          id={image.id}
                          src={image.imageReference}
                          alt="del"
                          className="main-image"
                        />
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </ImageUploading>
      </div>
    </>
  );
};

export default DragDropImagesSingle;
