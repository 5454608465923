export const claims = {
    FORBIDDEN_ACCESS: 'forbid:read',
    PRICING_READ: 'pricing:read',
    PRICING_UPDATE: 'pricing:update',
    PRICING_CREATE: 'pricing:create',
    PRICING_REMOVE: 'pricing:remove',
    DRIVER_READ: 'driver:read',
    MEMBERSHIP_UPDATE : 'membership:update',
    MEMBERSHIP_READ : 'membership:read',
    LCD_DISPLAY_READ: 'lcd:display:read',
    LCD_DISPLAY_CREATE: 'lcd:display:create',
    LCD_DISPLAY_UPDATE : 'lcd:display:update',
    LCD_DISPLAY_REMOVE : 'lcd:display:remove',
    FLEET_CREATE : 'fleet:create',
    FLEET_READ : 'fleet:read',
    FLEET_UPDATE : 'fleet:update',
    FLEET_REMOVE : 'fleet:remove',
    VEHICLE_CREATE : 'vehicle:create',
    VEHICLE_READ : 'vehicle:read',
    VEHICLE_UPDATE : 'vehicle:update',
    VEHICLE_REMOVE : 'vehicle:remove',
    WAITLIST_UPDATE : 'waitlist:update',
    WAITLIST_VIEW : 'waitlist:view',
}