import React from 'react';
import { NavLink } from 'react-router-dom';
import './fleetDashboard.scss';
import { useTranslation } from "react-i18next";
import { 
    Card, 
    CardContent, 
    List, 
    ListItem, 
    Grid
} from '@material-ui/core';
import Images from 'assets/images';
import {commonRoutes, defaultVariable, keyCloakConfig, rolePermissionCheck, supportConfigData}  from 'common'
import { toLower, startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { CheckAccess } from 'common/abilityHelpers';
import { claims } from 'common/claims';

import EnvironmentInfo from './environmentInfo';

const FleetDashboard = (props:any) => {
    
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const { t } = useTranslation();
    const emailTagInfo = "mailto:" +  supportConfigData.email;

    const manageUsersEnabled = useSelector((state: any) => {
        return state?.sidebarItems?.manageUsersEnabled ? state?.sidebarItems?.manageUsersEnabled : false;
    });

    const linkStyle = { textDecoration: 'none' };

    const delegateManageUserPemissionCheck = () => {
        let response = true;
    
        if (isDelegate) {
            response = manageUsersEnabled ? true : false;
        }
    
        return response;
    };
    

    return (
        <>
            <main data-testid="fleet_dashboard">
                <div className="welcome-back">
                    <div className="user-name"><h1>{t("common.welcomeback")}, {startCase(toLower(keyCloakConfig?.tokenParsed?.name))}</h1></div>
                    <Grid container spacing={2} alignItems="stretch" className="listing-item">
                        <Grid item md={CheckAccess([claims.DRIVER_READ]) && delegateManageUserPemissionCheck() ? 4 : 6} xs={12}>
                            <Card>
                                <CardContent>
                                    <img className="" src={Images.ic_evselocation_icon} alt="evselocation_icon" />
                                    <h2>{t("common.evse_locations")}</h2>
                                    <div className="dashboard-text"><p>{t("dashboard.evselocationtext")}</p></div>
                                    <div className="bottom-btn">
                                        <NavLink to={commonRoutes.depotListRoute} style={linkStyle}>
                                            <button 
                                                className="cursor_pointer create_fleet_btn" 
                                            >
                                                {t("common.evse_locations")}
                                            </button>
                                        </NavLink>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        {   
                            (CheckAccess([claims.DRIVER_READ]) && delegateManageUserPemissionCheck()) &&
                            <Grid item md={4} xs={12}>
                                <Card>
                                    <CardContent>
                                        <img className="pt_5" src={Images.ic_usermange} alt="schedule_icon" />
                                        <h2>{t("hostsetting.usergroups")}</h2>
                                        <div className="dashboard-text"><p>{t("dashboard.assignuseraccessandactivity")}</p></div>
                                        <div className="bottom-btn">
                                            <NavLink to={commonRoutes.manageUserRoute} style={linkStyle}>
                                                <button 
                                                    className="cursor_pointer create_fleet_btn" 
                                                >
                                                    {t("common.manageusers")}
                                                </button>
                                            </NavLink>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                         <Grid item md={CheckAccess([claims.DRIVER_READ]) && delegateManageUserPemissionCheck() ? 4 : 6} xs={12}>
                            <Card>
                                <CardContent>
                                    <img className="pt_5" src={Images.ic_training} alt="evselocation_icon" />
                                    <h2>{t("dashboard.training")}</h2>
                                    <div className="dashboard-text"><p>{t("dashboard.learnfeaturesplatform")}</p></div>
                                    <div className="bottom-btn">
                                        <NavLink to={commonRoutes.trainingRoute} style={linkStyle}>
                                            <button 
                                                className="cursor_pointer create_fleet_btn" 
                                            >
                                                {t("dashboard.training")}
                                            </button>
                                        </NavLink>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {
                        !isDelegate &&
                        <EnvironmentInfo />
                    }
                    <Grid container spacing={2} className="bottom-section">
                        <Grid className="pt_0" item md={4} sm={5} xs={12}>
                            <Card>
                                <CardContent>
                                    <h2>{t("common.support")}</h2>
                                    <div className="support-text">
                                        <div className="icon-box">
                                            <img className="" src={Images.ic_phone_icon} alt="phone_icon" />
                                            <img className="" src={Images.ic_mail_icon} alt="mail_icon" />
                                        </div>
                                        <List className="support-list">
                                            <ListItem>{supportConfigData.phone}</ListItem>
                                            <ListItem><a href={emailTagInfo}>{supportConfigData.email}</a></ListItem>
                                        </List>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className="pt_0" item md={8} sm={7} xs={12}>
                            <Card>
                                <CardContent>
                                    <h2>{t("common.feedback")}</h2>
                                    <div className="text feedback_text">{t("dashboard.feedback")}</div>
                                    <div className="feedback">
                                        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=nJ82RjRgWkORY2wecbJN1tnFj4gaQetFhG4HWIp5Q6NUMUlYNDVKOTBDQk4yUzBPSDM1NEIxQTMxNS4u" target="_blank">
                                            {t("common.provideusyourfeedback")}
                                        </a>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </main>
        </>
    )
}

export default FleetDashboard;
