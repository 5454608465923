const initialState  = {
    type:"",
    error: false,
    message: "",
    operation:"",
}


export const errorMessagesReducer = (state = initialState, action: any) => {

    if(action.type === 'SHOW_ERROR_MESSAGE') {
        state = action.payload;
        return state;
    } else {
        return state;
    }

}