import React, { useState, useEffect } from "react";
import { Grid, Button, Card, CardContent, IconButton, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Dialog, CircularProgress } from "@material-ui/core";
import Images from "assets/images";
import { useTranslation } from "react-i18next";
import { toUpper } from "lodash";
import { ApiEndPoint, getMethod, postMethod, putMethod } from "services";
import { setAddressFormat, depotGoogleMap, CheckAccess, claims } from "common";
import ZoneFormDetails from "./zoneFormDetails";
import AssignedStations from "./assignedStations";
import CommonGoogleMap from "components/commonGoogleMap";
import ViewAssignedStations from "./viewAssignedStation";
import PolygonMapDetails from "./polygonMapDetails";
import UpdateZoneStations from "./updateZoneStation";

const LocationsDetails = (props: any) => {

  const { t } = useTranslation();

  const zoneStationInfo = {
    name: "",
    notes: "",
    region: " ",
    stationIdsArr: []
  }
  const defaultFormType = 'New';
  const editFormType = 'Edit';

  const [openZone, setOpenZone] = useState<any>(false);
  const [openZoneStation, setOpenZoneStation] = useState<any>(false);
  const [coordinates, setCoordinates] = useState<any>();
  const [openAssignstation, setAssignStationOpen] = useState<any>(false);
  const [openCaptureZoneMap, setCaptureZoneMapOpen] = useState<any>(false);
  const [finalStation, setFinalStation] = useState<any>(false);
  const [zoneStationsPost, setZoneStationsPost] = useState<any>(zoneStationInfo);
  const locationId = props?.locationId;
  const [locationInformation, setLocationInformation] = useState<any>({});
  const [locationZonesData, setLocationZonesData] = useState<any>({});
  const [formType, setFormType] = useState<any>(defaultFormType);
  const [currentZoneId, setCurrentZoneId] = useState<any>();
  const [locationZonesDataZone, setLocationZonesDataZone] = useState<any>();
  const [depotSpinnerOff, setDepotSpinnerOff] = useState<boolean>(true);
  
  const getLocationInformation = async () => {
    await getMethod(ApiEndPoint.locations, '/' + locationId)
      .then((res) => {
        setLocationInformation(res?.data);
        setDepotSpinnerOff(false);
      })
      .catch((err) => {
        setDepotSpinnerOff(false);
        console.error("Error", err);
      });
  };

  const getLocationZones = async () => {
    await getMethod(ApiEndPoint.locations, '/' + locationId + '/zones')
      .then((res) => {
        setLocationZonesData(res?.data);
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const createZoneMethod = (formVal: string) => {
    setFormType(formVal);
    setOpenZoneStation(true);
    setAssignStationOpen(false)
  };

  const editZoneMethod = (value: any) => {
    setFormType(editFormType);
    setCurrentZoneId(value?.id)
    setZoneStationsPost({ ...zoneStationsPost, 'name': value?.name, 'notes': value?.notes, 'region': value?.region })
    setOpenZoneStation(true);
    setLocationZonesDataZone(JSON.parse(value.region))
  };

  const assignedStationMethod = (data: any) => {
    const { name, notes } = data
    setOpenZoneStation(false);
    setCaptureZoneMapOpen(false)
    setAssignStationOpen(true)
    setZoneStationsPost({ ...zoneStationsPost, 'name': name, 'notes': notes })
  };

  const polygonMapMethod = (stationData: any) => {
    setOpenZone(false);
    setCaptureZoneMapOpen(true)
    setAssignStationOpen(false);
    setZoneStationsPost({ ...zoneStationsPost, 'stationIdsArr': stationData })
  };

  const viewAssignedStationMethod = (coordinates: any, stationData: any) => {
    setZoneStationsPost({ ...zoneStationsPost, 'stationIdsArr': stationData })
    setCoordinates(coordinates);
    setLocationZonesDataZone(coordinates)
    setAssignStationOpen(false);
    setCaptureZoneMapOpen(false);
    setFinalStation(false);
    setOpenZone(true);
  };

  const viewFinalStation = (data: any) => {
    setCoordinates(data);
    setOpenZone(false);
    setFinalStation(true);
  };


  const zoneClose = () => {
    setZoneStationsPost(zoneStationInfo)
    setAssignStationOpen(false)
    setOpenZoneStation(false);
    setCaptureZoneMapOpen(false)
    setOpenZone(false)
    setFinalStation(false);
    setCurrentZoneId(null)
    setLocationZonesDataZone([])
  }

  const addZoneData = async (data: any) => {
    let stationIds = zoneStationsPost?.stationIdsArr?.filter((value: any) => value.zoneChecked === true && value.zoneDisabled === false).map((item: any) => {
      return item.id;
    })
    zoneStationsPost.region = JSON.stringify(data);
    let paramValue = { ...zoneStationsPost, 'stationIdsArr': stationIds }
    if (paramValue) {
      await postMethod(ApiEndPoint.locations, '/' + locationId + '/zones', paramValue)
        .then((res) => {
          if (res) {
            zoneClose();
          }

        })
        .catch((err) => {
          console.error("Error", err);

        });
    }
    zoneClose();
    getLocationZones();
  };

  const updateZoneData = async (data: any) => {
    let stationIds = zoneStationsPost?.stationIdsArr?.filter((value: any) => value.zoneChecked === true && value.zoneDisabled === false).map((item: any) => {
      return item.id;
    })
    zoneStationsPost.region = JSON.stringify(data);
    let paramValue = { ...zoneStationsPost, 'stationIdsArr': stationIds }
    if (paramValue) {
      await putMethod(ApiEndPoint.locations, '/' + locationId + '/zones/' + currentZoneId, paramValue)
        .then((res) => {
          if (res) {
            zoneClose();
          }

        })
        .catch((err) => {
          console.error("Error", err);

        });
    }
    zoneClose();
    getLocationZones();
  };

  const deleteZoneCallback = () => {
    zoneClose();
    getLocationZones();
  }

  useEffect(() => {
    getLocationInformation();
    getLocationZones();
  }, [locationId]);

  const getRegion = (data: any) => {
    if(data === " ") {
      return [];
    } else {
      return JSON.parse(data);
    }
  }

  const clearPolygone = () => {
    setLocationZonesDataZone([])
  }

  return (
    <>
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog edit_detail_section  dialog_container_center"
          {...props}
          open={openZoneStation}
          onClose={zoneClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <ZoneFormDetails deleteZoneCallback={deleteZoneCallback} currentZoneId={currentZoneId} coordinates={coordinates} formType={formType} zoneInformation={zoneStationsPost} zoneClose={zoneClose} assignedStationMethod={assignedStationMethod} />
        </Dialog>
      </div>
      <div className="dialog_box">
          <Dialog
            className="dialog_container zone_settings_dialog  dialog_container_center"
            {...props}
            open={openAssignstation}
            onClose={zoneClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <AssignedStations pathCoord={locationZonesDataZone?.length > 0 ? locationZonesDataZone : []} formType={formType} zoneStationsPost={zoneStationsPost} zoneClose={zoneClose} polygonMapMethod={polygonMapMethod} currentZoneId={currentZoneId} locationId={locationId} createZoneMethod={createZoneMethod} viewAssignedStationMethod={viewAssignedStationMethod} />
          </Dialog>
      </div>
      { CheckAccess([claims.FORBIDDEN_ACCESS]) &&
        <div className="dialog_box">
          <Dialog
            className="dialog_container zone_settings_dialog  dialog_container_center"
            {...props}
            open={openCaptureZoneMap}
            onClose={zoneClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <PolygonMapDetails clearPolygone={clearPolygone} pathCoord={locationZonesDataZone?.length  > 0 ?  locationZonesDataZone : [] } formType={formType} assignedStationMethod={assignedStationMethod} zoneStationsPost={zoneStationsPost} zoneClose={zoneClose} locationInformationDetails={locationInformation} viewAssignedStationMethod={viewAssignedStationMethod} />
          </Dialog>
        </div>
      }
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog  dialog_container_center"
          {...props}
          open={openZone}
          onClose={zoneClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <ViewAssignedStations  pathCoord={locationZonesDataZone?.length  > 0 ?  locationZonesDataZone : [] } formType={formType} zoneClose={zoneClose} viewFinalStation={viewFinalStation} polygonMapMethod={polygonMapMethod} zoneStationsDetails={zoneStationsPost} locationInformation={locationInformation} addZoneData={addZoneData} assignedStationMethod={assignedStationMethod}/>
        </Dialog>
      </div>
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog  dialog_container_center"
          {...props}
          open={finalStation}
          onClose={zoneClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <UpdateZoneStations coordinates={coordinates} currentZoneId={currentZoneId} formType={formType} zoneStationsPost={zoneStationsPost} locationInformation={locationInformation} zoneClose={zoneClose} viewAssignedStationMethod={viewAssignedStationMethod} updateZoneData={updateZoneData} />
        </Dialog>
      </div>
      { depotSpinnerOff ? <CircularProgress className="inner_progress" /> : 
        <>
          <div data-testid="location_details">
            <div className="information_section delegates_details">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined" className="basic_card">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <div className="avtar_user">
                            <div className="table_pic locationInfoMapThumb zone_map">
                              <CommonGoogleMap
                                center={{
                                  lat: locationInformation?.address?.latitude,
                                  lon: locationInformation?.address?.longitude,
                                }}
                                zoom={
                                  depotGoogleMap.zoom
                                }
                                markers={[
                                  {
                                    lat: locationInformation?.address?.latitude,
                                    lon: locationInformation?.address?.longitude
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="info_detail">
                            <div className="tb_info_section mt-0">
                              <div className="label">
                                {toUpper(t("common.locationname"))}
                              </div>
                              <div className="result">{locationInformation?.name}</div>
                            </div>
                            <div className="tb_info_section">
                              <div className="label">
                                {toUpper(t("common.organization"))}
                              </div>
                              <div className="result">
                                {locationInformation?.organizationName}
                              </div>
                            </div>
                            <div className="tb_info_section">
                              <div className="label">
                                {toUpper(t("common.address"))}
                              </div>
                              <div className="result">
                                {locationInformation?.address && setAddressFormat(locationInformation?.address)}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="info_detail">
                            <div className="tb_info_section mt-0">
                              <div className="label">
                                {toUpper(t("common.descripition"))}
                              </div>
                              <div className="result">
                                {locationInformation?.description}
                              </div>
                            </div>
                            { CheckAccess(claims.FORBIDDEN_ACCESS) &&
                            <div className="tb_info_section">
                              <div className="label">
                                {toUpper(t("common.notes"))}
                              </div>
                              <div className="result">
                                {locationInformation?.notes}
                              </div>
                            </div>
                            }
                          </div>
                        </Grid>
                        <Grid className="text_right" item xs={2}>
                          <div className="number_details">
                            <div className="label">
                              {toUpper(t("common.stations"))}
                            </div>
                            <div className="result">{locationInformation?.chargingStations}</div>
                          </div>
                          <div className="number_details">
                            <div className="label">
                              {toUpper(t("common.ports"))}
                            </div>
                            <div className="result">{locationInformation?.totalPorts}</div>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <div className="button_row text_right">
                <Button
                  className="btn_white blue_bg ml_0"
                  onClick={() => createZoneMethod(defaultFormType)}
                >
                  {t("hostsetting.createzonebutton")}
                </Button>
              </div>
            </div>
            <div className="selected_zones_list information_section">
              <Grid container spacing={3}>
                {locationZonesData && locationZonesData?.zones?.length > 0 && locationZonesData?.zones.map((value: any, index: number) => (<Grid item xs={6} key={value?.id}>
                  <Card variant="outlined" className="basic_card height_equal">
                    <CardContent>
                      <div className="title_row">
                        <h2>{value.name}</h2>
                        <div
                          className="action_icon"
                        >
                          <IconButton className="top_action_btn" onClick={() => editZoneMethod(value)}>
                            <img src={Images.ic_edit} alt="edit" />
                          </IconButton>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="info_detail">
                        <div className="zone_section  mt-13">
                          <Grid container>
                          { CheckAccess([claims.FORBIDDEN_ACCESS]) &&

                            <Grid
                              className=""
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              lg={6}
                            >
                              <div className="zone_detail">

                                  <div className="zone_map_height zone_map">
                                    <CommonGoogleMap
                                      center={{
                                        lat: locationInformation?.address?.latitude,
                                        lon: locationInformation?.address?.longitude,
                                      }}
                                      markers={[
                                        {
                                          lat: locationInformation?.address?.latitude,
                                          lon: locationInformation?.address?.longitude
                                        },

                                      ]}
                                      pathCoord={getRegion(value.region)}
                                      polygon={true}
                                      mapsize={true}      
                                    />
                                  </div>
                              
                                {/* <div className="tb_zone_section">
                                  <div className="label">
                                    {toUpper(t("common.notes"))}
                                  </div>
                                  <div className="result">
                                    {value.notes}
                                  </div>
                                </div> */}
                              </div>
                            </Grid>
                          }
                            <Grid
                              className=""
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              lg={6}
                            >
                              <div className="zone_floor_info">
                                <List
                                  component="nav"
                                  className="z_floor_list"
                                >
                                  <ListSubheader className="list_head">
                                    <Grid
                                      container
                                      className="list_sub_head"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={12}
                                        lg={6}
                                      >
                                        {value.totalStation +
                                          " " +
                                          t("common.station", { count: value.totalStation })
                                        }
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={12}
                                        lg={6}
                                      >
                                        {value.totalPort +
                                          " " +
                                          t("common.port", { count: value.totalPort })
                                        }
                                      </Grid>
                                    </Grid>
                                  </ListSubheader>
                                  {value && value?.stations.length > 0 && value.stations.map((val: any, index: number) => (<ListItem key={val?.id}>
                                    <ListItemIcon className="zone_icon">
                                      <img
                                        src={val.modelImageReference}
                                        alt="edit"
                                      />
                                    </ListItemIcon>
                                    <ListItemText>
                                      {val.name}
                                    </ListItemText>
                                  </ListItem>))}
                                  <div className="tb_zone_section">
                                    <div className="label">
                                      {toUpper(t("common.notes"))}
                                    </div>
                                    <div className="result">
                                      {value.notes}
                                    </div>
                                  </div>
                                </List>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>))}
              </Grid>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default LocationsDetails;
