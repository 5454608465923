import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import HourForm from "components/hourForm";
import React, { useState, useRef, useEffect } from "react";
import { t } from "i18next";
import SimpleReactValidator from "simple-react-validator";

const EditOperationalHours = (props: any) => {

  const simpleValidator = useRef(new SimpleReactValidator());
  const [hoursOfOperation, setHoursOfOperation] = useState<any>();
  const [, forceUpdate] = useState<any>();
  const [hoursErrors, setHoursErrors] = useState([]);
  const [updateButton,setUpdateButton] = useState<any>("popup_buttondisable");

  useEffect(() => {
    props.makeHoursData();
  }, []);

  const showErrors = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  useEffect(()=>{
    if(JSON.stringify(props?.tempWeekState.current) === JSON.stringify(props?.weekArrayState)) {
      setUpdateButton("popup_buttondisable");
    } else {
      setUpdateButton("blue_bg");
    }
    let HourError = hoursErrors.some((ele) => ele === true)
    if(HourError){
      setUpdateButton("popup_buttondisable");
    }
  },[props?.weekArrayState])

  return (
    <>
      <div className="dialog_title">
        <h4>{t("common.operationalhours")}</h4>
        <div className="button_row mt-0 full_right">
          <Button
            className="btn_white text_btn_theme"
            onClick={props?.operationalHoursClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={props?.submitEditForm} className={`btn_white ${updateButton}`} disabled={ updateButton === "popup_buttondisable" ? true : false}
          >
            {t("common.station.update")}
          </Button>
        </div>
      </div>
      <DialogContent {...props}>
        <DialogContentText id="scroll-dialog-description">
          <div className="defult_form">
            <HourForm
              hourDetails={hoursOfOperation}
              validator={simpleValidator}
              setHoursOfOperation={setHoursOfOperation}
              showErrors={showErrors}
              weekArrayState={props?.weekArrayState}
              setWeekArrayState={props?.setWeekArrayState}
              errors={hoursErrors}
              setErrors={setHoursErrors}
            />
          </div>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default EditOperationalHours;
