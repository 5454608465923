import { Grid } from "@material-ui/core";
import { LabelInfo } from "common";
import { constraintRowData } from "pages/fleet/fleetManagementHelper";
import React from "react";
import { useTranslation } from "react-i18next";
import FleetScheduleEnableComponent from "./fleetScheduleEnableComponent";

const FleetScheduleView = (props: any) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="schedule_container">
                <div className="info_detail common_form">
                    <div className="default_form mt-30">
                        <div className="ml_10 form_col">
                            <Grid container justifyContent="flex-start" alignItems="flex-start">
                                <Grid item className="form_col" xs={2} />
                                <Grid item className="form_col" xs={2} />
                                <FleetScheduleEnableComponent
                                    scheduleView={props.scheduleView}
                                    scheduleVal={"day"}
                                    type={"day"}
                                    borderBottom={false}
                                />
                            </Grid>
                            {constraintRowData.map((rowData: any) => (
                                <>
                                    <Grid container justifyContent="flex-start" alignItems="flex-start" className="mt-20">
                                        <Grid item className="form_col" xs={2} >
                                            <span className="font_split">
                                                {rowData.title !== "" &&
                                                    <LabelInfo name={t(rowData.title)} isMandatory={false} />
                                                }
                                                {(rowData.description !== "" && !rowData.splitup) && <>{t(rowData.description)}</>}
                                            </span>
                                        </Grid>
                                        {(rowData.description !== "" && rowData.splitup) &&
                                            <Grid item className="form_col" xs={2} >
                                                <div className="font_split">{t(rowData.description)}</div>
                                                {rowData.borderBottom &&
                                                    <div className="mt-21 bottom_border_split"></div>
                                                }
                                            </Grid>
                                        }
                                        {!rowData.splitup &&
                                            <Grid item className="form_col" xs={2} />
                                        }
                                        <FleetScheduleEnableComponent
                                            scheduleView={props.scheduleView}
                                            scheduleVal={rowData.displayvalue}
                                            type={rowData.type}
                                            borderBottom={rowData.borderBottom}
                                        />
                                    </Grid>
                                    {!rowData.borderBottom &&
                                        <><div className="mt-10 mb-10 bottom_border"></div></>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FleetScheduleView;