import { Grid, MenuItem, Select } from "@material-ui/core";
import { duration } from "pages/fleet/fleetManagementHelper";
import React from "react";
import { useTranslation } from "react-i18next";

const FleetConstraintSelectComponent = (props: any) => {

    const {isEnable, scheduleIndex, type, value, handleScheduleValueChange} = props;
    const {t} = useTranslation();

    return (
        <>
            <Select
                className={`dropdown_width ${(!isEnable) && "disable"}`}
                data-testId={`dropdown_${isEnable}_${type}`}
                disabled={!isEnable}
                variant="outlined"
                name={`${type}${scheduleIndex}`}
                value={value}
                onChange={(event) =>
                    handleScheduleValueChange(event, scheduleIndex, type)
                }
            >
                <MenuItem className="defult_select_option" key={0} value=" ">
                    <Grid>{t("common.hhmm")}</Grid>
                </MenuItem>
                {duration.map((durationValue: any) => (
                    <MenuItem
                        className="defult_select_option"
                        key={durationValue.value}
                        value={durationValue.value}
                    >
                        <Grid>{durationValue.label}</Grid>
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}

export default FleetConstraintSelectComponent