import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tab,
  Tabs,
  AppBar,
  IconButton,
  DialogContentText,
  DialogContent,
  Dialog,
  Grid,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import {isFieldValueCheck, isCurrencyValueCheck, HandlePaginationLabel, TabPanel, pageDetails} from "common";
import { useTranslation } from "react-i18next";
import { toLower, toUpper } from "lodash";
import { ChargeSession, ChargeHistoryDetails } from "common/interfaces";
import {ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine,} from "recharts";
import TransactionDetail from "./transactionDetails";
import CommonDownloadCsv from "components/commonDownloadCsv";
import moment from "moment";
import CommonPagination from "components/commonPagination";
import {fetchChargeSession} from "../../services/evseStationsService";

const CommonChargeSessionDetails = (props: ChargeSession) => {
  const { t } = useTranslation();
  const scroll = "paper";
  const [sessionPowerDetails, setSessionPowerDetails] = useState<any>([]);
  const [SessionCSVData, setSessionCSVData] = useState<any>([]);
  const [CSVData, setCSVData] = useState<any>([]);
  const historyId = props?.historyId;
  const chargeSessionInfo = props?.sessionInfo;
  const open = props?.open;
  const callBackMethod = props?.setHandleClose;
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const [disableDownoadButton, setDisableDownoadButton] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = pageDetails.pageSizeTwenty;
  const downloadSize = sessionDetails?.totalChargeHistoryDetails;
  const fileName: string = `${t('filename.chargesessiondetail')}_${moment().format('MMDDYYYYHHmmss')}.csv`;
  const fileHeader = [
      { label: toUpper(t("common.starttime")), key: "startTime" },
      { label: toUpper(t("common.endtime")), key: "endTime" },
      { label: toUpper(t("common.avgpower")), key: "avgPower" },
      { label: t("common.maxpeakkw"), key: "maxPeak" },
      { label: t("common.totalenergykwh"), key: "totalEnergy" },
      // This column will open in future and hide for now
      // { label: t("common.kwhrate"), key: "kwhRate" },
  ];

  const chargeSessionCsvDataSet = () => {
      let chargeSessionCsvData = [];
      if (CSVData?.length > 0) {
          chargeSessionCsvData = CSVData?.map(
              (val: any, index: any) => ({
                  ...val,
                  id: index,
                  startTime: isFieldValueCheck(val?.startTime),
                  endTime: isFieldValueCheck(val?.endTime),
                  avgPower: isFieldValueCheck(val?.avgPower),
                  maxPeak: isFieldValueCheck(val?.maxPeak),
                  totalEnergy: isFieldValueCheck(val?.totalEnergy),
                  kwhRate: isCurrencyValueCheck(val?.currency, val?.kwhRate),
              })
          );
      }
      setSessionCSVData(chargeSessionCsvData);
  };

  const historysessioncolumns = [
    {
      field: "firstCell",
      headerName: toUpper(t("common.firstcell")),
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 20,
      minWidth: 20,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return <div className="empty">{cellValues.value}</div>;
      },
    },

    {
      field: "startTime",
      headerName: toUpper(t("common.starttime")),
      sortable: false,
      hasFocus: false,
      flex:0.3,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues?.value}
          </>
        );
      },
    },

    {
      field: "endTime",
      headerName: toUpper(t("common.endtime")),
      sortable: false,
      hasFocus: false,
      flex:0.3,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues?.value}
          </>
        );
      },
    },
    {
      field: "avgPower",
      headerName: toUpper(t("common.avgpower")),
      sortable: false,
      flex:0.2,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.value}
          </>
        );
      },
    },
    {
      field: "maxPeak",
      headerName: t("common.maxpeakkw"),
      sortable: false,
      flex:0.2,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.value}
          </>
        );
      },
    },

    {
      field: "totalEnergy",
      headerName: t("common.totalenergykwh"),
      sortable: false,
      flex:0.2,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.value}
          </>
        );
      },
    },

    {
      field: "kwhRate",
      headerName: t("common.kwhrate"),
      sortable: false,
      flex:0.2,
      hide:true,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        const currValue = cellValues?.row?.currency ? (cellValues.row.currency ) : '';
        return (
          <>
            {currValue + cellValues.value}
          </>
        );
      },
    },

    {
      field: "lastCell",
      headerName: toUpper(t("common.lastcell")),
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 20,
      minWidth: 20,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return <div className="empty">{cellValues.value}</div>;
      },
    },
  ];

  useEffect(() => {
    getChargeSession();
  }, [historyId, currentPage]);

    const getChargeSession =  () => {
        let params = {historyId: historyId, currentPage: currentPage, pageSize: pageSize };
        fetchChargeSession(params).then((res: any) => {
            if (res?.data.chargeHistoryDetails) {
                let sessionTableData = res.data.chargeHistoryDetails.map(
                    (val: ChargeHistoryDetails, index: ChargeHistoryDetails) => ({...val, id: index})
                );
                setSessionPowerDetails(sessionTableData ? sessionTableData : []);
                setSessionDetails(res?.data);
            }
        }).catch((err) => {
                console.log("Error", err);
       });
    };


  const getChargeSessionCSVData = () => {
      let params = {historyId: historyId,  currentPage: 0, pageSize: downloadSize};
      fetchChargeSession(params).then((res: any) => {
          if (res?.data?.chargeHistoryDetails) {
              setCSVData(res?.data?.chargeHistoryDetails);
              setDisableDownoadButton(false)
          }
      }).catch((err: any) => {
              console.log("Error", err);
      });
  };
  
  useEffect(() => {
    if(downloadSize > 0){
      getChargeSessionCSVData();
    }
  }, [downloadSize]);

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage)    
  };

  return (
    <>
      <div className="dialog_box" data-testid="chargesession_detail_dialog">
        <Dialog
          {...props}
          className="dialog_container edit_detail_section chargesession_detail"
          open={open}
          onClose={callBackMethod}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("chargesessiondetail")}</h4>
            <IconButton
              className="closed_btn mt-10 full_right"
              onClick={callBackMethod}
            >
              <span className="material-icons">
                {toLower(t("common.cancelbutton"))}
              </span>
            </IconButton>
          </div>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              component="div"
              id="scroll-dialog-description"
            >
              <div className="chargesession_deatiltab detail_page_tab pl_pr_10">
                <AppBar
                  className="defult_tab position_fixed_nav"
                  position="static"
                  color="default"
                >
                  <Tabs
                    className="tab_nav"
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable force tabs example"
                  >
                    <Tab
                      className="tab_nav_item"
                      label={t("chargesessiondetail.chargedetail")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="tab_nav_item"
                      label={t("common.amount")}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className="dialog_body">
                    <div className="history_charging_list">
                      <Grid container>
                        <Grid item xs={5}>
                          <div className="h_charge_list">
                            <div className="label">
                              {t("common.port")}
                            </div>
                            <div className="result">
                              <a>
                                {isFieldValueCheck(chargeSessionInfo.portInformation)}
                              </a>
                            </div>
                            <div className="label">
                              {t("chargesessiondetail.startcharge")}
                            </div>
                            <div className="result">
                              {isFieldValueCheck(chargeSessionInfo.startChargeTime)}
                            </div>
                            <div className="label">
                              {t("chargesessiondetail.endcharge")}
                            </div>
                            <div className="result">
                              {isFieldValueCheck(chargeSessionInfo.endChargeTime)}
                            </div>
                            <div className="label">
                              {t("common.blinkcodecard")}
                            </div>
                            <div className="result">
                              {isFieldValueCheck(chargeSessionInfo.rfId)}
                            </div>
                            <div className="label">
                              {t("chargesessiondetail.energy")}
                            </div>
                            <div className="result">
                              {isFieldValueCheck(chargeSessionInfo.energy)}
                            </div>
                            <div className="label">
                              {t("chargesessiondetail.sessionid")}
                            </div>
                            <div className="result">
                              {isFieldValueCheck(chargeSessionInfo?.transactionId)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={7}>
                          {sessionPowerDetails.length >= 1 ? (
                            <ResponsiveContainer width={"99%"} height={150}>
                              <ComposedChart
                                data={sessionPowerDetails}
                                margin={{
                                  top: 3,
                                  right: 3,
                                  bottom: 3,
                                  left: 2,
                                }}
                              >
                                <CartesianGrid
                                  vertical={false}
                                  fill="#EEEEEE"
                                  stroke="#FFFFFF"
                                  strokeOpacity={1}
                                  fillOpacity={0.5}
                                  strokeWidth={2.5}
                                />
                                <XAxis hide />
                                <YAxis
                                  tickMargin={-1}
                                  dy={4}
                                  width={42}
                                  unit=" kW"
                                  tickCount={4}
                                  interval={0}
                                  axisLine={false}
                                  tickLine={false}
                                  style={{ fontSize: "8px", color: "#4D4D4F" }}
                                />
                                <Area
                                  dataKey="totalEnergy"
                                  fill="#65A844"
                                  stroke="#65A844"
                                  strokeOpacity={0.5}
                                  fillOpacity={0.2}
                                />
                                <Line
                                  dataKey="avgPower"
                                  stroke="#25556E"
                                  dot={false}
                                  strokeDasharray="2 1"
                                />
                                <ReferenceLine
                                  x={0}
                                  stroke="#65A844"
                                  strokeOpacity={1}
                                  alwaysShow={true}
                                  strokeWidth={2.5}
                                />
                                <ReferenceLine
                                  x={
                                    sessionPowerDetails
                                      ? sessionPowerDetails.length - 1
                                      : 0
                                  }
                                  stroke="#65A844"
                                  strokeOpacity={1}
                                  alwaysShow={true}
                                  strokeWidth={2.5}
                                />
                              </ComposedChart>
                            </ResponsiveContainer>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div className="dialog_data_table">
                    <h5>{t("chargesessiondetail.intervaldatadetail")}</h5>
                  </div>
                  <div className="table_scroll">
                    <div className="default_table global_cursor">
                      <DataGrid
                        autoHeight
                        className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={sessionPowerDetails}
                        columns={historysessioncolumns}
                        pageSize={pageSize}
                        disableSelectionOnClick
                      />
                    </div>
                    <div className="table_footer">
                        <Grid container direction="row" alignItems="center" spacing={0}>
                            <Grid className="text_left" item xs={8}>
                                {sessionDetails && sessionDetails?.totalChargeHistoryDetails > pageSize &&
                                <CommonPagination
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    totalCount={sessionDetails?.totalChargeHistoryDetails}
                                    callBackCurrentPage={handleCurrentPage}
                                />
                                }
                                {sessionDetails && sessionDetails?.totalChargeHistoryDetails > 0 &&
                                <CommonDownloadCsv
                                    datas={SessionCSVData}
                                    headers={fileHeader}
                                    filename={fileName}
                                    blueclass={"border"}
                                    callBackDownloadCsv={chargeSessionCsvDataSet}
                                    disableValue={disableDownoadButton}
                                />}
                            </Grid>
                            <Grid className="text_right" item xs={4}>
                                <div className="list_number">
                                    {HandlePaginationLabel(sessionDetails?.totalChargeHistoryDetails, pageSize, currentPage, "common.chargesession")}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TransactionDetail historyId={chargeSessionInfo?.historyId}  serialNumber={chargeSessionInfo?.serialNumber}/>
                </TabPanel>
              </div>

            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

CommonChargeSessionDetails.propTypes = {
  historyId: PropTypes.string,
  callBackMethod: PropTypes.func,
  chargeSessionInfo: PropTypes.any,
  open: PropTypes.any,
};

CommonChargeSessionDetails.defaultProps = {
  chargeSessionInfo: {},
  open: false,
};
export default CommonChargeSessionDetails;
