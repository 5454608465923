import React from 'react';
import Images from 'assets/images';
import { useTranslation } from 'react-i18next';
import { toUpper } from 'lodash';
import CommonGoogleMap from 'components/commonGoogleMap';
import { depotGoogleMap, renderCellExpand, setAddressFormat } from 'common';

export const userListColumns = [
  {
      field: "firstCell",
      headerName: "common.firstcell",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
          return <div className="empty">{cellValues.value}</div>;
      },
  },

  {
      field: "",
      headerName: "",
      sortable: false,
      flex: 0.1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
          if(cellValues.row.imageReference !== 0) {
             return  <div className="table_pic"><img className="user-logo"  src={cellValues.row.imageReference} alt="user" />{cellValues.value}</div> 
          } else {
            return  <div className="table_pic"><img className="user-logo" src={Images.user_avtar} alt="user" />{cellValues.value}</div> 
          }
      },
  },

  {
      field: "groupname",
      headerClassName: "action_columns",
      headerName: "header.groupname",
      sortable: false,
      cellClassName: "",
      flex: 0.2,
      minWidth: 100,
      disableColumnMenu: true,
  },
  
  {
      field: "numberOfUsers",
      headerName: "header.user",
      headerClassName: "action_columns",
      sortable: false,
      hasFocus: false,
      className: "port",
      minWidth: 100,
      flex: 0.2,
      disableColumnMenu: true,
  },

  {
      field: "numberOfRFIDCards",
      headerName: "common.blinkcard",
      headerClassName: "action_columns",
      sortable: false,
      hasFocus: false,
      className: "port",
      minWidth: 100,
      flex: 0.1,
      disableColumnMenu: true,
  },

  {
      field: "createdTimestamp",
      headerName: "header.creationdate",
      headerClassName: "action_columns",
      sortable: false,
      hasFocus: false,
      className: "port",
      minWidth: 100,
      flex: 0.1,
      disableColumnMenu: true,
  },

  {
      field: "lastCell",
      headerName: "common.lastcell",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
          return <div className="empty">{cellValues.value}</div>;
      },
  },
];
export const manageUserlistColumns = [
    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    }, 
    {
      field: 'userName',
      headerName: 'common.name',
      sortable: false,
      flex: 0.1,
      minWidth: 100,
      disableColumnMenu: true
    },     
    {
      field: 'email',
      headerName: 'common.email',
      sortable: false,
      cellClassName: "",
      minWidth:160,
      flex: 0.1,
      disableColumnMenu: true
    },
  
      {
      field: "rfids",
      headerName: "common.blinkcard",
      sortable: false,
      hasFocus: false,
      cellClassName: "",
      className: "",
      minWidth: 100,
      flex: 0.1,
      disableColumnMenu: true
    },  
    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
  ];
  export const userLocationColumns = [

    {
      field: "firstCell",
      headerName: "common.firstcell",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
    {
      field: 'mapDetails',
      headerName: 'common.map',
      sortable: false,
      width: 100,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
            <div className="table_pic listMapThumb">
                <CommonGoogleMap
                    center={{
                        lat: cellValues?.value?.latitude,
                        lon: cellValues?.value?.longitude,
                    }}
                    zoom={
                        depotGoogleMap.zoom
                    }
                    markers={[
                        {
                            lat: cellValues?.value?.latitude,
                            lon: cellValues?.value?.longitude
                        },

                    ]}

                />
            </div>
        );
      },
    },
    
     
    {
      field: 'locationName',
      headerName: 'common.location',
      sortable: false,
      cellClassName: "",
      minWidth:100,
      flex: 0.1,
      disableColumnMenu: true
    },
  
      {
      field: "addressDTO",
      headerName: "common.address",
      sortable: false,
      hasFocus: false,
      cellClassName: "",
      className: "",
      minWidth: 100,
      flex: 0.1,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
            <div className="line-height">
                {setAddressFormat(cellValues.value)}
            </div>
        );
    },
    },
    {
      field: "zones",
      headerName: "common.zone",
      sortable: false,
      hasFocus: false,
      cellClassName: "",
      className: "",
      minWidth: 100,
      flex: 0.1,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        const elementToRender = (
          <div className="zone_location_label">
            {cellValues.value?.length > 0 && cellValues.value.map((val: any) => (
                <span key={val?.name}>{val?.name}</span>
              ))}
          </div>
        );
        return renderCellExpand(cellValues, elementToRender);
    },
     
    },
  
    {
      field: "lastCell",
      headerName: "common.lastcell",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
  ];

  export const UserUploadErrorComponent = () => {
    const { t } = useTranslation();
const userUploadError = [
    {
        field: "firstCell",
        headerName: "firstcell",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
    {
        field: "email",
        headerName:  toUpper(t("common.email")),
        sortable: false,
        flex: 0.1,
        minWidth: 100,
        disableColumnMenu: true,
    },

    {
        field: "rfid",
        headerName: toUpper(t("common.blinkcard")),
        sortable: false,
        cellClassName: "",
        flex: 0.1,
        minWidth: 100,
        disableColumnMenu: true,
    },

    {
        field: "emailError",
        headerName: toUpper(t("common.error")),
        sortable: false,
        cellClassName: "",
        flex: 0.1,
        minWidth: 100,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
          return renderCellExpand(cellValues, undefined, undefined, 'error_text');
        },
    },

    {
        field: "lastCell",
        headerName: "common.lastcell",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
]
return userUploadError;
}
