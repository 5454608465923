import { Grid } from "@material-ui/core";
import { convertSecondToTimeFormat, displayFeeAndUnitPrice, formatTimeHHMMTo12Hour, getUnitValue, transactionInfo } from "./transactionDetailsHelper";
import { getBlankCurrencyPlaceholder } from "../../common";
import React from "react";
import { useTranslation } from "react-i18next";
import { currencyAmountValidate } from "../../common/methodHelpers";

const EnergyOccupancyDetails = (props: any) => {
    const { t } = useTranslation();
    let { priceValue, isEnergyDetail, sessionTransactionDetails, isLocationSubscribedToPricing } = props;

    const getChargingFeeDetails = () => {
        if (isLocationSubscribedToPricing)
            return displayFeeAndUnitPrice(sessionTransactionDetails, false, priceValue, true, t);
        else
            return t("chargesessiondetail.chargingunit", { chargingPrice: displayFeeAndUnitPrice(sessionTransactionDetails, false, priceValue, false), chargingUnit: getUnitValue(sessionTransactionDetails, t, false, priceValue) });
    }

    const getOccupancyFeeDetails = () => {
        if (isLocationSubscribedToPricing)
            return displayFeeAndUnitPrice(sessionTransactionDetails, true, priceValue, true, t)
        else
            return t("chargesessiondetail.occupancyUnit", { occupancyPrice: displayFeeAndUnitPrice(sessionTransactionDetails, true, priceValue, false), occupancyUnit: getUnitValue(sessionTransactionDetails, t, true, priceValue) })
    }

    return (
        <>
            {isEnergyDetail ?
                <>
                    <Grid item xs={4}>
                        {transactionInfo(t("common.used.energy"), `${(priceValue?.elapsedKWh ?? 0)?.toFixed(2)} ${t('common.kwh')}`)}
                    </Grid>
                    <Grid item xs={4}>
                        {transactionInfo(t("common.chargingfee"), getChargingFeeDetails())}
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={4}>
                        {transactionInfo(t("common.occupancytime"), convertSecondToTimeFormat(priceValue?.elapsedTime))}
                    </Grid>
                    <Grid item xs={4}>
                        {transactionInfo(t("chargesessiondetail.occupancyfee"), getOccupancyFeeDetails())}
                    </Grid>
                </>
            }

            <Grid item xs={4}>
                {transactionInfo(t("common.amount"), priceValue?.fee ? currencyAmountValidate(sessionTransactionDetails?.currencySymbol, priceValue?.fee) :
                    getBlankCurrencyPlaceholder(sessionTransactionDetails?.currencySymbol), priceValue?.fee)}
            </Grid>
            {isLocationSubscribedToPricing &&
                <>
                    <Grid item xs={4}>
                        {transactionInfo(t("common.starttime"), formatTimeHHMMTo12Hour(priceValue?.startTime))}
                    </Grid>
                    <Grid item xs={4}>
                        {transactionInfo(t("common.endtime"), formatTimeHHMMTo12Hour(priceValue?.endTime))}
                    </Grid>
                    <Grid item xs={4} />
                </>
            }
        </>
    )
};

export default EnergyOccupancyDetails;
