import React from "react";
export const revenueListColumns = [
    {
        field: "a",
        headerName: "a",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
  
    {
        field: "hostid",
        headerClassName: "action_columns",
        headerName: "HOST ID",
        sortable: false,
        cellClassName: "",
        flex: 0.2,
        minWidth: 100,
        disableColumnMenu: true,
    },
    
    {
        field: "DATE",
        headerName: "DATE",
        headerClassName: "action_columns",
        sortable: false,
        hasFocus: false,
        className: "port",
        minWidth: 100,
        flex: 0.9,
        disableColumnMenu: true,
    },

    {
        field: "b",
        headerName: "b",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
];
