import React, { useEffect } from 'react';
import FleetDashboard from '../pages/fleetDashboard';
import EvseLocations from '../pages/evseLocations';
import EvseDepotDetail from '../pages/evseLocations/evseDepotDetail';
import DepotStationDetails from '../pages/evseLocations/depotStationDetails';
import UserGroupDetail from '../pages/userGroupDetail';
import UserGroups from '../pages/userGroups';
import DriverInfo from '../pages/driverInfo';
import PrivateRoute from './privateRoute';
import SidebarMenu from '../components/sidebarMenu';
import Header from '../components/header';
import { makeStyles } from '@material-ui/core/styles';
import HostSettings from 'pages/hostSettings';
import Login from 'pages/login';
import Statements from 'pages/statements';
import BlinkCard from 'pages/blinkCard';
import NotFound, { Invalid } from '../pages/common';
import Training from 'pages/training';
import TrainingDetail from 'pages/training/details';
import ChangePassword from 'pages/profile/changePassword';
import ForbiddenPage from 'pages/forbiddenPage';
import { claims } from 'common/claims';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginTime } from 'redux/actions/loginTime';
import  DownloadReport  from 'components/downloadReport';
import HostMemberRequest from 'pages/hostMembershipRequest';
import HostMembershipDetail from 'pages/hostMembershipDetail';
import { defaultVariable, rolePermissionCheck, keyCloakConfig } from 'common';
import FleetManagement from 'pages/fleet';
import FleetVehicleInfo from 'pages/fleet/fleetVehicles/fleetVehicleDetails';
import FleetGroupInfo from 'pages/fleet/fleetGroups/fleetGroupDetails';

export const RoutesWithoutSidebar = () => { return (
    <>
        <Header />
    </>
)};

export const ForbiddenAccess = (isExpired) => { 
  
  
  const isAuthenticated = keyCloakConfig?.authenticated;
  const returnComp = isAuthenticated ? <ForbiddenPage isExpired={isExpired} /> : <></>;
  
  return (<>{returnComp}</>)
};


export const RoutesWithSidebar = () => { 

    const dispatch = useDispatch();
    const loginTime = useSelector((state) => state.loginTime)

    
    const manageUsersEnabled = useSelector((state) => state?.sidebarItems?.manageUsersEnabled);
    const fleetSubscribed = sessionStorage.getItem('fleetSubscribtion') === 'true';
    const isDelegate = rolePermissionCheck(keyCloakConfig?.realmAccess?.roles, [defaultVariable.ROLES.delegate]);
    const fleetSubscribedClaim =  fleetSubscribed ? [claims.DRIVER_READ] : [claims.FORBIDDEN_ACCESS];
    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
          flexGrow: 1,
          padding: theme.spacing(3),
        },
      }));
    
    const classes = useStyles();


    useEffect(() => {
      if(!loginTime) {
        dispatch(fetchLoginTime());
      }
    }, [])

    const canManageUsers = (manageUsersEnabled ? [claims.DRIVER_READ] : [claims.FORBIDDEN_ACCESS]);

    return (
    <>  
        <div className="inside_page">
        <div className={classes.root}>
        <SidebarMenu />
        <PrivateRoute exact path="/" component={FleetDashboard} />
        <PrivateRoute exact path="/profile" component={ChangePassword} />
        <PrivateRoute exact path="/evseLocations" component={EvseLocations} />
        <PrivateRoute path="/downloadReport" component={DownloadReport} />

        <PrivateRoute exact path="/evseLocations/evseDepotDetail" component={EvseDepotDetail} />
        <PrivateRoute exact path="/evseLocations/depotStationDetails" component={DepotStationDetails} />
        <PrivateRoute 
          exact
          path="/manageUsers/manageUsersDetail" 
          access={isDelegate ? canManageUsers : [claims.DRIVER_READ]}
          component={UserGroupDetail} />
          <PrivateRoute 
          exact
          path="/manageUsers/manageUsersMembershipDetail" 
          component={HostMembershipDetail} />
        <PrivateRoute 
          exact
          path="/manageUsers" 
          access={isDelegate ? canManageUsers : [claims.DRIVER_READ]}
          component={UserGroups} />
        <PrivateRoute 
          path="/driverInfo" 
          access={[claims.DRIVER_READ]}
          component={DriverInfo} />
        <PrivateRoute
          exact path="/fleet" 
          component={FleetManagement} />  
        <PrivateRoute
          exact path="/fleet/fleetVehicleInfo"
          component={FleetVehicleInfo} />
        <PrivateRoute
          path="/hostSettings" 
          component={HostSettings} />
        <PrivateRoute path="/login" component={Login} />
        <PrivateRoute path="/statements" component={Statements} />
        <PrivateRoute path="/blinkCards" access={[claims.FORBIDDEN_ACCESS]} component={BlinkCard} />
        <PrivateRoute  path="/404" component={NotFound} />
        <PrivateRoute  path="/invalid" component={Invalid} />
        <PrivateRoute exact path="/training" component={Training} />
        <PrivateRoute exact path="/training/details" component={TrainingDetail} />
        <PrivateRoute path="/forbiddenPage" component={ForbiddenPage} />
        <PrivateRoute exact path="/manageUsers/privateAccessRequests" component={HostMemberRequest} />
        <PrivateRoute exact path="/fleet/fleetInfo" access={isDelegate ? fleetSubscribedClaim : [claims.FLEET_READ]} component={FleetGroupInfo} />
        </div>
        </div>
    </>
)};

