import React from "react";
import { defaultVariable } from "common";
import parse from 'html-react-parser'
import { ApiEndPoint, getMethod } from "services";

const useChargeFee = (countryCode: string) => {

    const SESSION_TYPE = 'session';
    const USAGE_TYPE = 'usage';
    const OCCUPANCY_TYPE = 'occupancy';
    const SESSION_CURRENCY_TYPE = 'sessionCurrency';
    const STANDARD_TYPE = 'Standard';

    const defaultValueSet = '0.00';
    const defaultCurrencyType = defaultVariable.defaultCurrency;
    const defaultMetaData = { portLevels: [] as any[], pricingSpecs: [] as any[], currencies: [] as any[], billingUnits: [] as any[] }
    const [priceMetadata, setPriceMetadata] = React.useState(defaultMetaData);
    const currenciesType = priceMetadata?.currencies ? priceMetadata?.currencies : [];
    const unitsType = priceMetadata?.billingUnits ? priceMetadata?.billingUnits : [];
    const defaultUnitType = unitsType?.find((val: any) => val.unit === defaultVariable.defaultUnit) ? unitsType.find((val: any) => val.unit === defaultVariable.defaultUnit) : {};
    const [defaultCurrSymbol, setdefaultCurrSymbol] = React.useState(defaultVariable.defaultCurrencySymbol);
    const [countryList, setCountryList] = React.useState<any>([]);
    const [currencyList, setCurrencyList] = React.useState<any>([]);
    const [countryName, setCountryName] = React.useState<any>('');

    const intialDataSet = {
        globalTaxRate: defaultValueSet,
        sessionTaxRate: defaultValueSet,
        occupancyTaxRate: defaultValueSet,
        usageTaxRate: defaultValueSet,
        sessionCurrency: defaultCurrencyType,
        sessionFreeChargingTime: 0, //set 0 sec against the 0 min default value
        occupancyBillingIncrementTime: 300, // set 300 sec against 5 min default
        roamingFee: 0,
        occupancyMaxFee: defaultValueSet,
        occupancyGracePeriod: 0, //set 0 sec against the 0 min default value
        usageBillingUnit: defaultUnitType,
        memberLevelFees: [],
        usageBillingIncrementTime: 300  // set 300 sec against 5 min default
    }
    const [intialValues, setIntialValues] = React.useState<any>(intialDataSet);

    const getPricingMetadata = async () => {
        await getMethod(ApiEndPoint.evsePricing, 'metadata').then((res) => {
            setPriceMetadata(res.data)
        }).catch((err) => {
            console.error("Error", err)
        })
    }

    const getCountryList = async () => {
        await getMethod(ApiEndPoint.countryList, '').then((res) => {
            setCountryList(res?.data?.countries)
        }).catch((err) => {
            console.error("Error", err)
        })
    }

    const getCurrencyList = async () => {
        let selectedCountry = await countryList?.find((val: any) => val?.code === countryCode);
        setCurrencyList(selectedCountry?.currency)
        if (selectedCountry) {
            setCountryName(selectedCountry)
            let findValue = selectedCountry?.currency?.find((val: any) => val?.code === intialValues?.sessionCurrency)
            if(findValue) {
                setdefaultCurrSymbol(findValue?.symbol ? parse(findValue?.symbol) : '')
            } else {
                setdefaultCurrSymbol(selectedCountry?.currency?.length > 0  ? parse(selectedCountry?.currency[0]?.symbol)  : '')
                setIntialValues({ ...intialValues, [SESSION_CURRENCY_TYPE] : (selectedCountry?.currency?.length > 0)  ? selectedCountry?.currency[0]?.code  : intialValues?.sessionCurrency });

            }
        }
    }

    const templateDataFormat = (tempData:any) => {
        let values = tempData?.data;
        let memberFees = values?.memberLevelFees ? values?.memberLevelFees?.map((val: any) => ({ ...val, occupancyFee: Number(val.occupancyFee).toFixed(2), sessionServiceFee: Number(val.sessionServiceFee).toFixed(2), usageChargingFee: Number(val.usageChargingFee).toFixed(2)  })) : []
        if (memberFees){
            values = { ...values, ['memberLevelFees']: memberFees , globalTaxRate : Number(values.globalTaxRate).toFixed(2), occupancyTaxRate :Number(values.occupancyTaxRate).toFixed(2) ,sessionTaxRate :Number(values.sessionTaxRate).toFixed(2), usageTaxRate :Number(values.usageTaxRate).toFixed(2), occupancyMaxFee :Number(values.occupancyMaxFee).toFixed(2)};
            setIntialValues(tempData?.status === 200 ? values : intialDataSet);
        }
    }

    const templateDataFormatFiltered = (tempData: any) => {
        let values = tempData?.data;
        let memberFees = values?.memberLevelFees ? values?.memberLevelFees?.filter((val: any) => (val.costSegment === STANDARD_TYPE))?.map((val: any) => ({ ...val, occupancyFee: Number(val.occupancyFee).toFixed(2), sessionServiceFee: Number(val.sessionServiceFee).toFixed(2), usageChargingFee: Number(val.usageChargingFee).toFixed(2) })) : []
        if (memberFees) {
            values = { ...values, ['memberLevelFees']: memberFees, globalTaxRate: Number(values.globalTaxRate).toFixed(2), occupancyTaxRate: Number(values.occupancyTaxRate).toFixed(2), sessionTaxRate: Number(values.sessionTaxRate).toFixed(2), usageTaxRate: Number(values.usageTaxRate).toFixed(2), occupancyMaxFee: Number(values.occupancyMaxFee).toFixed(2), usageBillingIncrementTime: ((!values.usageBillingIncrementTime) ? intialDataSet.usageBillingIncrementTime : values.usageBillingIncrementTime)  };
            setIntialValues(tempData?.status === 200 ? values : intialDataSet);
        }
    }

    const findMemberLevelFees = (member: any, costSegment: any, chargeType: any) => {
        let feeAmount = '';
        let feeValue: any = intialValues?.memberLevelFees?.find((d: any) =>
            d.member === member &&
            d.costSegment === costSegment
        );
        switch (chargeType) {
            case SESSION_TYPE:
                feeAmount = feeValue && feeValue?.sessionServiceFee ? feeValue.sessionServiceFee : feeAmount;
                break;
            case USAGE_TYPE:
                feeAmount = feeValue && feeValue?.usageChargingFee ? feeValue.usageChargingFee : feeAmount;
                break;
            case OCCUPANCY_TYPE:
                feeAmount = feeValue && feeValue?.occupancyFee ? feeValue.occupancyFee : feeAmount;
                break;
            default:
                break;
        }
        return feeAmount;
    }

    const checkMemberAndCostSegment = (member: any, costSegment: any, d: any) => {
        return d.member === member && d.costSegment === costSegment;
    }

    const setFeeListData = (member: any, costSegment: any, d: any, feeValue: any, chargeType: any) => {

        if(checkMemberAndCostSegment(member, costSegment, d)) {
            if(chargeType === SESSION_TYPE) {
                return { ...d, sessionServiceFee: feeValue };
            } else if(chargeType === USAGE_TYPE) {
                return { ...d, usageChargingFee: feeValue };
            } else if(chargeType === OCCUPANCY_TYPE) {
                return { ...d, occupancyFee: feeValue };
            }
        } 
        return d;
    }

    const setMemberLevelFees = (member: any, costSegment: any, feeValue: any, chargeType: any) => {
        let feeList = intialValues.memberLevelFees.map((d: any) =>
            setFeeListData(member, costSegment, d, feeValue, chargeType)
        );
        setIntialValues({ ...intialValues, ['memberLevelFees']: feeList });
    }

    const handleMemberLevelFees = (member: any, costSegment: any, chargeType: any, e: any) => {

        let feeValue = e.target.value;
        if (feeValue.split('.')?.length <= 2) {
            feeValue = (feeValue.indexOf(".") >= 0) ? (feeValue.substr(0, feeValue.indexOf(".")) + feeValue.substr(feeValue.indexOf("."), 3)) : feeValue;
            let memberFeeCheck = intialValues?.memberLevelFees?.some((item: any) => item.member === member && item.costSegment === costSegment)
            switch (chargeType + memberFeeCheck) {
                case SESSION_TYPE + true:
                    setMemberLevelFees(member, costSegment, feeValue, chargeType);
                    break;
                case USAGE_TYPE + true:
                    setMemberLevelFees(member, costSegment, feeValue, chargeType);
                    break;
                case OCCUPANCY_TYPE + true:
                    setMemberLevelFees(member, costSegment, feeValue, chargeType);
                    break;
                default:
                    let memberFeeObject = { member: member, costSegment: costSegment, sessionServiceFee: (chargeType === SESSION_TYPE) ? feeValue : "", usageChargingFee: (chargeType === USAGE_TYPE) ? feeValue : "", occupancyFee: (chargeType === OCCUPANCY_TYPE) ? feeValue : "", };
                    let memberFeeArray = [...intialValues?.memberLevelFees, memberFeeObject];
                    setIntialValues({ ...intialValues, ['memberLevelFees']: memberFeeArray });
                    break;
            }
        }
    }

    const handleChange = (e: any) => {
        setIntialValues({ ...intialValues, [e.target.name]: e.target.value });
        if (e.target.name === SESSION_CURRENCY_TYPE) {
            let findValue = currenciesType.find((val: any) => val?.name === e.target.value)
            setdefaultCurrSymbol(parse(findValue.symbol))
            setIntialValues({ ...intialValues, [e.target.name]: e.target.value });
        } else {
            setIntialValues({ ...intialValues, [e.target.name]: e.target.value });
        }
    };

    const handleDecmalValueChange = (e: any) => {
        let feeValue = e.target.value;
        if (feeValue.split('.')?.length <= 2) {
            feeValue = (feeValue.indexOf(".") >= 0) ? (feeValue.substr(0, feeValue.indexOf(".")) + feeValue.substr(feeValue.indexOf("."), 3)) : feeValue;
            setIntialValues({ ...intialValues, [e.target.name]: feeValue });
        }
    };

    return {
        intialValues,
        defaultCurrSymbol,
        templateDataFormat,
        templateDataFormatFiltered,
        findMemberLevelFees,
        handleMemberLevelFees,
        handleChange,
        handleDecmalValueChange,
        getCountryList,
        getCurrencyList,
        getPricingMetadata,
        priceMetadata,
        countryName,
        currencyList,
        countryList
    }

};

export default useChargeFee;