import { 
    Grid, 
    TextField 
} from "@material-ui/core";
import { notAllowSpace } from "common/methodHelpers";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const AddNewUserForm = (props: any) => {

    const { register, handleSubmit, setError, formState: { errors }  } = useForm();

	const onSubmit = (data: any) => {
        if(props?.formType === 'email') {
            data['email'] = data.email.trim();  // only if email form type
        }
        props?.handleSubmitData(data)
	}

    useEffect(() => {
        if(props?.errorMessage && (props?.errorResponseType === props?.formType || props?.errorResponseType === 'both')) {
            setError(props?.formType, {type: "server", message: props?.errorMessage});
        }
    }, [props?.errorResponseType, props?.errorMessage])

    return (<>
        <form id="hook-form" data-testid="user_info_add_form" onSubmit={handleSubmit(onSubmit)}>
            <div className={`form_col ${props?.errorMessage && 'error'}`}>
                <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid item xs={12}>
                        {/** For Email */
                            props?.formType === 'email' && 
                            <>                    
                                <label>{t("common.email")}{" "}{t("common.required")}</label>
                                <TextField {...register("email", {required: {value:true, message: t("manageuser.email.required")}, pattern: {value: /^[ a-zA-Z0-9+&!._-]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/, message: t('emailrfiderrors.invalidemailid')}})}  placeholder={t("common.emailaddress")} variant="outlined"/>
                                {errors?.email && <span className="error_msg">{errors?.email?.message}</span>}
                            </>
                        }
                        {/**For Rfid */
                            props?.formType === 'rfid' &&
                            <>
                                <label>{t("common.rfidnumber")}{" "}{t("common.required")}</label>
                                <TextField onKeyPress={(event) => notAllowSpace(event)} {...register("rfid", {required: true, maxLength: 255})}  placeholder={t("common.blinkcard")} variant="outlined"/>
                                {errors?.rfid && String(errors?.rfid?.type) === 'server' && <span className="error_msg">{errors?.rfid?.message}</span>}
                                {errors?.rfid && String(errors?.rfid?.type) !== 'server' && <span className="error_msg">{t("manageuser.rfid.required")}</span>}
                            </>
                        }
                    </Grid>
                </Grid>
            </div>
        </form>
    </>);

};

export default AddNewUserForm;