import React, {useState, useEffect} from "react";
import {ObjectDetails} from "../../../common/interfaces";
import '../fleetManagement.scss'
import FleetConstraintsEnergyLevel from "./fleetConstraintsEnergyLevels";
import FleetConstraintsSchedules from "./fleetConstraintsSchedules";
import { CircularProgress} from "@material-ui/core";
import {TABS} from "../fleetManagementHelper";
import { fetchFleetConstrainsData } from "services/fleetManagementService";

const FleetConstrains = (props: ObjectDetails) => {
    let initialValueEnergyLevels: ObjectDetails = {
        minimum: '',
        maximum: '',
        target: '',
    };
    const {groupId, activeTab, vehicleId, isGroupLoading} = props;
    const [energyLevelDetailsView, setEnergyLevelDetailsView] = useState<any>(initialValueEnergyLevels);
    const [scheduleView, setScheduleView] = useState<any>();
    const [spinnerOff, setSpinnerOff] = useState<boolean>(false);

    useEffect(() => {
        if ((activeTab === TABS.CONSTRAINTS || activeTab ===  TABS.DELEGATE_CONSTRAINTS) && props?.groupId !== '') {
            fetchFleetConstrainsDetails();
        }
    }, [activeTab, props?.groupId]);

    const fetchFleetConstrainsDetails = () => {
        setSpinnerOff(true);
        let params: ObjectDetails = {groupId: groupId};
        if(vehicleId) {
            params['vehicleId'] = vehicleId;
        }
        fetchFleetConstrainsData(params, false).then((res: any) => {
            if (res) {
                if (res.energy_constraints) {
                    let parseEnergyConstraints = JSON.parse(res.energy_constraints);
                    let energyConstraints = {
                        minimum: parseEnergyConstraints.min_energy,
                        maximum: parseEnergyConstraints.max_energy,
                        target: parseEnergyConstraints.target_energy
                    };
                    setEnergyLevelDetailsView(energyConstraints);
                }
                setScheduleView(res);
            }
            setSpinnerOff(false);
        }).catch((err: any) => {
            console.error("Error", err);
        });
    };

    return (
        <>
            <div className="constrains">
                {spinnerOff && (
                    <CircularProgress className="inner_progress_userGroup"/>
                )}
                <FleetConstraintsEnergyLevel vehicleId={vehicleId}
                                             groupId={groupId}
                                             energyLevelDetails={energyLevelDetailsView}
                                             setSpinnerOff={setSpinnerOff}
                                             fetchFleetConstrainsDetails={fetchFleetConstrainsDetails}
                                             isLoading={spinnerOff || isGroupLoading ? true : false}
                />
                <FleetConstraintsSchedules  vehicleId={vehicleId}
                                            groupId={groupId}
                                            scheduleDetails={scheduleView}
                                            setSpinnerOff={setSpinnerOff}
                                            fetchFleetConstrainsDetails={fetchFleetConstrainsDetails}
                />
            </div>
        </>
    )
};

export default FleetConstrains;
