import { DataGrid } from "@material-ui/data-grid";
import Images from "assets/images";
import { dateFormats, EDIT, GetUrlDetails, HandlePaginationLabel, keyCloakConfig, pageDetails, useStyles } from "common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HostMemberUserGroupList } from "pages/tableColumns/hostMemberRequestColumns";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import CommonPagination from "components/commonPagination";
import CommonDownloadCsv from "components/commonDownloadCsv";
import { FileHeaderMembershipUserGroupComponent } from "pages/hostMembershipRequest/hostMembershipHelper";

import { columnNameTranslate } from "common/methodHelpers";
import { fetchUserGroupGridList } from "services/membershipService";
import { useHistory } from "react-router-dom";
import moment from "moment";
import UserInfoAdd from "pages/userGroupDetail/userInfoAdd";
import UserInfoDelete from "pages/userGroupDetail/userInfoDelete";


const HostMembershipDetail = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    
    const userGroupListColumns = HostMemberUserGroupList();
    const pageSize = pageDetails.pageSize;
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState(false);
    const [membershipUserGroupDetails, setMembershipUserGroupDetails] = useState<any>({});
    const [userGroupListCsvData, setUserGroupListCsvData] = useState<any>([]);
    const FileHeaderMembershipUserGroup = FileHeaderMembershipUserGroupComponent();
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const [openAddNewUser, setAddnewUserOpen] = useState(false);
    const history = useHistory();
    let userGroupId = GetUrlDetails(history, 'userGroup');
    const [userDeleteOpen, setUserDeleteOpen] = useState(false);
    const [userId, setUserId] = useState(false);

    useEffect(() => {
        getUserGroupListDetails()
    }, [currentPage])

    const fileNameUserGroupMembership = `${membershipUserGroupDetails.userGroupName}_${moment().format(dateFormats.csvTimeFormat)}.csv`;

    const membershipRequestCsvDataSet = async () => {
        let params = { "hostId": hostId, "userGroupId": userGroupId['userGroup'] }
        await fetchUserGroupGridList(params, false, true).then((res: any) => {
            let membershipRequestCsvDatas = [];
            if (res.users.length > 0) {
                membershipRequestCsvDatas = res.users.map(
                    (val: any, index: any) => ({
                        ...val,
                    })
                );
            }
            setUserGroupListCsvData(membershipRequestCsvDatas);
        })
    };

    const handleCellClick = (params: any) => {
        if (params.field === EDIT) {
            setUserDeleteOpen(true);
            console.log(params)
            setUserId(params.row.id);
        }
    }

    const userDeleteEventClose = () => {
        setUserDeleteOpen(false);
    };

    const userDeleteSave = () => {
        userDeleteEventClose();
    }

    const getUserGroupListDetails = async () => {
        setLoadingPage(true);
        let params = { "currentPage": currentPage, "size": pageDetails.pageSize, "hostId": hostId, "userGroupId": userGroupId['userGroup'] }
        fetchUserGroupGridList(params, false).then((res: any) => {
            setLoadingPage(false);
            setMembershipUserGroupDetails(res)
        })
    }

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)
    }

    const MembershipDataSet = (value: any) => {
        let membershipTableData = []
        if (value && value.users) {
            membershipTableData = value.users.map((val: any, index: number) =>
            ({
                ...val,
            }))
        }
        return membershipTableData
    }

    const addnewUserOpen = () => {
        setAddnewUserOpen(true);
    };

    const addNewUserClose = () => {
        setAddnewUserOpen(false);
    };

    return (
        <>
            <UserInfoAdd
                openAddNewUser={openAddNewUser}
                addNewUserClose={addNewUserClose}
                refreshDetails={getUserGroupListDetails}
                isMembership = {true}
                hostId = {hostId}
            />
            <UserInfoDelete
                userDeleteEventOpen={userDeleteOpen}
                userDeleteEventClose={userDeleteEventClose}
                userId={userId}
                userDeleteSave={userDeleteSave}
                refreshDetails={getUserGroupListDetails}
                isMembership = {true}
                hostId = {hostId}
                />
            <main className={classes.content}>
                <div className="depot_details host_settings">
                    <div className="depot_detail_head position_fixed_head">
                        <div className="depot_information">
                            <div className="depot_location_map">
                                <img src={Images.shift_driver_group} alt="Port" />
                            </div>
                            <div className="depot_info">
                                <div className="depot_name">
                                    {membershipUserGroupDetails.userGroupName}
                                </div>
                                <div className="member_deails">
                                    <div className="member_info ">
                                        <div className="port_details">
                                            <span className="alert">
                                                <img
                                                    src={
                                                        Images.ic_driver_group
                                                    }
                                                    alt={t('common.users')}
                                                />
                                                {
                                                    membershipUserGroupDetails?.totalCount
                                                }{" "}
                                                {t("common.users")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button_row text_right">
                        <Button className="btn_white blue_bg m_w_157" onClick={addnewUserOpen}>
                            {t("usergroupdetail.adduser")}
                        </Button>
                    </div>
                    {loadingPage ? <CircularProgress className="inner_progress_userGroup" /> :
                        <div className="table_scroll mt-10">
                            <div className="default_table">
                                <DataGrid autoHeight className="table"
                                    rowHeight={59}
                                    headerHeight={30}
                                    rows={MembershipDataSet(membershipUserGroupDetails)}
                                    columns={columnNameTranslate(userGroupListColumns)}
                                    pageSize={pageSize}
                                    onCellClick = {handleCellClick}
                                    disableSelectionOnClick />
                            </div>
                            <div className="table_footer mt-10">
                                <Grid container direction="row" alignItems="center" spacing={0}>
                                    <Grid className="text_left" item xs={8}>
                                        {membershipUserGroupDetails && membershipUserGroupDetails?.totalCount > pageSize ?
                                            <CommonPagination
                                                currentPage={currentPage}
                                                totalCount={membershipUserGroupDetails?.totalCount}
                                                callBackCurrentPage={handleCurrentPage}
                                            /> : <></>}
                                        {membershipUserGroupDetails && membershipUserGroupDetails?.totalCount > 0 &&
                                            <CommonDownloadCsv
                                                datas={userGroupListCsvData}
                                                filename={fileNameUserGroupMembership}
                                                headers={FileHeaderMembershipUserGroup}
                                                blueclass={"border"}
                                                callBackDownloadCsv={membershipRequestCsvDataSet} />
                                        }
                                    </Grid>
                                    <Grid className="text_right" item xs={4}>
                                        <div className="list_number">
                                            {HandlePaginationLabel(membershipUserGroupDetails?.totalCount, pageSize, currentPage, "common.users")}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </>
    );
};

export default HostMembershipDetail;
