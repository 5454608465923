import {ListItem} from "@material-ui/core";
import {BLANK, BLANK_DATA_PLACEHOLDER} from "../../common";
import React from "react";
import {currencyAmountValidate} from "../../common/methodHelpers";
import { toLower } from "lodash";

const DEFAULT_PLUS = "PLUS";
const formatCheck = (val: any) => {return val <= 9 ? ('0' + val) : val;};
export const ONE_MINUTE = 60;
export const ONE_HOUR = 3600;

const addSecondsInTime = (value: any, translate: any) => {
    return typeof(value) === "string" ? value : convertSecondsToMinutesOrHours(value, translate);
};
const convertSecondsToMinutesOrHours = (value: any, translate: any) => {
    if( value >= ONE_MINUTE) {
        return (value/ONE_MINUTE) + BLANK + translate("common.minutes");
    } else if (value >= ONE_HOUR) {
        return (value/ONE_HOUR) + BLANK + translate("common.hour");
    } else {
        return value + BLANK + translate("common.seconds")
    }
}
const FindvariableType = (value: any) => {
    return typeof(value) === "string" ? parseInt(value) : value;
}

export const validateAmount = (amount:any, type?:any)=>{

    if( type === DEFAULT_PLUS){
        if(amount > 0){
            return "error_text"
        } else{
            return ""
        }
    } else {
        if(amount < 0){
            return "error_text"
        } else{
            return ""
        }
    }
};

export const convertSecondToTimeFormat = (sec: any): string => {
    let returnValue = '00:00:00';
    if (sec > 0) {
        const colunValue = ":";
        const hView = Math.floor(sec / 3600);
        const mView = Math.floor(sec % 3600 / 60);
        const sView = Math.floor(sec % 3600 % 60);
        const displayFormat = (formatCheck(hView) + colunValue + formatCheck(mView) + colunValue + formatCheck(sView));
        returnValue = displayFormat ? displayFormat : returnValue;
    }
    return returnValue;
};

export const formatTimeHHMMTo12Hour = (time: any) => {
    // Time should be HHMM Format; and check correct time format and split into components

    if (!time) {
        return '';
    } else {      
        time = time.toString().match(/^([01]\d|2[0-3])([0-5]\d)([0-5]\d)?$/) || [time];
        if (time.length > 1) {
            time = time.slice(1);
            time[6] = +time[0] < 12 ? 'AM' : 'PM'; /*--Set AM/PM --*/
            time[0] = +time[0] % 12 || 12;
            setPrefixForHour(time);
            if (time[2]) {
                time[4] = time[2];/*--assign 2nd position value to 4th in array. --*/
            }
            time[2] = time[1];
            time[1] = ":";
            if (time[4]) {/*--check whether 4th in array object set or not --*/
                time[3] = ":"
            }
            time[5] = ' '
        }
        return time.join(''); /*--return adjusted time or original string--*/
    }
}

const setPrefixForHour = (time:any) => {
    if(time[0] < 10) {
        time[0] = '0' + time[0];
    }
}

export const transactionInfo = (label: string, value: any, amount?: any, sabLabel?:any) => {
    return (
        <>
            <ListItem>
                <label className="label">{label}
                    {sabLabel && <div className="sab_label">{sabLabel}</div>}
                </label>
                <span className={`${validateAmount(amount)} ${sabLabel && 'sab_margin_top'}`} >{value || BLANK_DATA_PLACEHOLDER}</span>
            </ListItem>
        </>
    )
};

export const displayFeeAndUnitPrice = (sessionTransactionDetails: any, parking?: boolean, priceValue?: any, isLocationSubscribedToPricing: boolean = false, t?: any) => {
    let priceDetails = sessionTransactionDetails?.sessionPriceDetails;
    let priceSegments = parking ? priceDetails?.parkingPriceSegments[0] : priceDetails?.chargingPriceSegments[0];
    priceValue = priceValue ? priceValue : priceSegments;
    if (priceValue?.intervalRate >= 0 && priceValue?.unit) {
        if (isLocationSubscribedToPricing)
            return displayOccupancyAndChargingFee(priceValue.intervalRate, priceValue.unit, sessionTransactionDetails?.currencySymbol, t)
        else 
            return currencyAmountValidate(sessionTransactionDetails?.currencySymbol, priceValue.intervalRate ?? 0);
    } else {
        return BLANK_DATA_PLACEHOLDER;
    }
};

export const displayOccupancyAndChargingFee = (intervalRate:any, unit:any, currencySymbol:any, translate :any) => {
    let finalResult = 0;
    let currencyResult;
    if (toLower(unit) == "kwh") { /*--if price is energy based with unit as kwh--*/
        finalResult = intervalRate;
        currencyResult = currencyAmountValidate(currencySymbol, finalResult ?? 0);
        return currencyResult + "/" + translate("common.kwh");
    } else {/*--if price is time based with unit as seconds--*/
        let unitConvert = FindvariableType(unit) / 3600;
        finalResult = intervalRate / unitConvert;
        currencyResult = currencyAmountValidate(currencySymbol, finalResult ?? 0);
        return currencyResult + "/" + translate("common.hr");
    }
};

export const getUnitValue = (sessionTransactionDetails: any, translate: any, parking?: boolean, priceValue?: any) => {
   let priceDetails = sessionTransactionDetails?.sessionPriceDetails;
   let priceSegments = parking ? priceDetails?.parkingPriceSegments[0] : priceDetails?.chargingPriceSegments[0];
   priceValue = priceValue ? priceValue : priceSegments;
   return priceValue?.unit !== translate("common.kwh") ? addSecondsInTime(priceValue?.unit, translate) : priceValue?.unit;
}

