import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { showErrorMessages } from 'redux/actions/errorMessages';
import ConfirmDialogPopupComponent from "../confirmDialogPopupComponent";

const ErrorComponent = (props: any) => {
    const { errorOpen = false, message = "", type= "", operation= "" } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const closePopup = () => {
      if(operation) {
        operation();
      }
      dispatch(showErrorMessages({error: false, message: '', type:'', operation:''}))
    }

    const checkMessageType = (messageType:any) => {
        switch (messageType.toLowerCase()) {
            case 'success':
                return "common.success";
            case 'error':
                return "common.oops";
            default:
                return '';
        }
    };

    const checkMessageContent = (messageType:any, messageContent:any) => {
        switch (messageType.toLowerCase()) {
            case 'success':
                return messageContent ? messageContent : "common.success.message" ;
            case 'error':
                return messageContent ? messageContent : "common.something.went.wrong.errmsg";
            default:
                return messageContent ? messageContent : "common.something.went.wrong.errmsg";
        }
    };

    const fieldDetails: any = [{ id: 1, columnLabel: checkMessageContent(type, message), LabelFormat: false, columnVisible: true, classFormat: ''}];
    const cancelButtonAction = { isVisible: false, buttonName: 'common.cancelbutton', buttonPermssion: true , classFormat: ''};
    const okButtonAction = { isVisible: true, buttonName: 'common.ok', buttonPermssion: true , classFormat: '', disabled: false };


    return (
        <div className="dialog_box" data-testid="error_component">
            <ConfirmDialogPopupComponent
                popUpPageOpen={errorOpen}
                popUpPageClose={closePopup}
                headerName={checkMessageType(type)}
                fieldDetails={fieldDetails}
                submitAction={okButtonAction}
                cancelAction={cancelButtonAction}
                submitData={closePopup}
            />
        </div>
    )
};


export default ErrorComponent;