import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";
import configureStore from './redux/store';
import { keyCloakConfig } from 'common';
import { onKeycloakTokens } from 'services';

const { persistor, store } = configureStore();

keyCloakConfig.init({ onLoad: 'login-required' }).then((authenticated) => {
  
  if(!authenticated) {
    window.location.reload();
  } else {
    onKeycloakTokens(keyCloakConfig);
  }
  
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
  );

});
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
