import { Route, BrowserRouter, Switch } from "react-router-dom";
import { RoutesWithSidebar, ForbiddenAccess } from './routes';
import React, { useEffect, useState } from 'react';
import { AbilityContext } from 'common/Can';
import { buildAbilityFor, claimsDataSet, SetAbilities } from 'common/ability';
import './App.scss';
import 'locales/language';
import { ALLOWED_ACCESS_ROLES, keyCloakConfig } from "common";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginTime } from 'redux/actions/loginTime';
import { showErrorMessages } from "redux/actions/errorMessages";
import ErrorComponent from "components/errorComponent";
import { CircularProgress } from "@material-ui/core";
import { ApiEndPoint, getMethod } from "services";
import TermsConditions from './components/termsConditions';

function App() {

  const dispatch = useDispatch();
  const getClaims = claimsDataSet(keyCloakConfig);
  const abilities: SetAbilities = buildAbilityFor(getClaims);
  const errorMessages = useSelector((state: any) => state.errorMessages)
  const [isExpired,setIsExpired] = useState<any>(false);
  const [openTermsConditions, setTermsConditions] = useState(false);
  const [openServerError, setOpenServerError] = useState(false);
  const [loading, setLoading] = useState(false);

  if(!keyCloakConfig?.authenticated) {
    keyCloakConfig.logout();
    dispatch(fetchLoginTime(new Date()));
  }

  useEffect(()=>{
    setIsExpired(keyCloakConfig.isTokenExpired())
  },[keyCloakConfig])

  useEffect(() => {
    if(errorMessages?.error) {
      dispatch(showErrorMessages({error: false, message:"you are login", type:"", operation: ""}))
    }
}, [])

  useEffect(() => {
    getAgreementStatus();
  }, []);

  const getAgreementStatus = async () => {
    await getMethod(ApiEndPoint.agreementStatus, "")
      .then((res) => {
        if (res?.status && res?.data === false) {
          setTermsConditions(true);
          setLoading(false);
        } else if (res?.status !== 200 && ALLOWED_ACCESS_ROLES.filter(value => keyCloakConfig?.realmAccess?.roles.includes(value)).length > 0) {
          setOpenServerError(true);
        }
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  return (
    <>
    {loading && <CircularProgress  className="termsloader"/>}
    {!loading &&
    <div className="wrapper" data-testid="app">
      <TermsConditions 
        setTermsConditions  = {setTermsConditions}
        openTermsConditions = {openTermsConditions}
        setOpenServerError  = {setOpenServerError}
        openServerError     = {openServerError}
      />
      {errorMessages?.error && <ErrorComponent operation={errorMessages?.operation} errorOpen={errorMessages?.error} message={errorMessages?.message} type={errorMessages?.type}/>}
      <AbilityContext.Provider value={abilities}>
        <BrowserRouter>
          <Switch>
            <Route path='/' component={ (ALLOWED_ACCESS_ROLES.filter(value => keyCloakConfig?.realmAccess?.roles.includes(value)).length > 0 && isExpired === false) ? RoutesWithSidebar : ()=>ForbiddenAccess(isExpired) } />
          </Switch>
        </BrowserRouter>
      </AbilityContext.Provider>
    </div >}
    </>
  );
}

export default App;
