import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, makeStyles, Menu, MenuItem, IconButton, AppBar, Toolbar, Breadcrumbs, Typography, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useSelector, useDispatch } from 'react-redux'
import { commonRoutes, GetUrlDetails, ALLOWED_ACCESS_ROLES, CheckAccess, claims, getFirstLetterColor, keyCloakConfig} from 'common';
import Images from '../../assets/images';
import './header.scss';
import { useTranslation } from "react-i18next";
import { startCase, toLower, capitalize } from 'lodash';
import { fetchLoginTime } from 'redux/actions/loginTime';
import UIBreadcrumbs from "../breadCrumbs/breadCrumbs";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  

  inputRoot: {
    color: 'inherit',
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const depotData = useSelector((state:any) => { return state?.depotData?.depotDetails ? state.depotData.depotDetails : {} }) 
  const vehicleData = useSelector((state:any) => { return state?.vehicleData?.vehDetails ? state.vehicleData.vehDetails : {} }) 
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);  
  const currentFleetName = null;
  const currentLocationName = null;
  const [currentDepotName , setCurrentDepotName] = React.useState(null);
  const [currentVehicleName , setCurrentVehicleName] = React.useState(null);
  const [breadCrumbsName , setBreadCrumbsName] = React.useState<any>(null);
  const [breadCrumbsLast , setBreadCrumbsLast] = React.useState<any>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { pathname, search } = GetUrlDetails(history, 'header');
  const tabLangName = '';
  const userInfo = keyCloakConfig?.tokenParsed;
  const firstChar = capitalize(userInfo?.given_name?.slice(0,1));

  useEffect(() => {
    setCurrentDepotName(depotData?.evsedepotName)
  }, [depotData])

  useEffect(() => {
    setCurrentVehicleName(vehicleData?.name)
  }, [vehicleData])

  useEffect(() => {
    handleBreadCrumbs()
  })

  const handleProfileMenuOpen = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleChangePassword = () => {
    handleMenuClose();
    history.push('/profile')
  }

  const handleLogOut = () => {
    sessionStorage.clear();
    keyCloakConfig.logout({ redirectUri: window.location.origin });
    dispatch(fetchLoginTime(new Date()));
  }

  const handleMobileMenuOpen = (event:any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const createLink = (href:string, span:string | null, spanClass?:string) => {

    if(span === 'Users'){
      href = 'user';
    }

    if(href !== null && href !== '' && search !== null && search !== '' && search !== undefined){
      href = href + search;
    }

    return (
            <Link color="inherit" href={href}>
              <span className={spanClass}>{span}</span>
            </Link>
    ) 
  };

  const handleBreadCrumbs = () => {
    let bCrumbsName = null;

    switch (pathname !== null) {
      case (pathname === commonRoutes.locationListRoute):
        bCrumbsName = t("common.evse_locations");
      break;  
      case (commonRoutes.depotAllRoutes.includes(pathname)):
        bCrumbsName = t("common.evseDepotDetails");
        setBreadCrumbsName(currentDepotName);
      break; 
      case (pathname === commonRoutes.vehicleListRoute):
        bCrumbsName = t("dashboard.card.evsevehicles");
      break;
      case (commonRoutes.vehicleAllRoutes.includes(pathname)):
        bCrumbsName = t("common.vehicleDetails");
        setBreadCrumbsName(currentVehicleName);
      break; 
      case (pathname === commonRoutes.manageUserRoute):
        bCrumbsName = t("common.group");
      break;
      case (pathname === commonRoutes.userRoute):
        bCrumbsName = t("common.users");
      break;
      case (commonRoutes.userAllRoutes.includes(pathname)):
        bCrumbsName = t("common.userDetails");
        setBreadCrumbsName('Users');
      break;
      case (pathname === commonRoutes.messageRoute):
        bCrumbsName = t("common.messages");
      break;
      case (commonRoutes.hostSettingsAllRoutes.includes(pathname)):
        bCrumbsName = t("common.hostsettings");
      break;
      case (commonRoutes.fleetAllRoutes.includes(pathname)):
        bCrumbsName = t("fleet");
        setBreadCrumbsName('Fleet');
      break;
      default:
        setBreadCrumbsName(null);        
    }        

    setBreadCrumbsLast(bCrumbsName);    
  };
  
  const getUserRole = (access:any) => {
      if(access){
        return ALLOWED_ACCESS_ROLES.filter(value => access?.roles?.includes(value))?.toString();
      }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      className="user_menu_open"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
        <MenuItem className="user_menu_item" >
              <div className="user_detail_row">
                  <div className="user_detail_row_inside">
                      <div className="user_img">
                      <span className={`user_start_name ${ firstChar ? getFirstLetterColor(firstChar) : 'green'}`}>{firstChar}</span>
                      <img className="hide" src={Images.IC_USER} alt={t("common.users")} />
                      </div>
                      <div className="user_info">
                          <div className="user_name">
                              {capitalize(toLower(userInfo?.given_name))} {capitalize(toLower(userInfo?.family_name))}
                              <span>{startCase(toLower(getUserRole(userInfo?.realm_access)))}</span>
                          </div>
                         
                      </div>
                  </div>
              </div>
          </MenuItem>
          <MenuItem className="user_menu_item" onClick={handleChangePassword}>
              <div className="user_detail_row">
                  <div className="link_list">
                      <img className="view" src={Images.ic_password_mark} alt={t("common.password")} />
                      <img className="hover" src={Images.ic_password_hover} alt={t("common.password")} />
                      {t("common.changepassword")}
                  </div>
              </div>
          </MenuItem>
          <MenuItem className="user_menu_item" onClick={handleMenuClose}>
              <div className="user_detail_row">
                  <div className="link_list" onClick={handleLogOut}>
                          <img className="view" src={Images.ic_sign_out} alt=""/>
                          <img className="hover" src={Images.ic_sign_out_hover} alt=""/>
                          {t("common.signout")}
                  </div>
              </div>
          </MenuItem>
      
     
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
    className="user_menu_open"
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
  <MenuItem className="user_menu_item" >
              <div className="user_detail_row">
                  <div className="user_detail_row_inside">
                      <div className="user_img">
                      <span className={`user_start_name ${ firstChar ? getFirstLetterColor(firstChar) : 'green'}`}>{firstChar}</span>
                      <img className="hide" src={Images.IC_USER} alt={t("common.users")} />
                      </div>
                      <div className="user_info">
                          <div className="user_name">
                              {capitalize(toLower(userInfo?.given_name))} {capitalize(toLower(userInfo?.family_name))}
                              <span>{startCase(toLower(getUserRole(userInfo?.realm_access)))}</span>
                          </div>
                         
                      </div>
                  </div>
              </div>
          </MenuItem>
          <MenuItem className="user_menu_item" onClick={handleChangePassword}>
              <div className="user_detail_row">
                  <div className="link_list">
                      <img className="view" src={Images.ic_password_mark} alt={t("common.password")} />
                      <img className="hover" src={Images.ic_password_hover} alt={t("common.password")} />
                      {t("common.changepassword")}
                  </div>
              </div>
          </MenuItem>
          <MenuItem className="user_menu_item" onClick={handleMenuClose}>
              <div className="user_detail_row">
                  <div className="link_list" onClick={handleLogOut}>
                          <img className="view" src={Images.ic_sign_out} alt=""/>
                          <img className="hover" src={Images.ic_sign_out_hover} alt=""/>
                          {t("common.signout")}
                  </div>
              </div>
          </MenuItem>
      
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className="top_header">
        <Toolbar className="header_inner">
        { history?.location?.pathname  !== commonRoutes.fleetDashboardRoute &&
          <div className="inside_page_head">
            <IconButton className="back-arrow" onClick={() => history.goBack()}>
              <img src={Images.ic_back_arrow} alt="back" />
            </IconButton>
            <UIBreadcrumbs/>
            { CheckAccess([claims.FORBIDDEN_ACCESS]) &&
              <div className="header_breadcrum">
                <b></b>
                <Breadcrumbs className="header_breadcrum_inside" aria-label="breadcrumb">

                  {createLink('/', t("header.home"), 'material-icons')}
                  {createLink('evseLocations', currentFleetName)}
                  {currentLocationName &&
                    createLink(pathname, currentLocationName)
                  }
                  {breadCrumbsName &&
                    createLink('', breadCrumbsName)
                  }
                  {tabLangName && breadCrumbsLast &&
                    createLink('', breadCrumbsLast)
                  }
                  <Typography color="textPrimary"> <span>{tabLangName ? t(tabLangName) : breadCrumbsLast}</span></Typography>
                </Breadcrumbs>
              </div>
            }
          </div> 
        }
        <img className="dashborad_logo" src={Images.LOGO} alt="logo" />
       
          <div className={classes.grow} />
          <div className="search_box hide">
            <div className="searchIcon">
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
         
          <div className={classes.sectionDesktop}>
            <IconButton className="top_action_btn"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            ><span className={`user_start_name topuser ${ firstChar ? getFirstLetterColor(firstChar) : 'green'}`}>{firstChar}</span>
              <img className="hide" src={Images.IC_USER} alt="user_icon" />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default Header;