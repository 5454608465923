import React from "react";
import { Grid, Card, CardContent, IconButton } from "@material-ui/core";
import Images from "assets/images";
import { BLANK_DATA_PLACEHOLDER, CheckAccess, claims } from "common";
import { InfoListView } from "common/interfaces/manageuser";
import DataGridCellExpand from "components/dataGridCellExpand";
import { toUpper } from "lodash";
import { useTranslation } from "react-i18next";

const UserInfoListView = (props: InfoListView) => {
    const { t } = useTranslation();

    const EdituserOpen = () => {
        props?.manageUserEditEvent(props)
    };
    const deleteUserOpen = () => {
        props?.manageUserDeleteEvent(props)
    };  

    return (
        <>
            {/* List View Start */}
            <div className="information_section delegates_details" data-testid="user_info_list_view">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            variant="outlined"
                            className="basic_card"
                        >
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className="info_detail">
                                            <div className="tb_info_section mt-0">
                                                <div className="label">
                                                    {toUpper(
                                                        t(
                                                            "common.username"
                                                        )
                                                    )}
                                                </div>
                                                <div className="result mt-5">
                                                    {props?.manageUserInfoListView?.userName}
                                                </div>
                                            </div>
                                            <div className="tb_info_section">
                                                <div className="label">
                                                    {toUpper(
                                                        t(
                                                            "common.rfidnumber"
                                                        )
                                                    )}
                                                </div>
                                                <div className="result mt-5">
                                                <DataGridCellExpand
                                                    value={props?.manageUserInfoListView?.rfids ? props?.manageUserInfoListView?.rfids.replaceAll(',', ', ') : BLANK_DATA_PLACEHOLDER}
                                                    width={450}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div className="info_detail">
                                            <div className="tb_info_section mt-0">
                                                <div className="label">
                                                    {toUpper(
                                                        t(
                                                            "common.contact"
                                                        )
                                                    )}
                                                </div>
                                                <div className="result">
                                                    <span className="label">
                                                        {t(
                                                            "common.email"
                                                        )}
                                                    </span>
                                                    <span className="result">
                                                        {props?.manageUserInfoListView?.email}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="info_detail">
                                            <div className="tb_info_section mt-0">
                                                <div className="label">
                                                    {toUpper(
                                                        t(
                                                            "common.status"
                                                        )
                                                    )}
                                                </div>
                                                <div className="result mt-5">
                                                    {props?.manageUserInfoListView?.status}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div className="title_row">
                                            { CheckAccess(claims.FORBIDDEN_ACCESS) &&
                                                <div className="action_icon">
                                                    <IconButton
                                                        className="top_action_btn"
                                                        onClick={EdituserOpen}
                                                    >
                                                        <img
                                                            src={
                                                                Images.ic_edit
                                                            }
                                                            alt="edit"
                                                        />
                                                    </IconButton>
                                                </div>
                                            }
                                            <div className="action_icon">
                                                <IconButton
                                                    className="top_action_btn"
                                                    onClick={deleteUserOpen}
                                                >
                                                    <img
                                                        src={
                                                            Images.ic_close
                                                        }
                                                        alt="delete"
                                                    />
                                                </IconButton>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            {/* List View End */}
        </>
    );
};
export default UserInfoListView;
