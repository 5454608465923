import React from "react";
import {
    Select,
    Grid,
    Button,
    DialogContentText,
    DialogContent,
    Dialog,
    TextField,
    List,
    ListItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LabelInfo } from "common";

const ManageBlinkCard = (props: any) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="dialog_box">
                <Dialog className="dialog_container edit_detail_section edit-user-info updated_pop_up sm dialog_container_center"
                    {...props}
                    open={props.openManageBlinkCard}
                    onClose={props.manageBlinkCardClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                    <form>
                        <div className="dialog_title">
                            <h4>{t("common.manageblinkcard")}</h4>
                            <div className="button_row mt-0 full_right">
                                <Button className="btn_white text_btn_theme" onClick={props.manageBlinkCardClose}> {t("common.cancelbutton")} </Button>
                                <Button type="submit" className="btn_white blue_bg"> {t("common.savechanges")} </Button>
                            </div>
                        </div>
                        <DialogContent>
                            <DialogContentText id="scroll-dialog-description">
                                <div className="defult_form mt-10">
                                    <div className="section_group">
                                        <List component="nav" className="card_listing_detail">
                                            <ListItem><span>{t("common.membernumber")}</span>001451836</ListItem>
                                            <ListItem><span>{t("common.accountnumber")}</span>043531D2B72080</ListItem>
                                        </List>
                                    </div>
                                    <div className="section_group mt-10">
                                        <List component="nav" className="card_listing_detail">
                                            <ListItem><span>{t("common.activatedtime")}</span>2022-07-04; 11:04:10 MST</ListItem>
                                            <ListItem><span>{t("common.assignedid")}</span>630</ListItem>
                                            <ListItem><span>{t("common.assignedemail")}</span>yudaskasanoja@email.com</ListItem>
                                        </List>
                                    </div>
                                    <div className="section_group">
                                        <div className="form_col mt-20">
                                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={6}>
                                                    <LabelInfo name={t("common.cardname")} isMandatory={false} />
                                                    <TextField variant="outlined" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LabelInfo name={t("common.cardtype")} isMandatory={false} />
                                                    <Select variant="outlined"></Select>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="form_col">
                                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={6}>
                                                    <div className="form_col">
                                                        <LabelInfo name={t("common.cardstatus")} isMandatory={false} />
                                                        <Select variant="outlined"></Select>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>

        </>
    );
};
export default ManageBlinkCard;