import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Images from '../../assets/images';
import Header from '../header';
import { sidebarData, commonRoutes, sidebarImages, menuList, defaultVariable, rolePermissionCheck, keyCloakConfig} from 'common';
import {NavLink, useHistory} from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import './sidebarMenu.scss';
import { CheckAccess } from 'common/abilityHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginTime } from 'redux/actions/loginTime';
import { fetchOrganizationDetails} from 'services/membershipService';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: sidebarData.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarData.drawerWidth,
    },

    drawerOpen: {
        width: sidebarData.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },


    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
}));

const SidebarMenu = (props:any) => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    //@ts-ignore
    const manageUsersEnabled = useSelector((state) => state?.sidebarItems?.manageUsersEnabled);
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const dashboardRoute = commonRoutes.fleetDashboardRoute
    const depotRoute = commonRoutes.depotAllRoutes;
    const driverRoute = commonRoutes.userAllRoutes;
    const blinkCardRoutes = commonRoutes.blinkCardAllRoutes;
    const messageRoutes = commonRoutes.messageAllRoutes;
    const hostSettingRoutes = commonRoutes.hostSettingsAllRoutes;
    const trainingRoutes = commonRoutes.trainingAllRoutes;
    const [opend, setOpend] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const linkStyle = { textDecoration: 'none' };
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const fleetRoutes = commonRoutes.fleetAllRoutes;

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchLoginTime());
        if(hostId) {
            fetchOrganizationDetails(hostId);
        }
    }, []);

    useEffect(() => {
        if(history?.location?.pathname){
            selectedRoute()
        }

    }, [history?.location?.pathname]);

    const handleDrawerOpen = () => {
        setOpend(true);
    };

    const handleDrawerClose = () => {
        setOpend(false);
    };

    const selectedRoute = () => {
        if(history?.location?.pathname){
            if(dashboardRoute === history?.location?.pathname){
                setSelectedIndex(0)
            }else if(depotRoute.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(1)
            }else if ( driverRoute.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(2)
            }else if ( blinkCardRoutes.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(3)
            }else if ( messageRoutes.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(4)
            }else if ( fleetRoutes.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(5)
            }else if ( hostSettingRoutes.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(6)
            }else if ( trainingRoutes.filter((val: any) => val === history?.location?.pathname).length >= 1){
                setSelectedIndex(7)
            }
        }
    };

    const checkDelegateAndSettings = (isDelegate: boolean, item: string) => {
        return !(item === "settings" && isDelegate);
    }

    const displayMenuOption = (value: any, isDelegate: boolean) => {
        let checkAccess = CheckAccess(value?.accessLevel) && checkDelegateAndSettings(isDelegate, value?.name)
        let enableMenu = false;
        if(value?.route === 'fleetRoutes'){
            let isFleetSubscribed = sessionStorage.getItem('fleetSubscribtion');
            checkAccess = (isFleetSubscribed === "true");
        }
        if (checkAccess) {
            enableMenu = (value?.route === 'manageUserRoute') ? manageUsersEnabled : true;
        }
        return enableMenu;
    }

    return (
        <div data-testid="sidebar_menu" className={opend ? "drawer_open" : "drawer_close"}>
            <CssBaseline />
            <Header />

            <div className="left_sidenavbar">
                <Drawer
                    variant="permanent"

                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: opend,
                        [classes.drawerClose]: !opend,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: opend,
                            [classes.drawerClose]: !opend,
                        }),
                    }}
                    anchor="left">
                    <div className="sidbar_logo">
                        <a href="/">
                            <img src={Images.LOGO} alt="logo" />
                        </a>
                        <Icon className="sidebar_open" onClick={handleDrawerClose}>
                            <img src={Images.ic_sidebar_open} alt="sidbar_open" />
                        </Icon>

                        <Icon className="sidebar_close">
                            <img {...props} onClick={handleDrawerOpen}
                                 edge="start"

                                 src={Images.ic_sidebar_close} alt="sidbar_close" />
                        </Icon>
                    </div>

                    <List className="menu_list" >
                        {menuList?.map((value: any, index: any) => displayMenuOption(value, isDelegate) && (                          
                            <NavLink to={commonRoutes[value.route]} style={linkStyle} key={value?.route}>
                                <ListItem key={value?.name} className={`menu_list_item ${selectedIndex === index ? 'active' : '' }`} button>
                                    <ListItemIcon className="nav_icon">
                                        <img className="img_ic" src={sidebarImages[value.menuImage]} alt={value.menuItem} />
                                        <img className="img_hover" src={sidebarImages[value.ImageHover]} alt={value.menuItem} />
                                    </ListItemIcon>
                                    <ListItemText primary={value.menuItem} />
                                </ListItem>
                            </NavLink>
                        ))}
                    </List>
                </Drawer>
            </div>
        </div>
    )
}

export default SidebarMenu