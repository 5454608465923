import {urlReplace} from "common";
import {UserDeleteInfo} from "common/interfaces/manageuser";
import {REMOVE_STATUS} from "pages/hostMembershipRequest/hostMembershipHelper";
import React, {useState} from "react";
import {ApiEndPoint, deleteMethod} from "services";
import {deleteMembershipUserGroup} from "services/membershipService";
import ConfirmDialogPopupComponent from "../../../components/confirmDialogPopupComponent";


const UserInfoDelete = (props: UserDeleteInfo) => {

    const [isLoader, setIsLoader] = useState(false);
    const fieldDetails: any = [{id: 1, columnLabel: "common.userGroupDeleteMsg", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const cancelButtonAction = {isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true, classFormat: ''};
    const removeButtonAction = {isVisible: true, buttonName: 'common.deletebutton', buttonPermssion: true, classFormat: 'red_bg', disabled: false };

    const deleteInfoUser = async () => {
        let paramDetails: any;
        if (props.isMembership) {
            deleteUserForMembership();
            return;
        }

        if (props?.manageUserInfoEditData?.rfids && props?.manageUserInfoEditData?.email) {
            paramDetails = '?email=' + encodeURIComponent(props?.manageUserInfoEditData?.email) + '&rfidNumbers=' + props?.manageUserInfoEditData?.rfids;
        } else if (props?.manageUserInfoEditData?.email) {
            paramDetails = '?email=' + encodeURIComponent(props?.manageUserInfoEditData?.email);
        } else {
            paramDetails = '?rfidNumbers=' + props?.manageUserInfoEditData?.rfids;
        }

        const url = urlReplace(ApiEndPoint.groupUsers, "{USERGROUP-ID}", props?.userGroupId);

        await deleteMethod(url, paramDetails, false)
            .then((res) => {
                props.userDeleteSave(true);
                props?.refreshDetails();
            })
            .catch((err) => {
                console.error(err);
            });

    };

    const deleteUserForMembership = () => {
        let data = {"status": REMOVE_STATUS, "user_id": props.userId, "host_id": props.hostId}
        setIsLoader(true);
        deleteMembershipUserGroup(data,).then((res: any) => {
            if (res && res?.status === true) {
                setIsLoader(false);
                props?.userDeleteSave(true);
                props?.refreshDetails();
            }
        }).catch((err: any) => {
            console.error("Error", err)
        })
    };

    return (
        <>
            {/* Dialog Delete Start */}
            <div className="dialog_box" data-testid="user_info_delete_dialog">
                <ConfirmDialogPopupComponent
                    popUpPageOpen={props.userDeleteEventOpen}
                    popUpPageClose={props.userDeleteEventClose}
                    headerName={"common.deleteuser"}
                    fieldDetails={fieldDetails}
                    submitAction={removeButtonAction}
                    cancelAction={cancelButtonAction}
                    loading={isLoader}
                    submitData={deleteInfoUser}
                />
            </div>
            {/* Dialog Delete End */}
        </>
    );
};
export default UserInfoDelete;
