import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getMethod, ApiEndPoint } from "services";
import { pageDetails } from 'common';
import UserGroupCreate from "./userGroupCreate";
import UserGroupUpdate from "./userGroupUpdate";
import UserGroupInfo from "./userGroupInfo";
import UserGroupList from "./userGroupsList";

const UserGroupTab = (props: any) => {
    const { t } = useTranslation();
    const pageSize = pageDetails.pageSize;
    const [editUserGroup, setEditGroupOpen] = useState(false);
    const [openCreateNewGroup, setCreateNewGroupOpen] = useState(false);
    const [currentPage, ] = useState<any>(0);
    const [userGroup, setUserGroup] = useState<any>();
    const [userGroupRowData, setUserGroupRowData] = useState<any>();
    const [userGroupId, setUserGroupId] = useState<boolean>(true);
    const [userGroupList, setUserGroupList] = useState<boolean>(false);
    const [, setSpinnerOff] = useState<boolean>(true);

    const editGroupClose = () => {
        setUserGroupRowData({});
        getUserGroup()
        setEditGroupOpen(false);
    };

    const createNewGroupOpen = () => {
        setCreateNewGroupOpen(true);
    };
    const createNewGroupClose = () => {
        setCreateNewGroupOpen(false);
        getUserGroup();
    };

    useEffect(() => {
        getUserGroup()
    }, [currentPage])

    const getUserGroup = async () => {
        setSpinnerOff(true)
        const paramDetails = '?page=' + currentPage + '&size=' + pageSize;

        await getMethod(ApiEndPoint.evseLocationUserGroup, paramDetails)
            .then((res) => {
                setUserGroup(res.data);
                setSpinnerOff(false)
            })
            .catch((err) => {
                console.error("Error", err);
                setSpinnerOff(false)
            });
    }

    const userGroupOpenInfo = (data: any) => {
        setUserGroupId(data);
        setUserGroupList(true)
    }

    const showUserList = (data: any) => {
        setUserGroupList(false)
        getUserGroup()
    } 
    return (
        <>
            <UserGroupCreate openCreateNewGroup={openCreateNewGroup} createNewGroupClose={createNewGroupClose} />

            <UserGroupUpdate userGroupRowData={userGroupRowData} editUserGroup={editUserGroup} editGroupClose={editGroupClose} />

            {userGroupList && <UserGroupInfo showUserList={showUserList} userGroupId={userGroupId} />}


            {userGroup && userGroupList === false &&
                <>
                    <div className="button_row text_right">
                        <Button
                            className="btn_white blue_bg ml_0"
                            onClick={createNewGroupOpen}
                        >
                            {t("user.createnewusergroup")}
                        </Button>
                    </div>
                    <UserGroupList userGroupId={userGroupOpenInfo} userGroup={userGroup}/>
                </>
            }            
        </>
    );
}
export default UserGroupTab
