import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    Select,
    MenuItem,
    LinearProgress
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { AddGroup } from "common/interfaces/fleet";
import { checkGroupExist, manageFleetData, fetchLocationList, fetchZoneList } from "services/fleetManagementService";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import { NEXTBTN, SAVEBTN, ADD_FLEET, UPDATE_FLEET } from "pages/fleet/fleetManagementHelper";
import {CheckAccess, claims, defaultVariable, keyCloakConfig, rolePermissionCheck} from "common";
import DeleteFleet from "../deleteFleet";
import "../../fleetManagement.scss";

const AddFleetGroup = (props: AddGroup) => {
    let initialValue = {
        locationId: "",
        zoneId: "",
        fleetGroup: "",
    };
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const [, forceUpdate] = useState<any>();
    const simpleValidator: any = useRef(new SimpleReactValidator());
    const { groupInfo, edit } = props;
    
    const [duplicateGroup, setDuplicateGroup] = useState(false);
    const [groupDetails, setGroupDetails] = useState<any>(initialValue);
  
    const [locationsList, setLocationsList] = useState<any>([]);
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>(" ");
    const [disableNxtBtn, setNxtDisable] = useState(true);
    const [disableSaveBtn, setSaveDisable] = useState(true);
    const [openDeleteFleet, setDeleteFleetOpen] = useState(false);
    const showGroupRemoveButton = CheckAccess([claims.FLEET_REMOVE]);
    const [disableZone, setDisableZone] = useState(true);
    const [zoneList, setZoneList] = useState<any>([]);
    const [selectedZone, setSelectedZone] = useState<any>(' ');
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const showGroupDeleteButton = isDelegate ? false : CheckAccess([claims.FLEET_REMOVE]);


    useEffect(() => {
        if (groupInfo && edit) {
            setGroupDetails(groupInfo);
            setSelectedLocation(groupInfo?.locationId);
            setSelectedZone(groupInfo?.zoneId);
            setNxtDisable(false);
            setSaveDisable(false);
        }
        if (props.addGroupModalOne) {
            getLocationDetails();
        }
    }, [groupInfo]);

    const resetFormData = () => {
        setGroupDetails(groupInfo);
        simpleValidator.current.hideMessages();
        forceUpdate(0);
        if (!edit) {
            setNxtDisable(true);
            setSaveDisable(true);
        }
    };

    const handleChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        setGroupDetails({ ...groupDetails, [name]: value });
        if (name === "locationId") {
            setSelectedLocation(value);
            getZoneDetails(value);

        }else if (name === "fleetGroup" && duplicateGroup) {
            setDuplicateGroup(false);
        }else if (name === "zoneId") {
            setSelectedZone(value);
        }
        setGroupDetails((prevState: any) => {
            if (prevState.locationId !== "" && prevState.fleetGroup !== "") {
                setSaveDisable(false);
            }
            return prevState;
        });
    };

    const checkDisabled = (type: any) => {
        let typeButton = NEXTBTN ? disableNxtBtn : disableSaveBtn;
        return (type ===  typeButton) ? "disable" : "blue_bg";
    };

    const addGroupModalOneClose = () => {
        props.addGroupModalOneClose();
        resetFormData();
        setDuplicateGroup(false);
        setSelectedLocation(" ");
        setZoneList([]);
        setSelectedZone(' ');
        setDisableZone(true);
    };

    const deleteFleetClose = () => {
        setDeleteFleetOpen(false);
    };

    const deleteFleetOpen = () => {
        setDeleteFleetOpen(true);
    };

    const checkGroupExists = () => {
        if (
            edit
                ? simpleValidator.current.fields["fleetName"] === true
                : simpleValidator.current.fields["fleetName"] === true &&
                  simpleValidator.current.fields["locationId"] === true
        ) {
            if (
                groupInfo?.fleetGroup === groupDetails?.fleetGroup &&
                props.edit
            ) {
                createFleetGroup();
            } else {
                setSpinnerOff(true);
                let paramObj = {
                    locationId: groupDetails?.locationId,
                    fleetGroup: groupDetails?.fleetGroup,
                };

                checkGroupExist(paramObj).then((res: any) => {
                    if (res?.is_exist === true) {
                        setDuplicateGroup(true);
                    } else {
                        createFleetGroup();
                    }
                    setSpinnerOff(false);
                });
            }
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const createFleetGroup = () => {
        let valid = simpleValidator.current.allValid();
        if (valid) {
            let editObj = {
                locationId: groupDetails?.locationId,
                zoneId: groupDetails?.zoneId,
                hostId: props.hostId,
                fleetGroup: groupDetails?.fleetGroup,
                id: props?.groupId,
            };
            setSpinnerOff(true);
            if (!props.edit) {
                Object.assign(groupDetails, { hostId: props.hostId });
                Object.assign(groupDetails, { zoneId: selectedZone?.trim() });
            }
            let body = props.edit ? editObj : groupDetails;
            let params = {
                action: props.edit ?  UPDATE_FLEET : ADD_FLEET,
            };

            manageFleetData(params, body).then((res: any) => {
                if (res.status) {
                    addGroupModalOneClose();
                    props.groupInfoRefresh();
                    setSpinnerOff(false);
                } else {
                    setSpinnerOff(false);
                    dispatch(
                        showErrorMessages({
                            error: true,
                            message: res.message,
                            type: "error",
                            operation: "",
                        })
                    );
                }
            }).catch((err: any) => {
                setSpinnerOff(false);
                console.error("Error", err);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const validateError = (fieldName: any) => {
        if (
            simpleValidator.current.fields[fieldName] === false &&
            simpleValidator.current.messagesShown === true
        ) {
            return "error-show";
        } else {
            return "";
        }
    };

    const getLocationDetails = () => {
        let params = { hostId: props.hostId };
        setSpinnerOff(true);
        fetchLocationList(params, false)
            .then((res: any) => {
                if (res.length > 0) {
                    setLocationsList(res);
                }
                setSpinnerOff(false);
            }).catch((err: any) => {
                setSpinnerOff(false);
                console.error("Error", err);
            });
    };

    const getZoneDetails = (location: any) => {
        if (location !== "") {
            setSpinnerOff(true);
            let params = {locationId: location};
            fetchZoneList(params, false)
                .then((res: any) => {
                    if (res && res.length > 0) {
                        setSelectedZone(' ');
                        setZoneList(res);
                        setDisableZone(false);
                    } else {
                        setSelectedZone(' ');
                        setDisableZone(true);
                    }
                    setSpinnerOff(false);
                })
                .catch((err: any) => {
                    setSpinnerOff(false);
                    console.error("Error", err);
                });
        }
    };

    return (
        <>
            {/*  Dialog for create New Fleet Group  */}
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info sm dialog_container_center"
                    open={props.addGroupModalOne}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                     <div className="dialog_title">
                        <h4>
                            {props.edit ? t("fleet.group.edit_group") : t("fleet.group.new_group")}
                        </h4>
                        <div className="button_row mt-0 full_right">
                            <Button className="btn_white text_btn_theme" onClick={addGroupModalOneClose}>
                                {t("common.cancelbutton")}
                            </Button>
                            <Button className={`btn_white ${checkDisabled(SAVEBTN)}`} onClick={checkGroupExists} disabled={disableSaveBtn}>
                                {props.edit ? t("common.savechanges") : t("common.save")}
                            </Button>
                        </div>
                    </div>
                    {spinnerOff && <LinearProgress />}
                    <DialogContent>
                         <DialogContentText id="scroll-dialog-description">
                            <div className="information_section delegates_details">
                                <div className="defult_form mt-25">
                                    <div className="section_group">
                                        <div className="custom_header_margin">
                                            <h3>
                                                <span className="custom_sub_header">
                                                    {t("fleet.groupdetail")}
                                                </span>
                                            </h3>
                                        </div>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={6}>
                                                            <label>
                                                                {t("fleet.group")}{" "}
                                                                {t("common.required")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <div className={`form_col-6 ${validateError("fleetName")}`}>
                                                                <TextField
                                                                    className="w_100per full_w"
                                                                    variant="outlined"
                                                                    value={groupDetails?.fleetGroup}
                                                                    onChange={handleChange}
                                                                    name="fleetGroup"
                                                                    placeholder={t("fleet.group.description")}
                                                                />
                                                                {duplicateGroup ? (
                                                                    <div className="srv-validation-message">{t("fleet.duplicategroup")}</div>
                                                                ):(
                                                                    <cite className="vg error_msg error_position">
                                                                        {simpleValidator.current.message("fleetName", groupDetails?.fleetGroup, "required")}
                                                                    </cite>)}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={6}>
                                                            <label className="w_100per full_w">
                                                                {t("fleet.location")}{" "}
                                                                {t("common.required")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <div hidden={edit}
                                                                className={`form_col-6 ${validateError("locationId")}`}
                                                            >
                                                                <Select
                                                                    className="w_100per full_w"
                                                                    labelId="demo-simple-select-label"
                                                                    variant="outlined"
                                                                    name="locationId"
                                                                    disabled={props.edit}
                                                                    value={selectedLocation}
                                                                    onChange={handleChange}
                                                                >
                                                                    <MenuItem
                                                                        className="defult_select_option"
                                                                        key={0}
                                                                        value=" "
                                                                        disabled
                                                                    >
                                                                        {t("fleet.selectLocation")}
                                                                    </MenuItem>
                                                                    {locationsList &&
                                                                        locationsList.map(
                                                                            (value: any) => (
                                                                                <MenuItem className="defult_select_option"
                                                                                    key={value?.id}
                                                                                    value={value?.id}
                                                                                >
                                                                                    {value?.name}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                                <cite className="error_msg error_position">
                                                                    {simpleValidator.current.message(
                                                                        "locationId",
                                                                        groupDetails?.locationId,
                                                                        "required"
                                                                    )}
                                                                </cite>
                                                            </div>

                                                            <div
                                                                hidden={!edit}
                                                                className={`form_col-6`}
                                                            >
                                                                <TextField
                                                                    className="w_100per full_w disable"
                                                                    disabled={true}
                                                                    variant="outlined"
                                                                    value={props.locationName}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={6}>
                                                            <label>
                                                                {t("common.zone")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <div hidden={edit} className={`form_col-6`}
                                                            >
                                                                <Select
                                                                    className={`w_100per full_w ${disableZone && 'disable'}`}
                                                                    labelId="demo-simple-select-label"
                                                                    variant="outlined"
                                                                    name="zoneId"
                                                                    disabled={disableZone}
                                                                    value={selectedZone}
                                                                    onChange={handleChange}
                                                                >
                                                                    <MenuItem
                                                                        className="defult_select_option"
                                                                        key={0}
                                                                        value=" "
                                                                    >
                                                                        {t("fleet.selectZone")}
                                                                    </MenuItem>
                                                                    {zoneList &&
                                                                        zoneList.map(
                                                                            (value: any) => (
                                                                                <MenuItem
                                                                                    className="defult_select_option"
                                                                                    key={value?.id}
                                                                                    value={value?.id}
                                                                                >
                                                                                    {value?.name}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                            </div>
                                                            <div hidden={!edit} className={`form_col-6`}>
                                                                <TextField
                                                                    className="w_100per full_w disable"
                                                                    disabled={true}
                                                                    variant="outlined"
                                                                    value={props.zoneName}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                {edit && showGroupRemoveButton && (
                                                    <div className="form_col">
                                                        <Grid
                                                            className="single_line textarea"
                                                            container
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6}/>
                                                            <Grid item xs={6}>
                                                                <label/>
                                                                <div className=" w_100per full_w">
                                                                    {showGroupDeleteButton &&
                                                                        <Button className="delet_acct ml_0 btn_white block_btn" onClick={deleteFleetOpen}>
                                                                            {t("fleet.delete")}
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                
            </div>
            {/* Dialog Add End */}

            <DeleteFleet
                deleteFleetId={props?.groupId}
                hostUserId={props?.hostUserId}
                openDeleteFleet={openDeleteFleet}
                deleteFleetClose={deleteFleetClose}
                closeEditFleet={addGroupModalOneClose}
            />
        </>
    );
};
export default AddFleetGroup;
