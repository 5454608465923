import {ApiEndPoint, getMethod} from "./index";
import {SUCCESS} from "../common";


export const fetchLocationListByPagination = async (params: any = '', mock = false) => {
    let queryParams = `?page=${params.page}&size=${params.size}`;
    return await getMethod(ApiEndPoint.locationsV2, queryParams, mock)
        .then((res) => {
            if (res.status === SUCCESS && res.data) {
                return res.data
            } else {
                console.error("Error", res.data);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
};


export const getLocationChargeHistory = async (params: any = '', mock = false) => {
    let url =  ApiEndPoint.locationChargerHistory.replace('{LOCATION-ID}', params.id);
    return  await getMethod(url, '', mock)
        .then((res) => {
            if (res.status === SUCCESS && res.data) {
                return res.data
            } else {
                console.error("Error", res.data);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
};


export const fetchAllLocationsAddress = async ( mock = false) => {
    const data = await getMethod(ApiEndPoint.locationMapData, '', mock)
        .then((res) => {
            if (res.status === SUCCESS && res.data) {
                return res.data
            } else {
                console.error("Error", res.data);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchEvseHealthStatus = async ( mock = false) => {
    const data = await getMethod(ApiEndPoint.evseHealthStatus, '', mock)
        .then((res) => {
            if (res.status === SUCCESS && res.data) {
                return res.data
            } else {
                console.error("Error", res.data);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchConsolidatedEnergyUsage= async ( mock = false) => {
    const data = await getMethod(ApiEndPoint.allLocationChargerHistory, '', mock)
        .then((res) => {
            if (res.status === SUCCESS && res.data) {
                return res.data
            } else {
                console.error("Error", res.data);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchMonthlyReports = async (params: any = '', mock = false) => {
    let url =  ApiEndPoint.monthlyReports.replace('{TYPE}', params.type).replace('{YEAR}',params.year).replace('{MONTH}',params.month).replace('{host_id}', params.host_id);
    let data =   await getMethod(url, '', mock, false, true)
        .then((res) => {
            if (res.status === SUCCESS && res.data) {
                return res.data
            } else {
                console.error("Error", res.data);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const downloadMonthlyReport = async (params: any = '', mock = false) => {
    let url =  ApiEndPoint.downloadMonthlyReports.replace('{HOST_ID}', params.hostId).replace('{FILE_NAME}',params.fileName);
    let data =   await getMethod(url, '', mock, false, true)
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

