import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CardContent, Card } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { toUpper } from 'lodash';
import { LocationDetails } from 'common/interfaces';
import { getFormattedDateTimeWithApiTimezone } from 'common';

const DepotStationDetailsLocation = (props: LocationDetails) => {
   const { t } = useTranslation();
   const stationInfo = props?.stationInfo;
   const locationInfo = props?.locationInfo;

   return (
      <>
         <Card variant="outlined" className="basic_card">
            <CardContent>
               <div className="title_row">
                  <h2>{toUpper(t("station.locationdetail"))}</h2>
                  <div className="clearfix"/>
               </div>
               {/*----Title End----*/}
               {/*----Information Start----*/}
               <div className="info_detail depotstation_infolist ">
                  <Grid container>
                     <Grid className="" item xs={12}>
                        <div className="tb_info_section  mt-13">
                           {stationInfo(t("common.locationname"), locationInfo?.locationDetailsDTO?.locationName)}
                           {stationInfo(t("common.address"), locationInfo?.locationDetailsDTO?.address?.addressLine1?.concat(' ',locationInfo?.locationDetailsDTO?.address?.addressLine2))}
                           {stationInfo(t("common.capital.city"), locationInfo?.locationDetailsDTO?.address?.city)}
                           {stationInfo(t("stationlocationinformation.sop"), locationInfo?.locationDetailsDTO?.address?.state)}
                           {stationInfo(t("common.zipcode"), locationInfo?.locationDetailsDTO?.address?.postalCode)}
                           {stationInfo(t("common.country"), locationInfo?.locationDetailsDTO?.address?.country)}
                           {stationInfo(t("stationlocationinformation.lastupdate"), getFormattedDateTimeWithApiTimezone(locationInfo?.locationDetailsDTO?.lastUpdated))}
                           {stationInfo(t("common.latitude"), locationInfo?.locationDetailsDTO?.address?.latitude)}
                           {stationInfo(t("common.longtitude"), locationInfo?.locationDetailsDTO?.address?.longitude)}
                           {stationInfo(t("common.descripition"), locationInfo?.locationDetailsDTO?.description)}
                        </div>
                     </Grid>
                  </Grid>
               </div>
            </CardContent>
         </Card>

      </>
   );
};

export default DepotStationDetailsLocation;	