import React, { useState, useEffect } from "react";
import { getMethod, ApiEndPoint, postMethod } from "services";
import { LocationPermission } from "common/interfaces";
import {
    Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Checkbox, Grid
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import "./delegates.scss";
import { useTranslation } from "react-i18next";
import { CheckAccess, claims, depotGoogleMap, GetUrlDetails, setAddressFormat, urlReplace } from 'common';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonGoogleMap from "components/commonGoogleMap";
import { toUpper } from "lodash";
import Images from "assets/images";
import { useDispatch } from "react-redux";
import { showErrorMessages } from "redux/actions/errorMessages";

const EditLocationPermissionListing = (props: LocationPermission) => {
    const dispatch = useDispatch();
    const [locationZonesList, setLocationZonesList] = useState<any>([]);
    const [locationSettingList, setLocationSettingList] = useState<any>([]);
    const [locationSettingsFiltered, setLocationSettingsFiltered] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const { t } = useTranslation();
    const history = useHistory();
    const { delegateId } = GetUrlDetails(history, 'hostSettings');
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingSavePage, setLoadingSavePage] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const PERMISSION_TYPE = {
        LOCATION: 'LOCATION',
        ZONE: 'ZONE',
        LOCATION_INFO: 'LOCATION_INFO',
        USAGE_RATE: 'USAGE_RATE',
        MANAGE_FLEET: 'MANAGE_FLEET',
        LCD_DISPLAY: 'LCD_DISPLAY'
    }
    const PERMISSION_USAGERATE = CheckAccess([claims.PRICING_UPDATE]);
    const PERMISSION_XSGRID = PERMISSION_USAGERATE ? 2 : 4;
    const DEFAULT_PAGE_SIZE = 500;

    useEffect(() => {
        getSettingData();
    }, []);

    useEffect(() => {
        if (locationSettingsFiltered !== null) {
            getLocationDataPaginated(currentPage, DEFAULT_PAGE_SIZE);
        }
    }, [locationSettingsFiltered])


    useEffect(() => {
        if (currentPage > 0) {
            getLocationDataPaginated(currentPage, DEFAULT_PAGE_SIZE);
        }
    }, [locationSettingsFiltered, currentPage])

    const locationIndexValue = (locationList: any, locationId: any) => {
        return locationList?.findIndex(((loc: any) => loc?.locationDTO?.locationId === locationId));
    }

    const zoneIndexValue = (zoneList: any, zoneId: any) => {
        return zoneList?.findIndex((obj: any) => obj?.zoneDTO?.id === zoneId);
    }

    const setFinalLocationData = (locationData: any) => {
        return locationData.map((object: any, index:any) => {
            let locationAccess = object?.locationLevelPermissions ? true : false;
            let zonePermissionAccess = object?.zoneLevelPermissions?.filter((val: any) => val?.permissions)?.length > 0 ? true : false;
            let zoneLevelAccess = object?.zoneLevelPermissions?.map((obj: any) => {
                let zoneAccess = obj?.permissions ? true : false;
                return { ...obj, zoneAccess: zoneAccess };
            });
            return { ...object, locationAccess: locationAccess, zoneLevelPermissions: zoneLevelAccess, indexValue: index, zoneLevelAccess: zonePermissionAccess,  };
        });
    }

    const getSettingData = async () => {
        setLoadingPage(true);
        const url = urlReplace(ApiEndPoint.locationSetting, "{DELEGATE-ID}", delegateId);
        let params = `?page=${0}&size=${500}`
        await getMethod(url, params, false)
            .then((res) => {
                if (res && res?.data && res?.data?.delegateLocationZonePermissionDTOS) {
                    const finalLocationData = setFinalLocationData(res?.data?.delegateLocationZonePermissionDTOS);
                    setLocationSettingsFiltered(finalLocationData);
                } else {
                    setLocationSettingsFiltered([]);
                }
            })
            .catch((err) => {
                setLoadingPage(false);
                console.error("Error", err);
            });
            setLoadingPage(false);
    };

    const getLocationDataPaginated = async (currentPage: number = 0, pageSize: number = 10) => {
        let params = `?page=${currentPage}&size=${pageSize}`
        await getMethod(ApiEndPoint.allLocationZones, params, false)
            .then((res) => {
                if (res && res?.data && res?.data?.locationZonesDetailDTOS?.length > 0) {
                    const locationZonesListLen = locationZonesList?.length;
                    const newLocationZoneList = locationZonesList;
                    newLocationZoneList.push(...res.data.locationZonesDetailDTOS);
                    setHasMore(res?.data?.totalLocations > (currentPage + 1) * pageSize);
                    let currentPagelocationZonesData: Array<any> = [];
                    currentPagelocationZonesData = res?.data?.locationZonesDetailDTOS?.map((val: any, index: any) => {
                        let assignedListIndex = locationIndexValue(locationSettingsFiltered, val.locationId);
                        let nextLocationIndex = locationZonesListLen + index;
                        let isLocationPermitted = assignedListIndex >= 0 ? true : false;
                        let zonePermissionAccess = locationSettingsFiltered[assignedListIndex]?.zoneLevelPermissions?.filter((val: any) => val?.zoneAccess === true)?.length > 0 ? true : false;
                        let zoneLevelAccess = val?.zones?.map((obj: any) => {
                            let zoneIndex = zoneIndexValue(locationSettingsFiltered[assignedListIndex]?.zoneLevelPermissions, obj?.id);
                            let isZoneIndex = zoneIndex >= 0 ? true : false;
                            return {
                                zoneDTO: obj,
                                permissions: isZoneIndex ? locationSettingsFiltered[assignedListIndex]?.zoneLevelPermissions[zoneIndex]?.permissions : null,
                                zoneAccess: isZoneIndex ? locationSettingsFiltered[assignedListIndex]?.zoneLevelPermissions[zoneIndex]?.zoneAccess : false
                            };
                        })
                        const locationObj = {
                            indexValue: nextLocationIndex,
                            zoneLevelAccess: zonePermissionAccess,
                            locationAccess: isLocationPermitted ? locationSettingsFiltered[assignedListIndex]?.locationAccess : null,
                            locationDTO: val,
                            locationLevelPermissions: isLocationPermitted ? locationSettingsFiltered[assignedListIndex]?.locationLevelPermissions : null,
                            zoneLevelPermissions: zoneLevelAccess
                        }
                        return locationObj;
                    });

                    const allLocationZonesData: Array<any> = [...locationSettingList, ...currentPagelocationZonesData];
                    setLocationSettingList(allLocationZonesData);
                    setLocationZonesList(newLocationZoneList);
                } else {
                    setHasMore(false);
                    setLocationSettingList(locationSettingsFiltered);
                }
            })
            .catch((err) => {
                setLoadingPage(false);
                console.error("Error", err);
            });
        setLoadingPage(false);
    };

    const loadMoreItems = () => {
        setCurrentPage(currentPage + 1)
    };

    const onChangeLocationPermission = (e: any, locationId: any, permissionType: any) => {
        let newArr = [...locationSettingList];
        let objIndex = locationIndexValue(newArr, locationId);
        if (permissionType === PERMISSION_TYPE.LOCATION) {
            newArr[objIndex].locationAccess = e.target.checked;
            if(e.target.checked === false){
                newArr[objIndex].locationLevelPermissions = [];
            }
        } else {
            let permissionTypes = (newArr[objIndex].locationLevelPermissions ? newArr[objIndex].locationLevelPermissions?.concat(permissionType) : [permissionType]);
            let locationPermission = e.target.checked ? permissionTypes : newArr[objIndex].locationLevelPermissions?.filter((val: any) => val !== permissionType);
            newArr[objIndex].locationLevelPermissions = locationPermission;
        }
        setLocationSettingList(newArr);
    };

    const onChangeZonePermission = (e: any, locationId: any, zoneId: any, permissionType: any) => {
        let newArr = [...locationSettingList];
        let objIndex = locationIndexValue(newArr, locationId);
        let zoneIndex = zoneIndexValue(newArr[objIndex].zoneLevelPermissions, zoneId);
        let zoneLevelAccess = (newArr[objIndex]?.zoneLevelPermissions?.filter((val: any) => val?.zoneAccess === true)?.length <= 1 && e.target.checked === false ) ? false : true;
        if (permissionType === PERMISSION_TYPE.ZONE) {
            newArr[objIndex].zoneLevelPermissions[zoneIndex].zoneAccess = e.target.checked;
            if(e.target.checked === false){
                newArr[objIndex].zoneLevelPermissions[zoneIndex].permissions = [];
            }
            newArr[objIndex].zoneLevelAccess = zoneLevelAccess;
        } else {
            let permissionTypes = (newArr[objIndex].zoneLevelPermissions[zoneIndex].permissions ? newArr[objIndex].zoneLevelPermissions[zoneIndex].permissions?.concat(permissionType) : [permissionType]);
            let zonePermission = e.target.checked ? permissionTypes : newArr[objIndex].zoneLevelPermissions[zoneIndex].permissions?.filter((val: any) => val !== permissionType);
            newArr[objIndex].zoneLevelPermissions[zoneIndex].permissions = zonePermission;
            newArr[objIndex].zoneLevelAccess = zoneLevelAccess;
        }
        setLocationSettingList(newArr);
    };

    const handlePermissionStatus = (permissionData: any, permissionType: any) => {
        let permissionAccess = false;
        let permissionMatched = permissionData?.find((type: any) => type === permissionType);
        if (permissionMatched) {
            permissionAccess = true;
        }
        return permissionAccess;
    };

    const saveChanges = () => {
        setLoadingSavePage(true);
        const url = urlReplace(ApiEndPoint.locationSetting, "{DELEGATE-ID}", delegateId);
        const finalLocation = locationSettingList?.map((object: any) => {
            let zoneLevelAccess = object?.zoneLevelPermissions?.filter((val: any) =>
                val?.permissions?.length > 0
            ).map((obj: any) => {
                return {
                    zoneId: obj.zoneDTO.id,
                    permissions: obj.permissions,
                };
            })
            return { locationId: object?.locationDTO?.locationId, locationLevelPermissions: object?.locationLevelPermissions?.length > 0 ? object?.locationLevelPermissions : null, zoneLevelPermissions: zoneLevelAccess?.length > 0 ? zoneLevelAccess : null };
        }).filter((value: any) => value?.locationLevelPermissions?.length > 0 || value?.zoneLevelPermissions?.length > 0)
        if (finalLocation) {
            postMethod(url, '', finalLocation)
                .then((res) => {
                    if(res.status === 200){                        
                        props?.editlocationPermissionClose();
                    }else{
                        dispatch(showErrorMessages({error: true, message:"common.something.went.wrong.errmsg", type: "error", operation: ""}));
                        props?.editlocationPermissionClose();
                    }
                    setLoadingSavePage(false);                  
                })
                .catch((err) => {
                    setLoadingSavePage(false);
                    dispatch(showErrorMessages({error: true, message:"common.something.went.wrong.errmsg", type: "error", operation: ""}));
                    console.error("Error", err);
                });   
        }    
    };

    return (
        <>
          <div data-testid="edit_location_permission_listing">
            <div className="information_section delegates_details mt-0">
            <div className="button_row  full_right">
                
            <Button className="btn_white" onClick={props?.editlocationPermissionClose}>{t("common.cancelbutton")}</Button>
            <Button
                className="btn_white blue_bg"
                onClick={saveChanges}
            >
                {t("hostsetting.savepermission")}
            </Button>
            </div>
            </div>
            <div className="accordian_table">
                <div className="section_title mb mt-20">
                {loadingSavePage === true &&  <CircularProgress className="inner_progress save_data" />}
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                            <h2>{toUpper(t("hostsetting.assign"))}</h2>
                        </Grid>
                        <Grid item xs={5}>
                            <h2 className="text-left">{toUpper(t("common.location"))}</h2>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("common.location.information"))}</h2>
                        </Grid>
                        {PERMISSION_USAGERATE &&
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("common.usagerate"))}</h2>
                        </Grid>}
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("delegate.permission.fleet"))}</h2>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("delegate.permission.lcd"))}</h2>
                        </Grid>
                    </Grid>
                </div>
                {loadingPage === true &&
                    <CircularProgress className="inner_progress" />
                }
                <InfiniteScroll
                                            dataLength={locationSettingList? locationSettingList.length:0}
                                            next={loadMoreItems}
                                            hasMore={hasMore}
                                            loader={<CircularProgress className="inner_progress" />}
                                            scrollableTarget="information_section"
                                            className=""
                                            style={{overflowY: "hidden"}}
                                        >
                {locationSettingList?.map((value: any) => (

                    <Accordion className="" key={value?.locationDTO?.locationId}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="section_group" onClick={(event) => event.stopPropagation()}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <div className="defult_form accordian_check">
                                            <div className="form_col checkbox">
                                                <Checkbox
                                                    id={value?.locationDTO?.locationId}
                                                    checked={value?.locationAccess}
                                                    color={value?.zoneLevelAccess ? 'secondary' : 'primary'}
                                                    disabled={value?.zoneLevelAccess}
                                                    onChange={(e: any) => onChangeLocationPermission(e, value?.locationDTO?.locationId, PERMISSION_TYPE.LOCATION)}
                                                />

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div className="depot_details">
                                            <div className="depot_information">

                                                <div className="depot_location_map">
                                                    <CommonGoogleMap
                                                        center={{
                                                            lat: value?.locationDTO?.address?.latitude,
                                                            lon: value?.locationDTO?.address?.longitude,
                                                        }}
                                                        zoom={
                                                            depotGoogleMap.zoom
                                                        }
                                                        markers={[
                                                            {
                                                                lat: value?.locationDTO?.address?.latitude,
                                                                lon: value?.locationDTO?.address?.longitude,
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="depot_info">
                                                    <div className="depot_name">{value?.locationDTO?.locationName}</div>

                                                    <div className="depot_addr">{setAddressFormat(value?.locationDTO?.address)}</div>
                                                    <div className="port_details">
                                                        <span className="port portDetailsEVSE"><img src={Images.IC_PORT_12x14} />
                                                            {value?.locationDTO?.chargingStations} {t("common.station", { count: value?.locationDTO?.chargingStations })}{", "}
                                                            {value?.locationDTO?.totalPorts} {t("common.port", { count: value?.locationDTO?.totalPorts })} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <div className="defult_form accordian_check">
                                            <div className="form_col checkbox">
                                                <Checkbox
                                                    id={value?.locationDTO?.locationId}
                                                    checked={handlePermissionStatus(value?.locationLevelPermissions, PERMISSION_TYPE.LOCATION_INFO)}
                                                    disabled={!value?.locationAccess}
                                                    color={value?.locationAccess ? 'primary' : 'secondary'}
                                                    onChange={(e: any) => onChangeLocationPermission(e, value?.locationDTO?.locationId, PERMISSION_TYPE.LOCATION_INFO)}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    {PERMISSION_USAGERATE &&
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <div className="defult_form accordian_check">
                                            <div className="form_col checkbox">
                                                <Checkbox
                                                    id={value?.locationDTO?.locationId}
                                                    checked={handlePermissionStatus(value?.locationLevelPermissions, PERMISSION_TYPE.USAGE_RATE)}
                                                    disabled={!value?.locationAccess}
                                                    color={value?.locationAccess ? 'primary' : 'secondary'}
                                                    onChange={(e: any) => onChangeLocationPermission(e, value?.locationDTO?.locationId, PERMISSION_TYPE.USAGE_RATE)}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    }
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <div className="defult_form accordian_check">
                                            <div className="form_col checkbox">
                                                <Checkbox
                                                    id={value?.locationDTO?.locationId}
                                                    checked={handlePermissionStatus(value?.locationLevelPermissions, PERMISSION_TYPE.MANAGE_FLEET)}
                                                    disabled={!value?.locationAccess}
                                                    color={value?.locationAccess ? 'primary' : 'secondary'}
                                                    onChange={(e: any) => onChangeLocationPermission(e, value?.locationDTO?.locationId, PERMISSION_TYPE.MANAGE_FLEET)}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <div className="defult_form accordian_check">
                                            <div className="form_col checkbox">
                                                <Checkbox
                                                    id={value?.locationDTO?.locationId}
                                                    checked={handlePermissionStatus(value?.locationLevelPermissions, PERMISSION_TYPE.LCD_DISPLAY)}
                                                    disabled={!value?.locationAccess}
                                                    color={value?.locationAccess ? 'primary' : 'secondary'}
                                                    onChange={(e: any) => onChangeLocationPermission(e, value?.locationDTO?.locationId, PERMISSION_TYPE.LCD_DISPLAY)}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="inner_summary">
                            <div className="section_title">
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <h2 className="space-right">{toUpper(t("common.assignzonepermission"))}</h2>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <h2 className="text-left space-left">{toUpper(t("common.zonename"))}</h2>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("common.location.information"))}</h2>
                                    </Grid>
                                    {PERMISSION_USAGERATE &&
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("common.usagerate"))}</h2>
                                    </Grid>}
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("delegate.permission.fleet"))}</h2>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("delegate.permission.lcd"))}</h2>
                                    </Grid>
                                    {!value?.zoneLevelPermissions &&
                                        <Grid item xs={12}><h2 className="no-row">{t("common.nozone")}</h2></Grid>
                                    }
                                </Grid>
                            </div>
                            {value?.zoneLevelPermissions && value?.zoneLevelPermissions?.map((zoneData: any) => (
                                <div className="section_group location_setting" onClick={(event) => event.stopPropagation()} key={zoneData?.zoneDTO?.id}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                            <div className="defult_form accordian_check">
                                                <div className="form_col checkbox">
                                                    <Checkbox
                                                        id={zoneData?.zoneDTO?.id}
                                                        disabled={value?.locationAccess}
                                                        checked={zoneData?.zoneAccess}
                                                        color={value?.locationAccess ? 'secondary' : 'primary'}
                                                        onChange={(e: any) => onChangeZonePermission(e, value?.locationDTO?.locationId, zoneData?.zoneDTO?.id, PERMISSION_TYPE.ZONE)
                                                        } />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <div className="lctnname space-left">{zoneData?.zoneDTO?.name}</div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <div className="defult_form accordian_check">
                                                <div className="form_col checkbox">
                                                    <Checkbox
                                                        id={zoneData?.zoneDTO?.id}
                                                        checked={handlePermissionStatus(zoneData?.permissions, PERMISSION_TYPE.LOCATION_INFO)}
                                                        disabled={!zoneData?.zoneAccess}
                                                        color={zoneData?.zoneAccess ? 'primary' : 'secondary'}
                                                        onChange={(e: any) => onChangeZonePermission(e, value?.locationDTO?.locationId, zoneData?.zoneDTO?.id, PERMISSION_TYPE.LOCATION_INFO)
                                                        } />
                                                </div>
                                            </div>
                                        </Grid>
                                        {PERMISSION_USAGERATE &&
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <div className="defult_form accordian_check">
                                                <div className="form_col checkbox">
                                                    <Checkbox disabled />
                                                </div>
                                            </div>
                                        </Grid>
                                        }
                                         <Grid
                                            item
                                            xs={1}
                                        >
                                            <div className="defult_form accordian_check">
                                                <div className="form_col checkbox">
                                                    <Checkbox
                                                        id={zoneData?.zoneDTO?.id}
                                                        checked={handlePermissionStatus(zoneData?.permissions, PERMISSION_TYPE.MANAGE_FLEET)}
                                                        disabled={!zoneData?.zoneAccess}
                                                        color={zoneData?.zoneAccess ? 'primary' : 'secondary'}
                                                        onChange={(e: any) => onChangeZonePermission(e, value?.locationDTO?.locationId, zoneData?.zoneDTO?.id, PERMISSION_TYPE.MANAGE_FLEET)
                                                        } />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <div className="defult_form accordian_check">
                                                <div className="form_col checkbox ">
                                                    <Checkbox
                                                        id={zoneData?.zoneDTO?.id}
                                                        checked={handlePermissionStatus(zoneData?.permissions, PERMISSION_TYPE.LCD_DISPLAY)}
                                                        disabled={!zoneData?.zoneAccess}
                                                        color={zoneData?.zoneAccess ? 'primary' : 'secondary'}
                                                        onChange={(e: any) => onChangeZonePermission(e, value?.locationDTO?.locationId, zoneData?.zoneDTO?.id, PERMISSION_TYPE.LCD_DISPLAY)
                                                        } />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
                </InfiniteScroll>
            </div>
          </div>
        </>
    );
};

export default EditLocationPermissionListing;
