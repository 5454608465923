const init: any[] = [];

export const fetchUserPermissionsReducer = (state = init, action: any) => {

    if(action.type === 'FETCH_USER_PERMISSIONS') {
        state = action.payload;
        return state;
    } else {
        return state;
    }
}