import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import AddressForm from "components/addressForm";

import React, { useState, useRef, useEffect } from "react";
import { t } from "i18next";
import SimpleReactValidator from "simple-react-validator";
import { ObjectDetails } from "common/interfaces";


const EditLocationPermission = (props: any) => {

  const [scroll] = React.useState("paper");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState<any>();
  const DEFAULT_ISPUBLIC = "isPublic";
  const [updateButton, setUpdateButton] = useState<any>("popup_buttondisable");
 
  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    let initialDta = {
      name: props.depotDetailsData?.name,
      organizationName: props.depotDetailsData?.organizationName,
      isPublic: props.depotDetailsData?.isPublic,
    };
    props?.setInitialDetails(initialDta);
  };

  const handleInitialChange = (e: ObjectDetails) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === DEFAULT_ISPUBLIC) {
      value = Boolean(value);
    }
    props?.setInitialDetails({ ...props?.initialDetails, [name]: value });
    if (value === "") {
      showErrors();
      setUpdateButton("popup_buttondisable"); 
    }
  };

 

  const showErrors = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  const validateError = (fieldName: string) => {
    if (
      simpleValidator.current.fields[fieldName] === false &&
      simpleValidator.current.messagesShown === true
    ) {
      return "error";
    } else {
      return "";
    }
  };


  useEffect(()=> {
       props?.initialDetails?.isPublic !== props?.depotDetailsData?.isPublic ? 
        setUpdateButton("blue_bg") : setUpdateButton("popup_buttondisable");   
  },[props?.initialDetails])

  return (
    <div>
      <div className="dialog_title">
        <h4>{t("common.locationpermission")}</h4>
        <div className="button_row mt-0 full_right">
          <Button
            className="btn_white text_btn_theme"
            onClick={props?.locationPermissionClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={props?.submitEditForm} className={`btn_white ${updateButton}`} disabled={ updateButton === "popup_buttondisable" ? true : false}
          >
            {t("common.station.update")}
          </Button>
        </div>
      </div>
      <DialogContent dividers={scroll === "paper"} {...props}>
        <DialogContentText id="scroll-dialog-description">
          <div className="defult_form">
            <AddressForm
              intialDetails={props?.initialDetails}
              handleInitialChange={handleInitialChange}
              validator={simpleValidator}
              validateError={validateError}
            />
          </div>
        </DialogContentText>
      </DialogContent>
    </div>
  );
};

export default EditLocationPermission;
