import React from "react";
import {
    DialogContent,
    DialogContentText,
    Grid,
    LinearProgress,
    MenuItem,
    Select
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const UserGroupDetails = (props: any) => {

    const { t } = useTranslation();

    const handleChange = (userEvent: any) => {
        props.setUserGroupName(userEvent?.target?.value?.name);
        props.setUserGroupId(userEvent?.target?.value?.id);
        props.setDisableButton(false);
    }

    return (
        <>
            {props.spinnerOff && <LinearProgress />}
            <DialogContent>
                <DialogContentText id="scroll-dialog-description">
                    <div className="information_section delegates_details">
                        <div className="defult_form mt-25">
                            <div className="section_group">
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={4}
                                >
                                    <Grid item xs={12}>
                                        <div className="form_col">
                                            <Grid
                                                className="single_line_delete"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={0}
                                            >
                                                <Grid item xs={4}>
                                                    <label className="w_100per full_w">
                                                        {t("membership.selectgroup")}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Select
                                                        className="w_100per full_w"
                                                        labelId="demo-simple-select-label"
                                                        variant="outlined"
                                                        name={"type"}
                                                        onChange={handleChange}
                                                        onFocus={() => props.setPlaceHolder(true)}
                                                        defaultValue={" "}
                                                    >
                                                        <MenuItem
                                                            className={`${props.showPlaceHolder()}`}
                                                            value=" ">{t("membership.selectgroup")}
                                                        </MenuItem>
                                                        {props.userGroupArray.map((userGroup: any) => (
                                                            <MenuItem key={userGroup.id} value={userGroup} className="optionlist">
                                                                {userGroup.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    )
}

export default UserGroupDetails;