import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardContent, IconButton} from "@material-ui/core";
import {toUpper} from "lodash";
import Images from "../../../../../assets/images";
import "../waitList.scss";
import {BLANK_DATA_PLACEHOLDER, CheckAccess, claims, SUCCESS} from "../../../../../common";
import EditWaitListConfiguration from "./editWaitListConfiguration";
import {DEFAULT_WAITLIST_CONFIGURE, WAITLIST_ERROR_MESSAGE} from "../waitListHelper";
import {fetchWaitListConfiguredData} from "../../../../../services/waitListService";
import {useDispatch} from "react-redux";
import {showErrorMessages} from "../../../../../redux/actions/errorMessages";
import GetUrlDetails from "../../../../../common/urlHelpers";
import {useHistory} from "react-router";

const WaitListConfiguration = () => {
    const {t} = useTranslation();
    let dispatch = useDispatch();
    const history = useHistory();
    const [openWaitListConfigurationEdit, setOpenWaitListConfigurationEdit] = useState(false);
    const [viewWaitListConfigure, setViewWaitListConfigure] = useState(DEFAULT_WAITLIST_CONFIGURE);
    const showWaitListConfigurationEditIcon =  CheckAccess([claims.WAITLIST_UPDATE]);
    const {locationId} = GetUrlDetails(history, '');

    useEffect(() => {
       fetchWaitListConfiguration();
    }, []);

    const dataRefresh = () => {
        fetchWaitListConfiguration();
    };


    const fetchWaitListConfiguration = () => {
        let params = {locationId: locationId};
        fetchWaitListConfiguredData(params).then((res: any) => {
             if(res?.status === SUCCESS) {
                 setViewWaitListConfigure(res.data)
             } else {
                 dispatch(showErrorMessages({error: true, message: t(WAITLIST_ERROR_MESSAGE[res?.data?.error_code]), type: "error", operation: "",}));
             }
        }) .catch((err: any) => {
            console.error("Error", err);
        });
    };

    const labelValue = (key: string, value: any, label: string = '') => {
        return (
            <>
                <div className="row">
                    <span className="label">{key}</span>
                    <span className="result">{(value && value !== 'N/A') ? (value + ' ' + label) : BLANK_DATA_PLACEHOLDER}</span>
                </div>
            </>
        );
    };

    return (
        <>
        <div className='station_details'>
            <div className="information_section waitlist_config">
                <Card variant="outlined" className="basic_card">
                    <CardContent>
                        <Card variant="outlined" className="basic_card">
                            {/*----Title Start----*/}
                            <div className="title_row">
                                <h2 className="text-primary">{toUpper(t("waitlist.configuration"))}</h2>
                                <>{showWaitListConfigurationEditIcon &&
                                <div className={`action_icon`}>
                                    <IconButton className="top_action_btn"
                                                onClick={() => setOpenWaitListConfigurationEdit(true)}>
                                        <img src={Images.ic_edit} alt={t("common.edit")}/>
                                    </IconButton>
                                </div>}
                                </>
                                <div className="clearfix"/>
                            </div>
                            {/*----Title End----*/}

                            {/*----Information Start----*/}
                            <div className="info_detail depotstation_infolist">
                                <div className="tb_info_section mt-13">
                                    {labelValue(t("fleet.delegate.status"), viewWaitListConfigure.status ? t('waitlist.enable') : t('waitlist.disable') )}
                                    {labelValue(t("waitlist.plugtime"), viewWaitListConfigure.minutes_to_plugin,  t("common.minutes"))}
                                    {labelValue(t("waitlist.limit"), viewWaitListConfigure.max_waitlist_allowed)}
                                </div>
                            </div>
                        </Card>
                    </CardContent>
                </Card>
            </div>
        </div>
        <EditWaitListConfiguration openWaitListConfigurationEdit = {openWaitListConfigurationEdit}
                                   setOpenWaitListConfigurationEdit= {setOpenWaitListConfigurationEdit}
                                   waitListConfigure={viewWaitListConfigure}
                                   locationId={locationId}
                                   dataRefresh={dataRefresh}/>
       </>
    )
};

export default WaitListConfiguration;