import { ObjectDetails } from "common/interfaces";

const initialStateUser = {
    error: null,
    manageUserDetails : {}
};

export default function fetchManageUserReducer(state = initialStateUser, action: ObjectDetails) {

    if(action.type === 'FETCH_MANAGE_USER_ACTION') {
        return {
            error: null,
            manageUserDetails: action.payload
        }
    } else {
        return state;
    }

}