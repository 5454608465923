import React, { useState, useEffect } from "react";
import { getMethod, ApiEndPoint } from "services";
import { GetUrlDetails, urlReplace, setAddressFormat, depotGoogleMap, CheckAccess, claims } from "common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ViewLocationPermissionInfo } from 'common/interfaces';
import { toUpper } from 'lodash';
import Images from "assets/images";
import { Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonGoogleMap from "components/commonGoogleMap";
import InfiniteScroll from "react-infinite-scroll-component";


const ViewLocationPermission = (props: ViewLocationPermissionInfo) => {
    const [locationSettingList, setLocationSettingList] = useState<any>(null);
    const { t } = useTranslation();
    const history = useHistory();
    const { delegateId } = GetUrlDetails(history, 'hostSettings');
    const PERMISSION_TYPE = {
        LOCATION_INFO: 'LOCATION_INFO',
        USAGE_RATE: 'USAGE_RATE',
        MANAGE_FLEET: 'MANAGE_FLEET',
        LCD_DISPLAY: 'LCD_DISPLAY'
    }
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const DEFAULT_PAGE_SIZE = 20;
    const PERMISSION_USAGERATE = CheckAccess([claims.PRICING_UPDATE]);

    useEffect(() => {
        const fetchData = async () => {
            await getSettingData(currentPage, DEFAULT_PAGE_SIZE);
        }
        fetchData();
    }, [delegateId, props.openEditlocationPermission]);

    useEffect(() => {
        if (locationSettingList === null) {
            const fetchData = async () => {
                await getSettingData(currentPage, DEFAULT_PAGE_SIZE);
            }
            fetchData();
        }
    }, [locationSettingList])


    useEffect(() => {
        if (currentPage > 0) {
            const fetchData = async () => {
                await getSettingData(currentPage, DEFAULT_PAGE_SIZE);
            }
            fetchData();
        }
    }, [currentPage])

    const getSettingData = async (currentPage: number = 0, pageSize: number = 10) => {
        const url = urlReplace(ApiEndPoint.locationSetting, "{DELEGATE-ID}", delegateId);
        let params = `?page=${currentPage}&size=${pageSize}`
        await getMethod(url, params, false)
            .then((res) => {
                if (res && res?.data && res?.data?.delegateLocationZonePermissionDTOS?.length > 0) {
                    setHasMore(res?.data?.totalLocations > (currentPage + 1) * pageSize);
                    locationSettingList ? setLocationSettingList([...locationSettingList, ...res.data.delegateLocationZonePermissionDTOS])
                    : setLocationSettingList(res?.data?.delegateLocationZonePermissionDTOS);
                    props.setPermissionButtonName(t("hostsetting.locationpermission"));
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    const loadMoreItems = () => {
        setCurrentPage(currentPage + 1)
    };

    const handlePermissionStatus = (permissionData: any) => {
        let permissionAccess = Images.IC_CLOSE_ASSIGN;
        if (permissionData?.length > 0) {
            permissionAccess = Images.ic_done;
        }
        return permissionAccess;
    };

    const handlePermissionChildStatus = (permissionData: any, permissionType: any) => {
        let permissionAccess = Images.IC_CLOSE_ASSIGN;
        let permissionMatched = permissionData?.find((type: any) => type === permissionType);
        if (permissionMatched) {
            permissionAccess = Images.ic_done;
        }
        return permissionAccess;
    };

    return (
        <>
        {locationSettingList && locationSettingList?.length > 0 &&
            <div className="accordian_table">
                <div className="section_title mb mt-20">
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                            <h2>{toUpper(t("hostsetting.assign"))}</h2>
                        </Grid>
                        <Grid item xs={5}>
                            <h2 className="text-left">{toUpper(t("common.location"))}</h2>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("common.location.information"))}</h2>
                        </Grid>
                        {PERMISSION_USAGERATE &&
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("common.usagerate"))}</h2>
                        </Grid>
                        }
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("delegate.permission.fleet"))}</h2>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        >
                            <h2>{toUpper(t("delegate.permission.lcd"))}</h2>
                        </Grid>
                    </Grid>
                </div>

                <InfiniteScroll
                    dataLength={locationSettingList ? locationSettingList.length : 0}
                    next={loadMoreItems}
                    hasMore={hasMore}
                    loader={<CircularProgress className="inner_progress" />}
                    scrollableTarget="information_section"
                    className=""
                    style={{ overflowY: "hidden" }}
                >
                {locationSettingList?.map((value: any) => (
                    <Accordion key={value?.locationDTO?.locationId}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="section_group" onClick={(event) => event.stopPropagation()}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <span><img src={handlePermissionStatus(value?.locationLevelPermissions)} /></span>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div className="depot_details">
                                            <div className="depot_information">
                                                <div className="depot_location_map">
                                                    <CommonGoogleMap
                                                        center={{
                                                            lat: value?.locationDTO?.address?.latitude,
                                                            lon: value?.locationDTO?.address?.longitude,
                                                        }}
                                                        zoom={
                                                            depotGoogleMap.zoom
                                                        }
                                                        markers={[
                                                            {
                                                                lat: value?.locationDTO?.address?.latitude,
                                                                lon: value?.locationDTO?.address?.longitude,
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="depot_info">
                                                    <div className="depot_name">{value?.locationDTO?.locationName}</div>
                                                    <div className="depot_addr">{setAddressFormat(value?.locationDTO?.address)}</div>
                                                    <div className="port_details">
                                                        <span className="port portDetailsEVSE"><img src={Images.IC_PORT_12x14} />
                                                            {value?.locationDTO?.chargingStations} {t("common.station", { count: value?.locationDTO?.chargingStations })}{", "}
                                                            {value?.locationDTO?.totalPorts} {t("common.port", { count: value?.locationDTO?.totalPorts })}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <span><img src={handlePermissionChildStatus(value?.locationLevelPermissions, PERMISSION_TYPE.LOCATION_INFO)} /></span>
                                    </Grid>
                                    {PERMISSION_USAGERATE &&
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <span><img src={handlePermissionChildStatus(value?.locationLevelPermissions, PERMISSION_TYPE.USAGE_RATE)} /></span>
                                    </Grid>
                                    }
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <span><img src={handlePermissionChildStatus(value?.locationLevelPermissions, PERMISSION_TYPE.MANAGE_FLEET)} /></span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <span><img src={handlePermissionChildStatus(value?.locationLevelPermissions, PERMISSION_TYPE.LCD_DISPLAY)} /></span>
                                    </Grid>
                                </Grid>
                            </div>

                        </AccordionSummary>
                        <AccordionDetails
                            className={value?.zoneLevelPermissions?.length > 0 ? '' : 'no_expandsummary' }
                        >
                        { value && value?.zoneLevelPermissions && 
                            <div className="section_title">
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <h2 className="space-right">{toUpper(t("common.assignzonepermission"))}</h2>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <h2 className="text-left space-left">{toUpper(t("common.zonename"))}</h2>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("common.location.information"))}</h2>
                                    </Grid>
                                    {PERMISSION_USAGERATE &&
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("common.usagerate"))}</h2>
                                    </Grid>}
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("delegate.permission.fleet"))}</h2>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <h2>{toUpper(t("delegate.permission.lcd"))}</h2>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                            {value?.zoneLevelPermissions && value?.zoneLevelPermissions?.map((zoneData: any) => (
                                <div className="section_group" key={zoneData?.zoneDTO?.id}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <span><img src={handlePermissionStatus(zoneData?.permissions)} /></span>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <div className="lctnname space-left">{zoneData?.zoneDTO?.name}</div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <span><img src={handlePermissionChildStatus(zoneData?.permissions, PERMISSION_TYPE.LOCATION_INFO)} /></span>
                                        </Grid>
                                        {PERMISSION_USAGERATE &&
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <span><img src={handlePermissionChildStatus(zoneData?.permissions, PERMISSION_TYPE.USAGE_RATE)} /></span>
                                        </Grid>}
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <span><img src={handlePermissionChildStatus(zoneData?.permissions, PERMISSION_TYPE.MANAGE_FLEET)} /></span>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <span><img src={handlePermissionChildStatus(zoneData?.permissions, PERMISSION_TYPE.LCD_DISPLAY)} /></span>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                )
                )}
                </InfiniteScroll>
            </div>
        }
        </>
    );
};

export default ViewLocationPermission;
