import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogContent, Grid, LinearProgress, MenuItem, Select} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DataGrid} from "@material-ui/data-grid";
import {ObjectDetails} from "../../../common/interfaces";
import {MonthlyReportsColumns} from "../../tableColumns/monthlyReportsColumns";
import {getDownloadData, keyCloakConfig, MONTHS, REPORT_TYPES, YEARS} from "../../../common";
import "./monthlyReports.scss";
import SimpleReactValidator from "simple-react-validator";
import {downloadMonthlyReport, fetchMonthlyReports} from "services/evseLocationsService";


const MonthlyReports = (props: ObjectDetails) => {

    let {t} = useTranslation();
    
    const URL = 'url';
    let initialValue = {type: ' ', month: ' ', year: ' '};
    const [, forceUpdate] = useState<any>();
    const simpleValidator: any = useRef(new SimpleReactValidator());
    const {openMonthlyReport, monthlyReportPopupClose} = props;

    const [spinnerOff, setSpinnerOff] = useState<boolean>(false);
    const [reports, setReports] = useState<any>(null);
    const [searchParams, setSearchParams] = useState<any>(initialValue);
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const monthlyReportsColumns = MonthlyReportsColumns();

    useEffect(() => {
        if(openMonthlyReport) {
            simpleValidator.current.hideMessages();
            forceUpdate(0);
            setReports(null);
            setSearchParams(initialValue);
            setSpinnerOff(false)
        }
    }, [openMonthlyReport]);

    const DownloadReports = (property: any) => {
        if (property?.field === URL) {
            let params = {hostId: property?.row?.host_id, fileName: property?.row?.file_name};
            downloadMonthlyReport(params).then((res: any) => {
                if (res) {
                    getDownloadData(res.data, property?.row?.file_name)
                }
            }).catch((error: any) => {
                console.log(error);
            })
        }
    };

    const monthlyReportClose = () => {
        monthlyReportPopupClose(false);
    };

    const searchReports = () => {
        if (searchParams?.type !== ' ' && searchParams?.year !== ' ') {
            let params = { type: searchParams?.type, year: searchParams?.year, month: getMonthVal(searchParams?.month), host_id : hostId };
            setSpinnerOff(true);
            fetchMonthlyReports(params).then((res: any) => {
                if (res) {
                    setSpinnerOff(false);
                    setReports(res);
                }
            }).catch((error: any) => {
                setSpinnerOff(false);
                console.log(error)
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const getMonthVal = (month:any) => {
        let monthIndex = MONTHS.findIndex((val:any) => val === month);
        return monthIndex + 1;
    };

    const handleChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        setSearchParams({ ...searchParams, [name]: value });
    };


    return (
        <div className="dialog_box">
            <Dialog
                className="dialog_container edit_detail_section edit-user-info dialog_container_center monthly_reports"
                open={openMonthlyReport}
                onClose={monthlyReportPopupClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{`${t("common.downloadmonthlyreports")}`}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme" data-testid="btn-cancel"
                                onClick={monthlyReportClose}>{t("common.cancelbutton")}</Button>
                        <Button className={`btn_white blue_bg`} data-testid="btn-save"
                                onClick={searchReports}>{t("common.searchreports")}</Button>
                    </div>
                </div>
                {spinnerOff && (<LinearProgress/>)}
                <DialogContent>
                    <div className="station_details">
                       <div className='information_section defult_form'>
                        <div className="info_detail depotstation_infolist">
                            <div className="tb_info_section mt-13 edit_detail_section">
                                <Grid container justifyContent="flex-start" alignItems="flex-start" xs={12} spacing={0}>
                                    <Grid item xs={6} md={4}>
                                        <div className="row mb-20">
                                            <span className="label type_width">{`${t("fleet.type")}*`}</span>
                                            <span className="result form_col">
                                                 <Select
                                                     data-testid="type-select"
                                                     labelId="demo-simple-select-label"
                                                     variant="outlined"
                                                     onChange={handleChange}
                                                     name="type"
                                                     value={searchParams?.type}>
                                                      <MenuItem className="defult_select_option" key={'default'} value=" ">
                                                            <Grid>{t("common.selecttype")}</Grid>
                                                      </MenuItem>
                                                     {REPORT_TYPES.map((status: any) => (
                                                         <MenuItem className="defult_select_option" key={status.value}
                                                                   value={status.value}>
                                                             {t(status.label)}
                                                         </MenuItem>
                                                     ))}
                                                 </Select>
                                                 <cite className="error_msg error_position">
                                                       {simpleValidator.current.message("type", searchParams?.type, "required")}
                                                 </cite>
                                              </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <div className="row mb-20">
                                            <span className="label type_width">{`${t("fleet.make_model_year")}*`}</span>
                                            <span className="result form_col">
                                                 <Select
                                                     data-testid="type-select"
                                                     labelId="demo-simple-select-label"
                                                     variant="outlined"
                                                     onChange={handleChange}
                                                     name="year"
                                                     value={searchParams?.year}>
                                                      <MenuItem className="defult_select_option" key={'default'} value=" ">
                                                            <Grid>{t("common.selectyear")}</Grid>
                                                      </MenuItem>
                                                     {YEARS.map((year: any) => (
                                                         <MenuItem className="defult_select_option" key={year} value={year}>
                                                             {year}
                                                         </MenuItem>
                                                     ))}
                                                 </Select>
                                                 <cite className="error_msg error_position">
                                                       {simpleValidator.current.message("year", searchParams?.year, "required")}
                                                 </cite>
                                             </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                         <div className="row mb-20">
                                            <span className="label month_width">{`${t("pricing.month")}`}</span>
                                            <span className="result form_col">
                                                 <Select
                                                     data-testid="type-select"
                                                     labelId="demo-simple-select-label"
                                                     variant="outlined"
                                                     onChange={handleChange}
                                                     name="month"
                                                     value={searchParams?.month}>
                                                      <MenuItem className="defult_select_option" key={'default'} value=" ">
                                                            <Grid>{t("common.selectmonth")}</Grid>
                                                      </MenuItem>
                                                     {MONTHS.map((month: any) => (
                                                         <MenuItem className="defult_select_option" key={month} value={month}>
                                                             {month}
                                                         </MenuItem>
                                                     ))}
                                                 </Select>
                                            </span>
                                         </div>
                                    </Grid>
                                </Grid>
                                {reports &&
                                <div className="table_scroll mb-20 report_list">
                                    <div className="default_table sorting_table hide_select_all">
                                        <DataGrid
                                            autoHeight
                                            vertical-align="top"
                                            className="table information_section"
                                            rowHeight={59}
                                            headerHeight={35}
                                            rows={reports}
                                            onCellClick={DownloadReports}
                                            columns={monthlyReportsColumns}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
};


export default MonthlyReports;