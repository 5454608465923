import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { userLocationColumns } from "pages/tableColumns";
import { ColumnNameTranslate, urlReplace, GetUrlDetails } from "common";
import { getMethod, ApiEndPoint } from "services";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const LocationList = (props: any) => {

    const history = useHistory();

    const userGroupId = GetUrlDetails(history,'userGroup');
    const locationColumns = userLocationColumns;
    const [locationData, setLocationData] = useState<any>({});
    const [loadingPage, setLoadingPage]  = useState(false);

    const getEvseLocations = async () => {
        setLoadingPage(true);
        const url = urlReplace(ApiEndPoint.manageUserLocationListV2, "{USER-GROUP-ID}", userGroupId['userGroup']);
        await getMethod(url, '')
            .then((res) => {
                setLocationData(res?.data);
            })
            .catch((err) => {
                console.error("Error", err);
            });
        setLoadingPage(false);
    };

    const locationDataSet = (value: any) => {
        let locationTableData = [];
        if (value?.locations) {            
            locationTableData = value.locations.map((val: any, index: number) => ({ 
                ...val, 
                mapDetails: val?.addressDTO, 
                id: index 
            }));
            locationTableData = locationTableData.sort((a: any, b: any) => Number(b.clickable) - Number(a.clickable));
        }
        return locationTableData;
    }

    useEffect(() => {
        getEvseLocations();
    }, []);

    useEffect(() => {
        if(props?.zoneCallback === true){
            getEvseLocations();
            props?.zoneClose()
        }
    }, [props?.zoneCallback]);

    return (
        <>
        {   loadingPage ? <CircularProgress className="inner_progress_userGroup"/> :
            <div className="table_scroll" data-testid="location_list_table">
                <div className="default_table zone_location-table zone_location_overflow_visible">
                    <DataGrid
                        autoHeight
                        autoPageSize
                        className='table'
                        rowHeight={80}
                        headerHeight={30}
                        rows={locationDataSet(locationData)}
                        columns={ColumnNameTranslate(locationColumns)}
                        pageSize={10}
                        onRowClick={(e) => props?.updateZoneOpen(e)}
                    />
                </div>
            </div>
        }
        </>
    );
};
export default LocationList;
