import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toUpper} from "lodash";
import {Grid, CardContent, Card, IconButton, CircularProgress} from "@material-ui/core";
import Images from "assets/images";
import {CheckAccess, claims, keyCloakConfig} from "common";
import AddFleetGroup from "../../addFleetGroup";

const FleetGroupInformation = (props: any) => {
  const {t} = useTranslation();
  const [addGroupModalOne, setAddGroupModalOne] = useState(false);
  const showGroupEditButton = CheckAccess([claims.FLEET_UPDATE]);
  const {getGroupinfo, groupinfo, editableinfo, spinnerOff, groupId} = props;
  const hostId = keyCloakConfig?.tokenParsed?.hostId;

  const addGroupModalOneOpen = () => {
    setAddGroupModalOne(true);
  };

  const addGroupModalOneClose = () => {
    setAddGroupModalOne(false);
  };

  const getGroupInfo = () => {
    getGroupinfo();
  };

  return (
    <>
      <div className="information_section mt-40">
        <Grid container spacing={2}>
          <Grid className="mid_section_left" item xs={12} sm={12} md={12} lg={12}>
            <Card variant="outlined" className="basic_card">
              <CardContent>
                {spinnerOff && <CircularProgress className="inner_progress_userGroup" />}
                {/*----Title Start----*/}
                <div className="title_row">
                  <h2>{toUpper(t("fleet.groupdetail"))}</h2>
                  {showGroupEditButton && (
                    <div className="action_icon">
                      <IconButton className="top_action_btn" onClick={addGroupModalOneOpen}>
                        <img src={Images.ic_edit} alt="edit" />
                      </IconButton>
                    </div>
                  )}
                  <div className="clearfix"></div>
                </div>

                {/*----Title End----*/}

                {/*----Information Start----*/}

                <div className="info_detail">
                  <Grid container>
                    <Grid className="" item xs={12}>
                      <div className="tb_info_section mt-13">
                        <div className="row">
                          <span className="label">{t("fleet.group")}</span>
                          {groupinfo && groupinfo?.map((value: any) => <span className="result" key={value?.id}>{value?.group}</span>)}
                        </div>
                        <div className="row">
                          <span className="label">{t("fleet.location")}</span>
                          {groupinfo &&
                            groupinfo?.map((value: any) => <span className="result" key={value?.id}>{value?.div_name}</span>)}
                        </div>
                        {groupinfo[0]?.zone_name && (
                          <div className="row">
                            <span className="label">{t("common.zone")}</span>
                            <span className="result">{groupinfo[0]?.zone_name}</span>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/*----Information End----*/}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <AddFleetGroup
        addGroupModalOne={addGroupModalOne}
        addGroupModalOneOpen={addGroupModalOneOpen}
        addGroupModalOneClose={addGroupModalOneClose}
        hostId={hostId}
        edit={true}
        groupInfo={editableinfo}
        groupId={groupId}
        locationName={groupinfo[0]?.div_name}
        zoneName={groupinfo[0]?.zone_name ? groupinfo[0]?.zone_name : t("fleet.selectZone")}
        groupInfoRefresh={getGroupInfo}
        hostUserId={groupinfo[0]?.host_user_id}
      />
    </>
  );
};

export default FleetGroupInformation;
