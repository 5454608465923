import {
  MenuItem,
  Grow,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const PopperDetails = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Popper
        open={props?.dropDownOpen}
        transition
        disablePortal
        className="dropdown_item_save"
        anchorEl={props?.anchorEl}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={props.handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={props.addLcdMessages}>
                    {t("lcdDisplay.saveAllStations")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
export default PopperDetails;
