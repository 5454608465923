import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { CheckAccess } from 'common/abilityHelpers';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthentic = true;
    let canAccess = (rest?.access) ? CheckAccess(rest?.access) : true;

    return (
        <Route {...rest} render={props => ((isAuthentic && canAccess) ? <Component {...props} /> : <Redirect to="/" />)} />
    )
}

export default PrivateRoute
