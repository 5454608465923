import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import statementsData from 'data/revenueStatements.json';
import Images from 'assets/images';
import { revenueListColumns} from 'pages/tableColumns';
import "../../App.scss";


const Statements = () => {
  
    return (
       <>
        <main data-testid="statements_page">
        <div className={'scroll_effect'}>
         <div className="revenue_statements">
         <div className="table_scroll ">
                  <div className="default_table">
                    <DataGrid autoHeight vertical-align='top' className="table"
                      rowHeight={80}
                      headerHeight={30}
                      rows={statementsData.revenueListRows}
                      columns={revenueListColumns}
                      pageSize={12}
                    />
                  </div>
                  <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid className="text_left" item xs={8}>
                        <div className="pagination position-left">
                          <span><img src={Images.ic_first_page} alt="first page" /></span>
                          <span><img src={Images.ic_chevron_left} alt="back page" /></span>
                          <span>1</span>
                          <span><img src={Images.ic_chevron_right} alt="back page" /></span>
                        </div>
                         <Button className="btn_white ml_0">Download CSV</Button>
                      </Grid>
                      <Grid className="text_right" item xs={4}>
                        <div className="list_number">1-20 of 100 Statements</div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
         </div>
         </div>
         </main>
       </>
     )
   }

export default Statements;