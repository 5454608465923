import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toUpper} from "lodash";
import {Grid, CardContent, Card, IconButton, CircularProgress} from "@material-ui/core";
import Images from "assets/images";
import "../depotStationDetails.scss";
import {fetchLcdDisplayDetails} from "services/LcdDisplayService";
import AddDisplayDetails from "./addDisplayDetails";
import {lcdMessageLabel} from "./stationLcdDisplayHelpers";

const StationLcdDisplay = (props: any) => {
  const {t} = useTranslation();
  const [spinnerOff, setSpinnerOff] = useState(false);
  const [addDetail, setAddDetail] = useState(false);
  const [inputFieldsView, setInputFieldsView] = useState<any>([{messagePartOne: "", messagePartTwo: ""}]);
  const [pendingMessageStatus, setPendingMessageStatus] = useState(false);
  const [pendingInputMessages, setPendingInputMessages] = useState<any>([{messagePartOne: "", messagePartTwo: ""}]);
  const [currentShowingMsgIndex, setCurrentShowingMsgIndex] = useState(0);
  
  // For changing messages in the lcd screen - dont remove this method
  setTimeout(
    () =>
      setCurrentShowingMsgIndex(
        (currentShowingMsgIndex + 1) % inputFieldsView.length
      ),
    3000
  );

  const setAddDetailClose = () => {
    setAddDetail(false);
  };

  const getStationLcdDetails = () => {
    let params = {stationId: props.stationId};
    setSpinnerOff(true);
    fetchLcdDisplayDetails(params, false)
      .then((res: any) => {
        if (res) {
          setInputFieldsView(res?.lcdMessages);
          setPendingMessageStatus(res?.PendingMessage);
          if (res?.PendingMessage) {
            setPendingInputMessages(res?.lcdPendingMessages);
          }
          setSpinnerOff(false);
        }
      })
      .catch((err: any) => {
        console.error("Error", err);
      });
  };

  useEffect(() => {
    getStationLcdDetails();
  }, []);

  return (
    <>
      <div className="information_section mt-40">
        <div className="custom_rows_margin">
          <Card variant="outlined" className="basic_card">
            <CardContent>
              {spinnerOff && <CircularProgress className="inner_progress_userGroup" />}
              <Grid className="display-flex" item xs={12} sm={12} md={12} lg={12}>
                <Grid className="mid_section_left" item xs={8} sm={8} md={8} lg={8}>
                  {/*----Title Start----*/}
                  <div className="title_row">
                    <h2>{toUpper(t("lcdDisplay.displayMessage"))}</h2>

                    <div className="clearfix" />
                  </div>

                  {/*----Title End----*/}

                  {/*----Information Start----*/}

                  <div className="info_detail mt-25">
                    <Grid container>
                      <Grid className="" item xs={10}>
                        <div className="tb_info_section mt-13">
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            className="row"
                            spacing={0}
                            md
                          >
                            <Grid item md={3}>
                              <span className="lcd_msg_header">{t("lcdDisplay.currentMessage")}</span>
                            </Grid>
                          </Grid>
                          {inputFieldsView?.map((message: any, index: any) => (
                              <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                className="row"
                                spacing={0}
                                md
                                key={`${message["messagePartOne"]}key`}
                              >
                                <Grid item md={3}>
                                  <span className="label">
                                    {message["messagePartOne"] !== "" ? t("lcdDisplay.message" + index) : ""}
                                  </span>
                                </Grid>
                                <Grid item md={6}>
                                  <span className="message_content text-left">
                                    {message["messagePartOne"]}
                                    {message["messagePartTwo"] && ", " + message["messagePartTwo"]}
                                  </span>
                                </Grid>
                              </Grid>
                            ))}
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  {/*----Information End----*/}
                </Grid>

                <Grid className="mid_section_left" item xs={4} sm={4} md={4} lg={4}>
                  {/*----Title Start----*/}
                  <div className="title_row">
                    <h2>{toUpper(t("lcdDisplay.preview"))}</h2>
                    <div className="action_icon">
                      <IconButton
                        className="top_action_btn"
                        onClick={() => {
                          setAddDetail(true);
                        }}
                      >
                        <img src={Images.ic_edit} alt="edit" />
                      </IconButton>
                    </div>
                    <div className="action_icon  mr_10">
                      <IconButton className="top_action_btn" onClick={getStationLcdDetails}>
                        <img src={Images.IC_REFRESH} alt={t("configuration.refersh.alt")} />
                      </IconButton>
                    </div>
                    <div className="clearfix"/>
                  </div>

                  {/*----Title End----*/}

                  {/*----Information Start----*/}

                  <div className="info_detail">
                    <Grid container>
                      <Grid className="" item xs={6}>
                        <div className="tb_info_section mt-13 lcd_display">
                          <div className="message_display">
                            <span>{inputFieldsView[currentShowingMsgIndex]?.messagePartOne}</span>
                            <br />
                            <span>{inputFieldsView[currentShowingMsgIndex]?.messagePartTwo}</span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  {/*----Information End----*/}
                </Grid>
              </Grid>

              {pendingMessageStatus && (
                <Grid className="mid_section_left" item xs={8} md={8} lg={8}>
                  {/*----Information Start----*/}

                  <div className="info_detail">
                    <Grid container>
                      <Grid className="" item xs={10}>
                        <div className="tb_info_section">
                          <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            className="row"
                            spacing={0}
                            md
                          >
                            <Grid item md={3}>
                              <span className="lcd_msg_header">{t("lcdDisplay.pendingMessage")}</span>
                            </Grid>
                          </Grid>
                          {pendingInputMessages?.map((message: any) => (
                              <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                className="row"
                                spacing={0}
                                md
                                key={`${message["messagePartOne"]}key`}
                              >
                                <Grid item md={3}>
                                  <span className="label">{lcdMessageLabel(message["label"])}</span>
                                </Grid>
                                <Grid item md={4}>
                                  <span className="message_content text-left">
                                    {message["messagePartOne"] !== "" ? message["messagePartOne"] : "-"}
                                    {message["messagePartTwo"] && ", " + message["messagePartTwo"]}
                                  </span>
                                </Grid>
                                <Grid item md={4}>
                                  <span className="message_content text-left">{message["updatedDate"]}</span>
                                </Grid>
                              </Grid>
                            ))}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="mb-20 mt-13 config_change_msg">{t("lcdDisplay.updateInfoText")}</div>
                  {/*----Information End----*/}
                </Grid>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
      {addDetail && (
        <AddDisplayDetails
          addDetail={addDetail}
          closeAddDetail={setAddDetailClose}
          stationId={props.stationId}
          inputFieldsView={inputFieldsView}
          getLcdDisplayDetail={getStationLcdDetails}
        />
      )}
    </>
  );
};

export default StationLcdDisplay;
