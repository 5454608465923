import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Radio,
    RadioGroup,
    CardContent,
    Card,
    Typography,
    Link,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Images from "assets/images";
import { t } from "i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import {UserUploadErrorComponent} from 'pages/tableColumns';
import { useHistory } from "react-router-dom";
import { bytesToSize, ColumnNameTranslate, defaultVariable, GetUrlDetails, urlReplace } from "common";
import { ApiEndPoint, postMethod } from "services";
import CommonDownloadCsv from "components/commonDownloadCsv";

const UserInfoUpload = (props: any) => {

    const emailCsv = t("common.uploademails");
    const blinkCardsCsv = t("common.uploadblinkcards");
    const templateData = [
        {},{},{}
    ];

    const emailTemplate = {
        headers: [
            { label: t("common.email"), key: "email" }
        ],
        data: templateData
    };

    const rfidTemplate = {
        headers: [
            { label: t("common.rfidnumber"), key: "blinkCardNumber" }
        ],
        data: templateData
    };

    const errorColumnValue = UserUploadErrorComponent();
    const history = useHistory();
    const [uploadUserError, setuploadUserErrorOpen] = useState(false);
    const [success, setSuccessOpen] = useState(false);
    const [radioValue, setRadioValue] = useState('email');
    const [file, setFile] = useState<any>();
    const [buttonEnable, setButtonEnable] = useState('gry_bg');
    const [uploadUsersList, setUploadUsersList] = useState<any>([]);
    const [uploadUsersErrorsList, setUploadUsersErrorsList] = useState<any>([]);
    const [userFile, setUserFile] = useState(emailCsv); 
    const [userTemplate, setUserTemplate] = useState(emailTemplate);
    const [uploadFileErrors, setUploadFileErrors] = useState<string[]>([]);
    const SIZE = bytesToSize(defaultVariable.DEFAULT_IMAGE_FILESIZE, t);

    let userGroupId = GetUrlDetails(history, 'userGroup');

    const handleChange = (event: any) => {

        // check for errors
        const getFile = event.target.files[0];
        const fileName: string = getFile?.name;
        const extension: string = fileName.substring(fileName.lastIndexOf('.') + 1);
        const fileSizeMeets: boolean = getFile?.size < defaultVariable.DEFAULT_IMAGE_FILESIZE;
        const matchExtension: boolean = extension.toLowerCase() === defaultVariable.CSV_VALUE;

        if(fileSizeMeets && matchExtension) {
            if(uploadFileErrors?.length > 0) {
                setUploadFileErrors([]);
            }
            setFile(getFile);
            setButtonEnable('blue_bg');
        } else {
            let errorsList: string[] = [];
            if(!fileSizeMeets) {
                errorsList.push(t("dragfile.maxfilesize", {SIZE}));
            }
            if(!matchExtension) {
                errorsList.push(t("dragfile.csvonly"));
            }
            setUploadFileErrors(errorsList);
        }
    }

    const removeDuplicates = (userList: any): any => {
        const filterUsers = userList?.filter((value: any, index: any, self: any) => 
            index === self?.findIndex((t: any) => (t?.email === value?.email && t?.rfid === value?.rfid))
        )
        return filterUsers;
    }

    const validateUploadUsers = async () => {
        const url = urlReplace(ApiEndPoint.groupUsersUploadValidate, "{USERGROUP-ID}", userGroupId?.userGroup);
        const param = '?isBlinkCardTemplate='+ (radioValue === 'email' ? false : true)

        const body = new FormData();
        body.append('file', file);
    
        await postMethod(url,param,body, true)
            .then((res) => {
                if(res?.data) {
                    // filter list without errors
                    const userList = res?.data;

                    // filter duplicate emails and/or rfids
                    const getUsersList = removeDuplicates(userList);

                    const filterUsers = getUsersList?.filter((user:any) => user?.error === null);
                    const usersToUpload = filterUsers?.map((val: any) => ({
                        email: val.email, 
                        rfid: val.rfid
                    }));

                    const filterErrors = getUsersList?.filter((user:any) => user?.error !== null);
                    const userErrorsToShow = filterErrors?.map((val: any, index: number) => ({
                        ...val,
                        id: index,
                        emailError: val.error
                    }));

                    setUploadUsersList(usersToUpload);
                    setUploadUsersErrorsList(userErrorsToShow);
                    
                    if(userErrorsToShow.length > 0) {
                        uploadUserErrorOpen();
                    } 
                    else {
                        successOpen();
                    }
                } 
            })
            .catch((err) => {
                console.error(err);
            });
        
    }

    const uploadUsers = async () => {
        const url = urlReplace(ApiEndPoint.groupUsersUpload, "{USERGROUP-ID}", userGroupId?.userGroup);
        if(uploadUsersList.length > 0) {
            await postMethod(url, '', uploadUsersList)
                .then((res) => {
                    if(res?.data) {
                        if(uploadUserError) { 
                            setuploadUserErrorOpen(false);
                        } else {
                            setSuccessOpen(false);
                        }
                    } 
                })
                .catch((err) => {
                    console.error(err.data?.message);
                });
        } else {
            setSuccessOpen(false);
        }

        if(uploadUserError) {
            uploadUserErrorClose();
        }
        
        props?.uploadUserGroupClose();
        props?.refreshDetails();
    }

    const onChangeRadio = (event: ChangeEvent<HTMLInputElement>) => {
        const getValue: string = (event.target as HTMLInputElement).value;
        
        setRadioValue(getValue);
    }

    const setTemplate = () => {
        // set download template link to correct value
        const isEmail = radioValue === 'email';
        const template = isEmail ? emailTemplate : rfidTemplate;
        const getFile = isEmail ? emailCsv : blinkCardsCsv;
        setUserFile(getFile);                     
        setUserTemplate(template);
    }

    const handleUserSave = () => {
        validateUploadUsers()
            .catch(console.log);
    }

    const uploadUserErrorOpen = () => {
        setuploadUserErrorOpen(true);
    };
    const uploadUserErrorClose = () => {
        setuploadUserErrorOpen(false);
        setFile(undefined);
        setButtonEnable('gry_bg');
        props?.uploadUserGroupClose();
        props?.refreshDetails();
    };

    const successOpen = () => {
        setSuccessOpen(true);
    };
    const successClose = () => {
        setSuccessOpen(false);
        setFile(undefined);
        setButtonEnable('gry_bg');
        props?.uploadUserGroupClose();
        props?.refreshDetails();
    };

    useEffect(() => {
        if(props?.uploadUserGroup) {
            setFile(undefined);
            setButtonEnable('gry_bg');
            setRadioValue('email');
            setUploadUsersList([]);
            setUploadUsersErrorsList([]);
            setUploadFileErrors([]);
        }
    }, [props?.uploadUserGroup])

    return (
        <>
           <div className="dialog_box" data-testid="upload_user_popup">
                <Dialog
                    className="dialog_container edit_detail_section zone_settings_dialog edit_photo_gallery sm_570 dialog_container_center"
                    open={props?.uploadUserGroup}
                    onClose={props?.uploadUserGroupClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("user.uploadusergroup")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={props?.uploadUserGroupClose}
                            >
                                {t("common.cancelbutton")}
                            </Button>
                            <Button
                                onClick={handleUserSave}
                                disabled={!file}
                                className={`btn_white ${buttonEnable}`} 
                            >
                                {t("common.upload")}
                            </Button>
                        </div>
                    </div>
                    <DialogContent >
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form">                                
                                {/** Add Radio Button(s) Here */}
                                <div className="section_group">
                                    <div className="form_col radio_button">
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            name="position"
                                            defaultValue="top"
                                            value={radioValue}
                                            onChange={onChangeRadio}
                                        >
                                            <FormControlLabel
                                                value="email"
                                                labelPlacement="start"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t("common.email")}
                                            />
                                            <FormControlLabel
                                                value="rfid"
                                                labelPlacement="start"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={t("common.blinkcard")}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="gallery_photo_upload mb-20">
                                    <Card>
                                        <CardContent>
                                            {   
                                                uploadFileErrors.length > 0 && uploadFileErrors.map((value: any) => (
                                                    <div className="error" key={value}>
                                                        {" "}
                                                        <span>{value}</span>{" "}
                                                    </div>
                                                ))
                                            }
                                            <div className="dragdrop">
                                                <img
                                                    src={Images.up_image}
                                                    alt={t("common.alt.upimg")}
                                                    className="up-icon"
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"

                                                    >
                                                        {t(
                                                            "common.draganddrop"
                                                        )} 
                                                        <cite>
                                                            {t("common.uploadfilesizemsg", {SIZE})}
                                                        </cite>
                                                    </Button>
                                                </label>
                                                <input
                                                    accept=".csv"
                                                    className=""
                                                    id="icon-button-file"
                                                    type="file"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                <div className="section_group">
                                    <div className="form_col">
                                        <p>{file?.name}</p>
                                    </div>
                                </div>
                                <div className="section_group">
                                    <div className="form_col">
                                        {/** Set download href based on Radio button click */}
                                        <CommonDownloadCsv
                                            {...props}
                                            datas={userTemplate?.data}
                                            headers={userTemplate?.headers}
                                            filename={userFile}
                                            callBackDownloadCsv={setTemplate}
                                        >
                                            <Typography className="download_link">
                                                <Link>
                                                    {t("common.downloadusertemplate")}
                                                </Link>
                                            </Typography>
                                        </CommonDownloadCsv>
                                    </div>
                                </div>                           
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section upload_user_error sm dialog_container_center"
                    open={uploadUserError}
                    onClose={uploadUserErrorClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("user.uploadingusererror")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={uploadUserErrorClose}
                            >
                                {t("common.cancelbutton")}
                            </Button>
                            <Button
                                className="btn_white blue_bg"
                                onClick={uploadUsers}
                            >
                                {t("common.ok")}
                            </Button>
                        </div>
                    </div>
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="user_info">
                                <div className="positive">
                                    {uploadUsersList.length} {
                                        uploadUsersList.length === 1 ? 
                                        t("user.uploadeduserrecord") 
                                        : t("user.uploadeduserrecords")
                                    }
                                </div>
                                <div className="nagative">
                                    {uploadUsersErrorsList.length} {
                                       uploadUsersErrorsList.length === 1 ? 
                                        t("user.errorusernumber") 
                                        : t("user.errorusersnumber")
                                    }
                                </div>
                            </div>
                            <div className="table_scroll">
                                <div className="default_table page_table">
                                    <DataGrid
                                        autoHeight
                                        vertical-align="top"
                                        className="table"
                                        rowHeight={59}
                                        headerHeight={30}
                                        rows={uploadUsersErrorsList}
                                        columns={ColumnNameTranslate(errorColumnValue)}
                                        pageSize={12}
                                    />
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section sm_330 dialog_container_center"
                    open={success}
                    onClose={successClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("common.success")}</h4>
                    </div>
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <Typography className="plain_text">
                                {uploadUsersList.length} {
                                    uploadUsersList.length === 1 ? 
                                    t("user.successfulyaddeduser") 
                                    : t("user.successfulyaddedusers")
                                }
                            </Typography>
                            <div className="button_row mt-20 text_center">
                                <Button
                                    onClick={uploadUsers}
                                    className="btn_white blue_bg ml_0"
                                >
                                    {t("common.ok")}
                                </Button>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};
export default UserInfoUpload;