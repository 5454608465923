import React, { useState, useEffect } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import { GetUrlDetails, TabPanel, a11yProps, useStyles, defaultVariable, rolePermissionCheck, keyCloakConfig} from "common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FLEET_LIST_PATH } from "pages/fleet/fleetManagementHelper";
import FleetGroups from "./fleetGroups";
import FleetVehicles from "./fleetVehicles";

import { fetchGroupsList } from "services/fleetManagementService";

const FleetManagement = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { tab } = GetUrlDetails(history, FLEET_LIST_PATH);
  const [tabChange, setTabChange] = useState(Number(tab));
  const [value, setValue] = React.useState(0);

  
  const userRoles = keyCloakConfig?.realmAccess?.roles;
  const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
  const delegateId =  isDelegate ? sessionStorage.getItem('delegateId') : "";
  const [isFleetGroupsAvilable, setFleetGroupsAvilable] = useState(true);
  const hostId = keyCloakConfig?.tokenParsed?.hostId;

  useEffect(() => {
    if (tab !== "") {
      setTabChange(parseInt(tab));
      setValue(parseInt(tab));
    }
  }, [tab]);

  const handleChange = (event: any, newValue: any) => {
    if (tabChange !== newValue) {
      setTabChange(newValue);
    }
    window.scrollTo(0, 0);
    setValue(newValue);
    history.push({
      pathname: FLEET_LIST_PATH,
      hash: "#" + newValue,
    });
  };

  const getGroupDetails = () => {
    let params = {host_id: hostId, delegateId: delegateId, isDelegate: isDelegate};
      fetchGroupsList(params, false)
        .then((res: any) => {
              if (res) {
                res?.length > 0 ? setFleetGroupsAvilable(true) : setFleetGroupsAvilable(false);
              }
          })
          .catch((err: any) => {
            console.error("Error", err);
          });
  };

  useEffect(() => {
    if (tab !== "") {
      setValue(parseInt(tab));
    }
  }, [tabChange, tab]);

  useEffect(() => {
      getGroupDetails();
  }, []);

  return (
    <>
      <main className={classes.content}>
          <div className="host_settings">
            <div className="detail_page_tab position_fixed_nav">
              <AppBar className="defult_tab mb-20" position="static" color="default">
                <Tabs
                  className="tab_nav"
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    className="tab_nav_item"
                    label={t("fleet")}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tab_nav_item"
                    label={t("fleet.vehicles")}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <FleetGroups getGroupDetails={getGroupDetails} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FleetVehicles isFleetGroupsAvilable={isFleetGroupsAvilable} />
              </TabPanel>
            </div>
          </div>
      </main>
    </>
  );
};
export default FleetManagement;
