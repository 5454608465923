import React from 'react';
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
      <>
      <p>
        404 {t("common.notfound")}
      </p>
    </>
  );
}

const Invalid = () => {
  const { t } = useTranslation();
  
  return (
      <>
      <p>
        {t("common.invalidparams")}
      </p>
    </>
  );
}

export { Invalid };

export default NotFound;