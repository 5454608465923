import {useTranslation} from "react-i18next";
import { toUpper} from 'lodash';
import {ObjectDetails} from "../../common/interfaces";

export const HEIGHT_TO_HIDE_FROM = 50;
export const DELETE_STATUS = "delete";
export const GROUP = "group";
export const FLEET_GROUPS = "fleet_groups_list";
export const FLEET_GROUP_INFO_PATH = "/fleet/fleetInfo";
export const FLEET_VEHICLE_INFO_PATH = "/fleet/fleetVehicleInfo";
export const FLEET_VEHICLES = "fleet_vehicles_list";
export const VEHICLE = "vehicle";
export const DEPARTURE_EARLY = "DEPARTURE_EARLY";
export const DEPARTURE_BELOW_MIN = "DEPARTURE_BELOW_MIN";
export const RETURN_LATE = "RETURN_LATE";
export const DELETE = "Delete";
export const ACTIVE = "ACTIVE";
export const SAVEBTN = "savebtn";
export const NEXTBTN = "nextbtn";
export const UPDATE_FLEET_VEHICLE = "updateFleetVehicle";
export const LOCATION = "location";
export const FLEET_GROUP = "fleetGroup";
export const YEAR = "year";
export const VIN = "vin";
export const MINIMUM_VIN_CHAR = 16;
export const DEACTIVATE_FLEET_ACTION = "deactivateFleetGroup";
export const MIN_AUTHCARD_CHAR = 1;
export const FILE_NAME_FLEET_GROUPS = "Fleets_";
export const FILE_NAME_FLEET_VEHICLES = "Fleet_Vehicles_";
export const VALID_VIN_EXPRESSION = "^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$";
export const FLEET_CONSTRAINTS_ISENABLE = 'isEnable';
export const TABS = {
    CONSTRAINTS: 2,
    DELEGATE_CONSTRAINTS: 3,
};
export const VEHICLE_NAME = "vehicleName";
export const ZERO_LENGTH = 0;
export const FLEET_LIST_PATH = "/fleet";
export const ADD_FLEET = "createFleetGroup";
export const UPDATE_FLEET = "updateFleetGroup";
export const FLEET_GROUP_VEHICLES = "fleetGroupVehicle";
export const ADD_FLEET_VEHICLES = "createFleetVehicle";
export const UPDATE_FLEET_VEHICLES = "updateFleetVehicle";
export const FLEET_TAB_ONE = 0;
export const FLEET_TAB_TWO = 1;
export const GROUP_ID = "groupId";
 


export const FileHeaderFleetComponent = (type: string) => {
    const { t } = useTranslation();

    switch (type) {

        case FLEET_GROUPS:
            return [
                { label: toUpper(t("fleet.location")), key: "div_name" },
                { label: toUpper(t("fleet.zone")), key: "zone_name" },
                { label: toUpper(t("fleet.fleetName")), key: "group" },
                { label: toUpper(t("fleet.noOfDelegate")), key: "delegate_count" },
                { label: toUpper(t("fleet.noOfVehicles")), key: "vehicles_count" },
                { label: toUpper(t("fleet.noOfStations")), key: "station_count" },
                { label: toUpper(t("fleet.creationDate")), key: "created_on" },
                { label: toUpper(t("fleet.updationDate")), key: "modified_on" },
            ];
        case FLEET_VEHICLES:
            return [
                { label: toUpper(t("fleet.vehiclename")), key: "vehicleName" },
                { label: toUpper(t("fleet.vin")), key: "vin" },
                { label: toUpper(t("fleet.api_provider")), key: "provider" },
                { label: toUpper(t("common.make")), key: "make" },
                { label: toUpper(t("common.model")), key: "model" },
                { label: toUpper(t("fleet.year")), key: "year" },
                { label: t("fleet.rangecaps"), key: "milege" },
                { label: toUpper(t("fleet.auth_card")), key: "pass_nos"},
                { label: toUpper(t("common.fleet")), key: "fleet_groups_name" }
            ];
        case FLEET_GROUP_VEHICLES:
            return [
                { label: toUpper(t("fleet.vehiclename")), key: "vehicleName" },
                { label: toUpper(t("fleet.vin")), key: "vin" },
                { label: toUpper(t("common.make")), key: "make" },
                { label: toUpper(t("common.model")), key: "model" },
                { label: toUpper(t("fleet.year")), key: "year" },
                { label: t("fleet.rangecaps"), key: "milege" }
            ];
    }
};


export const DropdownYears = (start_year:any) => {
    const year = new Date().getFullYear();
    const default_number_of_years = 11;
    const year_diffrence = year - (start_year - 1) ;
    const number_of_years = year_diffrence > default_number_of_years ? year_diffrence : default_number_of_years;
    const years = Array.from(new Array(number_of_years), (val, index) => year - index);
    return years;
};

// chargers
export const AUTO_AUTHORIZE = 'power_loss_restart';
export const DELETE_FIELD = 'delete';


// constrains
export const duration: any[] = [
    {label:'12:00 AM', value: 0},
    {label:'12:30 AM', value: 0.5},
    {label:'01:00 AM', value: 1},
    {label:'01:30 AM', value: 1.5},
    {label:'02:00 AM', value: 2},
    {label:'02:30 AM', value: 2.5},
    {label:'03:00 AM', value: 3},
    {label:'03:30 AM', value: 3.5},
    {label:'04:00 AM', value: 4},
    {label:'04:30 AM', value: 4.5},
    {label:'05:00 AM', value: 5},
    {label:'05:30 AM', value: 5.5},
    {label:'06:00 AM', value: 6},
    {label:'06:30 AM', value: 6.5},
    {label:'07:00 AM', value: 7},
    {label:'07:30 AM', value: 7.5},
    {label:'08:00 AM', value: 8},
    {label:'08:30 AM', value: 8.5},
    {label:'09:00 AM', value: 9},
    {label:'09:30 AM', value: 9.5},
    {label:'10:00 AM', value: 10},
    {label:'10:30 AM', value: 10.5},
    {label:'11:00 AM', value: 11},
    {label:'11:30 AM', value: 11.5},
    {label:'12:00 PM', value: 12},
    {label:'12:30 PM', value: 12.5},
    {label:'01:00 PM', value: 13},
    {label:'01:30 PM', value: 13.5},
    {label:'02:00 PM', value: 14},
    {label:'02:30 PM', value: 14.5},
    {label:'03:00 PM', value: 15},
    {label:'03:30 PM', value: 15.5},
    {label:'04:00 PM', value: 16},
    {label:'04:30 PM', value: 16.5},
    {label:'05:00 PM', value: 17},
    {label:'05:30 PM', value: 17.5},
    {label:'06:00 PM', value: 18},
    {label:'06:30 PM', value: 18.5},
    {label:'07:00 PM', value: 19},
    {label:'07:30 PM', value: 19.5},
    {label:'08:00 PM', value: 20},
    {label:'08:30 PM', value: 20.5},
    {label:'09:00 PM', value: 21},
    {label:'09:30 PM', value: 21.5},
    {label:'10:00 PM', value: 22},
    {label:'10:30 PM', value: 22.5},
    {label:'11:00 PM', value: 23},
    {label:'11:30 PM', value: 23.5}
];

export const day: ObjectDetails = {
    'Monday': 'Monday',
    'Tuesday': 'Tuesday',
    'Wednesday': 'Wednesday',
    'Thursday': 'Thursday',
    'Friday': 'Friday',
    'Saturday': 'Saturday',
    'Sunday': 'Sunday'
};

const calculateTime = (startTime: any, endTime: any) => {
    let isNextDay = false;
    if((startTime > -1 && startTime !== ' ') && (endTime > -1 && endTime !== ' ')) {
        let value = startTime === endTime ? 24 : endTime - startTime;
        if(value < 0) {
            value = value + 24;
            isNextDay = true;
        }
        return {dur : value, isNextDay: isNextDay};
    } else {
        return {dur : '', isNextDay: isNextDay};
    }
};


const findDurationValue = (label:string, conditionValue: any, conditionProp:any, returnLabel : string = 'label') => {
    let index = duration.findIndex((val: any) => val[label] === conditionValue[conditionProp]);
    if(index > -1) {
         return duration[index][returnLabel];
    }
};

export const prepareScheduleConstraintsForDisplay = (data: any = null) => {
    let scheduleConstraints = data.schedule_constraints ? JSON.parse(data.schedule_constraints) : {};
    let departureConstraints = data.departure_constraints ? JSON.parse(data.departure_constraints) : {};
    let returnConstraints = data.return_constraints ? JSON.parse(data.return_constraints) : {};
    let scheduleData = [];
    for (let dayKey in day) {
        let dayKeyLowCase = dayKey.toLocaleLowerCase();
        let isEnable = scheduleConstraints.hasOwnProperty(dayKeyLowCase);
        let startTime = isEnable ? findDurationValue('label', scheduleConstraints[dayKeyLowCase], 'scheduled_start', 'value')  : ' ';
        let endTime = isEnable ? findDurationValue('label', scheduleConstraints[dayKeyLowCase], 'scheduled_end', 'value') : ' ';
        let {dur, isNextDay} = calculateTime(startTime, endTime);
        scheduleData.push({
            isEnable: isEnable,
            day: dayKeyLowCase,
            dayDisplay: dayKey,
            plugOut: isEnable ? findDurationValue('label', departureConstraints[dayKeyLowCase], 'departure_time', 'value') : ' ',
            plugIn: isEnable ? findDurationValue('label', returnConstraints[dayKeyLowCase], 'return_time', 'value') : ' ',
            beforeDeparture: isEnable ? departureConstraints[dayKeyLowCase].before_departure : '',
            onReturn: isEnable ? returnConstraints[dayKeyLowCase].on_return : '',
            startTime: startTime,
            endTime: endTime,
            duration: dur,
            isNextDay: isNextDay
        })
    }
    return scheduleData;
};

export const validationScheduleConstraints= (data: any, simpleValidator: any) => {
    let valid = true;
    data.forEach((val:any, index: number) => {
        if(val.isEnable) {
            valid = valid && simpleValidator.current.fieldValid('plugIn'+index) &&
            simpleValidator.current.fieldValid('plugOut'+index) &&
            simpleValidator.current.fieldValid('startTime'+index) &&
            simpleValidator.current.fieldValid('endTime'+index) &&
            simpleValidator.current.fieldValid('onReturn'+index) &&
            simpleValidator.current.fieldValid('beforeDeparture'+index);
        }
    });
    return valid;
};

export const prepareScheduleConstraintsForSave = (data: ObjectDetails[]) => {
    let scheduleConstraints: any = {};
    let returnConstraints: any = {};
    let departureConstraints: any = {};
    let isDaysEnabled = false;
    for (let dayKey in day) {
        let dayWiseDataIndex = data.findIndex((val) => val.dayDisplay === dayKey);
        if (dayWiseDataIndex !== -1 && data[dayWiseDataIndex].isEnable) {
            isDaysEnabled = true;
            scheduleConstraints[dayKey.toLocaleLowerCase()] = {
                scheduled_start: findDurationValue('value', data[dayWiseDataIndex], 'startTime', 'label'),
                scheduled_end: findDurationValue('value', data[dayWiseDataIndex], 'endTime', 'label'),
            };
            returnConstraints[dayKey.toLocaleLowerCase()] = {
                return_time: findDurationValue('value', data[dayWiseDataIndex], 'plugIn', 'label'),
                 on_return: String(Number(data[dayWiseDataIndex].onReturn))
            };
            departureConstraints[dayKey.toLocaleLowerCase()] = {
                departure_time: findDurationValue('value', data[dayWiseDataIndex], 'plugOut', 'label'),
                before_departure: String(Number(data[dayWiseDataIndex].beforeDeparture))
            };
        }
    }
    return {
        departure_constraints: isDaysEnabled ? departureConstraints:null,
        return_constraints: isDaysEnabled ?  returnConstraints : null,
        schedule_constraints: isDaysEnabled ?  scheduleConstraints : null
    };
};

export const updatingScheduleConstraint = (fieldName: string, data: ObjectDetails, value: any, scheduleConstraint: any = [], index: number = 0) => {
    let tempData: ObjectDetails = {
        isEnable: data.isEnable,
        day: data.day,
        dayDisplay: data.dayDisplay,
        plugOut: data.plugOut,
        plugIn: data.plugIn,
        beforeDeparture: data.beforeDeparture,
        onReturn: data.onReturn,
        startTime: data.startTime,
        endTime: data.endTime,
        duration: data.duration,
        isNextDay: data?.isNextDay,
    };
    switch (fieldName) {
        case 'isEnable' :
            if (!value || index === -1) {
                tempData['plugOut'] = ' ';
                tempData['plugIn'] = ' ';
                tempData['beforeDeparture'] = '';
                tempData['onReturn'] = '';
                tempData['startTime'] = ' ';
                tempData['endTime'] = ' ';
                tempData['duration'] = '';
                tempData['isNextDay'] = false;
            } else {
                tempData['plugOut'] = getConstraintvalues('plugOut',scheduleConstraint, index);
                tempData['plugIn'] = getConstraintvalues('plugIn',scheduleConstraint, index); 
                tempData['beforeDeparture'] = getConstraintvalues('beforeDeparture',scheduleConstraint, index);
                tempData['onReturn'] = getConstraintvalues('onReturn',scheduleConstraint, index);
                tempData['startTime'] = getConstraintvalues('startTime',scheduleConstraint, index);
                tempData['endTime'] = getConstraintvalues('endTime',scheduleConstraint, index);
                tempData['duration'] = getConstraintvalues('duration',scheduleConstraint, index);
                tempData['isNextDay'] = getConstraintvalues('isNextDay',scheduleConstraint, index);
            }
            break;
        case 'startTime':
        case 'endTime':
            let start = getStartAndEndTime(fieldName, value, tempData, "start");
            let end = getStartAndEndTime(fieldName, value, tempData, "end");
            let {dur, isNextDay} = calculateTime(start, end);
            tempData['duration'] = dur;
            tempData['isNextDay'] = isNextDay;
            break;
    }
    tempData[fieldName] = value;
    return tempData;
};

const getConstraintvalues = (value: any, scheduleConstraint: any = [], index: number = 0) => {
    return (index > -1) ? scheduleConstraint[index][value] : ' ';
}

const getStartAndEndTime = (fieldName: any, value:any, tempData: any, type: any) => {
    if(type === "start") {
        return (fieldName === 'startTime') ? value : tempData['startTime'];
    } else if(type === "end") {
        return (fieldName === 'endTime') ? value : tempData['endTime'];
    }
}

export const checkForErrorSchedule = (simpleValidator: any, field: string, index: number, dayEnabled: boolean = false) => {
    return dayEnabled && (!simpleValidator.current.fields[`${field}${index}`] && simpleValidator.current.messagesShown);
};

export const checkForDisableClass = (disableBtnStatus:boolean) => {
    return disableBtnStatus ? "disable": "blue_bg";
};

export const constraintRowData: any = [
    { title: 'fleet.departureTime', description: 'fleet.plugOut', displayvalue: "plugOut", type: "scheduleData", borderBottom: false, splitup: false },
    { title: 'fleet.returnPlug', description: 'fleet.plugIn', displayvalue: "plugIn", type: "scheduleData", borderBottom: false, splitup: false },
    { title: 'fleet.buffer', description: 'fleet.beforeDeparture', displayvalue: "beforeDeparture", type: "buffer", borderBottom: true, splitup: true },
    { title: '', description: 'fleet.onReturn', displayvalue: "onReturn", type: "buffer", borderBottom: false, splitup: true },
    { title: 'fleet.scheduleEnergyFlow', description: 'fleet.startTime', displayvalue: "startTime", type: "scheduleData", borderBottom: true, splitup: true },
    { title: '', description: 'fleet.endTime', displayvalue: "endTime", type: "endTime", borderBottom: false, splitup: true },
    { title: 'admessage.duration', description: '', displayvalue: "duration", type: "hours", borderBottom: false, splitup: false },
];

export const validateError = (validatorObj: ObjectDetails, fieldName: string) => {
    if (validatorObj.current.fields[fieldName] === false && validatorObj.current.messagesShown === true) {
        return "error-show";
    }
    else {
        return "";
    }
};