import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { DataGrid } from "@material-ui/data-grid";
import { getMethod, ApiEndPoint } from "services";
import Images from "assets/images";
import StatusButton from "components/statusButton";
import DepotInformation from "./depotInformation";
import {
    pageDetails,
    STATUS,
    TabPanel,
    a11yProps,
    ColumnNameTranslate,
    CheckAccess,
    claims,
    depotGoogleMap,
    GetUrlDetails,
    urlReplace, LOCATION_INFO, rolePermissionCheck, defaultVariable, USAGE_RATE, keyCloakConfig
} from "common";
import ChargeFee from "./chargeFee";
import "./evseDepotDetail.scss";
import EvseDepotChargeHistory from "./evseDepotChargeHistory"; 
import {useHistory} from "react-router-dom";
import EvseDepotAnalytics from "./evseDepotAnalytics";
import CommonPagination from "components/commonPagination";
import {evseOverviewColumns} from "pages/tableColumns";
import { useTranslation } from "react-i18next";
import {Address} from "common/interfaces";
import CommonGoogleMap from "components/commonGoogleMap";
import { useDispatch } from "react-redux";
import {fetchDepotDetails}  from "../../../redux/actions/evseLocations"
import { CircularProgress } from "@material-ui/core";
import SemaConnectChargeFee from "./semaConnectChargeFee";

import WaitList from "./waitList";
import {checkPermission} from "./semaConnectChargeFee/pricingPlanHelpers";
const overviewColumns = evseOverviewColumns;


const useStyles = makeStyles((theme) => ({
  root: {display: 'flex',},
  toolbar: theme.mixins.toolbar,
  content: {flexGrow: 1, padding: theme.spacing(3),},
}));

const EvseDepotDetail = (props: any) => {
  const history = useHistory();
  
  const urlDetails = GetUrlDetails(history,'/evseLocations/evseDepotDetail');
  const [overviewInfo, setOverviewInfo] = useState<any>({});
  const [overviewPageData, setOverviewPageData] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [tabChange, setTabChange] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [value, setValue] = React.useState(0);
  const userRoles = keyCloakConfig?.realmAccess?.roles;
  const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
  const [locationId, setLocationId] = React.useState(urlDetails['locationId']);
  const [depotDetailsData, setDepotDetailsData] = useState<any>({});
  const pageSize = pageDetails.pageSize;
  const { t } = useTranslation();
  const { tab } = GetUrlDetails(history,'/evseLocations/evseDepotDetail');
  const dispatch = useDispatch();
  const [loadingPage, setLoadingPage] = useState(false);
  const [isLocationSubscribedToPricing, setLocationSubscribedToPricing] = useState<any>(null);
  const [isLocationSubscribedToWaitList, setLocationSubscribedToWaitList] = useState<any>(false);
  const [organizationName, setOrganizationName] =  useState("");
  const [waitListPermission, setWaitListPermission] = useState(false);

  useEffect(() => {

    if(urlDetails['tab'] !== ''){
        setValue(parseInt(urlDetails['tab']));
    }

    if(urlDetails['locationId'] !== ''){
        setLocationId(urlDetails['locationId']);
        getHostLocations(urlDetails['locationId']);
        if(isDelegate) {
            getLocationPermission(urlDetails['locationId']).then().catch();;
        }
    }
  }, [tab]);

  useEffect(() => {
    getOverviewData().then().catch();
  }, [currentPage]);

  useEffect(() => {
    handleOverviewPageData()
  }, [overviewInfo]);

  const getOverviewData = async () => {
    setLoadingPage(true);
    await getMethod(ApiEndPoint.locationsV2, '/' + locationId + "/charge-port-details?page=" + currentPage + "&size=" + pageDetails.pageSize).then((res) => {
      setOverviewInfo(res.data);
    }).catch((err) => {
      console.error("Error", err)
    });
    setLoadingPage(false);
  };

  const getHostLocations = async (location:string) => {
    await getMethod(ApiEndPoint.locationsV2, '/' + location)
        .then((res) => {
            if(res?.data){
                setOrganizationName(res?.data?.organizationName);
                setDepotDetailsData(res?.data);
                setLocationSubscribedToPricing(res?.data?.isFlexiblePricingPlan);
                setLocationSubscribedToWaitList(res?.data?.isWaitListEnabled);
                dispatch(fetchDepotDetails(res?.data))
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
  };

    const getLocationPermission = async (locationId: any) => {
        const url = urlReplace(ApiEndPoint.locationPermission, "{locationId}", locationId);
        await getMethod(url, '', false).then((res: any) => {
            if (res) {
                    let locationPermission = (res?.data?.locationLevelPermissions !== null) ? checkPermission(res?.data?.locationLevelPermissions, LOCATION_INFO) : false;
                    setWaitListPermission(locationPermission);
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };


    const statusCheck = (value: any) => {
    if (typeof value === "undefined" || value === null) {
        return 0;
    } else {
        return value;
    }
};

  const setAddressFormat = (key: Address) => {
    if (key) {
      return <>
        <div className="depot_addr">{key.addressLine1 + (key.addressLine2 ? ', ' + key.addressLine2: '')}<br />
          {(key.city ? key.city + ', ' : '') + (key.state ? key.state + ' ' : '') + (key.postalCode ? key.postalCode + ', ': '' )  + (key.country ? key.country : '') }</div>
      </>
    } return ' '
  };
  
  const overviewDataSet = (value: any) => {
    let overviewTableData = [];
    if (value && value.details) {
      overviewTableData = value.details.map((val: any) => ({ ...val, id: val.id }))
    }
    return overviewTableData
  }

  const handleOverviewPageData = () => {
    let totalPage = overviewInfo && overviewInfo.totalCount ? overviewInfo.totalCount : 0;
    if (totalPage > 0) {
      let setPageValue = (currentPage >= 1 ? (pageSize*currentPage) + 1 : 1) + ' - ' + ((pageSize*(currentPage+1)) < totalPage ? (pageSize*(currentPage+1)) : totalPage  ) + ' of '
      setOverviewPageData(setPageValue)
    }
  }

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage)     
  }
  
  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible &&      // to limit setting state only the first time         
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const navigateToDepotStationDetails = (value: any) => {
      let { locationId } = GetUrlDetails(history,'/evseLocations/evseDepotDetail'); 
      let searchParams = "?locationId=" + locationId + "&stationId=" + value?.chargerId;
            history.push({
                pathname:  "/evseLocations/depotStationDetails",
                search: searchParams,
                hash: '#0'
             });
  }
  
  const loactionLevelPermission = depotDetailsData?.locationLevelPermissions;
  const updatePrice = depotDetailsData?.locationLevelPermissions ? checkPermission(depotDetailsData?.locationLevelPermissions, USAGE_RATE) : CheckAccess([claims.PRICING_UPDATE]);
  const updateInfo = checkPermission(depotDetailsData?.locationLevelPermissions, LOCATION_INFO);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  const classes = useStyles();
  const handleChange = (event: any, newValue: any) => {
    if(tabChange !== newValue) {
        setCurrentPage(0);
        setTabChange(newValue);        
    }
    window.scrollTo(0, 0);
    setValue(newValue);

    let { pathname, locationId } = GetUrlDetails(history,'/evseLocations/evseDepotDetail');    
    let searchParams = "?locationId="+ locationId;
    history.push({
      pathname:  pathname,
      hash: '#' + newValue,
      search: searchParams
    });

  };
  
  const canChangeFee = CheckAccess([claims.PRICING_READ, claims.PRICING_UPDATE]) || depotDetailsData?.locationLevelPermissions;

  const hideTab = !canChangeFee ? { display: 'none'} : {};

  return (
    <>
        <main className={classes.content} data-testid="evse_depot_detail">
            <div
                className={
                    isVisible
                        ? "mid_section_inside details_pages"
                        : "mid_section_inside details_pages scroll_effect"
                }
            >
                <div className="depot_details">
                    <div className="depot_detail_head position_fixed_head">
                        <div className="depot_information">
                            <div className="depot_location_map">
                                {depotDetailsData?.address &&
                                    <CommonGoogleMap {...props}
                                        center={{
                                            lat: depotDetailsData?.address?.latitude,
                                            lon: depotDetailsData?.address?.longitude,
                                        }}
                                        zoom={
                                            depotGoogleMap.zoom
                                        }
                                        options={props?.defaultMapOptions}
                                        markers={[
                                            {
                                                lat: depotDetailsData?.address?.latitude,
                                                lon: depotDetailsData?.address?.longitude,
                                            },
                                        ]}
                                    />
                                }
                            </div>
                            <div className="depot_info">
                                <div className="depot_name">
                                    {depotDetailsData.name}
                                </div>
                                {setAddressFormat(depotDetailsData.address)}
                                {isVisible && (
                                    <div className="port_details">
                                        <span className="port">
                                            <img
                                                src={Images.IC_PORT_12x14}
                                                alt="Depot Port"
                                            />
                                            {depotDetailsData?.chargingStations} {t("common.station", { count: depotDetailsData?.chargingStations })}{", "}
                                            {depotDetailsData?.totalPorts} {t("common.port", { count: depotDetailsData?.totalPorts })}
                                        </span>
                                        {/* <span className="alert">
                                            {depotDetailsData?.chargingStations} {t("common.station", { count: depotDetailsData?.chargingStations })}
                                        </span> */}
                                    </div>
                                )} 
                            </div>
                        </div>
                        <div className="stat_list text_right">
                            <StatusButton
                                classType="available"
                                statusLabel={STATUS.AVAILABLE}
                                statusValue={statusCheck(
                                    depotDetailsData.availablePorts
                                )}
                            />
                            {CheckAccess(claims.FORBIDDEN_ACCESS) &&
                                <StatusButton
                                    classType="reserved"
                                    statusLabel={STATUS.RESERVED}
                                    statusValue={statusCheck(
                                        depotDetailsData.reservedPorts
                                      )}
                                  />
                              }
                              <StatusButton
                                  classType="connected"
                                  statusLabel={STATUS.CONNECTED}
                                  statusValue={statusCheck(
                                      depotDetailsData.connectedPorts
                                  )}
                              />
                              <StatusButton
                                  classType="charging"
                                  statusLabel={STATUS.CHARGING}
                                  statusValue={statusCheck(
                                      depotDetailsData.chargingPorts
                                  )}
                              />
                              <StatusButton
                                  classType="complete"
                                  statusLabel={STATUS.COMPLETE}
                                  statusValue={statusCheck(
                                      depotDetailsData.completedPorts
                                  )}
                              />
                              <StatusButton
                                  classType="fault"
                                  statusLabel={STATUS.FAULT}
                                  statusValue={statusCheck(
                                      depotDetailsData.errorPorts
                                  )}
                              />
                              <StatusButton
                                  classType="offline"
                                  statusLabel={STATUS.OFFLINE}
                                  statusValue={statusCheck(
                                      depotDetailsData.offlinePorts
                                  )}
                              />
                          </div>
                      </div>
                      <div className="detail_page_tab">
                          <AppBar
                              className="defult_tab position_fixed_nav"
                              position="static"
                              color="default"
                          >
                              <Tabs
                                  className="tab_nav"
                                  value={value}
                                  onChange={handleChange}
                                  variant="scrollable"
                                  scrollButtons="off"
                                  aria-label="scrollable force tabs example"
                              >
                                  <Tab
                                      className="tab_nav_item"
                                      label={t("common.overview")}
                                      {...a11yProps(0)}
                                  />
                                  <Tab
                                      className="tab_nav_item"
                                      label={t("common.chargehistory")}
                                      {...a11yProps(1)}
                                  />
                                  {/* We need this TAB in upcoming sprint, hiding right now */}
                                  {/* <Tab
                                      className="tab_nav_item"
                                      label={t("common.schedule")}
                                      {...a11yProps(2)}
                                  /> */}
                                  <Tab
                                      className={`tab_nav_item ${!canChangeFee && 'hide-tab'}`}
                                      label={t("common.usagerate")}
                                      style={hideTab}
                                      {...a11yProps(2)}
                                  />

                                  <Tab
                                      className={`tab_nav_item ${!(isDelegate ? (waitListPermission && isLocationSubscribedToWaitList) : isLocationSubscribedToWaitList) && 'hide-tab'}` }
                                      label={t("common.waitlist")}
                                      style={hideTab}
                                      {...a11yProps(3)}
                                  />

                                  <Tab
                                      className="tab_nav_item"
                                      label={t("common.information")}
                                      {...a11yProps(4)}
                                  />
                                  {/* We need this TAB in upcoming sprint, hiding right now */}
                                  {/* <Tab
                                      className="tab_nav_item hide"
                                      label={t("common.analytics")}
                                      {...a11yProps(4)}
                                  /> */}
                              </Tabs>
                          </AppBar>

                          <TabPanel className="tab_detail"{...props} value={value} index={0}>
                            { loadingPage ? <CircularProgress className="inner_progress_userGroup"/> :
                               <div className="table_scroll">
                                <div className="default_table location-overview">
                                    <DataGrid
                                        autoHeight
                                        className="table"
                                        {...props}
                                        rowHeight={59}
                                        headerHeight={30}
                                        rows={overviewDataSet(overviewInfo)}
                                        columns={ColumnNameTranslate(overviewColumns)}
                                        pageSize={12}
                                        onRowClick={(e) =>
                                            navigateToDepotStationDetails(
                                                e?.row
                                            )
                                        }
                                    />
                                </div>
                                <div className="table_footer">
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                        <Grid className="text_left" item xs={8}>
                                            {overviewInfo &&
                                            overviewInfo.totalCount > pageSize ? (
                                                <CommonPagination
                                                    currentPage={currentPage}
                                                    totalCount={overviewInfo?.totalCount}
                                                    callBackCurrentPage={handleCurrentPage}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                        <Grid className="text_right" item xs={4}>
                                            <div className="list_number">
                                                {overviewPageData}{" "}
                                                {overviewInfo.totalCount} {t("common.port", { count: overviewInfo.totalCount })}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            }
                          </TabPanel>

                          <TabPanel className="tab_detail" value={value} index={1}>
                              <EvseDepotChargeHistory
                                  locationId={locationId} isLocationSubscribedToPricing = {isLocationSubscribedToPricing}
                              />
                          </TabPanel>
                          {/* We need this TAB in upcoming sprint, hiding right now */}
                          {/* <TabPanel value={value} index={2}>
                              <EvseSchedule
                                  depotId={locationId}
                              />
                          </TabPanel> */}

                          <TabPanel className={`${!canChangeFee && "hide"}`} value={value} index={2}>
                            {isLocationSubscribedToPricing !== null && isLocationSubscribedToPricing && (
                              <SemaConnectChargeFee locationId={locationId} organizationName={organizationName}  loactionLevelPermission={loactionLevelPermission} />
                            )}
                            {isLocationSubscribedToPricing !== null && !isLocationSubscribedToPricing && (
                              <ChargeFee depotId={locationId} countryCode={depotDetailsData?.address?.countryCode} updatePrice={updatePrice}/>
                            )}
                          </TabPanel>

                          {(isDelegate ? waitListPermission : isLocationSubscribedToWaitList) &&
                              <TabPanel value={value} index={3}>
                                  {<WaitList/>}
                              </TabPanel>
                          }
                          <TabPanel value={value} index={4}>
                              <DepotInformation
                                  depotDetailsData={depotDetailsData}
                                  locationId={locationId}
                                  getHostLocations={getHostLocations}
                                  updateInfo={updateInfo}
                              />
                          </TabPanel>

                          <TabPanel className="hide" value={value} index={5}>
                              <EvseDepotAnalytics />
                          </TabPanel>
                      </div>
                  </div>
              </div>
          </main>
      </>
  );
}
export default EvseDepotDetail