const environment = {
    baseUrl: "https://apigw-feature2-qa.blinkng.com/host",
    semaBaseUrl: "https://apigw-feature2-qa.blinkng.com",
    googleMapKey: "AIzaSyCqFay6qqmew8KKgujKBlhSNzA7RAr4yqQ",
    keyCloakurl: "https://feature2-account-qa.blinkng.com/auth",
    keyCloakRealm: "blinkcharging",
    keyCloakClientId: "host-webapp-localhost",
    googleTimezoneUrl: "https://maps.googleapis.com/maps/api/timezone/json",
    staticSiteUrl: "https://static-qa.blinkng.com",
};
switch (process.env.REACT_APP_ENV) {

    case "development":
        environment.baseUrl = "https://apigw-qa.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-qa.blinkng.com";
        environment.keyCloakurl = "https://account-qa.blinkng.com/auth";
        environment.keyCloakClientId = "host-webapp-qa";
        environment.keyCloakRealm = "blinkcharging";
        environment.staticSiteUrl = "https://static-qa.blinkng.com";
        break;
    
    case "developmentfeature1":
        environment.baseUrl = "https://apigw-feature1-qa.blinkng.com/host";
        environment.keyCloakurl = "https://account-qa.blinkng.com/auth";
        environment.keyCloakClientId = "host-webapp-qa";
        environment.keyCloakRealm = "blinkcharging_feature1";
        environment.staticSiteUrl = "https://feature1-static-qa.blinkng.com";
        break;
    case "developmentfeature2":
        environment.baseUrl = "https://apigw-feature2-qa.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-feature2-qa.blinkng.com";
        environment.keyCloakurl = "https://feature2-account-qa.blinkng.com/auth";
        environment.keyCloakClientId = "host-webapp-qa";
        environment.keyCloakRealm = "blinkcharging";
        environment.staticSiteUrl = "https://feature2-static-qa.blinkng.com";
        break;
    case "developmentfeature3":
        environment.baseUrl = "https://apigw-feature3-qa.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-feature3-qa.blinkng.com";
        environment.keyCloakurl = "https://account-qa.blinkng.com/auth";
        environment.keyCloakClientId = "host-webapp-qa";
        environment.keyCloakRealm = "blinkcharging_feature3";
        environment.staticSiteUrl = "https://feature3-static-qa.blinkng.com";
        break;

    case "stage1uat":
        environment.baseUrl = "https://apigw-uat.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-uat.blinkng.com";
        environment.keyCloakurl = "https://account-uat.blinkng.com/auth";
        environment.keyCloakRealm = "blinkcharging";
        environment.keyCloakClientId = "host-webapp-uat";
        environment.staticSiteUrl = "https://static-uat.blinkng.com";
        break;

    case "stage1load":
        environment.baseUrl = "https://apigw-load.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-load.blinkng.com";
        environment.keyCloakurl = "https://account-load.blinkng.com/auth";
        environment.keyCloakRealm = "blinkcharging";
        environment.keyCloakClientId = "host-webapp-load";
        environment.staticSiteUrl = "https://static-load.blinkng.com";
        break;

    case "staging":
        environment.baseUrl = "https://apigw-stage.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-stage.blinkng.com";
        environment.keyCloakurl = "https://account-stage.blinkng.com/auth";
        environment.keyCloakClientId = "host-webapp-stage";
        environment.keyCloakRealm = "blinkcharging";
        environment.staticSiteUrl = "https://blinkcdn-stage.blinkng.com";
        break;

    case "production":
        environment.baseUrl = "https://apigw.blinknetwork.com/host";
        environment.semaBaseUrl = "https://apigw.blinknetwork.com";
        environment.keyCloakurl = "https://account.blinknetwork.com/auth";
        environment.keyCloakClientId = "host-webapp-demo";
        environment.keyCloakRealm = "blinkcharging";
        environment.staticSiteUrl = "https://blinkcdn.blinknetwork.com";
        break;

    case "demo":
        environment.baseUrl = "https://apigw-demo.blinkng.com/host";
        environment.semaBaseUrl = "https://apigw-demo.blinkng.com";
        environment.keyCloakurl = "https://account-demo.blinkng.com/auth";
        environment.keyCloakClientId = "host-webapp-demo";
        environment.keyCloakRealm = "blinkcharging";
        environment.staticSiteUrl = "https://static-demo.blinkng.com";
        break;

    default:
}

export default environment