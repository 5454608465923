import React, {useState} from "react";
import {manageFleetData} from "services/fleetManagementService";
import {showErrorMessages} from "redux/actions/errorMessages";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {UPDATE_FLEET_VEHICLE, FLEET_LIST_PATH, FLEET_TAB_TWO, DELETE_STATUS} from "pages/fleet/fleetManagementHelper";
import ConfirmDialogPopupComponent from "../../../../components/confirmDialogPopupComponent";

const DeleteGroupVehicle = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {groupVehicle, deleteVehicleId} = props;
    const vehicleId = groupVehicle ? deleteVehicleId?.id : deleteVehicleId;
    const [spinnerOff, setSpinnerOff] = useState(false);
    const fieldDetails: any = [{ id: 1, columnLabel: "fleet.deletevehicleconfirm", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const cancelButtonAction = { isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true , classFormat: ''};
    const removeButtonAction = { isVisible: true, buttonName: 'common.deletebutton', buttonPermssion: true , classFormat: 'red_bg', disabled: false };

    const deleteVehicle = () => {
        setSpinnerOff(true);
        let body = {status: DELETE_STATUS, id: vehicleId,};
        let paramObj = {action: UPDATE_FLEET_VEHICLE};
        manageFleetData(paramObj, body).then((res: any) => {
            if (res.status === true) {
                setSpinnerOff(false);
                props?.deleteVehicleClose();
                if(groupVehicle) {
                    props?.dataRefreshFunction();
                } else {
                    navigateToVehicleList();
                }
            } else {
                dispatch(showErrorMessages({error: true, message: res.message, type: "error", operation: "",}));
                setSpinnerOff(false);
            }
        });
    };

    const navigateToVehicleList = () => {
        props.closeEditVehicle();
        history.push({
            pathname: FLEET_LIST_PATH,
            hash: "#" + FLEET_TAB_TWO,
        });
    };

    return (
        <>
            <div className="dialog_box">
                <ConfirmDialogPopupComponent
                    popUpPageOpen={props?.openDeleteVehicle}
                    popUpPageClose={props?.deleteVehicleClose}
                    headerName={"fleet.deletevehicle"}
                    fieldDetails={fieldDetails}
                    submitAction={removeButtonAction}
                    cancelAction={cancelButtonAction}
                    loading={spinnerOff}
                    submitData={deleteVehicle}
                />
            </div>
        </>
    );
};

export default DeleteGroupVehicle;
