import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, IconButton, Button } from '@material-ui/core';
import Images from 'assets/images';
import { capitalize, toUpper } from 'lodash';
import { ViewDelegateInfo } from 'common/interfaces';
import ViewLocationPermission  from './viewLocationPermission';
import { useTranslation } from "react-i18next"
import { ApiEndPoint, getMethod } from 'services';
import { GetUrlDetails, defaultVariable, CheckAccess, claims, labelMultiInfo, viewLabelInfo, getFormattedDateTimeWithTZ } from 'common';
import {useHistory} from "react-router-dom";
import EditLocationPermissionListing from './editLocationPermissionListing';

const ViewDelegate = (props: ViewDelegateInfo) => {
    
    const { t } = useTranslation();
    const history = useHistory();
    const [openEditlocationPermission, setEditlocationPermissionOpen] = useState(false);
    const scrollEditlocationPermission = defaultVariable.scrollType.paper;
    const [permissionButtonName, setPermissionButtonName] = useState<any>(t("hostsetting.locationpermissionadd"));
    let   fullName = '';
    let { delegateId } = GetUrlDetails(history,'hostSettings');

    if(props?.currentDelegate?.firstName !== undefined){
        fullName = props?.currentDelegate?.firstName + ' ' + props?.currentDelegate?.lastName;
    }

    useEffect(() => {
        !props?.currentDelegate && getDelegatesData();
      }, [delegateId]);

    const getDelegatesData = async (id?:number) => {
        await getMethod(ApiEndPoint.delegatesData, '/' + delegateId, false).then((res) => {
            props?.setCurrentDelegate(res.data);
            }).catch((err) => {
                console.error("Error", err)
        })
    }
    
    const editlocationPermissionOpen = () => {
        setEditlocationPermissionOpen(true);
      };
      const editlocationPermissionClose = () => {
        setEditlocationPermissionOpen(false);
      };

    return (
        <>  
        {!openEditlocationPermission && 
            <div className="information_section delegates_details" data-testid="view_delegate">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <div className="avtar_user mr-40 d-in-b">
                                            { CheckAccess(claims.FORBIDDEN_ACCESS) &&
                                                <div className='avtar_div'>                                                
                                                    <img
                                                        src={props?.currentDelegate?.photoReference ? props?.currentDelegate?.photoReference : Images.delegates_avtar}
                                                        alt="user_img"
                                                    />
                                                </div>
                                            }
                                            <div className="status_details">
                                                <span className="active">
                                                    {capitalize(props?.currentDelegate.status)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="info_detail d-in-b">
                                            { viewLabelInfo( t("common.name"), fullName, 'mt-0', true) }
                                            { labelMultiInfo( t("common.contact"), [{'label': t("common.mobile"), 'value': props?.currentDelegate.mobile}, {'label': t("common.email"), 'value': props?.currentDelegate.email }], 'delegate_contact', true) }
                                        </div>
                                    </Grid>                                  
                                    <Grid item xs={5}>
                                        <div className="info_detail">
                                            { labelMultiInfo(t("common.date"), [{ 'label': t("common.login"), 'value': getFormattedDateTimeWithTZ(props?.currentDelegate?.lastLogin)}, {'label': t("common.creationdate"),
                                            'value': getFormattedDateTimeWithTZ(props?.currentDelegate?.createdTimestamp)}], 'mt-0', true) }                                            
                                            { viewLabelInfo(t("common.notes"), props?.currentDelegate.notes, '', true) }
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="info_detail mg_user">
                                            <div className='tb_info_section mt-0'>
                                                <div className='label'>{toUpper(t("common.userpermission"))}</div>
                                                <div className="result"><span className="label">{t("common.manageusers")}</span><span className="result"> <img
                                                        src={ props?.currentDelegate?.manageUsersEnabled ? Images.ic_done : Images.IC_CLOSE_ASSIGN} alt="manage_users_check_img"
                                                    /></span></div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div className="title_row">
                                            <div className="action_icon">
                                                <IconButton
                                                    className="top_action_btn"
                                                    onClick={props?.editDelegateOpen}
                                                >
                                                    <img
                                                        src={Images.ic_edit}
                                                        alt="edit"
                                                    />
                                                </IconButton>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <div className="button_row text_right">
                    {props?.hasAssignPermission &&
                    <Button
                        className="btn_white blue_bg ml_0"
                        onClick={editlocationPermissionOpen}
                    >
                        { permissionButtonName }
                    </Button>
                    }
                </div>
            </div> }
            { openEditlocationPermission ? 
                <EditLocationPermissionListing 
                openEditlocationPermission = {openEditlocationPermission}
                editlocationPermissionClose = {editlocationPermissionClose}
                scrollEditlocationPermission = {scrollEditlocationPermission}
                setPermissionButtonName = {setPermissionButtonName}
                permissionButtonName = {permissionButtonName}
                locationAssignPermission = {props?.locationAssignPermission}
                userRoles = {props?.userRoles}
                /> : (
                <ViewLocationPermission
                    openEditlocationPermission = {openEditlocationPermission}
                    setPermissionButtonName = {setPermissionButtonName}
                />)
            }
        </>
    );
};

export default ViewDelegate;
