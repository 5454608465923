import {getMethod, ApiEndPoint, postMethod, putMethod} from "services";

export const fetchWaitListConfiguredData = async (params: any, mock = false) => {
    let queryParams = `?location_id=${params.locationId}`;
    const data = await getMethod(ApiEndPoint.waitListConfiguredData,queryParams, mock,false,true)
        .then((res) => {
            if (res && res.data) {
                return res;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchWaitListStations = async (params: any, mock = false) => {
    let queryParams = `?location_id=${params.locationId}`;
    const data = await getMethod(ApiEndPoint.waitListStations,queryParams, mock,false,true)
        .then((res) => {
            if (res && res.data) {
                return res;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const manageWaitListData= async (params: any, body: any ) => {
    let queryParams = `?location_id=${params.locationId}`;
    const data = await putMethod(ApiEndPoint.waitListConfiguredData, queryParams, body ,false, false, true)
        .then((res) => {
            if (res && res.data) {
                return res
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const manageWaitListStations= async (body: any ) => {
    const data = await postMethod(ApiEndPoint.waitListManageStations, '', body ,false,  true)
        .then((res) => {
            if (res && res.data) {
                return res
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};