import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {columnNameTranslate} from "../../../../../common/methodHelpers";
import {WaitListColumns} from "../../../../tableColumns/waitListColumns";
import ManageStations from "./manageStations";
import {CheckAccess, claims, SUCCESS} from "../../../../../common";
import {fetchWaitListStations} from "../../../../../services/waitListService";
import {showErrorMessages} from "../../../../../redux/actions/errorMessages";
import {WAITLIST_ERROR_MESSAGE} from "../waitListHelper";
import {useDispatch} from "react-redux";
import GetUrlDetails from "../../../../../common/urlHelpers";
import {useHistory} from "react-router";

const AddedStations = () => {
    const {t} = useTranslation();
    const history = useHistory();
    let dispatch = useDispatch();
    const showAddStationButton = CheckAccess([claims.WAITLIST_UPDATE]);
    const [openManageStation, setOpenManageStation] = useState(false);
    const {locationId} = GetUrlDetails(history, '');
    const [addedStations, setAddedStations] = useState([]);
    const [allStations, setAllStations] = useState([]);
    const  waitListColumns = WaitListColumns();

    useEffect(() => {
        fetchAddedStationWaitList();
    }, []);

    const dataRefresh = () => {
        fetchAddedStationWaitList();
    };

    const fetchAddedStationWaitList = () => {
        let params = {locationId: locationId};
        fetchWaitListStations(params).then((res: any) => {
            if(res?.status === SUCCESS) {
                let  addedStn: any = [];
                res?.data.map((value: any) => {
                    if(value.is_waitlist_enabled) {
                        addedStn.push(value);
                    }
                });
                setAddedStations(addedStn);
                setAllStations(res?.data);
            } else {
                dispatch(
                    showErrorMessages({error: true, message: t(WAITLIST_ERROR_MESSAGE[res?.data?.error_code]), type: "error", operation: "",})
                );
            }
        }) .catch((err: any) => {
            console.error("Error", err);
        });
    };


    return (
        <>
            <div className="information_section">
                <div className="chargers">
                   <div className="host_settings">
                            <div className="button_row text_right">
                                {(showAddStationButton) &&
                                    <Button className="btn_white blue_bg manage_stn_btn" onClick={() => setOpenManageStation(true)}>
                                        {t("waitlist.managestations")}
                                    </Button>
                                }
                            </div>
                            <div className="table_scroll" data-testid="evse_depot_charge_history">
                                <div className="default_table">
                                    <DataGrid autoHeight className="table"
                                              rowHeight={59}
                                              headerHeight={30}
                                              rows={addedStations}
                                              columns={columnNameTranslate(waitListColumns)}
                                              disableSelectionOnClick/>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <ManageStations openManageStation={openManageStation}
                            setOpenManageStation={setOpenManageStation}
                            stationList={allStations}
                            dataRefresh={dataRefresh}
                            locationId={locationId}/>
        </>
    )
};

export default AddedStations;