import {Dialog, Button, Divider, DialogContent, DialogContentText, Typography, LinearProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import React from "react";

const ConfirmDialogPopupComponent = (props: any) => {
    const {t} = useTranslation();
    const {popUpPageOpen, popUpPageClose, headerName = '', submitAction = {}, cancelAction = {}, fieldDetails = [], submitData, loading = false} = props;

    return (
        <>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section inherit_width width_400 dialog_container_center"
                    open={popUpPageOpen}
                    onClose={popUpPageClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{headerName ? t(headerName) : ''}</h4>
                        <div className="button_row mt-0 full_right">
                            {cancelAction?.buttonPermssion && cancelAction?.isVisible &&
                            <Button className={`btn_white ${cancelAction?.classFormat ? cancelAction.classFormat : ''}`}
                                    onClick={popUpPageClose}>{t(cancelAction?.buttonName)}</Button>}
                            {submitAction?.buttonPermssion && submitAction?.isVisible &&
                            <Button className={`btn_white ${submitAction?.classFormat ? submitAction.classFormat : 'blue_bg'}`}
                                        onClick={submitData}  disabled={submitAction.disabled} >{t(submitAction?.buttonName)}</Button>}
                        </div>
                    </div>
                    {loading ? <LinearProgress/> : <Divider/>}
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form mt-25">
                                <div className="section_group">
                                    <div className="row">
                                        {fieldDetails?.map((val: any) => (
                                            <>
                                                {val?.columnVisible &&
                                                <Typography key={val.id} className={`paragraph ${val?.classFormat ? val.classFormat : ''}`}>
                                                    {val?.LabelFormat ? val?.columnLabel : t(val?.columnLabel)}
                                                </Typography>}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default ConfirmDialogPopupComponent;
