import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {DialogContentText, DialogContent, Button} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ApiEndPoint, getMethod } from "services";
import { ZonesAssignStationComponent } from "pages/tableColumns";

const AssignedStations = (props: any) => {
    const { stationIdsArr } = props?.zoneStationsPost || {};
    const { t } = useTranslation();
    const [zoneStations, setZoneStations] = useState<any>([]);
    const editFormType = "Edit";

    const handleCellChange = (params: any, e: any) => {
        let updatedZoneStations: any = zoneStations.map( (existingZones: any) => {
                if ( existingZones.id === params.id && e.target.checked !== undefined ) {
                    return { ...existingZones, zoneChecked: e.target.checked };
                } else {
                    return existingZones;
                }
            }
        );

        let cellCheck = updatedZoneStations.find( (val: any) => val.zoneChecked === false );

        if (cellCheck) {
            updatedZoneStations = updatedZoneStations.map( (existingZones: any) => {
                    return { ...existingZones, headerCheck: false };
                }
            );
        } else { 
            updatedZoneStations = updatedZoneStations.map( (existingZones: any) => {
                    return { ...existingZones, headerCheck: true };
                }
            );
        }

        setZoneStations(updatedZoneStations);
    };

    const handleColumnChange = (params: any, e: any) => {
        if (params) {
            const updatedZoneStations: any = zoneStations.map(
                (existingZones: any) => {
                    if (
                        e.target.checked !== undefined &&
                        existingZones.zoneDisabled === false
                    ) {
                        return {
                            ...existingZones,
                            zoneChecked: e.target.checked,
                            headerCheck: e.target.checked,
                        };
                    } else {
                        return {
                            ...existingZones,
                            headerCheck: e.target.checked,
                        };
                    }
                }
            );
            setZoneStations(updatedZoneStations);
        }
    };

    const zoneCheckedStatus = (val: string) => {
        if (val === null || val === undefined) {
            return false;
        } else {
            return true;
        }
    };

    const zoneDisableStatus = (val: string) => {
        if (val === null || val === undefined || val === props?.currentZoneId) {
            return false;
        } else {
            return true;
        }
    };

    const callbackMethod = () => {
        // props?.polygonMapMethod(zoneStations);  // hide this action
        props?.viewAssignedStationMethod(props?.pathCoord ?? '', zoneStations);
    };

    const zoneStationsDataSet = (value: any) => {
        let stattionTableData = [];
        if (value && stationIdsArr.length === 0) {
            let headerCheck = value?.filter((val: any) => val?.zoneId !== null && val?.zoneId !== undefined)?.length;
            let headerSet = (headerCheck > 0 && headerCheck === value?.length) ? true : false;
            stattionTableData = value.map((val: any) => ({
                ...val,
                zoneChecked: zoneCheckedStatus(val?.zoneId),
                zoneDisabled: zoneDisableStatus(val?.zoneId),
                headerCheck: headerSet,
            }));
        }
         else {
            stattionTableData = stationIdsArr;
        }
        return stattionTableData;
    };

    useEffect(() => {
        getZoneStations();
    }, []);

    const getZoneStations = async () => {
        await getMethod(
            ApiEndPoint.locations,
            "/" + props?.locationId + "/stations-for-zones"
        )
            .then((res) => {
                setZoneStations(zoneStationsDataSet(res.data));
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    const stationTypeValue = (stationTypeVal: string) => {
        return stationTypeVal === editFormType
            ? t("common.editstations")
            : t("hostsetting.assignlocation");
    };

    return (
        <>
            <div className="dialog_title">
                <h4>{stationTypeValue(props?.formType)} </h4>
                <div className="button_row mt-0 full_right">
                    <Button
                        className="btn_white text_btn_theme"
                        onClick={props?.zoneClose}
                    >
                        {t("common.cancelbutton")}
                    </Button>
                    <Button
                        className="btn_white outline_btn increase_padding"
                        onClick={() => props?.createZoneMethod(props?.formType)}
                    >
                        {t("common.backbutton")}
                    </Button>
                    <Button 
                        className="btn_white blue_bg increase_padding" onClick={callbackMethod}
                    >
                        {t("common.nextbutton")}
                    </Button>
                </div>
            </div>
            <DialogContent dividers="paper" {...props}>
                <DialogContentText id="scroll-dialog-description">
                    <div className="table_scroll">
                        <div className="default_table background-remove">
                            <DataGrid
                                autoHeight
                                vertical-align="top"
                                className="table"
                                {...props}
                                rowHeight={59}
                                headerHeight={30}
                                rows={zoneStations} //delegationData.assignStationRows
                                columns={ZonesAssignStationComponent()}
                                onCellClick={handleCellChange}
                                onColumnHeaderClick={handleColumnChange}
                            />
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    );
};

export default AssignedStations;
