import React, { useState, useEffect } from 'react';
import { Grid, Card, AppBar, Tabs, Tab, CardContent, IconButton } from '@material-ui/core';
import DepotAlerts from './depotAlerts';
import Images from 'assets/images';
import {
    defaultVariable,
    TabPanel,
    a11yProps,
    useStyles,
    GetUrlDetails,
    convertSecondToFormat,
    BLANK_DATA_PLACEHOLDER,
    CheckAccess,
    claims,
    isStationEmpty,
    getFormattedDateTimeWithApiTimezone,
    conversionWattsToKw,
    SOURCE_SEMA,
    SUCCESS,
    urlReplace, rolePermissionCheck, keyCloakConfig
} from 'common';
import { getMethod, ApiEndPoint } from 'services';
import { get, toUpper } from "lodash";
import { useSelector } from "react-redux";
import DepotChargeHistory from './depotChargeHistory';
import DepotAnalytics from './depotAnalytics';
import DepotStationDetailsLocation from './depotStationDetailsLocation';
import EditDepotStationInformation from "./editDepotStationInformation";
import PaginationCard from "components/PaginationCard";
import { DelegateDetails, StationDetails, DepoData } from 'common/interfaces';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {fetchLocationAccess} from 'services/pricingPlanService';
import StationLcdDisplay from './stationLcdDisplay';
import { MODELS_WITHOUT_LCD } from './stationLcdDisplay/stationLcdDisplayHelpers';
import { checkPermission, checkZonePermission, LCD_DISPLAY } from '../evseDepotDetail/semaConnectChargeFee/pricingPlanHelpers';

const DepotStationDetails = (props: StationDetails) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [stationDetails, setStationDetails] = useState<any>({});
  const urlDetails = GetUrlDetails(history, '/evseLocations/depotStationDetails');
  const [isVisible, setIsVisible] = useState(true);
  const [stationId, setStationId] = React.useState(urlDetails['stationId']);
  const [delegateList, setDelegateList] = useState([]);
  const isPublic = stationDetails?.stationDetailsDTO?.isPublic;
  const [isLocationSubscribedToPricing, setLocationSubscribedToPricing] = useState(false);
  const [stationAccessPricingPlan, setStationAccessPricingPlan] = useState<any>(null);
  const pricingReadAccess = CheckAccess([claims.PRICING_READ]);
  const [visibleLcdDisplay, setVisibleLcdDisplay] = useState(false);
  const isDelegate = rolePermissionCheck( keyCloakConfig?.realmAccess?.roles, [defaultVariable.ROLES.delegate]);
  const viewLcdDisplayMessage = CheckAccess([claims.LCD_DISPLAY_READ]) || isDelegate;


  useEffect(() => {
    getDepotStationData();
  }, []);
  
  const getDepotStationData = async () => {
    await getMethod(ApiEndPoint.stationInformation, stationId + "/details?page=0&size=10")
      .then((res) => {
        if(res.status === SUCCESS) {
            setStationDetails(res.data);
            setDelegateList(res.data.delegateDetails);
            getHostLocations(res?.data?.locationDetailsDTO?.locationId);
            let displayLcd = (res?.data?.source === SOURCE_SEMA && !MODELS_WITHOUT_LCD.includes(res?.data?.stationDetailsDTO?.modelNumber) && viewLcdDisplayMessage);
            if(isDelegate) {
                if(displayLcd) {
                    getLocationPermission(res?.data?.locationDetailsDTO?.locationId, res?.data?.stationDetailsDTO?.zoneId);
                }
            } else {
                setVisibleLcdDisplay(displayLcd);
            }
        }
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const getHostLocations = async (location:string) => {
    await getMethod(ApiEndPoint.locationsV2, '/' + location)
        .then((res) => {
            if (res?.data?.isFlexiblePricingPlan) {
                getStationAccess(res?.data?.id);
            }
            setLocationSubscribedToPricing(!!res?.data?.isFlexiblePricingPlan);
        })
        .catch((err) => {
            console.error("Error", err);
        });
  };

  const getLocationPermission = async (locationId:any, zoneId:any) => {
    const url = urlReplace(ApiEndPoint.locationPermission, "{locationId}", locationId);
    await getMethod(url, '', false)
        .then((res:any) => {
            if (res) {
              let locationPermissionForLcd = (res?.data?.locationLevelPermissions !== null) ? 
              checkPermission(res?.data?.locationLevelPermissions, LCD_DISPLAY) : checkZonePermission(res?.data?.zoneLevelPermissions, LCD_DISPLAY  , zoneId);
              setVisibleLcdDisplay(locationPermissionForLcd);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
};


  const getStationAccess = (locationId: any) => {
    let params = { locationId:locationId, stationId:stationId };
     fetchLocationAccess(params)
      .then((res: any) => {
        setStationAccessPricingPlan(res);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const depotDetailsData = useSelector((state: DepoData) => {
    return get(state.depotData, ["depotDetails"], []);
  });

  const stationAccess = (value: boolean) => {
    if (value) {
      return (t("common.public"));
    } else {
      return (t("common.private"));
    }
  };

  const stationRestricted = (value: boolean) => {
    if (value) {
      return (t("common.yes"));
    } else {
      return (t("common.no"));
    }
  };

  const localAuthorization = (value: boolean) => {
    if (value) {
      return (t("common.true"));
    } else {
      return (t("common.false"));
    }
  };

  const stationInfo = (key: string, value: string | boolean) => {
    return (
      <>
        <div className="row">
          <span className="label">{key}</span>
          <span className="result">{value || BLANK_DATA_PLACEHOLDER}</span>
        </div>
      </>
    );
  };

  const creatBox = (detail: DelegateDetails) => {
    const { firstName, lastName, telePhone, mobile, fax, email, notes } = detail.data;
    return (
      <>
        <div className="title_row">
          <h2>{toUpper(t("stationinformation.delegateheader"))}</h2>
          <div className="clearfix"/>
        </div>
        <div className="info_detail">
          <div className="port_details zone_card mt-16 tb_info_section">
            {stationInfo(t("common.name"), firstName + " " + lastName)}
            {stationInfo(t("common.contacts.telephone"), telePhone)}
            {stationInfo(t("common.mob"), mobile)}
            {stationInfo(t("common.contacts.fax"), fax)}
            {stationInfo(t("common.email"), email)}
            {stationInfo(t("common.notes"), notes)}
          </div>
        </div>
      </>
    );
  };

  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  useEffect(() => {
    let urlDetails = GetUrlDetails(history, '/evseLocations/depotStationDetails');
    if (urlDetails['tab'] !== '') {
      setStationId(urlDetails['stationId']);
      setValue(parseInt(urlDetails['tab']));
    }
  }, [urlDetails.tab]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    let { pathname, locationId, stationId } = GetUrlDetails(history, defaultVariable.urlParams.depotStationDetails);
    let searchParams = "?locationId=" + locationId + "&stationId=" + stationId;
    history.push({
      pathname: pathname,
      hash: '#' + newValue,
      search: searchParams
    });

  };

  const [openStationDetail, setStationDetailOpen] = useState(false);

  const stationDetailopen = () => {
    setStationDetailOpen(true);
  };

  const stationDetailclose = () => {
    setStationDetailOpen(false);
  };

  return (
    <>
      <EditDepotStationInformation {...props}
        onClose={stationDetailclose}
        openStationDetail={openStationDetail}
        details={stationDetails?.stationDetailsDTO}
        depotDetails={depotDetailsData}
        getDepotStationData={getDepotStationData}
        stationId={stationId}
        isPublic={isPublic}
        locationInfo={stationDetails}
      />

      {/*----Page Mid Section Start----*/}
      <main className={classes.content} data-testid="depotStationDetails_page">
        <div className={isVisible ? 'mid_section_inside details_pages' : 'scroll_effect'}>
          <div className="depot_details station_details">
            <div className="depot_detail_head position_fixed_head">
              <div className="depot_information">
                {/*----EVSE Depot Map----*/}
                { CheckAccess([claims.FORBIDDEN_ACCESS]) &&
                  <div className="depot_location_map">
                    <img src={Images.ic_port_charger} alt="Port" />
                  </div>
                }
                {/*----EVSE Depot Information----*/}
                <div className="depot_info">
                  <div className="depot_name">
                    <span>
                      { isStationEmpty(stationDetails?.stationDetailsDTO?.name, stationDetails?.stationDetailsDTO?.serialNumber ) }
                    </span>
                  </div>
                  <div className="port_details mt-10">
                    <span className="port">
                      {stationDetails?.stationDetailsDTO?.modelImageReference && <img src={stationDetails?.stationDetailsDTO?.modelImageReference} alt={t("common.depotport")} />}
                      {stationDetails.numberOfPorts} {t("common.port", {count: stationDetails.numberOfPorts})}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="detail_page_tab">
              <AppBar className="defult_tab position_fixed_nav" position="static" color="default">
                <Tabs className="tab_nav"
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example">

                  <Tab className="tab_nav_item" label={t("stationdetails")} {...a11yProps(0)} />
                  <Tab className="tab_nav_item" label={t("chargehistory")} {...a11yProps(1)} />
                  {visibleLcdDisplay ? <Tab className="tab_nav_item" label={t("lcdDisplay.display")} {...a11yProps(2)} /> : <></>}
                  {/* We need this TAB in upcoming sprint, hiding right now */}
                  {/* <Tab className="tab_nav_item" label={t("analytics")} {...a11yProps(2)} /> */}
                </Tabs>

              </AppBar>
              <TabPanel value={value} index={0}>
                <div className="information_section">
                  <Grid container spacing={2}>
                    <Grid className="mid_section_left" item xs={12} sm={12} md={12} lg={8}>
                      <Card variant="outlined" className="basic_card">
                        <CardContent>
                          {/*----Title Start----*/}
                          <div className="title_row">
                            <h2>{toUpper(t("stationinformation.heading"))}</h2>
                            <div className="action_icon">
                              <IconButton className="top_action_btn" 
                               onClick={stationDetailopen}>
                                <img src={Images.ic_edit} alt="edit" />
                              </IconButton>
                            </div>
                            <div className="clearfix"/>
                          </div>
                          {/*----Title End----*/}

                          {/*----Information Start----*/}
                          <div className="info_detail depotstation_infolist">
                            <Grid container>
                              <Grid className="" item xs={12}>
                                <div className="tb_info_section mt-13">
                                {stationDetails?.portDetails?.map((portValue: any) => (
                                    stationInfo(t("chargesessiondetail.unit"), portValue?.portName)
                                  ))}
                                  {stationInfo(t("common.stationname"), isStationEmpty(stationDetails?.stationDetailsDTO?.name, stationDetails?.stationDetailsDTO?.serialNumber))}
                                  {stationInfo(t("stationinformation.chargertype"), stationDetails?.stationDetailsDTO?.chargerType)}
                                  {stationInfo(t("common.stationaccess"), isPublic !== undefined ? stationAccess(isPublic) : isPublic)}
                                  {stationInfo(t("common.restrict"), stationDetails?.stationDetailsDTO?.isRestricted !==undefined ? stationRestricted(stationDetails?.stationDetailsDTO?.isRestricted): "")}
                                  {stationInfo(t("usps.localauthorization"), stationDetails?.stationDetailsDTO?.isLocalAuthorizationEnabled !==undefined ? localAuthorization(stationDetails?.stationDetailsDTO?.isLocalAuthorizationEnabled): "")}
                                  {stationInfo(t("common.map.availablity"), stationDetails?.stationDetailsDTO?.mapAvailabilityDuration)}
                                  {stationInfo(t("common.model"), stationDetails?.stationDetailsDTO?.modelNumber)}
                                  {stationInfo(t("common.serialNo"), stationDetails?.stationDetailsDTO?.serialNumber)}
                                  {stationInfo(t("stationinformation.meid"), stationDetails?.stationDetailsDTO?.meid)}
                                  {stationInfo(t("stationinformation.uniboardno"), stationDetails?.stationDetailsDTO?.uniBoardNo)}
                                  {stationInfo(t("stationinformation.guidcpid"), stationDetails?.stationDetailsDTO?.guid)}
                                  {stationInfo(t("common.make"), stationDetails?.stationDetailsDTO?.make)}
                                  {stationInfo(t("stationinformation.currentversion"), stationDetails?.stationDetailsDTO?.currentFirmwareVersion)}
                                  {stationInfo(t("common.manufactured.date"), getFormattedDateTimeWithApiTimezone(stationDetails?.stationDetailsDTO?.birthDate))}
                                  {stationInfo(t("stationinformation.deploydate"), getFormattedDateTimeWithApiTimezone(stationDetails?.stationDetailsDTO?.deployDate))}
                                  {stationInfo(t("common.laststatusupdate"), getFormattedDateTimeWithApiTimezone(stationDetails?.stationDetailsDTO?.lastKnownDateTime))}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          {/*----Information End----*/}
                        </CardContent>
                      </Card>

                      < DepotStationDetailsLocation
                        stationInfo={stationInfo}
                        locationInfo={stationDetails}
                      />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                      {stationDetails?.portDetails?.map((portValue: any) => (
                        <Card
                          key={portValue?.id}
                          variant="outlined"
                          className="basic_card port_card"
                        >
                          <CardContent>
                            {/*----Title Start----*/}
                            <div className="title_row">
                              <h2><span className="unitno">{t("chargesessiondetail.unit")}</span>{portValue?.portName}</h2>
                              <div className="full_right">
                                <div
                                  className={`status ${defaultVariable.portStatusIndicatorMap[portValue.status]?.bgclass}`}
                                >
                                  <span className="status_text">
                                    {portValue?.status
                                      ? t(defaultVariable.portStatusIndicatorMap[portValue.status]?.text)
                                      : BLANK_DATA_PLACEHOLDER}
                                  </span>
                                </div>
                              </div>
                              <div className="clearfix"/>
                            </div>
                            {/*----Title End----*/}
                            {/*----Information Start----*/}
                            <div className="info_detail">
                              <div className="port_details tb_info_section">
                                {stationInfo(t("stationPortInfo.connection"), portValue.connectorType)}
                                {stationInfo(t("common.starttime"), getFormattedDateTimeWithApiTimezone(portValue?.chargeStartTime))}
                                {stationInfo(t("common.chargetime"), portValue?.chargingTime ? (convertSecondToFormat(Number(portValue.chargingTime), 'HM')) : '')}
                                {stationInfo(t("stationPortInfo.soc"), portValue?.soc  ? (portValue?.soc + "%"): "")}
                                {CheckAccess([claims.FORBIDDEN_ACCESS]) && stationInfo(t("stationPortInfo.soc"), portValue.soc)}
                                {stationInfo(t("stationPortInfo.maximumamps"), portValue.maximumAmps + " " +t("common.amps"))}
                                {stationInfo(t("common.voltage"), portValue.volts + " " + t("common.volts"))}
                                {stationInfo(t("stationPortInfo.maximumpower"), conversionWattsToKw(portValue?.maximumPower, t("common.kw")))}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}

                      {/*----Edit Access Details Start----*/}
                       {isLocationSubscribedToPricing && stationAccessPricingPlan && pricingReadAccess && (
                        <>
                          <Card variant="outlined" className="basic_card">
                            <CardContent>
                              {/*----Title Start----*/}
                              <div className="title_row">
                                <h2>{toUpper(t("pricing.stationaccesspricingplan"))}</h2>
                                <div className="clearfix"/>
                              </div>
                              {/*----Title End----*/}
                              {/*----Edit Start----*/}
                              <div className="info_detail">
                                <div className="port_details zone_card mt-16 tb_info_section">
                                  {stationAccessPricingPlan?.stations && stationInfo(t("pricing.access"), stationAccessPricingPlan?.stations?.access_type)}
                                  {stationAccessPricingPlan?.memberPricing && stationInfo(t("pricing.privateaccessplan"), stationAccessPricingPlan?.memberPricing?.name)}
                                  {stationAccessPricingPlan?.publicPricing && stationInfo(t("pricing.publicplan"), stationAccessPricingPlan?.publicPricing?.name)}
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </>
                      )}
                      <div>
                        {delegateList && delegateList?.length > 0 && (
                          <Card variant="outlined" className="basic_card paginationCard">
                            <CardContent>
                              <>
                                <PaginationCard
                                  data={delegateList}
                                  RenderComponent={creatBox}
                                  pageLimit={delegateList.length}
                                  dataLimit={1}
                                  showIcons={true}
                                />
                              </>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel className="tab_detail" {...props} value={value} index={1}>
                <DepotChargeHistory stationId = {stationId} isLocationSubscribedToPricing = {isLocationSubscribedToPricing}/>
              </TabPanel>
              {(visibleLcdDisplay)&&
                <TabPanel className="tab_detail" {...props} value={value} index={2}>
                    <StationLcdDisplay stationId = {stationId} />
                </TabPanel>
              }
              <TabPanel className="hide" value={value} index={3}>
                <DepotAnalytics />
              </TabPanel>
              <TabPanel className="tab_detail" {...props} value={value} index={4}>
                <DepotAlerts />
              </TabPanel>
            </div>
          </div>
        </div>
      </main>
      {/*----Page Mid Section End----*/}
    </>
  );
};
export {
  DepotStationDetailsLocation,
  EditDepotStationInformation
};
export default DepotStationDetails;
