import React, { useState, useEffect } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import { TabPanel, a11yProps, useStyles, GetUrlDetails } from "common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    VEHICLE,
    FLEET_VEHICLE_INFO_PATH,
} from "pages/fleet/fleetManagementHelper";
import FleetVehicleHeader from "./fleetVehicleHeader";
import { fetchVehicleInfo } from "services/fleetManagementService";
import  FleetVehicleInformation from "./fleetVehicleInfo";
import FleetVehicleAuthCard from "./fleetVehicleAuthCard";
import FleetCommonAlert from "pages/fleet/alert";
import FleetChargers from "../../fleetChargers";
import FleetConstrains from "pages/fleet/fleetConstraints";

const FleetVehicleInfo = (props: any) => {
    const history = useHistory();
    const [tabChange, setTabChange] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const classes = useStyles();
    const { pathname, vehicleId, tab } = GetUrlDetails(
        history,
        FLEET_VEHICLE_INFO_PATH
    );
    const [vehicleinfo, setVehicleInfo] = useState<any>([]);
    const [editableinfo, setEditInfo] = useState<any>([]);
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [groupId, setGroupId] = useState<any>('');

    useEffect(() => {
        if (tab !== "") {
            setTabChange(parseInt(tab));
            setValue(parseInt(tab));
        }
    }, [tab]);

    const handleChange = (event: any, newValue: any) => {
        if (tabChange !== newValue) {
            setTabChange(newValue);
        }
        window.scrollTo(0, 0);
        setValue(newValue);
        let searchParams = "?vehicleId=" + vehicleId;
        history.push({
            pathname: pathname,
            hash: "#" + newValue,
            search: searchParams,
        });
    };

    useEffect(() => {
        const listenToScroll = () => {
            let heightToHideFrom = 50;
            const winScroll =
                document.body.scrollTop || document.documentElement.scrollTop;
            if (winScroll > heightToHideFrom) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    useEffect(() => {
        if (tab !== "") {
            setValue(parseInt(tab));
        }
    }, [tabChange, tab]);

    useEffect(() => {
        getVehicleInfo();
    }, []);

    const getVehicleInfo = () => {
        setSpinnerOff(true);
        let params = { vehicleId: vehicleId };
        fetchVehicleInfo(params, false, false, true)
            .then((res: any) => {
                if (res) {
                    setVehicleInfo(res);
                    let initialValue = {
                        host_id: res[0]?.host_id,
                        vehicleName: res[0]?.vehicleName,
                        vin: res[0]?.vin,
                        make: res[0]?.make,
                        model: res[0]?.model,
                        year: res[0]?.year,
                        efficiency: res[0]?.milege,
                        fleetGroup: res[0]?.fleet_groups_id,
                    };
                    setEditInfo(initialValue);
                    setGroupId(res[0]?.fleet_groups_id);
                    setSpinnerOff(false);
                }
            })
            .catch((err: any) => {
                console.error("Error", err);
            });
    };

    return (
        <>
            <main className={classes.content}>
                <div
                    className={
                        isVisible
                            ? "mid_section_inside details_pages"
                            : "mid_section_inside details_pages scroll_effect"
                    }
                >
                    <div className="depot_details members station_details">
                        <div className="depot_detail_head position_fixed_head">
                            <FleetVehicleHeader
                                vehicleinfo={vehicleinfo}
                            />
                        </div>
                        <div className="detail_page_tab">
                            <AppBar
                                className="defult_tab position_fixed_nav"
                                position="static"
                                color="default"
                            >
                                <Tabs
                                    className="tab_nav"
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="off"
                                    aria-label="scrollable force tabs example"
                                >
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.evse")}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.blinkcards")}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("fleet.constraints")}
                                        {...a11yProps(2)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("fleet.notifications")}
                                        {...a11yProps(3)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.information")}
                                        {...a11yProps(4)}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <FleetChargers groupId={groupId} vehicleId={vehicleId} isGroupLoading={spinnerOff}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <FleetVehicleAuthCard groupId={groupId} vehicleId={vehicleId} isGroupLoading={spinnerOff}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                            <FleetConstrains groupId={groupId} vehicleId={vehicleId} activeTab={tabChange} isGroupLoading={spinnerOff}/>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <FleetCommonAlert type={VEHICLE} groupId={groupId}  vehicleId={vehicleId} />                          
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <FleetVehicleInformation
                                    getvehicleinfo={getVehicleInfo}
                                    vehicleinfo={vehicleinfo}
                                    editableinfo={editableinfo}
                                    spinnerOff={spinnerOff}
                                    groupId={groupId}
                                    vehicleId={vehicleId}
                                    setGroupId={setGroupId}
                                />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default FleetVehicleInfo;
