import React, { useState, useEffect } from "react";
import { ListItem, Card, DialogContent, Grid, } from "@material-ui/core";
import environment from 'environment';
import GoogleMapReact from "google-map-react";
import {depotGoogleMap, setAddressFormat, mapStyle, SUCCESS, getBlankCurrencyPlaceholder, COUNTRY_CODE} from "common";
import { useTranslation } from "react-i18next";
import Images from 'assets/images';
import "./commonChargeSessionDetails.scss";
import { currencyAmountValidate } from "common/methodHelpers";
import { chargingSessionAppliedFeeId, fetchChargeSessionTransactionData, flexiblePricingInvoiceDetails } from "services/pricingPlanService";
import FlexiblePricingDetail from "./flexiblePricingDetail";
import { convertSecondToTimeFormat, displayFeeAndUnitPrice, getUnitValue, transactionInfo } from "./transactionDetailsHelper";
import EnergyOccupancyDetails from "./energyOccupancyDetails";

const TransactionDetail = (props: any) => {
    const { historyId, serialNumber } = props;
    const { t } = useTranslation();
    const [sessionTransactionDetails, setSessionTransactionDetails] = useState<any>({});
    const [pricingPlanInvoiceDetails, setPricingPlanInvoiceDetails] = useState<any>({});
    const [appliedFeeDetails, setAppliedFeeDetails] = useState<any>({});
    const [isLocationSubscribedToPricing, setIsLocationSubscribedToPricing] = useState(false);
    const defaultMapOptions = {
        fullscreenControl: false,
        scrollwheel: false,
        gestureHandling: 'none',
        zoomControl: false
    };
    const [appliedFeeId, setAppliedFeeId] = useState("");
    const [appliedFeeAvailable, setAppliedFeeAvailable] = useState(false);

    const getChargeSessionTransactionData = () => {
        fetchChargeSessionTransactionData(historyId).then((res: any) => {
            if (res.status === SUCCESS) {
                setSessionTransactionDetails(res.data);
                setAppliedFeeId(res.data.appliedFeeId);
                setIsLocationSubscribedToPricing(res?.data.locationDetails?.isFlexiblePricingPlan);
            }
        }).catch((err) => {
            console.error("Error", err);
        });
    };

    const getPricingTransactionData = () => {
        let params = { "chargingSessionId": historyId };
        flexiblePricingInvoiceDetails(params).then((res: any) => {
            if (res) {
                setPricingPlanInvoiceDetails(res);
            }
        }).catch((err: any) => {
            console.error("Error", err)
        })
    };

    const getChargingAppliedFee = () => {
        let params = { "appliedFeeId": appliedFeeId };
        chargingSessionAppliedFeeId(params).then((res: any) => {
            if (res) {
                setAppliedFeeDetails(res);
                setAppliedFeeAvailable(true);
            }
        }).catch((err: any) => {
            console.error("Error", err)
        })
    };

    useEffect(() => {
        getChargeSessionTransactionData();
    }, [historyId]);

    useEffect(() => {
        if (isLocationSubscribedToPricing) {
            getPricingTransactionData();
        }
    }, [isLocationSubscribedToPricing]);

    useEffect(() => {
        if (!isLocationSubscribedToPricing && appliedFeeId) {
            getChargingAppliedFee();
        }
    }, [isLocationSubscribedToPricing, appliedFeeId]);

    const ChargerName = (value: any) => {
        if (value) {
            return value;
        } return serialNumber;
    };


    return (
        <>
            <DialogContent>
                <Grid container className="list-detail" spacing={0} justifyContent="space-evenly">
                    <Grid item xs={7}>
                        <h3>{t("common.transaction")}</h3>
                        <div className="section-group mt-20 padding-right">
                            <div className="section-group">
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>{transactionInfo(t("common.transactiondate"), sessionTransactionDetails?.transactionTime)}</Grid>
                                    <Grid item xs={12}>
                                        {transactionInfo(t("chargesessiondetail.transactionid"), sessionTransactionDetails?.transactionId)}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="section-group mt-15">
                                <h3>{t("stationdetails")}</h3>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        {transactionInfo(t("chargesessiondetail.unit"), sessionTransactionDetails?.port)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {transactionInfo(t("common.chargingstation"), ChargerName(sessionTransactionDetails?.chargerName))}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {transactionInfo(t("common.serialnumber"), sessionTransactionDetails?.serialNumber)}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="section-group mt-15">
                                <h3>{t("common.sessiondetail")}</h3>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        {transactionInfo(t("common.startchargeession"), sessionTransactionDetails?.startSession)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {transactionInfo(t("common.endchargesession"), sessionTransactionDetails?.endTime)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {transactionInfo(t("common.blinkcodecard"), sessionTransactionDetails?.rfid)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {transactionInfo(t("common.sessiontime"), convertSecondToTimeFormat(sessionTransactionDetails?.sessionTime))}
                                    </Grid>
                                    {sessionTransactionDetails?.sessionPriceDetails?.chargingPriceSegments?.map(
                                        (priceValue: any) => (
                                            <EnergyOccupancyDetails isEnergyDetail={true} priceValue={priceValue} sessionTransactionDetails={sessionTransactionDetails} key={'energy'} isLocationSubscribedToPricing={isLocationSubscribedToPricing}/>
                                        ))}
                                    {sessionTransactionDetails?.sessionPriceDetails?.parkingPriceSegments?.map(
                                        (priceValue: any) => (
                                            <EnergyOccupancyDetails isEnergyDetail={false} priceValue={priceValue} sessionTransactionDetails={sessionTransactionDetails} key={'occupancy'} isLocationSubscribedToPricing={isLocationSubscribedToPricing}/>
                                        ))}
                                </Grid>
                            </div>
                            <div className="section-group mt-15">
                                <div className="gray_bg">
                                    <Grid container spacing={0}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={5}>
                                                {transactionInfo(t("chargesession.chargingbillabletime"), convertSecondToTimeFormat(sessionTransactionDetails?.chargingTime))}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {transactionInfo(t("common.energy.kwh"), sessionTransactionDetails?.totalChargingKwh?.toFixed(2))}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {transactionInfo(t("chargesessiondetail.totalfee"),
                                                    (sessionTransactionDetails?.totalFee) ? currencyAmountValidate(sessionTransactionDetails?.currencySymbol, sessionTransactionDetails?.totalFee) : getBlankCurrencyPlaceholder(sessionTransactionDetails?.currencySymbol),
                                                    sessionTransactionDetails?.totalFee,
                                                    sessionTransactionDetails?.locationDetails?.address?.countryCode === COUNTRY_CODE.ISRAEL ? t('common.sablabel.totalfee') : '')}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="text_left " item xs={5}>
                        <h3>{t("station.locationdetail")}</h3>
                        <div className="section-group mt-20">
                            {transactionInfo(t("common.locationname"), sessionTransactionDetails?.locationDetails?.locationName)}
                            {transactionInfo(t("common.locationaddress"), setAddressFormat(sessionTransactionDetails?.locationDetails?.address))}
                            <ListItem>
                                {sessionTransactionDetails?.locationDetails?.address &&
                                    <div className="map_section map_width">
                                        <div className="map_inside map_minheight240">
                                            <div className="map_height240">
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: environment.googleMapKey, }}
                                                    defaultCenter={{
                                                        lat: sessionTransactionDetails.locationDetails.address.latitude,
                                                        lng: sessionTransactionDetails.locationDetails.address.longitude
                                                    }}
                                                    defaultZoom={depotGoogleMap.zoom}
                                                    options={defaultMapOptions}>
                                                    <img src={Images.MAP_MARKER} alt={t("common.marker")} style={mapStyle} />
                                                </GoogleMapReact>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </ListItem>
                        </div>
                        <h3 className="price">{t("common.pricedetail")}</h3>
                        {isLocationSubscribedToPricing ?
                            <FlexiblePricingDetail
                                pricingPlanInvoiceDetails={pricingPlanInvoiceDetails}
                                sessionTransactionDetails={sessionTransactionDetails} />
                            :
                            <>
                                {appliedFeeAvailable && appliedFeeDetails &&
                                    <Card className="pricedetail-card" variant="outlined">
                                        <div className="pt-10">
                                            {transactionInfo(t("chargesessiondetail.chargingunitprice"), t("chargesessiondetail.chargingunit", { chargingPrice: displayFeeAndUnitPrice(sessionTransactionDetails, false), chargingUnit: getUnitValue(sessionTransactionDetails, t, false)}))}
                                            {transactionInfo(t("chargesessiondetail.occupancyunitprice"), t("chargesessiondetail.occupancyUnit", { occupancyPrice: displayFeeAndUnitPrice(sessionTransactionDetails, true), occupancyUnit: getUnitValue(sessionTransactionDetails, t, true)}))}
                                            {transactionInfo(sessionTransactionDetails?.locationDetails?.address?.countryCode === COUNTRY_CODE.ISRAEL ? t("common.vat") : t("common.taxrate"), (appliedFeeDetails?.globalTaxRate?.toString() ? appliedFeeDetails.globalTaxRate + "%" : "0%"))}
                                            {transactionInfo(t("common.servicefee"), currencyAmountValidate(sessionTransactionDetails?.currencySymbol, sessionTransactionDetails?.sessionPriceDetails?.serviceFee), sessionTransactionDetails?.sessionPriceDetails?.serviceFee)}
                                            {transactionInfo(t("common.charginggraceperiod"), convertSecondToTimeFormat(appliedFeeDetails?.sessionFreeChargingTime))}
                                            {transactionInfo(t("common.occupancygraceperiod"), convertSecondToTimeFormat(appliedFeeDetails?.occupancyGracePeriod))}
                                        </div>
                                    </Card>
                                }
                            </>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};


export default TransactionDetail;