import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from "@material-ui/core";
import { defaultVariable, LabelInfo } from "common";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { postMethod, ApiEndPoint } from "services";

const UserGroupCreate = (props: any) => {
  const { t } = useTranslation();
  const [notesCharCount, setNotesCharCount] = React.useState(0);

  const { register, reset, handleSubmit, formState: { errors } } = useForm();
  const defaultNewGroup = defaultVariable.defaultNewGroup
  const WORD = defaultVariable.textAreaLength;


  const onSubmit = async (data: any, e: any) => {

      await postMethod(ApiEndPoint.groupDetails, '', data)
          .then((res) => {
              e.target.reset(); // reset after form submit
              setNotesCharCount(0)
              props.createNewGroupClose(true);
          })
          .catch((err) => {
              e.target.reset(); // reset after form submit
              setNotesCharCount(0)
          });
  };

  const resetForm = () => {
      reset(defaultNewGroup);
      setNotesCharCount(0)
      props.createNewGroupClose(true);
  }

  useEffect(() => {
      reset(defaultNewGroup);
  }, [props.openCreateNewGroup])

  return (
      <>
          <div className="dialog_box" data-testid="user_group_create">
              <Dialog
                  className="dialog_container edit_detail_section zone_settings_dialog dialog_container_center"
                  {...props}
                  open={props.openCreateNewGroup}
                  onClose={resetForm}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
              >
                  <form id="hook-form"  onSubmit={handleSubmit(onSubmit)}>
                  <div className="dialog_title">
                      <h4>{t("user.createnewusergroup")}</h4>
                      <div className="button_row mt-0 full_right">
                          <Button
                              className="btn_white text_btn_theme"
                              onClick={resetForm}
                          >
                              {t("common.cancel")}
                          </Button>
                          <Button   type="submit"  className="btn_white blue_bg">
                              {t("common.create")}
                          </Button>
                      </div>
                  </div>
                  <DialogContent>
                      <DialogContentText id="scroll-dialog-description">
                          <div className="information_section delegates_details">
                              <div className="defult_form mt-10">
                                  <div className="section_group">
                                      <Grid
                                          container
                                          justifyContent="flex-start"
                                          alignItems="flex-start"
                                          spacing={4}
                                      >
                                          <Grid item xs={12}>
                                          <div className={`form_col ${errors?.name && 'error'}`}>
                                                  <Grid
                                                      className="single_line"
                                                      container
                                                      justifyContent="flex-start"
                                                      alignItems="flex-start"
                                                      spacing={0}
                                                  >
                                                      <Grid item xs={12}>
                                                      <LabelInfo name={t("common.name")} isMandatory={true}/>
                                                      <TextField {...register("name", { required: true })}  variant="outlined" defaultValue="" />
                                                      {errors?.name && <span className="error_msg">{t("evselocation.usergroup.groupname.required")}</span>}
                                                      </Grid>
                                                  </Grid>
                                              </div>
                                          </Grid>
                                      </Grid>
                                  </div>
                                  <div className="section_group">
                                      <div className="form_col">
                                      <Grid
                                      className="single_line textarea"
                                      container
                                      justifyContent="flex-start"
                                      alignItems="flex-start"
                                      spacing={0}
                                      >
                                      <Grid item xs={12}>
                                      <LabelInfo name={t("common.notes")} isMandatory={false}/>
                                      <TextField
                                          multiline
                                          rows={4}
                                          {...register("notes")}
                                          helperText={
                                            notesCharCount + ' ' + t("common.textarea400characters",{WORD})
                                        }
                                          onChange={e => {setNotesCharCount(e.target.value.length) }}
                                          variant="outlined"
                                          inputProps={{ maxLength: defaultVariable.TEXTAREALENGTH }}
                                      />
                                      </Grid>
                                      </Grid>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </DialogContentText>
                  </DialogContent>
                  </form>
              </Dialog>
          </div>
      </>
  );
};
export default UserGroupCreate;
