import React from "react";
import {useTranslation} from "react-i18next";
import {toUpper} from "lodash";
import Images from "../../assets/images";
import { REPORT_TYPE_MAPPING} from "../../common";

export const MonthlyReportsColumns =()=> {
    const {t} = useTranslation();

    const waitListStationColumns = [
        {
            field: "firstCell",
            headerName: toUpper(t("common.firstcell")),
            headerClassName: "empty",
            sortable: true,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "type",
            headerName: toUpper(t("common.type")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            flex: 0.4,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div>{REPORT_TYPE_MAPPING[cellValues.value]}</div>;
            },
        },
        {
            field: "year",
            headerName: toUpper(t("fleet.make_model_year")),
            headerClassName: "action_columns",
            sortable: true,
            hasFocus: false,
            cellClassName: "",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "month",
            headerName: toUpper(t("pricing.month")),
            headerClassName: "action_columns",
            sortable: true,
            hasFocus: false,
            cellClassName: "",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "created_on",
            headerName: toUpper(t("common.creationdate")),
            headerClassName: "action_columns",
            sortable: true,
            hasFocus: false,
            cellClassName: "",
            flex: 0.4,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "created_on",
            headerName: toUpper(t("common.creationdate")),
            headerClassName: "action_columns",
            sortable: true,
            hasFocus: false,
            cellClassName: "",
            flex: 0.4,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "url",
            headerName: "",
            headerClassName: "empty",
            cellClassName: "",
            sortable: false,
            hasFocus: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (cellValues: any) => {
                return (
                    <div className="cancel-row button_pointer mt-20">
                        <img src={Images.IC_DOWNLOAD}  alt="" />
                    </div>
                );
            },
        },
        {
            field: "lastCell",
            headerName: toUpper(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        }
    ];

    return waitListStationColumns
};
