import React from "react";
import Images from "assets/images";
import { BLANK_DATA_PLACEHOLDER, fieldDataCheck, convertSecondToFormat, renderCellExpand } from 'common';
import { useTranslation } from "react-i18next";
import { toLower, toUpper } from "lodash";

export const StationChargeHistoryColumnsComponent = () => {
    const { t } = useTranslation();
    const depotChargeHistoryColumns = [
        {
            field: "firstCell",
            headerName: (t("common.firstcell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },
        {
            field: "portInformation",
            headerName: toUpper(t("common.port")),
            sortable: false,
            hasFocus: false,
            cellClassName: "bold_cell_text",
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="port_icon">
                        <img src={Images.IC_EVSE_BLUE} alt={t("common.editevse")} />
                        {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                    </div>
                );
            }
        },
        {
            field: "startChargeTime",
            headerName: toUpper(t('common.starttime')),
            sortable: false,
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues?.value);
            },
           
        },
        {
            field: "endChargeTime",
            headerName: toUpper(t("common.endtime")),
            sortable: false,
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues?.value);
            },
        },
        {
            field: "occupancyTime",
            headerName: toUpper(t("common.occupancytime")),
            sortable: false,
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {convertSecondToFormat(Number(cellValues?.value), 'HM')}
                    </>
                );
            }
        },
        {
            field: "chargingTime",
            headerName: toUpper(t("common.chargingtime")),
            sortable: false,
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                     {convertSecondToFormat(Number(cellValues?.value), 'HM')}
                    </>
                );
            }
        },
        {
            field: "time",
            headerName: toUpper(t("common.sessiontime")),
            sortable: false,
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                     {convertSecondToFormat(Number(cellValues?.value), 'HM')}
                    </>
                );
            }
        },
        {
            field: "energyKWh",
            headerName: t("common.totalenergykwh"),
            sortable: false,
            flex:0.1,
            minWidth:100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                    </>
                );
            }
        },
        {
            field: "amount",
            headerName: toUpper(t("common.amount")),
            sortable: false,
            width:140,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                const currValue = cellValues?.row?.currency ? (cellValues.row.currency) : '';
                return (
                    <>
                         {cellValues.value ? (currValue + cellValues.value ) : BLANK_DATA_PLACEHOLDER}
                    </>
                );
            }
        },
        {
            field: "lastCell",
            headerName: toLower(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="empty">
                        {cellValues.value}
                    </div>
                );
            }
        },

    ]
    return depotChargeHistoryColumns;
}