import React, { useState, useEffect } from "react";
import {
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  MenuItem,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toUpper } from "lodash";
import CommonGoogleMap from 'components/commonGoogleMap/index';
import {
  MODE_TYPE,
  GetUrlDetails,
  mapDetails,
  urlReplace,
  LabelInfo,
  defaultVariable,
  rolePermissionCheck,
  CheckAccess,
  claims,
  keyCloakConfig,
} from "common";
import { ObjectDetails } from "common/interfaces";
import { ApiEndPoint, getMethod, putMethod } from "services";
import { useHistory } from "react-router-dom";


const ZoneLocationFormDetails = (props: any) => {
  const history = useHistory();
  const isNewMode = props?.formType === MODE_TYPE.New ? true : false;
  const disableLocationSelect = isNewMode ? false : true;
  enum View {
    Location,
    Zone,
  }
  const { userGroup } = GetUrlDetails(history, "userGroup");
  const [locationList, setLocationList] = useState<any>([]);
  const [locationDropDown, setLocationDropDown] = useState<any>([]);
  const [locationId, setLocationId] = useState<any>();
  const { t } = useTranslation();
  
  const userRoles = keyCloakConfig?.realmAccess?.roles;
  const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
  const [disableLocationRadioBtn, setDisableLocationRadioBtn] = useState(!(!isDelegate || !isNewMode));
  const [locationZonesData, setlocationZonesData] = useState<any>([]);
  const [locationStationsData, setLocationStationsData] = useState<any>([]);
  const [locationIdData, setLocationIdData] = useState<any>();
  const [checkedZonesData, setCheckedZonesData] = useState<any>([]);
  const [locationMatchedData, setlocationMatchedData] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const editFormType = MODE_TYPE.Edit;
  const [locationZone, setLocationZone] = useState<any>(
    (isNewMode || !props?.isZoneAvailable)? View.Location : View.Zone
  );
  const locationCheckStatus = (isNewMode || !props?.isZoneAvailable)? true : false ;
  const [locationChecked, setLocationChecked] = useState<any>(locationCheckStatus);
  const [loadingPage, setLoadingPage] = useState<any>(false);

  const getEvseLocations = async () => {
    const url = urlReplace(ApiEndPoint.manageUserLocationListV2, "{USER-GROUP-ID}", userGroup);
    await getMethod(url, '')
      .then((res) => {
        if (res.status === 200 && res?.data && res?.data?.locations?.length > 0) {
          setlocationMatchedData(res?.data?.locations);
        }

      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const getAvailableLocationData = async () => {
    const url = urlReplace(ApiEndPoint.parentHostLocationList, "", "");
    setLoadingPage(true);
    await getMethod(url, '')
      .then((res: any) => {
        if (res?.data) {
          setLoadingPage(false)
          let locations = res.data;

          if (res?.status === 200 && locations && isNewMode) {
            setLocationList(locations.locationsInfo);
          } else if (res?.status === 200 && locations && !isNewMode) {
            setLocationId(props?.currentLocZoneDetails?.locationId);
            setLocationDropDown(locations.locationsInfo);
            getLocationDetails(props?.currentLocZoneDetails?.locationId);
          }
        }
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const getLocationIdData = async (locationId?: string) => {
    if (locationId) {
      const url = urlReplace(
        ApiEndPoint.locationDataV2,
        "{LOCATION-ID}",
        locationId
      );

      await getMethod(url, "")
        .then((res: any) => {
          if (res.data) {
            setLocationIdData(res.data);
          }
        })
        .catch((err) => {
          console.error("Error", err);
        });
    }
  };

  const getLocationData = async (locationId?: string) => {
    if (locationId) {
      const url = urlReplace(
        ApiEndPoint.locationStationZoneData,
        "{LOCATION-ID}",
        locationId
      );

      await getMethod(url, "")
        .then((res: any) => {
          setLocationStationsData(res.data);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    }
  };

  const getLocationZones = async (locationId?: string) => {
    if (locationId) {
      const url = urlReplace(
        ApiEndPoint.locationZones,
        "{LOCATION-ID}",
        locationId
      );

      await getMethod(url, "")
        .then((res: any) => {
          if (res?.status === 200 && res?.data && res?.data?.zones?.length > 0) {
            let finalZone = res?.data?.zones?.map((zoneValue: any) => {
              let zoneItem = props?.currentLocZoneDetails?.zones?.find((findZone: any) => findZone.id === zoneValue.id)

              zoneValue.state = zoneItem
                ? true
                : false
              return zoneValue
            })
            setlocationZonesData(finalZone);
            setCheckedZonesData(finalZone?.filter((value: any) => value.state === true)?.map((zone: any) => {
              return zone?.id;
            }));
          }else{
            setlocationZonesData([]);
            
          }
        })
        .catch((err) => {
          console.error("Error", err);
        });
    }
  };

  const handleLocationChange = (e: any) => {
    setLocationChecked(locationCheckStatus);
    setLocationId(e.target.value);
    getLocationDetails(e.target.value);
    
    const locationObj = locationDropDown.find((obj: any) => obj.locationId === e.target.value);
    if (locationObj) {
      const isZoneLevel = locationObj?.isZoneLevelPermissionApplied === true;
      setDisableLocationRadioBtn(isZoneLevel);
      setLocationZone(isZoneLevel ? View.Zone : View.Location);
    }
  };

  const handleLocationZoneChange = (event: any) => {
    setLocationChecked(locationCheckStatus);
    setLocationZone(parseInt(event.target.value));
    getLocationDetails(locationId);
  };

  const getLocationDetails = (locationId: string) => {
    getLocationData(locationId);
    getLocationZones(locationId);
    getLocationIdData(locationId);
  };

  const handleDropdownData = () => {
    let filtredLocationData = locationList?.filter(function (obj: any) {
      return !locationMatchedData?.some(function (obj2: any) {
        return obj.locationId === obj2.locationId;
      });
    });
    if (filtredLocationData.length > 0) {
      setErrorMessage("");
      setLocationId(filtredLocationData[0]?.locationId);
      setLocationZone(filtredLocationData[0]?.isZoneLevelPermissionApplied === true ? View.Zone : View.Location);
      setDisableLocationRadioBtn(filtredLocationData[0]?.isZoneLevelPermissionApplied === true);
      setLocationDropDown(filtredLocationData);
      getLocationDetails(filtredLocationData[0]?.locationId);
    }else{
      setErrorMessage(t("manageuser.locationzone.message"))
    }
  };

  useEffect(() => {
    if (isNewMode === true) {
      getEvseLocations();
      getAvailableLocationData();
    } else {
      getAvailableLocationData();
    }
  }, [isNewMode]);


  useEffect(() => {
    if (isNewMode === true && locationList?.length > 0) {
      handleDropdownData()
    }
  }, [locationList, locationMatchedData]);

  const latLongValue = {
    lat: locationIdData?.address?.latitude,
    lon: locationIdData?.address?.longitude,
  };

  const formTypeValue = (formTypeVal: number) => {
    return formTypeVal === editFormType
      ? t("common.update.location.zone")
      : t("common.assign.location.zone");
  };

  const handleSelectionChange = (zoneId: any, e: any) => {

    let updatedCheckedState: any = locationZonesData.map((existingZones: any) => {
      if (existingZones.id === zoneId) {
        return { ...existingZones, state: e.target.checked };
      } else {
        return existingZones;
      }
    }
    );
    if (updatedCheckedState?.length > 0) {
      setlocationZonesData(updatedCheckedState)
      let ZonesArr: any[] = [];
      ZonesArr = updatedCheckedState?.filter((value: any) => value.state === true)?.map((zone: any) => {
        return zone?.id;
      });
      setCheckedZonesData(ZonesArr);
    }

  };

  const checkZoneIds = () => {
    let params;
    if (locationZone === View.Zone) {
      let zoneStatus = checkedZonesData?.length > 0 ? ("&zoneIds=" + checkedZonesData.toString()) : "&mapCompleteLocation=false" ;
      params =
        "?locationId=" +
        locationId + zoneStatus;
    } else {
      params =
        "?locationId=" + locationId +
        "&mapCompleteLocation=" + locationChecked ;
    }
    return params;
  };

  const onSubmit = async () => {
    const url = urlReplace(
      ApiEndPoint.groupDetailLocationZones,
      "{HOSTMEMBERGROUP-ID}",
      userGroup
    );

    await putMethod(url, checkZoneIds(), [])
      .then((res) => {
        props?.zoneClose(true);
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const handleLocationCheck = (e:any) => {
    setLocationChecked(e.target.checked)
  }

  return (
    <>
      <form data-testid="zonelocationformdetails-dialog">
        <div className="dialog_title">
          <h4>{formTypeValue(props?.formType)}</h4>
          {(locationDropDown?.length > 0 || !isNewMode) &&
          <div className="button_row mt-0 full_right">
            <Button
              className="btn_white text_btn_theme"
              onClick={props?.zoneClose}
            >
              {t("common.cancelbutton")}
            </Button>
            <Button className="btn_white blue_bg" onClick={onSubmit}>
              {t("common.save")}
            </Button>
          </div>
          }
           {locationDropDown?.length === 0 && isNewMode === true && 
          <div className="button_row mt-0 full_right">
            <Button className="btn_white blue_bg"  onClick={props?.zoneClose}>
              {t("common.ok")}
            </Button>
          </div>
          }
        </div>
        <DialogContent dividers="paper" {...props}>
       { loadingPage ? <CircularProgress className="inner_progress inner_progress_marginset"/> :
          <DialogContentText id="scroll-dialog-description">
            <div className="information_section delegates_details">
              <div className="defult_form mt-10">
              {errorMessage?.length > 0 &&
              <div className="error_prompt_msg">
                  {t("manageuser.locationzone.message")}
              </div>
              }
                {locationDropDown?.length > 0 &&   
                <div className="section_group">
                  <div className="form_col">                  
                    <Grid
                      className="single_line"
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={0}
                    >
                      <Grid item xs={12}>
                        <LabelInfo
                          name={isNewMode ? t("common.selectlocation") : t("common.location")}
                          isMandatory={false}
                        />
                        {locationId && (
                          <Select
                            className={`${disableLocationSelect && 'disable'}`}
                            disabled={disableLocationSelect}
                            labelId="demo-simple-select-label"
                            variant="outlined"
                            name="location"
                            value={locationId}
                            onChange={handleLocationChange}
                          >
                            {locationDropDown &&
                              locationDropDown?.map((value: any) => (
                                <MenuItem
                                  className="defult_select_option"
                                  key={value.locationId}
                                  value={value.locationId}
                                >
                                  {value.locationName}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </Grid>
                    </Grid>
                  </div>

                  <div className="form_col radio_button mb-0">
                    <RadioGroup
                      row
                      aria-label="location"
                      name="locationZone"
                      value={locationZone}
                      onChange={handleLocationZoneChange}
                    >
                      <FormControlLabel
                        className={`${disableLocationRadioBtn && 'disable_all'}`}
                        disabled={disableLocationRadioBtn}
                        value={View.Location}
                        labelPlacement="start"
                        control={
                          <Radio
                            checked={locationZone === View.Location}
                            color="primary"
                          />
                        }
                        label={t("common.location")}
                      />
                      <FormControlLabel
                        value={View.Zone}
                        labelPlacement="start"
                        control={
                          <Radio
                            checked={locationZone === View.Zone}
                            color="primary"
                          />
                        }
                        label={t("common.zone")}
                      />
                    </RadioGroup>
                  </div>
                </div>
                }
              </div>
            </div>

            {locationZone === View.Zone &&
              locationZonesData &&
              locationZonesData?.length > 0 &&
              locationZonesData?.map((value: any, index: number) => (
                <div key={value.id} className="information_section">
                  <div className="zone_section_border">
                    <div className="info_detail">
                      <div className="zone_section zone_sec_det mt-0">
                        <Grid container>
                          {CheckAccess([claims.FORBIDDEN_ACCESS]) &&
                            <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                              <div className="zone_detail">
                                <div className="zone_map zone_map_size">
                                  <CommonGoogleMap
                                    {...props}
                                    center={latLongValue}
                                    zoom={mapDetails.depotLocationMapZoom}
                                    markers={[latLongValue]}
                                  />
                                </div>
                                <div className="tb_zone_section">
                                  <div className="label">
                                    {toUpper(t("common.notes"))}
                                  </div>
                                  <div className="result">{value.notes}</div>
                                </div>
                              </div>
                            </Grid>
                          }
                          <Grid className="" item lg={12}>
                            <div className="zone_floor_info">
                              <List component="nav" className="z_floor_list">
                                <div className="information_section">
                                  <div className="title_row">
                                    <h2>{(t(value.name))}</h2>
                                    <div className="action_icon no_bg">
                                      <IconButton className="top_action_btn">
                                        <div className="defult_form">
                                          <div className="form_col checkbox">
                                            <Checkbox
                                              color="primary"
                                              id={value.id}
                                              checked={
                                                value.state
                                              }
                                              onChange={(e: any) =>
                                                handleSelectionChange(
                                                  value.id,
                                                  e
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </IconButton>
                                    </div>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              </List>
                            </div>
                            <div className="zone_floor_info">
                              <List component="nav" className="z_floor_list">
                                <ListSubheader className="list_head">
                                  <Grid container className="list_sub_head">
                                    {value.totalStation} {t("common.station", { count: value.totalStation })}{", "}
                                    {value.totalPort} {t("common.port", { count: value.totalPort })}
                                  </Grid>
                                </ListSubheader>
                                { value?.stations?.map(
                                    (val: ObjectDetails, index: number) => (
                                      <ListItem key={val?.id}>
                                        {val?.modelImageReference && (
                                          <ListItemIcon className="zone_icon">
                                            <img
                                              src={val.modelImageReference}
                                              alt={t("common.edit")}
                                            />
                                          </ListItemIcon>
                                        )}
                                        <ListItemText>
                                          {val?.name || val?.serialNumber}
                                        </ListItemText>
                                      </ListItem>
                                    )
                                  )}
                              </List>
                            </div>
                            <div className="tb_zone_section">
                              <div className="label">
                                {toUpper(t("common.notes"))}
                              </div>
                              <div className="result">{value.notes}</div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {locationZone === View.Location &&
              locationStationsData &&
              locationIdData && (
                <div className="information_section">
                  <div className="zone_section_border">
                    <div className="info_detail">
                      <div className="zone_section zone_sec_det mt-0">
                        <Grid container>
                          <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                            <div className="zone_detail">
                              <div className="zone_map zone_map_size">
                                <CommonGoogleMap
                                  {...props}
                                  center={latLongValue}
                                  zoom={mapDetails.depotLocationMapZoom}
                                  markers={[latLongValue]}
                                />
                              </div>
                              <div className="tb_zone_section">
                                <div className="label">
                                  {toUpper(t("common.notes"))}
                                </div>
                                <div className="result">
                                  {locationIdData.notes}
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                            <div className="zone_floor_info">
                              <List component="nav" className="z_floor_list">
                                <div className="information_section">
                                  <div className="title_row">
                                    <h2 className="max_w">{(t(locationIdData.name))}</h2>
                                    <div className="action_icon mt-min-5 no_bg">
                                      <IconButton className="top_action_btn">
                                        <div className="defult_form">
                                          <div className="form_col checkbox">
                                            <Checkbox
                                              color="primary"
                                              id={locationIdData?.id}
                                              checked={locationChecked}
                                              onChange={(e: any) => handleLocationCheck(e) }
                                            />
                                          </div>
                                        </div>
                                      </IconButton>
                                    </div>

                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              </List>
                            </div>
                            <div className="zone_floor_info">
                              <List component="nav" className="z_floor_list">
                                <ListSubheader className="list_head">
                                  <Grid container className="list_sub_head">
                                      {locationIdData.chargingStations} {t("common.station", { count: locationIdData.chargingStations })}{", "}
                                      {locationIdData.totalPorts} {t("common.port", { count: locationIdData.totalPorts })}
                                  </Grid>
                                </ListSubheader>
                                { locationStationsData?.map(
                                    (val: ObjectDetails, index: number) => (
                                      <ListItem key={val?.id}>
                                        {val?.modelImageReference && (
                                          <ListItemIcon className="zone_icon">
                                            <img
                                              src={val.modelImageReference}
                                              alt={t("common.edit")}
                                            />
                                          </ListItemIcon>
                                        )}
                                        <ListItemText>
                                          {val?.name || val?.serialNumber}
                                        </ListItemText>
                                      </ListItem>
                                    )
                                  )}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </DialogContentText> 
          }
        </DialogContent>
      </form>
    </>
  );
};

export default ZoneLocationFormDetails;
