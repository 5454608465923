import React from "react";
import {
    DialogContent,
    DialogContentText,
    Grid,
    LinearProgress
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const RejectRequest = (props: any) => {

    const { t } = useTranslation();

    return (
        <>
            {props.spinnerOff && <LinearProgress />}
            <DialogContent>
                <DialogContentText id="scroll-dialog-description">
                    <div className="information_section delegates_details">
                        <div className="defult_form mt-25">
                            <div className="section_group">
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={4}
                                >
                                    <Grid item xs={12}>
                                        <div className="form_col">
                                            <Grid
                                                className="single_line_delete textarea"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={0}
                                            >
                                                <Grid item xs={12}>
                                                    <label className="w_100per full_w">
                                                        {t("membership.rejectconfirmation")}
                                                    </label>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    )
}

export default RejectRequest;