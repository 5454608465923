import React from "react";
import {fieldDataCheck, BLANK_DATA_PLACEHOLDER, renderCellExpand } from "common"
import { useTranslation } from "react-i18next";
import {toUpper} from 'lodash';


export const ChargeHistoryColumnsComponent = () => {

    const { t } = useTranslation();
    const chargeHistoryColumns =  [
    {
        field: "firstCell",
        headerName: toUpper(t("common.firstcell")),
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="empty">
                    {cellValues.value}
                </div>
            );
        }
    },
    {
        field: "portInformation",
        headerName: toUpper(t("common.station")),
        headerClassName: "action_columns",
        sortable: false,
        hasFocus: false,
        cellClassName: "bold_cell_text",
        flex: 0.1,
        minWidth:100,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="port_icon">
                    {cellValues.row?.modelImageReference &&
                    <img src={cellValues.row?.modelImageReference} alt="Edit EVSE" />
                    }
                    {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                </div>
            );
        }
        
    },
    {
        field: "chargerName",
        headerName: toUpper(t("common.name")),
        sortable: false,
        hasFocus: false,
        flex: 0.1,
        minWidth:100,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, fieldDataCheck(cellValues, cellValues?.row?.serialNumber));
        }
        
    },
    {
        field: 'serialNumber',
        headerName: toUpper(t('common.serialnumber')),
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, cellValues?.value);
        },
        
    },
    {
        field: 'startChargeTime',
        headerName: toUpper(t('chargesessiondetail.startcharge')),
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, cellValues?.value);
        },
    },
    {
        field: 'endChargeTime',
        headerName: toUpper(t('chargesessiondetail.endcharge')),
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, cellValues?.value);
        },
    },
    {
        field: 'rfId',
        headerName: toUpper(t('common.blinkcodecard')),
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <>
                    {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                </>
            );
        }
    },
    {
        field: 'energy',
        headerName: t('chargesessiondetail.energy'),
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <>
                    {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                </>
            );
        }
    },
    {
        field: 'totalCost',
        headerName: toUpper(t('common.amount')),
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            const currValue = cellValues?.row?.currency ? (cellValues.row.currency ) : '';
            return (
                <>
                    {cellValues.value ? (currValue + cellValues.value) : BLANK_DATA_PLACEHOLDER}
                </>
            );
        }
    },
    {
        field: "lastCell",
        headerName: toUpper(t("common.lastCell")),
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="empty">
                    {cellValues.value}
                </div>
            );
        }
    },

]
return chargeHistoryColumns;
};