import React from "react";

export const DriverAlertColumns = [

    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
    {
      field: "time",
      headerName: "TIME",
      sortable: false,
      hasFocus: false,
      cellClassName: "bold_cell_text",
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="alert_unread_mark">
            <span className="unread dnone"></span>
            {cellValues.value}
          </div>
        );
      }
    },
  
  
    {
      field: 'vehicleId',
      headerName: 'VEHICLE ID',
      sortable: false,
      flex: 0.1,
      minWidth: 70,
      disableColumnMenu: true,
    },
    {
      field: 'alert',
      headerName: 'ALERT',
      sortable: false,
      minWidth: 200,
      flex: 0.4,
      disableColumnMenu: true
    },
  
  
    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
  ];