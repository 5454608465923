import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Images from "assets/images";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";
const Login = () => {
    const { t } = useTranslation();
    return (
        <>
            {/*-----------Create New Blink Account Start----------*/}
            <div className="blink-account">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card ">
                    <CardContent className="card_content">
                        <h2>Create New Blink Account</h2>
                        <h4>Your email has already been confirmed. Create your password to finish your profile.</h4>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="form_box">
                                        <div className="form_col-12">
                                        <TextField
                                                type="password"
                                                label="Password"
                                                name="Password"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                className="password_view_icon"
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div className="form_col-12 error">
                                        <TextField
                                                type="password"
                                                label="Confirm Password"
                                                name="Confirm Password"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                className="password_view_icon"
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <VisibilityOff />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <span className="error-text">{t("common.errorpasswordnotmatch")}</span>
                                        </div>
                                        <div className="form_col-12">
                                            <div className="form-text">
                                                By selecting Agree and Continue,
                                                I agree to Blink Charging’s{" "}
                                                <span>Terms of Service</span> &{" "}
                                                <span>Privacy Policy</span>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <Button className="block_btn disable">
                                    {t("common.agreecontinuebutton")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form_col-12 listing-items">
                                        <List>
                                            <ListSubheader>
                                                Password Requirements
                                            </ListSubheader>
                                            <ListItem>
                                                <span className="circle active"></span>
                                                At least 8 characters
                                            </ListItem>
                                            <ListItem>
                                                <span className="circle"></span>
                                                1 uppercase character
                                            </ListItem>
                                            <ListItem>
                                                <span className="circle"></span>
                                                1 lowercase character
                                            </ListItem>
                                            <ListItem>
                                                <span className="circle"></span>
                                                1 numeric character
                                            </ListItem>
                                            <ListItem>
                                                <span className="circle"></span>
                                                1 special character (such as: !
                                                @ # ?)
                                            </ListItem>
                                            <ListItem>
                                                <span className="circle"></span>
                                                Confirm new password
                                            </ListItem>
                                        </List>
                                        <Button className="text_login">
                                        {t("common.loginaccountbutton")}
                                        </Button>
                                    </div>
                                </Grid>
                                
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*-----------Create New Blink Account End----------*/}

            {/*------Login Page Start--------*/}
            <div className="blink-account hide log-in ">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card">
                    <CardContent className="card_content">
                        <h2>Log in</h2>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form_box">
                                        <div className="form_col-12 error">
                                            <TextField
                                                type="text"
                                                label="Username (email address)"
                                                name="Username (email address)"
                                            />
                                            <span className="error-text">
                                            {t("common.errorvaildemail")}
                                            </span>
                                        </div>
                                        <div className="form_col-12">
                                            <TextField
                                                type="password"
                                                label="Password"
                                                name="Password"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                className="password_view_icon"
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <VisibilityOff />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Button className="block_btn">
                                    {t("common.loginbutton")}
                                    </Button>
                                    <Grid
                                        container
                                        className="action_text"
                                        spacing={2}
                                    >
                                        <Grid className="text_left" item xs={5}>
                                            <Button className="text_link">
                                            {t("common.resetpassword")}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            className="text_right"
                                            item
                                            xs={7}
                                        >
                                            <Button className="text_link">
                                            {t("common.resendconfirmationemail")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*------Login Page End--------*/}

            {/*------Reset Password Page Start--------*/}
            <div className="blink-account hide log-in">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card">
                    <CardContent className="card_content">
                        <h2>Reset Password</h2>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form_box">
                                        <div className="form_col-12">
                                            <TextField
                                                type="text"
                                                label="Your email address"
                                                name="Your email address"
                                            />
                                        </div>
                                    </div>
                                    <Button className="block_btn">
                                    {t("common.resetpassword")}
                                    </Button>
                                    <Grid
                                        className="action_text"
                                        container
                                        spacing={2}
                                    >
                                        <Grid className="text_left" item xs={5}>
                                            <Button className="text_link">
                                            {t("common.logintohost")}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            className="text_right"
                                            item
                                            xs={7}
                                        >
                                            <Button className="text_link">
                                            {t("common.resendconfirmationemail")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*------Reset Password Page End--------*/}

            {/*------Look Out For An Email Page Start--------*/}
            <div className="blink-account email-look hide log-in">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card">
                    <CardContent className="card_content">
                        <h2>Look Out For An Email</h2>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form_box">
                                        <Typography>
                                            Check your inbox for a password
                                            reset email to update your password.
                                            Click on the link to reset your
                                            password.
                                        </Typography>
                                        <Typography>
                                            If you do not see an invitation,
                                            please check the junk mail folder.
                                        </Typography>
                                    </div>
                                    <Button className="block_btn">
                                    {t("common.loginintoaccountbutton")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*------Look Out For An Email Page End--------*/}

            {/*------Resend Confirmation Email Page Start--------*/}
            <div className="blink-account resend-confirmation hide log-in ">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card">
                    <CardContent className="card_content">
                        <h2>Resend Confirmation Email</h2>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form_box">
                                        <Typography>
                                            Can’t find your confirmation email?
                                            Please send me another email.
                                        </Typography>
                                        <div className="form_col-12">
                                            <TextField
                                                type="text"
                                                label="Your email address"
                                                name="Your email address"
                                            />
                                        </div>
                                    </div>
                                    <Button className="block_btn">
                                    {t("common.resendconfirmationemail")}
                                    </Button>
                                    <Grid
                                        className="action_text"
                                        container
                                        spacing={2}
                                    >
                                        <Grid className="text_left" item xs={5}>
                                            <Button className="text_link">
                                            {t("common.resetpassword")}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            className="text_right"
                                            item
                                            xs={7}
                                        >
                                            <Button className="text_link">
                                            {t("common.resendconfirmationemail")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*------Resend Confirmation Email Page End--------*/}

            {/*------Look Out For An Email Page Start--------*/}
            <div className="blink-account email-look hide log-in">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card">
                    <CardContent className="card_content">
                        <h2>Look Out For An Invitation</h2>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form_box">
                                        <Typography>
                                            Check your inbox for an invitation
                                            to sign-up for an account. Click on
                                            the link to start your sign-up
                                            process.
                                        </Typography>
                                        <Typography>
                                            If you do not see an invitation,
                                            please check the junk mail folder.
                                        </Typography>
                                    </div>
                                    <Button className="block_btn">
                                    {t("common.loginintoaccountbutton")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*------Look Out For An Email Page End--------*/}

            {/*------You Link Has Expired Page Start--------*/}
            <div className="blink-account email-look hide log-in">
                <div className="blink-image">
                    <img src={Images.blink} alt="Blink" />
                </div>
                <Card className="small_card">
                    <CardContent className="card_content">
                        <h2>You Link Has Expired</h2>
                        <div className="section_group">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form_box">
                                        <Typography>
                                            The link has expired. Please ask
                                            your administrator to resend
                                        </Typography>
                                    </div>
                                    <Button className="block_btn">
                                    {t("common.loginintoaccountbutton")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </div>
            {/*------You Link Has Expired Page End--------*/}
        </>
    );
};
export default Login;
