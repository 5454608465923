import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Images from 'assets/images';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const DepotAnalytics = (props:any) => {
  
  return (
    <>
      <div className="information_section analytics" data-testid="depot_analytics">
        <div className="vehicle_analytics">
          <Grid container spacing={3}>
            <Grid item sm={12} md={4} lg={4}>
              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h4>24 Hour Status</h4>
                    <div className="sub_heading">24 hour average status</div>
                  </div>
                  <div className="card_mid">
                    <img
                      className="img_res"
                      src={Images.evse_status}
                      alt="evse_status"
                    />
                  </div>
                  <div className="card_legend">
                    <Grid container spacing={0}>
                      <Grid
                        className="legend_item available"
                        item
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <img src={Images.ic_transit} alt="" />
                        Available (24%)
                      </Grid>
                      <Grid
                        className="legend_item charging"
                        item
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <img src={Images.ic_charging} alt="" />
                        Charging (23%)
                      </Grid>
                      <Grid
                        className="legend_item error pl_5"
                        item
                        sm={3}
                        md={6}
                        lg={3}
                      >
                        <img src={Images.ic_error} alt="" />
                        Error (3%)
                      </Grid>
                      <Grid
                        className="legend_item reserved"
                        item
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <img src={Images.ic_watch_later} alt="" />
                        Reserved (3%)
                      </Grid>
                      <Grid
                        className="legend_item connected"
                        item
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <img src={Images.ic_connected} alt="" />
                        Connected (29%)
                      </Grid>
                      <Grid
                        className="legend_item offline pl_5"
                        item
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <img src={Images.ic_offline} alt="" />
                        Offline (1%)
                      </Grid>
                      <Grid
                        className="legend_item complete"
                        item
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <img src={Images.ic_complete} alt="" />
                        Complete (18%)
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h4>Power Consumption</h4>
                    <div className="sub_heading">24 hours kW</div>
                  </div>
                  <div className="card_mid text_center">
                    <img
                      className="img_res"
                      src={Images.power_consumption}
                      alt="power consumption"
                    />
                  </div>
                </CardContent>
              </Card>

              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h4>Energy details</h4>
                    <div className="sub_heading">30 days energy usage</div>
                  </div>
                  <div className="card_mid">
                    <div className="efficiency_bar energy_detail">
                      <List aria-label="main mailbox folders">
                        <ListItem>
                          <div className="port">A - Port 1</div>
                          <div className="proress_bar">
                            <span style={{ width: '75%' }}></span>
                          </div>
                          <div className="current_kwh">11971.7 kWh</div>
                        </ListItem>
                        <ListItem>
                          <div className="port">A - Port 2</div>
                          <div className="proress_bar">
                            <span style={{ width: '48%' }}></span>
                          </div>
                          <div className="current_kwh">10769.5 kWh</div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h4>EVSE health</h4>
                    <div className="sub_heading">
                      30 days EVSE location health
                    </div>
                  </div>
                  <div className="card_mid text_center">
                    <img
                      className=""
                      src={Images.evse_health}
                      alt="EVSE Health"
                    />
                  </div>
                </CardContent>
              </Card>

              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h4>Create custom views</h4>
                    <div className="sub_heading">
                      Download vehicle data in a CSV format. Format customized
                      data tables and create graphs in Excel.
                    </div>
                  </div>
                  <div className="card_mid text_center">
                    <img
                      className="img_res"
                      src={Images.custom_views}
                      alt="charge_percent"
                    />
                  </div>
                  <div className="button_row">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid className="text_left" item xs={12}>
                        <Button className="btn_white ml_0 blue_bg">
                          Download CSV
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className="button_row">
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid className="text_left" item xs={12}>
              <Button className="btn_white ml_0 blue_bg">
                Customize analytics chart
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default DepotAnalytics;