import React, { useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ApiEndPoint, getCannedReportMethod } from "services";
import Report from "pages/evseLocations/chargeHistoryReportForm/report";
import { GetUrlDetails } from "common";
import { useHistory } from "react-router-dom";

const DownloadReport = (props: any) => {

    const { t } = useTranslation();
    const spinnerOff: boolean = true;
    const [openNew, setOpenNew] = useState(true);
    const [openReportAvailable, setReportAvailable] = useState(false);
    const [currentUrlLink, setCurrentUrlLink] = useState<any>('');
    const history = useHistory();
    const [messageShown, setMessageShown] = React.useState<any>('');
    const { host_id, start_date, end_date, timezone } = GetUrlDetails(history, 'downloadReport');

    const reportAvailableOpen = () => {
        setReportAvailable(true);
    };

    const reportAvailableClose = () => {
        setReportAvailable(false);
    }

    const detailsClose = () => {
        setOpenNew(false);
    };

    const getChargeHistoryReport = async () => {
        await getCannedReportMethod(ApiEndPoint.reportData, '?start_date=' + start_date + '&end_date=' + end_date + '&host_id=' + host_id + '&timezone=' + timezone + '&produce_json=false').then((res) => {
            if (res?.status === 200 && res?.data?.url) {
                setMessageShown("")
                setCurrentUrlLink(res?.data?.url);
                getReportUrlData(res?.data?.url);
            }
            else {
                setMessageShown(t("common.something.went.wrong.errmsg"));

            }
        })
            .catch((err) => {
                console.error("Error", err);
            });
    };


    const getReportUrlData = async (url: any) => {

        const interval = setInterval(() => {
            fetch(url, {
                method: "GET",
                mode: 'cors',
            })
                .then((response) => {
                    if (response?.status === 200) {
                        detailsClose()
                        reportAvailableOpen()
                        clearInterval(interval)
                    }
                })
                .catch(function (error) {
                    clearInterval(interval)
                    console.log('error', error)
                });
        }, 5000)
    }

    const getDwonlaodData = () => {
        let linkGenerate = document.createElement('a')
        linkGenerate.setAttribute('href', currentUrlLink)
        linkGenerate.click();
    }

    useEffect(() => {
        getChargeHistoryReport()
    }, []);

    const handleClick = () => {
        window.close()
    }

    return (
        <>
            <div className="dialog_box" data-testid="download_report_dialog">
                <Dialog className="dialog_container edit_detail_section  report_height sm dialog_container_center " {...props}
                    open={openNew}
                    onClose={detailsClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("common.historyreport")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={handleClick}
                            >
                                {t("common.cancelbutton")}
                            </Button>

                        </div>
                    </div>
                    <DialogContent>
                        {!messageShown && spinnerOff && <CircularProgress className="inner_progress_userGroup" />}
                        <DialogContentText id="scroll-dialog-description">
                            {messageShown?.length > 0 ? <Typography className="plain_text  mt-200 text_center">
                                {messageShown}
                            </Typography> : <Typography className="plain_text  mt-250 text_center">
                                    {t("common.reportnote")}
                                </Typography>}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            <Report {...props}
                openReportAvailable={openReportAvailable}
                reportAvailableClose={reportAvailableClose}
                getDwonlaodData={getDwonlaodData}
            />
        </>
    );
};

export default DownloadReport;
