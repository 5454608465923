const initialState = {
    error: null,
    depotDetails : []
};

export default function fetchDepotReducer(state = initialState, action: any) {

    if(action.type === 'FETCH_DEPOT_ACTION') {
        return {
            ...state,
            error: null,
            depotDetails: action.payload
        }
    } else {
        return state;
    }

}