import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
    Button, AppBar, Tabs, Tab, Typography, Box, Dialog,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Images from "assets/images";
import { useTranslation } from "react-i18next";
import { MODE_TYPE, GetUrlDetails, pageDetails, defaultVariable } from "common";
import './userGroupDetail.scss';
import UserInfo from "./userInfo";
import LocationList from "./locationList";
import { getMethod, ApiEndPoint } from "services";
import { ManageUserInfoList } from "common/interfaces/manageuser";
import ZoneLocationFormDetails from "./ZoneForms/ZoneLocationFormDetails";
const drawerWidth = 200;
function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
const UserGroupDetail = (props: any) => {
    const { t } = useTranslation();
    const currentPage: number = 0;
    const [selectZoneOpen, setSelectZoneOpen] = useState(false);
    const defaultFormType = MODE_TYPE.New;
    const [formType, setFormType] = useState<any>(defaultFormType);
    const [, setUpdateZoneOpen] = useState(false);
    const history = useHistory();
    let userGroupId = GetUrlDetails(history, 'userGroup');
    const pageSize = pageDetails.pageSize;
    const [manageUserInfo, setManageUserInfo] = useState<ManageUserInfoList>();
    const [currentLocationZone, setCurrentLocationZone] = useState<any>(0);
    const [zoneCallback, setZoneCallback] = useState<any>(false);
    const tab = parseInt(history.location.hash.slice(1));

    const updateZoneOpen = (e: any) => {
        setCurrentLocationZone(e?.row)
        setFormType(MODE_TYPE.Edit)
        setSelectZoneOpen(true);
        setUpdateZoneOpen(true);
    };
    const zoneOpen = () => {
        setSelectZoneOpen(true);
        setFormType(MODE_TYPE.New)
    }
    const zoneClose = (isZoneSave: boolean = false) => {
        setCurrentLocationZone(0)
        setZoneCallback(isZoneSave)
        setSelectZoneOpen(false);
    };
    const [isVisible, setIsVisible] = useState(true);
    const listenToScroll = () => {
        let heightToHideFrom = 50;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    useEffect(() => {
        let urlDetails = GetUrlDetails(history, 'userGroupDetail');
        if (urlDetails['tab'] !== '') {
            setValue(parseInt(urlDetails['tab']));
        }
    }, []);


    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);

        let { pathname } = GetUrlDetails(history, 'userGroupDetail');

        history.push({
            pathname: pathname,
            hash: '#' + newValue,
            search: history.location.search,
        });

    };

    useEffect(()=>{
        setValue(tab)
    },[tab])

    useEffect(() => {
        getGroupDetailsList()
    }, [pageSize])

    const getGroupDetailsList = async () => {
        const url = ApiEndPoint.groupDetailsUserEdit.replace('{USERGROUP-ID}', userGroupId['userGroup'])
        let params = "?page=" + currentPage + '&size=' + pageSize;
        await getMethod(url, params)
            .then((res) => {
                setManageUserInfo(res.data);
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    return (
        <>

            {/*----Page Mid Section Start----*/}
            <main className={classes.content} data-testid="user_group_detail">
                <div className={isVisible ? "" : "scroll_effect"}>
                    <div className="depot_details host_settings">
                        <div className="depot_detail_head position_fixed_head">
                            <div className="depot_information">
                                {/*----EVSE Depot Map----*/}

                                <div className="depot_location_map no-border">
                                    <img
                                        src={manageUserInfo?.imageReference ?? Images.shift_driver_group}
                                        alt="shift_driver_group" className="user-logo"
                                    />
                                </div>

                                {/*----EVSE Depot Information----*/}
                                <div className="depot_info">
                                    <div className="depot_name">
                                        {manageUserInfo?.name}
                                    </div>
                                    <div className="port_details">
                                        <span className="alert">
                                            <img
                                                src={Images.ic_driver_group}
                                                alt="user"
                                            />
                                             {manageUserInfo?.numberOfUsers === null ? 0 : manageUserInfo?.numberOfUsers} {t("common.user", { count: manageUserInfo?.numberOfUsers })}
                                        </span>
                                        <span className="port">
                                            <img
                                                src={Images.ic_rfid_grey}
                                                alt="rfid"
                                            />
                                            {manageUserInfo?.numberOfRfids === null ? 0 : manageUserInfo?.numberOfRfids} {t("common.blinkcard", { count: manageUserInfo?.numberOfRfids })}
                                        </span>
                                    </div>
                                    <p className="info_text">
                                        {manageUserInfo?.name === defaultVariable.USER_GROUP.freeCharge ? t("usergroupdetail.freechargingmessage") : ""}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="detail_page_tab">
                            <div className="dialog_box">
                                <Dialog
                                    className="dialog_container zone_settings_dialog edit_detail_section  dialog_container_center"
                                    {...props}
                                    open={selectZoneOpen}
                                    onClose={zoneClose}
                                    scroll="paper"
                                    aria-labelledby="scroll-dialog-title"
                                    aria-describedby="scroll-dialog-description"
                                > 
                                    <ZoneLocationFormDetails
                                        zoneClose={zoneClose}
                                        formType={formType}
                                        currentLocZoneDetails={currentLocationZone}
                                        isZoneAvailable={currentLocationZone?.zones?.length > 0 ? true : false}
                                    />
                                </Dialog>
                            </div>
                            <AppBar
                                className="defult_tab position_fixed_nav"
                                position="static"
                                color="default"
                            >
                                <Tabs
                                    className="tab_nav"
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable force tabs example"
                                >
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("usergroupdetail.userinfo")}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.location")}
                                        {...a11yProps(1)}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel
                                className="tab_detail"
                                {...props}
                                value={value}
                                index={0}
                            >
                                <UserInfo refreshDetails={getGroupDetailsList}/>
                            </TabPanel>
                            <TabPanel
                                    className="tab_detail location_settings"
                                    {...props}
                                    value={value}
                                    index={1}
                                >
                                <div className="button_row text_right">
                                    <Button
                                        className="btn_white blue_bg"
                                        onClick={zoneOpen}
                                    >
                                        {t("usergroupdetail.addnewlocation")}
                                    </Button>
                                </div>
                                <LocationList zoneCallback={zoneCallback} updateZoneOpen={updateZoneOpen} zoneClose={zoneClose} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </main>

            {/*----Page Mid Section End----*/}
        </>
    );
};

export default UserGroupDetail;
