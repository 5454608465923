import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
import { useTranslation } from "react-i18next";
import { lowerCase, upperCase } from 'lodash';
import { BlinkCardColumnsComponent } from "pages/tableColumns";
import { BLANK_DATA, ColumnNameTranslate, getFormattedDateTimeWithTZ, HandlePaginationLabel, pageDetails } from 'common';
import CommonDownloadCsv from "components/commonDownloadCsv";
import CommonPagination from "components/commonPagination";
import moment from "moment";
import { getMethod, ApiEndPoint } from "services";
import RequestBlinkCardInfo from "./requestBlinkCardInfo";
import RequestBlinkCardProgress from "./requestBlinkCardProgress";

const RequestBlinkCard = (props: any) => {
    const { t } = useTranslation();
    const DEFAULT_STATUS = t('common.sumitted')
    const columnValues = BlinkCardColumnsComponent();
    const pageSize = pageDetails.pageSizeTwenty;
    const [currentPage, setCurrentPage] = useState(0);
    const [requestBlinkMyCardInfo, setRequestBlinkCardInfo] = useState<any>();
    const [requestBlinkCardCsv, setRequestBlinkCardCsv] = useState<any>();
    const [openTransactionDetails, setTransactionDetailsOpen] = useState(false);
    const [spinnerOff, setSpinnerOff] = useState<boolean>(false);

    const fileHeader: any[] = [
        { label: upperCase(t('common.quantity')), key: t('common.numberOfCards') },
        { label: upperCase(t('common.orderby')), key: t('common.orderbycsv') },
        { label: upperCase(t('common.email')), key: lowerCase(t('common.email')) },
        { label: upperCase(t('common.status')), key: lowerCase(t('common.status')) },
        { label: upperCase(t('common.date')), key: lowerCase(t('common.date')) },
    ];

    const fileName: string = `${t('common.rfidcard')}_${moment().format('MMDDYYYYHHmmss')}.csv`;

    const BlinkCardCSVDataSet = () => {
        let chargeHistoryTableData: any[] = [];
        if (requestBlinkCardCsv && requestBlinkCardCsv.rfidDetails) {
            chargeHistoryTableData = requestBlinkCardCsv.rfidDetails.map(
                (val: any) => ({
                    ...val, 
                    quantity: val?.numberOfCards ?? BLANK_DATA,
                    orderby: val?.userName ?? BLANK_DATA,
                    email: val?.requestAddress?.email ?? BLANK_DATA,
                    status: val?.requestAddress?.status ?? DEFAULT_STATUS,
                    date: getFormattedDateTimeWithTZ(val?.createdOn) ?? BLANK_DATA,        
                })
            );
        }
        return chargeHistoryTableData;
    };


    const blinCardDataSet = (value: any) => {
        let blinkCardData = []
        if (value && value.rfidDetails) {
            blinkCardData = value.rfidDetails.map((val: any, index: number) => ({ 
                ...val, 
                id: val?.id,
                quantity: val?.numberOfCards ?? BLANK_DATA,
                orderby: val?.userName ?? BLANK_DATA,
                email: val?.requestAddress?.email ?? BLANK_DATA,
                status: val?.requestAddress?.status ?? DEFAULT_STATUS,
                date: getFormattedDateTimeWithTZ(val?.createdOn) ?? BLANK_DATA,
            }))
        }
        return blinkCardData
    }

    const transactionDetailsOpen = () => {
        setTransactionDetailsOpen(true);
    };
    const transactionDetailsClose = () => {
        setTransactionDetailsOpen(false);
        getGroupDetailsList();
    };

    const getGroupDetailsList = async () => {
        setSpinnerOff(true)
        const paramDetails = '?page=' + currentPage + '&size=' + pageSize;
        await getMethod(ApiEndPoint.requestBlinkCard, paramDetails).then((res) => {
                setRequestBlinkCardInfo(res.data);
                setSpinnerOff(false)
            })
            .catch((err) => {
                setSpinnerOff(false)
                console.error("Error", err);
            });
    };

    const getGroupDetailsCsvList = async () => {
        setSpinnerOff(true)
        const paramDetails = '?page=0' + '&size=' + requestBlinkMyCardInfo.totalRfidCount;
        await getMethod(ApiEndPoint.requestBlinkCard, paramDetails).then((res) => {
                setSpinnerOff(false)
                setRequestBlinkCardCsv(res.data);
            })
            .catch((err) => {
                setSpinnerOff(false)
                console.error("Error", err);
            });
    };


    const handleCurrentPage = (setPage: number) => {
        setCurrentPage(setPage);
    };

    useEffect(() => {
        setRequestBlinkCardInfo([])
        getGroupDetailsList();
    }, [currentPage])


    const [openProgressBlinkCard, setProgressBlinkCardOpen] = useState(false);
    const [blinkCardInfo, setBlinkCardInfo] = useState();

    const progressBlinkCardClose = () => {
        setProgressBlinkCardOpen(false);
    };

    const getRequestBlinkCardInfoData = (rowData: any) => {
        setBlinkCardInfo(rowData)
        setProgressBlinkCardOpen(true);
     };


    return (
        <>
            <div className="mt-0">
                <div className="button_row text_right">
                    <button className=" btn_white blue_bg" onClick={transactionDetailsOpen}>
                        {t("common.requestblinkcard")}
                    </button>
                </div>

                <RequestBlinkCardProgress blinkCardInfo={blinkCardInfo} openProgressBlinkCard={openProgressBlinkCard} progressBlinkCardClose={progressBlinkCardClose} />
                            
                <RequestBlinkCardInfo transactionDetailsClose={transactionDetailsClose} openTransactionDetails={openTransactionDetails}  />

                <div className="table_scroll mt-30">
                {spinnerOff && <CircularProgress className="inner_progress_userGroup"/>}
                    <div className="default_table">
                        <DataGrid autoHeight className="table"
                            rowHeight={59}
                            headerHeight={30}
                            rows={blinCardDataSet(requestBlinkMyCardInfo)}
                            disableSelectionOnClick
                            vertical-align="top"
                            columns={ColumnNameTranslate(columnValues)}
                            pageSize={pageSize}
                            onRowClick={(rowdata: any) => getRequestBlinkCardInfoData(rowdata)}
                        />
                    </div>
                    <div className="table_footer">
                        <Grid container direction="row" alignItems="center" spacing={0}>
                            <Grid className="text_left" item xs={8}>
                                {requestBlinkMyCardInfo && requestBlinkMyCardInfo.totalRfidCount > pageSize ? (
                                    <CommonPagination
                                        currentPage={currentPage}
                                        pageSize={pageSize}
                                        totalCount={requestBlinkMyCardInfo?.totalRfidCount}
                                        callBackCurrentPage={handleCurrentPage}
                                    />
                                ) : (
                                        <></>
                                    )}
                                {requestBlinkMyCardInfo && requestBlinkMyCardInfo.totalRfidCount ? (
                                    <CommonDownloadCsv
                                        {...props}
                                        datas={BlinkCardCSVDataSet()}
                                        headers={fileHeader}
                                        filename={fileName}
                                        callBackDownloadCsv={getGroupDetailsCsvList}
                                    />
                                ) : (
                                        <></>
                                    )}
                            </Grid>
                            <Grid className="text_right" item xs={4}>
                                <div className="list_number">
                                    {HandlePaginationLabel(requestBlinkMyCardInfo?.totalRfidCount, pageSize, currentPage, 'common.rfidcard')}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};
export default RequestBlinkCard;