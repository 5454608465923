import { Checkbox, Grid, ListItemText, MenuItem, Select } from "@material-ui/core"
import { MenuProps } from "common";
import React from "react";
import "./multiSelectDropdownComponent.scss";

export const MultiSelectDropdownComponent = (props: any) => {

    const { selectedValues, defaultName, selectedValuesArray, deletePricing, setSubmitButton, setSelectedValues, defaultPlaceHolder } = props;

    const handleChange = (event: any) => {
        let selectedName = event.target.value;
        if (selectedName.length !== 0) {
            selectedName = selectedName.filter((stationName: any) => stationName !== defaultPlaceHolder);
            setSubmitButton(true);
        } else {
            selectedName = [defaultPlaceHolder];
            setSubmitButton(false);
        }
        setSelectedValues(selectedName);
    };

    return (
        <Select
            className="w_100per full_w"
            labelId="mutiple-select-label"
            variant="outlined"
            name={"type"}
            multiple
            value={selectedValues}
            onChange={handleChange}
            inputProps = {{"data-testid": `delete-dropdown`}}
            renderValue={(selectedValuesList: any) => selectedValuesList.join(", ")}
            MenuProps={MenuProps}
        >
            <MenuItem value={selectedValues} disabled>
                {defaultName}
            </MenuItem>
            {selectedValuesArray.map((selectedValue: any) => (
                <MenuItem key={selectedValue.id} value={selectedValue.name} className="multi_select_checkbox">
                    <div className="form_col_check_multiselect checkbox">
                        <Checkbox
                            color="primary"
                            checked={selectedValues.indexOf(selectedValue.name) > -1}
                        />
                    </div>
                    {deletePricing &&
                        <ListItemText><Grid>{selectedValue.name}<span className="plan_text"> - {selectedValue.planType}</span></Grid></ListItemText>
                    }
                </MenuItem>
            ))}
        </Select>
    )
}