import React from "react";
import { toUpper } from 'lodash';
import {useTranslation} from "react-i18next";

export const WaitListColumns =()=> {
    const {t} = useTranslation();

    const waitListStationColumns = [
        {
            field: "firstCell",
            headerName: toUpper(t("common.firstcell")),
            headerClassName: "empty",
            sortable: true,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "name",
            headerName: toUpper(t("common.stationname")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "serial_number",
            headerName: toUpper(t("common.serialnumber")),
            headerClassName: "action_columns",
            sortable: true,
            hasFocus: false,
            cellClassName: "",
            className: "port",
            flex: 0.4,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "lastCell",
            headerName: toUpper(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        }
    ]

    return waitListStationColumns
}