import React from "react";
import {
    Link,
    Typography
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import "./breadCrumbs.scss"
import { locations,routes } from "./util"
import { useTranslation } from "react-i18next";

const UIBreadcrumbs = (props: any) => {

    const {
        history,
        location: { pathname, hash }
    } = props;

    const [expand, setExpand] = React.useState(true);
    const LocationKeys = Object.keys(locations);
    const LocationValues = Object.values(locations);
    const { t } = useTranslation();

    let pathnames = pathname.split("/").filter((path: any) => path);
    
    const breadCrumbsPaths = () => {
        routes.forEach((props: any)=>{
            if(pathname === props.pathname) {
                pathnames.pop();
                if(hash) {
                    pathnames.push(props.displayOptions[hash.split("").slice(1)]);
                } else {
                    pathnames.push(props.displayOptions[0]);
                }
                pathnames = pathnames.filter((path: any) => path);
            }
        })
        return pathnames;
    }
    const finalPathnames = breadCrumbsPaths();

    return (
        <div data-testid="breadCrumb-container" className="breadCrumb-container" onMouseLeave={()=>setExpand(true)}>
            <div className={finalPathnames.length > 0 ? "breadCrumbs":"noBreadCrumbs"}>
                <Link className="icons" onClick={() => history.push("/")}> <HomeIcon className="homeIcon" /></Link>
                <div className={expand && finalPathnames.length > 1 ? "expandIcons":"hide"}>
                    <NavigateNextIcon className="navigateNextIcon"/>
                    <MoreHorizIcon  onMouseOver={()=>setExpand(!expand)} className="moreHorizIcon"/>
                </div>
                {finalPathnames.map((pathElement: string, index: any) => {
                    const routeTo = `/${finalPathnames.slice(0, index + 1).join("/")}`;
                    const isLast = index === finalPathnames.length - 1;
                    let location = pathElement;
                    
                    LocationKeys.forEach((item, index) => {
                        if (item === pathElement) location = LocationValues[index];
                    })
                    
                    return isLast ? (
                        <div className="inActive-links">
                            <NavigateNextIcon className="navigateNextIcon"/>
                            <Typography className="breadcrumb-typography">
                                {t(location)}
                            </Typography>
                        </div>
                    ) : (
                        <div className={expand? "hide":"show-active-links"}>
                            <NavigateNextIcon className="navigateNextIcon"/>
                            <Link className="breadcrumb-link" onClick={() => history.push(routeTo)}>
                                {t(location)}
                            </Link>
                        </div>
                    );
                })}
            </div>
            <div className={finalPathnames.length > 0 ? "triDesign":"noBreadCrumbs"}></div>
        </div>
    );
};

export default withRouter(UIBreadcrumbs);