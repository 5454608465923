import React from "react";

export const FleetDelegateColumns = [

    {
        field: "name",
        headerName: 'fleet.delegate.name',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 200,
        flex: 2,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "email",
        headerName: 'fleet.delegate.email',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 200,
        flex: 2,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "phone",
        headerName: 'fleet.delegate.mobile',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 200,
        flex: 2,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "status",
        headerName: 'fleet.delegate.status',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 200,
        flex: 2,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
   
]

