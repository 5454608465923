import React from "react";
import { ApiEndPoint, deleteMethod } from "services";
import "./delegates.scss";
import { DeleteDelegates } from "common/interfaces";
import { urlReplace } from "common";
import { useHistory } from "react-router-dom";
import ConfirmDialogPopupComponent from "../../../components/confirmDialogPopupComponent";

const DeleteDelegate = (props: DeleteDelegates) => {
    const history = useHistory();
    const delegateDeleteFieldDetails: any = [{id: 1, columnLabel: "delegates.delete.confirm", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const delegateDeleteCancelButtonAction = {isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true, classFormat: ''};
    const delegateDeleteRemoveButtonAction = {isVisible: true, buttonName: 'common.remove', buttonPermssion: true, classFormat: 'red_bg', disabled: false };

    const ConfirmDelete = () => {
        const url = urlReplace(ApiEndPoint.deleteDelegate, "{DELEGATE-ID}", props?.currentDelegate.id);
        deleteMethod(url, "", false).then((res) => {
            props?.deleteAccountClose();
            props?.editDelegateClose();
            history.goBack();
        }).catch((err) => {
            console.error("Error", err);
        });
    };

    return (
        <>
            <div className="dialog_box" data-testid="delete_delegate">
                <ConfirmDialogPopupComponent
                    popUpPageOpen={props?.openDeleteaccount}
                    popUpPageClose={props?.deleteAccountClose}
                    headerName={"delegates.delete"}
                    fieldDetails={delegateDeleteFieldDetails}
                    submitAction={delegateDeleteRemoveButtonAction}
                    cancelAction={delegateDeleteCancelButtonAction }
                    submitData={ConfirmDelete}
                />
            </div>
        </>
    );
};

export default DeleteDelegate;
