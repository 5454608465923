import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { DataGrid } from "@material-ui/data-grid";
import { GetUrlDetails, HandlePaginationLabel, pageDetails } from "common";
import CommonPagination from "components/commonPagination";
import { userListColumns } from "pages/tableColumns";
import { HostSettingUserGroupData } from "common/interfaces/hostSetting";
import { userGroupHostSettingsId } from "redux/actions/userGroupHostSetting";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ObjectDetailsField } from "common/interfaces";
import { toUpper } from "lodash";
import { useTranslation } from "react-i18next";

const UserGroupList = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const pageSize = pageDetails.pageSize;
  const [userGroupHostSettings, setUserGroupHostSettings] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  let { pathname, hash } = GetUrlDetails(history, 'hostSettings');


  useEffect(() => {
    setUserGroupHostSettings(props.userGroup)
  }, [props.userGroup])

  const userGroupDataSet = (value: any) => {
    let userGroupTableData: any[] = [];
    if (value && value.userGroups) {
      userGroupTableData = value.userGroups.map(
        (val: any) => ({
          ...val,
          id: val.id,
          groupname: val.groupName,
          imageReference: val.imageReference ?? 0,
          numberOfUsers: val.numberOfUsers ?? 0,
          numberOfRFIDCards: val.numberOfRFIDCards ?? 0,
          createdTimestamp: val.createdTimestamp

        })
      );
    }
    return userGroupTableData;
  };

  const getGroupData = (e: HostSettingUserGroupData) => {
    dispatch(userGroupHostSettingsId(e?.id));
    props.userGroupId(e?.id)
    let userGroupId = e?.id; 
    let searchParams = "?usergroup="+ userGroupId + "&view=info";
    history.push({
      pathname: pathname,
      search: searchParams,
      hash: hash
    });
  };


  const handleCurrentPage = (setPage: number) => {
    setCurrentPage(setPage);
  };

  const colNameTranslate = (columnsList: Array<ObjectDetailsField>) => {
    let columnsData = columnsList;
    if (columnsList && columnsList.length > 0) {
      columnsData = columnsList.map((val: ObjectDetailsField) => ({ ...val, headerName: toUpper(t(val.headerName)) }))
    }
    return columnsData;
  }


  return (
    <>
      {userGroupHostSettings && (
        <div className="table_scroll" data-testid="user_group_list">
          <div className="default_table">
            <DataGrid
              autoHeight
              vertical-align="top"
              className="table"
              rowHeight={80}
              headerHeight={30}
              rows={userGroupDataSet(userGroupHostSettings)}
              columns={colNameTranslate(userListColumns)}
              pageSize={pageSize}
              onRowClick={(e: any) => getGroupData(e)}
            />
          </div>
          <div className="table_footer">
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid className="text_left" item xs={8}>
                {props.userGroup && props.userGroup?.totalUserGroups > pageSize ?
                  <CommonPagination
                    currentPage={currentPage}
                    totalCount={props.userGroup?.totalUserGroups}
                    callBackCurrentPage={handleCurrentPage}
                  /> : <></>}
              </Grid>
              <Grid className="text_right" item xs={4}>
                <div className="list_number">
                  {HandlePaginationLabel(props.userGroup?.totalUserGroups, pageSize, currentPage, "hostsetting.usergroups")}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
export default UserGroupList;
