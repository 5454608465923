import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Radio,
    RadioGroup,
    LinearProgress,
} from "@material-ui/core";
import { GetUrlDetails } from "common";
import { UserAddInfo } from "common/interfaces/manageuser";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiEndPoint, postMethod } from "services";
import { addMembershipUserGroup } from "services/membershipService";
// import EmailForm from "./emailForm";
// import RfidForm from "./rfidForm";
import AddNewUserForm from "./addNewUserForm";



const UserInfoAdd = (props: UserAddInfo) => {
    const { t } = useTranslation();
    const [radioValue, setRadioValue] = useState('email');
    const history = useHistory();
    const [errorResponseType, setErrorResponseType] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<any>();
    const [isLoader, setIsLoader] = useState(false);
    let userGroupId = GetUrlDetails(history,'userGroup');
    const onChangeRadio = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
        setErrorMessage(null)
    }

    useEffect(() => {
        if(props.openAddNewUser) {
            setRadioValue('email')
            setErrorResponseType(null);
            setErrorMessage(null);
        }
    }, [props.openAddNewUser])

    const handleSubmitData = (data: any) => {
        for (const [key, value] of Object.entries(data)) {
            if (value === "" || value === "-" || value === null) {
                delete data[key];
            }
        }
        if (props.isMembership) {
            addUserFormembership(data)
            return;
        }
        postMethod(ApiEndPoint.groupDetails, userGroupId['userGroup'] + "/users", data)
            .then((res) => {
                if (res.status === 200) {
                    props?.addNewUserClose(true);
                    props?.refreshDetails();
                } else if (res.status === 400) {
                    if (res.data && res.data.message) {
                        changeErrorMessage(res.data.message);
                    }
                }
            })
            .catch((err) => {
            });

    }

    const addUserFormembership = (data: any) => {
        let params = { 'hostId': props.hostId, 'userGroupId': userGroupId['userGroup'] }
        setIsLoader(true);
        addMembershipUserGroup(data, params).then((res: any) => {
            if(res && res?.status === true) {
                setIsLoader(false);
                props?.addNewUserClose(true);
                props?.refreshDetails();
            } else if(res?.status === false) {
                setIsLoader(false);
                if(res && res?.message) {
                    changeErrorMessage(res?.message);
                }
            }
        }).catch((err: any) => {
            console.error("Error", err)
        })
    }
        
        const changeErrorMessage = (data: any) => {
            if(data) {
                const message = data?.split(":").pop();
                setErrorMsgFromArray(data, message)
            }
    }

    const setErrorMsgFromArray = (data: any, message: any) => {
        const errorGroup = message;
        const ERROR_MESSAGES = [
            {type: 'both', error: "User group Id does not exist.", translate_error: t("emailrfiderrors.nogroupidexist") },
            {type: 'email', error: "User with given email not found", translate_error: t("emailrfiderrors.noemailfound") },
            {type: 'email', error: "No user found with given email", translate_error: t("emailrfiderrors.noemailfound") },
            {type: 'email', error: "User group id does not exist with user email id", translate_error: t("emailrfiderrors.nogroupidmapwithemail") },
            {type: 'email', error: "User with given email already exists in the group", translate_error: t("emailrfiderrors.emailidexists", {errorGroup}) },
            {type: 'email', error: "Invalid User, the mapped rfid is part of another group", translate_error: t("emailrfiderrors.emailexists", {errorGroup}) },
            {type: 'email', error: "Given email belongs to some other user role which can not", translate_error: t("emailrfiderrors.emailmapanotheruserrole") },
            {type: 'rfid', error: "Invalid RFID serial number provided", translate_error: t("emailrfiderrors.invalidrfidprovided") },
            {type: 'rfid', error: "SUSPENDED", translate_error: t("emailrfiderrors.rfidstatus.suspended") },
            {type: 'rfid', error: "BLOCKED", translate_error: t("emailrfiderrors.rfidstatus.blocked") },
            {type: 'rfid', error: "DELETED", translate_error: t("emailrfiderrors.rfidstatus.deleted") },
            {type: 'both', error: "Invalid Match", translate_error: t("emailrfiderrors.invalidmatch") },
            {type: 'rfid', error: "Given RFID is already mapped with user-group", translate_error: t("emailrfiderrors.rfidalreadymapped", {errorGroup}) },
            {type: 'rfid', error: "Invalid RFID, mapped with the user who is part of another group", translate_error: t("emailrfiderrors.rfidmapped", {errorGroup}) },
            {type: 'email', error: "Invalid Email Id. Enter a valid Email Id", translate_error: t("emailrfiderrors.invalidemailid") },
            {type: 'rfid', error: "Invalid RFID. Enter a valid number", translate_error: t("emailrfiderrors.invalidrfid") },
            {type: 'both', error: "The user account is disabled. To enable it again, please contact administrator" , translate_error: t("emailrfiderrors.userdisabled") }     
        ]

        ERROR_MESSAGES.forEach((element: any) => {
            if(data.includes(element.error)) {
                setErrorResponseType(null);
                setErrorMessage(null);
                setErrorResponseType(element.type);
                setErrorMessage(element.translate_error);
            }
        })
    }


    return (
        <>
            {/* Dialog Add Start */}
            <div className="dialog_box" data-testid="user_info_add_dialog">
                <Dialog
                    className="dialog_container zone_settings_dialog edit_detail_section  dialog_container_center"
                    {...props}
                    open={props.openAddNewUser}
                    onClose={props.addNewUserClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("common.addnewuser")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={props.addNewUserClose}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button onClick={handleSubmitData} type="submit" form="hook-form" className="btn_white blue_bg increase_size">
                                {t("common.save")}
                            </Button>
                        </div>
                    </div>
                    {isLoader && <LinearProgress />}
                    <DialogContent {...props}>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="information_section delegates_details">
                                <div className="defult_form mt-10">
                                    <div className="section_group">
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                            <div className="form_col radio_button">
                                                    {!props.isMembership && <RadioGroup
                                                      row
                                                      aria-label="position"
                                                      name="position"
                                                      defaultValue="top"
                                                      value={radioValue}
                                                      onChange={onChangeRadio}
                                                  >
                                                      <FormControlLabel
                                                          value="email"
                                                          labelPlacement="start"
                                                          control={
                                                              <Radio color="primary" />
                                                          }
                                                          label={t("common.email")}
                                                      />
                                                      <FormControlLabel
                                                          value="rfid"
                                                          labelPlacement="start"
                                                          control={
                                                              <Radio color="primary" />
                                                          }
                                                          label={t("common.blinkcard")}
                                                      />
                                                  </RadioGroup>
                                                }
                                              </div>
                                              <AddNewUserForm formType={radioValue} errorResponseType={errorResponseType} errorMessage={errorMessage} handleSubmitData={handleSubmitData}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>

            {/* Dialog Add End */}
        </>
    );
};
export default UserInfoAdd;
