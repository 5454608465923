import { FormControl, Grid, IconButton, OutlinedInput } from "@material-ui/core";
import { LabelInfo } from "common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getClassNameForDayName, getClassNameForInput, PRICING_PLAN_TYPES, addDetailedViewArray, getDayVal, createDetailedViewObject, TARIFF_MUL_FAC, TARIFF_PARKING_COST, getClassNameForBorderBottom } from "../pricingPlanHelpers";
import Images from "assets/images";
import { checkFloatValue } from "common/methodHelpers";

const DayDurationDetailedPricingPlan = (props: any) => {
    const { t } = useTranslation();
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowErrorMsg(false)
        }, 10000)
    }, [props])

    const getPricingTypeName = () => {
        if (props.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY)
            return t('pricing.timeofday');
        else
            return t('pricing.duration');
    }

    const pricingPlanExpandCollapse = (key: any) => {
        props.timeArrayDetailedKeys.forEach((pricingExpand: any) => {
            if (pricingExpand === key) {
                props.timeArrayDetail[pricingExpand]['substatus'] = !props.timeArrayDetail[pricingExpand]['substatus']
            }
            else {
                props.timeArrayDetail[pricingExpand]['substatus'] = false;
            }
            props.expandPricingPlan(props.timeArrayDetail);
        });
    }

    const calTotalCost = (event: any, key: any, dayStart: any, dayEnd: any, tree: any, dayIndex: number = 0, splitIndex: number = 0) => {
        let floatValue = checkFloatValue(event);
        if (floatValue) {
            let value = event.target.value;
            if (value > (TARIFF_MUL_FAC * TARIFF_PARKING_COST)) {
                setShowErrorMsg(true);
            }
            if (tree === "sub") {                
                for (let i = dayStart; i <= dayEnd; i++) {
                    let totalCost = 0;
                    let dayVal = getDayVal(i);
                    props.timeArrayDetail[key]['sub'][splitIndex]['day'][dayVal] = value;
                    let data = props.timeArrayDetail[key]['sub'];
                    for (const element of data) {
                        totalCost = totalCost + Number(element['day'][dayVal]);
                    }
                    props.timeArrayDetail[key]['day'][dayVal] = totalCost.toFixed(2);
                }
            }
            else if (tree === 'main') {
                for (let i = dayStart; i <= dayEnd; i++) {
                    let dayVal = getDayVal(i)
                    props.timeArrayDetail[key]['day'][dayVal] = value;
                    for (let j = 0; j < 4; j++) {
                        props.timeArrayDetail[key]['sub'][j]['day'][dayVal] = (value / 4);
                    }
                }
            }
            props.expandPricingPlan(props.timeArrayDetail);
            addDetailedViewArray(dayIndex, props.timeArrayDetailedKeys, props.timeArrayDetail, props.pricingPlanData);
            let detailedObject = createDetailedViewObject(props.pricingPlanData, props.timeArrayDetail, true);
            props.setDetailedViewobject(detailedObject);
        }
    }

    const getDayName = (dayVal: any) => {
        let dayValueStart = "";
        let dayValueEnd = "";
        dayValueStart = getDayVal(dayVal.start);
        dayValueEnd = getDayVal(dayVal.end);
        if (dayVal.start !== dayVal.end) {
            return dayValueStart.substring(0, 3) + " - " + dayValueEnd.substring(0, 3);
        }
        else {
            return dayValueStart;
        }
    }

    const getSplitValue = (value : any) => {
        return (value === 0) ? "0.00" : value;
    }

    return (
        <div className="pricing-detailed">
            <div className="detailed-splitup">
                <div className="info_detail common_form">
                    <div className="default_form mt-30">
                        <div className="ml_10 form_col">
                            <Grid container
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <Grid item xs={3}>
                                    <Grid container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        className="ml-15">
                                        <Grid item>
                                            <LabelInfo name={getPricingTypeName()}
                                                isMandatory={false} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {props.pricingPlanData && props.pricingPlanData.map((monthVal: any) => (
                                    <>
                                        {monthVal.day && monthVal.day.map((dayVal: any, dayIndex: number) => (
                                            <Grid item xs={1}>
                                                <Grid container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    className={getClassNameForDayName(dayIndex)}
                                                >
                                                    <Grid item>
                                                        <LabelInfo name={getDayName(dayVal)}
                                                            isMandatory={false} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                ))}
                            </Grid>
                            {props.timeArrayDetailedKeys.map((key: any, keyIndex:any) => (
                                <div className="mb-10 bottom_border">
                                    <div className="mt-15">
                                        <Grid container
                                            justifyContent="flex-start"
                                            alignItems="flex-start">
                                            <Grid item xs={3} >
                                                <>
                                                    <IconButton className="top_action_btn" data-testid = {`action-btton-${keyIndex}`}
                                                        onClick={() => pricingPlanExpandCollapse(key)}>
                                                        <img src={!props.timeArrayDetail[key]['substatus'] ? Images.IC_ACCORDIAN_PLUS : Images.IC_ACCORDIAN_MINUS}
                                                            alt="expand" />
                                                    </IconButton>
                                                    {props.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY ?
                                                        <span>
                                                            {props.timeArrayDetail[key].from} - {props.timeArrayDetail[key].to}
                                                        </span> :
                                                        <span>
                                                            {props.timeArrayDetail[key].fromDur} - {props.timeArrayDetail[key].toDur}
                                                        </span>
                                                    }
                                                    {props.timeArrayDetail[key].substatus === true &&
                                                        props.timeArrayDetail[key].sub.map((time: any, splitIndex: number) => (
                                                            <>
                                                                <div className={splitIndex === 0 ? "mt-15 mb-10 split_bottom_border" : ""}></div>
                                                                <div className="child_split">
                                                                    {props.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY ?
                                                                        <div>
                                                                            {time.from} - {time.to}
                                                                        </div> :
                                                                        <div>
                                                                            {time.fromDur} - {time.toDur}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                            </Grid>
                                            {props.pricingPlanData && props.pricingPlanData.map((monthVal: any) => (
                                                <>
                                                    {monthVal.day && monthVal.day.map((dayVal: any, dayIndex: number) => (
                                                        <Grid item xs={1} className={getClassNameForInput(dayIndex)}>
                                                            <Grid container
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start">
                                                                <Grid item className="result">
                                                                    <div className="form_control">
                                                                        <div className={"form_col"}>
                                                                            <FormControl>
                                                                                <OutlinedInput
                                                                                    className={props.timeArrayDetail[key].substatus === true ? "input_text disable" : "input_text"}
                                                                                    name="cost"
                                                                                    value={getSplitValue(props.timeArrayDetail[key]['day'][getDayVal(dayVal.start)])}
                                                                                    placeholder='0.00'
                                                                                    inputProps={{ maxLength: 5, "data-testid": `cost-input-${keyIndex}` }}
                                                                                    onChange={(event) => calTotalCost(event, key, dayVal.start,dayVal.end, 'main', dayIndex)}
                                                                                    disabled={props.timeArrayDetail[key].substatus === true}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            {props.timeArrayDetail[key].substatus === true &&
                                                                props.timeArrayDetail[key].sub.map((time: any, splitIndex: number) => (
                                                                    <>
                                                                        <div className={splitIndex === 0 ? `split_bottom_border_days ${getClassNameForBorderBottom(dayIndex)}` : ""}></div>
                                                                        <Grid container
                                                                            justifyContent="flex-start"
                                                                            alignItems="flex-start"
                                                                            className="mt-15">
                                                                            <Grid item className="result">
                                                                                <div className="form_control">
                                                                                    <div className={"form_col"}>
                                                                                        <FormControl>
                                                                                            <OutlinedInput
                                                                                                className={"input_text"}
                                                                                                name="split-cost"
                                                                                                value={getSplitValue(time['day'][getDayVal(dayVal.start)])}
                                                                                                placeholder='0.00'
                                                                                                inputProps={{ maxLength: 5, "data-testid": `cost-input-${keyIndex}-${splitIndex}` }}
                                                                                                onChange={(event) => calTotalCost(event, key, dayVal.start, dayVal.end, 'sub', dayIndex, splitIndex)}
                                                                                            />
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                ))
                                                            }
                                                        </Grid>
                                                    ))}
                                                </>
                                            ))}
                                        </Grid>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {showErrorMsg &&
                            <div className="error_msg_cost">{t("pricing.highcost")}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DayDurationDetailedPricingPlan;