const MENU_ITEM_MANAGE_USERS = 'MENU_ITEM_MANAGE_USERS';

export const sidebarItems = (state = {}, action: any) => {

    if(action.type === MENU_ITEM_MANAGE_USERS) {
        state = {
            ...state,
            manageUsersEnabled: action.payload
        }
        return state;
    } else {
        return state;
    }
}