import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { toUpper } from "lodash";
import { Card, CardContent, IconButton } from "@material-ui/core";
import Images from "assets/images";
import UserGroupUpdate from "../userGroupUpdate";
import { useTranslation } from "react-i18next";
import { HostSettingUserGroupInfo } from "common/interfaces/hostSetting";
import { urlReplace } from "common";
import { ApiEndPoint, getMethod } from "services";

const UserGroupInfo = (props: HostSettingUserGroupInfo) => {
  const { t } = useTranslation();

  const [editUserGroupOpen, setEditGroupOpen] = useState(false);
  const [userGroupDetails, setUserGroupDetails] = useState<any>();


  const editGroupOpen = () => {
    setEditGroupOpen(true);
  };

  const editGroupClose = () => {
    setEditGroupOpen(false);
    getUserGroupDetails();
  };

  const getUserGroupDetails = async () => {
      const url = urlReplace(ApiEndPoint.groupDetailsUserEdit, "{USERGROUP-ID}", props?.userGroupId);
      await getMethod(url, '').then((res) => {
        setUserGroupDetails(res?.data);
      }).catch((err) => {
        console.error("Error", err)
      })
    }

  useEffect(() => {
    if (props?.userGroupId) {
      getUserGroupDetails();
    }
  }, [props?.userGroupId]);

  return (
    <>

     {editUserGroupOpen && <UserGroupUpdate showUserList={props.showUserList} userGroupId={props?.userGroupId} editUserGroupOpen={editUserGroupOpen} editGroupClose={editGroupClose} />} 
      
      <div className="information_section delegates_details" data-testid="user_group_info">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined" className="basic_card">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="avtar_user ">
                      <img className="user-logo" src={userGroupDetails?.imageReference ?? Images.shift_driver_group} alt="shift_driver_group" />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info_detail">
                      <div className="tb_info_section mt-0">
                        <div className="label">
                          {toUpper(t("common.usergroupname"))}
                        </div>
                        <div className="result">
                          {userGroupDetails?.name}
                        </div>
                      </div>
                      <div className="tb_info_section">
                        <div className="label">
                          {toUpper(t("common.notes"))}
                        </div>
                        <div className="result">
                          {userGroupDetails?.notes}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="info_detail">
                      <div className="tb_info_section mt-0">
                        <div className="label">
                          {toUpper(t("common.creationdate"))}
                        </div>
                        <div className="result">
                          {userGroupDetails?.createdTimestamp}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid className="text_right" item xs={2}>
                    <div className="number_details">
                      <div className="label">{toUpper(t("common.users"))}</div>
                      <div className="result">
                        {userGroupDetails?.numberOfUsers}
                      </div>
                    </div>
                    <div className="number_details">
                      <div className="label">
                        {toUpper(t("common.blinkcards"))}
                      </div>
                      <div className="result">
                        {userGroupDetails?.numberOfRfids}
                      </div>
                    </div>
                  </Grid>
                  <Grid className="text_right" item xs={2}>
                    <div className="title_row">
                      <div className="action_icon" onClick={editGroupOpen}>
                        <IconButton className="top_action_btn">
                          <img src={Images.ic_edit} alt="edit" />
                        </IconButton>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>{" "}
    </>
  );
};
export default UserGroupInfo;
