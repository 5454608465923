import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { ZoneListColumnsComponent } from "pages/tableColumns/zonesListColumns";
import Grid from "@material-ui/core/Grid";
import { HandlePaginationLabel, pageDetails } from "common";
import LocationsDetails from "pages/hostSettings/evseLocationsList/locationDetails";
import { CircularProgress } from "@material-ui/core";
import CommonPagination from "components/commonPagination";
import { useHistory } from "react-router-dom";
import {fetchLocationListByPagination} from "../../../services/evseLocationsService";

const EvseLocationsList = () => {
    const zonesColumns = ZoneListColumnsComponent();
    const [isDefaultLocations, setIsDefaultLocations] = useState(true);
    const [currentLocation, setCurrentLocation] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [loadingPage, setLoadingPage]  = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalLocationCount, setTotalLocationCount] = useState<any>(0);
    const pagingSize = pageDetails.pageSize;
    const history = useHistory();
    const { location: { pathname,search,hash }} = history;

    const getEvseLocations =  (pageCount: number = 0) => {
        setLoadingPage(true);
        let params = {page: pageCount, size: pagingSize};
        fetchLocationListByPagination(params).then((data: any) => {
            let locationsTableData = [];
            if (data?.locationsInfo && data?.locationsInfo.length > 0) {
                locationsTableData = data?.locationsInfo.map((val:any, index: number) => ({
                    ...val,
                    id: index,
                    mapDetails: val?.address
                }));

            }
            setLocationList(locationsTableData);
            setTotalLocationCount(data.totalLocations);
            setLoadingPage(false);
        }) .catch((err) => {
            setLoadingPage(false);
            console.error("Error", err);
        });
    };
    
    const showLocationDetails = (value: boolean, currentLocationId: string) => {
        setCurrentLocation(currentLocationId);
        setIsDefaultLocations(value);

        history.push({
            pathname: pathname,
            search: '?currentLocationId=' + currentLocationId,
            hash: hash
        })
    };

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage);
        setLocationList([]);
        getEvseLocations(setPage);
    };

    useEffect(()=>{
        if(search === ''){
            setIsDefaultLocations(true);
        }
    },[search]);

    useEffect(() => {
        getEvseLocations();
    }, []);

    return (
        <>
            {loadingPage ? <CircularProgress className="inner_progress_userGroup"/> : (isDefaultLocations && (
                <div className="table_scroll" data-testid="evse_locations_list">
                    <div className="default_table">
                        <DataGrid
                            autoHeight
                            vertical-align="top"
                            className="table"
                            rowHeight={80}
                            headerHeight={30}
                            rows={locationList}
                            columns={zonesColumns}
                            pageSize={pagingSize}
                            onRowClick={(e) => showLocationDetails(false, e.row?.locationId)}
                        />
                    </div>
                    <div className="table_footer">
                        <Grid container direction="row" alignItems="center" spacing={0}>
                            <Grid className="text_left" item xs={8}>
                                {locationList && totalLocationCount > pagingSize && <CommonPagination
                                        currentPage={currentPage}
                                        totalCount={totalLocationCount}
                                        callBackCurrentPage={handleCurrentPage}
                                        pageSize = {pagingSize}
                                    />
                                }
                            </Grid>
                            <Grid className="text_right" item xs={4}>
                                <div className="list_number">
                                    {HandlePaginationLabel(totalLocationCount, pagingSize, currentPage, "common.locations")}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ))}
            {!isDefaultLocations && <LocationsDetails locationId={currentLocation} />}
        </>
    );
};

export default EvseLocationsList;
