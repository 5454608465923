import {makeStyles} from "@material-ui/core/styles";
import Images from "assets/images";
import Keycloak from "keycloak-js";
import environment from '../environment';
import {dateFormats} from './dateFormatHelpers';
import {getMapOptions, getAnotherOptions} from './mapHelpers';
import GetUrlDetails, {urlReplace} from './urlHelpers';
import TabPanel from './tabPanel'; 
import { menuList } from  './sidebarMenu';
import { CheckAccess } from "./abilityHelpers";
import { claims } from "./claims";
import { showProgressBar, distanceToMouse, a11yProps, fieldDataCheck, LabelInfo, dateDiffGenerate, isMultipleLabelCheck, isStationCheck, timeCheck, chargeSessionInformation, convertSecondToFormat, setAddressFormat, handleErrorOnBlur, isUrlParamEmpty, HandlePaginationLabel, getFormattedDateTimeWithTZ, isFieldValueCheck, isCurrencyValueCheck, viewLabelInfo, labelMultiInfo, bytesToSize, getFirstLetterColor, rolePermissionCheck, checkLocationPermission, getPermitedLocation, isStationEmpty, ColumnNameTranslate, renderCellExpand, getFormattedDateTimeWithApiTimezone, conversionWattsToKw, findMaxArrayObjectValue, eventStyleGetter, startOfWeekHelper } from "./methodHelpers";

export const sidebarData = {
  drawerWidth: 200
}

export const supportConfigData = {
  phone: "+1 (888) 998-2546",
  email: "Support@BlinkCharging.com"
}


export const pageDetails = {
    pageSize: 10,
    page: 1,
    pageSizeTwenty: 20,
    pageSizeHundred: 100,
}

export const ZERO_VALUE = 0;

export const ALLOWED_ACCESS_ROLES = [
  'DELEGATE_BLINK', 
  'MANAGER_BLINK', 
  'MANAGER_HOST', 
  'BLINK_OWNED_MANAGER', 
  'DELEGATE'
];

export const mapDetails = {
  depotLocationMapZoom: 11,
  viewSatellite: true
}

export const depotGoogleMap = { 
  zoom: 9,
  transactionZoom:14
};

export const styleDetails = {
  styleWidth:{ width: '100%' }
};

export const organizationName = {
  name: "LAX Food Services"
}
export const STATUS = {
  AVAILABLE: "Available",
  RESERVED: "Reserved",
  CONNECTED: "Connected",
  CHARGING: "Charging",
  COMPLETE: "Complete",
  ERROR: "Error",
  OFFLINE: "Offline",
  FAULT: "Fault/Error"
};

export const BLANK_DATA_PLACEHOLDER = '-';
export const BLANK_DATA = '';
export const WATTS_VALUE = 1000;
export const SOURCE_SEMA = "SEMA";



export const mapStyle = { height: 'auto', width: 'auto', transform: 'translate(-50%, -50%)' };
export const defaultVariable: {[key:string]: any} = { 

    freeChargingDetails : [
    {id: 1, value: 0, name: "0 min"},
    {id: 2, value: 300, name: "5 min"},
    {id: 3, value: 600, name: "10 min"},
    {id: 4, value: 900, name: "15 min"},
    {id: 5, value: 1200, name: "20 min"},
    {id: 6, value: 1800, name: "30 min"},
    {id: 7, value: 2400, name: "40 min"},
    {id: 8, value: 3600, name: "1 hour"},
    {id: 9, value: 5400, name: "1 hour 30 min"},
    {id: 10, value: 7200, name: "2 hours"},
    {id: 11, value: 10800, name: "3 hours"},
    {id: 12, value: 14400, name: "4 hours"}
  ],
  
   billingIncrementDetails : [
    {id: 1, value: 300, name: "5 min"},
    {id: 2, value: 600, name: "10 min"},
    {id: 3, value: 900, name: "15 min"},
    {id: 4, value: 1800, name: "30 min"},
    {id: 5, value: 3600, name: "1 hour"}
  ],

  usageBillingIncrementDetails : [
    {id: 1, value: 15, name: "15 sec"},
    {id: 2, value: 30, name: "30 sec"},
    {id: 3, value: 60, name: "1 min"},
    {id: 4, value: 300, name: "5 min"},
    {id: 5, value: 600, name: "10 min"},
    {id: 6, value: 900, name: "15 min"},
    {id: 7, value: 1800, name: "30 min"},
    {id: 8, value: 3600, name: "1 hour"}
  ],

  gracePeriodDetails : [
    {id: 1, value: 0, name: "0 min"},
    {id: 2, value: 300, name: "5 min"},
    {id: 3, value: 600, name: "10 min"},
    {id: 4, value: 900, name: "15 min"},
    {id: 5, value: 1200, name: "20 min"},
    {id: 6, value: 1800, name: "30 min"},
    {id: 7, value: 3600, name: "1 hour"}
  ],

  locationAccessDropDownDetails : [
    {
      id: 0,
      name: "Private"
  },
  {
      id: 1,
      name: "Public"
  },
  ],

  CSV_VALUE : 'csv',
  defaultUnit : 'kWh',  
  defaultUnitType : 'kW',  
  defaultCurrency: 'USD',
  defaultCurrencyName: 'US Dollar',
  defaultCurrencySymbol: "$",
  defaultCountry: "US",
  defaultState: "UE",
  DEFAULT_STATION_ACCESS: "Public",
  defaultTimeZone: "America/Antigua",
  defaultLocationAccess : 2,
  DELEGATE_FILESIZE : 4190000,
  DEFAULT_IMAGE_FILESIZE : 6291456,
  defaultLanguage: "ENGLISH",
  locationIdInformation: "55a90c09-c62b-46ed-9c00-7b2ae0054097",
  textAreaLength: 400,
  DEFAULT_CENTER: { lat: 36.778259, lng: -119.417931 },

  USER_GROUP: {
    freeCharge: "Free Charging",
    freeChargeSema: "Free (Series X Stations)",
},

  portStatusIndicatorMap: {
    UNREACHABLE: {
        text: 'evselocations.health.unreachable',
        bgclass: 'unavailable'
    },
    CHARGING: {
        text: 'evselocations.health.charging',
        bgclass: 'charging'
    },
    CONNECTED: {
        text: 'evselocations.health.connected',
        bgclass: 'connected'
    },
    SUSPENDED_EVSE: {
        text: 'evselocations.health.suspendevse',
        bgclass: 'charging'
    },
    SUSPENDED_EV: {
        text: 'evselocations.health.suspendev',
        bgclass: 'charging'
    },
    COMPLETED: {
        text: 'evselocations.health.completed',
        bgclass: 'charging'
    },
    AVAILABLE: {
        text: 'evselocations.health.available',
        bgclass: 'available'
    },
    ERROR: {
        text: 'evselocations.health.error',
        bgclass: 'error'
    },
    FAULTED: {
        text: 'evselocations.health.faulted',
        bgclass: 'fault'
    },
    UNAVAILABLE: {
        text: 'evselocations.health.unavailable',
        bgclass: 'unavailable'
    },
    'USER SUSPEND': {
        text: 'evselocations.health.usersuspend',
        bgclass: 'unavailable'
    },
    DECOMMISSIONED: {
        text: 'evselocations.health.decommissioned',
        bgclass: 'unavailable'
    }
  },

 vehicleStatusColor: {
  error: 'error', 
  faulted: 'error', 
  unavailable : 'offline',
  complete: 'complete', 
  connected: 'connected', 
  charging : 'charging' , 
  offline: 'offline', 
  available: 'available',
  parked: 'parked',
  transit: 'transit',
  inservice: 'in-service',
  reserved: 'reserved'
},

driverStatusColor: {
  'on duty': 'driver_onduty', 
  'suspended': 'driver_suspended', 
  'off duty': 'offline'
},



urlParams: {
  depotStationDetails: "/evseLocations/depotStationDetails"
},


fieldName: {
  email : 'email'
},

scrollType: {
  paper: 'paper'
},

STATUS: {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
},

MEMBER_STATUS: {
  active : 'Active', suspend : 'Suspend', pending : 'Pending', declined : 'Declined'
},

FORBIDDEN_ERROR_CODE: '403',

ROLES: {
  delegate: 'DELEGATE'
},

LOCATION_PERMISSIONS: {
  delegate: 'delegate',
  delegateAccess: 'delegate:access',
  delegateAssign: 'delegate:assign',
  delegateSchedule: 'delegate:schedule',
  delegateAddress: 'delegate:address',
}  

}

export const commonRoutes: any = { 
  fleetDashboardRoute : '/',
  depotAllRoutes : ['/evseLocations', '/evseLocations/evseDepotDetail', '/evseLocations/depotStationDetails'],
  vehicleAllRoutes : ['/vehicleList', '/fleetVehicle', '/vehicleDetail'],
  userAllRoutes : ['/manageUsers', '/manageUsers/manageUsersDetail','/manageUsers/manageUsersMembershipDetail', '/manageUsersInfo', '/manageUsers/privateAccessRequests'],
  messageAllRoutes : ['/messages'],
  hostSettingsAllRoutes :['/hostSettings'] ,
  trainingAllRoutes : ['/training', '/training/details'],
  depotListRoute: '/evseLocations',
  depotDetailRoute: '/evseLocations/evseDepotDetail',
  depotStationDetailRoute: '/evseLocations/depotStationDetails',
  vehicleListRoute: '/vehicleList',
  fleetVehicleRoute: '/fleetVehicle',
  vehicleDetailRoute: '/vehicleDetail',
  manageUserRoute: '/manageUsers',
  userDetailRoute: '/manageUsers/manageUsersDetail',
  driverInfoRoute: '/driverInfo',
  messageRoute : '/messages',
  hostSettingsRoute: '/hostSettings#0',
  blinkCardAllRoutes :['/blinkCards'] ,
  blinkCardsRoute: '/blinkCards',
  locationListRoute: '/evseLocations',
  trainingRoute: '/training',
  profile: '/profile',
  trainingDetailsRoute: '/training/details',
  fleetRoutes :'/fleet#0',
  fleetAllRoutes :['/fleet','/fleet/fleetInfo', '/fleet/fleetVehicleInfo'],
};

export const sidebarImages: any = { 
  dashboardImage: Images.IC_DASHBOARD,
  hoverDashboard: Images.IC_DASHBOARD_HOVER,
  locationImage: Images.IC_EVSE_DEPOT_NAV,
  locationHover:  Images.IC_EVSE_DEPOT_W_NAV,
  userImage: Images.IC_ACCOUNT_BOX,
  hoverUser: Images.IC_ACCOUNT_BOX_W,
  analyticsImage: Images.IC_ANALYTICS_NAV,
  hoverAnalytics: Images.IC_ANALYTICS_W_NAV,
  settingsImage: Images.IC_FLEET_SETTING_NAV,
  hoverSettings: Images.IC_FLEET_SETTING_W_NAV,
  statementImage: Images.IC_STATEMENT,
  hoverStatement: Images.IC_STAEMENT_W,
  blinkcardImage: Images.IC_BLINKCARD,
  hoverBlinkcard: Images.IC_BLINKCARDHOVER_W,
  trainingImage: Images.IC_TRAINING,
  hovertrainingimage: Images.IC_TRAININGHOVER_W,
  fleetImage: Images.IC_FLEET,
  hoverFleet: Images.IC_FLEET_HOVER,

}

export const requiredSign: any = {
  important: "*"
}

export const MODE_TYPE = {
  New: 0,
  Edit: 1
};

export const DEFAULT_CLOSE = "Close";

export const DEFAULT_OPEN = "Open";

export const useStyles = makeStyles((theme) => ({
    search: {
      position: 'relative',
      '&:hover': {
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
        paddingRight: '10px',
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '313px',
      background: '#fff',
      borderRadius: '5px'

    },

  search1: {
      position: 'relative',
      '&:hover': {
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
        paddingRight: '10px',
      },
      marginRight: theme.spacing(1),
      marginLeft: 0,
      width: '150px',
      background: '#fff',
      borderRadius: '5px'

    },
    searchIcon: {
      padding: '0px 10px',
      height: '100%',
      fontSize: '14px',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#999',
    },

    inputRoot: {
      color: '#999',
    },
    inputInput: {
      padding: theme.spacing(1.5, 1, 1.5, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
      transition: theme.transitions.create('width'),
      fontSize: '12px',
      width: '300px',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
      },
    },
    inputInput1: {
      padding: theme.spacing(1.5, 1, 1.5, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
      transition: theme.transitions.create('width'),
      fontSize: '12px',
      width: '106px',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
      },
    },

    root: {
        display: 'flex',
    },
    drawer: {
        width: sidebarData.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarData.drawerWidth,
    },

    drawerOpen: {
        width: sidebarData.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },


    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export const keyCloakConfig = Keycloak({
  url: environment.keyCloakurl,
  realm: environment.keyCloakRealm,
  clientId: environment.keyCloakClientId,
 });

export const REPORT_TYPE_MAPPING: any = {'station_utilization' : 'Station Utilization', 'station_usage': 'Station Usage Patterns', 'host_revenue_by_station' : 'Host Revenue by Station', 'host_revenue_by_location': 'Host Revenue by Location'  }
export  const REPORT_TYPES: any = [
    {label: 'All', value: 'all'},
    {label: 'Station Utilization', value: 'station_utilization'},
    {label: 'Station Usage Patterns', value: 'station_usage'},
    {label: 'Host Revenue by Station', value: 'host_revenue_by_station'},
    {label: 'Host Revenue by Location', value: 'host_revenue_by_location'}
];
export  const MONTHS: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export  const YEARS: any = ['2023', '2022'];

export const getDownloadData = (data:any, fileName: string) => {
    window.URL = window.URL || window.webkitURL;
    let userAgent = navigator.userAgent;
    let blob;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        blob = new Blob([decodeURIComponent(encodeURI(data))], {type: 'text/csv;charset=utf-8;'});
    } else {
        blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
};

export const defaultTimeZoneSelect = 'US/Mountain';

 export {
     dateFormats,
     showProgressBar,
     distanceToMouse,
     a11yProps,
     fieldDataCheck,
     getMapOptions,
     getAnotherOptions,
     TabPanel,
     menuList,
     LabelInfo,
     dateDiffGenerate,
     isMultipleLabelCheck,
     isStationCheck,
     timeCheck,
     chargeSessionInformation,
     ColumnNameTranslate,
     GetUrlDetails,
     convertSecondToFormat,
     setAddressFormat,
     handleErrorOnBlur,
     isUrlParamEmpty,
     HandlePaginationLabel,
     getFormattedDateTimeWithTZ,
     urlReplace,
     isFieldValueCheck,
     isCurrencyValueCheck,
     CheckAccess,
     claims,
     renderCellExpand,
     viewLabelInfo, 
     labelMultiInfo,
     bytesToSize,
     getFirstLetterColor,
     rolePermissionCheck,
     checkLocationPermission,
     getPermitedLocation,
     isStationEmpty,
     getFormattedDateTimeWithApiTimezone,
     conversionWattsToKw,
     findMaxArrayObjectValue,
     eventStyleGetter,
     startOfWeekHelper,
 };

 export const SUCCESS = 200;
 export const EDIT = "edit";
 export const BLANK = " ";
 export const LOCATION_INFO = "LOCATION_INFO";
 export const USAGE_RATE = "USAGE_RATE";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const COUNTRY_CODE = {ISRAEL: 'IL'};
export const HOST_USER_GROUP_ENUM = {'BLINK': 'BLINK', 'SEMA': 'SEMA', 'BLINK_SEMA': 'BLINK_SEMA'};

 export const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

export const getBlankCurrencyPlaceholder = (currency: string, amount: any = 0) => {
    if(currency) {
        return !amount ? currency + '0.00' : currency + amount;
    } else {
        return BLANK_DATA_PLACEHOLDER;
    }
};
