import {ApiEndPoint, getMethod} from "./index";
import {urlReplace} from "../common";


export const fetchChargeSession = async  (params: any = '', mock = false)  => {
    const url = urlReplace(ApiEndPoint.charingSessionsDetails, "{CHARGE-HISTORY-ID}", params?.historyId);
    const paramDetails = '?page=' + params?.currentPage + '&size=' +  params?.pageSize;
    const data =  await getMethod(url, paramDetails, mock).then((res) => {
            if (res) {
                return res
            }
        })
        .catch((err) => {
            console.log("Error", err);
        });
    return data;
};
