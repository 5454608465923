

export const loginTime = (state = '', action: any) => {

    if(action.type === 'LOGIN_TIME') {
        state = action.payload;
        return state;
    } else {
        return state;
    }

}