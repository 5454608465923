import React from "react";
import {DialogContent, DialogContentText, Grid, Select, MenuItem, Button, LinearProgress,} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {ACCESS_TO_STATIONS, setClassName} from "../pricingPlanHelpers";
import "../semaConnectChargeFee.scss";
import {BLANK} from "../../../../../common";

const Pricing = (props: any) => {
    const {applyPricingDetails, tariffForDropDown, simpleValidator, loadingPage, selectedEdit, disablePricingSaveButton} = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="dialog_title">
                <h4>{selectedEdit ? t("pricing.changepricingsteptwo") : t("pricing.applypricingsteptwo")}</h4>
                <div className="button_row mt-0 full_right">
                    <Button className="btn_white text_btn_theme" onClick={props.handlechangeCancel} disabled={loadingPage}>
                        {t("common.cancelbutton")}
                    </Button>
                    <Button className="btn_white outline_btn" onClick={props.navigateBacktoFirstStep} disabled={loadingPage}>
                        {t("common.backbutton")}
                    </Button>
                    <Button className={`btn_white ${setClassName(loadingPage,false,disablePricingSaveButton)}`}
                            onClick={props.navigateToApplyPricingSecondStepNextSave}
                            disabled={loadingPage || disablePricingSaveButton}
                    >
                        {t("pricing.apply")}
                    </Button>
                </div>
            </div>
            {loadingPage && <LinearProgress />}
            <DialogContent>
                <DialogContentText id="scroll-dialog-description">
                    <div className="defult_form mt-30">
                        <div className="section_group">
                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                <Grid item xs={12}>
                                    {applyPricingDetails?.accessToStations !== ACCESS_TO_STATIONS[0]['id'] && (
                                        <>
                                            <div className="form_col">
                                                <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                                    <Grid item xs={4}>
                                                        <label className="w_100per full_w">
                                                            {t("pricing.publicpricingplan")} {t("common.required")}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select
                                                            className="w_100per full_w"
                                                            labelId="demo-simple-select-label"
                                                            variant="outlined"
                                                            name="publicPricing"
                                                            value={applyPricingDetails?.publicPricing ? applyPricingDetails?.publicPricing: BLANK}
                                                            onChange={(e) => props.handleChangePlanPublic(e)}
                                                        >
                                                            <MenuItem className="defult_select_option" key={0} value={BLANK} disabled>
                                                                {t("pricing.selectpublicpricing")}
                                                            </MenuItem>
                                                            {tariffForDropDown.length > 0 &&
                                                            tariffForDropDown.map((value: any) => (
                                                                <MenuItem  className="defult_select_option" key={value.id} value={value.id}>
                                                                    <Grid>{value.name} <span className="plan_text"> - {value.type}</span></Grid>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <Grid><span className="description">{t("pricing.publicpricingplandescription")}</span></Grid>
                                                        <Grid>
                                                            <cite className="srv-validation-message">
                                                                {simpleValidator.current.message(t("pricing.publicpricingplan"), applyPricingDetails?.publicPricing, "required")}
                                                            </cite>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}

                                    <div className="form_col">
                                        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                            <Grid item xs={4}>
                                                <label className="w_100per full_w">
                                                    {t("pricing.privateaccessplan")} {t("common.required")}
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    className="w_100per full_w"
                                                    labelId="demo-simple-select-label"
                                                    variant="outlined"
                                                    name="memberPricing"
                                                    value={applyPricingDetails?.memberPricing ? applyPricingDetails?.memberPricing: BLANK}
                                                    onChange={(e) => props.handleChangePlanMember(e)}
                                                >
                                                    <MenuItem className="defult_select_option" key={0} value={BLANK} disabled>
                                                        {t("pricing.selectprivateaccesspricing")}
                                                    </MenuItem>
                                                    {tariffForDropDown.length > 0 && tariffForDropDown.map((value: any) => (
                                                        <MenuItem  className="defult_select_option" key={value.id} value={value.id}>
                                                            <Grid>{value.name} <span className="plan_text"> - {value.type}</span></Grid>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <Grid>
                                                    <span className="description">
                                                        {t("pricing.memberpricingplandescription")}
                                                    </span>
                                                </Grid>
                                                <cite className="srv-validation-message">
                                                    {simpleValidator.current.message(t("pricing.privateaccessplan"), applyPricingDetails?.memberPricing, "required")}
                                                </cite>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    );
};
export default Pricing;
