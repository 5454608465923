import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { claimsData } from "data/claimsData";

export type SetAbilities = Ability;
export const appAbility = Ability as AbilityClass<SetAbilities>;

export default function defineRulesFor(userClaims: any) {
    const { can, rules } = new AbilityBuilder(appAbility);
    if(userClaims) {
        userClaims.forEach((claims: any) => {
            const { rsname, scopes } = claims;
            if (scopes && rsname) {
                const subject = rsname;
                scopes.forEach((action: any) => {
                    action.toString() === "*" ? can('manage', subject) : can(action, subject);
                })
            }
        })
    }
    return rules;
}

export function buildAbilityFor(userClaims: any): SetAbilities {
    return new appAbility(defineRulesFor(userClaims), {
        detectSubjectType: (object:any) => object?.type
    })
}

export const claimsDataSet = (keycloak:any) => {
    if(keycloak) {
        const userRoles = keycloak?.tokenParsed?.realm_access?.roles;
        let setClaimsData: any[] = [];
        Object.entries(claimsData).forEach(([key, value]) => {
            if(userRoles?.includes(key)) {
                Array.prototype.push.apply(setClaimsData, value);
            }
        })
        return [ ...setClaimsData ];
    }
}