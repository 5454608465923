import { getMethod, ApiEndPoint } from "services";
import { urlReplace } from "common";

export const fetchDelegatesDetails =  (email: string) => {
    const urlSearchDelegates =  urlReplace(ApiEndPoint.searchDelegatesAcrossHosts, "{EMAIL-ID}", email);
    return getMethod(urlSearchDelegates,'');
};

export const fetchDelegateTypesData = () => {
    return getMethod(ApiEndPoint.delegateTypes, '')
};