import React, {useState, useEffect} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Images from "assets/images";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { toUpper } from 'lodash';
import {BLANK_DATA_PLACEHOLDER, CheckAccess, claims, defaultVariable, pageDetails, rolePermissionCheck, HOST_USER_GROUP_ENUM, keyCloakConfig} from "common";
import "./userGroups.scss";
import { useTranslation } from "react-i18next";
import { GroupDetails } from "common/interfaces/driverGroup";
import { getMethod, ApiEndPoint } from "services";
import CommonPagination from "components/commonPagination";
import { Button, CircularProgress } from "@material-ui/core";
import {  fetchTotalMembershipRequests, fetchUserGroupListing } from "services/membershipService";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },

    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
const UserGroups = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    
    const [depotSpinnerOff, setDepotSpinnerOff] = useState<boolean>(true);
    const [groupsPageData, setGroupsPageData] = useState('');
    const pageSize = pageDetails.pageSize;
    const [groupList, setGroupList] = useState<any>([]);
    const [memberUserGroupList, setMemberUserGroupList] = useState<any>([]);
    const [totalUserGroupsCount, setTotalUserGroupsCount] = useState<any>("");
    const [currentPage, setCurrentPage] = useState(0);
    const [progressList, seProgressList] = useState<any>([]);
    const linkStyle = { textDecoration: 'none' };
    let routingDisabled = localStorage.getItem('routingDisabled') ?? '';    
    const manageUser  = JSON.parse(routingDisabled);
    const [membershipRequestCount, setMembershipRequestCount] = useState<any>([]);    
    const [disabled, setDisabled] = useState(false);
    const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0);
    const [totalNumberOfActiveUsers, setTotalNumberOfActiveUsers] = useState(0);
    const [totalNumberOfPendingUsers, setTotalNumberOfPendingUsers] = useState(0);
    const [totalNumberOfSuspendedUsers, setTotalNumberOfSuspendedUsers] = useState(0);
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const readAccessForMembership = CheckAccess([claims.MEMBERSHIP_READ]) || isDelegate;
    
    let hostName = sessionStorage.getItem('memberShipSubscribtion');
    const memberShipSubscription = hostName === "true";

    const invitationCode: any = sessionStorage.getItem('invitationCode');
    const hostUserGroupDisplayFor: any = sessionStorage.getItem('hostUserGroupEnum')?? '';

    const navigateToUserDetails = (userGroupId: any) => {
        let searchParams = "?userGroup=" + userGroupId;
        history.push({
            pathname: '/manageUsers/manageUsersMembershipDetail',
            search: searchParams
        });
    };

 useEffect(()=>{
    if(manageUser === false){
       window.location.href = "/";
    }
    },[]);

    useEffect(() => {
        getGroupDetailsList().then(()=>{}) .catch((error) => {});
    }, [currentPage]);

    useEffect(() => {
        handlePageData();
        progressData();
    }, [groupList, memberUserGroupList]);

    const handlePageData = () => {
        if (totalUserGroupsCount > 0) {
            let setPageValue = (currentPage >= 1 ? (pageSize * currentPage) + 1 : 1) + ' - ' + ((pageSize * (currentPage + 1)) < totalUserGroupsCount ? (pageSize * (currentPage + 1)) : totalUserGroupsCount) + t("common.of")
            setGroupsPageData(setPageValue)
        }
    };

    const progressData = () => {
        const progressDataList: any[] = [];
        const usersKey = ['totalNumberOfActiveUsers', 'totalNumberOfPendingUsers', 'totalNumberOfSuspendedUsers'];

        let name;
        Object.entries(groupList).forEach(([key, value]) => {
            name = key.replace('totalNumberOf', '');
            name = name.replace('Users', '').toLowerCase();

            if(usersKey.includes(key)) {
                progressDataList.push({ name, percentage: getPercentage(value || 0, groupList) });
            }
        });

        seProgressList(progressDataList);
    };

    const getMemberInvitationDetails = () => {
        let params = {"count" : true, 'hostId' : hostId};
        fetchTotalMembershipRequests(params, true).then((res: any) => {            
            if(res) {
                setMembershipRequestCount(res);
                if(res.total === 0) {
                    setDisabled(true);
                }
                else {
                    setDisabled(false);
                }
            }
            setDepotSpinnerOff(false);
        })
    };

    const totalUserGroups = (res: GroupDetails) => {
        let totaluser = (res?.totalNumberOfActiveUsers ? res.totalNumberOfActiveUsers : 0) + (res?.totalNumberOfPendingUsers ? res.totalNumberOfPendingUsers : 0) + (res?.totalNumberOfSuspendedUsers ? res.totalNumberOfSuspendedUsers : 0)
        return totaluser
    };

    const getPercentage = (val: any, res: GroupDetails) => {
        return Math.fround((val / totalUserGroups(res)) * 100)
    };

    const getGroupDetailsList = async () => {
        if(hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK || hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA) {
            await getMethod(ApiEndPoint.groupDetails, "details?&page=" + currentPage + '&size=' + pageSize)
            .then((res) => {
                if(res) {
                    setTotalUserGroupsCount(res.data.totalUserGroups);
                    setGroupList(res.data);
                    if(hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA) {  
                        getMemberUserGroupDetails(res.data); 
                    }  else {
                        setTotalNumberOfActiveUsers(res.data.totalNumberOfActiveUsers);
                        setTotalNumberOfPendingUsers(res.data.totalNumberOfPendingUsers);
                        setTotalNumberOfSuspendedUsers(res.data.totalNumberOfSuspendedUsers);
                        setTotalNumberOfUsers(res.data.totalNumberOfUsers);
                        setDepotSpinnerOff(false);  
                    }                  
                } else {
                    setDepotSpinnerOff(false);
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });
        } else {
            getMemberUserGroupDetails();
        }       
    };

    const getMemberUserGroupDetails = (data?: any) => {
        let params = {'hostId' : hostId, 'currentPage': currentPage, 'pageSize': pageSize};
        fetchUserGroupListing(params).then((res: any) => {
            if (res) {
                let totalUserGroupCount = 0;
                let totalNumberOfUsers = 0;
                let totalNumberOfActiveUsers = 0;
                let totalNumberOfPendingUsers = 0;
                let totalNumberOfSuspendedUsers = 0;
                if(hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA ) {                    
                    totalUserGroupCount =  Number(data.totalUserGroups) + Number(res.totalUserGroups);
                    totalNumberOfUsers =  Number(res.totalNumberOfUsers) +  Number(data.totalNumberOfUsers);
                    totalNumberOfActiveUsers= Number(res.totalNumberOfActiveUsers) + Number(data.totalNumberOfActiveUsers);
                    totalNumberOfPendingUsers= Number(res.totalNumberOfPendingUsers) + Number(data.totalNumberOfPendingUsers);
                    totalNumberOfSuspendedUsers = Number(res.totalNumberOfSuspendedUsers)  + Number(data.totalNumberOfSuspendedUsers);
                } else if(hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.SEMA) {
                    totalUserGroupCount =   Number(res.totalUserGroups);
                    totalNumberOfUsers =  Number(res.totalNumberOfUsers);
                    totalNumberOfActiveUsers= Number(res.totalNumberOfActiveUsers) ;
                    totalNumberOfPendingUsers= Number(res.totalNumberOfPendingUsers) ;
                    totalNumberOfSuspendedUsers = Number(res.totalNumberOfSuspendedUsers);
                }
                getMemberInvitationDetails();
                setTotalUserGroupsCount(totalUserGroupCount);
                setTotalNumberOfActiveUsers(totalNumberOfActiveUsers);
                setTotalNumberOfPendingUsers(totalNumberOfPendingUsers);
                setTotalNumberOfSuspendedUsers(totalNumberOfSuspendedUsers);
                setTotalNumberOfUsers(totalNumberOfUsers);
                setMemberUserGroupList(res);
                setDepotSpinnerOff(false);                   
            }
        }).catch((err: any) => {
            setDepotSpinnerOff(false);   
            console.error("Error", err) 
        })  
    };

    const userInfo = (key: string, value: string) => {
        return (
            <>
                <div className="row">
                    <span className="label">{key}</span>
                    <span className="result">{value || BLANK_DATA_PLACEHOLDER}</span>
                </div>
            </>
        );
    };

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage);
    };

    const navigateToViewAllMembership = () => {
        history.push({
            pathname: '/manageUsers/privateAccessRequests',
            search: '?hostId='+hostId 
        });
    };

    return (
        <>
            {/*----Page Mid Section Start----*/}
            <main className={classes.content} data-testid="user_groups_component">
                {depotSpinnerOff ? (
                    <CircularProgress className="inner_progress" />
                ) : (
                        <div className={"mid_section_inside, shift_group_row"}>
                            <Grid container spacing={2}>
                                {/*----Page Mid Left Section Start----*/}
                                <Grid item xs={12} sm={12} md={7} lg={8} className="mid_section_left">
                                    <div className="depot_list">
                                        <List>
                                            {(hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK || hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA ) &&
                                                groupList?.userGroups?.slice(0, groupList.size).map((value: any) => (
                                                <NavLink key={value.id} to={`/manageUsers/manageUsersDetail?userGroup=${value.id}&view=info#0`} style={linkStyle}>     
                                                    <ListItem>
                                                        <Card className="list_card" variant="outlined">
                                                            <CardContent className="card_content">
                                                                <div className="depot_details vehicle_detail">
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <div className="depot_information">
                                                                                <div className="depot_location_map no-border">
                                                                                <img className="user-logo" src={value?.imageReference ?? Images.shift_driver_group} alt="shift_driver_group" />
                                                                                </div>
                                                                                <div className="depot_info">
                                                                                    <div className="depot_name">
                                                                                        {value?.groupName}
                                                                                    </div>
                                                                                    <div className="port_details">
                                                                                        <span className="alert">
                                                                                            <img src={Images.ic_driver_group} alt="{t('common.users')}"/>
                                                                                            {value.numberOfUsers === null ? 0 : value.numberOfUsers} {t("common.user", { count: value.numberOfUsers })}
                                                                                        </span>
                                                                                        <span className="port">
                                                                                            <img src={Images.ic_rfid_grey} alt="{t('common.blinkcard')}"/>
                                                                                            {value.numberOfRFIDCards === null ? 0 : value.numberOfRFIDCards} {t("common.blinkcard", { count: value.numberOfRFIDCards })}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <div className="stat_list mt-10">
                                                                        <div className="status driver_onduty">
                                                                            <span className="status_text">
                                                                                {t("common.active")}
                                                                            </span>
                                                                            <span className="status_number">
                                                                                {value.numberOfActiveUsers}
                                                                            </span>
                                                                        </div>
                                                                        <div className="status driver_not_available">
                                                                            <span className="status_text">
                                                                                {t("common.pending")}
                                                                            </span>
                                                                            <span className="status_number">
                                                                                {value.numberOfPendingUsers}
                                                                            </span>
                                                                        </div>
                                                                        <div className="status driver_suspended">
                                                                            <span className="status_text">
                                                                                {t("common.suspended")}
                                                                            </span>
                                                                            <span className="status_number">
                                                                                {value.numberOfSuspendedUsers}
                                                                            </span> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </ListItem>
                                                </NavLink>
                                            ))}
                                        </List>
                                        <List>
                                            {(hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.SEMA || hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA ) &&
                                                memberUserGroupList?.userGroups?.slice(0, memberUserGroupList.size).map((value: any) => (
                                                <ListItem key={value.id}>
                                                    <Card className="list_card" variant="outlined">
                                                        <CardContent
                                                            className="card_content"
                                                            onClick={() => navigateToUserDetails(value.id)}
                                                        >
                                                            <div className="depot_details vehicle_detail">
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <div className="depot_information">
                                                                            <div className="depot_location_map no-border">
                                                                                <img className="user-logo" src={value?.imageReference ?? Images.shift_driver_group} alt="shift_driver_group" />
                                                                            </div>
                                                                            <div className="depot_info">
                                                                                <div className="depot_name">
                                                                                    {value?.groupName}
                                                                                </div>
                                                                                <div className="port_details">
                                                                                    <span className="alert mb-0">
                                                                                        <img src={Images.ic_driver_group} alt="{t('common.users')}"/>
                                                                                        {value.numberOfUsers === null ? 0 : value.numberOfUsers} {t("common.user", { count: value.numberOfUsers })}
                                                                                    </span>
                                                                                    <p className="info_text">
                                                                                      { value?.groupName === defaultVariable.USER_GROUP.freeChargeSema ? t('membership.freeusergrouptext') : ''}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                <div className="stat_list mt-12">
                                                                    <div className="status driver_onduty">
                                                                        <span className="status_text">
                                                                            {t("common.active")}
                                                                        </span>
                                                                        <span className="status_number">
                                                                            {value.numberOfActiveUsers}
                                                                        </span>
                                                                    </div>
                                                                    <div className="status driver_not_available">
                                                                        <span className="status_text">
                                                                            {t("common.pending")}
                                                                        </span>
                                                                        <span className="status_number">
                                                                            {value.numberOfPendingUsers}
                                                                        </span>
                                                                    </div>
                                                                    <div className="status driver_suspended">
                                                                        <span className="status_text">
                                                                            {t("common.suspended")}
                                                                        </span>
                                                                        <span className="status_number">
                                                                            {value.numberOfSuspendedUsers}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                    <div className="table_scroll">
                                        <div className="table_footer">
                                            <Grid container direction="row" alignItems="center" spacing={0}>
                                                <Grid className="text_left" item xs={8}>
                                                    {totalUserGroupsCount > pageSize ?
                                                        <CommonPagination
                                                            currentPage={currentPage}
                                                            totalCount={totalUserGroupsCount}
                                                            callBackCurrentPage={handleCurrentPage}
                                                        /> : <></>}
                                                </Grid>
                                                <Grid className="text_right" item xs={4}>
                                                    <div className="list_number">{groupsPageData} {(totalUserGroupsCount + ' ' + t("common.groups"))} </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} md={5} className="mid_section_right user_groups_status vehicle_g_status">
                                    {readAccessForMembership && (hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.SEMA || hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA) &&
                                        <Card className="small_card" variant="outlined">
                                            <CardContent className="card_content">
                                                <div className="section_title">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <h3>{t("privateAccess.privateAccessInvitation")}</h3>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="section_content">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div className="invitation_code mt-13">
                                                                {userInfo(t("privateAccess.invitationcode"), invitationCode)}
                                                                {userInfo(t("privateAccess.privateAccessInvitation"), (memberShipSubscription ? t("common.open") : ''))}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    }

                                    <Card className="small_card" variant="outlined">
                                        <CardContent className="card_content">
                                            <div className="section_title">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <h3>{t("common.groups")}</h3>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="section_content">
                                                <div className="depot_bar">
                                                    {progressList?.map((value: any, index: number) =>
                                                        <span key={value.name} className={value.name} style={{ width: `calc(${value.percentage}%)` }}></span>
                                                    )}
                                                </div>
                                                <div className="depot_state">
                                                    <Grid container justifyContent="space-between" alignItems="flex-start">
                                                        <Grid className="state">
                                                            <div className="state_text">
                                                                {toUpper(t("common.totalusers"))}
                                                            </div>
                                                            <div className="state_number">
                                                                {totalNumberOfUsers}
                                                            </div>
                                                        </Grid>
                                                        <Grid className="state">
                                                            <span className="active">
                                                            </span>
                                                            <div className="state_text">
                                                                {toUpper(t("common.active"))}
                                                            </div>
                                                            <div className="state_number">
                                                                {totalNumberOfActiveUsers}
                                                            </div>
                                                        </Grid>

                                                        <Grid className="state">
                                                            <span className="pending">
                                                            </span>
                                                            <div className="state_text">
                                                                {toUpper(t("common.pending"))}
                                                            </div>
                                                            <div className="state_number">
                                                                {totalNumberOfPendingUsers}
                                                            </div>
                                                        </Grid>
                                                        <Grid className="state">
                                                            <span className="suspended">
                                                            </span>
                                                            <div className="state_text">
                                                                {t("common.suspended")}
                                                            </div>
                                                            <div className="state_number">
                                                                {totalNumberOfSuspendedUsers}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>

                                    {readAccessForMembership && (hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.SEMA || hostUserGroupDisplayFor === HOST_USER_GROUP_ENUM.BLINK_SEMA) &&
                                        <Card className="small_card" variant="outlined">
                                            <CardContent className="card_content">
                                                <div className="section_title">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <h3>{t("membership.requests")}</h3>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="section_content">
                                                    <div className="depot_state">
                                                        <Grid container justifyContent="space-between" alignItems="flex-start">
                                                            <Grid className="state">
                                                                <div className="state_text">
                                                                    {toUpper(t("membership.totalrequests"))}
                                                                </div>
                                                                <div className="state_number">
                                                                    {membershipRequestCount.total}
                                                                </div>
                                                            </Grid>
                                                            <Grid/>
                                                            <Grid/>
                                                            <Grid className="state">
                                                                <span className="pending"/>
                                                                <div className="state_text">
                                                                    {toUpper(t("common.pending"))}
                                                                </div>
                                                                <div className="state_number">
                                                                    {membershipRequestCount.pending}
                                                                </div>
                                                            </Grid>
                                                            <Grid className="state">
                                                                <span className="suspended"/>
                                                                <div className="state_text">
                                                                    {t("membership.declined")}
                                                                </div>
                                                                <div className="state_number">
                                                                    {membershipRequestCount.rejected}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                                <Button className={!disabled ? "btn_white light_gry ml_0" : "btn_white disable ml_0"}
                                                    onClick={navigateToViewAllMembership}
                                                    disabled = {disabled}>
                                                    {t("membership.viewall")}
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    )}
            </main>
            {/*----Page Mid Section End----*/}
        </>
    );
};
export default UserGroups;
