import { getMethod, ApiEndPoint, postMethod } from "services";

export const fetchLcdDisplayDetails = async (params: any, mock = false) => {
    let queryParams = `?chargerId=${params.stationId}`;
    const data = await getMethod(
        ApiEndPoint.lcdDisplayDetails,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const manageLcdDisplayDetails = async (body: any ) => {
    let queryParams = ``;
    const data = await postMethod(ApiEndPoint.lcdDisplayDetails, queryParams, body ,false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};