import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, DialogContent, DialogContentText, Grid, Button, ListSubheader } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toUpper } from "lodash";
import { CheckAccess, claims, depotGoogleMap } from "common";
import CommonGoogleMap from "components/commonGoogleMap";

const ViewAssignedStations = (props: any) => {
  const { name, notes, stationIdsArr } = props?.zoneStationsDetails || {};
  const { address } = props?.locationInformation || {};
  const { t } = useTranslation();
  const editFormType = 'Edit';
  const selectedStation = stationIdsArr.filter((value: any) => value.zoneChecked === true && value.zoneDisabled === false)
  const sumPorts = selectedStation.reduce((accumulator: any, object: any) => {
    return accumulator + object.portCount;
  }, 0);

  const viewFormType = (viewFormVal: string) => {
    return viewFormVal === editFormType
      ? t("common.updatezone")
      : t("common.createnewzone");
  };

  const callbackPolygon = () => {
    // props?.polygonMapMethod(stationIdsArr); // go back to AssignedStations
    props?.assignedStationMethod(props?.zoneStationsDetails);
  };

  const callFinalStation = (formVal: string) => {
    if (formVal === editFormType) {
      props?.viewFinalStation(props.pathCoord)
    } else {
      props?.addZoneData(props.pathCoord)
    }
  };

  const viewButtonType = (viewFormVal: string) => {
    return viewFormVal === editFormType
      ? t("common.nextbutton")
      : t("common.savebutton");
  };
  return (
  <>
    <div data-testid="view_assigned_station">
      <div className="dialog_title">
        <h4>{viewFormType(props?.formType)}</h4>
        <div className="button_row mt-0 full_right">
          <Button
            className="btn_white text_btn_theme"
            onClick={props?.zoneClose}
          >
            {t("common.cancelbutton")}
          </Button>
          <Button className="btn_white outline_btn" onClick={callbackPolygon}>
            {t("common.backbutton")}
          </Button>
          <Button className="btn_white blue_bg" onClick={() => callFinalStation(props?.formType)}>
            {viewButtonType(props?.formType)}
          </Button>
        </div>
      </div>
      <DialogContent dividers="paper" {...props}>
        <DialogContentText id="scroll-dialog-description">
          <div className="information_section delegates_details">
            <div className="title_row">
              <h2>{name}</h2>
              <div className="clearfix"></div>
            </div>
            <div className="info_detail">
              <div className="zone_section  mt-13">
                <Grid container>
                
                  { CheckAccess([claims.FORBIDDEN_ACCESS]) &&
                    <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                      <div className="zone_detail">
                        <div className="zone_map_height zone_map">
                          <CommonGoogleMap
                            center={{
                              lat: address?.latitude,
                              lon: address?.longitude,
                            }}
                            zoom={
                              depotGoogleMap.zoom
                            }
                            markers={[
                              {
                                lat: address?.latitude,
                                lon: address?.longitude
                              },
                            ]}
                            pathCoord={props.pathCoord}
                            polygon={true}
                            mapsize={true}
                          />
                        </div>
                        {/* <div className="tb_zone_section">
                          <div className="label">
                            {toUpper(t("common.notes"))}
                          </div>
                          <div className="result">
                            {notes}
                          </div>
                        </div> */}
                      </div>
                    </Grid>
                  }

                  <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                    <div className="zone_floor_info">
                      <List component="nav" className="z_floor_list">
                        <ListSubheader className="list_head">
                          <Grid container className="list_sub_head">
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                              {selectedStation?.length} {t("common.station", {count: selectedStation?.length})}{" "}
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                              {sumPorts}  {t("common.port", {count: sumPorts})}
                            </Grid>
                          </Grid>
                        </ListSubheader>
                        {selectedStation && selectedStation.length > 0 && selectedStation.map((value: any, index: number) => (<ListItem key={value?.id}>
                          <ListItemIcon className="zone_icon">
                            <img src={value?.modelImageReference} alt="edit" />
                          </ListItemIcon>
                          <ListItemText>
                            {value?.name}
                          </ListItemText>
                        </ListItem>))}
                      </List>
                    </div>
                    <div className="tb_zone_section">
                      <div className="label">
                        {toUpper(t("common.notes"))}
                      </div>
                      <div className="result">
                        {notes}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </div>
  </>)
};

export default ViewAssignedStations;
