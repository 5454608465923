import React, { useEffect, useState } from "react";
import {
    DialogContentText,
    DialogContent,
    Dialog,
    IconButton,
    List,
    ListItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toLower } from "lodash";
import { BLANK_DATA_PLACEHOLDER, CheckAccess, claims } from "common";
import { getMethod, ApiEndPoint } from "services";

const RequestBlinkCardProgress = (props: any) => {
    const { t } = useTranslation();
    const DEFAULT_STATUS = t('common.sumitted')
    const [countryName, setCountryName] = useState<any>('');
    const [stateName, setStateName] = useState<any>('');


    const getCountryList = (code: any) => {
        getMethod(ApiEndPoint.countryList, '').then((res) => {
            if (res?.data) {
                res?.data?.countries?.forEach((element: any) => {
                    if(element?.code === code) {
                        setCountryName(element?.name)
                    }
                });
            }
        }).catch((err) => {
            console.error("Error", err)
        })
    }
    
    const getStateList = (code: any) => {
        getMethod(ApiEndPoint.stateList, '?countryCode=' + props?.blinkCardInfo?.row?.requestAddress?.country).then((res) => {
            if (res?.data) {
                res?.data?.states?.forEach((element: any) => {
                    if(element?.code === code) {
                        setStateName(element?.name);
                    }
                });
            }
        }).catch((err) => {
            console.error("Error", err)
        })
    }

    useEffect(() => {
        getCountryList(props?.blinkCardInfo?.row?.requestAddress?.country);
    }, [props?.openProgressBlinkCard, props?.blinkCardInfo?.row?.requestAddress?.country]);

    useEffect(() => {
        getStateList(props?.blinkCardInfo?.row?.requestAddress?.state);
    }, [props?.openProgressBlinkCard, props?.blinkCardInfo?.row?.requestAddress?.country, props?.blinkCardInfo?.row?.requestAddress?.state]);


    return (
        <>
            <div className="dialog_box">
                <Dialog className="dialog_container edit_detail_section edit-user-info updated_pop_up sm dialog_container_center"
                    {...props}
                    open={props?.openProgressBlinkCard}
                    onClose={props?.progressBlinkCardClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                    <form>
                        <div className="dialog_title">
                            <h4>{t("blinkcard.blinkcardrequest")}</h4>
                            <IconButton
                                className="closed_btn mt-10 full_right"
                                onClick={props?.progressBlinkCardClose}
                            >
                                <span className="material-icons">
                                    {toLower(t("common.cancelbutton"))}
                                </span>
                            </IconButton>
                        </div>
                        <DialogContent>
                            <DialogContentText id="scroll-dialog-description">
                                <div className="defult_form mt-20">
                                    <div className="section_group">
                                        <List component="nav" className="card_listing_detail">
                                            {CheckAccess([claims.FORBIDDEN_ACCESS]) && <ListItem><span>{t("common.requestid")}</span>{props?.blinkCardInfo?.row?.id || BLANK_DATA_PLACEHOLDER}</ListItem>} 
                                            <ListItem><span>{t("common.quantity")}</span>{props?.blinkCardInfo?.row?.quantity || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.status")}</span><b>{props?.blinkCardInfo?.row?.status || DEFAULT_STATUS}</b></ListItem>
                                            <ListItem><span>{t("common.date")}</span>{props?.blinkCardInfo?.row?.date || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.addressline1")}</span>{props?.blinkCardInfo?.row?.requestAddress?.addressLine1 || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.addressline2")}</span>{props?.blinkCardInfo?.row?.requestAddress?.addressLine2 || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.city")}</span>{props?.blinkCardInfo?.row?.requestAddress?.city || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.state")}</span>{stateName || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.zipcode")}</span>{props?.blinkCardInfo?.row?.requestAddress?.postalCode || BLANK_DATA_PLACEHOLDER}</ListItem>
                                            <ListItem><span>{t("common.country")}</span>{countryName || BLANK_DATA_PLACEHOLDER}</ListItem>
                                        </List>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>
        </>
    );
};
export default RequestBlinkCardProgress;