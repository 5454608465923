import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField, LinearProgress,
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import { activateCard } from "services/fleetManagementService";
import { setClassName } from "pages/evseLocations/evseDepotDetail/semaConnectChargeFee/pricingPlanHelpers";
import { MIN_AUTHCARD_CHAR, GROUP_ID} from "pages/fleet/fleetManagementHelper";

const AddVehicleAuthCard = (props: any) => {
    let initialValue = { vehicleId: props.vehicleId, pass: "", groupId: props.groupId };
    const [authCardDetails, setAuthCardDetails] = useState(initialValue);
    const { t } = useTranslation();
    const simpleValidator: any = useRef(new SimpleReactValidator());
    const [loading, setLoader] = useState(false);
    const [, forceUpdate] = useState<any>();
    const [disableAssign, setDisableAssign] = useState(true);
    const dispatch = useDispatch();

    const handleChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        setAuthCardDetails({ ...authCardDetails, [name]: value });
        if(value?.length >= MIN_AUTHCARD_CHAR) {
            setDisableAssign(false);
        }
    };

    const resetAuthCardFormData = () => {
        setAuthCardDetails(initialValue);
        simpleValidator.current.hideMessages();
        forceUpdate(0);
    };

    const closeAddAuthCardModal = () => {
        props.addAuthCardClose();
        resetAuthCardFormData();
        setDisableAssign(true);
    };

    const validateError = (fieldName: any) => {
        if (
            simpleValidator.current.fields[fieldName] === false &&
            simpleValidator.current.messagesShown === true
        ) {
            return "error-show";
        } else {
            return "";
        }
    };

    const submitNewAuthCard = () => {
        if (simpleValidator.current.allValid()) {
            setLoader(true);
            let body = {
                "vehicle_id":authCardDetails.vehicleId,
                "card_no": authCardDetails.pass,
                "fleet_group_id":authCardDetails.groupId
            };
            activateCard(body).then((res: any) => {
                if (res.status) {
                    props.addAuthCardClose();
                    resetAuthCardFormData();
                    props.dataRefresh();
                    forceUpdate(1);
                    setLoader(false);
                } else {
                    dispatch(
                        showErrorMessages({
                            error: true,
                            message: res.message,
                            type: "error",
                            operation: "",
                        })
                    );
                    setLoader(false);
                }
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    useEffect(() => {
        setAuthCardDetails({ ...authCardDetails, [GROUP_ID]: props?.groupId });
    }, [props?.groupId]);

    return (
        <>
            {/* Dialog for add new auth card */}
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info sm dialog_container_center"
                    open={props.openAddAuthCard}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("fleet.vehicle.addauthcard")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button className="btn_white text_btn_theme" onClick={closeAddAuthCardModal}>
                                {t("common.cancelbutton")}
                            </Button>
                            <Button
                                id= "authAssignBtn"
                                className={`btn_white ${setClassName(loading,disableAssign)}`}
                                onClick={submitNewAuthCard}
                                disabled = {disableAssign}
                            >
                                {t("hostsetting.assign")}
                            </Button>
                        </div>
                    </div>
                    {loading && <LinearProgress/>}
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form mt-25">
                                <div className="section_group">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={12}>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={4}>
                                                        <label>
                                                            {t("fleet.cardnumber")}{" "}{t("common.required")}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <div
                                                            className={`form_col-6 ${validateError("pass")}`}
                                                        >
                                                            <TextField
                                                                className="w_100per full_w"
                                                                variant="outlined"
                                                                name="pass"
                                                                id="pass_number"
                                                                value={authCardDetails?.pass}
                                                                placeholder={t("fleet.card.desc")}
                                                                onChange={handleChange}
                                                            />
                                                            <cite>
                                                                {simpleValidator.current.message("pass",authCardDetails?.pass,"required")}
                                                            </cite>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>

            {/* Dialog Add End */}
        </>
    );
};
export default AddVehicleAuthCard;
