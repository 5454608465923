import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { pageDetails, HandlePaginationLabel, rolePermissionCheck,  defaultVariable, CheckAccess, claims, keyCloakConfig } from '../../../common';
import { DataGrid } from '@material-ui/data-grid';
import CommonDownloadCsv from 'components/commonDownloadCsv';
import CommonPagination from 'components/commonPagination'
import { useTranslation } from "react-i18next";
import { CircularProgress, Button } from '@material-ui/core';
import moment from 'moment';
import {FleetGroupsColumns} from "pages/tableColumns/fleetGroupColumns";
import { fetchFleetList } from 'services/fleetManagementService';

import { columnNameTranslate, getFormattedDateTimeWithTZ } from 'common/methodHelpers';
import { FLEET_GROUPS, FileHeaderFleetComponent, FLEET_GROUP_INFO_PATH, FILE_NAME_FLEET_GROUPS } from '../fleetManagementHelper';
import { useHistory } from 'react-router-dom';
import { ObjectDetails } from "common/interfaces";
import AddFleetGroup from './addFleetGroup';

const FleetGroups = (props: any) => {
    const { t } = useTranslation();
    const pageSize = pageDetails.pageSize;
    const [fleetList, setFleetList] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [downloadCsv, setDownloadCsv] = useState([]);
    const [loadingPage, setLoadingPage]  = useState(false);
    
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const delegateId =  sessionStorage.getItem('delegateId');
    const fileName: string = FILE_NAME_FLEET_GROUPS+`${moment().format('MMDDYYYYHHmmss')}.csv`;
    const fileHeader = FileHeaderFleetComponent(FLEET_GROUPS);
    const history = useHistory();
    const [addGroupModalOne, setAddGroupModalOne] = useState(false);
    const showGroupAddButton = isDelegate ? false : CheckAccess([claims.FLEET_CREATE]);

    let initialValue = {
        locationId: "",
        hostId: hostId,
        fleetGroup: "",
        zoneId:"",
    };

    const dataRefresh = () => {
        getFleetList();
        props?.getGroupDetails();
    };

    const addGroupModalOneOpen = () => {
        setAddGroupModalOne(true);
    };

    const addGroupModalOneClose = () => {
        setAddGroupModalOne(false);
    };

    const getFleetList = () => {
        let params = isDelegate ?  { currentPage: currentPage, size: pageDetails.pageSize , delegateId:delegateId, csvDownload:false, isDelegate:true } : 
        { currentPage: currentPage, size: pageDetails.pageSize , hostId:hostId, csvDownload:false, };
        setLoadingPage(true);
        fetchFleetList(params, false)
            .then((res: any) => {
                setFleetList(res);
                setLoadingPage(false);
            })
            .catch((err: any) => {
                setLoadingPage(false);
                console.error("Error", err);
            });
    };

    const getFleetListDownload = async() => {
        let params = isDelegate ? {delegateId:delegateId, csvDownload:true, isDelegate:true} :
         {hostId:hostId , csvDownload:true};
        await fetchFleetList(params, false)
            .then((res: any) => {
                let downloadCsvData = [];
                if (res?.fleetGroups.length > 0) {
                    downloadCsvData = res?.fleetGroups.map(
                       (val: any, index: any) => ({
                           ...val,
                           created_on: getFormattedDateTimeWithTZ(val?.created_on),
                           modified_on: getFormattedDateTimeWithTZ(val?.modified_on),
                       })
                   );
               }
               setDownloadCsv(downloadCsvData);
            })
            .catch((err: any) => {
                setLoadingPage(false);
                console.error("Error", err);
            });
    };


    const fleetListDataSet = (value: any) => {
        let fleetTableData = []
        if (value && value?.fleetGroups) {
            fleetTableData = value.fleetGroups.map((val: any, index:number) => ({ ...val, id: index }))
        }
        return fleetTableData
    }

    const handleClickOpenRow = (event: ObjectDetails) =>{
        let searchParams = "?groupId=" + event?.groupId;
        history.push({
          pathname:  FLEET_GROUP_INFO_PATH,
          search: searchParams,
          hash: "#" + 0 
       });   
      }

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)    
    }

    useEffect(() => {
        getFleetList()
    }, [currentPage])


    return (
        <>
            {loadingPage ? <CircularProgress className="inner_progress_userGroup"/> : 
            <div className="host_settings" >
                {showGroupAddButton &&
                    <div className="button_row text_right" >
                        <Button className="btn_white blue_bg" onClick={addGroupModalOneOpen}>
                            {t("fleet.addnewFleet")}
                        </Button>
                    </div>
                 }
            <div className="table_scroll mt-10" data-testid="evse_depot_charge_history">
                <div className="default_table">
                    <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={fleetListDataSet(fleetList)}
                        columns={columnNameTranslate(FleetGroupsColumns)}
                        pageSize={pageSize}
                        onRowClick={(event) => handleClickOpenRow(event?.row)}
                        disableSelectionOnClick />
                </div>
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                            {fleetList && fleetList?.totalCount > pageSize ?
                               <CommonPagination 
                               currentPage = {currentPage}
                               totalCount = {fleetList?.totalCount}
                               callBackCurrentPage = {handleCurrentPage}
                            /> : <></>}
                        {fleetList && fleetList?.totalCount > 0 &&
                            <CommonDownloadCsv {...props}
                            datas={downloadCsv}
                            filename={fileName}
                            headers={fileHeader}
                            blueclass={"border"}
                            callBackDownloadCsv={getFleetListDownload}/>
                        }
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">
                                {HandlePaginationLabel(fleetList?.totalCount, pageSize, currentPage, fleetList?.totalCount > 1 ? "fleet.fleets" : "fleet")}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            </div>
            }
          

            <AddFleetGroup
                addGroupModalOne={addGroupModalOne}
                addGroupModalOneOpen={addGroupModalOneOpen}
                addGroupModalOneClose={addGroupModalOneClose}
                hostId={hostId}
                edit={false}
                groupInfo={initialValue}
                groupId={""}
                groupInfoRefresh={dataRefresh}
                locationName={""}
                hostUserId={""}
                zoneName={""}
            />
        </>
    );
}
export default FleetGroups;