  
  
  export const rowsChargehistory = [
    { id: 1, e: 'a', start: 'Jan 3, 2022; 7:13AM', end: 'Jan 3, 2022; 11:46AM', vehicleId: 'VEH1658', distance: '145',  odometer: '23,958', energy: '47.1570', ee: 'a' },
    { id: 2, e: 'a', start: 'Jan 2, 2022; 7:26AM', end: 'Jan 2, 2022; 12:15PM', vehicleId: 'VEH2275', distance: '100',  odometer: '24,158', energy: '35.0917', ee: 'a' },
    { id: 3, e: 'a', start: 'Dec 28, 2021; 7:08AM', end: 'Dec 28, 2021; 11:34AM', vehicleId: 'VEH2456', distance: '151',  odometer: '41,158', energy: '52.1745', ee: 'a' },
    { id: 4, e: 'a', start: 'Dec 22, 2021; 6:56AM', end: 'Dec 22, 2021; 1:34PM', vehicleId: 'VEH2275', distance: '137',  odometer: '24,058', energy: '41.7165', ee: 'a' },
    { id: 5, e: 'a', start: 'Dec 9, 2021; 7:08AM', end: 'Dec 9, 2021; 11:13AM', vehicleId: 'VEH2283', distance: '145',  odometer: '23,958', energy: '47.1570', ee: 'a' },
    { id: 6, e: 'a', start: 'Nov 25, 2021; 7:11AM', end: 'Nov 25, 2021; 10:41AM', vehicleId: 'VEH2275', distance: '79',  odometer: '24,021', energy: '23.1746', ee: 'a' },
    { id: 7, e: 'a', start: 'Nov 16, 2021; 7:03AM', end: 'Nov 16, 2021; 11:14AM', vehicleId: 'VEH2275', distance: '145',  odometer: '23,958', energy: '47.1570', ee: 'a' },
    { id: 8, e: 'a', start: 'Nov 13, 2021; 7:13AM', end: 'Nov 13, 2021; 11:46AM', vehicleId: 'VEH1263', distance: '71',  odometer: '6,901', energy: '21.5815', ee: 'a' },
    { id: 9, e: 'a', start: 'Oct 20, 2021; 6:59AM', end: 'Oct 20, 2021; 12:31PM', vehicleId: 'VEH2275', distance: '86',  odometer: '23,958', energy: '39.4812', ee: 'a' },
    { id: 10, e: 'a', start: 'Oct 15, 2021; 7:12AM', end: 'Oct 15, 2021; 2:21PM', vehicleId: 'VEH2275', distance: '78',  odometer: '24,058', energy: '47.1570', ee: 'a' },
    { id: 11, e: 'a', start: 'Oct 5, 2021; 7:05AM', end: 'Oct 5, 2021; 11:21AM', vehicleId: 'VEH1658', distance: '98',  odometer: '23,958', energy: '52.1745', ee: 'a' },
    { id: 12, e: 'a', start: 'Oct 2, 2021; 7:12AM', end: 'Oct 2, 2021; 11:46AM', vehicleId: 'VEH2456', distance: '104',  odometer: '41,158', energy: '47.1570', ee: 'a' },
    
 ];

 export const rows = [
    { id: 1, e: 'a', vehicleId: 'VEH1658', port: 'A Port - 1', start: 'Jan 2, 2022; 7:13AM', end: 'Jan 2, 2022; 11:46AM', time: '4:34', energy: '47.1570', ee: 'a' },
    { id: 2, e: 'a', vehicleId: 'VEH2456', port: 'C Port - 2', start: 'Jan 1, 2022; 12:15PM', end: 'Jan 1, 2022; 5:51PM', time: '5:51', energy: '52.1745', ee: 'a' },
    { id: 3, e: 'a', vehicleId: 'VEH2275', port: 'D Port - 1', start: 'Dec 28, 2021; 12:15PM', end: 'Dec 28, 2021; 2:47PM', time: '3:27', energy: '35.0917', ee: 'a' },
    { id: 4, e: 'a', vehicleId: 'VEH2275', port: 'C Port - 1', start: 'Dec 18, 2021; 1:26PM', end: 'Dec 18, 2021; 4:31PM', time: '3:45', energy: '38.0917', ee: 'a' },
    { id: 5, e: 'a', vehicleId: 'VEH1658', port: 'D Port - 1', start: 'Dec 11, 2021; 11:59AM', end: 'Dec 11, 2021; 3:19AM', time: '3:20', energy: '39.4812', ee: 'a' },
    { id: 6, e: 'a', vehicleId: 'VEH2275', port: 'D Port - 1', start: 'Dec 8, 2021; 10:18AM', end: 'Dec 8, 2021; 2:52PM', time: '5:14', energy: '52.1745', ee: 'a' },
    { id: 7, e: 'a', vehicleId: 'VEH1658', port: 'F Port - 2', start: 'Nov 30, 2021; 1:12PM', end: 'Nov 30, 2021; 4:27PM', time: '2:57', energy: '31.2381', ee: 'a' },
    { id: 8, e: 'a', vehicleId: 'VEH2456', port: 'C Port - 1', start: 'Nov 26, 2021; 12:16PM', end: 'Nov 26, 2021; 3:25PM', time: '3:53', energy: '35.0917', ee: 'a' },
    { id: 9, e: 'a', vehicleId: 'VEH2275', port: 'D Port - 2', start: 'Nov 22, 2021; 3:12PM', end: 'Nov 22, 2021; 11:46AM', time: '4:34', energy: '42.1570', ee: 'a' },
    { id: 10, e: 'a', vehicleId: 'VEH1658', port: 'F Port - 1', start: 'Nov 18, 2021; 7:13AM', end: 'Nov 18, 2021; 5:26PM', time: '3:20', energy: '27.1470', ee: 'a' },
    { id: 12, e: 'a', vehicleId: 'VEH2456', port: 'D Port - 2', start: 'Nov 14, 2021; 7:13AM', end: 'Nov 14, 2021; 11:46AM', time: '4:34', energy: '77.1570', ee: 'a' },
    { id: 13, e: 'a', vehicleId: 'VEH2275', port: 'F Port - 2', start: 'Nov 7, 2021; 7:13AM', end: 'Nov 7, 2021; 11:46AM', time: '3:20', energy: '17.1570', ee: 'a' },
 ];
 

export const alertrow = [
  { id: 1, e: 'a', time: 'Jan 3, 2022; 4:24PM', vehicleId: 'VEH1455', alert: 'Unavailable for schedule shift', ee: 'a' },
  { id: 2, e: 'a', time: 'Jan 1, 2022; 2:51 PM', vehicleId: 'VEH1174', alert: 'Submit a citation', ee: 'a' },
  { id: 3, e: 'a', time: 'Dec 31, 2021; 8:45 AM', vehicleId: 'VEH2215', alert: 'EV battery is below 10% battery', ee: 'a' },
  { id: 4, e: 'a', time: 'Dec 30, 2021; 7:37 PM', vehicleId: 'VEH3455', alert: 'Submit a citation', ee: 'a' },
  { id: 5, e: 'a', time: 'Dec 29, 2021; 5:14 PM', vehicleId: 'VEH4567', alert: 'EV battery is below 20% battery', ee: 'a' },
  { id: 6, e: 'a', time: 'Dec 29, 2021; 10:42 AM', vehicleId: 'VEH5191', alert: 'License will expire in 30 days', ee: 'a' },
  { id: 7, e: 'a', time: 'Dec 27, 2021; 8:57 AM', vehicleId: 'VEH6091', alert: 'Unavailable for schedule shift', ee: 'a' },
  { id: 8, e: 'a', time: 'Dec 20, 2021; 8:07 AM', vehicleId: 'VEH7511', alert: 'Upload new profile photo', ee: 'a' },
  { id: 9, e: 'a', time: 'Dec 16, 2021; 7:12 AM', vehicleId: 'VEH3455', alert: 'EV battery is below 20% battery', ee: 'a' },
  { id: 10, e: 'a', time: 'Dec 14, 2021; 7:38 PM', vehicleId: 'VEH5191', alert: 'Update shift schedule', ee: 'a' },
  { id: 11, e: 'a', time: 'Dec 12, 2021; 3:19 PM', vehicleId: 'VEH1174', alert: 'Submit accident report', ee: 'a' },
  { id: 12, e: 'a', time: 'Dec 6, 2021; 7:16 AM', vehicleId: 'VEH2215', alert: 'Unavailable for schedule shift', ee: 'a' }, 
   
];
