import React from "react";
import {Dialog, Button, DialogContent, DialogContentText, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {toLower, startCase} from "lodash";
import "./welcomeMessage.scss";
import { keyCloakConfig } from "common";

const WelcomeMessage = (props: any) => {
  const {t} = useTranslation();

  const welcomeMessageClose = () => {
    props?.setWelcomeMessage(false);
  };

  const goToTraining = () => {
    props?.setWelcomeMessage(false);
    window.location.replace("/training");
  };

  return (
    <>
      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_detail_section terms_conditions dialog_container_center"
          open={props?.openWelcomeMessage}
          onClose={welcomeMessageClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title user-name">
            <h2>
              {t("welcomemessage.hi")} {startCase(toLower(keyCloakConfig?.tokenParsed?.name))},<br />
              {t("welcomemessage.welcomemessage")}
            </h2>
          </div>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description">
              <div className="welcome-message">
                <Grid container direction="row" spacing={0}>
                  <Grid item xs={6}>
                    <h2>{t("welcomemessage.youwillbeableto")}</h2>
                    <ul>
                      <li>
                        <span className="span-text">{t("welcomemessage.locationinformation")}</span>
                      </li>
                      <li>
                        <span className="span-text">{t("welcomemessage.createandmanage")}</span>
                      </li>
                      <li>
                        <span className="span-text">{t("welcomemessage.memberaccess")}</span>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={6} className="border-left">
                    <h2 className="h2-left">{t("welcomemessage.learnabout")}</h2>
                    <div className="about-host-portal">{t("welcomemessage.trainingcontent")}</div>
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={0}>
                  <Grid item xs={6}>
                    <div className="welcome-btn">
                      <Button className="btn" onClick={welcomeMessageClose} data-testid="getStarted">
                        {t("welcomemessage.getstarted")}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="welcome-btn">
                      <Button className="btn" onClick={goToTraining} data-testid="goToTraining">
                        {t("welcomemessage.gototraining")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default WelcomeMessage;
