import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from "react-i18next";
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
import {findMaxArrayObjectValue, ZERO_VALUE} from 'common';


const DepotPowerCard = (props:any) => {
    const { t } = useTranslation();
    let AREA_KEY_VALUE = 'actualLoad';
    let LINE_KEY_VALUE = 'peakLoad';
    let yAxisMaxValue = Math.max(findMaxArrayObjectValue(props?.depotPower, AREA_KEY_VALUE), findMaxArrayObjectValue(props?.depotPower, LINE_KEY_VALUE), ZERO_VALUE);
    return (
        <>
            <div className="section_title mt-20">
                <Grid container>
                    <Grid item xs={12}>
                        <h3>{t("evselocations.powerconsumption")}</h3>
                    </Grid>
                </Grid>
            </div>
            <Card className="small_card mt-10" variant="outlined">
                <CardContent className="card_content">
                    
                    {props.depotPower && props.depotPower.length > 0 &&
                    <div className="section_content">
                        <ResponsiveContainer  width={'99%'} height={100}>
                            <ComposedChart
                                data={props.depotPower}
                                margin={{
                                    top: 3,
                                    right: 3,
                                    bottom: 3,
                                    left: 2,
                                }}
                            >
                                <CartesianGrid vertical={false} fill="#EEEEEE" stroke="#FFFFFF" strokeOpacity={1} fillOpacity={0.5} />
                                <XAxis hide />
                                <YAxis tickMargin={-3} dy={4} width={42} unit=' kW' tickCount={4} interval={0} axisLine={false} tickLine={false} style={{ fontSize: '8px', color: '#4D4D4F' }} />
                                <Area dataKey={AREA_KEY_VALUE} fill="#DDDDDD" stroke="#666666" strokeOpacity={0.5} fillOpacity={0.5} />
                                <Line dataKey={LINE_KEY_VALUE} stroke="#25556E" dot={false} strokeDasharray="2 1" />
                                <ReferenceLine x={0} stroke="#666666" strokeOpacity={0.5} alwaysShow={true} />
                                <ReferenceLine x={props && props.depotPower? props.depotPower.length-1 : 0} stroke="#666666" strokeOpacity={0.5} alwaysShow={true} />
                                <ReferenceLine y={yAxisMaxValue} stroke="none" strokeOpacity={0} alwaysShow={true}/>
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    }
                </CardContent>
            </Card>
        </>
    )
}

export default DepotPowerCard;
