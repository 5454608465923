import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import ContactForm from "components/contactForm";
import React, { useState, useRef, useEffect } from "react";
import { t } from "i18next";
import SimpleReactValidator from "simple-react-validator";

const EditLocationContact = (props: any) => {

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState<any>();
  const [updateButton, setUpdateButton] = useState<any>("popup_buttondisable");

  useEffect(() => {
    props.makeContactData();
  }, []);


  const showErrors = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  const validateError = (fieldName: string) => {
    if (
      simpleValidator.current.fields[fieldName] === false &&
      simpleValidator.current.messagesShown === true
    ) {
      return "error";
    } else {
      return "";
    }
  };

  const getDisabledState = () => {
        if(!props?.fleetContact?.firstName || !props?.fleetContact?.lastName || !props?.fleetContact?.mobile || !props?.fleetContact?.phone || !props?.fleetContact?.email) {
            setUpdateButton("popup_buttondisable");
         }
  }

  useEffect(()=> {
          if(simpleValidator.current.errorMessages.mobile||simpleValidator.current.errorMessages.phone||simpleValidator.current.errorMessages.email){
            setUpdateButton("popup_buttondisable");
          }
          else if(props?.fleetContact?.firstName !== props.contactInfo?.firstName || props?.fleetContact?.lastName !== props.contactInfo?.lastName||props?.fleetContact?.mobile !== props.contactInfo?.mobile|| props?.fleetContact?.phone!==props.contactInfo?.phone||props?.fleetContact?.email!==props.contactInfo?.email){
              setUpdateButton("blue_bg");
          } else {
              setUpdateButton("popup_buttondisable");
          }
         
         getDisabledState();
      },[props?.fleetContact])

  return (
    <>
      <div className="dialog_title">
        <h4>{t("common.locationcontact")}</h4>
        <div className="button_row mt-0 full_right">
          <Button
            className="btn_white text_btn_theme"
            onClick={props?.locationContactClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={props?.submitEditForm} className={`btn_white ${updateButton}`} disabled={ updateButton === "popup_buttondisable" ? true : false}
          >
            {t("common.station.update")}
          </Button>
        </div>
      </div>

      <DialogContent {...props}>
        <DialogContentText id="scroll-dialog-description">
          <div className="defult_form">
            <ContactForm
              contactDetails={props?.fleetContact}
              setContact={props?.setFleetContact}
              validator={simpleValidator}
              title=""
              showErrors={showErrors}
              validateError={validateError}
            />
          </div>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default EditLocationContact;
