import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import Images from "assets/images";
import CommonDownloadCsv from "components/commonDownloadCsv";
import { ApiEndPoint, getCannedReportMethod } from "services";

import { dateFormats, keyCloakConfig, urlReplace } from "common";
import moment from "moment";
import { initData } from "data/gaugeData";

const EnvironmentInfo = (props: any) => {
    const { t } = useTranslation();
    const [detailStatement, setDetailStatement] = useState<any>([]);
    const [gaugeInfo, setGaugeInfo] = useState<any>({});
    
    const hostId = keyCloakConfig?.tokenParsed?.hostId;

    const csvEnvironmentData: any = {
        co2Data: {
            filename: 'CO2ReducedData.csv',
            headers: [
                { label: t("common.date"), key: "date_time" },
                { label: t("dashboard.cosavedheader"), key: "reduction_in_co2" },
            ]
        },
        barrelsData: {
            filename: 'BarrelsSavedData.csv',
            headers: [
                { label: t("common.date"), key: "date_time" },
                { label: t("dashboard.barrelssavedheader"), key: "oil_saved" },
            ]
        },
        gasData: {
            filename: 'GasSavedData.csv',
            headers: [
                { label: t("common.date"), key: "date_time"},
                { label: t("dashboard.gassavedheader"), key: "fuel_saved" },
            ]
        },
        moneyData: {
            filename: 'MoneySavedData.csv',
            headers: [
                { label: t("common.date"), key: "date_time"},
                { label: t("dashboard.moneysavedheader"), key: "dollar_saved" },
            ]
        },
    };

    const getEnvironmentInfo = async () => {
        const url = urlReplace(ApiEndPoint.gaugesDataDetailStatement, "{HOST-ID}", hostId);
        await getCannedReportMethod(url, '')
            .then((res) => {
                if(res && res?.data && res?.status === 200) {
                    setDetailStatement(res?.data);
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    const getGaugesData = async () => {
        const url = urlReplace(ApiEndPoint.gaugesDataCommulative, "{HOST-ID}", hostId);
        
        await getCannedReportMethod(url, '')
        .then((res) => {
            if (res && res?.data && res?.status === 200) {
                setGaugeInfo(res?.data);
            } else {    // init to 0's if no data
                setGaugeInfo(initData);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    }

    const formatDate = (value: any) => {
        if(value) {
            let dateStr = moment.utc(value).format(dateFormats.dateFormmat);
            return dateStr;    
        } else {
            return value;
        }
    }

    const environmentDataSet = (value: any) => {
        let envHistoryData: any[] = [];
        if(value?.length > 0){
            envHistoryData = value.map(
                (val: any) => ({
                    ...val,
                    date_time: formatDate(val?.created_on)
                })
            );
        }
        return envHistoryData;
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        getGaugesData()
            .catch(console.log);
    },[]);
    
    return (
      <>
        <div data-testid="environment_info">
          <Grid container spacing={0} className="text_section">
              <Grid item xs={12}>
                  <Card>
                      <CardContent>
                          <h2>{t("dashboard.environment")}</h2>
                          <Typography  component="p">
                              {t("dashboard.environmenttextone")}    
                          </Typography>                           
                          <Grid container spacing={2} className="saved_section">
                              <Grid className="saved_section_inside active pt_0" item md={3} xs={12}>
                                  <Card>
                                      <CardContent>
                                          <CommonDownloadCsv
                                              {...props}
                                              datas={environmentDataSet(detailStatement)}
                                              headers={csvEnvironmentData.co2Data.headers}
                                              filename={csvEnvironmentData.co2Data.filename}
                                              callBackDownloadCsv={getEnvironmentInfo}
                                          >
                                              <div className="download_action">
                                                  <img className="cloud" src={Images.ic_cloud_download} alt="" />
                                                  <Typography  component="p">
                                                  {t("dashboard.downloadcsvusagedata")}
                                                  </Typography>
                                              </div>
                                          </CommonDownloadCsv>
                                          <h2>{t("dashboard.coreduce")}</h2>
                                          <Typography  component="p">
                                              {t("dashboard.coreducedetail")}
                                          </Typography>
                                          <img className="cloud" src={Images.ic_co_clouds} alt="" />
                                          <div className="dashboard-data-number">
                                              { gaugeInfo?.reduction_in_co2 } {t("common.pounds", { count: gaugeInfo?.reduction_in_co2 } )}
                                          </div>
                                          <Typography  component="p">
                                              {t("dashboard.poundsreducedyear")}
                                          </Typography>                    
                                      </CardContent>
                                  </Card>
                              </Grid>
                              <Grid className="saved_section_inside pt_0" item md={3} xs={12}>
                                  <Card>
                                      <CardContent> 
                                          <CommonDownloadCsv
                                              {...props}
                                              datas={environmentDataSet(detailStatement)}
                                              headers={csvEnvironmentData.barrelsData.headers}
                                              filename={csvEnvironmentData.barrelsData.filename}
                                              callBackDownloadCsv={getEnvironmentInfo}
                                          >
                                              <div className="download_action">
                                                  <img className="cloud" src={Images.ic_cloud_download} alt="" />
                                                  <Typography  component="p">
                                                  {t("dashboard.downloadcsvusagedata")}
                                                  </Typography>
                                              </div>
                                          </CommonDownloadCsv>
                                          <h2>{t("dashboard.oilsaved")}</h2>
                                          <Typography  component="p">
                                              {t("dashboard.oilsaveddetails")}
                                          </Typography>
                                          <img className="cloud" src={Images.ic_saving_energy} alt="" />
                                          <div className="dashboard-data-number">
                                              { gaugeInfo?.oil_saved } {t("common.barrels", { count: gaugeInfo?.oil_saved })}
                                          </div>
                                          <Typography  component="p">{t("dashboard.oilsavedyear")}</Typography>                            
                                      </CardContent>
                                  </Card>
                              </Grid>
                              <Grid className="saved_section_inside pt_0" item md={3} xs={12}>
                                  <Card>
                                      <CardContent>
                                          <CommonDownloadCsv
                                              {...props}
                                              datas={environmentDataSet(detailStatement)}
                                              headers={csvEnvironmentData.gasData.headers}
                                              filename={csvEnvironmentData.gasData.filename}
                                              callBackDownloadCsv={getEnvironmentInfo}
                                          >
                                              <div className="download_action">
                                                  <img className="cloud" src={Images.ic_cloud_download} alt="" />
                                                  <Typography  component="p">
                                                  {t("dashboard.downloadcsvusagedata")}
                                                  </Typography>
                                              </div>
                                          </CommonDownloadCsv>
                                          <h2>{t("dashboard.fluesaved")}</h2>
                                          <Typography  component="p">
                                          {t("dashboard.fluesaveddetails")}
                                          </Typography>
                                          <img className="cloud" src={Images.ic_fuel} alt="" />
                                          <div className="dashboard-data-number">
                                              { gaugeInfo?.fuel_saved } {t("common.gallons", { count: gaugeInfo?.fuel_saved })}
                                          </div>
                                          <Typography  component="p">{t("dashboard.gallonsfuelsavedyear")}</Typography>                            
                                      </CardContent>
                                  </Card>
                              </Grid>
                              <Grid className="saved_section_inside pt_0" item md={3} xs={12}>
                                  <Card>
                                      <CardContent>
                                          <CommonDownloadCsv
                                              {...props}
                                              datas={environmentDataSet(detailStatement)}
                                              headers={csvEnvironmentData.moneyData.headers}
                                              filename={csvEnvironmentData.moneyData.filename}
                                              callBackDownloadCsv={getEnvironmentInfo}
                                          >
                                              <div className="download_action">
                                                  <img className="cloud" src={Images.ic_cloud_download} alt="" />
                                                  <Typography  component="p">
                                                  {t("dashboard.downloadcsvusagedata")}
                                                  </Typography>
                                              </div>
                                          </CommonDownloadCsv>                     
                                          <h2>{t("dashboard.dollarssaved")}</h2>
                                          <Typography  component="p">
                                          {t("dashboard.dollarssaveddetails")}
                                          </Typography>
                                          <img className="cloud" src={Images.ic_saving} alt="" />
                                          <div className="dashboard-data-number">
                                              {formatter.format(gaugeInfo?.dollar_saved)} {t("common.dollars", {count: gaugeInfo?.dollar_saved })}
                                          </div>
                                          <Typography  component="p">{t("dashboard.millionsdollarssavedyear")}</Typography>
                                      </CardContent>
                                  </Card>
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>
          </Grid>
        </div>
      </>
    );

}

export default EnvironmentInfo;