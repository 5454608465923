import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    LinearProgress
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { AddVehicle } from "common/interfaces/fleet";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import '../../fleetManagement.scss';
import DeleteGroupVehicle from "../deleteGroupVehicle";
import { fetchGroupsList, manageFleetData, fetchVinInfo} from "services/fleetManagementService";
import {CheckAccess, claims, defaultVariable, keyCloakConfig, rolePermissionCheck} from "../../../../common";

import {
    DropdownYears,
    checkForDisableClass,
    FLEET_GROUP,
    YEAR,
    VIN,
    VEHICLE_NAME,
    ADD_FLEET_VEHICLES,
    UPDATE_FLEET_VEHICLES
} from "pages/fleet/fleetManagementHelper";



const AddFleetVehicle = (props: AddVehicle) => {
    let initialValue = {
        host_id: props.hostId,
        vehicleName: "",
        vin: "",
        make: "",
        model: "",
        year: "",
        efficiency: "",
        fleetGroup: "",
    };
    const { t } = useTranslation();
    const { vehicleInfo, edit, addGroupVehicleStatus } = props;
    const simpleValidator: any = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState<any>();
    const [groupsList, setGroupsList] = useState<any>([]);
    const year = DropdownYears(new Date().getFullYear());
    const [vehicleDetails, setVehicleDetails] = useState<any>(initialValue);
    const dispatch = useDispatch();
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [duplicateVin, setDuplicateVin] = useState(false);
    const [duplicateVehicleName, setDuplicateVehicleName] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<any>(' ');
    const [selectedYear, setSelectedYear] = useState<any>(' ');
    const [disableSaveBtn, setSaveDisable] = useState(true);
    const [openDeleteVehicle, setDeleteVehicleOpen] = useState(false);
    const [years, setDropdownYears] = useState<any>(year);
    const showVehicleRemoveButton = CheckAccess([claims.VEHICLE_REMOVE]);
    
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const delegateId =  isDelegate ? sessionStorage.getItem('delegateId') : "";
    const [searchVin, setSearchVin] = useState('');
    

    useEffect(() => {
        if (vehicleInfo) {
            setVehicleDetails(vehicleInfo);
            setSelectedGroup(vehicleInfo?.fleetGroup);
            setSelectedYear(vehicleInfo?.year ? vehicleInfo?.year : ' ');
            if(edit){
                setSaveDisable(false);
                getGroupDetails(props.hostId);
            } else {
                setSaveDisable(true);
            }
        } else {
            getGroupDetails(props.hostId);
        }
    }, [vehicleInfo]);

    const deleteVehicleclose = () => {
        setDeleteVehicleOpen(false);
    };

    const deleteVehicleopen = () => {
        setDeleteVehicleOpen(true);
    };


    const getDataFromVin = (vin:any) => {
        let params = {vin: vin};
        setSpinnerOff(true);
        fetchVinInfo(params, false)
            .then((res: any) => {
                if (res.status) {
                    const updatedVehicle = {...vehicleDetails, ...res?.vinDetails, ...params};
                    let year = DropdownYears(res?.vinDetails?.year ? res?.vinDetails?.year : 0);
                    setDropdownYears(year);
                    setVehicleDetails(updatedVehicle);
                    setSelectedYear(res?.vinDetails?.year);
                    setSpinnerOff(false);
                    checkPrevState();
                } else {
                    setSpinnerOff(false);
                }
                setSearchVin('');
            })
            .catch((err: any) => {
                console.error("Error", err);
            });
    };
       

    const handleChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        setVehicleDetails({ ...vehicleDetails, [name]: value });
        if (name === FLEET_GROUP){
            setSelectedGroup(value);
        } else if (name === YEAR){
            setSelectedYear(value);
        } else if (name === VEHICLE_NAME){
            if (duplicateVehicleName) {
                setDuplicateVehicleName(false);
            }
        } else if (name === VIN){
            if (duplicateVin) {
                setDuplicateVin(false);
            }
            setSearchVin(value);
        }
        checkPrevState();    
    };

    useEffect(() => {
        if(searchVin !==''){
            const delay = 500; // Adjust the delay 
            const timeout = setTimeout(() => {
              getDataFromVin(searchVin);
            }, delay);
            return () => clearTimeout(timeout);
        }
    }, [searchVin]);

    const checkPrevState = () => {
        setVehicleDetails((prevState: any) => {
            if (
                prevState.vehicleName !== "" &&
                prevState.make !== "" &&
                prevState.model !== "" &&
                prevState.year !== "" &&
                prevState.fleetGroup !== ""
            ) {
                setSaveDisable(false);
            }
            return prevState;
        });
    };

    const resetFleetVehicleFormData = () => {
        setVehicleDetails(vehicleInfo);
        setSelectedGroup(vehicleInfo?.fleetGroup);
        setSelectedYear(vehicleInfo?.year ? vehicleInfo?.year : ' ');
        simpleValidator.current.hideMessages();
        forceUpdate(0);
        if (!edit) {
            setSaveDisable(true);
            if(addGroupVehicleStatus){
                setVehicleDetails(vehicleInfo);
            } else {
                setVehicleDetails(initialValue);         
            }
            
        }
    };

    const closeAddVehicleModal = () => {
        props.addVehicleClose();
        resetFleetVehicleFormData();
        setDuplicateVin(false);
        setDuplicateVehicleName(false);
        if(!edit){
            setSelectedYear(' ');
            setSaveDisable(true);
            setSelectedGroup(' ');
        }
        
    };

    const validateError = (fieldName: any) => {
        if (
            simpleValidator.current.fields[fieldName] === false &&
            simpleValidator.current.messagesShown === true
        ) {
            return "error-show";
        } else {
            return "";
        }
    };

    const submitNewVehicle = () => {
        let valid = simpleValidator.current.allValid();
        if (valid) {
            setSpinnerOff(true);
            let editObj = {
                host_id: props.hostId,
                vehicleName: vehicleDetails?.vehicleName,
                vin: vehicleDetails?.vin,
                make: vehicleDetails?.make,
                model: vehicleDetails?.model,
                year: vehicleDetails?.year,
                efficiency: vehicleDetails?.efficiency,
                fleetGroup: vehicleDetails?.fleetGroup,
                id: props?.vehicleId,
            };

            let body =props.edit ? editObj : vehicleDetails;
            let paramObj =  {action: props.edit ? UPDATE_FLEET_VEHICLES : ADD_FLEET_VEHICLES};

            manageFleetData(paramObj, body).then((res: any) => {
                if (res.status) {
                    props.addVehicleClose();
                    resetFleetVehicleFormData();
                    props.dataRefreshFunction();
                    setSpinnerOff(false);
                    setDuplicateVin(false);
                    setDuplicateVehicleName(false);
                    setSelectedGroup(' ');
                    setSelectedYear(' ');
                    if(edit){
                        props?.setGroupId(vehicleDetails?.fleetGroup);
                    }
                } else {
                    if(res?.duplicateVehicleName || res?.duplicateVin){
                        res?.duplicateVin ? setDuplicateVin(true) : setDuplicateVin(false);
                        res?.duplicateVehicleName ? setDuplicateVehicleName(true) : setDuplicateVehicleName(false);
                    }  else {
                        dispatch(
                            showErrorMessages({
                                error: true,
                                message: res.message,
                                type: "error",
                                operation: "",
                            })
                        );
                    }
                    
                    setSpinnerOff(false);
                }
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const getGroupDetails = (hostId: any) => {
        if (hostId !== "" || delegateId !== "") {
            setSpinnerOff(true);
            let params = {host_id: hostId, delegateId: delegateId, isDelegate: isDelegate};
            fetchGroupsList(params, false)
                .then((res: any) => {
                    if (res) {
                        setGroupsList(res);
                        setSpinnerOff(false);
                    }
                })
                .catch((err: any) => {
                    console.error("Error", err);
                    setSpinnerOff(false);
                });
        }
    };

    return (
        <>
            {/* Dialog for add or edit vehicle */}
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info sm dialog_container_center"
                    open={props.openAddVehicle}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>
                            {props.edit
                                ? t("fleet.editvehicle")
                                : t("fleet.addnewvehicle")}
                        </h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={closeAddVehicleModal}
                            >
                                {t("common.cancelbutton")}
                            </Button>
                            <Button
                                className={`btn_white ${checkForDisableClass(
                                    disableSaveBtn
                                )}`}
                                onClick={submitNewVehicle}
                                disabled={disableSaveBtn}
                            >
                                {props.edit
                                    ? t("common.savechanges")
                                    : t("common.save")}
                            </Button>
                        </div>
                    </div>
                    {spinnerOff && <LinearProgress />}
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form mt-25">
                                <div className="section_group">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={12}>
                                        <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>
                                                            {t("fleet.vehiclename")}{" "}
                                                            {t(
                                                                "common.required"
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col-6 ${validateError(
                                                                "vehicleName"
                                                            )}`}
                                                        >
                                                            <TextField
                                                                className="w_100per full_w"
                                                                variant="outlined"
                                                                name="vehicleName"
                                                                value={
                                                                    vehicleDetails?.vehicleName
                                                                }
                                                                placeholder={t(
                                                                    "fleet.vehiclename.description"
                                                                )}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            {duplicateVehicleName ? (
                                                                <div className="srv-validation-message">
                                                                    {t(
                                                                        "fleet.duplicateVehicleName"
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <cite className="error_msg error_position">
                                                                    {simpleValidator.current.message(
                                                                        "vehicleName",
                                                                        vehicleDetails?.vehicleName,
                                                                        "required"
                                                                    )}
                                                                </cite>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>
                                                            {t("fleet.vin")}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col-6 ${validateError(
                                                                "vin"
                                                            )}`}
                                                        >
                                                            <TextField
                                                                className="w_100per full_w"
                                                                variant="outlined"
                                                                name="vin"
                                                                value={
                                                                    vehicleDetails?.vin
                                                                }
                                                                placeholder={t(
                                                                    "fleet.vin.description"
                                                                )}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            {duplicateVin && (
                                                                <div className="srv-validation-message">
                                                                    {t(
                                                                        "fleet.duplicateVin"
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>
                                                            {t("fleet.make")}{" "}
                                                            {t(
                                                                "common.required"
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col-6 ${validateError(
                                                                "make"
                                                            )}`}
                                                        >
                                                            <TextField
                                                                className="w_100per full_w"
                                                                variant="outlined"
                                                                name="make"
                                                                value={
                                                                    vehicleDetails?.make
                                                                }
                                                                placeholder={t(
                                                                    "fleet.make.description"
                                                                )}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            <cite className="error_msg error_position">
                                                                {simpleValidator.current.message(
                                                                    "make",
                                                                    vehicleDetails?.make,
                                                                    "required"
                                                                )}
                                                            </cite>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>
                                                            {t("fleet.model")}{" "}
                                                            {t(
                                                                "common.required"
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col-6 ${validateError(
                                                                "model"
                                                            )}`}
                                                        >
                                                            <TextField
                                                                className="w_100per full_w"
                                                                variant="outlined"
                                                                name="model"
                                                                value={
                                                                    vehicleDetails?.model
                                                                }
                                                                placeholder={t(
                                                                    "fleet.model.description"
                                                                )}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            <cite className="error_msg error_position">
                                                                {simpleValidator.current.message(
                                                                    "model",
                                                                    vehicleDetails?.model,
                                                                    "required"
                                                                )}
                                                            </cite>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label className="w_100per full_w">
                                                            {t(
                                                                "fleet.make_model_year"
                                                            )}{" "}
                                                            {t(
                                                                "common.required"
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col-6 ${validateError(
                                                                "year"
                                                            )}`}
                                                        >
                                                            <Select
                                                                className="w_100per full_w"
                                                                labelId="demo-simple-select-label"
                                                                variant="outlined"
                                                                name="year"
                                                                value={
                                                                    selectedYear
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            >
                                                                <MenuItem
                                                                    className="defult_select_option"
                                                                    key={0}
                                                                    value=" "
                                                                    disabled
                                                                >
                                                                    {t(
                                                                        "fleet.selectYear"
                                                                    )}
                                                                </MenuItem>
                                                                {years &&
                                                                    years.map(
                                                                        (
                                                                            value: any
                                                                        ) => (
                                                                            <MenuItem
                                                                                className="defult_select_option"
                                                                                key={
                                                                                    value?.index
                                                                                }
                                                                                value={
                                                                                    value
                                                                                }
                                                                            >
                                                                                {
                                                                                    value
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </Select>
                                                            <cite className="error_msg error_position">
                                                                {simpleValidator.current.message(
                                                                    "year",
                                                                    vehicleDetails?.year,
                                                                    "required"
                                                                )}
                                                            </cite>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>
                                                            {t(
                                                                "fleet.efficiency"
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type="number"
                                                            className="w_100per full_w"
                                                            variant="outlined"
                                                            name="efficiency"
                                                            value={
                                                                vehicleDetails?.efficiency
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            placeholder={t(
                                                                "fleet.efficiency.placeholder"
                                                            )}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <span className="input_adornment_font">
                                                                            {t(
                                                                                "fleet.efficiency.description"
                                                                            )}
                                                                        </span>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div
                                                className="form_col"
                                                hidden={addGroupVehicleStatus}
                                            >
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label className="w_100per full_w">
                                                            {t("common.fleet")}{" "}
                                                            {t(
                                                                "common.required"
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col-6 ${validateError(
                                                                "fleetGroup"
                                                            )}`}
                                                        >
                                                            <Select
                                                                className="w_100per full_w"
                                                                labelId="demo-simple-select-label"
                                                                variant="outlined"
                                                                name="fleetGroup"
                                                                value={
                                                                    selectedGroup
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            >
                                                                <MenuItem
                                                                    className="defult_select_option"
                                                                    key={0}
                                                                    value=" "
                                                                    disabled
                                                                >
                                                                    {t(
                                                                        "fleet.selectGroup"
                                                                    )}
                                                                </MenuItem>
                                                                {groupsList &&
                                                                   groupsList?.length > 0 && groupsList.map(
                                                                        (
                                                                            value: any
                                                                        ) => (
                                                                            <MenuItem
                                                                                className="defult_select_option"
                                                                                key={
                                                                                    value?.id
                                                                                }
                                                                                value={
                                                                                    value?.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    value?.group
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </Select>
                                                            <cite className="error_msg error_position">
                                                                {simpleValidator.current.message(
                                                                    "fleetGroup",
                                                                    vehicleDetails?.fleetGroup,
                                                                    "required"
                                                                )}
                                                            </cite>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {edit && showVehicleRemoveButton &&  (
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={6}
                                                        />
                                                        <Grid item xs={6}>
                                                            <label/>
                                                            <div className=" w_100per full_w">
                                                                <Button className="delet_acct ml_0 btn_white block_btn" onClick={deleteVehicleopen}>
                                                                    {t(
                                                                        "fleet.deletevehicle"
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>

            {/* Dialog Add End */}

            <DeleteGroupVehicle
                deleteVehicleId={props?.vehicleId}
                openDeleteVehicle={openDeleteVehicle}
                deleteVehicleClose={deleteVehicleclose}
                groupVehicle={false}
                closeEditVehicle={closeAddVehicleModal}
                hostUserId={props?.hostUserId}
            />
        </>
    );
};
export default AddFleetVehicle;
