import React from "react";
import { ObjectDetails } from "common/interfaces";
import Proptypes from "prop-types";
import GoogleMapReact from "google-map-react";
import Images from "../../assets/images";
import environment from "../../environment";
import { getAutoSearchMapOptions } from 'common/mapHelpers';
import ReactDOM from "react-dom";

const AnyReactComponent = () => (
    <div>{<img src={Images.MAP_MARKER} />}</div>
);

const AutoSearchGoogleMap = (props: any) => {
    React.useEffect(() => {
         let tlet = Number(props.addressDetails.latitude);
         let tlon = Number(props.addressDetails.longitude);
         let centerp = [tlet,tlon]
         setConfigSearch({...configSearch,lat:tlet,lon:tlon,center:centerp});
      }, [props.addressDetails.latitude, props.addressDetails.longitude]);

    const [dragabble, setDragabble] = React.useState(true);
    const initialConfig = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        center:[props.addressDetails.latitude,props.addressDetails.longitude],
        zoom:props?.zoom, 
        lat:props.addressDetails.latitude,
        lon:props.addressDetails.longitude
    };
    const [configSearch, setConfigSearch] = React.useState<any>(initialConfig);

    const defaultMapOptions = {
        fullscreenControl: props?.fullscreenControl
            ? props.fullscreenControl
            : false,
        scrollwheel: props?.scrollwheel ? props.scrollwheel : false,
        gestureHandling: props?.gestureHandling ? props?.gestureHandling : "none",
    };

    const onMarkerInteraction = (childKey: any, childProps: any, mouse: any) => {
        if(props?.enablePinMove) {
            setDragabble(false);
            setConfigSearch({
                ...configSearch,
                lat: mouse.lat,
                lon: mouse.lng,
            });
        }

    };

    const onMarkerInteractionMouseUp = (childKey: ObjectDetails, childProps: ObjectDetails, mouse: ObjectDetails) => {
        if(props?.enablePinMove) {
            setDragabble(true);
            
            if( mouse.lat!== undefined && mouse.lng !== undefined ){
                props.setAddressDetails({ ...props.addressDetails, latitude: mouse.lat, longitude: mouse.lng });
            }
        }
    }

    const apiHasLoaded = (map: any, maps: any) => {
        setConfigSearch({
            ...configSearch,
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
        handleOnLoad(map);

    };
   
    const onChange = ({ center, zoom }: any) => {
        
        setConfigSearch({
            ...configSearch,
            center: center,
            zoom: zoom,
        });

    }

    const handleOnLoad = (GoogleMap: any) => {
        if(props.activateBlinkCardOpen){
        const controlButtonDiv = document.createElement('div');
        ReactDOM.render(<img onClick={() => props.activateBlinkCardOpen()} src={Images.MAP_MAXIMIZE} />, controlButtonDiv);
        GoogleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);                
        }
      };

    return (
        <>
            <div data-testid="autosearch_google_maps">
                <GoogleMapReact
                    {...props}
                    bootstrapURLKeys={{ key: environment.googleMapKey, libraries: ['places', 'geometry'] }}
                    center={configSearch.center}
                    draggable={dragabble}
                    onChange={onChange} 
                    onChildMouseDown={onMarkerInteraction}
                    onChildMouseUp={onMarkerInteractionMouseUp}
                    onChildMouseMove={onMarkerInteraction}
                    zoom={configSearch.zoom}
                    options={props.options ? getAutoSearchMapOptions : defaultMapOptions}
                    onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                >
                    <AnyReactComponent
                        {...props}
                        lat={configSearch.lat}
                        lng={configSearch.lon}
                    />
                </GoogleMapReact>
            </div>
        </>
    );
};

AutoSearchGoogleMap.propTypes = {
    zoom: Proptypes.number,
    fullscreenControl: Proptypes.bool,
    scrollwheel: Proptypes.bool,
    gestureHandling: Proptypes.string,
    setAddressDetails: Proptypes.instanceOf,
    addressDetails: Proptypes.instanceOf,
    options: Proptypes.bool,
    activateBlinkCardOpen: Proptypes.instanceOf,
    enablePinMove: Proptypes.bool
};

AutoSearchGoogleMap.defaultProps = {
    zoom: 5,
    fullscreenControl: false,
    scrollwheel: false
};

export default AutoSearchGoogleMap;
