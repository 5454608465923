import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem }  from "@material-ui/core";
import { defaultVariable, LabelInfo } from "common";
import AutoSearchGoogleMap from "components/commonGoogleMap/autoSearchGoogleMap";
import { getLatLng, geocodeByLatLng } from "react-google-places-autocomplete";
import environment from "../../environment";
import { getMethod, getTimezoneMethod, ApiEndPoint } from "services";
import { useTranslation } from "react-i18next";

const AddressForm = (props: any) => {
  const { t } = useTranslation();

  const [, setstateList] = useState<any>([]);
  const [, setCountryList] = useState<any>();
  const [mapValue, setMapValue] = useState<any>();
    const CURRENT_VALUE = 'current';
    const FUTURE_VALUE = 'future';
 
  const defaultCountry = props.addressDetails?.countryCode
    ? props.addressDetails?.countryCode
    : defaultVariable.defaultCountry;

  const [, setTimezonesData] = useState<any>([]);
  const [, setPopupLatLng] = useState<any>();
 
  const [locationAccessList, setLocationAccess] = useState<any>([]);
  const defaultLocationAccess = props.intialDetails?.isPublic
    ? defaultVariable.locationAccessDropDownDetails[1].id
    : defaultVariable.locationAccessDropDownDetails[0].id;

  useEffect(() => {
        if(!openActivateBlinkCard){
            setPopupLatLng({latitude: props?.addressDetails?.latitude, longitude: props?.addressDetails?.longitude });
    }
  }, [props?.addressDetails?.latitude]);

  const [openActivateBlinkCard] = useState(false);
  
    
  useEffect(() => {
    getstateData(defaultCountry);
    getCountriesData();
    getTimezonesData();
    setLocationAccess(defaultVariable.locationAccessDropDownDetails)
  }, [defaultCountry]);

  React.useEffect(() => {
    setMapValue({
      ...mapValue,
      label: props.addressDetails?.addressLine1,
      value: { place_id: "" },
    });
  }, [props.addressDetails?.addressLine1]);

  React.useEffect(() => {
    let lat = props.addressDetails?.latitude;
    let lng = props.addressDetails?.longitude;

        if(lat!=null && lng!=null){
      geocodeByLatLng({ lat: lat, lng: lng })
            .then(results => matchAddress(results[0],props.refreshAddress.type))
            .catch(error => console.error(error));
    }

  }, [props.refreshAddress]);

    const reverseGeocodingWithGoogle = async (latitude: number, longitude: number) => {
    try {
      let timestamp = Math.floor(Date.now() / 1000);
         let res = await getTimezoneMethod(environment.googleTimezoneUrl, `?location=${latitude}%2C${longitude}&timestamp=${timestamp}&key=${environment.googleMapKey}`);
      return res;
        } catch (error:any) {
      return error.response;
    }
      }

  const getCountriesData = async () => {
    await getMethod(ApiEndPoint.countryList, "")
      .then((res) => {
        if (res && res?.data && res?.data?.countries?.length >= 1) {
          setCountryList(res?.data?.countries);
        }
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const getstateData = async (country: string) => {
    const paramObj = `?countryCode=${country}`;
    await getMethod(ApiEndPoint.stateList, paramObj)
      .then((res) => {
        if (res && res?.data) {
          setstateList(res?.data?.states);
        }
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const matchAddress = (place: any, matchName: string) => {
    let data: any = [];
    data["street_number"] = "";
    data["route"] = "";
    data["timezone"] = place?.utc_offset_minutes;

    let addressComponents = place?.address_components ? [...place.address_components] : [];

    for(let addr of addressComponents) {

      let expr = addr.types[0];
      let longVal = addr.long_name;
      let shortVal = addr.short_name;

      switch (expr) {
        case "locality":
          data["city"] = longVal;
          break;
        case "administrative_area_level_2":
          data["district"] = longVal;
          break;
        case "administrative_area_level_1":
          data["state"] = longVal;
          data["state_code"] = shortVal;
          break;
        case "country":
          data["country"] = longVal;
          data["country_code"] = shortVal;
          break;
        case "postal_code":
          data["postal_code"] = longVal;
          break;
        case "street_number":
          data["street_number"] = longVal;
          break;
        case "route":
          data["route"] = longVal;
          break;
        default:
      }
    }

    let formatedAddress = place.formatted_address;
    if (data["street_number"] !== "" && data["route"] !== "") {
      formatedAddress = data["street_number"] + " " + data["route"];
    }

    getLatLng(place).then(async ({ lat, lng }) => {

      let timeZoneRes = await reverseGeocodingWithGoogle(lat, lng);

      let tempAddress = {
        ...props.addressDetails,
        addressLine1: formatedAddress,
        city: data["city"],
        countryCode: data["country_code"],
        country: data["country"],
        postalCode: data["postal_code"],
        state: data["state_code"],
        latitude: lat,
        longitude: lng,
                timezone: timeZoneRes?.data?.timeZoneId
            }

            if(matchName === FUTURE_VALUE){
        props.setUpdatedAddressDetails(tempAddress);
              
      }

            if(matchName === CURRENT_VALUE){
            
        props.setAddressDetails(tempAddress);
        props.setInitialAddressDetails(tempAddress);

        if (mapValue.value.place_id) {
          setMapValue({ ...mapValue, label: formatedAddress });
        } else {
          setMapValue({
            ...mapValue,
            label: formatedAddress,
            value: { place_id: "" },
          });
        }
      }
    });
  };


  const getTimezonesData = async () => {
    await getMethod(ApiEndPoint.timeZoneList, '').then((res) => {
        if (res && res?.data && res?.data?.timeZones?.length >= 1) {
          setTimezonesData(res?.data?.timeZones);
        }
      }).catch((err) => {
        console.error("Error", err)
      })
  };

  return (
    <> 
      <div className="section_group" data-testid="address_form">
        <div className={`form_col ${props?.validateError("isPublic")}`}>
          <Grid
            className="single_line"
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <LabelInfo
                name={t("common.locationAccess")}
                isMandatory={false}
              />
              {locationAccessList && (
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  name="isPublic"
                  value={defaultLocationAccess}
                  onChange={props.handleInitialChange}  
                >
                  {locationAccessList &&
                    locationAccessList.map((value: any) => (
                      <MenuItem
                        className="defult_select_option"
                        key={value.id}
                        value={value.id}
                      >
                        {value.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
              <span className="error_msg">
                {props.validator.current.message(
                  "locationAccess",
                  props?.intialDetails?.isPublic,
                  "required"
                )}
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export { AutoSearchGoogleMap };
export default AddressForm;
