import { claims } from './claims';

export const menuList = [
    {
      "name":"dashboard",
      "menuItem":"Dashboard",
      "menuImage":"dashboardImage",
      "ImageHover":"hoverDashboard",
      "route":"fleetDashboardRoute"
    },
    {
      "name":"location",
      "menuItem":"EVSE Locations",
      "menuImage":"locationImage",
      "ImageHover":"locationHover",
      "route":"depotListRoute"
    },
    {
      "name":"users",
      "menuItem":"Manage Users",
      "menuImage":"userImage",
      "ImageHover":"hoverUser",
      "route":"manageUserRoute",
      "accessLevel": [claims.DRIVER_READ]
    },
    {
      "name":"blinkcards",
      "menuItem":"Blink Cards",
      "menuImage":"blinkcardImage",
      "ImageHover":"hoverBlinkcard",
      "route":"blinkCardsRoute",
      "accessLevel": [claims.FORBIDDEN_ACCESS]
    },
    {
      "name":"statements",
      "menuItem":"Statements",
      "menuImage":"statementImage",
      "ImageHover":"hoverStatement",
      "route":"",
      "accessLevel": [claims.FORBIDDEN_ACCESS]
    },
    {
      "name":"fleet",
      "menuItem":"Manage Fleet",
      "menuImage":"fleetImage",
      "ImageHover":"hoverFleet",
      "route":"fleetRoutes",
      "accessLevel": [claims.FLEET_READ]
    },
    {
      "name":"settings",
      "menuItem":"Host Settings",
      "menuImage":"settingsImage",
      "ImageHover":"hoverSettings",
      "route":"hostSettingsRoute"
    },
    {
      "name":"training",
      "menuItem":"Training",
      "menuImage":"trainingImage",
      "ImageHover":"hovertrainingimage",
      "route":"trainingRoute"
    }
  ];