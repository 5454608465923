import React, { useState, useEffect } from "react";
import {
    Grid,
    Select,
    IconButton,
    MenuItem,
    OutlinedInput
}
    from "@material-ui/core";
import { PricingTypeGrid } from "common/interfaces/pricingPlan";
import { useTranslation } from "react-i18next";
import { filterDropDownOptions, displayRemoveIconForHybrid, months, times, addFieldForHybrid, removeFieldForHybrid, 
        hours, TARIFF_MUL_FAC, TARIFF_PARKING_COST } from "../pricingPlanHelpers";
import Images from "assets/images";
import { checkFloatValue } from "common/methodHelpers";

const HybridpricingPlan = (props: PricingTypeGrid) => {

    const { t } = useTranslation();
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        setTimeout(() =>{
            setShowErrorMsg(false)
        },8000)
    }, [props])

    const addNewSplitInTime = (type: string, event: any, monthIndex: number, timeIndex: number, hourIndex: number, total: any) => {
        let newPricingDetails = addFieldForHybrid(type, event, monthIndex, timeIndex, hourIndex, total, props.pricingPlanData, props.pricingDetails.type);
        props.setPricingPlanData([...newPricingDetails]);
    }


    const handleCostUpdate = (monthIndex: number, timeIndex: number, hourIndex: number, event: any) => {
        let floatValue = checkFloatValue(event);
        if (floatValue) {
            let cost = event.target.value;
            if (cost > TARIFF_MUL_FAC * TARIFF_PARKING_COST) {
                setShowErrorMsg(true);
            }
            let newPricingDetails = props.pricingPlanData;
            newPricingDetails[monthIndex].time[timeIndex].duration[hourIndex].cost = cost;
            props.setPricingPlanData([...newPricingDetails]);
        }
    }


    const handleRemoveField = (time: any, hour: any, monthIndex: number, timeIndex: number, hourIndex: number) => {
        let newPricingDetails = removeFieldForHybrid(props.pricingPlanData, time, hour, monthIndex, timeIndex, hourIndex, props.pricingDetails.type);
        props.setPricingPlanData([...newPricingDetails]);
    }

    return (
        <>
            <Grid className="time_of_day">
                <div className="pc_time_padding">
                    <Grid container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}>
                        <Grid item xs={3}><span className="label">{t("pricing.month")}</span></Grid>
                        <Grid item xs={3}><span className="label">{t("pricing.time")}</span></Grid>
                        <Grid item xs={3}><span className="label">{t("pricing.duration")}</span></Grid>
                        <Grid className="header_padding" item xs={3}><span className="label">{t("pricing.cost")}</span></Grid>
                    </Grid>
                </div>
                <div className="defult_form default_form">
                    <div className="form_col">
                        {
                            props.pricingPlanData && props.pricingPlanData.map((monthVal: any, monthIndex: number) => (
                                <Grid container
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    className="month_separator"
                                    spacing={0}>
                                    <Grid item xs={3}>
                                        <Grid container
                                            className="single_line_dropdown"
                                            spacing={2}>
                                            <Grid item>
                                                <Select
                                                    className="disable"
                                                    variant="outlined"
                                                    name="startMonth"
                                                    disabled
                                                    value={monthVal.start}
                                                    defaultValue={monthVal.start}>
                                                    {months.map((month: any) => (
                                                        <MenuItem className="defult_select_option"
                                                            key={month.value}
                                                            value={month.value}>
                                                            <Grid>{month.label}</Grid>
                                                        </MenuItem>)
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    variant="outlined"
                                                    name="endMonth"
                                                    defaultValue={monthVal.end}
                                                    value={monthVal.end}
                                                    onChange={(e) => addNewSplitInTime('month', e, monthIndex, 0, 0, props.pricingPlanData.length)}>
                                                    {filterDropDownOptions('month', months, monthIndex, monthVal.start, "", props.pricingPlanData).map((month, i) => (
                                                        <MenuItem className="defult_select_option"
                                                            key={month.value}
                                                            value={month.value}>
                                                            <Grid>{month.label}</Grid>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs>
                                        {
                                            monthVal.time && monthVal.time.map((timeVal: any, timeIndex: number) => (
                                                <Grid container
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    className={timeIndex > 0 ? "pt-10" : ''}
                                                    spacing={0}>
                                                    <Grid item xs={4}>
                                                        <Grid container
                                                            className="single_line_dropdown"
                                                            spacing={2}>
                                                            <Grid item>
                                                                <Select
                                                                    className="disable"
                                                                    variant="outlined"
                                                                    name="startDay"
                                                                    disabled
                                                                    value={timeVal.start}
                                                                    defaultValue={timeVal.start}>
                                                                    {times.map((time: any) => (
                                                                        <MenuItem className="defult_select_option"
                                                                            key={time.value}
                                                                            value={time.value}>
                                                                            <Grid>{time.label}</Grid>
                                                                        </MenuItem>)
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item>
                                                                <Select
                                                                    variant="outlined"
                                                                    name="endDay"
                                                                    defaultValue={timeVal.end}
                                                                    value={timeVal.end}
                                                                    onChange={(e) => addNewSplitInTime('time', e, monthIndex, timeIndex, 0, monthVal.time.length)}>
                                                                    {filterDropDownOptions('time', times, monthIndex, timeVal.start,"", props.pricingPlanData,timeIndex,0).map((time) => (
                                                                        <MenuItem className="defult_select_option"
                                                                            key={time.value}
                                                                            value={time.value}>
                                                                            <Grid>{time.label}</Grid>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs>
                                                        {
                                                            timeVal.duration && timeVal.duration.map((hourVal: any, hourIndex: number) => (
                                                                <Grid container
                                                                    justifyContent="space-between"
                                                                    alignItems="flex-start"
                                                                    className=""
                                                                    spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        <Grid container
                                                                            className="single_line_dropdown"
                                                                            spacing={2}>
                                                                            <Grid item>
                                                                                <Select
                                                                                    className="disable"
                                                                                    variant="outlined"
                                                                                    name="timeDay"
                                                                                    disabled
                                                                                    value={hourVal.start}
                                                                                    defaultValue={hourVal.start}>
                                                                                    {hours.map((time: any) => (
                                                                                        <MenuItem
                                                                                            className="defult_select_option"
                                                                                            key={time.value}
                                                                                            value={time.value}>
                                                                                            <Grid>{time.label}</Grid>
                                                                                        </MenuItem>)
                                                                                    )}
                                                                                </Select>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Select
                                                                                    variant="outlined"
                                                                                    name="endDay"
                                                                                    id = {`endDay-${hourIndex}`}
                                                                                    defaultValue={hourVal.end}
                                                                                    value={hourVal.end}
                                                                                    inputProps = {{"data-testid": `hour-input-${hourIndex}`}}
                                                                                    onChange={(e) => addNewSplitInTime('hours', e, monthIndex, timeIndex, hourIndex, timeVal.duration.length)}>
                                                                                    {filterDropDownOptions('hours', hours, monthIndex, hourVal.start, props.pricingDetails.type, props.pricingPlanData, timeIndex, hourIndex).map((time, k) => (
                                                                                        <MenuItem
                                                                                            className="defult_select_option"
                                                                                            key={time.value}
                                                                                            value={time.value}>
                                                                                            <Grid>{time.label}</Grid>
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item container xs={6} className="custom_input" direction="row">
                                                                        <div className="pricing_rate">
                                                                            <OutlinedInput
                                                                                className={`input_text`}
                                                                                id="outlined-guest-standard"
                                                                                name={`vip`}
                                                                                value={hourVal.cost}
                                                                                placeholder='0.00'
                                                                                inputProps={{ maxLength: 5, "data-testid": `cost-input-${hourIndex}`}}
                                                                                onChange={(event) => handleCostUpdate(monthIndex, timeIndex, hourIndex, event)}
                                                                            />
                                                                        </div>
                                                                        {displayRemoveIconForHybrid(props.pricingPlanData, monthVal.time, timeVal.duration, monthIndex, timeIndex, hourIndex) &&
                                                                            <IconButton data-testid = {`action-button-${hourIndex}`}
                                                                                onClick={() => handleRemoveField(monthVal.time, timeVal.duration, monthIndex, timeIndex, hourIndex)}>
                                                                                <img className="img_ic"
                                                                                    src={Images.ic_close_white_background}
                                                                                    alt="" />
                                                                            </IconButton>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </div>
                </div>
            </Grid>
            {showErrorMsg &&
                <div className="error_msg_cost">{t("pricing.highcost")}</div>
            }
        </>
    );
};

export default HybridpricingPlan;