import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  ListSubheader,
  List,
  ListItem,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";
import { toUpper } from "lodash";
import { getFirstLetterColor, getFormattedDateTimeWithTZ, keyCloakConfig, useStyles } from "common";
import { ApiEndPoint, getMethod, postMethod } from "services";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginTime } from "redux/actions/loginTime";

const ChangePassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [passwordValues, setPasswordValues] = useState({ oldPassword: '', newPassWord: '', confirmNewPassowrd: '' })
  const permissions = useSelector((state: any) => { return state?.fetchUserPermissions ? state.fetchUserPermissions : [] });
  const [newVisbile, setNewVisible] = useState(false);
  const [oldVisbile, setOldVisible] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [confirmNewVisbile, setConfirmNewVisible] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordNotSameError, setoldPasswordNotSameError] = useState(false);
  const NEW_PASSWORD_VALUE = "newPassWord";
  const CONFIRM_PASSWORD_VALUE = "confirmNewPassowrd"
  const OLD_PASSWORD_VALUE = "oldPassword"
  const [errors, setErrors] = useState({ atleastEight: false, oneUpperCase: false, oneLowerCase: false, oneNumeric: false, oneSpecialChar: false, confirmNewPass: false })

  const checkNumberRegex = (value: any) => {
    const numberRegex = /^(?=.*\d)/;

    if (value && numberRegex.test(value)) {
      setErrors(prevState => ({
        ...prevState,
        oneNumeric: true
      }))
    } else {
      setErrors(prevState => ({
        ...prevState,
        oneNumeric: false
      }))
    }
  };

  const checkUpperRegex = (value: any) => {
    const upperRegex = /^(?=.*[A-Z])/;

    if (value && upperRegex.test(value)) {
      setErrors(prevState => ({
        ...prevState,
        oneUpperCase: true
      }))
    } else {
      setErrors(prevState => ({
        ...prevState,
        oneUpperCase: false
      }))
    }
  };

  const checkLowerRegex = (value: any) => {
    const lowerRegex = /^(?=.*[a-z])/;

    if (value && lowerRegex.test(value)) {
      setErrors(prevState => ({
        ...prevState,
        oneLowerCase: true
      }))
    } else {
      setErrors(prevState => ({
        ...prevState,
        oneLowerCase: false
      }))
    }
  }

  const checkSpecialCharRegex = (value: any) => {
    const specialCharRegex = /^(?=.*[-+_!@#$%^&*., ?])/;

    if (value && specialCharRegex.test(value)) {
      setErrors(prevState => ({
        ...prevState,
        oneSpecialChar: true
      }))
    } else {
      setErrors(prevState => ({
        ...prevState,
        oneSpecialChar: false
      }))
    }
  }

  function isAllPresent(value: any) {

    checkNumberRegex(value);
    checkUpperRegex(value);
    checkLowerRegex(value);
    checkSpecialCharRegex(value);

    if (value && value.length >= 8) {
      setErrors(prevState => ({
        ...prevState,
        atleastEight: true
      }))
    } else {
      setErrors(prevState => ({
        ...prevState,
        atleastEight: false
      }))
    }

  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === NEW_PASSWORD_VALUE) {
      isAllPresent(value)
    }
    if (name === CONFIRM_PASSWORD_VALUE || name === NEW_PASSWORD_VALUE) {
      if (value === passwordValues.newPassWord || value === passwordValues.confirmNewPassowrd) {
        setErrors(prevState => ({
          ...prevState,
          confirmNewPass: true
        }))
      } else {
        setErrors(prevState => ({
          ...prevState,
          confirmNewPass: false
        }))
      }
    }
    if (name === OLD_PASSWORD_VALUE) {
      setOldPasswordError(false);
    }
    if (name === NEW_PASSWORD_VALUE || name === OLD_PASSWORD_VALUE) {
      if (value && (value === passwordValues.newPassWord || value === passwordValues.oldPassword)) {
        setoldPasswordNotSameError(true);
      } else {
        setoldPasswordNotSameError(false);
      }
    }
    setPasswordValues({ ...passwordValues, [name]: value })
  }
  const getSubmitButtonActive = () => {
    const access = Object.values(errors).every((value) => value === true);
    return access;
  }

  const submitResetPassword = async () => {
    const flag = getSubmitButtonActive();
    if (flag) {
      const reqBody = {
        oldPwd: passwordValues.oldPassword,
        newPwd: passwordValues.newPassWord
      }
      const url = ApiEndPoint.changePassword.replace('{USER-ID}', permissions.hostId)
      const apiResponse = await postMethod(url, '', reqBody);
      if (apiResponse.status === 200) {
        keyCloakConfig.logout();
        dispatch(fetchLoginTime(new Date()));
      } else if (apiResponse.status === 400) {
        setOldPasswordError(true);
      } else {
        console.error(apiResponse);
      }
    }
  }

  const getUserInfo = async () => {
    const apiResponse = await getMethod(ApiEndPoint.getUserInfo, '')
    if (apiResponse.status === 200) {
      setUserInfo(apiResponse.data)
    } else {
      console.error(apiResponse);
    }
  }

  const setErrorClass = (errorType: any) => {
    return !errorType ? "circle" : " circle active";
  }


  useEffect(() => {
    getUserInfo();
  }, []);


  return (
    <>
      <main className={classes.content} data-testid="change_password">
        <div className="mid_section_inside change_password">
          <div className="information_section mt-0">
            <Grid container spacing={2}>
              <Grid className="mid_section_left" item xs={12}>
                <Card variant="outlined" className="basic_card">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <div className="info_detail">
                          <div className="tb_info_section mt-0">
                            <div className="user_img">
                              {!userInfo.photoReference ? <span className={`user_start_name ${userInfo.firstName ? getFirstLetterColor(userInfo.firstName.charAt(0).toUpperCase()) : "green"}`}>
                                {userInfo?.firstName?.charAt(0)?.toUpperCase() || 'U'}</span> :
                                <img
                                  src={userInfo.photoReference}
                                  alt={t("common.users")}
                                />}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className="info_detail">
                          <div className="tb_info_section mt-0">
                            <div className="label">
                              {toUpper(t("common.username"))}
                            </div>
                            <div className="result">{userInfo.firstName} {userInfo.lastName}</div>
                          </div>
                          <div className="tb_info_section">
                            <div className="label">
                              {toUpper(t("common.contact"))}
                            </div>
                            <div className="result">
                              <span className="label">{t("common.email")}</span>
                              <span className="result">
                                {userInfo.email || "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className="info_detail">
                          <div className="tb_info_section mt-0">
                            <div className="label">
                              {toUpper(t("common.language"))}
                            </div>
                            <div className="result">{t("common.english")}</div>
                          </div>
                          <div className="tb_info_section">
                            <div className="label">
                              {toUpper(t("common.date"))}
                            </div>
                            <div className="result">
                              <span className="label">{t("common.login")}</span>
                              <span className="result">{userInfo.lastLoginTime ? getFormattedDateTimeWithTZ(userInfo.lastLoginTime) : '-'}</span>
                            </div>
                            <div className="result">
                              <span className="label">
                                {t("common.creationdate")}
                              </span>
                              <span className="result">{userInfo.createdTimestamp ? getFormattedDateTimeWithTZ(userInfo.createdTimestamp) : '-'}</span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <div className="blink-account">
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div className="section_group">
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className="form_box">
                                  <div className={`form_col-12 ${oldPasswordError && 'error'}`}>
                                    <TextField
                                      type={oldVisbile ? "text" : "password"}
                                      placeholder={t("common.oldpassword")}
                                      name="oldPassword"
                                      value={passwordValues.oldPassword}
                                      onChange={handleInputChange}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              className="password_view_icon"
                                              onClick={() => setOldVisible(!oldVisbile)}
                                              aria-label="toggle password visibility"
                                              edge="end"
                                            >
                                              {oldVisbile ? <VisibilityIcon className="active_icon" /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    {oldPasswordError && <span className="error-text">{t("common.errorOldPassword")}</span>}
                                  </div>
                                  <div className={`form_col-12 ${oldPasswordNotSameError && 'error'}`}>
                                    <TextField
                                      type={newVisbile ? "text" : "password"}
                                      placeholder={t("common.newpassword")}
                                      name="newPassWord"
                                      value={passwordValues.newPassWord}
                                      onChange={handleInputChange}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              className="password_view_icon"
                                              aria-label="toggle password visibility"
                                              onClick={() => setNewVisible(!newVisbile)}
                                              edge="end"
                                            >
                                              {newVisbile ? <VisibilityIcon className="active_icon" /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    {oldPasswordNotSameError && <span className="error-text">{t("common.newPasswordCannotBeSameAsOld")}</span>}
                                  </div>
                                  <div className="clearfix"></div>
                                  <div className="form_col-12">
                                    <TextField
                                      type={confirmNewVisbile ? "text" : "password"}
                                      placeholder={t("common.confirmpassword")}
                                      value={passwordValues.confirmNewPassowrd}
                                      onChange={handleInputChange}
                                      name="confirmNewPassowrd"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              className="password_view_icon"
                                              aria-label="toggle password visibility"
                                              edge="end"
                                              onClick={() => setConfirmNewVisible(!confirmNewVisbile)}
                                            >
                                              {confirmNewVisbile ? <VisibilityIcon className="active_icon" /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div className="clearfix"></div>
                                </div>
                                <Button className={`block_btn ${(!getSubmitButtonActive()) || (oldPasswordError) || (oldPasswordNotSameError) ? 'disable' : ''}`} disabled={(!getSubmitButtonActive()) || (oldPasswordError) || (oldPasswordNotSameError) } onClick={submitResetPassword}>
                                  {t("common.changePasswordButton")}
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <div className="form_col-12 listing-items">
                                  <List>
                                    <ListSubheader>
                                      {t("common.passwordrequirements")}
                                    </ListSubheader>
                                    <ListItem>
                                      <span className={setErrorClass(errors.atleastEight)}></span>
                                      {t("common.atleastcharacters")}
                                    </ListItem>
                                    <ListItem>
                                      <span className={setErrorClass(errors.oneUpperCase)}></span>
                                      {t("common.uppercasecharacter")}
                                    </ListItem>
                                    <ListItem>
                                      <span className={setErrorClass(errors.oneLowerCase)}></span>
                                      {t("common.lowercasecharacter")}
                                    </ListItem>
                                    <ListItem>
                                      <span className={setErrorClass(errors.oneNumeric)}></span>
                                      {t("common.numericcharacter")}
                                    </ListItem>
                                    <ListItem>
                                      <span className={setErrorClass(errors.oneSpecialChar)}></span>
                                      {t("common.specialcharacter")}
                                    </ListItem>
                                    <ListItem>
                                      <span className={setErrorClass(errors.confirmNewPass)}></span>
                                      {t("common.confirmnewpassword")}
                                    </ListItem>
                                  </List>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </>
  );
};
export default ChangePassword;
