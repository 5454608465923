import React from "react";
import Images from "assets/images";
import { useTranslation } from "react-i18next";
import { lowerCase, toUpper, upperFirst } from "lodash";
import {
    BLANK_DATA_PLACEHOLDER,
    CheckAccess,
    claims,
    defaultVariable,
    fieldDataCheck,
    getFormattedDateTimeWithTZ,
    keyCloakConfig,
    renderCellExpand,
    rolePermissionCheck
} from "common";
import { Button, IconButton } from "@material-ui/core";

export const MemberRequestColumnsComponent = () => {
    const { t } = useTranslation();
    
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const updateAccessForMembership = CheckAccess([claims.MEMBERSHIP_UPDATE]) || isDelegate;

    return [
        {
            field: "firstCell",
            headerName: "firstcell",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "email",
            headerName: toUpper(t("common.email")),
            headerClassName: "action_columns",
            sortable: true,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "name",
            headerName: toUpper(t("common.name")),
            headerClassName: "action_columns",
            sortable: true,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "phone",
            headerName: toUpper(t("common.phone")),
            headerClassName: "action_columns",
            sortable: true,
            minWidth: 110,
            flex: 0.1,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                    </>
                );
            }
        },
        {
            field: "requested_date",
            headerName: "membership.requesteddate",
            headerClassName: "action_columns",
            sortable: true,
            minWidth: 110,
            flex: 0.1,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (<div>{getFormattedDateTimeWithTZ(cellValues.value)}</div>
                );
            },
        },
        {
            field: "status",
            headerName: toUpper(t("common.status")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <Button className={`table_action_btn ${cellValues.value === defaultVariable.MEMBER_STATUS.declined ? 'rejected_btn' : 'pending_btn'} `}>{upperFirst(lowerCase(cellValues.value))}</Button>
                );
            },
        },
        {
            field: "edit",
            headerName: "",
            headerClassName: "empty",
            sortable: false,
            cellClassName: "",
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            align: "right",
            hide: !updateAccessForMembership,
            renderCell: () => {
                return <div>
                    <IconButton className="top_action_btn">
                        <img
                            src={
                                Images.ic_edit_highlight
                            }
                            alt={t("common.edit")}
                        />
                    </IconButton>
                </div>;
            },
        },

        {
            field: "lastCell",
            headerName: "common.lastcell",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ]
}

export const HostMemberUserGroupList = () => {
    const { t } = useTranslation();
    
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const updateAccessForMembership = CheckAccess([claims.MEMBERSHIP_UPDATE]) || isDelegate;

    return [
        {
            field: "firstCell",
            headerName: "firstcell",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "email",
            headerName: toUpper(t("userlistcsv.userName")),
            headerClassName: "action_columns",
            sortable: true,
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "userName",
            headerName: toUpper(t("common.name")),
            headerClassName: "action_columns",
            sortable: true,
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "status",
            headerName: toUpper(t("common.status")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true
        },
        {
            field: "edit",
            headerName: "",
            headerClassName: "empty",
            sortable: false,
            cellClassName: "",
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            align: "right",
            hide: !updateAccessForMembership,
            renderCell: () => {
                return <div>
                    <IconButton className="top_action_btn">
                        <img
                            src={
                                Images.ic_close
                            }
                            alt={t("common.edit")}
                        />
                    </IconButton>
                </div>;
            },
        },

        {
            field: "lastCell",
            headerName: "common.lastcell",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ]
}


