import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Card, CardContent, Grid, ListItem} from "@material-ui/core";
import CommonGoogleMap from "../../../components/commonGoogleMap";
import { CheckAccess, claims, depotGoogleMap} from "../../../common";
import Images from "../../../assets/images";
import '../evseLocations.scss';
import CommonComposedChart from "../../../components/commonComposedChart";
import StatusButton from "../../../components/statusButton";
import {chartRendered, statusCheck} from "../evseLocationsHelper";
import {useTranslation} from "react-i18next";
import {getLocationChargeHistory} from "../../../services/evseLocationsService";


const Location = (props: any) => {
    let {location} = props;
    const { t } = useTranslation();
    const linkStyle = { textDecoration: 'none' };
    const  [powerConsumption, setPowerConsumption] = useState<any>([]);

    useEffect(() => {
         if(location?.locationId) {
             getLocationPowerConsumption();
         }
    }, [location]);


    const getLocationPowerConsumption = () => {
        let params = {id:location.locationId};
        getLocationChargeHistory(params).then((res: any) => {
            if(res) {
                setPowerConsumption(res);
            }
        }).catch((error: any)=>{
            console.log(error)
        })
    };

    return (
        <NavLink key={location?.locationId} to={`/evseLocations/evseDepotDetail?locationId=${location.locationId}#0`} style={linkStyle}>
            <ListItem key={location?.locationId}>
                <Card className="list_card" variant="outlined">
                    <CardContent className="card_content">
                        <div className="depot_details">
                            <Grid container>
                                <Grid item xs={9} sm={10} md={9} lg={9}>
                                    <div className="depot_information">
                                        {/*----EVSE Depot Map----*/}
                                        <div className="depot_location_map">
                                            <CommonGoogleMap
                                                {...props}
                                                center={{lat: location.address.latitude, lon: location.address.longitude,}}
                                                zoom={depotGoogleMap.zoom}
                                                options={props?.defaultMapOptions}
                                                markers={[{lat: location.address.latitude, lon: location.address.longitude,}]}
                                            />
                                        </div>

                                        {/*----EVSE Depot Information----*/}
                                        <div className="depot_info">
                                            <div className="depot_name">{location.locationName}</div>
                                            <div className="depot_addr">
                                                {location.address.addressLine1 ? location.address.addressLine1 : ""}{" "}
                                                {location.address.addressLine2 ? ", " + location.address.addressLine2 : ""}
                                                <br/>
                                                {location.address.city + ", " + location.address.state + " " + location.address.postalCode + ", " + location.address.country}
                                            </div>
                                            <div className="port_details">
                                             <span className="port portDetailsEVSE">
                                                <img src={Images.IC_PORT_12x14} alt="Depot Port"/>
                                                 {location.chargingStations} {t("common.station", {count: location.chargingStations})}{", "}
                                                 {location.totalPorts} {t("common.port", {count: location.totalPorts})}
                                             </span>
                                             {
                                               /* <span className="alert">
                                                     {location.chargingStations} {t("common.station", { count: location.chargingStations })}
                                                     </span> */
                                             }
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid className="depot_graph text_right" item xs={3} sm={2} md={3} lg={3}>
                                    { powerConsumption && powerConsumption.length > 0 && chartRendered(powerConsumption) &&
                                        <CommonComposedChart data={powerConsumption}/>
                                    }
                                </Grid>
                            </Grid>

                            {/*----EVSE Depot Status----*/}
                            <div className="stat_list ">
                                <StatusButton classType="available" statusLabel="Available" statusValue={location.availablePorts}/>
                                <StatusButton classType="connected" statusLabel="Connected" statusValue={location.connectedPorts}/>
                                {CheckAccess(claims.FORBIDDEN_ACCESS) &&
                                  <StatusButton classType="reserved" statusLabel="Reserved" statusValue={statusCheck(location.reservedPorts)}/>
                                }
                                <StatusButton classType="charging" statusLabel="Charging" statusValue={location.chargingPorts}/>
                                <StatusButton classType="complete" statusLabel="Complete" statusValue={location.completedPorts}/>
                                <StatusButton classType="fault" statusLabel="Fault/Error" statusValue={location.errorPorts}/>
                                <StatusButton classType="offline" statusLabel="Offline" statusValue={location.offlinePorts}/>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </ListItem>
        </NavLink>)
};
export default Location;