import React, { useState, useEffect } from "react";
import {
    Select,
    MenuItem,
    Grid,
    Button,
    DialogContentText,
    DialogContent,
    Dialog,
    TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { defaultVariable, LabelInfo } from "common";
import { useForm } from "react-hook-form";
import { postMethod, ApiEndPoint, getMethod } from "services";
import { NewBlinkCardDetails } from "common/interfaces";

const RequestBlinkCardInfo = (props: any) => {
    const { t } = useTranslation();
    const [countryList, setCountryList] = useState<any>([]);
    const [countryCode, setCountryCode] = useState<any>(defaultVariable.defaultCountry);
    const [stateList, setStateList] = useState<any>([]);
    const [stateCode, setStateCode] = useState<any>('');
    const defaultValues = {
        requestAddress: {
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            city: '',
            country: countryCode,
            state: stateCode,
        },
        notes: '',
        numberOfCards: 1
    }

    const { register, reset, handleSubmit, formState: { errors } } = useForm<NewBlinkCardDetails>({
        defaultValues: defaultValues
    });

    const getcountryList = async () => {
        await getMethod(ApiEndPoint.countryList, '').then((res) => {
            if (res?.data) {
                setCountryList(res?.data)
            }
        }).catch((err) => {
            console.error("Error", err)
        })
    }

    useEffect(() => {
        getcountryList();
    }, []);

    const getStateList = async () => {
        await getMethod(ApiEndPoint.stateList, '?countryCode=' + countryCode).then((res) => {
            if (res?.data) {
                setStateList(res?.data)
            }
        }).catch((err) => {
            console.error("Error", err)
        })
    }

    useEffect(() => {
        getStateList()
    }, [countryCode]);


    const handleDropdownChange = (e: any) => {
        let findValue = countryList?.countries?.find((val: any) => val?.code === e.target.value)
        if (findValue) {
            setCountryCode(findValue?.code)
        }
    };

    const handleStateChange = (e: any) => {
        let findValue = stateList?.states?.find((val: any) => val?.name === e.target.value)
        if (findValue) {
            setStateCode(findValue?.name)
        }
    };

    const resetForm = () => {
        props.transactionDetailsClose(true)
        reset(defaultValues)
    }

    const submitEditForm = (data: any, e: any) => {
        data.requestAddress.state = stateList?.states?.find((val: any) => val?.name === data?.requestAddress?.state)?.code;
        postMethod(ApiEndPoint.requestBlinkCard, '', [data])
            .then((res) => {
                if (res?.status === 200) {
                    resetForm()
                }
            })
            .catch((err) => {
                console.error('error', err);
            });
    };

    return (
        <>
            <div className="dialog_box">
                <Dialog className="dialog_container edit_detail_section edit-user-info updated_pop_up sm dialog_container_center" open={props.openTransactionDetails} onClose={resetForm} scroll={"paper"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                    <form onSubmit={handleSubmit(submitEditForm)}>
                        <div className="dialog_title">
                            <h4>{t("common.requestnewblinkcard")}</h4>
                            <div className="button_row mt-0 full_right">
                                <Button className="btn_white text_btn_theme" onClick={resetForm}> {t("common.cancelbutton")} </Button>
                                <Button type="submit" className="btn_white blue_bg"> {t("common.send")} </Button>
                            </div>
                        </div>
                        <DialogContent>
                            <DialogContentText id="scroll-dialog-description">
                                <div className="defult_form mt-10">
                                    <div className="section_group">
                                        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                            <Grid item xs={12}>
                                                <div className={`form_col ${errors?.numberOfCards && 'error'}`}>
                                                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                                        <Grid item xs={6}>
                                                            <LabelInfo name={t("common.quantity")} isMandatory={true} />
                                                            <TextField
                                                                variant="outlined"
                                                                className="error"
                                                                type="number"
                                                                {...register("numberOfCards", { required: true, min: 1, max: 99 })}
                                                            />
                                                            {errors?.numberOfCards && <span className="error_msg">{t("blinkcard.requestinfo.blinkcardquantity.required")}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col mt-40">
                                                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6}>
                                                        <div className={`form_col ${errors?.requestAddress?.addressLine1 && 'error'}`}>
                                                            <LabelInfo name={t("common.addressline1")} isMandatory={true} />
                                                            <TextField
                                                                variant="outlined"
                                                                className="error"
                                                                {...register("requestAddress.addressLine1", { required: true })}
                                                            />
                                                            {errors?.requestAddress?.addressLine1 && <span className="error_msg">{t("blinkcard.requestinfo.addressline1.required")}</span>}
                                                        </div>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <LabelInfo name={t("common.addressline2")} isMandatory={false} />
                                                            <TextField
                                                                variant="outlined"
                                                                {...register("requestAddress.addressLine2")}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6}>
                                                        <div className={`form_col ${errors?.requestAddress?.country && 'error'}`}>
                                                            <LabelInfo name={t("common.country")} isMandatory={true} />
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                variant="outlined"
                                                                value={defaultValues.requestAddress.country ?? " "}
                                                                {...register("requestAddress.country", { required: true, onChange: handleDropdownChange })}
                                                            >
                                                                {countryList?.countries?.map((value: any) => (
                                                                    <MenuItem
                                                                        className="defult_select_option"
                                                                        key={value.id}
                                                                        value={value.code}
                                                                    >
                                                                        {value.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors?.requestAddress?.country && <span className="error_msg">{t("blinkcard.requestinfo.country.required")}</span>}
                                                        </div>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                        <div className={`form_col ${errors?.requestAddress?.country && 'error'}`}>
                                                            <LabelInfo name={t("common.state")} isMandatory={true} />
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                variant="outlined"
                                                                value={defaultValues.requestAddress.state ?? " "}
                                                                {...register("requestAddress.state", { required: true, onChange: handleStateChange })}
                                                            >
                                                                {stateList?.states?.length > 0 ? stateList?.states?.map((value: any) => (
                                                                    <MenuItem
                                                                        className="defult_select_option"
                                                                        key={value.id}
                                                                        value={value.name}
                                                                    >
                                                                        {value.name}
                                                                    </MenuItem>
                                                                ))
                                                                : <MenuItem className="defult_select_option">
                                                                    {t('common.norecord')}
                                                                </MenuItem>  
                                                            }
                                                            </Select>
                                                            {errors?.requestAddress?.state && <span className="error_msg">{t("blinkcard.requestinfo.state.required")}</span>}
                                                            </div>

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6}>
                                                        <div className={`form_col ${errors?.requestAddress?.postalCode && 'error'}`}>
                                                            <LabelInfo name={t("common.city")} isMandatory={true} />
                                                            <TextField
                                                                variant="outlined"
                                                                className="error"
                                                                {...register("requestAddress.city", { required: true })}
                                                            />
                                                            {errors?.requestAddress?.city && <span className="error_msg">{t("blinkcard.requestinfo.city.required")}</span>}
                                                        </div>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                        <div className={`form_col ${errors?.requestAddress?.postalCode && 'error'}`}>
                                                            <LabelInfo name={t("common.zipcode")} isMandatory={true} />
                                                            <TextField
                                                                variant="outlined"
                                                                className="error"
                                                                {...register("requestAddress.postalCode", { required: true })}
                                                            />
                                                            {errors?.requestAddress?.postalCode && <span className="error_msg">{t("blinkcard.requestinfo.zipcode.required")}</span>}
                                                        </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>
        </>
    );
};
export default RequestBlinkCardInfo;