import { ILabel, ObjectDetailsField, ObjectDetails, ErrorOnBlur } from "./interfaces";
import { requiredSign, defaultVariable, BLANK_DATA_PLACEHOLDER, dateFormats, WATTS_VALUE, ZERO_VALUE } from "common";
import React from "react";
import { toUpper } from "lodash";
import { t } from "i18next";
import moment from "moment-timezone";
import DataGridCellExpand from "components/dataGridCellExpand";
import getSymbolFromCurrency from "currency-symbol-map";

export const showProgressBar = (value: number) => {
    let statusClass = '';
    if (value) {
        if (value > 0 && value <= 20) {
            statusClass = 'battery_low';
        } else if (value > 20 && value <= 80) {
            statusClass = 'battery_avg';
        } else if (value > 80 && value <= 100) {
            statusClass = 'battery_full';
        }
    }
    return statusClass;
};

export  const distanceToMouse = (pt: any, mp: any) => {
    if (pt && mp) {
        // return distance between the marker and mouse pointer
        return Math.sqrt(
            (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
    }
};

export function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

export const fieldDataCheck = (cellValues: any, returnCellValue: string) => {
    let returnValue = returnCellValue;
    if (cellValues?.value !== undefined && cellValues?.value !== '' && cellValues?.value !== null) {
        return cellValues.value;
    }
    return returnValue;
};

export const LabelInfo = (props: ILabel) => {
    const name = props.name;
    const isMandatory = props.isMandatory;
    let notes = '';
    if(props?.notes){
     notes = props?.notes;
    }

    let mandatory = "";

    if (isMandatory === true) {
        mandatory = requiredSign.important;
    }

    return (
        <>
            <label>
                {name} {mandatory} <span className="sub_label">{notes}</span>
            </label>
        </>
    );
}
export const isStationCheck = (cellValues: string, portValue: string , serialValue: string) => {
    let returnValue = cellValues + '-' + portValue;
    if(!cellValues){
        return serialValue + '-' + portValue
    }
    return returnValue;
};

export const timeCheck = (cellValues: string, timeZone: string, returnCellValue: string) => {
    let returnValue = returnCellValue;
    if(cellValues){
        returnValue = timeZone ?  (cellValues + ' ' + timeZone) : cellValues;
    }
    return returnValue;
};

export const isMultipleLabelCheck = (totalCount:number , singleLabel:string, multiLabelValue:string) => {
    let returnLabel = singleLabel;
    let returnmultiLable = multiLabelValue;
    if(totalCount && totalCount > 1) {
        return returnmultiLable;
    }
    return returnLabel;
};

export const chargeSessionInformation = (cellValues: any, returnCellValue: string) => {
    let returnValue = returnCellValue;
    if(cellValues){
        return cellValues 
    }
    return returnValue;
};

export const bytesToSize = (bytes: number, t: (val:any)=>void) => {
  const oneByte = 1024;
  const sizes = [ t("common.size.bytes"), t("common.size.kb"), t("common.size.mb"), t("common.size.gb") ];
  const total = Math.floor(Math.log(bytes) / Math.log(oneByte));

  return (
    parseFloat((bytes / Math.pow(oneByte, total)).toFixed(2)) +
    " " +
    sizes[total]
  );
};

export const ColumnNameTranslate = (columnsList: Array<ObjectDetailsField>) => {
    let columnsData = columnsList;
    if (columnsList && columnsList?.length > 0) {
        columnsData = columnsList.map((val: ObjectDetailsField) => ({
            ...val,
            headerName: toUpper(t(val.headerName || val.field)),
            renderCell: (val?.renderCell ? val.renderCell : renderCellExpand)
        }))
    }
    return columnsData;
}

export const viewLabelInfo = (label:string, value:string, exClass:string, isUpperCase?:boolean ) => {
    return (
        <>
            <div className={"tb_info_section " + exClass}>
                <div className="label">{ isUpperCase ? toUpper(label) : label}</div>
                <div className="result">{value}</div>
            </div>
        </>
    );
};

export const labelMultiInfo = (headerLabel: string, value: any, exClass?: string, isUpperCase?:boolean) => {
    return (
        <>
            <div className={"tb_info_section " + exClass}>
                <div className="label">{ isUpperCase ? toUpper(headerLabel) : headerLabel }</div>
                {value.map((val: any) => (
                    <div className="result" key={val.label}>
                        <span className="label">{val.label}</span>
                        <span className="result">{val.value}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export const formatValueCheck = (value:any,formatType:any)=>{
    const setValue = '0' + formatType;
    return value.trim() === '' ? setValue : value.trim();
}

export const convertSecondToFormat = (sec: number, formatType?: string): string => {
    sec = Number(sec);
    let returnValue: string;
    const spaceValue = ' ';
    const blankValue = '';
    const h = Math.floor(sec / 3600);
    const m = Math.floor(sec % 3600 / 60);
    const s = Math.floor(sec % 3600 % 60);
    const hDisplay = h > 0 ? (h + t("common.hoursortvalue") + spaceValue) : blankValue;
    const mDisplay = m > 0 ? (m + t("common.minutesortvalue")) : blankValue;
    const sDisplay = s > 0 ? (spaceValue + s + t("common.secondsortvalue")) : blankValue;
    switch (formatType) {
        case "H":
            returnValue = formatValueCheck(hDisplay , t("common.hoursortvalue"));
            break;
        case "HM":
            returnValue = formatValueCheck(hDisplay + mDisplay , t("common.minutesortvalue"));
            break;
        case "HMS":
            returnValue = formatValueCheck(hDisplay + mDisplay + sDisplay , t("common.secondsortvalue"));
            break;
        default:
            returnValue = formatValueCheck(hDisplay + mDisplay + sDisplay , t("common.secondsortvalue"));
    }
    returnValue = returnValue ? returnValue : BLANK_DATA_PLACEHOLDER;
    return returnValue
}

export const setAddressFormat = (address: ObjectDetails) => {
    let returnAddress = defaultVariable.BLANK_DATA_PLACEHOLDER
    if(address) {
    const { addressLine1, addressLine2, city, state, postalCode, country } = address;
    const formatCommaSpace = ", "
    const formatSpace = " "
    returnAddress =  (
        <>
            {addressLine1 + (addressLine2 ? formatCommaSpace + addressLine2 : formatSpace)}
            <br />
            {city + formatCommaSpace + state + formatSpace + postalCode + formatCommaSpace + country}

        </>
    )}
    return returnAddress;

}

//This Function is use to highlight error on text fields on blur with simple-react-validator library
export const handleErrorOnBlur = (params : ErrorOnBlur) => {

    // Below statement is calling the library function it will get the message if any error is there
    params.validator.current.showMessageFor(params.fieldName);
    
    //Below conditions are updating class as error/non-error to highlight/unhiglight the div 
    if (params.validator.current.errorMessages[params.fieldName] === null){
        params.e.target.closest('.form_col-12').classList.remove("error-show");
    }
    else
    {
        params.e.target.closest('.form_col-12').classList.add("error-show");
    }
};

/******************
 * This function validate the string data for null , undefined and empty  
 * on valida data return true else false
******************/
export const isUrlParamEmpty = (parameter:string) => {
    if (parameter !== '' && parameter !== null && parameter !== undefined) {
      return true;
    }
    else
    {
      return false;
    }
};

export const HandlePaginationLabel = (totalCount: number, pageSize: number, currentPage: number, pageLabel: string) => {
    let setPagelabel = '';
    if (totalCount > 0) {
        let conditionCheck = (pageSize * (currentPage + 1));
        let pageStartValue = ((pageSize * currentPage) + 1);
        let pageEndValue = (conditionCheck < totalCount) ? conditionCheck : totalCount;
        let pageLabelTranslate = t(pageLabel, { count: totalCount });
        setPagelabel = t("common.pagination.footerlabel", { startValue: pageStartValue, endValue: pageEndValue, totalValue: totalCount, footerLabel: pageLabelTranslate });
    }
    return setPagelabel;
}

export const isCurrencyValueCheck = (currencyType: string, amountValue: number) => {
    let returnAmountValue = BLANK_DATA_PLACEHOLDER;
    const currValue = currencyType ? currencyType + " " : "";
    returnAmountValue = amountValue ? currValue + amountValue : BLANK_DATA_PLACEHOLDER;
    return returnAmountValue;
}
export const isFieldValueCheck = (value: any, returnCellValue: any = BLANK_DATA_PLACEHOLDER) => {
    let returnValue = returnCellValue;
    if (value !== undefined && value !== '' && value !== null) {
        returnValue =  value;
    }
    return returnValue;
};
export const rolePermissionCheck = (availableRolesPermission: any, verifyRolePermission: any) => {
    let response = false;

    verifyRolePermission.forEach((val:any)=>{
        if(availableRolesPermission && availableRolesPermission?.includes(val))
        {
            response = true;
        }
    })

    return response;
};
export const checkLocationPermission = (userRoles: any, locationPermission: any, rolesToVerify: any, permissionToVerify: any, CompareLocationId: any) => {
    let status = false;
    // if role exist for restriction then verify its permission else allow 
    if (rolePermissionCheck(userRoles, rolesToVerify)) { 
        if(locationPermission !== undefined){
        locationPermission.forEach((val: any) => {
            if (val.locationId === CompareLocationId) {
                    if (rolePermissionCheck(val?.permissions, permissionToVerify)) {
                        status = true;
                    }
            }
        })}
    } else {
        status = true;
    }
    return status;
}


export const getFirstLetterColor = (letter: any) => {
    let classColor = "user_name-dark-blue"
    if (/[a-fA-F- ]+/.test(letter)) {
      classColor = "user_name-sky-blue"
    } 
    if (/[g-jG-J- ]+/.test(letter)) {
      classColor = "user_name-opaq-green"
    } 
    if (/[k-nK-N- ]+/.test(letter)) {
      classColor = "user_name-dark-green"
    } 
    if (/[o-rO-R- ]+/.test(letter)) {
      classColor = "user_name-ligh-green"
    } 
    if (/[s-uS-U- ]+/.test(letter)) {
      classColor = "user_name-grey"
    } 
    if (/[w-xW-X- ]+/.test(letter)) {
      classColor = "user_name-dark-grey "
    } 
    if (/[y-zY-Z- ]+/.test(letter)) {
      classColor = "user_name-darkish-grey"
    } 

    return classColor;
  }
export const getPermitedLocation = (userRoles: any, locationPermission: any, rolesToVerify: any, permissionToVerify: any) => {
    let locations: any = [];
    // if role exist for restriction then verify its permission else allow 
    if (rolePermissionCheck(userRoles, rolesToVerify)) {
        if (locationPermission !== undefined) {
            locationPermission.forEach((val: any) => {
                if (rolePermissionCheck(val?.permissions, permissionToVerify)) {
                    locations.push(val?.locationId);
                }
            })
        }
    }
    return locations;
}

export const getFormattedDateTimeWithTZ = (date: any, format: any = dateFormats.defaultDateTimeFormat) => {
      if(!date) {return BLANK_DATA_PLACEHOLDER};
      const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone
      return  moment.utc(date).tz(timeZoneString).format(format)
}

export const getFormattedDateTimeWithApiTimezone = (date: any, format: any = dateFormats.defaultDateTimeFormat) => {
    if (!date) {
        return BLANK_DATA_PLACEHOLDER
    } else {
        let dateFinal = date;
        let timezonePositionLeft = date?.indexOf("[");
        let timezonePositionRight = date?.indexOf("]");
        if (timezonePositionLeft > 0 && timezonePositionRight > 0 && timezonePositionRight > timezonePositionLeft) {
            let timeZoneString = date.substring((timezonePositionLeft + 1), timezonePositionRight);
            dateFinal = moment(date.substring(0, timezonePositionLeft)).tz(timeZoneString).format(format)
        }
        return dateFinal;
    }
}

export const isStationEmpty = (stationName: string, serialNumber: string) => {
    let returnValue = stationName;
    if(!stationName){
        returnValue = serialNumber;
    }
    return returnValue;
};

export const dateDiffGenerate = (startDate: any, endDate: any) => {
    let returnDiff = 0;
    let difftime = Math.abs(startDate - endDate)
    returnDiff = Math.ceil(difftime / (1000 * 60 * 60 * 24))
    return returnDiff;
}

export const renderCellExpand = (params: any, renderItem?: any, valueOverflow?:boolean, setStyle?: string) => {
    return (
        <DataGridCellExpand 
            value={params.value ? params.value.toString() : BLANK_DATA_PLACEHOLDER}
            width={params.colDef.computedWidth}
            render={renderItem}
            itmeOverflow={valueOverflow}
            setStyle={setStyle}
        />
    )
}

export const notAllowSpace = (event: any) => {
    if (event.key === " ") {
        return ( event.preventDefault() );
    }
}

export const conversionWattsToKw = (wattsValue:any, kwType: any) => {
    if (!wattsValue && wattsValue !== 0) {
        return BLANK_DATA_PLACEHOLDER;
    }else {
        let kwhValue = Number(wattsValue) >= 0 ? (wattsValue/WATTS_VALUE) : wattsValue;
        return (kwhValue.toFixed(2) + ' ' + kwType) ;
    }
};

export const findMaxArrayObjectValue = (valueList:any, fieldName:any ) => {
    let returnValue = ZERO_VALUE;
    if(valueList && valueList?.length > ZERO_VALUE ){
        returnValue = Math.max(...valueList.map((value:any) => value[fieldName]),ZERO_VALUE);
    }
    return returnValue
}

export const checkFloatValue = (event: any) => {
  let floatRegex = /^\d*\.?\d{0,2}$/;
  if (!floatRegex.test(event?.target?.value)) {
    event.preventDefault();
  } else {
    return true;
  }
};

 export const checkTwoDigitBeforeAfterDecimal = (event: any) => { 
     let floatRegex = /^\d{0,2}(\.\d{0,2})?$/;
     if(!floatRegex.test(event?.target?.value)) {
          event.preventDefault();
     }  
     else{
        return true;
     }
             
  };

 
export const isHoursOperation = (start: string) => {
    let dateFormat = moment(start, dateFormats.chargingTimeFormat).format(
      dateFormats.check12HourFormat
    );
    if (dateFormat && dateFormat.length === 3) {
      dateFormat = 0 + dateFormat;
    }

    dateFormat = dateFormat.slice(0, 2) + ":00 " + dateFormat.slice(2);
    if (dateFormat && dateFormat.charAt(0) === "0") {
      dateFormat = dateFormat.substring(1);
    }

    return dateFormat;
  };

 export const convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    let actualTime = `${hours}:${minutes}`;
    if (actualTime && actualTime.length === 4) {
      actualTime = 0 + actualTime;
    }
    return actualTime;
  };

 export const currencyAmountValidate = (currency: any, amount: any) => {
    const upperRegex = /^(?=.*[A-Z])/;
    amount = Number(amount);
    if (currency !== null) {
        currency = currency?.toUpperCase();
        if (upperRegex.test(currency)) {
            const fetchCurrencySymbol = getSymbolFromCurrency(currency);
            currency = fetchCurrencySymbol !== undefined ? fetchCurrencySymbol : currency;
        }
        if (amount < 0) {
            amount = Math.abs(amount);
            amount = amount.toFixed(2);
            return currency + " " + `(${amount})`;
        }
        if (amount >= 0) {
            amount = amount.toFixed(2);
            return currency + " " + amount;
        }
    } else {
        if (amount < 0) {
            amount = Math.abs(amount);
            amount = amount.toFixed(2);
            return `(${amount})`;
        }
        if (amount >= 0) {
            amount = amount.toFixed(2);
            return amount;
        }
    }
};

const FLEET_EFFICIENCY = "fleet.rangecaps";

export const columnNameTranslate = (columnsList: Array<any>) => {
    let columnsData = columnsList;

    if (columnsList && columnsList?.length > 0) {
        columnsData = columnsList.map((val: ObjectDetails) => ({
            ...val,
            headerName: (val.headerName === FLEET_EFFICIENCY) ? t(FLEET_EFFICIENCY) : toUpper(t(val.headerName)),
            renderCell: (val?.renderCell ? val.renderCell : renderCellExpand)
        }))
    }
    return columnsData;
}

export const validateError = (validatorObj: ObjectDetails, fieldName: string) => {
    if (validatorObj.current.fields[fieldName] === false && validatorObj.current.messagesShown === true) {
        return "global_error_msg";
    }
    else {
        return "";
    }
};

export const checkWholeNumberValue = (event: any) => {
    if (!/\d/.test(event.key)) {
        event.preventDefault();
    }
};
export const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    let style = {}
    switch (event.costSegment) {
        case 'Peak':
            return { style: { backgroundColor: 'rgba(255, 75, 156, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #FF489C' } };
        case 'Off-Peak':
            return { style: { backgroundColor: 'rgba(0, 185, 255, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #00B9FF' } };
        case 'Super-Peak':
            return { style: { backgroundColor: 'rgb(195, 101, 153, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #C365FD' } };
        case 'Standard':
            return { style: { backgroundColor: 'rgb(175, 134, 34, 0.2)', color: '#666666', fontSize: '10px', fontWeight: '500', borderRadius: '5px', border: '1px solid #af8625' } };
        default:
            return { style: style };
    }
}

const setEndTimeForDayOfWeek = (schedueldata: any) => {
    const checkWithTime = dateFormats.check12TimeFormat;
    const setWithTime = dateFormats.set12TimeFormat;

    return (schedueldata.endTime === checkWithTime ? setWithTime : schedueldata.endTime);
};

export const startOfWeekHelper = (moment: any, calendetFinalData: any[]) => {
        
    const eventData: any = [];
    const dateFormmat = dateFormats.dateFormmat;
    const startType = dateFormats.dayTypeFormat;

    let currentDate = moment();
    let weekStart = currentDate.clone().startOf('week');
    let monday = weekStart.format(dateFormmat);
    let tuesday = moment(weekStart).add(1, startType).format(dateFormmat);
    let wednesday = moment(weekStart).add(2, startType).format(dateFormmat);
    let thursday = moment(weekStart).add(3, startType).format(dateFormmat);
    let friday = moment(weekStart).add(4, startType).format(dateFormmat);
    let saturday = moment(weekStart).add(5, startType).format(dateFormmat);
    let sunday = moment(weekStart).add(6, startType).format(dateFormmat);

    calendetFinalData.forEach((val: any) => {
        if (val.day === 'MONDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(monday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(monday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,

                })
            })
        } else if (val.day === 'TUESDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(tuesday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(tuesday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,
                })
            })
        }
        else if (val.day === 'WEDNESDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(wednesday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(wednesday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,
                })
            })
        }
        else if (val.day === 'THURSDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(thursday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(thursday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,
                })
            })
        }
        else if (val.day === 'FRIDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(friday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(friday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,
                })
            })
        }
        else if (val.day === 'SATURDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(saturday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(saturday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,
                })
            })
        }
        else if (val.day === 'SUNDAY') {
            val?.schedule.forEach((schedueldata: any) => {
                schedueldata?.costSegment?.toLowerCase() !== 'standard' && eventData.push({
                    title: '',
                    start: new Date(moment(sunday + ' ' + schedueldata.startTime)),
                    end: new Date(moment(sunday + ' ' + setEndTimeForDayOfWeek(schedueldata))),
                    costSegment: schedueldata.costSegment,
                    costSegmentId: schedueldata.costSegmentId,
                    startDay: val.day,
                })
            })
        }
    });

    return eventData;
}
