import { AddPricing, Pricing } from "common/interfaces/pricingPlan";
import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import {
    timeOfDay, hybrid, pricingPlanTypes, CTEP_YES, CTEP_NO, CTEP_ENABLED, CTEP_NOT_ENABLED, DAY, setPricingPlanType,
    createDetailedViewArrayView, createDetailedViewArrayEdit, SIMPLE_VIEW, createTimeArrayDetailed, PARKING, ENERGY,
    preparePlanForDayTime, preparePlanForHybrid, prepareConfigSaveData, PRICING_PLAN_TYPES, getDurationBased, createDetailedViewObject, NAME, TYPE
} from "../pricingPlanHelpers";
import { addPricingplan, editPricingPlan, getLastInsertedPricing, checkPricingPlanName } from "services/pricingPlanService";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import { toLower } from "lodash";
import AddPricingPlanStepOne from "./addPricingPlanStepOne";
import AddPricingPlanStepTwo from "./addPricingPlanStepTwo";
import { BLANK } from "common";

const AddPricingPlan = (props: AddPricing) => {
    const { t } = useTranslation();
    const [, forceUpdate] = useState<any>();
    const dispatch = useDispatch();
    const simpleValidator: any = useRef(new SimpleReactValidator());
    let initialValue: Pricing = {
        name: "",
        description: "",
        ctep: props.ctepEnabled,
        type: "",
        billEnergy: false,
        durationBased: false
    };
    const [pricingDetails, setPricingDetails] = useState(initialValue);
    const [parkingFee, setparkingFee] = useState(true);
    const [energyFee, setEnergyFee] = useState(false);
    const [pricingPlanData, setPricingPlanData] = useState<any>('');
    const [parkingData, setParkingData] = useState<any>('');
    const [chargingData, setChargingData] = useState<any>('');
    const [createPlanSecondPopup, setCreatePlanSecondPopup] = useState(false);
    const [ctepValue, setCtepValue] = useState(CTEP_NOT_ENABLED);
    const [appliedValue, setAppliedvalue] = useState(DAY);
    const [showBillEnergy, setShowBillEnergy] = useState(true);
    const [savedData, setsavedData] = useState(false);
    const [savedParkingFee, setSavedParkingFee] = useState(false);
    const [lastSavedId, setLastSavedId] = useState("");
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [nameExists, setNameExists] = useState(false);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [pricingPlanFilteredType, setPricingPlanFilteredType] = useState([{}]);
    const [savedPricingType, setSavedPricingType] = useState("");
    const [view, setView] = useState(SIMPLE_VIEW);
    const [timeArrayDetail, setTimeArrayDetail] = useState([]);
    const [timeArrayDetailedKeys, setTimeArrayDetailedKeys] = useState([{}]);
    const [detailedViewobject, setDetailedViewobject] = useState<any>('');
    const [saveddetailedViewobject, setSavedDetailedViewobject] = useState<any>('');
    const [savedparkingFeeOnChange, setSavedParkingFeeOnChange] = useState(false);
    const [changedType, setChangedType] = useState(false);
    const [previousPricingTypeView, setPreviousPricingTypeView] = useState("");
    const [savedCtepValue, setsavedCtepvalue] = useState("");

    useEffect(() => {
        if (props.editPopup) {
            getPricingplanData();
            setDisableNextButton(false);
            setPricingPlanFilteredType(pricingPlanTypes);
        }
        else
            setsavedData(false);
        resetFormData();
        let timeArrayDetail = createTimeArrayDetailed();
        setTimeArrayDetail(timeArrayDetail);
        setparkingFee(true);
        setPricingPlanData("");
    }, [])

    const getPricingplanData = () => {
        let pricingPlanId = (savedparkingFeeOnChange && !props.editPopup)  ? lastSavedId : props.pricingPlanId;
        let params = { "pricingPlanId": pricingPlanId, "orgId": props.orgId }
        setSpinnerOff(true);
        editPricingPlan(params).then((res: any) => {
            if (res && res[0]) {
                setSpinnerOff(false);
                let pricingResponse = res[0];
                setEditPopupDetails(pricingResponse);                            
            }
        }).catch((err: any) => {
            setSpinnerOff(false);
            console.error("Error", err)
        })
    }

    const setEditPopupDetails = (pricingResponse: any) => {
        setPricingDetails({
            ...pricingDetails,
            name: pricingResponse.name,
            description: pricingResponse.description,
            type: pricingResponse.type,
            billEnergy: pricingResponse.bill_energy_first ? "1" : "",
            durationBased: getDurationBased(pricingResponse.charging_fee_type)
        });
        setSavedPricingType(pricingResponse.type);
        if (pricingResponse.ctep_enabled === CTEP_YES){
            setCtepValue(CTEP_ENABLED); 
            setsavedCtepvalue(CTEP_ENABLED); 
        }       
        else {
            setCtepValue(CTEP_NOT_ENABLED);
            setsavedCtepvalue(CTEP_NOT_ENABLED);
        }
        setAppliedvalue(pricingResponse.applied_to ? pricingResponse.applied_to : DAY);
        setsavedData(true);  
        getPricingPlanDataResponse(pricingResponse);  
    }

    const preparePlanOnTypeChange = (dayTime: boolean = false, parkingFee: boolean = false) => {
        let pricingData : any = [];
        if(dayTime && parkingFee) {
            pricingData = preparePlanForDayTime(parkingData, PARKING);
        }
        else if(dayTime && !parkingFee) {
            pricingData = preparePlanForDayTime(chargingData, ENERGY);
        }
        else if(!dayTime) {
            pricingData = preparePlanForHybrid(parkingData, pricingDetails.type)
        }
        return pricingData;
    }

    const getPricingPlanDataResponse = (pricingResponse:any) => {
        let parkingPricing: any = [];
        if (pricingResponse.type === PRICING_PLAN_TYPES.TIME_OF_DAY || pricingResponse.type === PRICING_PLAN_TYPES.DURATION) {
            parkingPricing = preparePlanForDayTime(pricingResponse.parking, PARKING);
            setSavedDetailedViewobject(pricingResponse.parking);
            setDetailedViewobject(pricingResponse.parking);
            setParkingData(pricingResponse.parking);
            setChargingData(pricingResponse.charging);
            setShowBillEnergy(true);
            let timeArrayDetailed = createTimeArrayDetailed();
            let detailedPricing = createDetailedViewArrayEdit(pricingResponse.parking, timeArrayDetailed);
            setTimeArrayDetail(detailedPricing);
        }
        else {
            parkingPricing = preparePlanForHybrid(pricingResponse.parking, pricingResponse.type);
            setParkingData(pricingResponse.parking);  
            setShowBillEnergy(false);
        }
        setPreviousPricingTypeView(pricingResponse.type);
        setPricingPlanData(parkingPricing.data);        
    }

    const getLastInsertedPricingData = () => {
        let params = { "orgId": props.orgId }
        getLastInsertedPricing(params).then((res: any) => {
            setLastSavedId(res);
        })
    }

    const resetFormData = () => {
        setPricingDetails(initialValue);
        setparkingFee(false);
        setEnergyFee(false);
        pricingPlanCollapse();
        simpleValidator.current.hideMessages();
        forceUpdate(0);
    };

    const pricingPlanCollapse = () => {
        let timeArray: any = [];
        timeArray = timeArrayDetail;
        if (timeArrayDetailedKeys?.length > 1) {
            timeArrayDetailedKeys.forEach((pricingExpand: any) => {
                timeArray[pricingExpand]['substatus'] = false;
                expandPricingPlan(timeArrayDetail);
            })
        }
    }

    const handleChange = (event: any, isPricingPlanType: boolean) => {
        let name = event.target.name;
        let value = event.target.value;
        setPricingDetails({ ...pricingDetails, [name]: value });
        setSavedParkingFeeOnChange(savedParkingFee);
        setparkingFee(true);
        setEnergyFee(false);
        setView(SIMPLE_VIEW);
        pricingPlanCollapse();     
        if (isPricingPlanType) {            
            if ((savedData ||savedParkingFee) && value === savedPricingType) {
                getPricingplanData();
                createPlanforPricingType(value);
                setSavedParkingFee(savedparkingFeeOnChange);
                setDetailedViewobject(saveddetailedViewobject);
                if (value === PRICING_PLAN_TYPES.TIME_OF_DAY || value === PRICING_PLAN_TYPES.DURATION) {
                    if (parkingData.day?.length > 0) {
                        let parkingPricing = preparePlanOnTypeChange(true, true);
                        setPricingPlanData(parkingPricing.data);
                    }
                    setShowBillEnergy(true);
                }
                else {
                    let parkingPricing = preparePlanOnTypeChange(false);
                    setPricingPlanData(parkingPricing.data);
                }
            }
            else {
                if (previousPricingTypeView !== "") {
                    setChangedType(true);
                    setSavedParkingFee(false);
                }
                setPreviousPricingTypeView(value);
                createPlanforPricingType(value);
            }
        } 
        if(name === toLower(NAME)){
            setNameExists(false);
        } 
        enableDisableButton(name, value);  
    };

    const enableDisableButton = (name:string, value: string) => {        
        let pricingName = toLower(NAME);
        let pricingType = toLower(TYPE);
        if ((name === pricingName) && value !== "") {
            setDisableNextButton(false);
        }
        else if ((name === pricingType) && value !== "") {
            setDisableSaveButton(false);
        } 
    }

    const handleSelection = (event: any) => {
        let name = event.target.name;
        let value = event.target.checked;
        setPricingDetails({ ...pricingDetails, [name]: value });
    };

    const createPlanforPricingType = (value: any) => {
        let pricing: any = [];
        if (value === PRICING_PLAN_TYPES.TIME_OF_DAY || value === PRICING_PLAN_TYPES.DURATION) {
            pricing = preparePlanForDayTime(timeOfDay);
            setShowBillEnergy(true);
        }
        else if (value === PRICING_PLAN_TYPES.HHYBRID || value === PRICING_PLAN_TYPES.HYBRID_2) {
            pricing = preparePlanForHybrid(hybrid);
            setShowBillEnergy(false);
        }

        setPricingPlanData(pricing.data);
    }

    const openParkingFee = () => {
        setparkingFee(true);
        setEnergyFee(false);
        if(savedParkingFee && pricingDetails.type === savedPricingType) {
            getPricingplanData();
        }
        if (!savedData || (savedData && pricingDetails.type !== savedPricingType)) {
            setPricingDetails({ ...pricingDetails, ["durationBased"]: false });
            setPricingPlanData("");
            createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
        } else {
            let parkingPricing: any = [];
            if (pricingDetails.type === PRICING_PLAN_TYPES.TIME_OF_DAY || pricingDetails.type === PRICING_PLAN_TYPES.DURATION) {
                if ((ctepValue === savedCtepValue && ctepValue === CTEP_ENABLED) || (ctepValue === CTEP_NOT_ENABLED)) {
                    parkingPricing = preparePlanOnTypeChange(true, true);
                    setPricingPlanData(parkingPricing.data);
                }
                else if (ctepValue !== savedCtepValue && ctepValue === CTEP_ENABLED){
                    createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
                }                
            }
            else {
                parkingPricing = preparePlanOnTypeChange(false);
                setPricingPlanData(parkingPricing.data);
            }      
            setDetailedViewobject(saveddetailedViewobject);
        }
    }

    const openEnergyFee = () => {
        setparkingFee(false);
        setEnergyFee(true);
        setView(SIMPLE_VIEW);
        if (!savedData || (savedData && pricingDetails.type !== savedPricingType)) {
            setPricingPlanData("");
            createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
        }
        else {
            let  energyPricing = [];
            if ((ctepValue === savedCtepValue && ctepValue === CTEP_ENABLED) || (ctepValue === CTEP_NOT_ENABLED)) {
                energyPricing = preparePlanOnTypeChange(true, false);
                setPricingPlanData(energyPricing.data);
            } else if (ctepValue !== savedCtepValue && ctepValue === CTEP_ENABLED){
                createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
            }
        }
    }

    const createFirstPopupClose = () => {
        props.addPricingPlanClose(false);
        resetFormData();
    }

    const createSecondPopupClose = () => {
        props.addPricingPlanClose(false);
        setCreatePlanSecondPopup(false);
        resetFormData();
    }
    
    
    const navgateToPricingPlanSecondStep = () => {
        if ((simpleValidator.current.fields[t("pricing.name")] === true)) {
            if (!savedData) {
                checkExistingPricingPlanName();
            } else {
                setSecondPopupProperties();
                setPricingPlanType(ctepValue, setPricingPlanFilteredType, pricingDetails);
            }
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const checkExistingPricingPlanName = () => {
        let params = { "orgId": props.orgId, "name": pricingDetails.name }
        setSpinnerOff(true);
        checkPricingPlanName(params).then((res: any) => {
            if (res?.message === "") {
                setSpinnerOff(false);
                setNameExists(false);
                pricingDetails.type = BLANK;
                setPricingPlanType(ctepValue, setPricingPlanFilteredType, pricingDetails);
                createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
                setSecondPopupProperties();
            }
            else if(res?.message !== "" && res?.message.includes(t("pricing.tariffnamealreadyexists"))){
                setSpinnerOff(false);
                setNameExists(true);
                simpleValidator.current.showMessages();
            }
        }).catch((err: any) => {
            setSpinnerOff(false);
            console.error("Error", err)
        })
    }

    const setSecondPopupProperties = () => {
        if (!props.editPopup && ctepValue === CTEP_NOT_ENABLED) {
            setDisableSaveButton(true);
        }
        else {
            setDisableSaveButton(false);
        }
        setPricingTypeOnCtep(); 
        props.setAddPricingOpen(false);
        setCreatePlanSecondPopup(true);
        setparkingFee(true);
        setEnergyFee(false);
        simpleValidator.current.hideMessages();
        forceUpdate(0);
    }

    const setPricingTypeOnCtep = () => {
        let parkingPricing: any = [];
        if (props.editPopup && pricingDetails.type !== savedPricingType && savedPricingType !== PRICING_PLAN_TYPES.TIME_OF_DAY) {
            if (ctepValue !== CTEP_ENABLED) {
                getPricingplanData();
                pricingDetails.type = savedPricingType;
            }
            else {
                createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
            }
        }
        else if (props.editPopup && savedPricingType === PRICING_PLAN_TYPES.TIME_OF_DAY && pricingDetails.type !== savedPricingType) {
            pricingDetails.type = savedPricingType;
            parkingPricing = preparePlanOnTypeChange(true, true);
            setPricingPlanData(parkingPricing.data);
        }
        else if (props.editPopup && pricingDetails.type === savedPricingType) {
            if (pricingDetails.type === PRICING_PLAN_TYPES.HYBRID_2 || pricingDetails.type === PRICING_PLAN_TYPES.HHYBRID) {
                parkingPricing = preparePlanOnTypeChange(false);
                setPricingPlanData(parkingPricing.data);
            }
            else {
                if((ctepValue === CTEP_NOT_ENABLED) || (ctepValue === CTEP_ENABLED && ctepValue === savedCtepValue)) {
                    parkingPricing = preparePlanOnTypeChange(true, true);
                    setPricingPlanData(parkingPricing.data);
                } else if(ctepValue !== savedCtepValue && ctepValue === CTEP_ENABLED){
                    createPlanforPricingType(PRICING_PLAN_TYPES.TIME_OF_DAY);
                }
            }   
        }
    }

    const navigateBacktoFirstStep = () => {
        setDisableNextButton(false);
        setCreatePlanSecondPopup(false);
        props.setAddPricingOpen(true);
    }

    const validateError = (fieldName: any) => {
        if (
            simpleValidator.current.fields[fieldName] === false &&
            simpleValidator.current.messagesShown === true
        ) {
            return "error-show";
        } else {
            return "";
        }
    };

    const onChangeCtepValue = (event: ChangeEvent<HTMLInputElement>) => {
        setCtepValue((event.target as HTMLInputElement).value);
        if(event.target.value === CTEP_ENABLED) {
            pricingDetails.type = PRICING_PLAN_TYPES.TIME_OF_DAY;
        }
        else
            pricingDetails.type = savedPricingType;
    }

    const onChangeAppliedvalue = (event: ChangeEvent<HTMLInputElement>) => {
        setAppliedvalue((event.target as HTMLInputElement).value);
    }

    const onChangeView = (event: any) => {
        setView(event.target.value);
        if (event.target.value !== SIMPLE_VIEW) {
            let detailedPricing: any = [];

            if ((props.editPopup && pricingDetails.type !== savedPricingType && !changedType) || (!props.editPopup && !savedParkingFee && !changedType)) {
                detailedPricing = createDetailedViewArrayEdit(detailedViewobject, timeArrayDetail);
            }
            else if ((props.editPopup && pricingDetails.type !== savedPricingType && changedType)
                || (!props.editPopup && !savedParkingFee && changedType)) {                   
                    detailedPricing = createDetailedViewArrayView(pricingPlanData, timeArrayDetail);
                    let detailedObject = createDetailedViewObject(pricingPlanData, timeArrayDetail);
                    setDetailedViewobject(detailedObject);
            }
            else if ((!props.editPopup && savedParkingFee) || (props.editPopup && pricingDetails.type === savedPricingType)) {
                detailedPricing = createDetailedViewArrayEdit(detailedViewobject, timeArrayDetail);
            }
            setTimeArrayDetailedKeys(Object.keys(detailedPricing));
        }
        setChangedType(false);
    }
    
    const expandPricingPlan = (timeArray : any) => {
        setTimeArrayDetail({...timeArray});
    }

    const getParamsForSave = (action? : boolean, parkingAndEnergyFee?:boolean) => {
        if( action) {
            return (savedData || savedParkingFee || parkingAndEnergyFee);
        } else {
            if((!props.editPopup && savedParkingFee) || (!props.editPopup && parkingAndEnergyFee)) {
                return lastSavedId;
            } else if((props.editPopup && savedParkingFee) || (props.editPopup && parkingAndEnergyFee)) {
                return props.pricingPlanId;
            } else {
                return props.pricingPlanId;
            }
        }
    }

    const savePricingPlan = () => {
        let body = {
            name: pricingDetails?.name,
            type: pricingDetails?.type,
            ctep_enabled: (ctepValue === CTEP_ENABLED) ? CTEP_YES : CTEP_NO,
            description: pricingDetails?.description,
            host_id: props.orgId,
            status: "1",
            bill_energy_first: pricingDetails?.billEnergy ? "1" : "0",
            applied_to: appliedValue
        };        
        let parkingAndEnergyFee = savedPricingType !== "" && pricingDetails?.type !== savedPricingType && !savedParkingFee;
        body = prepareConfigSaveData(body, pricingPlanData, pricingDetails, energyFee, parkingFee , timeArrayDetail, parkingAndEnergyFee);
        setSpinnerOff(true);
        let params = { 'action': getParamsForSave(true, parkingAndEnergyFee), 'pricingPlanId': getParamsForSave(false, parkingAndEnergyFee) }
        addPricingplan(body, params).then((res: any) => {
            if (res?.status === true) {
                setSpinnerOff(false);
                if ((pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY || pricingDetails?.type === PRICING_PLAN_TYPES.DURATION) && parkingFee) {
                    setSavedParkingFee(true);
                    setSavedPricingType(pricingDetails?.type);
                    openEnergyFee();                    
                    getLastInsertedPricingData();
                    setSavedParkingFeeOnChange(true);
                } else if (parkingFee || energyFee) {
                    resetFormData();
                    props.addPricingPlanClose(true, true);
                }
            }
            else {
                setSpinnerOff(false);
                dispatch(showErrorMessages({ error: true, message: res.message, type: "error", operation: "" }));
            }
        });
    }

    return (
        <>
            {/* Dialog for create New pricing plan first popup start*/}
            <AddPricingPlanStepOne
                createFirstPopupClose={createFirstPopupClose}
                navgateToPricingPlanSecondStep={navgateToPricingPlanSecondStep}
                handleChange={handleChange}
                nameExists={nameExists}
                onChangeCtepValue={onChangeCtepValue}
                pricingDetails={pricingDetails}
                ctepValue={ctepValue}
                spinnerOff={spinnerOff}
                simpleValidator={simpleValidator}
                validateError={validateError}
                firstPopupName={props.firstPopupName}
                editPopup={props.editPopup}
                disableNextButton={disableNextButton}
                addPricingOpen={props.addPricingOpen}
            />
            {/* Dialog for create New pricing plan second popup start*/}
            <AddPricingPlanStepTwo
                createSecondPopupClose={createSecondPopupClose}
                navigateBacktoFirstStep={navigateBacktoFirstStep}
                savePricingPlan={savePricingPlan}
                handleChange={handleChange}
                handleSelection={handleSelection}
                openParkingFee={openParkingFee}
                openEnergyFee={openEnergyFee}
                pricingDetails={pricingDetails}
                parkingFee={parkingFee}
                energyFee={energyFee}
                onChangeAppliedvalue={onChangeAppliedvalue}
                pricingPlanData={pricingPlanData}
                setPricingPlanData={setPricingPlanData}
                spinnerOff={spinnerOff}
                showBillEnergy={showBillEnergy}
                savedData={savedData}
                pricingPlanTypes={pricingPlanFilteredType}
                appliedValue={appliedValue}
                secondPopupName={props.secondPopupName}
                simpleValidator={simpleValidator}
                validateError={validateError}
                disableSaveButton={disableSaveButton}
                ctepValue={ctepValue}
                onChangeView={onChangeView}
                view={view}
                timeArrayDetailedKeys={timeArrayDetailedKeys}
                timeArrayDetail={timeArrayDetail}
                setTimeArrayDetail={setTimeArrayDetail}
                expandPricingPlan={expandPricingPlan}
                setDetailedViewobject={setDetailedViewobject}
                detailedViewobject={detailedViewobject}
                setTimeArrayDetailedKeys={setTimeArrayDetailedKeys}
                savedParkingFee={savedParkingFee}
                addPricingPlanClose={props.addPricingPlanClose}
                savedparkingFeeOnChange={savedparkingFeeOnChange}
                createPlanSecondPopup={createPlanSecondPopup}
            />
            {/* Dialog New pricing plan second popup End */}
        </>
    );
};
export default AddPricingPlan;


