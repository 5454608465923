import React, {useState} from "react";
import {
    Grid,
    Button,
    DialogContentText,
    DialogContent,
    Dialog, LinearProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import { deactivateCard } from "services/fleetManagementService";

const DeleteVehicleAuthCard = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoader] = useState(false);

    const deactivateAuthCard = () => {
        setLoader(true);
        let body = {vehicle_id: props.deleteAuthcard?.vehicle_id};
        let params = { pass: props.deleteAuthcard?.pass};
        deactivateCard(params, body).then((res: any) => {
            if (res.status) {
                props.deleteAuthCardClose();
                props.dataRefresh();
            } else {
                dispatch(
                    showErrorMessages({
                        error: true,
                        message: res.message,
                        type: "error",
                        operation: "",
                    })
                );
            }
            setLoader(false);
        });
    };

    return (
        <>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info payment-mode sm dialog_container_center"
                    open={props?.openDeleteAuthCard}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("fleet.removeauthcard")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button className="btn_white text_btn_theme" onClick={props?.deleteAuthCardClose}>
                                {t("common.cancelbutton")}
                            </Button>

                            <Button className="btn_white delet_acct" onClick={deactivateAuthCard}>
                                {t("common.remove")}
                            </Button>
                        </div>
                    </div>
                    {loading && <LinearProgress/>}
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form">
                                <div className="delete_message_padding">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <div className="text-terminate">
                                                {t("fleet.authremovedetail")}{" "}{props?.deleteAuthcard?.pass}{"?"}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};

export default DeleteVehicleAuthCard;
