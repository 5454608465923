import { Button, Grid, Dialog, DialogContent, DialogContentText, TextField, CircularProgress } from "@material-ui/core";
import { bytesToSize, defaultVariable, LabelInfo, urlReplace } from "common";
import { DragDragObj, ObjectDetails } from "common/interfaces/dragdropimages";
import DragDropImages from "components/dragDropImages";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiEndPoint, getMethod, putMethod } from "services";
import UserGroupDelete from "../userGroupDelete";

const UserGroupUpdate = (props: any) => {
    const { t } = useTranslation();
    const [deleteUserGroup, setDeleteUserOpen] = useState(false);
    const SIZE = bytesToSize(defaultVariable.DELEGATE_FILESIZE, t);
    const [notesCharCount, setNotesCharCount] = useState(0);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const WORD = defaultVariable.textAreaLength;
    const [ImageList, setImageList] = useState<ObjectDetails>([]);
    const [userGroupDetails, setUserGroupDetails] = useState<any>();
    const [spinnerOff, setSpinnerOff] = useState<boolean>(false);

    useEffect(() => {
        setUserGroupDetails(null)
        setImageList([]);
        getGroupDetailsList();
    }, [props?.userGroupId]);

    const getGroupDetailsList = async () => {
        if (props?.userGroupId) {
            const url = urlReplace(ApiEndPoint.groupDetailsUserEdit, "{USERGROUP-ID}", props?.userGroupId);

            await getMethod(url, '')
                .then((res) => {
                    setUserGroupDetails(res?.data)
                    setValue("name", res?.data?.name);
                    setValue("notes", res?.data?.notes);
                    setNotesCharCount(res?.data?.notes?.length);
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        }
    };

    const deleteUser = () => {
        setDeleteUserOpen(true);
    };

    const deleteUserClose = () => {
        setDeleteUserOpen(false);
    };

    

    let orderImages: any[] = [];
    const onSubmit = async (data: any, e: any) => {
        setSpinnerOff(true);
        orderImages = ImageList.sort((a: any, b: any) => a.order - b.order).map(
            (image: DragDragObj) => ({
                order: image.order + 1,
                file: image.file,
            })
        );
        let formData = new FormData();

        formData.append('userGroupImage', orderImages[0]?.file);

        const url = urlReplace(ApiEndPoint.groupDetailsUserEdit, "{USERGROUP-ID}", props?.userGroupId);
        const paramDetails = '?name=' + data.name + '&notes=' + data.notes;

        await putMethod(url, paramDetails, formData, false, true)
            .then((res) => {
                resetForm()
                e.target.reset(); // reset after form submit
                setNotesCharCount(0)
                setSpinnerOff(false);
            })
            .catch((err) => {
                e.target.reset(); // reset after form submit
                setNotesCharCount(0)
                setSpinnerOff(false);
            });
    };

    const resetForm = () => {
        setSpinnerOff(false);
        props.editGroupClose(true);
    }


    const selectedImagesDetails = (value: DragDragObj) => {
        setImageList(value);
    };

    const deleteUserGroupSave = () => {
        setDeleteUserOpen(false);
        props.editGroupClose(false);
        props.showUserList(false)
    }

    return (
        <>

            <UserGroupDelete deleteUserGroupSave={deleteUserGroupSave} deleteUserGroupId={userGroupDetails?.id} deleteUserGroup={deleteUserGroup} deleteUserGroupClose={deleteUserClose} />

            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section zone_settings_dialog edit_photo_gallery sm_570 dialog_container_center"
                    {...props}
                    open={props.editUserGroupOpen}
                    onClose={resetForm}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="dialog_title">
                            <h4>{t("user.editusergroup")}</h4>
                            <div className="button_row mt-0 full_right">
                                <Button
                                    className="btn_white text_btn_theme"
                                    onClick={resetForm}
                                >
                                    {t("common.cancelbutton")}
                                </Button>
                                <Button type="submit" disabled={spinnerOff} className="btn_white blue_bg">
                                    {t("common.savechangesbutton")}
                                </Button>
                            </div>
                        </div>
                        <DialogContent {...props}>
                            <DialogContentText id="scroll-dialog-description">
                                <div className="defult_form">
                               {spinnerOff && <CircularProgress className="inner_progress_userGroup"/>}
                                    <div className="section_group">
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <div className={`form_col ${errors?.name && 'error'}`}>
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={12}>
                                                            <LabelInfo name={t("common.name")} isMandatory={true} />
                                                            <TextField {...register("name", { required: true, pattern: /^(\w+\s)*\w+$/ })} variant="outlined" />
                                                            {errors?.name && <span className="error_msg">{t("evselocation.usergroup.groupname.required")}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="section_group">
                                        <div className="form_col">
                                            <Grid
                                                className="single_line textarea"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={0}
                                            >
                                                <Grid item xs={12}>
                                                    <LabelInfo name={t("common.notes")} isMandatory={false} />
                                                    <TextField
                                                        multiline
                                                        rows={4}
                                                        {...register("notes")}
                                                        helperText={
                                                            notesCharCount + ' ' + t("common.textarea400characters",{WORD})
                                                        }
                                                        onChange={e => { setNotesCharCount(e.target.value.length) }}
                                                        variant="outlined"
                                                        inputProps={{ maxLength: defaultVariable.TEXTAREALENGTH }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="gallery_photo_upload mb-20">
                                        <DragDropImages receivedImagePath={userGroupDetails} fileMessage={t("dragimage.filesizetype", { SIZE })} fileSize={defaultVariable.DELEGATE_FILESIZE} imagetype={false} selectedImagesDetails={selectedImagesDetails} />
                                    </div>
                                    <div className="form_col">
                                        <Grid
                                            className="single_line textarea"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                            <div className="button_row mt-25 full_right">
                                                    <Button
                                                        className="delet_acct ml_0 btn_white block_btn"
                                                        onClick={deleteUser}
                                                    >
                                                        {t("user.removeusergroup")}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>

        </>
    );
}
export default UserGroupUpdate
