import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// Importing translation files
import en from "locales/en.json";
import en_US from "locales/en-US.json";

// detect browser language order wise
// const options = {
//   order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
// }

const resources = {
  "en": {
    translation: en,
  },
  "en-US": {
    translation: en_US,
  },
};

//i18N Initialization
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", //when specified language translations not present then fallbacklang translations loaded by default.
    // detection: options,
    lng:"en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;