import fetchDepotReducer from './evseLocations';
import { fetchCurrentStationReducer } from './stationList'
import fetchManageUserInfoListReducer from './manageUserInfoList';
import fetchManageUserReducer from './manageuser';
import { fetchUserPermissionsReducer } from './userPermissions';
import { loginTime } from './loginTime';
import { sidebarItems } from './sidebar';
import { errorMessagesReducer } from './errorMessages';


const rehydrated = (state = false, action: any) => {
    if(action.type === 'persist/REHYDRATE') {
        return true;
    } else {
        return state;
    }
};

export default {
    rehydrated: rehydrated,
    depotData:fetchDepotReducer,
    currentStationId: fetchCurrentStationReducer,
    manageUserData: fetchManageUserReducer,
    manageUserInfoData: fetchManageUserInfoListReducer,
    fetchUserPermissions: fetchUserPermissionsReducer,
    loginTime: loginTime,
    errorMessages: errorMessagesReducer,
    sidebarItems: sidebarItems
};
