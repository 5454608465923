import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import Images from "assets/images";
import { Button, IconButton, CardContent, Card, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { bytesToSize, defaultVariable } from "common";
import { DragDragImages } from "common/interfaces/dragdropimages";

const DragDropImagesMultiple = (props: DragDragImages) => {
  const { t } = useTranslation();
  const [images, setImages] = React.useState([]);
  const maxNumber = 10;
  const imageType = ["jpeg", "png"];
  const fileMessage =  t("dragimage.filesizetype", {'SIZE': `${Math.round(defaultVariable.DEFAULT_IMAGE_FILESIZE/1000000)} MB`});
  const [fileErr, setfileError] = useState<any>();
  const [dragId, setDragId] = useState();
  const [boxes, setBoxes] = useState([]);
  const bytes = defaultVariable.DEFAULT_IMAGE_FILESIZE;
  const SIZE = bytesToSize(bytes, t);

  const onChange = (imageList: any) => {
    for (let i = 0; i < imageList.length; i++) {
      imageList[i].id = "id-" + i;
      imageList[i].order = i;
    }
    props.selectedImagesDetails(imageList);

    setBoxes(imageList);
    setImages(imageList);
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    const dragBox: any = boxes.find((box: any) => box.id === dragId);
    const dropBox: any = boxes.find(
      (box: any) => box.id === ev.currentTarget.id
    );

    const dragBoxOrder: any = dragBox.order;
    const dropBoxOrder: any = dropBox.order;

    const newBoxState: any = boxes.map((box: any) => {
      switch (box.id) {
        case dragId:
          box.order = dropBoxOrder;
          break;
        case ev.currentTarget.id:
          box.order = dragBoxOrder;
          break;
        default:
          break;
      }
      return box;
    });
    props.selectedImagesDetails(newBoxState);
    setBoxes(newBoxState);
  };

  const onError = (errors: any, files: any) => {
    if (errors.maxFileSize) {
      setfileError({
        name: files[0].file.name,
        size: bytesToSize(files[0].file.size, t),
      });
    }
  };

  return (
    <>
      <div data-testid="dragDropImagesMultiple_dialog">
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          maxFileSize={defaultVariable.DEFAULT_IMAGE_FILESIZE}
          acceptType={imageType}
          dataURLKey="key"
          onError={onError}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => (
            // write your building UI
            <>
              <Card
                style={{
                  border: isDragging ? "1px dashed #000" : "1px dashed #25556E",
                }}
                onClick={onImageUpload}
                {...dragProps}
              >
                <CardContent>
                  {errors && (
                    <div>
                      {errors?.maxNumber && (
                        <div className="error">
                          {" "}
                          <span> {t("dragimage.maxphotos")}</span>{" "}
                        </div>
                      )}
                      {errors?.acceptType && (
                        <div className="error">
                          {" "}
                          <span> {t("dragimage.filetype")}</span>{" "}
                        </div>
                      )}
                      {errors?.maxFileSize && (
                        <div className="error">
                          {" "}
                          <span>
                            {" "}
                            {t("dragimage.file")} "{fileErr?.name}" (
                            {fileErr?.size}){t("dragimage.filesize", {SIZE})}
                          </span>{" "}
                        </div>
                      )}
                      {errors?.resolution && (
                        <div className="error">
                          {" "}
                          <span>{t("dragimage.fileresolution")}</span>{" "}
                        </div>
                      )}
                    </div>
                  )}
                  {onError}

                  <div className="dragdrop">
                    <img src={Images.up_image} alt="upimg" className="up-icon" />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        {t("dragimage.dragdroporclick")}{" "}
                        <cite>{fileMessage}</cite>
                      </Button>
                    </label>
                  </div>
                </CardContent>
              </Card>

              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <div className="drag-text">
                    {t("dragimage.dragimageordering")}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="drag-text text-right">
                    {t("dragimage.dragimageuploadcount")}
                  </div>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={3} alignItems="center">
                {imageList
                  .sort((a: any, b: any) => a.order - b.order)
                  .map((image, index) => (
                    <Grid item key={image.id}>
                      <div className="items">
                        <img
                          draggable={true}
                          id={image.id}
                          onDragOver={(ev) => ev.preventDefault()}
                          onDragLeave={(ev) => ev.preventDefault()}
                          onDragStart={handleDrag}
                          onDrop={handleDrop}
                          src={image.key}
                          alt="del"
                          className="main-image"
                        />
                        <IconButton
                          onClick={() => onImageRemove(index)}
                          className="top_action_btn del"
                        >
                          <img
                            src={Images.delete_icon}
                            alt="del"
                            className="del-img"
                          />
                        </IconButton>

                        <IconButton
                          onClick={() => onImageUpdate(index)}
                          className="top_action_btn drag"
                        >
                          <img src={Images.drag_icon} alt="drag" />
                        </IconButton>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </ImageUploading>
      </div>
    </>
  );
};

export default DragDropImagesMultiple;
