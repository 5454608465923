import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {HourDetails} from "common/interfaces";
import { useTranslation } from "react-i18next";
import { LabelInfo } from "common";
import workingHours from '../../data/workingHours.json'
import moment from "moment";
import { capitalize } from "lodash";


const HourForm = (props: HourDetails) => {
  const { t } = useTranslation();
  const [startTimeData, setStartTimeData] = useState<any>([]);
  const [time, setTimeData] = useState<any>([]);
    const initialErrors = [
        false,
        false,
        false,
        false,
        false,
        false,
        false
     ]

  useEffect(() => {
        getTimeData()
        props.setErrors(initialErrors)
      }, [])
    

  const getTimeData = async () => {
        const startTimeDataList = JSON.parse(JSON.stringify(workingHours.workingHours))
        startTimeDataList.unshift({id:'0', hour:'Open'})
        startTimeDataList.push({ id:'25', hour:'Close'})
    setStartTimeData(startTimeDataList);
    setTimeData(workingHours.workingHours);
  };

    
  const handleHourChange = (e: any, index: any, time: any) => {

    const rowsInput: any = [...props.weekArrayState];
        const { name, value} = e.target

        if(value && (value !== 'Close' || value !== 'Open')) {
      let beginningTime;
      let endTime;
      const valueErrors = [...props.errors];
            if(name === 'startTime') {
                beginningTime = moment(value, 'h:mma');
                endTime = moment(time, 'h:mma');
      } else {
                beginningTime = moment(time, 'h:mma');
                endTime = moment(value, 'h:mma');
      }

            if(beginningTime.isAfter(endTime)) {
        valueErrors[index] = true;
        props.setErrors(valueErrors);
      } else {
        valueErrors[index] = false;
        props.setErrors(valueErrors);
      }
    }
    rowsInput[index][name] = value;
    props.setWeekArrayState(rowsInput);
    }

    const weekDays = props.weekArrayState.map((ele: any, index:any) => {
    return (
      <div className="form_col" key={ele.weekDay}>
        <Grid className="single_line time_schedule" container justifyContent="flex-start" alignItems="center" spacing={3} xs={12}>
            <Grid item xs={4}>
                <LabelInfo name={capitalize(ele.weekDay)} isMandatory={false}/>
            </Grid>
            <Grid item xs={4}>
                    <Select labelId="demo-simple-select-label" variant="outlined" name="startTime" value={ele.startTime} onChange={(e) => handleHourChange(e, index , ele.endTime)}>
                        {startTimeData && startTimeData.map((value: any) => (<MenuItem className="defult_select_option" key={value.id} value={value.hour}>
                    {value.hour}
                        </MenuItem>))}
                    </Select>
            </Grid>
            <Grid item xs={4}>
                    { ele.startTime !== "Close" && ele.startTime !== "Open" && 
                    <Select labelId="demo-simple-select-label" variant="outlined"  name="endTime" value={ele.endTime} onChange={(e) => handleHourChange(e, index , ele.startTime)}>
                        {time && time.map((value: any) => {
                            return(<MenuItem className="defult_select_option" key={value.id} value={value.hour}>
                        {value.hour}
                        </MenuItem>)})}
                    </Select>
                          }
            </Grid>
          <p className="error_msg error_position">
                      {props.errors && props.errors.map((ele: any, i: any) => {
                        if(ele && i === index) {
                            return t("common.timeErrorMessage")
                }
              })}
          </p>
        </Grid>
       
      </div>
        )
    })


  return (
    <>
          <div className="section_group" data-testid="hour_form_component">
            
                <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid item xs={12}>                    
                {weekDays}
              </Grid>
            </Grid>
          </div>
    </>
  );

}

export default HourForm;
