import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import './commonTableDetails.scss';

const CommonTableDetails = (props:any) => {
  return (
    <>
      <div data-testid="common_table_details" >
        <DataGrid autoHeight   
            className="table"
            rowHeight={props?.rowHeight}
            headerHeight={props?.headerHeight}
            rows={props?.rowDetails}
            columns={props?.columnDetails}
            pageSize={props?.pazeSizeDetails}
            disableSelectionOnClick />
      </div>
    </>
  );
}
export default CommonTableDetails;