export const LCD_MSG_LENGTH = 16;
export const ZERO_LENGTH = 0;
export const MODELS_WITHOUT_LCD = ['848','850','880','830','430','450'];  //This will be removed once the model level capability is implemented (S8, S4)
export const CURRENT_ITEM_INDEX = 0;
export const lcdMessageLabel = (label: any) => {
    let message_labels = {
        "Message 1": "lcdtext0",
        "Message 2": "lcdtext1",
        "Message 3": "lcdtext2",
        "Message 4": "lcdtext3",
    };
    return (
        Object.keys(message_labels) as (keyof typeof message_labels)[]
    ).find((key) => {
       return message_labels[key] === label;
    });
   
};