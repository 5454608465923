import { renderCellExpand } from "common";
import React from "react";

export const semaConnectChargeFeeColumns = () => {
    const stationsColumns = [
    {
        field: "firstCell",
        headerName: "firstcell",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
    {
        field: "name",
        headerName: "STATION NAME",
        headerClassName: "action_columns",
        sortable: false,
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
    },
    {
        field: "serial_number",
        headerName: "SERIAL NUMBER",
        headerClassName: "action_columns",
        sortable: false,
        cellClassName: "",
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
    },
    {
        field: "access_type",
        headerName: "ACCESS TYPE",
        headerClassName: "action_columns",
        sortable: false,
        hasFocus: false,
        cellClassName: "",
        className: "",
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
    },
    {
        field: "lastCell",
        headerName: "lastcell",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
]
return stationsColumns;
};