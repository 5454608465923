import React, { useEffect, useState } from "react";
import { DialogContent, DialogContentText, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isMultipleLabelCheck } from "common";
import { capitalize } from "lodash";
import { getMethod, ApiEndPoint } from "services";

const UpdateZoneStations = (props: any) => {
    const { t } = useTranslation();
    const { name, stationIdsArr } = props?.zoneStationsPost || {};
    const singlePortLabel = capitalize(t("port"));
    const multiPortLabel = capitalize(t("ports"));
    const singleStationLabel = capitalize(t("station"));
    const multiStationLabel = capitalize(t("stations"));
    const [prevZoneInfo, setPrevZoneInfo] = useState<any>({});
    const selectedStation = stationIdsArr.filter(
        (value: any) =>
            value.zoneChecked === true && value.zoneDisabled === false
    );
    const sumPorts = selectedStation.reduce((accumulator: any, object: any) => {
        return accumulator + object.portCount;
    }, 0);

    const getZoneDetails = async () => {
        await getMethod(ApiEndPoint.zoneInformation, props?.currentZoneId)
            .then((res) => {
                setPrevZoneInfo(res.data);
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    useEffect(() => {
        getZoneDetails();
    }, [props?.currentZoneId]);


    const updateForm = () => {
        props?.updateZoneData(props?.coordinates)
    }

    return (
      <>
        <div data-testid="update_zone_station">
          <div className="dialog_title">
              <h4>{t("common.updatezone")}</h4>
          </div>
          <DialogContent dividers="paper" {...props}>
              <DialogContentText id="scroll-dialog-description">
                  <div className="msg_text">{t("common.doyouwant.updatezone")}</div>
                  <br />
                  <div className="update_box">
                      <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={4}
                      >
                          <Grid item xs={6}>
                              <div className="update_box_item">
                                  <h4>{props?.locationInformation?.name}</h4>
                                  <div className="longlat">
                                      <div className="longlat_row">
                                          <span className="result">
                                              {prevZoneInfo?.name}
                                          </span>
                                      </div>
                                      <div className="longlat_row">
                                          <span className="result">
                                              {prevZoneInfo?.totalStation}{" "}
                                              {isMultipleLabelCheck(
                                                  stationIdsArr?.length,
                                                  singleStationLabel,
                                                  multiStationLabel
                                              )}{" "}
                                              {prevZoneInfo?.totalPort}{" "}
                                              {isMultipleLabelCheck(
                                                  stationIdsArr?.length,
                                                  singlePortLabel,
                                                  multiPortLabel
                                              )}
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </Grid>
                          <Grid item xs={6}>
                              <div className="update_box_item active_zone">
                                  <h4>{props?.locationInformation?.name}</h4>
                                  {selectedStation?.length > 0 ? (
                                      <div className="longlat">
                                          <div className="longlat_row">
                                              <span className="result">
                                                  {name}
                                              </span>
                                          </div>
                                          <div className="longlat_row">
                                              <span className="result">
                                                  {selectedStation?.length}{" "}
                                                  {isMultipleLabelCheck(
                                                      selectedStation?.length,
                                                      singleStationLabel,
                                                      multiStationLabel
                                                  )}{" "}
                                                  {sumPorts}{" "}
                                                  {isMultipleLabelCheck(
                                                      selectedStation?.length,
                                                      singlePortLabel,
                                                      multiPortLabel
                                                  )}
                                              </span>
                                          </div>
                                      </div>
                                  ) : (
                                          <div className="longlat">
                                              <div className="longlat_row">
                                                  <span className="result">
                                                      {t("common.nozone")}
                                                  </span>
                                              </div>{" "}
                                          </div>
                                      )}
                              </div>
                          </Grid>
                      </Grid>
                  </div>
                  <div className="button_row mt-0 full_right">
                      <Button
                          className="btn_white light_gry"
                          onClick={props?.viewAssignedStationMethod}
                      >
                          {t("common.backtoedit")}
                      </Button>
                      <Button
                          className="btn_white blue_bg"
                          onClick={updateForm}
                      >
                          {t("common.updatezone")}
                      </Button>
                  </div>
              </DialogContentText>
          </DialogContent>
        </div>
      </>
    );
};

export default UpdateZoneStations;
