import React, { useState, useEffect } from "react";
import Images from "assets/images";
import moment from "moment";
import ImageGallery from "react-image-gallery";
import { getMethod, ApiEndPoint, putMethod } from "services";
import "react-image-gallery/styles/css/image-gallery.css";
import EditOrganizationDetails from "./editOrganizationDetails";
import EditDepotInformation from "./editDepotInformation";
import { dateFormats, mapDetails, defaultVariable, GetUrlDetails, CheckAccess, claims, bytesToSize } from "common";
import { ObjectDetails } from "common/interfaces"
import { useTranslation } from "react-i18next";
import { toUpper, capitalize } from "lodash";
import {
  Dialog,
  IconButton,
  DialogContent,
  DialogContentText,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Card,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import CommonGoogleMap from "components/commonGoogleMap";
import DragDropImages from "components/dragDropImages";
import {useHistory} from "react-router-dom";
import { DragDragObj } from "common/interfaces/dragdropimages";

const DepotInformation = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openZone, setZoneOpen] = useState(false);
  const [scrollZone] = useState("paper");
  const [openLocationPermission, setLocationPermissionOpen] = useState(false);
  const [scrollLocationPermission, setLocationPermissionScroll] = useState("paper");
  const [editType, setEditType] = useState('');
  const [infoDetails, setInfoDetails] = useState<any>({});
  const SIZE = bytesToSize(defaultVariable.DELEGATE_FILESIZE, t);
  const [imageData, setImageData] = useState<any>({});
  const [zoneData, setZoneData] = useState<any>([]);
  const [contactInformation, setContactInformation] = useState<any>({});
  const isPublic = props?.depotDetailsData?.isPublic;
  const default_Closed_Variable = "Closed";
  let { locationId } = GetUrlDetails(history, "/evseLocations/evseDepotDetail");
  const canUpdateInfo = props?.updateInfo;
 

  const imageSlider =
    imageData?.originalImages
      ? imageData.originalImages.map(({ url, thumbnailUrl, name }: any) => ({
          original: url,
          thumbnail: thumbnailUrl ? thumbnailUrl : url,
          description: name,
        }))
      : [];

  const [openEditPhoto, setEditphotoOpen] = useState(false);
  const [scrollEditphoto, setEditphotoScroll] = useState("paper");
  const [ImageList, setImageList] = useState<any>([]);
  const [loadingPage, setLoadingPage] = useState(false);


  useEffect(() => {
    getDepotListData();
  }, [locationId]);

  const isHoursOperation = (start: string, end: string, status: boolean) => {
      if(start && start.length === 3) {
      start = 0 + start;
    }
      if(end && end.length === 3) {
      end = 0 + end;
    }
    if (!status && status !== null) {
      return default_Closed_Variable;
    } else if (status) {
        return t('common.openFor24hours')
    } else {
      return (
        moment(start, dateFormats.infoTimeFormat).format(
          dateFormats.check12HourSpaceFormat
        ) +
        " - " +
        moment(end, dateFormats.infoTimeFormat).format(
          dateFormats.check12HourSpaceFormat
        )
          )
    }
  };

  const getDepotListData = async () => {
    if (ImageList.length !== 0) {
      let orderImages: any[] = [];
      orderImages = ImageList.sort((a: any, b: any) => a.order - b.order).map(
        (image: any) => ({
          order: image.order + 1,
          file: image.file,
        })
      );

      let formData = new FormData();
      for (let image of orderImages) {
        formData.append(image.order, image.file);
      }

      await putMethod(
        ApiEndPoint.locations + '/',
        locationId + "/images",
        formData
      )
        .then((res: any) => "")
        .catch((err: any) => {
          console.error("Error", err);
        });
    }
  };

  const receiveDataImages = (value: any) => {
    setImageList(value);
  };

  const editphotoOpen = (scrollType: any) => () => {
    setEditphotoOpen(true);
    setEditphotoScroll(scrollType);
  };

  const editphotoClose = () => {
    setEditphotoOpen(false);
  };


  const zoneClose = () => {
    setZoneOpen(false);
  };


  const locationPermissionOpen = (scrollType: any) => () => {
    setLocationPermissionOpen(true);
    setLocationPermissionScroll(scrollType);
    setEditType("locPermission")
  };
  const locationPermissionClose = () => {
    setLocationPermissionOpen(false);
  };

  const operationalHoursOpen = (scrollType: any) => () => {
    setLocationPermissionOpen(true);
    setLocationPermissionScroll(scrollType);
    setEditType('operationalHours')
  };


  const locationContactOpen = (scrollType: any) => () => {
    setLocationPermissionOpen(true);
    setLocationPermissionScroll(scrollType);
    setEditType('contact')
  };

  const latLongValue = {
    lat: props?.depotDetailsData?.address?.latitude,
    lon: props?.depotDetailsData?.address?.longitude,
    title:props?.depotDetailsData?.name,
  };

  useEffect(() => {
    getContactInformation();
    /* getImageData(); This is image slider data , will open in future, hide for now */
    getZoneData();
  }, []);

  

  const getContactInformation = async() => {
    setLoadingPage(true);
    await getMethod(ApiEndPoint.locationContactInformation, `?locationId=${locationId}`).then((res) => {
      if(res.status === 200 && res.data && res.data.length) {
          setContactInformation(res.data[0]);
        }
    }).catch((err) => {
        console.error("Error", err);
    })
    setLoadingPage(false);
  }

  const getImageData = async () => {
    await getMethod(ApiEndPoint.locations, '/' + defaultVariable.locationIdInformation + "/images")
      .then((res) => {
        setImageData(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const locationAccess = (value: boolean) => {
    if (value) {
      return (t("common.public"));
    } else {
      return (t("common.private"));
    }
  }

  const navigateToDepotStationDetails = (value: any) => {
    let { locationId } = GetUrlDetails(history, '/evseLocations/evseDepotDetail');
    let searchParams = "?locationId=" + locationId + "&stationId=" + value?.id;
    history.push({
      pathname: "/evseLocations/depotStationDetails",
      search: searchParams,
      hash: '#0'
    });
  }


  const getZoneData = async () => {
    setLoadingPage(true);
    await getMethod(ApiEndPoint.locationsV2, '/' + props?.locationId + '/zones' )
      .then((res) => {
        setZoneData(res.data.zones);
      })
      .catch((err) => {
        console.error("Error", err);
      });
    setLoadingPage(false);
  };

  const selectedImagesDetails = (value: DragDragObj) => {
    setImageList(value);
  };

  useEffect(() => {
    getDepotInformation();
    // getImageData(props?.locationId); comment for future use.
}, [props?.locationId]);

const getDepotInformation = async () => {
    await getMethod(ApiEndPoint.hostDepotInformation, "", true)
        .then((res) => {
            setInfoDetails(res.data);
        })
        .catch((err) => {
            console.error("Error", err);
        });
};

  return (
    <>
      <div className="dialog_box">
        <Dialog
          className="dialog_container dialog_container_center"
          {...props}
          open={openZone}
          onClose={zoneClose}
          scroll={scrollZone}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>Station Zone</h4>
            <IconButton className="closed_btn full_right" onClick={zoneClose}>
              <span className="material-icons">cancel</span>
            </IconButton>
          </div>

          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="station_zone">
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <div className="zone_map">
                      <img
                        className="img_res"
                        src={Images.ic_zone_map_del}
                        alt="Zone Detail Map"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={3}>
                    <div className="zone_floor_info">
                      <List component="nav" className="z_floor_list">
                        <ListSubheader className="list_head">
                          <span className="circle">1</span> Loading
                        </ListSubheader>
                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="A - Port 1" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="A - Port 2" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="B - Port 1" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="B - Port 2" />
                        </ListItem>

                        <ListSubheader className="list_head">
                          <span className="circle">2</span> Service
                        </ListSubheader>
                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="C - Port 1" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="C - Port 2" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="C - Port 1" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="C - Port 2" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="C - Port 1" />
                        </ListItem>

                        <ListItem>
                          <ListItemIcon className="icon">
                            <img src={Images.IC_EVSE_BLUE} alt="Edit EVSE" />
                          </ListItemIcon>
                          <ListItemText primary="C - Port 2" />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                </Grid>

                <div className="button_row">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid className="text_left" item xs={12}>
                      <Button className="text_btn">First floor</Button>
                      <Button className="text_btn active">3rd floor</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <EditDepotInformation
          depotDetailsData={props.depotDetailsData}
          openLocationPermission={openLocationPermission}
          locationPermissionClose={locationPermissionClose}
          scrollLocationPermission={scrollLocationPermission}
          getContactInformation={getContactInformation}
          getLocationData={props.getLocationData}
          editType={editType}
          contactInfo={contactInformation}
          infoDetails={infoDetails}
          getHostLocations={props.getHostLocations}
      />
    
      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_photo_gallery  dialog_container_center"
          {...props}
          open={openEditPhoto}
          onClose={editphotoClose}
          scroll={scrollEditphoto}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>Edit Photos</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={editphotoClose}
              >
                Cancel
              </Button>
              <Button
                className="btn_white blue_bg"
                disabled={ImageList.length === 0}
                onClick={getDepotListData}
              >
                Save Changes
              </Button>
            </div>
          </div>

          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="gallery_photo_upload">
                <DragDropImages selectedImagesDetails={selectedImagesDetails} fileMessage={t("dragimage.filesizetype",{SIZE})} fileSize={defaultVariable.DELEGATE_FILESIZE} imagetype={true} receiveDataImages={receiveDataImages} />
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      {   loadingPage ? <CircularProgress className="inner_progress_userGroup"/> :
        <div className="information_section location-details" data-testid="depot_information">
          <Grid container spacing={2}>
            <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h2>{toUpper(t("common.locationdetails"))}</h2>

                    <div className="clearfix"></div>
                  </div>
                  <div className="info_detail">
                   <Grid container className="row">
                   
                    <Grid xs={4}>
                      <div className="label">{t("common.name")}</div>
                    </Grid>
                    <Grid xs={8}>
                      <div className="result">
                        {props.depotDetailsData.name}
                      </div>
                    </Grid>
                   
                  </Grid>
                  <Grid container className="row">
                    <Grid xs={4}>
                    <div className="label">{t("common.organization")}</div>
                    </Grid>
                    <Grid xs={8}>
                    <div className="result">{props.depotDetailsData?.organizationName}</div>
                    </Grid>
                  </Grid>
                  <Grid container className="row">
                 
                    <Grid xs={4}>
                    <div className="label">{t("common.address")}</div>
                    </Grid>
                    <Grid xs={8}>
                      { props?.depotDetailsData?.address && 
                        <div className="result">

                          {props?.depotDetailsData?.address?.addressLine1 || ""}
                          {props?.depotDetailsData?.address?.addressLine2
                            ? ", " + props.depotDetailsData.address.addressLine2
                            : ""}
                          <br />
                          {props?.depotDetailsData?.address?.city ? props.depotDetailsData.address.city + ',': ''}
                          {props?.depotDetailsData?.address?.state ? ` ${props.depotDetailsData.address.state}` : '' }
                          {props?.depotDetailsData?.address?.postalCode ? ` ${props.depotDetailsData.address.postalCode}` : '' }
                          <br /> {props?.depotDetailsData?.address?.country}
                          <br />
                        </div> }
                   
                    </Grid>
                    
                  </Grid>
                  <Grid container className="row">
                    <Grid xs={4}>
                    <div className="label">{t("common.description")}</div>
                    </Grid>
                    <Grid xs={8}>
                    <div className="result">
                          {props.depotDetailsData.description}
                    </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={4}>
                      <div className="label">
                        {t("depotinformation.edit.timezone")}
                      </div>
                    </Grid>
                    <Grid xs={8}>
                      <div className="result">
                        { props?.depotDetailsData?.address?.timeZone || ""}
                      </div>
                    </Grid>
                  </Grid>
                  </div>
                </CardContent>
              </Card>
            
              <Card variant="outlined" className="basic_card">
                <CardContent>
                <div className="info_detail">
                  <div className="title_row">
                    <h2>{t("common.locationpermission")}</h2>
                    {canUpdateInfo && (
                      <div className="action_icon hide">
                        <IconButton
                          className="top_action_btn"
                          onClick={locationPermissionOpen("paper")}
                        >
                          <img src={Images.ic_edit} alt="edit" />
                        </IconButton>
                      </div>
                    )}
                    <div className="clearfix"></div>
                  </div>
                  <Grid container>
                    <Grid xs={4}>
                      <div className="label">
                        {t("common.locationAccess")}
                      </div>
                    </Grid>
                    <Grid xs={8}>
                      <div className="result">{locationAccess(isPublic)}</div>
                    </Grid>
                  </Grid>
                  </div>
                </CardContent>
              </Card>

              <Card variant="outlined" className="basic_card">
                <CardContent>
                <div className="info_detail">
                  <div className="title_row">
                    <h2>{t("common.locationcontact")}</h2>
                    {canUpdateInfo && (
                      <div className="action_icon hide">
                        <IconButton
                          className="top_action_btn"
                          onClick={locationContactOpen("paper")}
                        >
                          <img src={Images.ic_edit} alt="edit" />
                        </IconButton>
                      </div>
                    )}
                    <div className="clearfix"></div>
                  </div>
                  <Grid container>
                    <Grid xs={4} className="row">
                    <span className="label">{t("common.name")}</span>
                    </Grid>
                    <Grid xs={8} className="row">
                    <span className="result">
                            {contactInformation?.firstName || ""}{" "}
                            {contactInformation?.lastName || ""}{" "}
                          </span>
                    </Grid>
                    <Grid xs={4} className="row">
                    <span className="label">{t("common.contacts.telephonefullform")}</span>
                    </Grid>
                    <Grid xs={8} className="row">
                    <span className="result">
                            {contactInformation?.phone || ""}
                    </span>
                    </Grid>

                    <Grid xs={4} className="row">
                    <span className="label">{t("common.mobile")}</span>
                    </Grid>
                    <Grid xs={8} className="row">
                    <span className="result">
                            {contactInformation?.mobile || ""}
                          </span>
                    </Grid>
                    <Grid xs={4} className="row">
                      <div className="label">{t("common.contacts.fax")}</div>
                    </Grid>
                    <Grid xs={8} className="row">
                      <div className="result">
                        {contactInformation.fax || ""}
                      </div>
                    </Grid>
                    <Grid xs={4}>
                      <div className="label">{t("common.contacts.email")}</div>
                    </Grid>
                    <Grid xs={8}>
                      <div className="result">
                        {contactInformation.email || ""}
                      </div>
                    </Grid>
                  </Grid>
                  </div>
                </CardContent>
              </Card>

              <Card variant="outlined" className="basic_card">
                <CardContent>
                <div className="info_detail">
                  <div className="title_row">
                    <h2>{t("common.operationalhours")}</h2>
                    {canUpdateInfo && (
                      <div className="action_icon">
                        <IconButton
                          className="top_action_btn"
                          onClick={operationalHoursOpen("paper")}
                        >
                          <img src={Images.ic_edit} alt="edit" />
                        </IconButton>
                      </div>
                    )}
                    <div className="clearfix"></div>
                  </div>

                  { props.depotDetailsData && props.depotDetailsData.locationScheduleInfoDTO && 
                          props.depotDetailsData.locationScheduleInfoDTO.length &&
                          props.depotDetailsData.locationScheduleInfoDTO.map(
                      (value: any, index: any) => (
                        <Grid container key={value.weekDay} className="result row">
                          <Grid xs={4} className="label">
                            {capitalize(value.weekDay)}
                          </Grid>
                          <Grid xs={8} className="result">
                          { isHoursOperation(value.startTime, value.endTime, value.isOpen) }
                          </Grid>
                        </Grid>
                      )
                    )}
                    </div>
                </CardContent>
              </Card>
              {CheckAccess(claims.FORBIDDEN_ACCESS) && 
                <Card variant="outlined" className="basic_card">
                  <CardContent>
                    <div className="title_row">
                      <h2>{toUpper(t("depotinformation.photos"))}</h2>
                      <div className="action_icon">
                        <IconButton
                          className="top_action_btn"
                          onClick={editphotoOpen("paper")}
                        >
                          <img src={Images.ic_edit} alt="edit" />
                        </IconButton>
                      </div>
                      <div className="clearfix"></div>
                      <div className="image_gallery">
                        <ImageGallery
                          items={imageSlider}
                          showPlayButton={false}
                          showBullets={true}
                        />
                      </div>
                    </div>
                    <div className="info_detail"></div>
                  </CardContent>
                </Card>
              }
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card variant="outlined" className="basic_card">
                <CardContent>
                  <div className="title_row">
                    <h2>{toUpper(t("common.location"))}</h2>
                    <div className="clearfix"></div>
                  </div>
                  <div className="section_content host_depot_info mt-13">
                  <CommonGoogleMap {...props}
                      center={latLongValue}
                      zoom={mapDetails.depotLocationMapZoom}
                      options={3}
                      markers={[latLongValue]}
                      gestureHandling="cooperative"
                    />
                  </div>
                </CardContent>
              </Card>
              <Card variant="outlined" className="basic_card">
              { zoneData?.length > 0 &&
                  <CardContent>
                    <div className="title_row">
                      <h2>{toUpper(t("depotinformation.zones"))}</h2>
                      <div className="clearfix"></div>
                    </div>
                    <div className="zone_scroll">
                      {zoneData.map((value: any) => (
                        <div className="info_detail" key={value?.id}>
                          <div className="zone_section  mt-13 mb-20 zone_section_border">
                            <Grid container>
                              <Grid className="" item xs={12}>
                                <div className="zone_floor_info">
                              <List component="nav" className="z_floor_list">
                                    <ListSubheader className="list_head">
                                      {value.name}
                                    </ListSubheader>
                                  </List>
                                </div>
                              </Grid>
                            </Grid>

                            <Grid container>
                        { CheckAccess([claims.FORBIDDEN_ACCESS]) &&
                          <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                                  <div className="zone_detail">
                                    <div className="zone_map zone_map_size">
                                  <CommonGoogleMap {...props}
                                        center={latLongValue}
                                        zoom={mapDetails.depotLocationMapZoom}
                                        markers={[latLongValue]}
                                      />
                                    </div>
                                    {/* <div className="tb_zone_section hide">
                                <div className="label">{toUpper(t("common.access"))}</div>
                                <div className="result">
                                {t("common.privatezone")}
                                </div>
                              </div>
                              <div className="tb_zone_section">
                                <div className="label">{toUpper(t("common.notes"))}</div>
                                <div className="result">
                                  {value.notes}
                                </div>
                              </div> */}
                                  </div>
                                </Grid>
                              }
                          <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                                <div className="zone_floor_info">
                              <List component="nav" className="z_floor_list">
                                    <ListSubheader className="list_head mb-20">
                                      <Grid container className="list_sub_head">
                                        {/* <Grid item xs={12} sm={6} md={4} lg={5}>
                                      {value.totalStation + ' ' + t("common.station", {count: value.totalStation})}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                      {value.totalPort + ' ' + t("common.port", {count: value.totalPort})}
                                    </Grid> */}
                                    {value.totalStation + ' ' + t("common.station", {count: value.totalStation})}{", "}
                                    {value.totalPort + ' ' + t("common.port", {count: value.totalPort})}
                                      </Grid>
                                    </ListSubheader>
                                {value?.stations?.map((val: ObjectDetails, index:number) => (
                                <ListItem key={val?.id} >
                                  {val?.modelImageReference &&
                                              <ListItemIcon className="zone_icon">
                                                <img
                                                  src={val.modelImageReference}
                                                  alt="edit"
                                                />
                                  </ListItemIcon>}
                                  <ListItemText className="cursor_pointer" onClick={() => navigateToDepotStationDetails(val)}>
                                              {val?.name || val?.serialNumber}
                                            </ListItemText>
                                </ListItem>))} 
                                  </List>
                                </div>
                                <div className="tb_zone_section hide">
                              <div className="label">{toUpper(t("common.access"))}</div>
                                  <div className="result">
                                    {t("common.privatezone")}
                                  </div>
                                </div>
                                <div className="tb_zone_section">
                              <div className="label">{toUpper(t("common.notes"))}</div>
                              <div className="result">
                                {value.notes}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                }
              </Card>
            </Grid>
          </Grid>
        </div>
       }
    </>
  );
};
export { EditOrganizationDetails };
export default DepotInformation;
