import React, {useState} from "react";
import {Grid, Button, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {deleteMethod, ApiEndPoint, postMethod} from "services";
import {urlReplace} from "common";
import ConfirmDialogPopupComponent from "../../../components/confirmDialogPopupComponent";

const ZoneFormDetails = (props: any) => {
    const {t} = useTranslation();
    const {name, notes} = props?.zoneInformation || {};
    const editFormType = 'Edit';
    type ZoneInputs = { name: string, notes: string, };
    const [openDeleteAccount, setDeleteAccountOpen] = useState(false);
    const zoneSize = notes ? notes.length : 0;
    const [zoneDescSize, setZoneDescSize] = useState(zoneSize);

    let zoneDeleteFieldDetails: any = [{id: 1, columnLabel: "common.deletezonemessage", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const zoneDeleteCancelButtonAction = {isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true, classFormat: ''};
    const zoneDeleteRemoveButtonAction = {isVisible: true, buttonName: 'common.deletebutton', buttonPermssion: true, classFormat: 'red_bg', disabled: false };

    const deleteAccountOpen = () => {
        setDeleteAccountOpen(true);
    };

    const deleteZone = () => {
        zoneDeleteRemoveButtonAction.disabled = true;
        zoneDeleteRemoveButtonAction.classFormat = 'disable';
        deleteZoneDetails();
    };

    const {register, handleSubmit, formState: {errors}} = useForm<ZoneInputs>({
        defaultValues: {name: name, notes: notes}
    });

    const onSubmit = (data: any) => {props?.assignedStationMethod(data);};

    const formTypeValue = (formTypeVal: string) => {
        return formTypeVal === editFormType ? t("common.editzone") : t("common.createnewzone");
    };

    const deleteZoneClose = () => {
        zoneDeleteRemoveButtonAction.disabled = false;
        setDeleteAccountOpen(false);
    };

    const deleteZoneDetails = async () => {
        const url = urlReplace(ApiEndPoint.zoneDetails, "{ZONE-ID}", props?.currentZoneId);
        await deleteMethod(url).then((res) => {
            zoneDeleteRemoveButtonAction.disabled = false;
            zoneDeleteRemoveButtonAction.classFormat = 'red_bg';
            if (res.status === 200) {
                moveZoneToLocationLevel();
            } else {
                zoneDeleteFieldDetails[0]['columnLabel'] = "common.invalidzonemessage";
            }
        }).catch((err) => {
            zoneDeleteFieldDetails[0]['columnLabel'] = "common.invalidzonemessage";
            zoneDeleteRemoveButtonAction.disabled = false;
            zoneDeleteRemoveButtonAction.classFormat = 'red_bg';
            console.error("Error", err);
        });
    };

    const moveZoneToLocationLevel = async () => {
        let params = '?zoneId=' + props?.currentZoneId;
        let body = {};
        await postMethod(ApiEndPoint.fleetZoneDelete, params, body, false, true)
            .then((res) => {
                props?.deleteZoneCallback();
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };


    const handleChange = (e: any) => {
        let value = e.target.value;
        setZoneDescSize(value.length);
    };

    const trapSpacesForRequiredFields = (value: any) => !!value.trim();

    return (<>
        <div className="dialog_box">
            <ConfirmDialogPopupComponent
                popUpPageOpen={openDeleteAccount}
                popUpPageClose={deleteZoneClose}
                headerName={"common.deletezone"}
                fieldDetails={zoneDeleteFieldDetails}
                submitAction={zoneDeleteRemoveButtonAction}
                cancelAction={zoneDeleteCancelButtonAction }
                submitData={deleteZone}
            />
        </div>

        <div data-testid="zone_form_details">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="dialog_title">
                    <h4>{formTypeValue(props?.formType)}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button
                            className="btn_white text_btn_theme"
                            onClick={props?.zoneClose}
                        >
                            {t("common.cancelbutton")}
                        </Button>
                        <Button className="btn_white blue_bg increase_padding" type="submit">
                            {t("common.nextbutton")}
                        </Button>
                    </div>
                </div>
                <DialogContent dividers="paper" {...props}>
                    <DialogContentText id="scroll-dialog-description">
                        <div className="information_section delegates_details">
                            <div className="defult_form mt-10">
                                <div className="section_group">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={12}>
                                            <div className={`form_col ${errors?.name && 'error'}`}>
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <label>{t("common.zonename")} *</label>
                                                        <TextField
                                                            variant="outlined"
                                                            inputProps={{maxLength: 255}}
                                                            {...register("name", {
                                                                required: true,
                                                                validate: {trapSpacesForRequiredFields}
                                                            })}
                                                        />
                                                        {errors?.name && <span
                                                            className="error_msg">{t("hostsetting.zone.name.required")}</span>}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="section_group">
                                    <div className="form_col">
                                        <Grid
                                            className="single_line textarea"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                                <label>{t("common.notes")}</label>
                                                <TextField
                                                    multiline
                                                    inputProps={{maxLength: 255}}
                                                    helperText={t("common.zone.maxlengthcharacters", {zoneDescSize})}
                                                    rows={4}
                                                    {...register("notes")}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                {props?.formType === editFormType &&
                                <div className="section_group">
                                    <div className="form_col">
                                        <Grid
                                            className="single_line textarea"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                                <label></label>
                                                <div className="button_row mt-0 full_w_155">
                                                    <Button className="delet_acct ml_0 btn_white block_btn"
                                                            onClick={deleteAccountOpen}>
                                                        {t("common.deletezone")}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </form>
        </div>
    </>);
};

export default ZoneFormDetails;
