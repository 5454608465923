import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
  ButtonGroup,
  LinearProgress
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import {useDispatch} from "react-redux";
import {defaultVariable, keyCloakConfig, rolePermissionCheck} from "common";
import "../../depotStationDetails.scss";
import Images from "assets/images";
import { showErrorMessages } from "redux/actions/errorMessages";
import { manageLcdDisplayDetails } from "services/LcdDisplayService";
import PopperDetails from "./popperDetails";
import { LCD_MSG_LENGTH, ZERO_LENGTH } from "../stationLcdDisplayHelpers";

const AddDisplayDetails = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const simpleValidator: any = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState<any>();
  const [spinnerOff, setSpinnerOff] = useState(false);
  const [dropDownOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const userRoles = keyCloakConfig?.realmAccess?.roles;
  const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
  const [inputFieldsEdit, setInputFieldsEdit] = useState<any>([
    { messagePartOne: "", messagePartTwo: "" },
  ]);


  const resetFleetVehicleFormData = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  const closeAddDetail = () => {
    props.closeAddDetail();
    setOpen(false);
    const inputFieldsView = props.inputFieldsView;
    setInputFieldsEdit(inputFieldsView);
    resetFleetVehicleFormData();
  };


  const handleToggle = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(!dropDownOpen);
  };

  const addNewMessageRow = () => {
    let validation = true;
    inputFieldsEdit.forEach((inputField: any) => {
        if(inputField.messagePartOne === "" || inputField.messagePartTwo === "") {
            validation = false;
            simpleValidator.current.showMessages();
        }            
    }); 
    if (validation) {
        setInputFieldsEdit([
            ...inputFieldsEdit,
            { messagePartOne: "", messagePartTwo: "" },
        ]);
        hideErrors();
    } else {
        showErrors();
    }
};

  const showErrors = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };
  const hideErrors = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  const removeMessageRow = (index: any) => {
    const list = [...inputFieldsEdit];
    list.splice(index, 1);
    setInputFieldsEdit(list);
  };

  const handleOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    inputFieldsEdit[index][name] = value;
    setInputFieldsEdit([...inputFieldsEdit]);
  };

  const addLcdMessages = (allStations: boolean) => {
    let valid = simpleValidator.current.allValid();
        inputFieldsEdit.forEach((inputField: any) => {
            let messagePartOneLength = inputField?.messagePartOne?.length;
            let messagePartTwoLength = inputField?.messagePartTwo?.length;
            if (
                !(
                    messagePartOneLength > ZERO_LENGTH &&
                    messagePartOneLength <= LCD_MSG_LENGTH
                ) ||
                !(
                    messagePartTwoLength > ZERO_LENGTH &&
                    messagePartTwoLength <= LCD_MSG_LENGTH
                )
            ) {
                valid = false;
                simpleValidator.current.showMessages();
            }
        }); 
    setOpen(false);
    if (valid) {
      setSpinnerOff(true);
      let body = {
        lcdMessages: inputFieldsEdit,
        organization: allStations,
                chargerId:props.stationId
      };
      manageLcdDisplayDetails(body).then((res: any) => {
        if (res.status) {
          closeAddDetail();
          props.getLcdDisplayDetail();
          setSpinnerOff(false);
        } else {
          dispatch(
            showErrorMessages({
              error: true,
              message: res.message,
              type: "error",
              operation: "",
            })
          );
          setSpinnerOff(false);
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const inputFieldsArray = props.inputFieldsView.map((inputField: any) => (
      { ...inputField }
    ))
    setInputFieldsEdit(inputFieldsArray);
  }, []);

  return (
    <>
      {/* Dialog for create New Lcd Display Messages */}
      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_detail_section edit-user-info dialog_container_center"
          open={props.addDetail}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("lcdDisplay.displayMessage")}</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={closeAddDetail}
              >
                {t("common.cancelbutton")}
              </Button>

              <ButtonGroup variant="contained" aria-label="split button" className={spinnerOff ? "col_disable" : ""}>
                <Button className="btn_white blue_bg btn_save_with_dropdown" onClick={(e) => addLcdMessages(false)}>
                  {t("common.save")}
                </Button>
                  {!isDelegate &&
                   <Button size="small" className="save_dropdown_btn" aria-label="select merge strategy" aria-haspopup="menu" onClick={handleToggle}>
                      <ArrowDropDown />
                   </Button>
                  }
              </ButtonGroup>
                {!isDelegate &&
                  <PopperDetails
                      dropDownOpen={dropDownOpen}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      addLcdMessages={() => addLcdMessages(true)}
                  />
                }
            </div>
          </div>
          {spinnerOff && <LinearProgress />}
          <DialogContent>
            <DialogContentText id="scroll-dialog-description">
              <div className="defult_form mt-21">
                <div className="section_group add_display_message">
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                  >
                    <Grid item xs={12}>
                      {inputFieldsEdit.map((messages: any, index: any) => (
                        <div className="form_col" key={index}>
                          <Grid item xs={12}>
                            <div className="default_form">
                              <div className="section_group">
                                <div className="form_col">
                                  {index === 0 && (
                                    <Grid
                                      className="single_line"
                                      container
                                      justifyContent="flex-start"
                                      alignItems="flex-start"
                                      spacing={1}
                                    >
                                      <Grid item xs={4}>
                                        <label>
                                          {index === 0 &&
                                            t("lcdDisplay.message")}
                                        </label>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <label className="w_100per full_w">
                                          {t("lcdDisplay.line1")}
                                        </label>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <label className="w_100per full_w">
                                          {t("lcdDisplay.line2")}
                                        </label>
                                      </Grid>
                                    </Grid>
                                  )}
                                  <Grid
                                    className="single_line"
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                  >
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={3}>
                                      <TextField
                                        className="w_100per full_w"
                                        variant="outlined"
                                        name="messagePartOne"
                                        onChange={(e) =>
                                          handleOnChange(e, index)
                                        }
                                        value={messages?.messagePartOne}
                                      />
                                      <Grid>
                                        <cite className="srv-validation-message">
                                          {simpleValidator.current.message(
                                            t("lcdDisplay.message"),
                                            messages?.messagePartOne,
                                            `required|max:${LCD_MSG_LENGTH}`
                                          )}
                                        </cite>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextField
                                        className="w_100per full_w"
                                        variant="outlined"
                                        name="messagePartTwo"
                                        value={messages?.messagePartTwo}
                                        onChange={(e) =>
                                          handleOnChange(e, index)
                                        }
                                      />
                                      <Grid>
                                        <cite className="srv-validation-message">
                                          {simpleValidator.current.message(
                                            t("lcdDisplay.message"),
                                            messages?.messagePartTwo,
                                            `required|max:${LCD_MSG_LENGTH}`
                                          )}
                                        </cite>
                                      </Grid>
                                    </Grid>
                                    <Grid>
                                      <span>
                                        {index > 0 && (
                                          <IconButton
                                            className="top_action_btn"
                                            onClick={() =>
                                              removeMessageRow(index)
                                            }
                                          >
                                            <img
                                              src={Images.ic_close}
                                              alt="Close"
                                            />
                                          </IconButton>
                                        )}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </div>
                      ))}
                      {inputFieldsEdit.length < 4 && (
                        <div className="form_col">
                          <Grid
                            className="single_line"
                            container
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                          >
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                              <span
                                className="addNewMessage"
                                onClick={addNewMessageRow}
                              >
                                {t("lcdDisplay.addAnother")}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Dialog Add End */}
    </>
  );
};
export default AddDisplayDetails;
