import React from "react";
import {
    AppBar,
    Tabs,
    Tab
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TabPanel, a11yProps, CheckAccess, claims } from 'common';
import BlinkMyCard from "./blinkMyCard";
import RequestBlinkCard from "./requestBlinkCard";

const BlinkCard = () => {
    const { t } = useTranslation();
    

    const [value, setValue] = React.useState(0);
    const handleChange = (event: any, newValue: any) => {
        if (event) {
            setValue(newValue);
        }
    };

    return (
        <>
            <main className="blinkcards" data-testid="blinkcards">
                <div className="depot_details free_templates station_details">
                    <div className="detail_page_tab">
                        <AppBar
                            className="defult_tab position_fixed_nav"
                            position="static"
                            color="default"
                        >
                            <Tabs
                                className="tab_nav"
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable force tabs example"
                            >
                                {CheckAccess(claims.FORBIDDEN_ACCESS) &&
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.blinkcard")}
                                        {...a11yProps(0)}
                                    />
                                }
                                <Tab
                                    className="tab_nav_item"
                                    label={t("common.requestblinkcard")}
                                    {...a11yProps(0)}
                                    />
                            </Tabs>
                        </AppBar>
                        {CheckAccess(claims.FORBIDDEN_ACCESS) &&
                         <TabPanel className="tab_detail" value={value} index={0}>
                            <BlinkMyCard/>
                        </TabPanel>
                        }
                        <TabPanel className="tab_detail" value={value} index={0}>
                            <RequestBlinkCard/>
                        </TabPanel>
                    </div>
                </div>
            </main>

        </>
    );
};
export default BlinkCard;