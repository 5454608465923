import React, { useState, useEffect, useRef } from "react";
import { putMethod, ApiEndPoint } from "services";

import SimpleReactValidator from "simple-react-validator";
import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    Checkbox, Tooltip
} from "@material-ui/core";
import "./delegates.scss";
import { useTranslation } from "react-i18next";
import DragDropImages from "components/dragDropImages";
import { EditDelegates, ObjectDetails, Delegates } from "common/interfaces";
import { bytesToSize, CheckAccess, claims, defaultVariable, keyCloakConfig, LabelInfo, urlReplace } from "common";

const EditDelegate = (props: EditDelegates) => {

    //This state needs to be on top as character count needs to be set whenever data changes in initialData
    const [notesCharCount, setNotesCharCount] = React.useState(0);
    
    const userInfo = keyCloakConfig?.tokenParsed;
    const initialData = (cVal: ObjectDetails) => {
        
        let initialValue: Delegates = {
            firstName: cVal?.firstName,
            lastName: cVal?.lastName,
            mobile: cVal?.mobile,
            email: cVal?.email,
            notes: cVal?.notes,
            manageUsersEnabled: cVal?.manageUsersEnabled,
            hostId: cVal?.hostId,
        };

        if (initialValue.notes !== undefined && initialValue.notes !== null && initialValue.notes !== '' ) {
            setNotesCharCount(initialValue.notes.length);
        }
        return initialValue;
    }

    const simpleValidator = useRef(new SimpleReactValidator());
    const { t } = useTranslation();
    const [currentDelegate, setCurrentDelegate] = useState<any>(initialData({}));

    const [, setImageList] = useState<any>([]);
    const [, forceUpdate] = useState<any>();
    const SIZE = bytesToSize(defaultVariable.DELEGATE_FILESIZE, t);

    const receiveDataImages = (value: any) => {
        setImageList(value);
    };

    const toggleManageUsers = (e: any) => {
        const checked = e.target.checked;
        setCurrentDelegate({ ...currentDelegate, manageUsersEnabled: checked });
    }

    useEffect(()=>{
        setCurrentDelegate (initialData (props?.currentDelegate));
    },[props?.currentDelegate])

    const handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setCurrentDelegate({ ...currentDelegate, [name]: value });
    }; 
    
    const submitEditForm = () => {
        if (simpleValidator.current.allValid()) {
            const url = urlReplace(ApiEndPoint.editDelegate, "{DELEGATE-ID}", props?.currentDelegate.id);

            putMethod(url, '', currentDelegate)
                .then((res) => {
                    props.editDelegateClose();
                    props?.getDelegateDataById();
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        } else {
            simpleValidator.current.showMessages();
            // rerender to show messages for the first time, autoForceUpdate option to do this automatically`
            forceUpdate(1);
        }
    };  

    return (
        <>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit_photo_gallery  dialog_container_center zone_settings_dialog"
                    {...props}
                    open={props.openEditDelegate}
                    onClose={props.editDelegateClose}
                    scroll={props.scrollEditDelegate}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("hostsetting.editdelegate")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button className="btn_white text_btn_theme" onClick={props.editDelegateClose} >
                                {t("common.cancelbutton")}
                            </Button>
                            <Button className="btn_white blue_bg" onClick={submitEditForm} >
                                {t("common.savechangesbutton")}
                            </Button>
                        </div>
                    </div>
                    <DialogContent >
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form">
                                <div className="section_group mt-20">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={12}>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <LabelInfo name={t("common.firstname")} isMandatory={false}/>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                currentDelegate?.firstName
                                                            }
                                                            name="firstName"
                                                            className="disable"
                                                            disabled 
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <cite className="error_msg">
                                                            {simpleValidator.current.message(
                                                                "firstName",
                                                                currentDelegate?.firstName,
                                                                "required"
                                                            )}
                                                        </cite>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                    <LabelInfo name={t("common.lastname")} isMandatory={false}/>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                currentDelegate?.lastName
                                                            }
                                                            name="lastName"
                                                            className="disable"
                                                            disabled 
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <cite className="error_msg">
                                                            {simpleValidator.current.message(
                                                                "lastName",
                                                                currentDelegate?.lastName,
                                                                "required"
                                                            )}
                                                        </cite>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <LabelInfo name={t("common.mobile")} isMandatory={false}/>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                currentDelegate?.mobile
                                                            }
                                                            name="mobile"
                                                            className="disable"
                                                            disabled
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <LabelInfo name={t("common.email")} isMandatory={false}/>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                currentDelegate?.email
                                                            }
                                                            name="email"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            className="disable"
                                                            disabled  
                                                        />
                                                        <cite className="error_msg">
                                                            {simpleValidator.current.message(
                                                                "email",
                                                                currentDelegate?.email,
                                                                "required|email"
                                                            )}
                                                        </cite>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="section_group">
                                    <div className="form_col checkbox space_set">
                                        <Grid
                                            className="single_line textarea"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                            <LabelInfo name={t("common.manageusers")} isMandatory={false}/>
                                                <Tooltip title={currentDelegate?.hostId !== userInfo?.hostId ? t('delegates.manage.user.warning'): ''}>
                                                    <span><Checkbox name={t("common.manageusers")}
                                                                    checked={ currentDelegate?.hostId === userInfo?.hostId && currentDelegate?.manageUsersEnabled}
                                                                    onClick={toggleManageUsers}
                                                                    disabled={currentDelegate?.hostId !== userInfo?.hostId}/>
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="section_group">
                                    <div className="form_col">
                                        <Grid
                                            className="single_line textarea"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                            <LabelInfo name={t("common.notes")} isMandatory={false}/>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    helperText={
                                                        notesCharCount + ' ' + t("common.charLengthInfo")
                                                    }
                                                    value={
                                                        currentDelegate?.notes
                                                    }
                                                    variant="outlined"
                                                    name="notes"
                                                    onChange={e => { handleChange(e); setNotesCharCount(e.target.value.length) }}
                                                    inputProps={{ maxLength: defaultVariable.textAreaLength }}                                                    
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                { CheckAccess(claims.FORBIDDEN_ACCESS) && 
                                    <div className="gallery_photo_upload">
                                    {/* Image uploading Start*/}
                                    <DragDropImages fileMessage={t("dragimage.profileFilesizetype",{SIZE})} fileSize={defaultVariable.DELEGATE_FILESIZE} receiveDataImages={receiveDataImages}/>
                                    {/* Image Uploading End */}
                                    </div>
                                }
                                <div className="button_row mt-0 full_right">
                                    <Button className="delete_usergroup" onClick={props?.deleteAccountOpen}>
                                        {t("delegates.delete")}
                                    </Button>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};

export default EditDelegate;
