import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, LinearProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {ObjectDetails} from "../../../../../../common/interfaces";
import {DataGrid, GridRowId} from "@material-ui/data-grid";
import {WaitListColumns} from "../../../../../tableColumns/waitListColumns";
import "../../waitList.scss";
import {manageWaitListStations} from "../../../../../../services/waitListService";
import {SUCCESS} from "../../../../../../common";
import {showErrorMessages} from "../../../../../../redux/actions/errorMessages";
import {WAITLIST_ERROR_MESSAGE} from "../../waitListHelper";


const ManageStations = (props: ObjectDetails) => {

    let {t} = useTranslation();
    let dispatch = useDispatch();
    const {openManageStation, stationList, locationId} = props;
    const  waitListColumns = WaitListColumns();
    const [spinnerOff, setSpinnerOff] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

    useEffect(() => {
        if(stationList.length > 0) {
            let stations = stationList.filter((val: any) => val.is_waitlist_enabled).map((val1: any) => val1.id);
            setSelectionModel(stations);
        }
    }, [stationList]);

    const openManageStationsClose = () => {
        props.dataRefresh();
        props.setOpenManageStation(false);
    };

    const handleSaveWaitListConfiguration = () => {
        setSpinnerOff(true);
        let body = {location_id: locationId, station_list : selectionModel};
        manageWaitListStations(body).then((res : any) => {
            if(res.status === SUCCESS) {
                openManageStationsClose();
            } else {
                dispatch(showErrorMessages({error: true, message: t(WAITLIST_ERROR_MESSAGE[res.data.error_code]), type: "error", operation: "",}));
            }
            setSpinnerOff(false);
        }, (error: any) => {
            setSpinnerOff(false);
        });
    };

    const checkboxClickCallback = (ids: any) => {
        setSelectionModel(ids);
    };

    return (
        <div className="dialog_box">
            <Dialog
                className="dialog_container edit_detail_section edit-user-info dialog_container_center manage_stations"
                open={openManageStation}
                onClose={openManageStationsClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{`${t("fleet.assignStation")} ${t('common.to')} ${t('common.waitlist')}`}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme"  data-testid="btn-cancel"
                                onClick={openManageStationsClose}>{t("common.cancelbutton")}</Button>
                        <Button className={`btn_white blue_bg`}  data-testid="btn-save"
                                onClick={handleSaveWaitListConfiguration}>{t("common.savechanges")}</Button>
                    </div>
                </div>
                {spinnerOff && (<LinearProgress/>)}
                <DialogContent>
                    <div className="table_scroll stations_assigned mb-20">
                        <div className="default_table sorting_table hide_select_all">
                            <DataGrid
                                autoHeight
                                vertical-align="top"
                                className="table information_section"
                                rowHeight={59}
                                headerHeight={35}
                                rows={stationList}
                                columns={waitListColumns}
                                checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={checkboxClickCallback}
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
};


export default ManageStations;