import React, { useState, useEffect } from "react";
import {
    Grid,
    Select,
    IconButton,
    MenuItem,
    OutlinedInput
}
    from "@material-ui/core";
import { PricingTypeGrid } from "common/interfaces/pricingPlan";
import { useTranslation } from "react-i18next";
import {
    filterDropDownOptions, displayRemoveIcon, months, days, times, addFieldForTimeDay,
    removeFieldForDayTime, hours, handletimeOrHour, TARIFF_MUL_FAC, TARIFF_PARKING_COST, TARIFF_ENERGY_COST, CTEP_ENABLED,
    createDetailedViewObject, createDetailedViewArrayEdit
} from "../pricingPlanHelpers";
import Images from "assets/images";
import { checkFloatValue } from "common/methodHelpers";

const DayDurationPricingPlan = (props: PricingTypeGrid) => {

    const { t } = useTranslation();
    const [duration, setDuration] = useState<any>([]);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        setDuration(handletimeOrHour(props, hours, times));
        setTimeout(() => {
            setShowErrorMsg(false)
        }, 8000)
    }, [props])

    const addNewSplitInTime = (type: string, event: any, monthIndex: number, dayIndex: number, timeIndex: number, total: any) => {
        let newPricingDetails = addFieldForTimeDay(type, event, monthIndex, dayIndex, timeIndex, total, props.pricingPlanData);
        props.setPricingPlanData([...newPricingDetails]);
        createDetailedPricingObject(); 
    }


    const handleCostUpdate = (monthIndex: number, dayIndex: number, timeIndex: number, event: any) => {
        let floatValue = checkFloatValue(event);
        if (floatValue) {
            let cost = event.target.value;
            if ((props.parkingFee && cost > (TARIFF_MUL_FAC * TARIFF_PARKING_COST)) || (props.energyFee && cost > (TARIFF_MUL_FAC * TARIFF_ENERGY_COST))) {
                setShowErrorMsg(true);
            }
            props.pricingPlanData[monthIndex].day[dayIndex].time[timeIndex].cost = cost;
            props.setPricingPlanData([...props.pricingPlanData]);
            createDetailedPricingObject(); 
        }
    }

    const createDetailedPricingObject = () => {
        let detailedObject = createDetailedViewObject(props.pricingPlanData, props.timeArrayDetail);
        props.setDetailedViewobject(detailedObject);
        let detailedPricing = createDetailedViewArrayEdit(detailedObject, props.timeArrayDetail); 
        props.setTimeArrayDetailedKeys(Object.keys(detailedPricing));
    }


    const handleRemoveField = (day: any, time: any, monthIndex: number, dayIndex: number, timeIndex: number) => {
        let newPricingDetails = removeFieldForDayTime(props.pricingPlanData, day, time, monthIndex, dayIndex, timeIndex);
        props.setPricingPlanData([...newPricingDetails]);
        createDetailedPricingObject(); 
    }

    return (
        <>
            <Grid className="time_of_day">
                <div className="pc_time_padding">
                    <Grid container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}>
                        <Grid item xs={3}><span className="label">{t("pricing.month")}</span></Grid>
                        <Grid item xs={3}><span className="label">{t("pricing.day")}</span></Grid>
                        <Grid item xs={3}><span className="label">{t("pricing.hours")}</span></Grid>
                        <Grid item xs={3} className="header_padding"><span className="label">{t("pricing.cost")}</span></Grid>
                    </Grid>
                </div>
                <div className="defult_form default_form">
                    <div className="form_col">
                        {
                            props.pricingPlanData && props.pricingPlanData.map((monthVal: any, monthIndex: number) => (
                                <Grid container
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    className="month_separator"
                                    spacing={0}>
                                    <Grid item xs={3}>
                                        <Grid container
                                            className="single_line_dropdown"
                                            spacing={2}>
                                            <Grid item>
                                                <Select
                                                    className="disable"
                                                    variant="outlined"
                                                    name="startMonth"
                                                    disabled
                                                    value={monthVal.start}
                                                    defaultValue={monthVal.start}>
                                                    {months.map((month: any) => (
                                                        <MenuItem className="defult_select_option"
                                                            key={month.value}
                                                            value={month.value}>
                                                            <Grid>{month.label}</Grid>
                                                        </MenuItem>)
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    className="disable"
                                                    variant="outlined"
                                                    name="endMonth"
                                                    defaultValue={monthVal.end}
                                                    value={monthVal.end}
                                                    disabled>
                                                    {filterDropDownOptions('month', months, monthIndex, monthVal.start).map((month, i) => (
                                                        <MenuItem className="defult_select_option"
                                                            key={month.value}
                                                            value={month.value}>
                                                            <Grid>{month.label}</Grid>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs>
                                        {
                                            monthVal.day && monthVal.day.map((dayVal: any, dayIndex: number) => (
                                                <Grid container
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    className={dayIndex > 0 ? "pt-10" : ''}
                                                    spacing={0}>
                                                    <Grid item xs={4}>
                                                        <Grid container
                                                            className="single_line_dropdown"
                                                            spacing={2}>
                                                            <Grid item>
                                                                <Select
                                                                    className="disable"
                                                                    variant="outlined"
                                                                    name="startDay"
                                                                    disabled
                                                                    value={dayVal.start}
                                                                    defaultValue={dayVal.start}>
                                                                    {days.map((day: any) => (
                                                                        <MenuItem className="defult_select_option"
                                                                            key={day.value}
                                                                            value={day.value}>
                                                                            <Grid>{day.label}</Grid>
                                                                        </MenuItem>)
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item>
                                                                <Select
                                                                    variant="outlined"
                                                                    name="endDay"
                                                                    className = {props.ctepValue === CTEP_ENABLED ? "disable" : ""}
                                                                    defaultValue={dayVal.end}
                                                                    value={dayVal.end}
                                                                    disabled = {props.ctepValue === CTEP_ENABLED}
                                                                    onChange={(e) => addNewSplitInTime('day', e, monthIndex, dayIndex, 0, monthVal.day.length)}>
                                                                    {filterDropDownOptions('day', days, dayIndex, dayVal.start).map((day) => (
                                                                        <MenuItem className="defult_select_option"
                                                                            key={day.value}
                                                                            value={day.value}>
                                                                            <Grid>{day.label}</Grid>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs>
                                                        {
                                                            dayVal.time && dayVal.time.map((timeVal: any, timeIndex: number) => (
                                                                <Grid container
                                                                    justifyContent="space-between"
                                                                    alignItems="flex-start"
                                                                    className=""
                                                                    spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        <Grid container
                                                                            className="single_line_dropdown"
                                                                            spacing={2}>
                                                                            <Grid item>
                                                                                <Select
                                                                                    className="disable"
                                                                                    variant="outlined"
                                                                                    name="timeDay"
                                                                                    disabled
                                                                                    value={timeVal.start}
                                                                                    defaultValue={timeVal.start}>
                                                                                    {duration.map((time: any) => (
                                                                                        <MenuItem
                                                                                            className="defult_select_option"
                                                                                            key={time.value}
                                                                                            value={time.value}>
                                                                                            <Grid>{time.label}</Grid>
                                                                                        </MenuItem>)
                                                                                    )}
                                                                                </Select>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Select
                                                                                    variant="outlined"
                                                                                    name="endDay"
                                                                                    id = {`endDay-${timeIndex}`}
                                                                                    className = {props.ctepValue === CTEP_ENABLED ? "disable" : ""}
                                                                                    defaultValue={timeVal.end}
                                                                                    value={timeVal.end}
                                                                                    disabled = {props.ctepValue === CTEP_ENABLED}
                                                                                    inputProps = {{"data-testid": `hour-input-${timeIndex}`}}
                                                                                    onChange={(e) => addNewSplitInTime('times', e, monthIndex, dayIndex, timeIndex, dayVal.time.length)}>
                                                                                    {filterDropDownOptions('times', duration, timeIndex, timeVal.start).map((time) => (
                                                                                        <MenuItem
                                                                                            className="defult_select_option"
                                                                                            key={time.value}
                                                                                            value={time.value}>
                                                                                            <Grid>{time.label}</Grid>
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container item xs={6} className="custom_input" direction="row">
                                                                        <div className="pricing_rate">
                                                                            <OutlinedInput
                                                                                className={"`input_text`"}
                                                                                name={`vip`}
                                                                                value={timeVal.cost}
                                                                                placeholder='0.00'
                                                                                inputProps={{ maxLength: 5, "data-testid": `cost-input-${timeIndex}` }}
                                                                                onChange={(event) => handleCostUpdate(monthIndex, dayIndex, timeIndex, event)}
                                                                            />
                                                                        </div>
                                                                        {displayRemoveIcon( monthVal.day, dayVal.time, dayIndex, timeIndex) &&
                                                                            <IconButton data-testid = {`action-button-${timeIndex}`}
                                                                                onClick={() => handleRemoveField(monthVal.day, dayVal.time, monthIndex, dayIndex, timeIndex)}>
                                                                                <img className="img_ic"
                                                                                    src={Images.ic_close_white_background}
                                                                                    alt="" />
                                                                            </IconButton>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </div>
                </div>
            </Grid>
            {showErrorMsg &&
                <div className="error_msg_cost">{t("pricing.highcost")}</div>
            }
        </>
    );
};

export default DayDurationPricingPlan;