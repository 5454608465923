import { Button, CircularProgress, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { pageDetails } from "common";
import { columnNameTranslate, HandlePaginationLabel } from "common/methodHelpers";
import CommonPagination from "components/commonPagination";
import { ACTIVE, DELETE } from "pages/fleet/fleetManagementHelper";
import { VehicleAuthCardColComponent } from "pages/tableColumns/fleetVehicleColumns";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchFleetVehicleAuth } from "services/fleetManagementService";
import AddVehicleAuthCard from "./addVehicleAuthCard";
import DeleteVehicleAuthCard from "./deleteVehicleAuthCard";


const FleetVehicleAuthCard = (props:any) => {

    const { t } = useTranslation();
    const pageSize = pageDetails.pageSize;
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState(false);
    const [fleetVehicleAuthCardDetails, setFleetVehicleAuthCardDetails] = useState<any>({});
    const AuthCardColumns = VehicleAuthCardColComponent();
    const [openAddAuthCard, setAddAuthCardOpen] = useState(false);
    const { vehicleId, groupId, isGroupLoading } = props;
    const [deleteAuthCard, setDeleteAuthCard] = useState({ vehicle_id: vehicleId ,pass:""});
    const [openDeleteAuthCard, setDeleteAuthCardOpen] = useState(false);

    useEffect(() => {
        getFleetAuthCardDetails()
    }, [currentPage])


    const getFleetAuthCardDetails = async () => {
        setLoadingPage(true);
        let params = { currentPage: currentPage, size: pageDetails.pageSize, vehicleId: vehicleId }
        await fetchFleetVehicleAuth(params, false).then((res: any) => {
            setLoadingPage(false);
            setFleetVehicleAuthCardDetails(res)
        })
    };

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)
    };

    const fleetVehicleDataSet = (value: any) => {
        let fleetVehicleAuthCardTableData = []
        if (value && value.fleetCards) {
            fleetVehicleAuthCardTableData = value.fleetCards.map((val: any, index: number) => ({ ...val, id: index }))
        }
        return fleetVehicleAuthCardTableData
    };

    const addAuthCardClose = () => {
        setAddAuthCardOpen(false);
    };

    const addAuthCardOpen = () => {
        setAddAuthCardOpen(true);
    };

    const deleteAuthCardClose = () => {
        setDeleteAuthCardOpen(false);
    };

    const deleteAuthCardOpen = () => {
        setDeleteAuthCardOpen(true);
    };

    const handleDeleteButton = (params: any) => {
        if (params.field === DELETE && params?.row?.status === ACTIVE) {
            setDeleteAuthCard({
                ...deleteAuthCard,
                pass: params?.row?.pass_no ? params?.row?.pass_no : "",
            });
            deleteAuthCardOpen();
        }
    };

    return (
        <>
            <AddVehicleAuthCard
                openAddAuthCard={openAddAuthCard}
                addAuthCardClose={addAuthCardClose}
                dataRefresh={getFleetAuthCardDetails}
                vehicleId={vehicleId}
                groupId={groupId}
            />
            <DeleteVehicleAuthCard
                deleteAuthcard={deleteAuthCard}
                openDeleteAuthCard={openDeleteAuthCard}
                deleteAuthCardClose={deleteAuthCardClose}
                dataRefresh={getFleetAuthCardDetails}                
            />
            {loadingPage || isGroupLoading ? <CircularProgress className="inner_progress_userGroup" /> :
                <>
                    <div className="button_row text_right">
                        <Button className="btn_white blue_bg ml_0" onClick={addAuthCardOpen}>
                            {t("fleet.newcard")}
                        </Button>
                    </div>
                    <div className="table_scroll mt-10">
                        <div className="default_table">
                            <DataGrid autoHeight className="table"
                                rowHeight={59}
                                headerHeight={30}
                                rows={fleetVehicleDataSet(fleetVehicleAuthCardDetails)}
                                columns={columnNameTranslate(AuthCardColumns)}
                                pageSize={pageSize}
                                onCellClick={handleDeleteButton}
                                disableSelectionOnClick />
                        </div>
                        <div className="table_footer mt-10">
                            <Grid container direction="row" alignItems="center" spacing={0}>
                                <Grid className="text_left" item xs={8}>
                                    {fleetVehicleAuthCardDetails && fleetVehicleAuthCardDetails?.totalCount > pageSize ?
                                        <CommonPagination
                                            currentPage={currentPage}
                                            totalCount={fleetVehicleAuthCardDetails?.totalCount}
                                            callBackCurrentPage={handleCurrentPage}
                                        /> : <></>}
                                </Grid>
                                <Grid className="text_right" item xs={4}>
                                    <div className="list_number">
                                        {HandlePaginationLabel(fleetVehicleAuthCardDetails?.totalCount, pageSize, currentPage, "fleet.vehicle.authcard")}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default FleetVehicleAuthCard;