import React from "react";
import { Grid } from "@material-ui/core";
import { BLANK, BLANK_DATA_PLACEHOLDER, LabelInfo } from "common";
import { duration } from "pages/fleet/fleetManagementHelper";
import { useTranslation } from "react-i18next";
import { startCase, toLower } from "lodash";

const FleetScheduleEnableComponent = (props: any) => {

    const { scheduleView, scheduleVal, type, borderBottom } = props;
    const { t } = useTranslation();

    const getDurationLabel = (scheduleValue: any) => {
        let val = "";
        for (let value in duration) {
            if (duration.hasOwnProperty(value)) {
                val = duration[value].value;
                if ((String(scheduleValue)).replace(/ /g, '') === "") {
                    return BLANK_DATA_PLACEHOLDER;
                }
                else if (Number(val) === Number(scheduleValue)) {
                    let Index = duration.findIndex((dur: any) => dur.value === val);
                    return duration[Index].label;
                }
            }
        }
    }

    return (
        <>
            {scheduleView && scheduleView.map((schedule: any) => (
                <>
                    <Grid item xs={1}>
                        <Grid container
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            {type === "day" &&
                                <Grid item>
                                    <LabelInfo name={startCase(toLower(schedule[scheduleVal]))}
                                        isMandatory={false} />
                                </Grid>
                            }
                            {type === "scheduleData" &&
                                <Grid item>
                                    <div className="fleet_span">{getDurationLabel(schedule[scheduleVal])}</div>
                                    {borderBottom &&
                                        <div className="mt-20 bottom_border_split mr_dayindex"></div>
                                    }
                                </Grid>
                            }
                            {type === "endTime" &&
                                <>
                                    <Grid item>
                                        <div className="fleet_span">{getDurationLabel(schedule[scheduleVal])}</div>
                                    </Grid>
                                    <div>
                                        {schedule?.isNextDay && <div className="text_font">{"("}{t("fleet.nextday")}{")"}</div>}
                                    </div>
                                </>
                            }
                            {type === "buffer" &&
                                <Grid item>
                                    {schedule[scheduleVal] === "" ?
                                        <div className="fleet_span">{BLANK_DATA_PLACEHOLDER}</div> :
                                        <div className="fleet_span">{schedule[scheduleVal]}</div>
                                    }
                                    {borderBottom &&
                                        <div className="mt-20 bottom_border_split mr_dayindex"></div>
                                    }
                                </Grid>
                            }
                            {type === "hours" &&
                                <Grid item>
                                    {schedule[scheduleVal] === "" ?
                                        <div className="fleet_span">{BLANK_DATA_PLACEHOLDER}</div> :
                                        <div className="fleet_span">{schedule[scheduleVal]}{BLANK}{t("common.hours")}</div>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </>
            ))}
        </>
    )
}
export default FleetScheduleEnableComponent