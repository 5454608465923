import { ObjectDetails } from "./interfaces";

export const urlReplace = (url: string, replaceValue: string, replaceBy: any) => {
    return url?.replace(replaceValue, replaceBy);
};


const InvalidUrl = (history:any, search:string) => {
    history.push({
        pathname: "/invalid",
        search: search
    });
}

const GetUrlDetails = (history: ObjectDetails, module: string) => {

    let data: any = [];
    const defaultTabPath = ['/hostSettings', '/blinkCards']
    let search = history.location.search;
    data['tab'] = history.location.hash.slice(1);
    data['pathname'] = history.location.pathname;
    data['hash'] = history.location.hash;
    data['search'] = history.location.search;
    data['locationId'] = new URLSearchParams(search).get('locationId');
    data['defaultTab'] = '';

    if (defaultTabPath.includes(data['pathname'])) { 
        data['defaultTab'] = '0';
    }

    switch (module) {
        case 'hostSettings':
            data['delegateId'] = new URLSearchParams(search).get('delegate');
            data['viewType'] = new URLSearchParams(search).get('view');
            break;

        case '/evseLocations/evseDepotDetail':
            data['depotId'] = new URLSearchParams(search).get('depotId');

            if (data['locationId'] === '') {
                InvalidUrl(history, search);
            }
            break;

        case '/evseLocations/depotStationDetails':
            data['stationId'] = new URLSearchParams(search).get('stationId');

            if (data['stationId'] === '') {
                InvalidUrl(history, search);
            }
            break; 
        case 'downloadReport':
            data['host_id'] = new URLSearchParams(search).get('host_id');
            data['start_date'] = new URLSearchParams(search).get('start_date');
            data['end_date'] = new URLSearchParams(search).get('end_date');
            data['timezone'] = new URLSearchParams(search).get('timezone');
            break;
        case '/fleet/fleetInfo':
            data['hostId'] = new URLSearchParams(search).get('hostId');
            data['groupId'] = new URLSearchParams(search).get('groupId');
            data['vehicleId'] = new URLSearchParams(search).get('vehicleId');
            break;     
        case 'userGroup':
            data['userGroup'] = new URLSearchParams(search).get('userGroup');
            data['userDetailsId'] = new URLSearchParams(search).get('userDetailsId');
            data['viewType'] = new URLSearchParams(search).get('view');

            if (data['userGroup'] === '') {
                InvalidUrl(history, search);
            }
            break;
        case '/fleet/fleetVehicleInfo':
            data['vehicleId'] = new URLSearchParams(search).get('vehicleId');
            break;
        default:
    }
    return data;
};

export default GetUrlDetails;
