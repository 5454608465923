import Proptypes from 'prop-types';
import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const CommonComposedChart = (props:any) => {
  return (
    <>
      <ResponsiveContainer className="graph_height">
        <ComposedChart
          data={props?.data ? props.data : []}
          margin={{
            top: 3,
            right: 3,
            bottom: 3,
            left: 2,
          }}
        >
          <CartesianGrid vertical={false} fill="#EEEEEE" stroke="#FFFFFF" strokeOpacity={1} fillOpacity={0.5} />
          <XAxis hide />
          <YAxis tickMargin={-3} dy={4} width={40} unit=' kW' tickCount={4} interval={0} axisLine={false} tickLine={false} style={{ fontSize: '8px', color: '#4D4D4F' }} />
          <Area dataKey="actualLoad" fill="#DDDDDD" stroke="#666666" strokeOpacity={0.5} fillOpacity={0.5} />
          <Line dataKey="peakLoad" stroke="#25556E" dot={false} strokeDasharray="2 1" />
          <ReferenceLine x={0} stroke="#666666" strokeOpacity={0.5} alwaysShow={true} />
          <ReferenceLine x={props?.data ? props?.data.length - 1 : 0} stroke="#666666" strokeOpacity={0.5} alwaysShow={true} />
        </ComposedChart>
      </ResponsiveContainer>

    </>
  );
}

CommonComposedChart.propTypes = {
  data: Proptypes.any,
}

CommonComposedChart.defaultProps = {
  data: [],
}

export default CommonComposedChart;

