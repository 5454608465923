import React, {useState} from "react";
import {ObjectDetails} from "../../../../common/interfaces";
import {useDispatch} from "react-redux";
import {showErrorMessages} from "../../../../redux/actions/errorMessages";
import {manageFleetData} from "../../../../services/fleetManagementService";
import ConfirmDialogPopupComponent from "../../../../components/confirmDialogPopupComponent";


const FleetChargerRemove = (props: ObjectDetails) => {
    const dispatch = useDispatch();
    const {selectedCharger, removeCharger} = props;
    const [loading, setLoading] = useState(false);

    const fieldDetails: any = [{ id: 1, columnLabel: "fleet.chargeremovedetail", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const cancelButtonAction = { isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true , classFormat: ''};
    const removeButtonAction = { isVisible: true, buttonName: 'common.remove', buttonPermssion: true , classFormat: 'red_bg', disabled: false };

    const closePopup = () => {
        props.setRemoveCharger(false);
    };

    const removeChargerFromFleet = () => {
        setLoading(true);
        let body: ObjectDetails = {id: selectedCharger.id, status: 0};
        let params = { action: 'updateFleetStations'};
        manageFleetData(params, body).then((res: any) => {
            if (!res.status) {
                dispatch(showErrorMessages({error: true, message: res.message, type: "error", operation: "",}));
            }
            setLoading(false);
            props.setRemoveCharger(false);
            props.setRefreshData();
        }, (error: any) => {
            setLoading(false);
            dispatch(
                showErrorMessages({error: true, message: error.message, type: "error", operation: "",})
            );
        });
    };

    return (
        <div className="dialog_box">
            <ConfirmDialogPopupComponent
                popUpPageOpen={removeCharger}
                popUpPageClose={closePopup}
                headerName={"common.remove.station"}
                fieldDetails={fieldDetails}
                submitAction={removeButtonAction}
                cancelAction={cancelButtonAction}
                loading={loading}
                submitData={removeChargerFromFleet}
            />
        </div>
    )
};


export default FleetChargerRemove;