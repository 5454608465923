import { dateFormats } from "common";
import { toUpper } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const fileNameMembershiprequest = `PrivateAccessRequests_${moment().format(dateFormats.csvTimeFormat)}.csv`;

export const FileHeaderMembershipComponent = () => {
    const { t } = useTranslation();
    return [
        { label: toUpper(t("common.email")), key: "email" },
        { label: toUpper(t("common.name")), key: "name" },
        { label: toUpper(t("common.phone")), key: "phone" },
        { label: toUpper(t("membership.requesteddate")), key: "requested_date" },
        { label: toUpper(t("common.status")), key: "status" }
    ];
};

export const FileHeaderMembershipUserGroupComponent = () => {
    const { t } = useTranslation();
    return [
        { label: toUpper(t("userlistcsv.userName")), key: "email" },
        { label: toUpper(t("common.name")), key: "userName" },   
        { label: toUpper(t("common.status")), key: "status" }
    ];
};

export const MEMBERSHIP_APPROVE = 1;
export const MEMBERSHIP_DECLINE = 2;
const APPROVE = "Approve";
const DECLINE = "Decline";
const NEXT = "Next";
export const REMOVE_STATUS = 3;
export const DECLINED = "Declined";

export const validateButtonClass = (approveButton: boolean = false, rejectButton: boolean = false, nextButton: boolean = false,
    secondPopup: boolean = false, spinnerOff: boolean = false, userGroupName: string = "", userGroupDetails: boolean = false) => {
    if (approveButton && secondPopup && !spinnerOff && userGroupName !== "")
        return "blue_bg";
    else if (rejectButton && secondPopup)
        return "delet_acct";
    else if (nextButton || spinnerOff || (approveButton && userGroupName === "" && userGroupDetails))
        return "disable";
    else
        return "blue_bg";
}

export const changeButtonName = (membershipStatus: string, setNextButton: any, setApproveButton: any, setRejectButton: any, setButtonName: any) => {
    if (membershipStatus === APPROVE) {
        setNextButton(false);
        setApproveButton(true);
        setButtonName(APPROVE);
        setRejectButton(false);
    } else if (membershipStatus === DECLINE) {
        setNextButton(false);
        setRejectButton(true);
        setApproveButton(false);
        setButtonName(DECLINE);
    }
    else {
        setNextButton(true);
        setButtonName(NEXT);
    }
}

export const navigateToNextStep = (setSecondPopup: any, setuserGroupDetails: any, setDeleteConfirmation: any,
    setNextButton: any, setDisableButton: any, approveButton: boolean = false, rejectButton: boolean = false) => {
    if (approveButton) {
        setSecondPopup(true);
        setuserGroupDetails(true);
        setDeleteConfirmation(false);
        setDisableButton(true);
    } else if (rejectButton) {
        setSecondPopup(true);
        setDeleteConfirmation(true);
        setuserGroupDetails(false);
    } else {
        setNextButton(false);
        setSecondPopup(false);
    }
}

export const setFirstPopupDetails = (setNextButton: any, setDisableButton: any, setSecondPopup: any, setDeleteConfirmation: any,
    setuserGroupDetails: any, setUserGroupName: any) => {
    setNextButton(false);
    setDisableButton(false);
    setSecondPopup(false);
    setDeleteConfirmation(false);
    setuserGroupDetails(false);
    setUserGroupName("");
}
