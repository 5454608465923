import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {DEPARTURE_BELOW_MIN, DEPARTURE_EARLY, RETURN_LATE} from "../fleetManagementHelper";

const AlertDetails = (props: any) => {
  const {t} = useTranslation();

  return (
    <>
      <div className="info_detail common_form">
        <Grid container>
          <Grid className="" item xs={12}>
            <div className="tb_info_section mt-13">
              {!props.dialogContent && (
                <div className="row">
                  <Typography component="span">{t("fleet.alertdesc")}</Typography>
                </div>
              )}
              <div className="row mt-30">
                <Grid
                  className="single_line textarea"
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Grid className="" item xs={props.dialogContent ? 6 : 4}>
                    <span className="notification_label">{t("fleet.alert.departure")}</span>
                  </Grid>
                  <Grid className="" item xs={props.dialogContent ? 6 : 4}>
                    <div className="defult_form">
                      {props.editStatus && props.dialogContent ? (
                        <div className="form_col_alert radio_button_alert color_change">
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            value={props.alertInfoEdit?.DEPARTURE_EARLY}
                            onChange={(e) => {
                              props.setAlertStatus(e, DEPARTURE_EARLY);
                            }}
                          >
                            <FormControlLabel value={true} control={<Radio color="primary" />} label={t("fleet.on")} />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={t("fleet.off")}
                            />
                          </RadioGroup>
                        </div>
                      ) : (
                        <div>
                          {props.showData && (
                            <div className="form_col">
                              {props.alertInfoEdit?.DEPARTURE_EARLY ? t("fleet.on") : t("fleet.off")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="" item xs={props.dialogContent ? 6 : 4}>
                    <span className="notification_label">{t("fleet.alert.energy")}</span>
                  </Grid>
                  <Grid className="" item xs={props.dialogContent ? 6 : 4}>
                    <div className="defult_form">
                      {props.editStatus && props.dialogContent ? (
                        <div className="form_col_alert radio_button_alert color_change">
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            value={props.alertInfoEdit?.DEPARTURE_BELOW_MIN}
                            onChange={(e) => {
                              props.setAlertStatus(e, DEPARTURE_BELOW_MIN);
                            }}
                          >
                            <FormControlLabel value={true} control={<Radio color="primary" />} label={t("fleet.on")} />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={t("fleet.off")}
                            />
                          </RadioGroup>
                        </div>
                      ) : (
                        <div>
                          {props.showData && (
                            <div className="form_col">
                              {props.alertInfoEdit?.DEPARTURE_BELOW_MIN ? t("fleet.on") : t("fleet.off")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="" item xs={props.dialogContent ? 6 : 4}>
                    <span className="notification_label">{t("fleet.alert.return")}</span>
                  </Grid>
                  <Grid className="" item xs={props.dialogContent ? 6 : 4}>
                    <div className="defult_form">
                      {props.editStatus && props.dialogContent ? (
                        <div className="form_col_alert radio_button_alert color_change">
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            value={props.alertInfoEdit?.RETURN_LATE}
                            onChange={(e) => {
                              props.setAlertStatus(e, RETURN_LATE);
                            }}
                          >
                            <FormControlLabel value={true} control={<Radio color="primary" />} label={t("fleet.on")} />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={t("fleet.off")}
                            />
                          </RadioGroup>
                        </div>
                      ) : (
                        <div>
                          {props.showData && (
                            <div className="form_col">
                              {props.alertInfoEdit?.RETURN_LATE ? t("fleet.on") : t("fleet.off")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AlertDetails;
