/* global window */
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducers from './reducers';

// Redux Persist config
const config = {
  key: 'root',
  storage
};

//using redux-dev-tools in typescript by declaring a global interface 
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

const reducer = persistCombineReducers(config, reducers);

const middleware = [thunk];

const configureStore = () => {

  let store: any = undefined;

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    store = createStore(reducer, 
      compose(
        applyMiddleware(...middleware), 
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));      
  } else {
    store = createStore(reducer, 
      compose(
        applyMiddleware(...middleware)));  
  }

  const persistor = persistStore(
    store,
    null,
    () => { store.getState(); },
  );

  return { persistor, store };
};

export default configureStore;