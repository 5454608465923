import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { getMethod, ApiEndPoint } from 'services';
import { pageDetails, styleDetails, urlReplace, HandlePaginationLabel }  from 'common';
import { DataGrid } from '@material-ui/data-grid';
import CommonChargeSessionDetails from 'components/commonChargeSessionDetails'
import CommonDownloadCsv from 'components/commonDownloadCsv';
import CommonPagination from 'components/commonPagination';
import { StationChargeHistoryColumnsComponent } from 'pages/tableColumns';
import { useTranslation } from "react-i18next";
import {ChargeInterface} from 'common/interfaces';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const DepotChargeHistory = (props:any) => {
  const [loadingPage, setLoadingPage]  = useState(false);
  const currentStationId = props?.stationId;
  const [depotStationChargeHistory, setDepotStationChargeHistory] = useState<any>();
  const pageSize = pageDetails.pageSize;
  const [currentPage, setCurrentPage] = useState(0);
  const [stationChargeSessionInfo, setStationChargeSessionInfo] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [depotStationDownloadCsv, setDepotStationDownloadCsv] = useState<any>([]);
  const { t } = useTranslation();
  const isLocationSubscribedToPricing = props?.isLocationSubscribedToPricing;

 /* const fileHeader =[
    {label: toUpper(t("common.port")),key: 'portInformation'},
    {label: toUpper(t('common.starttime')) ,key: 'startChargeTime'},
    {label: toUpper(t("common.endtime")) ,key: 'endChargeTime'},
    {label: toUpper(t("common.occupancytime")) ,key: 'occupancyTime'},
    {label: toUpper(t("common.chargingtime")) ,key: 'chargingTime'},
    {label: toUpper(t("common.sessiontime")) ,key: 'time'},
    {label: t("common.totalenergykwh") ,key: 'energyKWh'},
    {label: toUpper(t("common.amount")) ,key: 'amount'} 
  ]; */
 
  const fileName: string = `${t('filename.stationchargehistory')}_${moment().format('MMDDYYYYHHmmss')}.csv`;

  const columnValue = StationChargeHistoryColumnsComponent();

  const stationHandleClickOpen = (scrollType:any, chargeData:any) => () => {
    if (
      chargeData &&
      chargeData.chargeHistoryList &&
      chargeData.chargeHistoryList.length >= 1
    ) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getDepotStationChargeHistoryData();
  }, [currentPage]);

  const getDepotStationChargeHistoryData = async () => {
    const url = urlReplace(ApiEndPoint.stationChargeHistory, "{STATION-ID}", currentStationId);
    const paramDetails = '?page=' + currentPage + '&size=' + pageSize;
    setLoadingPage(true);
    await getMethod(url, paramDetails).then((res) => {
        setDepotStationChargeHistory(res.data);
    }).catch((err) => {
        console.log('Error', err);
    });
    setLoadingPage(false);
  };

  const getDepotStationDownloadCsv = async () => {
    const url = ApiEndPoint.downloadStationChargeHistory;
    const paramDetails = '?stationId=' + currentStationId;
    await getMethod(url, paramDetails).then((res) => {
      setDepotStationDownloadCsv(res.data);
      }).catch((err) => {
        console.log('Error', err);
      });
  };

  const onStationChargeHistoryClick = (e:any) =>{
    if (e && e.row){
        setStationChargeSessionInfo(e.row);
      }     
    }
  
  const depotStationChargeHistoryDataSet = (value: ChargeInterface) => {
    let chargeHistoryTableData = [];
    if (value && value.chargeHistoryList) {
      chargeHistoryTableData = value.chargeHistoryList.map((val: any) => ({ ...val,id: val.historyId, energy: val.energyKWh }));
    }
    return chargeHistoryTableData;
  };

  const handleCurrentPage = (setPage:any) => {
    setCurrentPage(setPage)
  }  

  return (
      <>
        {loadingPage ? <CircularProgress className="inner_progress_userGroup"/> : 
          <div className="table_scroll" data-testid="depot_charge_history_table">
              <div
                  className="default_table"
                  style={styleDetails.styleWidth}
                  onClick={stationHandleClickOpen(
                      "paper",
                      depotStationChargeHistory
                  )}
              >
                  <DataGrid {...props}
                      autoHeight
                      className="table"
                      rowHeight={59}
                      headerHeight={30}
                      rows={depotStationChargeHistoryDataSet(
                          depotStationChargeHistory
                      )}
                      columns={columnValue}
                      pageSize={pageSize}
                      onRowClick={(e) => onStationChargeHistoryClick(e)}
                  />
              </div>
              {open && (
                  <CommonChargeSessionDetails
                      {...props}
                      open={open}
                      setHandleClose={handleClose}
                      historyId={stationChargeSessionInfo.historyId}
                      sessionInfo={stationChargeSessionInfo}
                      isLocationSubscribedToPricing={isLocationSubscribedToPricing}
                  />
              )}

              <div className="table_footer">
                  <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={0}
                  >
                      <Grid className="text_left" item xs={8}>
                          {depotStationChargeHistory &&
                          depotStationChargeHistory.totalChargeHistory >
                              pageSize ? (
                              <CommonPagination
                                  currentPage={currentPage}
                                  totalCount={
                                      depotStationChargeHistory?.totalChargeHistory
                                  }
                                  callBackCurrentPage={handleCurrentPage}
                              />
                          ) : (
                              <></>
                          )}
                          <CommonDownloadCsv
                              {...props}
                              datas={
                                depotStationDownloadCsv
                              }
                              filename={fileName}
                              callBackDownloadCsv={getDepotStationDownloadCsv}
                          />
                      </Grid>
                      <Grid className="text_right" item xs={4}>
                          <div className="list_number">
                            {HandlePaginationLabel(depotStationChargeHistory?.totalChargeHistory, pageSize, currentPage, 'common.chargesession' )}
                          </div>
                      </Grid>
                  </Grid>
              </div>
          </div>
        }
      </>
  );
};
export default DepotChargeHistory;