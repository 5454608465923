import React from "react";
import WaitListConfiguration from "./waitListConfiguration";
import AddedStations from "./addedStations";

const WaitList = () => {
    return (
        <>
            <div className="waitlist_main">
                <WaitListConfiguration/>
                <AddedStations/>
            </div>
        </>
    )
};

export default WaitList;