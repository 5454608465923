import React from "react";
import { useTranslation } from "react-i18next";
import { toUpper,toLower } from 'lodash';

export const BlinkCardColumnsComponent = () => {

    const { t } = useTranslation();
    const blinkListColumns = [
        {
            field: "firstCell",
            headerName:(t("common.firstcell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "id",
            headerName: toUpper(t("common.requestid")),
            headerClassName: "action_columns",
            hide:true,
            cellClassName: "",
            minWidth:100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "quantity",
            headerName:(t("common.quantity")),
            headerClassName: "action_columns",
            cellClassName: "t",
            className: "port",
            minWidth:100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "orderby",
            headerName: toUpper(t("common.orderby")),
            headerClassName: "action_columns",
            sortable: false,
            minWidth:100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "email",
            headerName: toUpper(t("common.email")),
            headerClassName: "action_columns",
            sortable: false,
            width:160,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: toUpper (t("common.status")),
            headerClassName: "action_columns",
            sortable: false,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "date",
            headerName: toUpper(t("common.date")),
            headerClassName: "action_columns",
            cellClassName: "",
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "lastCell",
            headerName: toLower(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
        },
    ]
    return blinkListColumns;
}

export const BlinkMyCardComponent = () => {

    const { t } = useTranslation();
    const blinkCardListColumns = [
        {
            field: "firstCell",
            headerName:(t("common.firstcell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "blinkcard",
            headerName: toUpper(t("common.blinkcard")),
            headerClassName: "action_columns",
            cellClassName: "",
            sortable: false,
            minWidth:100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "memberid",
            headerName:(t("common.memberid")),
            headerClassName: "action_columns",
            cellClassName: "t",
            sortable: false,
            className: "port",
            flex: 0.1,
            minWidth:100,
            disableColumnMenu: true,
        },
        {
            field: "date",
            headerName: toUpper(t("common.date")),
            headerClassName: "action_columns",
            sortable: false,
            flex: 0.1,
            minWidth:100,
            disableColumnMenu: true,
        },
        {
            field: "email",
            headerName: toUpper (t("common.email")),
            headerClassName: "action_columns",
            sortable: false,
            flex: 0.1,
            minWidth:100,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: toUpper (t("common.status")),
            headerClassName: "action_columns",
            sortable: false,
            flex: 0.1,
            minWidth:100,
            disableColumnMenu: true,
        },
        {
            field: "country",
            headerName: toUpper (t("common.country")),
            headerClassName: "action_columns",
            sortable: false,
            width:100,
            disableColumnMenu: true,
        },
        {
            field: "lastCell",
            headerName: toLower(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
        },
    ]
    return blinkCardListColumns;
}

