import React, { useEffect, useState } from "react";
import {
    Button,
    DialogContent,
    DialogContentText,
    Grid,
    MenuItem,
    Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LabelInfo, dateFormats, defaultTimeZoneSelect } from "common";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import DownloadReport from "components/downloadReport";
import { ApiEndPoint, getMethod } from "services";

const ChargeHistoryReportForm = (props: any) => {

    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date());
    const [errorShown, setErrorShown] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [timeZone, setTimeZone] = useState('');
    
    const handleEvseDate = (startValue: any, endValue: any) => {
        let errorValue = t("date.validation.message");
        if (startValue && endValue && endValue < startValue) {
            setErrorShown(errorValue);
        } else {
            setErrorShown('');
        }
        setStartDate(startValue);
        setEndDate(endValue);
    };

    const handleClick = () => {
        let dateParams = '';
        if (!errorShown && startDate && endDate) {
            dateParams =  `?host_id=${props?.hostId}&start_date=${moment.utc(startDate).format(dateFormats.dateFormmat)}&end_date=${moment.utc(endDate).format(dateFormats.dateFormmat)}&timezone=${timeZone}`
            if (dateParams ){
                props?.reportClose();
                window.open( '/downloadReport' + dateParams, '_blank');
                
            }
        }
    }

    const getTimeZoneList = async () => {
        await getMethod(ApiEndPoint.timeZoneList, '').then((res) => {
            const timeZone = res?.data?.timeZones.map(function(d: any) { return { label: d?.name, value: d?.code, id: d.id } })
            if(timeZone) {
                setTimeZoneList(timeZone);
                setTimeZone(defaultTimeZoneSelect);
            }
        }).catch((err) => {
            console.log("Error", err)
        })
    };

    const handleTimeZone = (e: any) => {
        let value = e.target.value;
        setTimeZone(value);
    }

    useEffect(() => {
        getTimeZoneList()
            .catch(console.error);
    },[]);

    return (
        <>
            <div className="dialog_title">
                <h4>{t("common.chargehistoryreport")}</h4>
                <div className="button_row mt-0 full_right">
                    <Button
                        className="btn_white text_btn_theme"
                        onClick={props?.reportClose}
                    >
                        {t("common.cancelbutton")}
                    </Button>
                    <Button onClick={handleClick}
                        className="btn_white blue_bg"
                    >
                        {t("common.downloadcsv")}
                    </Button>
                </div>
            </div>
            <DialogContent  {...props}>
                <DialogContentText id="scroll-dialog-description">
                    <div className="defult_form">
                        <div className="section_group">    
                            <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                <Grid item xs={12}>
                                    <div className="form_col">
                                        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                            <Grid item xs={12}>
                                                <LabelInfo name={t("common.rangeofdays")} isMandatory={false}/>
                                            </Grid>
                                            <div className="common-date-picker">
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        inputVariant="outlined"
                                                        value={startDate}
                                                        onChange={(e) => handleEvseDate(e, endDate)}
                                                        format={dateFormats.dateFormmat}
                                                    />
                                                    <DatePicker
                                                        autoOk
                                                        inputVariant="outlined"
                                                        value={endDate}
                                                        onChange={(e) => handleEvseDate(startDate, e)}
                                                        format={dateFormats.dateFormmat}
                                                        maxDate={new Date()}
                                                    />
                                                    <h4 className="picker-error mt-0">{errorShown}</h4>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Grid>
                                    </div>
                                    <div className={`form_col`}>
                                        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                            <Grid item xs={12}>
                                                <LabelInfo name={t("common.chargehistoryreport.timezone")} isMandatory={true}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    className="w_100per full_w"
                                                    labelId="demo-simple-select-label"
                                                    variant="outlined"
                                                    name="timeZone"
                                                    onChange={handleTimeZone}
                                                    value={timeZone}
                                                >
                                                    {
                                                        timeZoneList?.map((value: any) => (
                                                            <MenuItem
                                                                className="defult_select_option"
                                                                key={value?.id}
                                                                value={value?.value}
                                                            >
                                                                {value?.label}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    );
};

export { DownloadReport };
export default ChargeHistoryReportForm;
