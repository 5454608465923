import React from "react";

export const DriverChargeHistoryColumns = [
    
    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },

    {
        field: 'vehicleId',
        headerName: 'VEHICLE ID',
        sortable: false,
        flex: 0.1,
        minWidth: 100,
        disableColumnMenu: true
      },
      {
        field: 'port',
        headerName: 'PORT',
        sortable: false,
        flex: 0.1,
        minWidth: 100,
        disableColumnMenu: true
      },
  
    {
      field: 'start',
      headerName: 'START',
      sortable: false,
      flex: 0.2,
      minWidth: 100,
      disableColumnMenu: true,
      },
    {
      field: 'end',
      headerName: 'END',
      sortable: false,
      minWidth: 100,
      flex: 0.2,
      disableColumnMenu: true
    },
  
    
    {
      field: 'time',
      headerName: 'TIME',
      sortable: false,
      flex: 0.1,
      disableColumnMenu: true
    },
    {
      field: 'energy',
      headerName: 'ENERGY (kWh)',
      sortable: false,
      width: 120,
      disableColumnMenu: true
    },
  
    
    
    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
  ];