import { Card, Grid, ListItem } from "@material-ui/core";
import { BLANK_DATA_PLACEHOLDER } from "common";
import React from "react";
import { useTranslation } from "react-i18next";
import { transactionInfo } from "./transactionDetailsHelper";


const FlexiblePricingDetail = (props: any) => {

    const { t } = useTranslation();
    const timeDuration = !props.pricingPlanInvoiceDetails?.is_hybrid;

    return (
        <Card className="pricedetail-card" variant="outlined">
            <div className="mt-10">
                {transactionInfo(t("pricing.pricingtype"), props.pricingPlanInvoiceDetails?.parking_type ? props.pricingPlanInvoiceDetails?.parking_type : BLANK_DATA_PLACEHOLDER)}
            </div>
            {props.pricingPlanInvoiceDetails?.days?.map((priceValue: any) => (
                <>
                    <ListItem>
                        <div className="pricing_day_label font_weight_header">{priceValue.day_name}</div>
                        <span>
                            <label className="mt-10">{t("common.energy")} - {props.pricingPlanInvoiceDetails.energy_type}</label>
                        </span>
                        {priceValue?.energySlots?.map((energy: any) => (
                            <>
                                {timeDuration ?
                                    <Grid container spacing={0}>
                                        <Grid item xs={7}>
                                            <span>{energy.start} - {energy.end}</span>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span>{energy.cost}</span>
                                        </Grid>
                                    </Grid> :
                                    <Grid container spacing={0}>
                                        <Grid item xs={7}>
                                            <span>{energy.end}</span>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span>{energy.cost}</span>
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        ))
                        }
                        <span>
                            <label className="mt-10">{t("common.parking")} - {props.pricingPlanInvoiceDetails.parking_type}</label>
                        </span>
                        {priceValue?.parkingSlots?.map((parking: any) => (
                            <>
                                {timeDuration ?
                                    <Grid container spacing={0}>
                                        <Grid item xs={7}>
                                            <span>{parking.start} - {parking.end}</span>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span>{parking.cost}</span>
                                        </Grid>
                                    </Grid> :
                                    <>
                                        {parking?.time?.map((timeval: any) => (
                                            <Grid className="margin_top" key={timeval.end_t}>
                                                <Grid container spacing={0}>
                                                    <>
                                                        <Grid item xs={7}>
                                                            <span>{timeval.start_t} - {timeval.end_t}</span>
                                                        </Grid>
                                                        {timeval?.hours?.map((time: any) => (
                                                            <>
                                                                <Grid item xs={7}>
                                                                    <div className="pricing_day_label font_weight_data">{time.start} - {time.end}</div>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <div className="pricing_day_label font_weight_data">{time.cost}</div>
                                                                </Grid>
                                                            </>
                                                        ))}
                                                    </>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                }
                            </>
                        ))
                        }
                    </ListItem>
                </>
            ))}
            <div className="mt-10">
                {transactionInfo(t("common.taxrate"), (props.pricingPlanInvoiceDetails?.tax_rate ? (props.pricingPlanInvoiceDetails?.tax_rate) : BLANK_DATA_PLACEHOLDER))}
            </div>
            <div className="mt-10">
                {transactionInfo(t("common.servicefee"), (props.pricingPlanInvoiceDetails?.service_fee ? (props.pricingPlanInvoiceDetails?.service_fee) : BLANK_DATA_PLACEHOLDER))}
            </div>
        </Card>
    )
};

export default FlexiblePricingDetail