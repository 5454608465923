import React, { useState } from "react";
import { manageFleetData } from "services/fleetManagementService";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {FLEET_LIST_PATH, DEACTIVATE_FLEET_ACTION, FLEET_TAB_ONE,} from "pages/fleet/fleetManagementHelper";
import ConfirmDialogPopupComponent from "../../../../components/confirmDialogPopupComponent";

const DeleteFleet = (props: any) => {
    const dispatch = useDispatch();
    const [spinnerOff, setSpinnerOff] = useState(false);
    const history = useHistory();
    const fieldDetails: any = [{ id: 1, columnLabel: "fleet.removeFleetMsg", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const cancelButtonAction = { isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true , classFormat: ''};
    const removeButtonAction = { isVisible: true, buttonName: 'common.deletebutton', buttonPermssion: true , classFormat: 'red_bg', disabled: false };

    const deleteFleet = () => {
        setSpinnerOff(true);
        let body = {
            id: props?.deleteFleetId,
        };
        let paramObj = {action: DEACTIVATE_FLEET_ACTION};
        manageFleetData(paramObj, body).then((res: any) => {
            if (res.status === true) {
                setSpinnerOff(false);
                props?.deleteFleetClose();
                props?.closeEditFleet();
                navigateToFleetList();
            } else {
                dispatch(
                    showErrorMessages({error: true, message: res.message, type: "error", operation: "",})
                );
                setSpinnerOff(false);
            }
        }).catch((err: any) => {
            setSpinnerOff(false);
            console.error("Error", err);
        });
    };

    const navigateToFleetList = () => {
        history.push({
            pathname: FLEET_LIST_PATH,
            hash: "#" + FLEET_TAB_ONE,
        });
    };

    return (
        <>
            <div className="dialog_box">
                <ConfirmDialogPopupComponent
                    popUpPageOpen={props?.openDeleteFleet}
                    popUpPageClose={props?.deleteFleetClose}
                    headerName={"fleet.delete"}
                    fieldDetails={fieldDetails}
                    submitAction={removeButtonAction}
                    cancelAction={cancelButtonAction}
                    loading={spinnerOff}
                    submitData={deleteFleet}
                />
            </div>
        </>
    );
};

export default DeleteFleet;
