import { Dialog, Button, DialogContent, DialogContentText, Grid, TextField } from "@material-ui/core";
import { urlReplace } from "common";
import { UserEditInfo } from "common/interfaces/manageuser";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiEndPoint, postMethod } from "services";
import UserInfoDelete from "../userInfoDelete";

const UserInfoEdit = (props: UserEditInfo) => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [userDeleteOpen, setUserDeleteOpen] = useState(false);
    const userDeleteEventOpen = () => {
        setUserDeleteOpen(true);
      };
    
      const userDeleteEventClose = () => {
        setUserDeleteOpen(false);
      };

    const onSubmit = async (data: any, e: any) => {
        const url = urlReplace(ApiEndPoint.groupUsers, "{USERGROUP-ID}", props?.userGroupId);

        for (const [key, value] of Object.entries(data)) {
            if (value === "" || value === "-" || value === null){
                delete data[key];
            }
        }

        await postMethod(url, '', data)
            .then((res) => {
                resetForm()
                e.target.reset();
            })
            .catch((err) => {
                e.target.reset(); 
            });
    };

    useEffect(() => {
        setValue("firstName", props?.manageUserInfoEditData?.firstName);
        setValue("lastName", props?.manageUserInfoEditData?.lastName);
        setValue("email", props?.manageUserInfoEditData?.email);
        setValue("rfids", props?.manageUserInfoEditData?.rfids);
    }, [props?.manageUserInfoEditData]);

    const resetForm = () => {
        props.closeEditUser(props?.manageUserInfoEditData);
    }

    const userDeleteSave = () => {
        userDeleteEventClose();
        resetForm();
        props.refreshListAfterDelete(true)
    }

    return (
        <>

            {/* User Info Delete Start */}
            {userDeleteOpen && (
                <UserInfoDelete
                userDeleteEventOpen={userDeleteEventOpen}
                userDeleteEventClose={userDeleteEventClose}
                manageUserInfoEditData={props?.manageUserInfoEditData}
                userGroupId={props?.userGroupId}
                userDeleteSave={userDeleteSave}
                />
            )}
            {/* User Info Delete End */}
            {/* Dialog Edit Start */}
            <div className="dialog_box" data-testid="user_info_edit_dialog">
                <Dialog
                    className="dialog_container zone_settings_dialog edit_detail_section  dialog_container_center"
                    {...props}
                    open={props.openEditUser}
                    onClose={resetForm}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="dialog_title">
                        <h4>{t("common.edituser")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={resetForm}
                            >
                                {t("common.cancel")}
                            </Button>
                            {/* <Button type="submit" className="btn_white blue_bg">
                                {t("common.savechanges")}
                            </Button> */}
                        </div>
                    </div>
                    <DialogContent {...props}>
                        <DialogContentText id="scroll-dialog-description">
                                <div className="defult_form mt-10">
                                    <div className="section_group">
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={12}>
                                                            <label>
                                                                {t(
                                                                    "common.firstname"
                                                                )}
                                                            </label>
                                                            <TextField
                                                                variant="outlined"
                                                                disabled={true}
                                                                {...register("firstName")}
                                                            />
                                                            {errors?.firstName && <span className="error_msg">{t("usergroup.firstname.required")}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={12}>
                                                            <label>
                                                                {t(
                                                                    "common.lastname"
                                                                )}
                                                            </label>
                                                            <TextField
                                                                disabled={true}
                                                                variant="outlined"
                                                                {...register("lastName")}
                                                            />
                                                            {errors?.lastName && <span className="error_msg">{t("usergroup.lastname.required")}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={12}>
                                                            <label>
                                                                {t(
                                                                    "common.email"
                                                                )}
                                                            </label>
                                                            <TextField
                                                                variant="outlined"
                                                                {...register("email", {pattern: /^((?=(\S+@\S+))\2)$/i})}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start" spacing={0}>
                                                        <Grid item xs={12}>
                                                            <label>
                                                                {t("common.rfidnumber")}
                                                            </label>
                                                            <TextField
                                                                {...register("rfids")}
                                                                variant="outlined"
                                                            />
                                                            {errors?.rfids && <span className="error_msg">{t("usergroup.rfids.required")}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={12}>
                                                            <label></label>
                                                            <div className="button_row mt-0 full_w_155">
                                                                <Button
                                                                    className="delet_acct ml_0 btn_white block_btn"
                                                                    onClick={
                                                                        userDeleteEventOpen
                                                                    }
                                                                >
                                                                    {t(
                                                                        "usergroup.removebutton"
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                        </DialogContentText>
                    </DialogContent>
                </form>
                </Dialog>
            </div>
            {/* Dialog Edit End */}
        </>
    );
};
export default UserInfoEdit;
