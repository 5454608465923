import React from "react";
import { defaultVariable, fieldDataCheck, convertSecondToFormat , BLANK_DATA_PLACEHOLDER, renderCellExpand} from "common";
import { startCase } from "lodash";
import { t } from "i18next";

export const evseOverviewColumns = [
    {
        field: "firstCell",
        headerName: "common.firstcell",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="empty">
                    {cellValues.value}
                </div>
            );
        }
    },
    {
        field: "port",
        headerName: "common.station",
        headerClassName: "action_columns",
        sortable: false,
        hasFocus: false,
        className: "port",
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="port_icon">
                    <div className="port_icon_img"> 
                    {cellValues?.row?.imageReference &&                    
                    <img src={cellValues.row.imageReference} alt="Edit EVSE" />}
                    </div>
                    {fieldDataCheck(cellValues, BLANK_DATA_PLACEHOLDER)}
                </div>
            );
        }
    },
    {
        field: "chargerName",
        headerName: "common.name",
        sortable: false,
        hasFocus: false,
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, fieldDataCheck(cellValues, cellValues?.row?.serialNumber));
        },
    },
    {
        field: 'status',
        headerName: 'common.status',
        sortable: false,
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className={`status ${defaultVariable.portStatusIndicatorMap[cellValues?.value]?.bgclass}`}>
                    <span className="status_text">
                        {cellValues?.value ? t(defaultVariable.portStatusIndicatorMap[cellValues?.value]?.text) : BLANK_DATA_PLACEHOLDER }
                    </span>
                </div>
            );
        }
    },
    {
        field: 'startTime',
        headerName: 'common.start',
        sortable: false,
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, cellValues?.value);
        },
    },
    {
        field: 'time',
        headerName: 'common.time',
        sortable: false,
        flex: 0.1,
        minWidth: 110,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <>
                    {cellValues?.value ? convertSecondToFormat(Number(cellValues?.value), 'HM') : BLANK_DATA_PLACEHOLDER}
                </>
            );
        }
    },
    {
        field: 'serialNumber',
        headerName: 'common.serialnumber',
        sortable: false,
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, cellValues?.value);
        }
    },
    {
        field: 'model',
        headerName: 'common.modelid',
        sortable: false,
        minWidth: 110,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return renderCellExpand(cellValues, cellValues?.value);
        },
    },
    {
        field: "lastCell",
        headerName: "common.lastcell",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="empty">
                    {cellValues.value}
                </div>
            );
        }
    },
];