import React from 'react';
import Proptypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Images from '../../assets/images';
import environment from '../../environment';
import {getMapOptions,getMyOptions, getAnotherOptions} from 'common/mapHelpers';
import PolygonMap from 'components/polygonMap';
import { mapStyle } from 'common';

const AnyReactComponent = ({props,onMarkerClick, title, ids} : { props:any,onMarkerClick: any, title?: string, ids:any}) => <div {...props} onClick={onMarkerClick} title={title} ids={ids}>{<img style={mapStyle} src={Images.MAP_MARKER} />}</div>;

const CommonGoogleMap = (props:any) => {


    const defaultMapOptions = {
        fullscreenControl: props?.fullscreenControl ? props.fullscreenControl: false,
        scrollwheel: props?.scrollwheel ? props.scrollwheel: false,
        gestureHandling: props?.gestureHandling ? props?.gestureHandling: 'none',
      }; 
      
      const checkMapOptions = (value: number) => {
          if (value === 2) {
              return getMyOptions;
          } else if (value === 1) {
              return getMapOptions;
          }else if (value === 3) {
            return getAnotherOptions;
        }
          return defaultMapOptions;
      };

      const getCoordinates = (value: any) => {
        props.coordinates(value);
      }

      const handleApiLoaded = (map: any) => {

        if(props?.markers && props?.markers.length > 1) {
            const bounds = new google.maps.LatLngBounds();

            props?.markers.forEach((value: any, index: any) => {
                const location = new google.maps.LatLng(
                    value.lat,
                    value.lon
                );
                bounds.extend(location);
            });
         
            map.fitBounds(bounds);
        }
      };

    return (
        <>
            {props?.polygon && 
                <PolygonMap  
                center={{lat: props?.center?.lat, lng: props?.center?.lon}}
                zoom={props?.zoom} 
                clearPolygone={props.clearPolygone} 
                mapsize={props.mapsize}  
                pathCoord={props.pathCoord} 
                selectedCoordinates={getCoordinates}/>               
            }
            
            {!props?.polygon && <GoogleMapReact {...props}
                bootstrapURLKeys={{ key: environment.googleMapKey, libraries: ['places', 'geometry', 'drawing'] }}
                center={
                    {
                        lat: props?.center?.lat,
                        lng: props?.center?.lon
                    }
                }
                zoom={props?.zoom}
                options={checkMapOptions(props?.options)}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => handleApiLoaded(map) }
            >
                {props?.markers && props.markers.map((value:any, index:any) => (<AnyReactComponent {...props}
                 lat={value.lat}
                 lng={value.lon}
                 title={value.title}
                 ids={value.ids}
                 onMarkerClick={() => props.markerClicked(value)}
                />))}  
            </GoogleMapReact>
        }
        
        </>
    );
}

CommonGoogleMap.propTypes = {
    center: Proptypes.any,
    zoom: Proptypes.number,
    markers: Proptypes.any,
    polygon: Proptypes.any,
    coordinates: Proptypes.any,
    pathCoord: Proptypes.any,
    mapsize: Proptypes.any,
    clearPolygone: Proptypes.any,
};
  
CommonGoogleMap.defaultProps = {
    center: {},
    zoom: 5,
    markers: [],
    polygon: false,
    pathCoord: [],
    mapsize: false,
    clearPolygone: '' 
    
};

export default CommonGoogleMap;

