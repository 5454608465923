import {Button, Card, CardContent, Checkbox, Dialog, DialogContent, DialogContentText, FormControl, Grid, IconButton, LinearProgress, OutlinedInput} from "@material-ui/core";
import {toUpper, startCase, toLower } from "lodash";
import Images from "../../../../assets/images";
import {CheckAccess, claims, LabelInfo} from "../../../../common";
import { checkForDisableClass, checkForErrorSchedule, FLEET_CONSTRAINTS_ISENABLE, prepareScheduleConstraintsForDisplay, prepareScheduleConstraintsForSave, updatingScheduleConstraint, validationScheduleConstraints} from "../../fleetManagementHelper";
import React, {useEffect, useRef, useState} from "react";
import {ObjectDetails} from "../../../../common/interfaces";
import {useTranslation} from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import {manageFleetData} from "services/fleetManagementService";
import {useDispatch} from "react-redux";
import {showErrorMessages} from "../../../../redux/actions/errorMessages";
import {checkWholeNumberValue} from "../../../../common/methodHelpers";
import FleetScheduleView from "./fleetScheduleView";
import FleetConstraintSelectComponent from "./fleetConstraintSelectComponent";

const FleetConstraintsSchedules = (props: ObjectDetails) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  let {scheduleDetails, groupId, vehicleId, fetchFleetConstrainsDetails} = props;
  const [scheduleEdit, setScheduleEdit] = useState<any>([]);
  const [scheduleView, setScheduleView] = useState<any>([]);
  const showConstraintsUpdateButton = vehicleId
    ? CheckAccess([claims.VEHICLE_UPDATE])
    : CheckAccess([claims.FLEET_UPDATE]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState<any>();
  const [editScheduleModal, setEditScheduleModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [callScheduleView, setCallScheduleView] = useState(false);
  const [disableSaveBtn, setSaveDisable] = useState(true);

  useEffect(() => {
    if (scheduleDetails) {     
      setScheduleEdit(prepareScheduleConstraintsForDisplay(scheduleDetails));
      setScheduleView(prepareScheduleConstraintsForDisplay(scheduleDetails));
      let scheduleData = prepareScheduleConstraintsForDisplay(scheduleDetails);
      getEnableData(scheduleData);
      setCallScheduleView(scheduleData.length >= 1);
    }
  }, [scheduleDetails]);

const getEnableData = (scheduleData: {}[]) => {
  const scheduleIsEnabledData = scheduleData
    .map((schedule: any, i: any) => (schedule.isEnable === true ? i : -1))
    .filter((i) => i != -1);
  if(scheduleIsEnabledData.length > 0){
    setSaveDisable(false);
  } else{
    setSaveDisable(true);
  }
};

const cancelScheduleEvent = (isScheduleEditCancel: boolean) => {
  if (isScheduleEditCancel) {
    setEditScheduleModal(false);
    setScheduleEdit([...scheduleView]);
    getEnableData(scheduleView);
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  }
}; 

  const handleScheduleValueChange = (event: any, index: number, fieldName: string = "") => {
    if(event?.target?.checked) {
      setSaveDisable(false);
    }
    let name = fieldName ? fieldName : event.target.name;
    let value = name === FLEET_CONSTRAINTS_ISENABLE ? event?.target?.checked : event.target.value;
    if (name === FLEET_CONSTRAINTS_ISENABLE) {
      scheduleEdit[index] = updatingScheduleConstraint(name, scheduleEdit[index], value, scheduleEdit, (index - 1));
    } else {
      scheduleEdit[index] = updatingScheduleConstraint(name, scheduleEdit[index], value);
    }
    setScheduleEdit([...scheduleEdit]);
  };

  const saveScheduleData = () => {
    const formValid = validationScheduleConstraints(scheduleEdit, simpleValidator);
    if (formValid) {
      setLoading(true);
      let body: ObjectDetails = prepareScheduleConstraintsForSave(scheduleEdit);
      body.group_id = groupId;
      if (vehicleId) {
        body.vehicle_id = vehicleId;
      }
      let params = {action: "createFleetConstraints"};
      manageFleetData(params, body).then(
        (res: any) => {
          if (!res.status) {
            dispatch(
              showErrorMessages({
                error: true,
                message: res.message,
                type: "error",
                operation: "",
              })
            );
          }
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setEditScheduleModal(false);
          setScheduleView([...scheduleEdit]);
          setCallScheduleView(scheduleEdit.length >= 1);
          fetchFleetConstrainsDetails();
          setLoading(false);
        },
        (error) => {
          dispatch(
            showErrorMessages({
              error: true,
              message: error.message,
              type: "error",
              operation: "",
            })
          );
        }
      );
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <div className="information_section schedule_section">
        <Card variant="outlined" className="basic_card">
          <CardContent>
            <Card variant="outlined" className="basic_card">
              {/*----Title Start----*/}
              <div className="title_row">
                <h2>{toUpper(t("fleet.schedule"))}</h2>
                {showConstraintsUpdateButton && (
                  <div className={`action_icon`}>
                    <IconButton className="top_action_btn" data-testid="editIcon" onClick={() => setEditScheduleModal(true)}>
                      <img src={Images.ic_edit} alt={t("common.edit")} />
                    </IconButton>
                  </div>
                )}
                <div className="clearfix" />
                {!loading && callScheduleView ?
                  <FleetScheduleView
                    scheduleView={scheduleView}
                  />
                  :
                  <></>
                }
              </div>
              {/*----Title End----*/}

              {/*----Information Start----*/}
            
              {/*----Information End----*/}

              {/*  Dialog for Edit Energy level constraint*/}
              <div className="dialog_box">
                <Dialog
                  className="dialog_container_fleet dialog_container_center_fleet  sm"
                  open={editScheduleModal}
                  scroll={"paper"}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <div className="dialog_title">
                    <h4>{t("fleet.schedule")}</h4>
                    <div className="button_row mt-0 full_right">
                      <Button className="btn_white text_btn_theme" data-testId="cancelBtn" onClick={() => cancelScheduleEvent(true)}>
                        {t("common.cancelbutton")}
                      </Button>
                      
                      <Button
                        onClick={() => saveScheduleData()}
                        className={`btn_white ${checkForDisableClass(disableSaveBtn)}`}  disabled={disableSaveBtn}
                        type="submit"
                        data-testId="editScheduleModal"
                      >
                        {t("common.savechanges")}
                      </Button>
                    </div>
                  </div>
                  {loading && <LinearProgress />}
                  <DialogContent>
                    <DialogContentText id="scroll-dialog-description">
                      <div className="information_section schedule_section">
                        {/*----Information Start----*/}
                        <div className="schedule_container">
                          <div className="info_detail common_form">
                            <div className="default_form mt-30">
                              <div className="ml_10 form_col">
                                <Grid container justifyContent="flex-start" alignItems="flex-start">
                                  <Grid item className="form_col" xs={2} />
                                  <Grid item xs={3}>
                                    <Grid container justifyContent="flex-start" alignItems="flex-start">
                                      <Grid item>
                                        <LabelInfo name={t("fleet.departureTime")} isMandatory={false} />
                                        <div>
                                          <LabelInfo name={t("fleet.plugOut")} isMandatory={false} />
                                        </div>
                                      </Grid>
                                      <Grid item className="ml-30 padding-left-returnplug">
                                        <LabelInfo name={t("fleet.returnPlug")} isMandatory={false} />
                                        <div>
                                          <LabelInfo name={t("fleet.plugIn")} isMandatory={false} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item justifyContent="flex-start" xs={3}>
                                    <span className="margin-left-20">
                                      <LabelInfo name={t("fleet.buffer")} isMandatory={false} />
                                    </span>
                                    <Grid container justifyContent="flex-start" alignItems="flex-start">
                                      <Grid item>
                                        <LabelInfo name={t("fleet.beforeDeparture")} isMandatory={false} />
                                      </Grid>
                                      <Grid item className="ml-40 padding-left-onreturn">
                                        <LabelInfo name={t("fleet.onReturn")} isMandatory={false} />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <span className="margin-left-20">
                                      <LabelInfo name={t("fleet.scheduleEnergyFlow")} isMandatory={false} />
                                    </span>
                                    <Grid container justifyContent="flex-start" alignItems="flex-start">
                                      <Grid item>
                                        <LabelInfo name={t("fleet.startTime")} isMandatory={false} />
                                      </Grid>
                                      <Grid item className="ml-60 padding-left-endtime">
                                        <span className="margin-left-30">
                                          <LabelInfo name={t("fleet.endTime")} isMandatory={false} />
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs>
                                    <LabelInfo name={t("admessage.duration")} isMandatory={false} />
                                  </Grid>
                                </Grid>
                                {scheduleEdit &&
                                  scheduleEdit.map((value: any, index: number) => (
                                    <div className="mt-10 mb-10 bottom_border" key={"plugOut"}>
                                      <div className="mb-20 mt-20">
                                        <Grid container justifyContent="flex-start" alignItems="flex-start">
                                          <Grid item className="form_col checkbox mt-10 mb-20" xs={2}>
                                            <Checkbox
                                              id="enableDay"
                                              data-testId={`enableDay-${value.isEnable}-${index}`}
                                              checked={value.isEnable}
                                              value={value.isEnable}
                                              color="primary"
                                              name="isEnable"
                                              onChange={(event) => handleScheduleValueChange(event, index)}
                                            />
                                            <LabelInfo name={startCase(toLower(value.day))} isMandatory={false} />
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Grid container justifyContent="flex-start" alignItems="flex-start">
                                              <Grid item>
                                                <FormControl>                                                 
                                                  <FleetConstraintSelectComponent
                                                    isEnable={value.isEnable}
                                                    scheduleIndex={index}
                                                    type={"plugOut"}
                                                    value={value.plugOut}
                                                    handleScheduleValueChange={handleScheduleValueChange}
                                                  />
                                                  {value.isEnable && (
                                                    <cite className="fleet_error_msg">
                                                      {simpleValidator.current.message(
                                                        `plugOut${index}`,
                                                        value.plugOut,
                                                        "required",
                                                        {messages: {required: t("fleet.plugOutRequired.error")}}
                                                      )}
                                                    </cite>
                                                  )}
                                                </FormControl>
                                              </Grid>
                                              <Grid item className="ml-30">
                                                <FormControl>                                                  
                                                  <FleetConstraintSelectComponent
                                                    isEnable={value.isEnable}
                                                    scheduleIndex={index}
                                                    type={"plugIn"}
                                                    value={value.plugIn}
                                                    handleScheduleValueChange={handleScheduleValueChange}
                                                  />
                                                  {value.isEnable && (
                                                    <cite className="fleet_error_msg">
                                                      {simpleValidator.current.message(
                                                        `plugIn${index}`,
                                                        value.plugIn,
                                                        "required|numeric|min:0,num",
                                                        {messages: {required: t("fleet.plugInRequired.error")}}
                                                      )}
                                                    </cite>
                                                  )}
                                                </FormControl>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Grid container justifyContent="flex-start" alignItems="flex-start">
                                              <Grid item className="result">
                                                <div className="form_control">
                                                  <div
                                                    className={`form_col f_md ${
                                                      checkForErrorSchedule(
                                                        simpleValidator,
                                                        "beforeDeparture",
                                                        index,
                                                        value.isEnable
                                                      ) && "error-show"
                                                    }`}
                                                  >
                                                    <FormControl>
                                                      <OutlinedInput
                                                        key={`beforeDeparture`}
                                                        className={`input_text ${
                                                          (!value.isEnable) && "disable"
                                                        }`}
                                                        disabled={!value.isEnable}
                                                        id="beforeDeparture"
                                                        name={`beforeDeparture${index}`}
                                                        inputProps={{maxLength: 4, "data-testid": `beforeDeparture_${index}`}}
                                                        value={value.beforeDeparture}
                                                        onKeyPress={(event) => checkWholeNumberValue(event)}
                                                        required={true}
                                                        onChange={(event) =>
                                                          handleScheduleValueChange(event, index, "beforeDeparture")
                                                        }
                                                      />
                                                      {value.isEnable && (
                                                        <cite className="fleet_error_msg">
                                                          {simpleValidator.current.message(
                                                            `beforeDeparture${index}`,
                                                            value.beforeDeparture,
                                                            "required|numeric|min:0|max:1440,num",
                                                            {
                                                              messages: {
                                                                required: t("fleet.departureRequired.error"),
                                                                min: t("fleet.departureMin.error"),
                                                                max: t("fleet.onReturnDepartureMax.error"),
                                                              },
                                                            }
                                                          )}
                                                        </cite>
                                                      )}
                                                    </FormControl>
                                                  </div>
                                                </div>
                                              </Grid>
                                              <Grid item className="ml-30 result">
                                                <div className="form_control">
                                                  <div
                                                    className={`form_col f_md ${
                                                      checkForErrorSchedule(
                                                        simpleValidator,
                                                        "onReturn",
                                                        index,
                                                        value.isEnable
                                                      ) && "error-show"
                                                    }`}
                                                  >
                                                    <FormControl>
                                                      <OutlinedInput
                                                        key={`onReturn`}
                                                        className={`input_text ${
                                                          (!value.isEnable) && "disable"
                                                        }`}
                                                        disabled={!value.isEnable}
                                                        id="onReturn"
                                                        name={`onReturn${index}`}
                                                        inputProps={{maxLength: 4, "data-testid": `onReturn_${index}`}}
                                                        value={value.onReturn}
                                                        onKeyPress={(event) => checkWholeNumberValue(event)}
                                                        required={true}
                                                        onChange={(event) =>
                                                          handleScheduleValueChange(event, index, "onReturn")
                                                        }
                                                      />
                                                      {value.isEnable && (
                                                        <cite className="fleet_error_msg">
                                                          {simpleValidator.current.message(
                                                            `onReturn${index}`,
                                                            value.onReturn,
                                                            "required|numeric|min:0|max:1440,num",
                                                            {
                                                              messages: {
                                                                required: t("fleet.onReturnRequired.error"),
                                                                min: t("fleet.onReturnMin.error"),
                                                                max: t("fleet.onReturnDepartureMax.error"),
                                                              },
                                                            }
                                                          )}
                                                        </cite>
                                                      )}
                                                    </FormControl>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Grid container justifyContent="flex-start" alignItems="flex-start">
                                              <Grid item>
                                                <FormControl>                                                  
                                                  <FleetConstraintSelectComponent
                                                    isEnable={value.isEnable}
                                                    scheduleIndex={index}
                                                    type={"startTime"}
                                                    value={value.startTime}
                                                    handleScheduleValueChange={handleScheduleValueChange}
                                                  />
                                                  {value.isEnable && (
                                                    <cite className="fleet_error_msg">
                                                      {simpleValidator.current.message(
                                                        `startTime${index}`,
                                                        value.startTime,
                                                        "required",
                                                        {messages: {required: t("fleet.scheduleStartRequired.error")}}
                                                      )}
                                                    </cite>
                                                  )}
                                                </FormControl>
                                              </Grid>
                                              <Grid item className="ml-30">
                                                <FormControl>
                                                  <FleetConstraintSelectComponent
                                                    isEnable={value.isEnable}
                                                    scheduleIndex={index}
                                                    type={"endTime"}
                                                    value={value.endTime}
                                                    handleScheduleValueChange={handleScheduleValueChange}
                                                  />
                                                  {value.isNextDay && (
                                                    <div className="text_font">{t("fleet.nextday")}</div>
                                                  )}
                                                  {value.isEnable && (
                                                    <cite className="fleet_error_msg">
                                                      {simpleValidator.current.message(
                                                        `endTime${index}`,
                                                        value.endTime,
                                                        "required",
                                                        {messages: {required: t("fleet.scheduleEndRequired.error")}}
                                                      )}
                                                    </cite>
                                                  )}
                                                </FormControl>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs>
                                            <div className="text_font custom_line_height mt-10">
                                              {value?.duration ? value?.duration + ` ${t("pricing.hours")}` : ""}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*----Information End----*/}
                      </div>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </div>
              {/* Dialog Add End */}
            </Card>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
export default FleetConstraintsSchedules;
