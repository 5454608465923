const initialStateUser = {
    error: null,
    manageUserInfo : {}
};

export default function fetchManageUserInfoListReducer(state = initialStateUser, action: any) {

    if(action.type === 'FETCH_MANAGE_USER_INFO_LIST_ACTION') {
        return {
            error: null,
            manageUserInfo: action.payload
        }
    } else {
        return state;
    }
}