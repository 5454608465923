import {ObjectDetails} from "../../../../common/interfaces";
import { PricingTypeGrid } from "common/interfaces/pricingPlan";
import { BLANK } from "common";
import { cloneDeep } from "lodash";


export const LCD_DISPLAY = "LCD_DISPLAY";
export const USAGE_RATE  = "USAGE_RATE";

/*--------- List Pricing Plan Start ----------*/
export const checkPermission = (locationLevelPermissions: string, accessType: string) => {
    let result = true;
    if(locationLevelPermissions) {
        result = locationLevelPermissions.includes(accessType);
    }
    return result;
  } 

export const functionSetPaginateListArray = (pricingPlanList: any, pagingSize: any) => {
  let pricingPlan: any[] = pricingPlanList;
  const noOfPages = Math.ceil(pricingPlan.length / pagingSize);
  let pageList: Array<any> = [];
  let i = 0;
  while (i < noOfPages - 1) {
    const begin = i * pagingSize;
    const end = begin + pagingSize;
    pageList.push(pricingPlan.slice(begin, end));
    i++;
  }
  pageList.push(pricingPlan.slice(i * pagingSize, pricingPlan.length))
   return pageList;
};

/*--------- List Pricing Plan End -----*/

/*----------------------------DayOfTime & Duration ------------------------------------*/

export const getChargingFeeType = (chargingFeeType : boolean) => {
  return chargingFeeType? PRICING_PLAN_TYPES.DURATION : PRICING_PLAN_TYPES.TIME_OF_DAY;
}

export const preparePlanForDayTime = (plan: any, feeType:string="") => {
  let temp : any = [];
  let index = 0;
  plan.forEach((monthVal: any) => {
      let mVal = {
          start: monthVal.month[0],
          end: monthVal.month[monthVal.month.length - 1],
          day:""
      };
      let dayArr: any = [];
      monthVal.day.forEach((dayVal: any) => {
        let dVal = {
            start: dayVal.days[0],
            end: dayVal.days[dayVal.days.length - 1],
            time:""
        };
        let rate = dayVal.time[0].rate;
        let start = TIME_START;
        let end = TIME_END;
        let timing: any = [];
        for (let time in rate) {
            if (rate.hasOwnProperty(time)) {
              if((Number(time) === 0 || Number(time)%TIME_IN_1_HOUR === 0) && Number(time) <= TIME_IN_11_HR){
                let currentHour = Number(rate[Number(time) + TIME_IN_1_HOUR]);
                let currentTime = Number(time) + TIME_IN_1_HOUR;
                let everyHourValue = currentHour + Number(rate[Number(currentTime) + TIME_IN_15_MIN]) + 
                                     Number(rate[Number(currentTime) + TIME_IN_30_MIN])
                                     + Number(rate[Number(currentTime) + TIME_IN_45_MIN]);
                let val = Number(rate[time]) + Number(rate[Number(time) + TIME_IN_15_MIN]) + Number(rate[Number(time) + TIME_IN_30_MIN])
                + Number(rate[Number(time) + TIME_IN_45_MIN]);
                if (end === Number(time)) {
                    timing.push({
                        start: start,
                        end: end,
                        cost: Number(feeType === PARKING ? (rate[start] * 4): rate[start]).toFixed(2),
                        index: index++
                    });
                } else if (val !== everyHourValue) {
                    timing.push({
                        start: start,
                        end: Number(time) === (TIME_IN_11_HR) ? Number(time) + TIME_IN_45_MIN : Number(time) + TIME_IN_1_HOUR,
                        cost: Number(feeType === PARKING ? (val): rate[start]).toFixed(2),
                        index: index++
                    });
                    start = Number(time) === (TIME_IN_11_HR) ?
                        Number(time) + TIME_IN_45_MIN : Number(time) + TIME_IN_1_HOUR;
                }
            }
          }
        }
        dVal['time'] = timing;
        dayArr.push(dVal);
    });
      mVal['day'] = dayArr;
      temp.push(mVal);
      
  });
  return { data: temp, index: index };
}

const initDay = (start = DAY_START, end = DAY_END) => {
  return { start: start, end: end, time: [initTimes()] }
}

const initTimes = (start = TIME_START, end = TIME_END, value = 0) => {
  return { start: start, end: end, cost: value.toFixed(2)};
}

export const addFieldForTimeDay = (type: string, event: any, monthIndex: number, dayIndex: number, timIndex: number, total: any, pricingPlanData: any) => {
  let selectVal = event.target.value;
  let  newPricingDetails= [...pricingPlanData];
  if (type === 'day') {
      let controlDay = newPricingDetails[monthIndex]['day'];
      controlDay[dayIndex]['end'] = (selectVal);
      if ((dayIndex + 1) === total) {
          controlDay.push(initDay((selectVal + 1), 6));
      } else {
          let end = controlDay[dayIndex + 1]['end'];
          let start = controlDay[dayIndex + 1]['start'];
          if (selectVal >= end) {
              controlDay[dayIndex]['end'] = (start - 1);
          } else {
              controlDay[dayIndex + 1]['start'] = (selectVal + 1);
          }
          newPricingDetails[monthIndex]['day'] = controlDay;
      }
  } else {
      let controlTime = newPricingDetails[monthIndex]['day'][dayIndex]['time'];
      controlTime[timIndex]['end'] = selectVal;
      if ((timIndex + 1) === total) {
          controlTime.push(initTimes(selectVal, TIME_END));
      } else {
          let end = controlTime[timIndex + 1]['end'];
          let start = controlTime[timIndex + 1]['start'];
          let cost = controlTime[timIndex + 1]['cost'];
          if (selectVal >= end) {
              controlTime[timIndex]['end'] = start;
              controlTime[timIndex]['cost'] = cost;
          } else {
              controlTime[timIndex + 1]['start'] = selectVal;
          }
          newPricingDetails[monthIndex]['day'][dayIndex]['time'] = controlTime;
      }
  }
  return newPricingDetails;
};

export const removeFieldForDayTime = (pricingPlanData: any, day: ObjectDetails[], time:ObjectDetails[], monthIndex: number, dayIndex: number,timeIndex: number) => {
  let  newPricingDetails= [...pricingPlanData];
  if ((day.length === (dayIndex + 1) && day.length !== 1) && (time.length === 1)) {
      let controlDay = newPricingDetails[monthIndex]['day'];
      controlDay[dayIndex - 1]['end'] = DAY_END;
      controlDay.splice(dayIndex);
      newPricingDetails[monthIndex]['day'] = controlDay;
  } else if ((time.length === (timeIndex + 1) && time.length !== 1)) {
      let controlTime = newPricingDetails[monthIndex]['day'][dayIndex]['time'];
      controlTime[timeIndex - 1]['end'] = TIME_END;
      controlTime.splice(timeIndex);
      newPricingDetails[monthIndex]['day'][dayIndex]['time'] = controlTime;
  }
  return newPricingDetails;
}


export const displayRemoveIcon = (day: ObjectDetails[], time:ObjectDetails[], dayIndex: number,timeIndex: number) => {
    if (((day.length === (dayIndex + 1) && day.length !== 1) && (time.length === 1)) || (time.length === (timeIndex + 1) && time.length !== 1)) {
        return true;
    }
}

export const timeOfDay = [{
    "month": [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12
    ],
    "day": [
        {
            "days": [
                0,
                1,
                2,
                3,
                4,
                5,
                6
            ],
            "time": [
                {
                    "start_t": 0,
                    "end_t": 85500,
                    "rate": {
                        "0": "0.00",
                        "900": "0.00",
                        "1800": "0.00",
                        "2700": "0.00",
                        "3600": "0.00",
                        "4500": "0.00",
                        "5400": "0.00",
                        "6300": "0.00",
                        "7200": "0.00",
                        "8100": "0.00",
                        "9000": "0.00",
                        "9900": "0.00",
                        "10800": "0.00",
                        "11700": "0.00",
                        "12600": "0.00",
                        "13500": "0.00",
                        "14400": "0.00",
                        "15300": "0.00",
                        "16200": "0.00",
                        "17100": "0.00",
                        "18000": "0.00",
                        "18900": "0.00",
                        "19800": "0.00",
                        "20700": "0.00",
                        "21600": "0.00",
                        "22500": "0.00",
                        "23400": "0.00",
                        "24300": "0.00",
                        "25200": "0.00",
                        "26100": "0.00",
                        "27000": "0.00",
                        "27900": "0.00",
                        "28800": "0.00",
                        "29700": "0.00",
                        "30600": "0.00",
                        "31500": "0.00",
                        "32400": "0.00",
                        "33300": "0.00",
                        "34200": "0.00",
                        "35100": "0.00",
                        "36000": "0.00",
                        "36900": "0.00",
                        "37800": "0.00",
                        "38700": "0.00",
                        "39600": "0.00",
                        "40500": "0.00",
                        "41400": "0.00",
                        "42300": "0.00",
                        "43200": "0.00",
                        "44100": "0.00",
                        "45000": "0.00",
                        "45900": "0.00",
                        "46800": "0.00",
                        "47700": "0.00",
                        "48600": "0.00",
                        "49500": "0.00",
                        "50400": "0.00",
                        "51300": "0.00",
                        "52200": "0.00",
                        "53100": "0.00",
                        "54000": "0.00",
                        "54900": "0.00",
                        "55800": "0.00",
                        "56700": "0.00",
                        "57600": "0.00",
                        "58500": "0.00",
                        "59400": "0.00",
                        "60300": "0.00",
                        "61200": "0.00",
                        "62100": "0.00",
                        "63000": "0.00",
                        "63900": "0.00",
                        "64800": "0.00",
                        "65700": "0.00",
                        "66600": "0.00",
                        "67500": "0.00",
                        "68400": "0.00",
                        "69300": "0.00",
                        "70200": "0.00",
                        "71100": "0.00",
                        "72000": "0.00",
                        "72900": "0.00",
                        "73800": "0.00",
                        "74700": "0.00",
                        "75600": "0.00",
                        "76500": "0.00",
                        "77400": "0.00",
                        "78300": "0.00",
                        "79200": "0.00",
                        "80100": "0.00",
                        "81000": "0.00",
                        "81900": "0.00",
                        "82800": "0.00",
                        "83700": "0.00",
                        "84600": "0.00",
                        "85500": "0.00"
                    }
                }
            ]
        }
    ]
}];

/*----------------------------------------------------------------End of DayOfTime & Duration ------------------------------------------------------*/

/*------------------------------------------------------------------Hybrid -------------------------------------------------------------------------*/

export const hybrid = [{
  "scope": "month",
  "month": [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12
  ],
  "time": [
      {
          "start_t": 0,
          "end_t": 85500,
          "tariff": [
              {
                  "dollar": "0",
                  "time": 24,
                  "time_diff": 24
              }
          ]
      }
  ]
},
];

export const getTimeIndex = (timeValIndex: number, rate: any, pricingType: any, timeStart: any) => {
    let timeIndex: number = 0;
    if (timeValIndex !== 0) {
        if (!timeStart) {
            timeIndex = Number(rate?.time - rate?.time_diff);
        } else {
            timeIndex = Number((rate?.time - rate?.time_diff) * TIME_IN_1_HOUR);
        } 
    }
    return timeIndex;
}

export const preparePlanForHybrid = (plan: any, pricingType: string ="") => {
  let temp: any = [];
  let index = 0;
  plan.forEach((monthVal: any) => {
      let mVal = {
          start: monthVal.month[0],
          end: monthVal.month[monthVal.month.length - 1],
          time: ""
      };

      let timeDiffValArr: any = [];
      let timeIndexLength = monthVal.time.length;
      let timeArrayIndex = (timeIndexLength - 1);
      monthVal.time.forEach((timeVal: any, timeIndex: any) => {

          let start = timeVal.start_t;
          let end = timeVal.end_t;

          let timeDiffVal = {
              start: start,
              end: end,
              duration: ""
          };
          let duration: any = [];
          timeVal.tariff.forEach((rate: any, timeValIndex:any) => {
              let dollar = rate.dollar;
              let timeEnd = rate.time; 
              let timeindex =  getTimeIndex(timeValIndex, rate, pricingType, false);
              let timeValue = prepareTimeDiffArray(timeEnd, dollar, timeindex);
              let val = "";
              let timeDiffStart = getTimeIndex(timeValIndex, rate, pricingType, true);
              let timeDiffEnd = TIME_END;
              for (let time in timeValue) {
                  if (timeValue.hasOwnProperty(time)) {
                      val = timeValue[time];
                      if (timeDiffEnd === Number(time)) {
                          duration.push({
                              start: timeDiffStart,
                              end: timeDiffEnd,
                              cost: Number(timeValue[timeDiffStart]).toFixed(2),
                              index: index++
                          });
                      } else if (val !== timeValue[Number(time) + TIME_IN_15_MIN]) {
                          duration.push({
                              start: timeDiffStart,
                              end: Number(time) + TIME_IN_15_MIN,
                              cost: Number(timeValue[timeDiffStart]).toFixed(2),
                              index: index++
                          });
                          timeDiffStart = Number(time) + TIME_IN_15_MIN;
                      }
                  }
              }
          })
          let endTime = hours[timeVal.tariff[timeVal.tariff.length - 1]['time']] ?
              hours[timeVal.tariff[timeVal.tariff.length - 1]['time']]['value'] : TIME_END;
          let time_End = getTimeEndValue(pricingType, timeIndex, timeArrayIndex, timeVal);
          if ((pricingType === PRICING_PLAN_TYPES.HHYBRID && endTime < TIME_END)
              || (pricingType === PRICING_PLAN_TYPES.HYBRID_2 && ((timeIndex !== timeArrayIndex && endTime < (timeVal.end_t - timeVal.start_t))
                  || (timeIndex === timeArrayIndex && endTime < time_End)))) {
              duration.push({
                  start: endTime,
                  end: time_End,
                  cost: (0).toFixed(2)
              });
          }
          timeDiffVal['duration'] = duration;
          timeDiffValArr.push(timeDiffVal);

      });
      mVal['time'] = timeDiffValArr;
      temp.push(mVal);

  });
  return { data: temp, index: index };
}

export const getTimeEndValue = (pricingType: any, timeIndex: number, timeArrayIndex: number, timeVal: any) => {
    if (pricingType === PRICING_PLAN_TYPES.HHYBRID) 
        return TIME_END;
    else if ((timeIndex !== timeArrayIndex) || (timeArrayIndex === 0)) {
        return timeVal.end_t - timeVal.start_t;
    } else {
        return (timeVal.end_t - timeVal.start_t) + TIME_IN_15_MIN ;
    }           
}

export const prepareTimeDiffArray = (time: number, dollar: string, index: number) => {    
  let timeDiff: any = {}
  let timeIndex = 0;
  index = index * 4;
  if(index !== 0) 
  {
      timeIndex = (index*TIME_IN_15_MIN);
  }
  time = time * 4;
  for (let i = index; i < time; i++) {
      timeDiff[timeIndex] = dollar;
      timeIndex += TIME_IN_15_MIN;
  }
  if (time === 96) //(Every 15 mins for 24 hours (24*4))
      timeDiff[TIME_END] = timeDiff[TIME_IN_11_HR];
  return timeDiff;
}

const initMonthForHybrid = (start = MONTH_START, end = MONTH_END) => {
  return { start: start, end: end, time: [initTimesForHybrid()] }
}

const initTimesForHybrid = (start = TIME_START, end = TIME_END, pricingType:any="") => {
  if (pricingType === PRICING_PLAN_TYPES.HYBRID_2) {
      let indexEnd = hours.findIndex(val => val.value === end);
      let indexStart = hours.findIndex(val => val.value === start);
      return { start: (indexStart * TIME_IN_1_HOUR), end: TIME_END, duration: [initHoursForHybrid(0, hours[(indexEnd - indexStart)].value, 0)] };
  }        
  else
      return { start: start, end: end, duration: [initHoursForHybrid()] };
}

const initHoursForHybrid = (start = TIME_START, end = TIME_END, value = 0) => {
  return { start: start, end: end, cost: value.toFixed(2) };
}

export const addFieldForHybrid = (type: string, event: any, monthIndex: number, timeIndex: number, hourIndex: number, total: any, pricingPlanData: any, pricingType:any) => {
  let selectVal = event.target.value;
  let  newPricingDetails= [...pricingPlanData];
  if (type === 'month') {
      newPricingDetails[monthIndex]['end'] = (selectVal);
      if ((monthIndex + 1) === total) {
          newPricingDetails.push(initMonthForHybrid((selectVal + 1), 12));
      } else {
          let prevEnd = newPricingDetails[monthIndex + 1]['end'];
          let nextStart = newPricingDetails[monthIndex + 1]['start'];
          if (selectVal >= prevEnd) {
              newPricingDetails[monthIndex]['end'] = (nextStart - 1);
          } else {
              newPricingDetails[monthIndex + 1]['start'] = (selectVal + 1);
          }
      }
  } else if (type === 'time') {
      let controlTime = newPricingDetails[monthIndex]['time'];
      controlTime[timeIndex]['end'] = (selectVal);
      if ((timeIndex + 1) === total) {            
          if(pricingType === PRICING_PLAN_TYPES.HYBRID_2) {                
              let duration = newPricingDetails[monthIndex]['time'][timeIndex]['duration'];
              let durationCost = Number(duration[0].cost);
              for (let i = 0; i <= duration.length; i++) {
                duration = duration.splice(1, i);
              } 
              let indexEnd = hours.findIndex((val:any) => val.value === selectVal);
              let indexStart = hours.findIndex((val:any) => val.value === controlTime[timeIndex]['start']);
              let durationTime = {
                  end: hours[(indexEnd - indexStart)].value,
                  start: 0,
                  cost: durationCost.toFixed(2)
              } 
              duration.push(Object.assign({}, durationTime));
              newPricingDetails[monthIndex]['time'][timeIndex]['duration'] = duration;
          }
          controlTime.push(initTimesForHybrid((selectVal), TIME_END, pricingType));
      } else {
          let end = controlTime[timeIndex + 1]['end'];
          let start = controlTime[timeIndex + 1]['start'];
          if (selectVal >= end) {
              controlTime[timeIndex]['end'] = start;
          } else {
              controlTime[timeIndex + 1]['start'] = selectVal;
          }
          if (pricingType === PRICING_PLAN_TYPES.HYBRID_2) {
            controlTime.forEach((time: any, index: any) => {
              let duration = newPricingDetails[monthIndex]['time'][index]['duration'];
              let durationCost = Number(duration[0].cost);
              for (let i = 0; i <= duration.length; i++) {
                duration = duration.splice(1, i);
              }              
              let indexEnd = hours.findIndex((val:any) => val.value === (index === timeIndex ? selectVal: time['end']));
              let indexStart = hours.findIndex((val:any) => val.value === time['start']);  
              let durationTime = {
                  end: hours[(indexEnd - indexStart)].value,
                  start: 0,
                  cost: durationCost.toFixed(2)
              } 
              duration.push(Object.assign({}, durationTime));         
              newPricingDetails[monthIndex]['time'][index]['duration'] = duration;
            })              
          }
          newPricingDetails[monthIndex]['time'] = controlTime;
      }
  } else {
      let controlHour = newPricingDetails[monthIndex]['time'][timeIndex]['duration'];
      controlHour[hourIndex]['end'] = selectVal;
      if ((hourIndex + 1) === total) {    
          let end = TIME_END;    
          if(pricingType === PRICING_PLAN_TYPES.HYBRID_2) {    
              let indexEnd = hours.findIndex((val:any) => val.value === newPricingDetails[monthIndex]['time'][timeIndex]['end']);
              let indexStart = hours.findIndex((val:any) => val.value === newPricingDetails[monthIndex]['time'][timeIndex]['start']);  
              end = hours[(indexEnd - indexStart)].value;                
          }              
          controlHour.push(initHoursForHybrid(selectVal, end));
      } else {
          let end = controlHour[hourIndex + 1]['end'];
          let start = controlHour[hourIndex + 1]['start'];
          let cost = controlHour[hourIndex + 1]['cost'];
          if (selectVal >= end) {
              controlHour[hourIndex]['end'] = start;
              controlHour[hourIndex]['cost'] = cost;
          } else {
              controlHour[hourIndex + 1]['start'] = selectVal;
          }
          newPricingDetails[monthIndex]['time'][timeIndex]['duration'] = controlHour;
      }
  }
  return newPricingDetails;
};

export const removeFieldForHybrid = (pricingPlanData: any, time: ObjectDetails[], hour:ObjectDetails[], monthIndex: number, timeIndex: number,hourIndex: number, pricingType:string="") => {
  let  newPricingDetails= [...pricingPlanData];
  if ((newPricingDetails.length === (monthIndex+ 1) && newPricingDetails.length !== 1) && (time.length === 1 && hour.length === 1)) {
      let controlMonth = newPricingDetails;
      controlMonth[monthIndex - 1]['end'] = MONTH_END;
      controlMonth.splice(monthIndex);
      newPricingDetails = controlMonth;
  } else if ((time.length === (timeIndex + 1) && time.length !== 1) && (hour.length === 1)) { 
      let controlTime = newPricingDetails[monthIndex]['time'];
      controlTime[timeIndex - 1]['end'] = TIME_END;
      if (pricingType === PRICING_PLAN_TYPES.HYBRID_2) {
          let duration = newPricingDetails[monthIndex]['time'][timeIndex-1]['duration'];
          let deleteHourindex = duration.length -1;
          let indexEnd = hours.findIndex(val => val.value === TIME_END);
          let indexStart = hours.findIndex(val => val.value === controlTime[timeIndex-1]['start']);
          duration[deleteHourindex]['end'] = hours[(indexEnd - indexStart)].value;
      }
      controlTime.splice(timeIndex);
      newPricingDetails[monthIndex]['time'] = controlTime;            
  } else if ((hour.length === (hourIndex + 1) && hour.length !== 1)) {
      let controlHour = newPricingDetails[monthIndex]['time'][timeIndex]['duration'];
      let value = TIME_END;
      if (pricingType === PRICING_PLAN_TYPES.HYBRID_2) {
          value = controlHour[hourIndex]['end'];
      }        
      controlHour[hourIndex - 1]['end'] = value;
      controlHour.splice(hourIndex);
      newPricingDetails[monthIndex]['time'][timeIndex]['duration'] = controlHour;
  }
  return newPricingDetails;
}

export const displayRemoveIconForHybrid = (pricingPlanData: any, time: ObjectDetails[], hour:ObjectDetails[], monthIndex: number, timeIndex: number,hourIndex: number) => {
  if (((pricingPlanData.length === (monthIndex + 1) && pricingPlanData.length !== 1) && (time.length === 1 && hour.length === 1)) || ((time.length === (timeIndex + 1) && time.length !== 1) && (hour.length === 1)) || ((hour.length === (hourIndex + 1) && hour.length !== 1))) {
      return true;
  }
}

/*-----------------------------------------------------------------End of Hybrid ------------------------------------------------------------------*/


/*---------------------------------------------------------------Save Pricing Plan --------------------------------------------------------------*/

export const prepareConfigSaveData = (body: any, pricingPlandata: any[], pricingDetails: any, energyFee: boolean, parkingFee: boolean, timeArrayDetail: any = [], parkingAndEnergyFee?: boolean) => {
    if (energyFee) {
        body[CHARGING_FEE_TYPE] = getChargingFeeType(pricingDetails?.durationBased)
        body[CHARGING] = preparePlanForDayTimeSave(pricingPlandata, ENERGY_FEE);
        if (parkingAndEnergyFee) {
            getParkingOrChangingFee(PARKING_FEE, body);
        }
    }
    else if (parkingFee) {
        if (pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY || pricingDetails?.type === PRICING_PLAN_TYPES.DURATION) {
            if(body.ctep_enabled ===  CTEP_YES) {
                body[PARKING] = preparePlanForDayTimeSave(pricingPlandata, PARKING_FEE);
            } else {
                body[PARKING] = createDetailedViewObjectChange(timeArrayDetail, pricingPlandata);
            }
            if (parkingAndEnergyFee) {
                getParkingOrChangingFee(ENERGY_FEE, body);
            }
        }
        else
            body[PARKING] = preparePlanForHybridSave(pricingPlandata);
    }

    return body;
}

export const getParkingOrChangingFee = (feeType: any, body: any) => {
    let parameter = feeType === PARKING_FEE ? PARKING : CHARGING;
    let pricing = preparePlanForDayTime(timeOfDay);
    body[parameter] = preparePlanForDayTimeSave(pricing.data, feeType);
}

export const preparePlanForDayTimeSave = (data: any, feeType: any) => {
  let tempTime: any = {}
  for (const element of data) {
      let day = element['day'];
      tempTime = prepareTimeArray(day, feeType);
  }
  return tempTime;
}

export const prepareTimeArray = (selectedTime: any, feeType: any) => {
  let eachDay: any = []
  let eachTime: any = {};
  let eachDayArray: any = {};

  selectedTime.forEach((item: any) => {

      let dayVal = item.start;
      let timeVal = item.time;

      if (!Array.isArray(item.time)) {
          timeVal = [item.time];
      }
      eachTime = getTimeVal(timeVal, eachTime, feeType)
      let eachTimeArray = Object.assign({}, eachTime)
      for (let i = dayVal; i <= item.end; i++) {
          eachDay.push(eachTimeArray);
          eachDayArray = Object.assign({}, eachDay)
      }  
  });   
  return eachDayArray;
}

export const getTimeVal = (timeVal:any, eachTime: any={}, feeType: any) => {
  let index = 0;
  timeVal.forEach((time: any) => {
      let start = time.start;
      while (start < time.end) {
          if (index <= time.end) {
              for (let i = 0; i < 2; i++) {
                  eachTime[index] = (feeType === PARKING_FEE ? time.cost/4 : time.cost);
                  index += TIME_IN_15_MIN;
              }
          }
          start += TIME_IN_30_MIN;
      }
  });
  eachTime[TIME_END] = eachTime[TIME_IN_11_HR];
  return eachTime;
}

export const preparePlanForHybridSave = (data: any) => {
  let temp = [];
  for (const month of data) {
      let monthVal = prepareArray(month.start, month.end, 1);
      let tempTime:any = [];
      for (const time of month['time']) {
          tempTime.push(prepareTimeArrayHybrid(time));
      }
      let costObj = {
          scope: 'month', month: Object.assign({}, monthVal),
          time: Object.assign({}, tempTime)
      };
      temp.push(Object.assign({}, costObj));
  }
  return Object.assign({}, temp)
}


export const prepareArray = (start: any, end: any, increment: any) => {
  let val = [];
  while (start <= end) {
      val.push(start);
      start += increment;
  }
  return val;
};

export const prepareTimeArrayHybrid = (selectedTime: any) => {
  let eachTime: any = {};
  let index = 0;
  let value = selectedTime.duration;
  if (!Array.isArray(selectedTime.duration)) {
      value = [selectedTime.duration];
  }
  value.forEach((item: any) => {
      let start = item.start;
      while (start < item.end) {
          if (index <= item.end) {
              for (let i = 0; i < 2; i++) {
                  eachTime[index] = item.cost/4;
                  index += TIME_IN_15_MIN;
              }
          }
          start += TIME_IN_30_MIN;
      }
  });
  eachTime[TIME_END] = eachTime[TIME_IN_11_HR];
  return {"start_t" : selectedTime.start, "end_t": selectedTime.end, tariff: eachTime };
}

/*---------------------------------------------------------------End Save Pricing Plan --------------------------------------------------------------*/

/*---------------------------------------------------------------Delete Pricing Plan Body--------------------------------------------------------------*/

export const prepareBodyForDelete = (openStations: any, selectedStations: any) => {
  let selectedId : any = [];
  let selectedIdToDelete = {
      "id" : ""
  };
  openStations.forEach(function(stations: any) {
      if (selectedStations.indexOf(stations.name) !== -1)             
      selectedId.push(stations.id);
  });
  selectedIdToDelete['id'] = selectedId;
  return selectedIdToDelete;   
}

/*---------------------------------------------------------------End DeletePricing Plan Body--------------------------------------------------------------*/

/*--------------------------------------------------------------- Common Methods and data------------------------------------------------------------*/

export const filterDropDownOptions = (type: any, array: any[], index: number, val: any, pricingType: string = "", pricingPlanData: any[] = [], timeIndex:number =0, hourIndex:number = 0) => {
  let newPricingDetails = [...pricingPlanData];
  if (type === 'month') {
      if (index > 0) {
          return array.slice(val - 1);
      } else {
          return array;
      }
  } else if (type === 'day') {
      if (index > 0) {
          return array.slice(val);
      } else {
          return array;
      }
  } else if (type === "time") {

          let controlTime = newPricingDetails[index]['time'];
          let value = +(controlTime[timeIndex]['start']);
          let i = hours.findIndex((val: any) => val.value === value);
          return array.slice(i + 1);

  } else {
      if (pricingType === PRICING_PLAN_TYPES.HYBRID_2) {
          let controlTime = newPricingDetails[index]['time'][timeIndex]['duration'];
          let startValue = +(controlTime[hourIndex]['start']);
          let endValue = +(controlTime[hourIndex]['end']);
          let i = hours.findIndex((val: any) => val.value === startValue);
          let j = hours.findIndex((val: any) => val.value === endValue);
          return array.slice(i + 1, j + 1);
      } else {
          let i = array.findIndex((val1: any) => val1.value === val);
          return array.slice(i + 1);
      }
  }
}

export const pricingPlanTypes = [
  { value: "T", label: "Time Of Day" },
  { value: "D", label: "Duration" },
  { value: "H", label: "Hybrid" },
  { value: "H2", label: "Hybrid-2" },
];

export const PRICING_PLAN_TYPES = {
    "TIME_OF_DAY": "T",
    "DURATION": "D",
    "HHYBRID": "H",
    "HYBRID_2": "H2"
};

export const handletimeOrHour = (props: PricingTypeGrid, hours: any[], times: any[]) => {
  if (((props?.pricingDetails?.type === PRICING_PLAN_TYPES.DURATION || props?.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY) &&
      ((props?.pricingDetails?.type === PRICING_PLAN_TYPES.DURATION && props?.parkingFee) || (props?.energyFee && props?.pricingDetails?.durationBased))))
      return hours;
  else
      return times;
}

export const getDurationBased = (chargingFeeType: any) => {
    if (chargingFeeType === PRICING_PLAN_TYPES.TIME_OF_DAY || chargingFeeType === "" || chargingFeeType === null)
        return false;
    if (chargingFeeType === PRICING_PLAN_TYPES.DURATION)
        return true;
}

export const handleAddorEditPopupTitle = (editPopup:boolean, setFirstPopupname: any, setSecondPopupname: any, addFirstName: any, addSecondName: any, editFirstName: any, editSecondname: any) => {
  let firstPopupHeader = "";
  let secondPopupHeader = "";

  if (editPopup) {
      firstPopupHeader = editFirstName;
      secondPopupHeader = editSecondname;
  }
  else {
      firstPopupHeader = addFirstName;
      secondPopupHeader = addSecondName;
  }

  setFirstPopupname(firstPopupHeader);
  setSecondPopupname(secondPopupHeader);
}

export const months = [{ value: 1, label: 'Jan' }, { value: 2, label: 'Feb' },
{ value: 3, label: 'Mar' }, { value: 4, label: 'Apr' },
{ value: 5, label: 'May' }, { value: 6, label: 'Jun' },
{ value: 7, label: 'Jul' }, { value: 8, label: 'Aug' },
{ value: 9, label: 'Sep' }, { value: 10, label: 'Oct' },
{ value: 11, label: 'Nov' }, { value: 12, label: 'Dec' }];

export const days = [{ value: 0, label: 'Sun' }, { value: 1, label: 'Mon' },
{ value: 2, label: 'Tue' }, { value: 3, label: 'Wed' },
{ value: 4, label: 'Thu' }, { value: 5, label: 'Fri' },
{ value: 6, label: 'Sat' }];

export const times = [
  { value: 0, label: '12:00AM' }, { value: 3600, label: '01:00AM' },
  { value: 7200, label: '02:00AM' }, { value: 10800, label: '03:00AM' },
  { value: 14400, label: '04:00AM' }, { value: 18000, label: '05:00AM' },
  { value: 21600, label: '06:00AM' }, { value: 25200, label: '07:00AM' },
  { value: 28800, label: '08:00AM' }, { value: 32400, label: '09:00AM' },
  { value: 36000, label: '10:00AM' }, { value: 39600, label: '11:00AM' },
  { value: 43200, label: '12:00PM' }, { value: 46800, label: '01:00PM' },
  { value: 50400, label: '02:00PM' }, { value: 54000, label: '03:00PM' },
  { value: 57600, label: '04:00PM' }, { value: 61200, label: '05:00PM' },
  { value: 64800, label: '06:00PM' }, { value: 68400, label: '07:00PM' },
  { value: 72000, label: '08:00PM' }, { value: 75600, label: '09:00PM' },
  { value: 79200, label: '10:00PM' }, { value: 82800, label: '11:00PM' },
  { value: 85500, label: '12:00AM' }
];

export const hours = [
  { value: 0, label: '00:00' }, { value: 3600, label: '01:00' },
  { value: 7200, label: '02:00' }, { value: 10800, label: '03:00' },
  { value: 14400, label: '04:00' }, { value: 18000, label: '05:00' },
  { value: 21600, label: '06:00' }, { value: 25200, label: '07:00' },
  { value: 28800, label: '08:00' }, { value: 32400, label: '09:00' },
  { value: 36000, label: '10:00' }, { value: 39600, label: '11:00' },
  { value: 43200, label: '12:00' }, { value: 46800, label: '13:00' },
  { value: 50400, label: '14:00' }, { value: 54000, label: '15:00' },
  { value: 57600, label: '16:00' }, { value: 61200, label: '17:00' },
  { value: 64800, label: '18:00' }, { value: 68400, label: '19:00' },
  { value: 72000, label: '20:00' }, { value: 75600, label: '21:00' },
  { value: 79200, label: '22:00' }, { value: 82800, label: '23:00' },
  { value: 85500, label: '24:00' }
];

export const TARIFF_MUL_FAC = 2;
export const TARIFF_ENERGY_COST = 0.10;
export const TARIFF_PARKING_COST = 2;

//Pricing Plan Constants
const MONTH_START = 1;
const MONTH_END = 12;
const DAY_START = 0;
const DAY_END = 6;
const TIME_START = 0;
const TIME_END = 85500;
const TIME_IN_15_MIN = 900;
const TIME_IN_30_MIN = 1800;
const TIME_IN_45_MIN = 2700;
const TIME_IN_1_HOUR = 3600;
const TIME_IN_11_HR = 82800;
const DETAILED_VIEW_15_MIN = 15;
const DETAILED_VIEW_30_MIN = 30;
const DETAILED_VIEW_45_MIN = 45;
export const SIMPLE_VIEW = "simple";
export const PARKING = "parking";
export const ENERGY = "energy";
export const CHARGING = "charging";
export const PARKING_FEE = "parkingFee";
export const ENERGY_FEE = "energyFee";
export const CHARGING_FEE_TYPE = "charging_fee_type";


/*--------- Apply Pricing Plan Start----*/
export const ACCESS_TO_STATIONS = [
    { id: 1, value: 1, name: "Private Access Only" },
    { id: 2, value: 2, name: "Private Access and Public" },
    { id: 3, value: 3, name: "Open Access" },
];

/*--------- Apply Pricing Plan End -----*/

export const CTEP_YES = "YES";
export const CTEP_NO = "NO";
export const CTEP_ENABLED = 'ctepEnabled';
export const CTEP_NOT_ENABLED = 'ctepNotEnabled';
export const DAY = "DAY";
export const ADDUSERGROUP = "userGroupAdd";
export const ADDPRICINGPLAN = "pricingPlanAdd";

export const setClassName = (spinnerOff:boolean= false, disableNextButton: boolean = false, disableSaveButton: boolean = false) => {
    if((spinnerOff || (!spinnerOff && disableNextButton)) || disableSaveButton) {
        return "disable";
    }
    else {
        return "blue_bg";
    }
}

export const setPricingPlanType = (ctepValue: string, setPricingPlanFilteredType: any, pricingDetails: any) => {
    let pricingPlanType = [];
    if (ctepValue === CTEP_ENABLED) {
        pricingPlanType = pricingPlanTypes.filter(obj => {
            return obj.value === "T";
        });  
        pricingDetails.type = PRICING_PLAN_TYPES.TIME_OF_DAY;
        setPricingPlanFilteredType(pricingPlanType);                       
    }
    else {
        setPricingPlanFilteredType(pricingPlanTypes);
    }
}

export const getAccessTypeValue = (applyPricingDetails:any) => {
    if (applyPricingDetails?.accessToStations === 0)
        return 0;
    else if (applyPricingDetails?.accessToStations)
        return applyPricingDetails?.accessToStations;
    else
        return BLANK;
}

export const createTimeArrayDetailed = () => {
    let time_Start = 1;
    let time_End = 24;    
    let eachHour : any =[];
    let timeArray: any = [];
    let start = 0;
    let end = TIME_IN_1_HOUR;

    let dayObject: any = "";
    dayObject = getDayArrayObject();
    for (let i=time_Start; i<=time_End; i++) {        
       let timeArrayObject = {
            from : (times[i-1].label),
            to:(times[i].label),
            fromDur: hours[i-1].label,
            toDur: hours[i].label,
            subStatus: false,
            day: dayObject,
            sub: ""
        }      
        let timeArrayObjectCopy = cloneDeep(timeArrayObject);   
        let subObject = GetDetailedSubArray(i, timeArrayObjectCopy, dayObject);  
        timeArray.push(subObject);
        getDetailedViewCompleteObject(eachHour, timeArray, start, end);
    }    
    return eachHour;
}

export const getDetailedViewCompleteObject = (eachHour: any, timeArray: any, start: number, end: number) => {
    let timeForOneHour = "";
    for (const element of timeArray) {
        timeForOneHour = start + '-' + end;
        eachHour[timeForOneHour] = element;
        start = end;
        end = end + TIME_IN_1_HOUR;
    }
    return eachHour;
}

export const getDayArrayObject = () => {
    const dayArray = new Object({ Sunday : 0.00, Monday : 0.00, Tuesday : 0.00, Wednesday : 0.00, Thursday : 0.00, Friday : 0.00, Saturday : 0.00})
    return dayArray;
}

export const GetDetailedSubArray = (index: number, timeArrayObject: any, dayObject: any) => {
    let timeSubArray: any = [];
    for(let j=0; j<4; j++) {  
        let lastIndex = j===3;
        let timeAddition = (Number((times[index-1].label).split(":")[0]) + 1).toString();
        timeAddition = (timeAddition === "13") ? "01" : timeAddition
        let timeFirstPart = lastIndex ? ((timeAddition.length === 1) ? (0 + timeAddition) : timeAddition) : (times[index-1].label).split(":")[0];
        let timeSecondPartTimeMeridian = ((times[index-1].label).split(":")[1]).substring(2,4);
        let timeSecondPartTimeMeridianLastIndex = ((times[index].label).split(":")[1]).substring(2,4);
        let hourAddition = (Number((hours[index-1].label).split(":")[0]) + 1).toString();
        let durFirstPart = lastIndex ? ((hourAddition.length === 1) ? (0 + hourAddition) : hourAddition) : (hours[index-1].label).split(":")[0];
       
        let toTimeFirstPart = lastIndex ? (Number(timeFirstPart) - 1).toString() : timeFirstPart;
        toTimeFirstPart  = toTimeFirstPart === "0" ? "12" : toTimeFirstPart;
        toTimeFirstPart = (toTimeFirstPart.length === 1) ? (0 + toTimeFirstPart) : toTimeFirstPart;
        let toDurFirstPart = lastIndex ? (Number(durFirstPart) - 1).toString() : durFirstPart;
        toDurFirstPart = (toDurFirstPart.length === 1) ? (0 + toDurFirstPart) : toDurFirstPart;

        let timeArray = {
            from: toTimeFirstPart + ":" + getFullHourDetails(true, j, timeSecondPartTimeMeridian),
            to: timeFirstPart + ":" + getFullHourDetails(false, j, (lastIndex && index === 24 ? timeSecondPartTimeMeridianLastIndex : timeSecondPartTimeMeridian)),
            fromDur: toDurFirstPart + ":" + getFullHourDetails(true, j),
            toDur: durFirstPart + ":" + getFullHourDetails(false, j),
            day: new Object(dayObject)
        }
        let timeArrayCopy = cloneDeep (timeArray)

        timeSubArray.push(timeArrayCopy)  
        timeArrayObject["sub"] =  timeSubArray;    
    }    
    return  timeArrayObject;   
}

export const getFullHourDetails = (fromHour: any, index: any, timeMeridian: any = "") => {
    switch (index) {
        case 0: if (fromHour) {
            return  0 + (0 + timeMeridian);
        }
        else {
            return DETAILED_VIEW_15_MIN + timeMeridian;
        }
        case 1: if (fromHour) {
            return DETAILED_VIEW_15_MIN + timeMeridian;
        }
        else {
            return DETAILED_VIEW_30_MIN + timeMeridian;
        }
        case 2: if (fromHour) {
            return DETAILED_VIEW_30_MIN + timeMeridian ;
        }
        else {
            return DETAILED_VIEW_45_MIN + timeMeridian;
        }
        case 3: if (fromHour) {
            return DETAILED_VIEW_45_MIN + timeMeridian;
        }
        else {
            return 0 + (0 + timeMeridian);
        }
    }
}

export const getClassNameForDayName = (index: number) => {
    switch (index) {
        case 0:
            return "";
        case 1:
            return "ml-20";
        case 2:
            return "ml-40";
        case 3:
            return "ml-60";
        case 4:
            return "ml-80";
        case 5:
            return "ml-100";
        case 6:
            return "ml-120";
        default:
            return "";
    }
}

export const getClassNameForBorderBottom = (index: number) => {
    switch (index) {
        case 0:
            return "mr_dayindex-one";
        case 1:
            return "mr_dayindex-two";
        case 2:
            return "mr_dayindex-three";
        case 3:
            return "mr_dayindex-four";
        case 4:
            return "mr_dayindex-five";
        case 5:
            return "mr_dayindex-six";
        case 6:
            return "mr_dayindex-seven";
        default:
            return "";
    }
}

export const getClassNameForInput = (index: number) => {
    return (index === 0) ? "":"ml-20";
}

export const daysExpanded = [{ value: 0, label: 'Sunday' }, { value: 1, label: 'Monday' },
{ value: 2, label: 'Tuesday' }, { value: 3, label: 'Wednesday' },
{ value: 4, label: 'Thursday' }, { value: 5, label: 'Friday' },
{ value: 6, label: 'Saturday' }];

export const getDayVal = (index: any) => {
    switch (index) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "";
    }
}
export const createDetailedViewArrayView = (pricingPlanData: any, timeArrayDetail: any) => {
    if (pricingPlanData?.length > 0) {
        let dayArray = pricingPlanData[0].day;
        for (let j = 0; j < dayArray.length; j++) {
            for (let i = dayArray[j].start; i <= dayArray[j].end; i++) {
                let dayValue = getDayVal(i);
                let start = 0;
                let end = TIME_IN_1_HOUR;
                for (let k = 0; k < dayArray[j].time.length; k++) {
                    let hoursDiff = Math.round((dayArray[j].time[k].end - dayArray[j].time[k].start) / TIME_IN_1_HOUR);
                    let cost = Number(dayArray[j].time[k].cost);
                    for (let l = 1; l <= hoursDiff; l++) {
                        timeArrayDetail[start + '-' + end]['day'][dayValue] = cost.toFixed(2);
                        for (let sub = 0; sub < timeArrayDetail[start + '-' + end]['sub'].length; sub++) {
                            timeArrayDetail[start + '-' + end]['sub'][sub]['day'][dayValue] = (cost / 4).toFixed(2);
                        }
                        start = end;
                        end = end + TIME_IN_1_HOUR;
                    }
                }
            }
        }
    }
    return timeArrayDetail;
}
export const createDetailedViewArrayEdit = (pricingPlanData: any, timeArrayDetail: any) => {
    if (pricingPlanData?.length > 0) {
        let dayArray = pricingPlanData[0].day;
        for (let i = 0; i < dayArray.length; i++) {
            let days = dayArray[i].days;
            for (let j = days[0]; j <= days[days.length-1]; j++) {
                let dayValue = getDayVal(j);
                let start = 0;
                let end = TIME_IN_1_HOUR;
                for (let k = 0; k < dayArray[i].time.length; k++) {
                    let hoursDiff = Math.round((dayArray[i].time[k].end_t - dayArray[i].time[k].start_t) / TIME_IN_1_HOUR);
                    let cost = dayArray[i].time[0].rate;
                    for (let l = 1; l <= hoursDiff; l++) {
                        let rateValue = Number(cost[start]) + Number(cost[start + TIME_IN_15_MIN]) + Number(cost[start + TIME_IN_30_MIN]) + Number(cost[start + TIME_IN_45_MIN]);
                        timeArrayDetail[start + '-' + end]['day'][dayValue] = (rateValue).toFixed(2);
                        for (let sub = 0; sub < timeArrayDetail[start + '-' + end]['sub'].length; sub++) {
                            let rate = start + (sub * TIME_IN_15_MIN);
                            timeArrayDetail[start + '-' + end]['sub'][sub]['day'][dayValue] = cost[rate];
                        }
                        start = end;
                        end = end + TIME_IN_1_HOUR;
                    }
                }
            }
        }
    }
    return timeArrayDetail;
}
export const addDetailedViewArray = (dayIndex: number, timeArrayDetailedKeys: any, timeArrayDetail: any, pricingPlanData: any) => {
    let newPricingDetails = [...pricingPlanData];
    let dayName = "";
    let timeArray: any = [];
    newPricingDetails[0]['day'].forEach((eachDay: any, index: any) => {
        for (let i = eachDay.start; i <= eachDay.end; i++) {
            if (dayIndex === index && i === eachDay.start) {
                dayName = daysExpanded[i].label;
                let cost = 0;
                let start = 0;
                let end = 0;                
                timeArrayDetailedKeys.forEach((timeArr: any, timeIndex: number) => {
                    if (timeIndex === 0) {
                        cost = timeArrayDetail[timeArr].day[dayName];
                        start = timeArr.split('-')[0];
                        end = timeArr.split('-')[1];
                    }
                    else {
                        if (timeArrayDetail[timeArr].day[dayName] === cost) {
                            end = timeArr.split('-')[1];
                        }
                        else {
                            let timeObject = { start: start, end: end, cost: cost };
                            timeArray.push(timeObject);
                            start = end;
                            end = timeArr.split('-')[1];
                            cost = timeArrayDetail[timeArr].day[dayName];
                        }
                        if (timeIndex === timeArrayDetailedKeys.length - 1) {
                            let timeObject = { start: start, end: TIME_END, cost: cost };
                            timeArray.push(timeObject);
                        }
                    }
                });                
            }
        }
    });
    addDetailedPricingControl(timeArray, dayIndex, pricingPlanData);
}
export const addDetailedPricingControl = (timeArray: any, dayIndex: number, pricingPlanData: any) => {
    let newPricingDetails = [...pricingPlanData];
    let controlTime: any = [];
    newPricingDetails[0]['day'].forEach((eachDay: any, index: any) => {
        for (let i = eachDay.start; i <= eachDay.end; i++) {
            if (dayIndex === index) {
                controlTime = eachDay['time'];
                for (let j = 0; j < timeArray.length; j++) {
                    let pricing_data = timeArray[j];
                    if (typeof controlTime[j] === 'undefined') {
                        controlTime.push(initTimes(Number(pricing_data.start), Number(pricing_data.end), Number(pricing_data.cost)));
                    } else {
                        controlTime[j].start = (Number(pricing_data.start));
                        controlTime[j].end = (Number(pricing_data.end));
                        controlTime[j].cost = (Number(pricing_data.cost).toFixed(2));
                    }
                }
            }
        }
    });
}
export const createDetailedViewObject = (pricingPlanData: any, timeArrayDetail: any, detailedView: boolean = false) => {
    let newPricingDetails = [...pricingPlanData];
    let costArray : any = [];
    if(detailedView) {
        costArray = createDetailedViewObjectChange(timeArrayDetail, pricingPlanData);        
    }
    else {
        costArray = prepareTimeArray(newPricingDetails[0]['day'], PARKING_FEE);
    }
    let day: any = [];
    let daysObject = {days: "",time: ""};
    let dayArrayobject: any = [];
    let dayConsolidatedObject = {day : "", month: ""}
    let monthArray: any = [];
    for(let j=newPricingDetails[0].start; j<=newPricingDetails[0].end; j++){
        monthArray.push(j);
    }
    dayConsolidatedObject["month"] = monthArray;
    newPricingDetails[0]['day'].forEach((eachDay: any) => {        
        let daysVal: any = [];    
        let timeObject : any = [];    
        for (let i = eachDay.start; i <= eachDay.end; i++) {
            daysVal.push(i);                       
        }      
        let timeArray = {
            start_t : 0,
            end_t : TIME_END,
            rate : ""
        }            
        timeArray["rate"] = costArray[eachDay.start]
        timeObject.push(timeArray);   
        daysObject["days"] = daysVal;      
        daysObject["time"] = timeObject;          
        let daysObjectCopy = cloneDeep(daysObject);
        dayArrayobject.push(daysObjectCopy);        
    });        
    dayConsolidatedObject["day"] = dayArrayobject;
    day.push(dayConsolidatedObject);
    return day;
}
export const createDetailedViewObjectChange = (timeArrayDetail: any, pricingPlanData: any) => {
    let newPricingDetails = [...pricingPlanData];
    let costArray: any = [];
    let eachTime: any = {};
    let eachTimeCopy: any = {};
    newPricingDetails[0]['day'].forEach((eachDay: any) => {
        for (let j = eachDay.start; j <= eachDay.end; j++) {
            let dayValue = getDayVal(j);
            let start = 0;
            let end = TIME_IN_1_HOUR;
            for (let k = 0; k < eachDay.time.length; k++) {
                let hoursDiff = Math.round((eachDay.time[k].end - eachDay.time[k].start) / TIME_IN_1_HOUR);
                for (let l = 1; l <= hoursDiff; l++) {
                    for (let sub = 0; sub < timeArrayDetail[start + '-' + end]['sub'].length; sub++) {
                        let costValue = timeArrayDetail[start + '-' + end]['sub'][sub]['day'][dayValue];
                        eachTime = getTimeValueFOrDetailed(start, sub, costValue, eachTime);
                    }
                    start = end;
                    end = end + TIME_IN_1_HOUR;
                }
            }
            eachTimeCopy = cloneDeep(eachTime);
            costArray.push(eachTimeCopy);
        }        
    });
    return costArray;
}
export const getTimeValueFOrDetailed = (start: any, index: any, costValue: any, eachTime: any) => {
        start = start + (index * TIME_IN_15_MIN);
        eachTime[start] = costValue;
        return eachTime;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

export const checkZonePermission = (data:any ,permission_type:any, zone:any) => {
    let result = false;
    data.forEach((record: any) => {
        if (record?.zoneDTO?.id === zone) {
            result = checkPermission(record?.permissions, permission_type);
        }
    });
  
return result;
};

export const NAME = "Name";
export const TYPE = "Type"