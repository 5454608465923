import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { DataGrid } from "@material-ui/data-grid";
import { getMethod, ApiEndPoint } from "services";
import { BLANK_DATA, ColumnNameTranslate, GetUrlDetails, HandlePaginationLabel, pageDetails } from "common";
import CommonPagination from "components/commonPagination";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { manageUserlistColumns } from "pages/tableColumns";
import CommonDownloadCsv from "components/commonDownloadCsv";
import uuid from 'uuid-random';
import {
  InfoList,
  ManageUserInfoList,
  SetListData,
  UserEditInfoList,
} from "common/interfaces/manageuser";
import moment from 'moment';
import { lowerCase, upperCase } from "lodash";
import { CircularProgress } from "@material-ui/core";

const userColumns = manageUserlistColumns;

const UserInfoList = (props: UserEditInfoList) => {
  const { t } = useTranslation();
  const history = useHistory();
  let userGroupId = GetUrlDetails(history,'userGroup');
  const pageSize = pageDetails.pageSizeHundred;
  const [currentPage, setCurrentPage] = useState(0);
  const [manageUserInfo, setManageUserInfo] = useState<any>();
  const [manageUserInfoCsv, setManageUserInfoCsv] = useState<any>();
  const [loadingPage, setLoadingPage]  = useState(false);

  const getGroupDetailsList = async () => {
    setLoadingPage(true);
    await getMethod(ApiEndPoint.groupDetails, userGroupId['userGroup'] + "/users?page=" + currentPage + '&size=' + pageSize)
      .then((res) => {
        setManageUserInfo(res.data);
      })
      .catch((err) => {
        console.error("Error", err);
      });
      setLoadingPage(false);
  };

  const getGroupDetailsAllRecord = async () => {
    await getMethod(ApiEndPoint.groupDetails, userGroupId['userGroup'] + "/users?page=0&size=" + (manageUserInfo?.totalUsers + manageUserInfo?.totalRFIDs))
      .then((res) => {
        setManageUserInfoCsv(res.data);
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };


  const fileHeader: any[] = [
    { label: upperCase(t('common.name')), key: t('userlistcsv.userName') },
    { label: upperCase(t('common.email')), key: lowerCase(t('common.email')) },
    { label: upperCase(t('common.blinkcard')), key: t('userlistcsv.rfids') },
  ];
  
  const fileName: string = `${manageUserInfoCsv?.userGroupName}_${moment().format('MMDDYYYYHHmmss')}.csv`;

  const DownloadCsvManageUserInfoListDataSet = () => {
    let chargeHistoryTableData: any[] = [];
    if (manageUserInfoCsv && manageUserInfoCsv.users) {
      chargeHistoryTableData = manageUserInfoCsv.users.map(
        (val: any) => ({
          ...val,
          id: val.id !== null ? val.id : uuid(),
          userName: val.firstName !== null ? val.firstName + " " + val.lastName : BLANK_DATA
        })
      );
    }
    return chargeHistoryTableData;
  };

  const manageUserListDataSet = (value?: ManageUserInfoList) => {
    let historyTableData: InfoList[] = [];
    if (value && value.users) {
      historyTableData = value.users.map((val: InfoList) => ({
        ...val,
        id: val.id !== null ? val.id : uuid(),
        userName: val.firstName !== null ? val.firstName + " " + val.lastName : BLANK_DATA,
        rfids: val.rfids !== null ? val.rfids.toString().replaceAll(',', ', ') : BLANK_DATA
      }));
    }
    return historyTableData;
  };

  const getManageUserInfoData = (rowData: SetListData) => {
    props.manageUserEvent(rowData?.row);
  };

  const handleCurrentPage = (setPage: number) => {
    setCurrentPage(setPage);
  };


  useEffect(() => {
    setManageUserInfo([])
    getGroupDetailsList();
  }, [props.refreshList, props.refreshUpload, currentPage])

  return (
    <>
      {
      loadingPage ? <CircularProgress className="inner_progress_userGroup"/> : 
      <div className="table_scroll">
        <div className="default_table">
          <DataGrid
            autoHeight
            vertical-align="top"
            className="table"
            rowHeight={59}
            headerHeight={30}
            rows={manageUserListDataSet(manageUserInfo)}
            columns={ColumnNameTranslate(userColumns)}
            pageSize={pageSize}
            onRowClick={(rowdata: any) => getManageUserInfoData(rowdata)}
          />
        </div>
        <div className="table_footer">
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid className="text_left" item xs={8}>
              {manageUserInfo && manageUserInfo.totalRecords > pageSize ? (
                <CommonPagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalCount={manageUserInfo?.totalRecords}
                  callBackCurrentPage={handleCurrentPage}
                />
              ) : (
                <></>
              )}
              {manageUserInfo && manageUserInfo.totalUsers ? (
              <CommonDownloadCsv
                {...props}
                datas={DownloadCsvManageUserInfoListDataSet()}
                headers={fileHeader}
                filename={fileName}
                callBackDownloadCsv={getGroupDetailsAllRecord}
              />
              ) : (
                <></>
              )}
            </Grid>
            <Grid className="text_right" item xs={4}>
              <div className="list_number">
                {HandlePaginationLabel(manageUserInfo?.totalRecords, pageSize, currentPage, 'common.row' )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      }
    </>
  );
};
export default UserInfoList;
