import { getFormattedDateTimeWithTZ } from "common";
import React from "react";

export const FleetGroupsColumns = [

    {
        field: "div_name",
        headerName: 'fleet.location',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "zone_name",
        headerName: 'fleet.zone',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "group",
        headerName: 'fleet.fleetName',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "delegate_count",
        headerName: 'fleet.noOfDelegate',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "vehicles_count",
        headerName: 'fleet.noOfVehicles',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "station_count",
        headerName: 'fleet.noOfStations',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return <div className="cellValue">{cellValues.value}</div>;
        },
    },
    {
        field: "created_on",
        headerName: 'fleet.creationDate',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return (<div>{getFormattedDateTimeWithTZ(cellValues.value)}</div>
            );
        }
    },
    {
        field: "modified_on",
        headerName: 'fleet.updationDate',
        headerClassName: "action_columns",
        cellClassName:"cellValue",
        sortable: true,
        minWidth: 100,
        flex: 0.4,
        disableColumnMenu: true,
        renderCellExpand: (cellValues: any) => {
            return (<div>{getFormattedDateTimeWithTZ(cellValues.value)}</div>
            );
        }
    },
   
]
