import React from "react";
import DragDropImagesMultiple from "./dragDropImagesMultiple";
import DragDropImagesSingle from "./dragDropImagesSingle";

const DragDropImages = (props: any) => {
  return (
    <>
    {props.imagetype ?
        <DragDropImagesMultiple fileMessage={props?.fileMessage} selectedImagesDetails={props.selectedImagesDetails}/> :

        <DragDropImagesSingle receivedImagePath={props.receivedImagePath} fileMessage={props?.fileMessage} fileSize={props?.fileSize} selectedImagesDetails={props.selectedImagesDetails}/>
        }
    </>
  );
};

export default DragDropImages;
