import { Button, Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { setClassName } from "pages/evseLocations/evseDepotDetail/semaConnectChargeFee/pricingPlanHelpers";
import React from "react";
import { useTranslation } from "react-i18next";
import AlertDetails from "../alertDetails";

const UpdateFleetAlert = (props: any) => {

    const { t } = useTranslation();

    return (
        <div className="dialog_box">
            <Dialog
                className="dialog_container zone_settings_dialog"
                open={props.openAlert}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{t("fleet.notifications")}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme" onClick={props.closeAlert}>{t("common.cancelbutton")}</Button>
                        <Button className={`btn_white ${setClassName(false, false)}`} onClick={props.saveAlert}>{t("common.savechanges")}</Button>
                    </div>
                </div>
                {props.spinnerOff && (<LinearProgress />)}
                <div className="alert_dialog">
                    <DialogContent>
                        <AlertDetails
                            editStatus={props.editStatus}
                            alertInfoEdit={props.alertInfoEdit}
                            showData={props.showData}
                            setAlertStatus={props.setAlertStatus}
                            dialogContent={true}
                        />
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    )

}
export default UpdateFleetAlert;