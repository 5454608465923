import React, {useState, useEffect} from "react";
import {List, ListItem, Grid, Card, CardContent, CircularProgress, IconButton, Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {BLANK_DATA_PLACEHOLDER, CheckAccess, claims, HandlePaginationLabel, pageDetails, GetUrlDetails} from "common";
import CommonPagination from "components/commonPagination";
import Images from "assets/images";
import {DataGrid} from "@material-ui/data-grid";
import {semaConnectChargeFeeColumns} from "pages/tableColumns";
import "./semaConnectChargeFee.scss";
import {fetchLocationAccess, fetchPricingList, fetchTariffForDropDown} from "services/pricingPlanService";
import { functionSetPaginateListArray, handleAddorEditPopupTitle,  checkPermission
} from "./pricingPlanHelpers";
import AddPricingPlan from "./addPricingPlan";
import ApplyPricing from "./applyPricing";
import DeletePricingPlan from "./deletePricingPlan";
import { toUpper} from 'lodash';

export const SemaConnectChargeFee = (props: any) => {
  const history = useHistory();
  const {locationId, organizationName, loactionLevelPermission} = props;
  const [pricingList, setPricingList] = useState<any>({});
  const {t} = useTranslation();
  const [paginateList, setPaginateList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pagingSize = pageDetails.pageSize;
  const columnValue = semaConnectChargeFeeColumns();
  const [loadingPage, setLoadingPage] = useState(false);
  const [stationDetailsExpand, setStationDetailsExpand] = useState(false);
  const [stationDetails, setStationDetails] = useState<any>();
  const [applyPricingOpen, setApplyPricingOpen] = useState(false);
  const [addPricingOpen, setAddPricingOpen] = useState(false);
  const [pricingPlanId, setPricingplanId] = useState("");
  const [editPopup, setEditPopup] = useState(false);
  const [firstPopupName, setFirstPopupname] = useState("");
  const [secondPopupName, setSecondPopupname] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [deletePricingopen, setDeletePricingOpen] = useState(false);
  const [ctepEnabled, setCtepEnabled] = useState(false);
  const [reload, setReload] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [unAssignPricingPlanList, setUnAssignPricingPlanList] = useState<any>({});
  const [hostId, setHostId] = useState("");
  const [accessPricingPlan, setAccessPricingPlan] = useState<any>(null);
  const pricingUpdateAccess = loactionLevelPermission ? checkPermission(loactionLevelPermission, "USAGE_RATE") : CheckAccess([claims.PRICING_UPDATE]);
  const pricingCreateAccess = loactionLevelPermission ? checkPermission(loactionLevelPermission, "USAGE_RATE") : CheckAccess([claims.PRICING_CREATE]);
  const pricingDeteteAccess = loactionLevelPermission ? checkPermission(loactionLevelPermission, "USAGE_RATE") : CheckAccess([claims.PRICING_REMOVE]);

  useEffect(() => {
    getPricingPlanData();
  }, [reload]);

  useEffect(() => {
    let addFirstName = t("pricing.pricingplanfirstpopup");
    let addSecondname = t("pricing.pricingplansecondpopup");
    let editFirstName = t("pricing.pricingplanfirstpopupEdit");
    let editSecondname = t("pricing.pricingplansecondpopupEdit");
    handleAddorEditPopupTitle(
      editPopup,
      setFirstPopupname,
      setSecondPopupname,
      addFirstName,
      addSecondname,
      editFirstName,
      editSecondname
    );
  }, [editPopup]);

    

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage);
  };
  const handleDrawerOpenClose = (val: any, stationDetailsExpandVal: boolean) => {
    setStationDetailsExpand(stationDetailsExpandVal);
    setStationDetails(val);
  };

  const addNewPricing = () => {
    setApplyPricingOpen(true);
  };
  const applyPricingClose = (parentReload: boolean = false) => {
    setApplyPricingOpen(false);
    if (parentReload) {
      setReload(true);
    } else {
      setReload(false);
    }
  };

  const getPricingPlanData = () => {
        setLoadingPage(true);
        let params = { locationId: locationId };
        fetchPricingList(params)
            .then((res) => {
                setHostId(res?.host_id);
                getAppliedPricingAndAccess();
                getUnAssignedPricingPlan(res?.host_id);
                setPaginateListArray(res?.pricing_plan);
                setPricingList(res?.pricing_plan);
                setNoDataFound(res?.pricing_plan.length === 0);
                setCtepEnabled(res?.cteb_enabled);
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

  const getUnAssignedPricingPlan = (hostId: string = "", parentReload: boolean = false) => {
        let params = {"hostId" : hostId, "is_mapped":false};
        if(parentReload) {
            setLoadingPage(true);
        }
        fetchTariffForDropDown(params).then((res: any) => {
            if (res) {
                setUnAssignPricingPlanList(res);
                setLoadingPage(false);
            }
        })
    };

  const getAppliedPricingAndAccess = () => {
        let params = {"locationId" : locationId};
        fetchLocationAccess(params).then((res: any) => {
            if (res) {
                setAccessPricingPlan(res);
            }
            setLoadingPage(false);
        })
    };

  const setPaginateListArray = (pricingPlanList: any) => {
    let pageList = functionSetPaginateListArray(pricingPlanList, pagingSize);
    setPaginateList(pageList);
  };

  const navigateToDepotStationDetails = (stationId: any) => {
    let {locationId} = GetUrlDetails(history, "/evseLocations/evseDepotDetail");
    let searchParams = "?locationId=" + locationId + "&stationId=" + stationId;
    history.push({
      pathname: "/evseLocations/depotStationDetails",
      search: searchParams,
      hash: "#0",
    });
  };

  const handleClickOpen = (popupType: string) => {
    if (popupType === "Edit") {
      setEditPopup(true);
    } else {
      setEditPopup(false);
    }
    setAddPricingOpen(true);
    setOpenDialog(true);
  };

  const handleDeletePopupOpen = (event: any) => {
    setDeletePricingOpen(true);
  };

  const deletePricingplanClose = (parentReload: any) => {
    setDeletePricingOpen(false);
    if (parentReload) {
      getPricingPlanData();
    }
  };

  const addPricingPlanClose = (parentReload: boolean = false, savedClose: boolean = false) => {
    setAddPricingOpen(false);
    setEditPopup(false);
    setOpenDialog(false);
    if (savedClose && parentReload) {
      getPricingPlanData();
    }
  };

  const openEditPricingPopup = (planId: any) => {
    setPricingplanId(planId);
    handleClickOpen("Edit");
  };

  const accessInfo = (key: string, value: string | boolean) => {
        return (
            <>
                <div className="row">
                    <span className="label">{key}</span>
                    <span className="result">{value || BLANK_DATA_PLACEHOLDER}</span>
                </div>
            </>
        );
    };

  return (
    <>
      {/*----Page Mid Section Start----*/}

      {loadingPage ? (
        <CircularProgress className="inner_progress" />
      ) : (
        <div className="pricing_section">
          <div className="mid_section_inside ">
            <Grid container spacing={2} md={12}>
              {/*----Page Mid Left Section Start----*/}
              <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
                {noDataFound && (
                  <>
                    <Card variant="outlined" className="basic_card">
                      <CardContent>
                        <div className="title_row"/>
                        <h5 className="mt-10 msg_text ">{t("pricing.nopricingplanconfigured")}</h5>
                      </CardContent>
                    </Card>
                  </>
                )}

                {/*----Pricing Information plan List Section Start----*/}
                <div className="table_scroll">
                  <div className="pricing_list">
                    <List>
                      {pricingList && pricingList.length > 0 && paginateList &&
                        paginateList[currentPage] && paginateList[currentPage].map((value: any) => (
                          <ListItem key={value.id}>
                            <Card className="list_card" variant="outlined">
                              <CardContent className="card_content">
                                <div className="pricing_details">
                                  <Grid container justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      {/*----Pricing Information plan----*/}
                                      <div className="wrapper_main">
                                        <Grid item xs={11} sm={11} md={11} lg={11}>
                                          <div className="pricing_heading">{value.name}
                                            <div className="label">{t("pricing.pricingtype")}
                                              <span className="result">{value.type}</span>
                                            </div>
                                            <div className="pricing_staion" key={value.id}>{t("pricing.noofstations")}
                                              {value.charger.length}
                                              {value.charger.length > 0 &&
                                                <>
                                                  {stationDetailsExpand && stationDetails === value.id ? (
                                                    <IconButton
                                                      className="top_action_btn"
                                                      onClick={() => handleDrawerOpenClose(value.id, false)}>
                                                      <img src={Images.ic_expand_less} alt="expand less" />
                                                    </IconButton>
                                                  ) : (
                                                    <IconButton
                                                      className="top_action_btn" data-testid="ic_expandmore_icon"
                                                      onClick={() => handleDrawerOpenClose(value.id, true)}>
                                                      <img src={Images.ic_expend} alt="expand more" />
                                                    </IconButton>
                                                  )}
                                                </>
                                              }
                                            </div>
                                          </div>
                                          <div className="pricing_second_heading">
                                            <div className="label">{t("pricing.parkingfee")}
                                              <span className="result">{value.parking_summary}</span>
                                            </div>
                                            <div className="label">{t("pricing.energyfee")}
                                              <span className="result">{value.charging_summary}</span>
                                            </div>
                                          </div>
                                          {/*----No. of Station data table Start ----*/}
                                          {stationDetailsExpand && value.charger.length > 0 && stationDetails === value.id ? (
                                            <div className="detail_page_tab">
                                              <div className="table_scroll mt-25 mb-20">
                                                <div className="default_table">
                                                  <DataGrid
                                                    autoHeight
                                                    className="table"
                                                    rowHeight={59}
                                                    headerHeight={30}
                                                    rows={value.charger}
                                                    columns={columnValue}
                                                    loading={loadingPage}
                                                    onRowClick={(e) => navigateToDepotStationDetails(e?.row?.id)}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {/*----No. of Station data table End ----*/}
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} lg={1}>
                                          {pricingUpdateAccess && (
                                            <div className="pricing_edit">
                                              <div className="action_icon">
                                                <IconButton
                                                  className="top_action_btn"
                                                  onClick={() => openEditPricingPopup(value.id)}
                                                >
                                                  <img src={Images.ic_edit} alt="edit" />
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </Grid>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardContent>
                            </Card>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                  <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                      <Grid className="text_left" item xs={8}>
                        {pricingList && pricingList?.length > pagingSize && (
                          <CommonPagination
                            currentPage={currentPage}
                            totalCount={pricingList?.length}
                            callBackCurrentPage={handleCurrentPage}
                            pageSize={pagingSize}
                          />
                        )}
                      </Grid>
                      <Grid className="text_right" item xs={4}>
                        <div className="list_number">
                          {HandlePaginationLabel(pricingList?.length, pagingSize, currentPage, "pricing.pricingplan")}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {/*----Pricing Information plan List Section End----*/}
              </Grid>

              {/*----Page Mid Left Section End----*/}

              {/*----Page Mid Right Section Start----*/}
              <Grid className="mid_section_right" item xs={12} sm={12} md={4}>
                {/*---Open Access Station Card start---*/}
                  <>
                      <Card variant="outlined" className="basic_card">
                          <CardContent>
                              {/*----Title Start----*/}
                              <div className="title_row wrapper_main">
                                  <span className="access-heading">{toUpper(t("pricing.accesspricingplan"))}</span>
                                  <div className="clearfix"/>
                              </div>
                              {/*----Title End----*/}
                              {/*----Edit Start----*/}
                              <div className="location_access">
                                  <div className="mt-16">
                                      {accessPricingPlan?.stations?.access_type && accessInfo(t("pricing.access"), accessPricingPlan?.stations?.access_type)}
                                      {accessPricingPlan?.memberPricing && accessInfo(t("pricing.privateaccessplan"), accessPricingPlan?.memberPricing?.name)}
                                      {accessPricingPlan?.publicPricing && accessInfo(t("pricing.publicplan"), accessPricingPlan?.publicPricing?.name)}
                                  </div>
                                  {pricingUpdateAccess &&
                                  <div className="button_row">
                                      <Button className="btn_white light_gry ml_0" onClick={addNewPricing}>
                                          {t("pricing.changeaccesspricing")}
                                      </Button>
                                  </div>
                                  }
                              </div>
                          </CardContent>
                      </Card>
                  </>
                {/*---Open Access Station Card End---*/}
                {pricingCreateAccess && (
                  <div className="button_row mt-18">
                    <Button className="soc_level btn_white blue_bg ml_0" onClick={() => handleClickOpen("Add")}>
                      <span className="MuiButton-label">{t("pricing.createnewplan")}</span>
                    </Button>
                  </div>
                )}
                {unAssignPricingPlanList?.length > 0 && pricingDeteteAccess && (
                  <div className="button_row_white mt-18">
                    <Button onClick={(deleteEvent) => handleDeletePopupOpen(deleteEvent)} className="btn_white text_btn_theme ml_0">
                      {t("pricing.deleteplan")}
                    </Button>
                  </div>
                )}
              </Grid>
              {/*----Page Mid Right Section End----*/}
            </Grid>
          </div>
        </div>
      )}
      {/*----Page Mid Section End----*/}

      {/*----Apply Pricing Start ----*/}

      <ApplyPricing
          hostId={hostId}
          locationId={locationId}
          organizationName={organizationName}
          applyPricingOpen={applyPricingOpen}
          applyPricingClose={applyPricingClose}
          appliedPricingDetails={accessPricingPlan}
          tariffForDropDown={pricingList}
          setApplyPricingOpen={setApplyPricingOpen}
      />
      {/*----Apply Pricing End ----*/}

      {/*----Add or Edit Pricing Plan Start ----*/}
      {openDialog && (
        <AddPricingPlan
          addPricingOpen={addPricingOpen}
          setAddPricingOpen={setAddPricingOpen}
          addPricingPlanClose={addPricingPlanClose}
          orgId={hostId}
          pricingPlanId={pricingPlanId}
          firstPopupName={firstPopupName}
          secondPopupName={secondPopupName}
          editPopup={editPopup}
          ctepEnabled={ctepEnabled}
        />
      )}
      {/*----Add or Edit Pricing Plan End ----*/}

      {/*----Delete Pricing Plan Start ----*/}

      {deletePricingopen && (
        <DeletePricingPlan
          deletePricingOpen={deletePricingopen}
          setDeletePricingOpen={setDeletePricingOpen}
          deletePricingplanClose={deletePricingplanClose}
          orgId={hostId}
          unAssignPricingPlanList={unAssignPricingPlanList}
        />
      )}

      {/*----Delete Pricing Plan End ----*/}
    </>
  );
};

export default SemaConnectChargeFee;
