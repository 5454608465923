import React, {useEffect, useRef, useState} from 'react';
import {
    Grid,
    OutlinedInput,
    InputAdornment,
    Button,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import { getMethod, putMethod, ApiEndPoint } from 'services';
import SetChargeFee from './setChargeFee';
import {defaultVariable, GetUrlDetails, COUNTRY_CODE} from 'common';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser'
import { useDispatch } from "react-redux";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useChargeFee } from 'hooks';
import SimpleReactValidator from "simple-react-validator";
import {validateError} from "../../../../common/methodHelpers";

const ChargeFee = (props: any) => {
    const countryCode = props?.countryCode ? props?.countryCode : '';

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        intialValues,
        defaultCurrSymbol,
        templateDataFormatFiltered,
        findMemberLevelFees,
        handleMemberLevelFees,
        handleChange,
        handleDecmalValueChange,
        getCountryList,
        getCurrencyList,
        getPricingMetadata,
        priceMetadata,
        countryName,
        currencyList,
        countryList
    } = useChargeFee(countryCode);

    let { locationId } = GetUrlDetails(history, '/evseLocations/evseDepotDetail');

    const SESSION_TYPE = 'session';
    const USAGE_TYPE = 'usage';
    const OCCUPANCY_TYPE = 'occupancy';
    const STANDARD_TYPE = 'Standard';
    const TIME_TYPE = 'Time';
    const GUEST_TYPE = 'Guest';
    const FLEET_TYPE = 'Custom';
    const MEMBER_TYPE = 'Member';
    const EMPLOYEE_TYPE = 'Employee';
    const PARTNER_TYPE = 'Network Partners';

    const evsePortLevelDetails = priceMetadata?.portLevels ? priceMetadata?.portLevels : [];
    const chargeRateSetting = priceMetadata?.pricingSpecs ? priceMetadata?.pricingSpecs : [];
    const unitsType = priceMetadata?.billingUnits ? priceMetadata?.billingUnits : [];
    
    const [tabValue, settabValue] = React.useState(0);
    const [rateSetting, setRateSetting] = React.useState<any>('');
    const [statusCode, setStatusCode] = React.useState(false);
    const [messageShown, setMessageShown] = React.useState<any>('');
    
    const portLevelId = evsePortLevelDetails[tabValue]?.id;
    const [loadingPage, setLoadingPage] = useState(false);
    const canUpdatePricing = props?.updatePrice;
    const setFormDisabled = !canUpdatePricing && 'disable';
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState<any>();

    useEffect(()=>{
        setLoadingPage(true);
        setTimeout(() =>{
        setLoadingPage(false)
       },1000)
    },[])

    useEffect(() => {
        getPricingMetadata()
        getCountryList()
    }, [])

    useEffect(() => {
        if (portLevelId) {
            getAppliedPriceData();
        }
    }, [portLevelId, tabValue])

    useEffect(() => {
        getCurrencyList()
    }, [countryList, countryCode, intialValues?.sessionCurrency])
    

    const handleChargetab = (event: any) => {
        settabValue(event?.target?.value);
    };

    const handleRateSetting = (event: any) => {
        setRateSetting(event?.target?.value);
        if (event?.target?.value === 0) {
            getAppliedPriceData();
        } else {
            getChargeFeeData(event.target.value);
        }
    };

    const getChargeFeeData = async (specId: any) => {
        if(specId){
            const url = ApiEndPoint.locationTemplatePricing.replace('{depotId}', portLevelId).replace('{specId}', specId)
            await getMethod(url, '').then((res) => {
                templateDataFormatFiltered(res)
            }).catch((err) => {
                console.log("Error", err)
            })
        }
    }


    const getAppliedPriceData = async () => {
        const url = ApiEndPoint.priceInformation.replace('{depotId}', locationId).replace('{specId}', portLevelId);
        await getMethod(url, '').then((res) => {
            if (res?.status === 200) {
                setMessageShown('');
                setStatusCode(true);
                setRateSetting(0);
                templateDataFormatFiltered(res)
               
            }else{
                setMessageShown(t("usagerate.applied.fee.message"));
                let chargeRateId = chargeRateSetting.length > 0 ? chargeRateSetting[0]?.id : '';
                setRateSetting(chargeRateId);
                setStatusCode(false);
                getChargeFeeData(chargeRateId)
            }
        }).catch((err) => {
            console.log("Error", err)
        })
    }

    const checkNumericValue = (event: any) => {
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const submitChargeFee = () => {
        const flag = simpleValidator.current.allValid();
        if(flag) {
            const {evseDepotId, evseDepotLevelFeeId, roamingFee, ...remainingObject} = intialValues;
            let paramObject = {
                ...remainingObject,
                occupancyMaxFee: Number(remainingObject.occupancyMaxFee),
                globalTaxRate: Number(remainingObject.globalTaxRate),
                occupancyTaxRate: Number(remainingObject.occupancyTaxRate),
                sessionTaxRate: Number(remainingObject.sessionTaxRate),
                usageTaxRate: Number(remainingObject.usageTaxRate),
                usageBillingIncrementTime: (intialValues?.usageBillingUnit === TIME_TYPE ? remainingObject.usageBillingIncrementTime : 0)
            }
            let memberFees = paramObject?.memberLevelFees ? paramObject?.memberLevelFees?.map((val: any) => ({
                ...val,
                occupancyFee: Number(val.occupancyFee),
                sessionServiceFee: Number(val.sessionServiceFee),
                usageChargingFee: Number(val.usageChargingFee)
            })) : []
            if (memberFees) {
                paramObject = {...paramObject, ['memberLevelFees']: memberFees};
            }
            const url = ApiEndPoint.priceInformation.replace('{depotId}', locationId).replace('{specId}', portLevelId);
            putMethod(url, '', paramObject).then((res) => {
                if (res?.status === 201) {
                    setMessageShown('');
                    dispatch(showErrorMessages({
                        error: true,
                        message: "common.newusagerate.success.message",
                        type: "success",
                        operation: ""
                    }));
                } else {
                    dispatch(showErrorMessages({
                        error: true,
                        message: "common.newusagerate.failure.message",
                        type: "error",
                        operation: ""
                    }));
                }
            }).catch((err) => {
                dispatch(showErrorMessages({error: true, message: "", type: "error", operation: ""}));
                console.log("Error", err)
            })
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };
            
    return (
        <>
           { loadingPage ? <CircularProgress className="inner_progress_userGroup"/> :
            <div className="information_section" data-testid="charge_fee_page">
                <div className="fee_templeate">
                    <Card className="small_card" variant="outlined">
                        <CardContent className="card_content">
                            <div className="title_row">
                                <h2>{t("feetemplate.fieldleftblank")} 
                                <div className="error_prompt_msg_sm">{messageShown} </div>
                                </h2>
                                { canUpdatePricing &&
                                    <Button className="btn_white ml_0 full_right blue_bg" onClick={submitChargeFee}>
                                        {t("usagerate.apply.chargefee")}
                                    </Button>
                                }
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="charge_rate">
                                <div className="common_form">
                                    <div className="section_block">
                                        <div className="row_form">
                                            <Grid container spacing={6}>
                                                <Grid item className="p_tb_0">
                                                    <div className="label">
                                                        {t("feetempleate.chargetype")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_md">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="charge-type-label"
                                                                id="charge-type-select"
                                                                disabled={!canUpdatePricing}
                                                                value={tabValue}
                                                                name="chargeType"
                                                                onChange={handleChargetab}
                                                            >
                                                                {evsePortLevelDetails && evsePortLevelDetails.map((value: any, index: number) => (<MenuItem className="optionlist" key={value?.id} value={index}>
                                                                    {value?.level}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item className="p_tb_0">
                                                    <div className="label">
                                                        {t("feetempleate.ratetemplate")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_md">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="charge-rate-setting-label"
                                                                id="charge-rate-setting-select"
                                                                disabled={!canUpdatePricing}
                                                                value={rateSetting}
                                                                name="rateSetting"
                                                                onChange={handleRateSetting}
                                                            >
                                                                {statusCode ? <MenuItem className="optionlist" value={0}>
                                                                    {t("usagerate.appliedprice")}
                                                                </MenuItem> : ''}
                                                                {chargeRateSetting && chargeRateSetting.map((value: any) => (<MenuItem className="optionlist" key={value?.id} value={value.id}>
                                                                    {value?.name}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="section_block">
                                        <div className="row_form">
                                            <div className="title_row mb-15">
                                                <h2>{t("common.globalfee")} </h2>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <Grid container spacing={6}>
                                                <Grid item >
                                                    <div className="label mn-34">
                                                        {t("common.country")}
                                                        <span className="sab_label">{t("common.selectedcountry")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_md">
                                                              <OutlinedInput
                                                                    className="input_text w_100per disable"
                                                                    id="outlined-country-text"
                                                                    disabled={true}
                                                                    name="countryCode"
                                                                    value={countryName?.name ? countryName.name : ''}
                                                                />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className="label mn-34">
                                                        {t("common.currency")}
                                                        <span className="sab_label">{t("feetemplate.localchargingcurrency")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="charge-curreny-label"
                                                                id="charge-curreny-select"
                                                                disabled={!canUpdatePricing}
                                                                value={intialValues?.sessionCurrency}
                                                                name="sessionCurrency"
                                                                onChange={handleChange}
                                                            >
                                                                {currencyList && currencyList.map((value: any, index: number) => (<MenuItem className="optionlist" key={value?.code} value={value?.code}>
                                                                    {value?.code}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label mn-34">
                                                        {t("feetemplate.roamingfee")}
                                                        <span className="sab_label">{t("feetemplate.setbyblinkcharging")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className="input_text input_text_left disable"
                                                                id="outlined-roaming-fee"
                                                                disabled={true}
                                                                name="roamingFee"
                                                                value={Number(intialValues?.roamingFee)?.toFixed(2)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                {countryCode === COUNTRY_CODE.ISRAEL &&
                                                <Grid item data-testid="vat_field">
                                                    <div className="label mn-34">
                                                        {t("common.vat")}
                                                        <span className="sab_label">{t("common.vatdesc")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <div className={`form_col-12 ${validateError(simpleValidator, "globalTaxRate")}`}>
                                                                <OutlinedInput
                                                                    className={`input_text ${!canUpdatePricing && 'disable'}`}
                                                                    id="outlined-global-taxrate"
                                                                    disabled={!canUpdatePricing}
                                                                    name="globalTaxRate"
                                                                    type="number"
                                                                    defaultValue={0}
                                                                    inputProps={{maxLength: 5}}
                                                                    value={intialValues.globalTaxRate}
                                                                    placeholder='0.00'
                                                                    onKeyPress={(event) => checkNumericValue(event)}
                                                                    onChange={handleDecmalValueChange}
                                                                    startAdornment={<InputAdornment
                                                                        position="start">%</InputAdornment>}
                                                                />
                                                                <cite className="error_msg">
                                                                    {simpleValidator.current.message("globalTaxRate", intialValues.globalTaxRate, 'numeric|min:0|max:100,num',
                                                                        {
                                                                            messages: {
                                                                                min: t("usagerate.vatmin.error"),
                                                                                max: t("usagerate.vatmax.error")
                                                                            }
                                                                        })}
                                                                </cite>
                                                            </div>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="section_block second_level">
                                        <div className="row_form">
                                            <div className="title_row mb-15">
                                                <h2>{t("feetemplate.sessionfee")} </h2>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <Grid container spacing={6}>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("common.servicefee")}
                                                        <span className="sab_label">{t("feetemplate.feetostart")}</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid className="fs_12" container spacing={6}>
                                                <Grid item >
                                                    <div className="label">
                                                        {t("common.guest")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="session-guest-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"
                                                                value={findMemberLevelFees(GUEST_TYPE, STANDARD_TYPE, SESSION_TYPE)}
                                                                defaultValue={'0.00'}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(GUEST_TYPE, STANDARD_TYPE, SESSION_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className="label">
                                                        {t("common.member")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="session-member-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(MEMBER_TYPE, STANDARD_TYPE, SESSION_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(MEMBER_TYPE, STANDARD_TYPE, SESSION_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.roamingpartner")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="session-roamingpartner-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(PARTNER_TYPE, STANDARD_TYPE, SESSION_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(PARTNER_TYPE, STANDARD_TYPE, SESSION_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.custom")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="session-fleet-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(FLEET_TYPE, STANDARD_TYPE, SESSION_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(FLEET_TYPE, STANDARD_TYPE, SESSION_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("common.employee")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="session-employee-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                             
                                                                value={findMemberLevelFees(EMPLOYEE_TYPE, STANDARD_TYPE, SESSION_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(EMPLOYEE_TYPE, STANDARD_TYPE, SESSION_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="section_block second_level mb-0">
                                        <div className="row_form">
                                            <div className="title_row mb-15">
                                                <h2>{t("common.usagerate")} </h2>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <Grid container spacing={6}>
                                                <Grid item >
                                                    <div className="label mn-34">
                                                        {t("common.billingunit")}
                                                        <span className="sab_label">{intialValues?.usageBillingUnit === TIME_TYPE ? t("feetemplate.minutetobilling") : t("feetemplate.chargesessionbilling")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="charge-billing-unit-label"
                                                                id="charge-billing-unit-select"
                                                                disabled={!canUpdatePricing}
                                                                value={intialValues?.usageBillingUnit}
                                                                name="usageBillingUnit"
                                                                onChange={handleChange}
                                                            >
                                                                {unitsType && unitsType.map((value: any) => (<MenuItem className="optionlist" key={value?.id} value={value?.unit}>
                                                                    {value.unit}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className="label mn-34">
                                                        {t("common.graceperiod")}
                                                        <span className="sab_label">{t("feetemplate.freepromotionalincentive")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="charge-free-charging-label"
                                                                id="charge-free-charging-select"
                                                                disabled={!canUpdatePricing}
                                                                value={intialValues.sessionFreeChargingTime}
                                                                name="sessionFreeChargingTime"
                                                                onChange={handleChange}
                                                            >
                                                                {defaultVariable.freeChargingDetails && defaultVariable.freeChargingDetails.map((value: any) => (<MenuItem className="optionlist" key={value?.id} value={value.value}>
                                                                    {value?.name}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                {intialValues?.usageBillingUnit === TIME_TYPE &&
                                                    <Grid item>
                                                        <div className="label mn-34">
                                                            {t("usagerate.billingincrement")}
                                                            <span className="sab_label">{t("usagerate.minutes.billusage")}</span>
                                                        </div>
                                                        <div className="result">
                                                            <FormControl className="form_control f_sm">
                                                                <Select variant="outlined"
                                                                    className={`${setFormDisabled} `}
                                                                    labelId="usage-billing-increment-label"
                                                                    id="usage-billing-increment-select"
                                                                    disabled={!canUpdatePricing}
                                                                    value={intialValues.usageBillingIncrementTime}
                                                                    name="usageBillingIncrementTime"
                                                                    onChange={handleChange}
                                                                >
                                                                    {defaultVariable.usageBillingIncrementDetails && defaultVariable.usageBillingIncrementDetails.map((value: any) => (<MenuItem className="optionlist" key={value?.id} value={value.value}>
                                                                        {value.name}
                                                                    </MenuItem>))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="section_block second_level">
                                        <div className="row_form">
                                            <Grid container spacing={6}>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.userchargingrates")}
                                                        <span className="sab_label">{t("feetemplate.rateconsumption")}</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid className="fs_12" container spacing={6}>
                                                <Grid item >
                                                    <div className="label">
                                                        {t("common.guest")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="uasage-guest-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                          
                                                                value={findMemberLevelFees(GUEST_TYPE, STANDARD_TYPE, USAGE_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(GUEST_TYPE, STANDARD_TYPE, USAGE_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className="label">
                                                        {t("common.member")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="uasage-member-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                              
                                                                value={findMemberLevelFees(MEMBER_TYPE, STANDARD_TYPE, USAGE_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(MEMBER_TYPE, STANDARD_TYPE, USAGE_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.roamingpartner")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="uasage-roamingpartner-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(PARTNER_TYPE, STANDARD_TYPE, USAGE_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(PARTNER_TYPE, STANDARD_TYPE, USAGE_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.custom")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="uasage-fleet-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                           
                                                                value={findMemberLevelFees(FLEET_TYPE, STANDARD_TYPE, USAGE_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(FLEET_TYPE, STANDARD_TYPE, USAGE_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("common.employee")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="uasage-employee-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(EMPLOYEE_TYPE, STANDARD_TYPE, USAGE_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(EMPLOYEE_TYPE, STANDARD_TYPE, USAGE_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="section_block second_level mb-0">
                                        <div className="row_form">
                                            <div className="title_row mb-15">
                                                <h2>{t("feetemplate.occupancyrate")} </h2>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <Grid container spacing={6}>
                                                <Grid item >
                                                    <div className="label mn-34">
                                                        {t("usagerate.billingincrement")}
                                                        <span className="sab_label">{t("feetemplate.minutestobilloccupancy")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="charge-billing-increment-label"
                                                                id="charge-billing-increment-select"
                                                                disabled={!canUpdatePricing}
                                                                value={intialValues.occupancyBillingIncrementTime}
                                                                name="occupancyBillingIncrementTime"
                                                                onChange={handleChange}
                                                            >
                                                                {defaultVariable.billingIncrementDetails && defaultVariable.billingIncrementDetails.map((value: any) => (<MenuItem className="optionlist" key={value?.id} value={value.value}>
                                                                    {value.name}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className="label mn-34">
                                                        {t("feetemplate.occupancymaxfee")}
                                                        <span className="sab_label">{t("feetemplate.maximumamountcharged")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput                                      
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="outlined-occupancy-maxfee"
                                                                disabled={!canUpdatePricing}
                                                                name="occupancyMaxFee"                                                               
                                                                value={intialValues.occupancyMaxFee}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onChange={handleDecmalValueChange}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label mn-34">
                                                        {t("common.graceperiod")}
                                                        <span className="sab_label">{t("feetemplate.lengthpenalty")}</span>
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <Select variant="outlined"
                                                                className={`${setFormDisabled} `}
                                                                labelId="outlined-occupancy-graceperiod"
                                                                id="occupancy-graceperiod-increment-select"
                                                                disabled={!canUpdatePricing}
                                                                value={intialValues.occupancyGracePeriod}
                                                                name="occupancyGracePeriod"
                                                                onChange={handleChange}
                                                            >
                                                                {defaultVariable.gracePeriodDetails && defaultVariable.gracePeriodDetails.map((value: any) => (<MenuItem className="optionlist" key={value?.id} value={value.value}>
                                                                    {value.name}
                                                                </MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="section_block second_level">
                                        <div className="row_form">
                                            <Grid container spacing={6}>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.occupancyrate")}
                                                        <span className="sab_label">{t("feetemplate.rateperbillingunit")}</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid className="fs_12" container spacing={6}>
                                                <Grid item >
                                                    <div className="label">
                                                        {t("common.guest")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="occupancy-guest-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(GUEST_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(GUEST_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className="label">
                                                        {t("common.member")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="occupancy-member-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                               
                                                                value={findMemberLevelFees(MEMBER_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(MEMBER_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.roamingpartner")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="occupancy-roamingpartner-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                                
                                                                value={findMemberLevelFees(PARTNER_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(PARTNER_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("feetemplate.custom")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="occupancy-fleet-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                                
                                                                value={findMemberLevelFees(FLEET_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(FLEET_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className="label">
                                                        {t("common.employee")}
                                                    </div>
                                                    <div className="result">
                                                        <FormControl className="form_control f_sm">
                                                            <OutlinedInput
                                                                className={`input_text ${setFormDisabled}`}
                                                                id="occupancy-employee-standard"
                                                                disabled={!canUpdatePricing}
                                                                name="memberLevelFees"                                                                
                                                                value={findMemberLevelFees(EMPLOYEE_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE)}
                                                                placeholder='0.00'
                                                                inputProps={{ maxLength: 5 }}
                                                                onKeyPress={(event) => checkNumericValue(event)}
                                                                onChange={(e) => handleMemberLevelFees(EMPLOYEE_TYPE, STANDARD_TYPE, OCCUPANCY_TYPE, e)}
                                                                startAdornment={<span>{parse(defaultCurrSymbol)}</span>}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </div>
            </div> 
           }
        </>
    );
}
export { SetChargeFee };
export default ChargeFee