export const stationAcess = [
    { id: 1, access: "Public" ,value:"true" },
    { id: 2, access: "Private" , value:"false" },
 ];

 export const restrictedLocation = [
    { id: 1, label: "Yes" ,value:"true" },
    { id: 2, label: "No" , value:"false" },
 ];

 export const localauthorization = [
   { id: 1, label: "True", value:"true" },
   { id: 2, label: "False", value:"false" },
];

