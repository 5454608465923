import { urlReplace } from "common";
import {getMethod, ApiEndPoint, postMethod } from "services";
import {useCallback} from "react";


export const fetchPricingList = async (parameter: any, mock = false) => {
    let queryParams = `?location_id=${parameter.locationId}`;
    const data = await getMethod(ApiEndPoint.semaPricingPlan, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return data;
};

export const fetchLocationAccess = async (parameter: any, mock = false) => {
    let queryParams = `?location_id=${parameter.locationId}`;
    const data = await getMethod(ApiEndPoint.semaStationAccessAndPlan, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return data;
};

export const addPricingplan = async (data: any, parameter: any) => {

    let params = parameter.action === true ? `?action=edit&id=${parameter.pricingPlanId}` : '?action=add';
    let pricingData = await postMethod(ApiEndPoint.semaPricingManagetariff, params, data, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return pricingData
};

export const editPricingPlan = async (parameter: any, mock = false) => {    

    let params = `?id=${parameter.pricingPlanId}&host_id=${parameter.orgId}`
    let pricingData = await getMethod(ApiEndPoint.semaPricingTariffdetails, params, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return pricingData
};

export const checkPricingPlanName = async (parameter: any, mock = false) => {    

    let params = `?host_id=${parameter.orgId}&name=${parameter.name}`
    let pricingPlanName = await getMethod(ApiEndPoint.semaPricingCheckTariff, params, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return pricingPlanName
};

export const getLastInsertedPricing = async (parameter: any, mock = false) => {    

    let params = `?host_id=${parameter.orgId}`
    let pricingData = await getMethod(ApiEndPoint.semaPricingLastInserted, params, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return pricingData
};

export const deletePricingPlan = async (data: any) => {

    let params = '?action=delete';
    let pricingData = await postMethod(ApiEndPoint.semaPricingManagetariff, params, data, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return pricingData
};

export const fetchUserGroupLists = async (parameter: any, mock = false) => {
    let queryParams = `?host_id=${parameter.hostId}`;
    const data = await getMethod(ApiEndPoint.semaUserGroupAPI, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return data;
};

export const fetchTariffForDropDown = async (parameter: any, mock = false) => {
    let queryParams = `?host_id=${parameter.hostId}&is_mapped=${parameter.is_mapped}`;
    const data = await getMethod(ApiEndPoint.semaTariffForDropDown, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return data;
};

export const manageApplyPricingPlan = async (body: any, params: any) => {
    let queryParams = ``;
    const data = await postMethod(ApiEndPoint.semaManageApplyPricingAPI, queryParams, body, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const flexiblePricingInvoiceDetails = async (parameter: any, mock = false) => {
    const url = urlReplace(ApiEndPoint.flexiblePricingInvoiceDetails, "{CHARGE-SESSION-ID}", parameter.chargingSessionId);
    const data = await getMethod(url, "", mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return data;
};

export const chargingSessionAppliedFeeId = async (parameter: any, mock = false) => {
    const url = urlReplace(ApiEndPoint.appliedfeeDetails, "{appliedFeeId}", parameter.appliedFeeId);
    const data = await getMethod(url, "", mock, false, false)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
    return data;
};

export const fetchChargeSessionTransactionData = async (historyId: any, mock = false) => {
    const url = urlReplace(ApiEndPoint.transactionChargeDetails, "{CHARGE-SESSION-ID}", historyId);
    const data =  await getMethod(url, "", mock)
        .then((res) => {
            if (res) {
                return res
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};


