import React, { useState, useEffect } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import {TabPanel, a11yProps, useStyles, GetUrlDetails, defaultVariable, rolePermissionCheck, keyCloakConfig} from "common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FleetGroupHeader from "./fleetGroupHeader";
import {FLEET_GROUP_INFO_PATH, GROUP, HEIGHT_TO_HIDE_FROM} from "pages/fleet/fleetManagementHelper";
import { fetchGroupInfo } from "services/fleetManagementService";
import FleetGroupInformation from "./fleetGroupInfo";
import FleetConstrains from "pages/fleet/fleetConstraints";
import FleetVehicles from "pages/fleet/fleetVehicles";
import FleetDelegate from "./fleetDelegate";
import FleetCommonAlert from "pages/fleet/alert";
import FleetChargers from "../../fleetChargers";


const FleetGroupInfo = () => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname, groupId, tab, hostId } = GetUrlDetails(history, FLEET_GROUP_INFO_PATH);
    const [tabChange, setTabChange] = useState(Number(tab));
    const [isVisible, setIsVisible] = useState(true);
    const [value, setValue] = React.useState(0);
    const [groupinfo, setGroupInfo] = useState<any>([]);
    const [editableinfo, setEditInfo] = useState<any>([]);
    const [spinnerOff, setSpinnerOff] = useState(false);
    
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);

    useEffect(()=> {
        if (tab !== "") {
            setTabChange(parseInt(tab));
            setValue(parseInt(tab));
        }
    }, [tab]);

    const handleChange = (event: any, newValue: any) => {
        if (tabChange !== newValue) {
            setTabChange(newValue);
        }
        window.scrollTo(0, 0);
        setValue(newValue);
        let searchParams = "?groupId=" + groupId;
        history.push({
            pathname: pathname,
            hash: "#" + newValue,
            search: searchParams,
        });
    };

    const getGroupInfo = () => {
        setSpinnerOff(true);
        let params = {groupId: groupId};
        fetchGroupInfo(params, false)
            .then((res: any) => {
                if (res) {
                    setGroupInfo(res);
                    let initialValue = {
                        locationId: res[0]?.location_id,
                        hostId: res[0]?.host_id,
                        fleetGroup: res[0]?.group,
                        zoneId: res[0]?.zone_id,
                    };
                    setEditInfo(initialValue);
                    setSpinnerOff(false);
                }
            })
            .catch((err: any) => {
                console.error("Error", err);
            });
    };

   const  dataRefresh = () => {
       getGroupInfo();
   };

    useEffect(() => {
        if (tab !== "") {
            setValue(parseInt(tab));
        }
    }, [tabChange, tab]);

    useEffect(() => {
        const listenToScroll = () => {
            let heightToHideFrom = HEIGHT_TO_HIDE_FROM;
            const winScroll =
                document.body.scrollTop || document.documentElement.scrollTop;
            if (winScroll > heightToHideFrom) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    useEffect(() => {
        getGroupInfo();
    }, []);

    return (
        <>
            <main className={classes.content}>
                <div
                    className={
                        isVisible
                            ? "mid_section_inside details_pages"
                            : "mid_section_inside details_pages scroll_effect"
                    }
                >
                    <div className="depot_details members station_details">
                        <div className="depot_detail_head position_fixed_head">
                            <FleetGroupHeader groupinfo={groupinfo} />
                        </div>
                        <div className="detail_page_tab">
                            <AppBar
                                className="defult_tab position_fixed_nav"
                                position="static"
                                color="default"
                            >
                                <Tabs
                                    className="tab_nav"
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="off"
                                    aria-label="scrollable force tabs example"
                                >
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("fleet.vehicles")}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.evse")}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        className={`tab_nav_item ${isDelegate && 'hide-tab'}`}
                                        label={t("fleet.delegate")}
                                        {...a11yProps(2)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("fleet.constraints")}
                                        {...a11yProps(3)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("fleet.notifications")}
                                        {...a11yProps(4)}
                                    />
                                    <Tab
                                        className="tab_nav_item"
                                        label={t("common.information")}
                                        {...a11yProps(5)}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <FleetVehicles groupId={groupId} type={GROUP} isFleetGroupsAvilable={true} getUpdatedInfo={getGroupInfo} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <FleetChargers groupId={groupId} dataRefresh={dataRefresh} />
                            </TabPanel>
                            <TabPanel className={`${isDelegate && "hide"}`} value={value} index={2}>
                                <FleetDelegate groupId={groupId} />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <FleetConstrains groupId={groupId} hostId={hostId} activeTab={tabChange} />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <FleetCommonAlert groupId={groupId} type={GROUP} />
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <FleetGroupInformation groupId={groupId} getGroupinfo={getGroupInfo} groupinfo={groupinfo} editableinfo={editableinfo} spinnerOff={spinnerOff} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
export default FleetGroupInfo;
