import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Grid, Card, CircularProgress, Dialog} from '@material-ui/core';
import DepotHealthCard from 'components/depotHealthCard';
import {mapDetails, useStyles, BLANK_DATA_PLACEHOLDER, HandlePaginationLabel, pageDetails, rolePermissionCheck, defaultVariable, SOURCE_SEMA, keyCloakConfig} from 'common';
import CommonGoogleMap from 'components/commonGoogleMap';
import './evseLocations.scss';
import { useTranslation } from "react-i18next";
import DepotPowerCard from 'components/depotPowerCard';
import ChargeHistoryReportForm from './chargeHistoryReportForm';
import CommonPagination from 'components/commonPagination';
import {
    fetchAllLocationsAddress,
    fetchConsolidatedEnergyUsage,
    fetchEvseHealthStatus,
    fetchLocationListByPagination
} from "../../services/evseLocationsService";
import Location from "./location";
import {chartRendered} from "./evseLocationsHelper";
import MonthlyReports from "./monthlyReports";

const EvseLocations = (props: any) => {
    
    const { t } = useTranslation();
    const history = useHistory();
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const [markers, setMarkers] = useState<any>([]);   
    const classes = useStyles();
    const [openHistoryReport, setHistoryReportOpen] = useState(false);
    const [openMonthlyReport, setMonthlyReportOpen] = useState(false);
    const [locationsList, setLocationsList] = useState<any>([]);
    const [depotSpinnerOff, setDepotSpinnerOff] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [centerValue, setCenterValue] = useState<any>(null);
    const [totalLocationCount, setTotalLocationCount] = useState<any>(0);
    const [evseHealthStatus, setEvseHealthStatus] = useState<any>(null);
    const [totalEnergyUsage, setTotalEnergyUsage] = useState<any>([]);
    const pagingSize = pageDetails.pageSize;
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const hostSource = sessionStorage.getItem('hostSource');

    
    useEffect(() => {
        getLocationsForMap();
        getHostLocations();
        getEvseHealthStatus();
        getConsolidatedEnergyUsage();
    }, []);

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage);
        getHostLocations(setPage);
    };

    const reportOpen = () => {
        setHistoryReportOpen(true);
    };
    
    const reportClose = () => {
        setHistoryReportOpen(false);
    };

    const monthlyReportOpen = () => {
        setMonthlyReportOpen(true);
    };

    const monthlyReportClose = () => {
        setMonthlyReportOpen(false);
    };

    const getHostLocations = (pageCount: number = 0) => {
        let params = {page: pageCount, size: pagingSize};
        setDepotSpinnerOff(true);
        fetchLocationListByPagination(params).then((locationData: any) => {
            if(locationData?.locationsInfo) {
                setLocationsList(locationData.locationsInfo);
                setTotalLocationCount(locationData.totalLocations);
            }
            setDepotSpinnerOff(false);
        }).catch((error: any)=>{
            setDepotSpinnerOff(false);
            console.log(error)
        })
    };

    const getLocationsForMap = () =>{
        fetchAllLocationsAddress().then((allLocationsAddress: any) => {
            // set markers for map component
            if(allLocationsAddress && allLocationsAddress?.locationsInfo) {
                setCenterValue(allLocationsAddress?.locationsInfo[0]);
                let arr = allLocationsAddress?.locationsInfo.map((value: any) => (
                    {
                        lat: value?.address?.latitude,
                        lon: value?.address.longitude,
                        title: value.locationName ? value.locationName : BLANK_DATA_PLACEHOLDER,
                        ids: value.locationId ? value.locationId : BLANK_DATA_PLACEHOLDER
                    }
                ));
                setMarkers(arr);
            }
        }).catch((error: any)=>{
            console.log(error)
        })
    };

    const getEvseHealthStatus = () => {
        fetchEvseHealthStatus().then((evseHealthStatus: any) => {
            if(evseHealthStatus) {
                setEvseHealthStatus(evseHealthStatus);
            }
        }).catch((error: any) => {
            console.log(error)
        })
    };

    const getConsolidatedEnergyUsage = () => {
        fetchConsolidatedEnergyUsage().then((energyUsageData: any) => {
            if(energyUsageData) {
                setTotalEnergyUsage(energyUsageData?.energyUsageDtos);
            }
        }).catch((error: any) => {
            console.log(error)
        })
    };

    const navigateToDepotDetails = (value: any) => {
        if (value) {
            let searchParams = "locationId="+ value;  
            history.push({
                pathname:  "/evseLocations/evseDepotDetail",
                search: searchParams,
                hash: '#0'
             });   
        }
    };

    const markerClicked = (value: any) => { navigateToDepotDetails(value?.ids) };

    return (
        <>
            {/*----Page Mid Section Start----*/}
            <main className={classes.content} data-testid="evse_locations_page">
                {depotSpinnerOff && <CircularProgress className="inner_progress_userGroup"/>}
                <div className="mid_section_inside evse_list">
                    <Grid container spacing={2}>
                            {/*----Page Mid Left Section Start----*/}
                            <Grid className="mid_section_left" item xs={12} sm={12} md={8}>
                                <div className="section_title">
                                    <Grid container>
                                        <Grid item xs={8}><h2>{t("evselocations.label")}</h2></Grid>
                                        <Grid className="title_right" item xs={4}>
                                           {totalLocationCount> 0 && <h2>{totalLocationCount}{" "}{t("common.locations")}</h2>}
                                        </Grid>
                                    </Grid>
                                </div>
                                {/*----EVSE Depot List Section Start----*/}
                                <div className="table_scroll">
                                    <div className="depot_list">
                                        {!depotSpinnerOff && totalLocationCount <= 0 && <List>{t("common.no.rows")}</List>}
                                        <List>
                                            {/*----EVSE Depot1 Start----*/}
                                            {locationsList && locationsList.map((value: any) => (
                                                 <Location key={value?.locationId} location={value} />
                                             ))}
                                        </List>
                                    </div>
                                    <div className="table_footer">
                                        <Grid container direction="row" alignItems="center" spacing={0}>
                                            <Grid className="text_left" item xs={8}>
                                                { totalLocationCount > pagingSize &&
                                                   <CommonPagination
                                                        currentPage={currentPage}
                                                        totalCount={totalLocationCount}
                                                        callBackCurrentPage={handleCurrentPage}
                                                        pageSize = {pagingSize}
                                                   />
                                                }
                                            </Grid>
                                            <Grid className="text_right" item xs={4}>
                                                <div className="list_number">
                                                    {HandlePaginationLabel(totalLocationCount, pagingSize, currentPage, "common.locations")}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                {/*----EVSE Depot List Section End----*/}
                            </Grid>
                            {/*----Page Mid Left Section End----*/}

                            {/*----Page Mid Right Section Start----*/}
                            {locationsList.length > 0 &&
                              <Grid className="mid_section_right" item xs={12} sm={12} md={4}>
                                {/*---Map Locations---*/}
                                <div className="section_title">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h3>{t("common.locations")}</h3>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Card variant="outlined" className="location_map_height">
                                    {markers.length > 0 &&
                                    <>
                                        {centerValue &&
                                        <CommonGoogleMap {...props}
                                            center={{lat: centerValue.address.latitude, lon: centerValue.address.longitude,}}
                                            zoom={mapDetails.depotLocationMapZoom}
                                            options={2}
                                            markers={markers}
                                            markerClicked={markerClicked}/>}
                                    </>}
                                </Card>
                                <div className="dialog_box">
                                    <Dialog
                                        className="dialog_container edit_detail_section sm report_height dialog_container_center" {...props}
                                        open={openHistoryReport}
                                        onClose={reportClose}
                                        aria-labelledby="scroll-dialog-title"
                                        aria-describedby="scroll-dialog-description">
                                        <ChargeHistoryReportForm{...props} reportClose={reportClose} hostId={hostId}/>
                                    </Dialog>
                                </div>
                                <div className="button_row mt-20">
                                    <button className="btn_white blue_bg ml_0 download_report" onClick={reportOpen}>
                                        <span className="MuiButton-label">{t("common.downloadchargehistoryreport")}</span>
                                    </button>
                                </div>
                                { hostSource === SOURCE_SEMA && !isDelegate && 
                                    <div className="button_row mt-20">
                                        <button className="btn_white blue_bg ml_0 download_report" onClick={monthlyReportOpen}>
                                            <span className="MuiButton-label">{t("common.downloadmonthlyreports")}</span>
                                        </button>
                                    </div>
                                }
                                {totalEnergyUsage && totalEnergyUsage.length > 0 && chartRendered(totalEnergyUsage) && <DepotPowerCard depotPower={totalEnergyUsage}/>}
                                {/*---EVSE Depot Analytics---*/}
                                <div className="analytics">
                                    <DepotHealthCard
                                        depotLabel={t("evselocations.health.label")}
                                        chargingPorts={evseHealthStatus?.totalChargingPorts}
                                        availablePorts={evseHealthStatus?.totalAvailablePorts}
                                        offlinePorts={evseHealthStatus?.totalOfflinePorts}
                                        completedPorts={evseHealthStatus?.totalCompletedPorts}
                                        connectedPorts={evseHealthStatus?.totalConnectedPorts}
                                        errorPorts={evseHealthStatus?.totalErrorPorts}
                                        reservedPorts={evseHealthStatus?.totalReservedPorts}
                                    />
                                </div>
                            </Grid>
                            }
                            {/*----Page Mid Right Section End----*/}
                        </Grid>
                </div>
                <MonthlyReports openMonthlyReport={openMonthlyReport} monthlyReportPopupClose={monthlyReportClose}/>
            </main>
            {/*----Page Mid Section End----*/}

        </>
    );
};

export default EvseLocations;
