import { claims } from './claims';
import { useContext } from "react";
import { AbilityContext } from './Can';

export const CheckAccess = (access: any) => {
    const ability = useContext(AbilityContext);
    let result: boolean = false;

    if(access && !access.includes(claims.FORBIDDEN_ACCESS)) {
        access.forEach((claim: any) => {
            const claimTokenized = claim.split(":");
            const action = claimTokenized.splice(claimTokenized.length - 1, 1)?.toString();
            const subject = claimTokenized.join(":");
            if (ability.can(action, subject)) {
                result = true;
            }
        });
    } else {
        // interim solution for sidebar and others
        const isForbidden = (access && access.includes(claims.FORBIDDEN_ACCESS));
        result = isForbidden ? false : true;
    }

    return result;
}