import React, {useState, useEffect} from "react";
import {FleetDelegateColumns} from "pages/tableColumns/fleetDelegateColumns";
import {DataGrid} from "@material-ui/data-grid";
import {CircularProgress} from "@material-ui/core";
import {fetchDelegates} from "services/fleetManagementService";
import {columnNameTranslate} from "common/methodHelpers";

const FleetDelegate = (props: any) => {
    const {groupId} = props;
    const [loadingPage, setLoadingPage] = useState(false);
    const [fleetDelegateList, setFleetDelegateList] = useState<any>({});

    const getFleetDelegateList = () => {
        let params = {groupId: groupId};
        setLoadingPage(true);
        fetchDelegates(params, false)
            .then((res: any) => {
                setFleetDelegateList(res);
                setLoadingPage(false);
            })
            .catch((err: any) => {
                setLoadingPage(false);
                console.error("Error", err);
            });
    };

    const fleetDelegateListDataSet = (value: any) => {
        let fleetTableData = [];
        if (value && value?.delegates) {
            fleetTableData = value.delegates.map((val: any, index: number) => ({
                ...val,
                id: index,
            }));
        }
        return fleetTableData;
    };

    useEffect(() => {
        getFleetDelegateList();
    }, []);

    return (
        <>
            {loadingPage ? (
                <CircularProgress className="inner_progress_userGroup"/>
            ) : (
                <div className="information_section">
                    <div className="table_scroll mt-10">
                        <div className="default_table">
                            <DataGrid
                                autoHeight
                                className="table"
                                rowHeight={59}
                                headerHeight={30}
                                rows={fleetDelegateListDataSet(fleetDelegateList)}
                                columns={columnNameTranslate(FleetDelegateColumns)}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FleetDelegate;
