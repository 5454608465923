import environment from "../environment"
import axios from "axios";
import ApiEndPoint from './apiUrl.json';
import i18next from 'i18next';
import { fetchUserPermissions } from './../redux/actions/userPermission';
import jwt_decode from "jwt-decode";
import configureStore from "redux/store";

const { store } = configureStore();

export const initAxios = (token: any) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const onKeycloakTokens = (tokens: any) => {
    if (tokens) {
        initAxios(tokens.token);
        let tokenDecoded: any = jwt_decode(tokens.token);
        store.dispatch(fetchUserPermissions(tokenDecoded));    
    }
    else
    {
        console.error(i18next.t("console.keycloakmissingtokens"));
    }
}

const getHeaders = (multipart?: boolean) => {
    let headers;
    if(multipart) {
        headers = {
            accept: 'application/json',
            "Content-type": "multipart/form-data",
        };
    } else {
        headers = {
            accept: 'application/json',
        };
    }

    return headers;
};

let cancelToken:any;
export const getMethod = async (url: string,param: any,mock:boolean=false,cancelRequest:boolean = false, semaApiRequest: boolean = false) => {
    if (cancelRequest && typeof cancelToken !== typeof undefined) {
        cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();
    let params = param ? param : '';
    let baseUrl = semaApiRequest? environment.semaBaseUrl : environment.baseUrl;
    await axios.defaults?.headers.common['Authorization'];
    try {
        let data = await axios({
            method: "GET",
            url: mock === true ? url + params: baseUrl + url + params,
            headers: getHeaders(),
        })
        return data;
    } catch (error:any) {
        return error.response;
    }
};

export const getCannedReportMethod = async (url: string, param: any) => {
    let params = param ? param : '';
    let reportsUrl = environment.baseUrl.replace('host', 'reports');
    await axios.defaults?.headers.common['Authorization'];
    try {
        let data = await axios({
            method: "GET",
            url: reportsUrl + url + params,
            headers: getHeaders(),
        })
        return data;
    } catch (error: any) {
        return error.response;
    }
}

export const getTimezoneMethod = async (url: string,param: any) => {
    let params = param ? param : '';
    await axios.defaults.headers.common['Authorization'];
    try {
        let data = await axios({
            method: "GET",
            url: url + params,
            headers: ''
        })
        return data;
    } catch (error:any) {
        return error.response;
    }
};

export const getIpAddressMethod = async (url: string) => {

    await axios.defaults?.headers.common['Authorization'];
    try {
        let data = await axios({
            method: "GET",
            url: url,
            headers: ''
        })
        return data;
    } catch (error:any) {
        return error.response;
    }
};

export const putMethod = async (url: string, param: any, body: any, mock:boolean=false, multipart? : boolean,  semaApiRequest: boolean = false) => {
    let params = param ? param : '';
    let baseUrl = semaApiRequest ? environment.semaBaseUrl : environment.baseUrl;
    try {
        let data = await axios({
            method: "PUT",
            url: mock === true ? url : baseUrl + url + params,
            data: body,
            headers : getHeaders(multipart)
        });
        return data;
    } catch (error:any) {
        return error.response;
    }
};

export const postMethod = async (url: string, param: any, body: any, multipart? : boolean, semaApiRequest: boolean = false) => {
    let params = param ? param : '';
    let baseUrl = semaApiRequest ? environment.semaBaseUrl : environment.baseUrl;
    await axios.defaults.headers.common['Authorization'];
    try {
        let data = await axios({
            method: "POST",
            url: baseUrl + url + params,
            data: body,
            headers: getHeaders(multipart)
        });
        return data;
    } catch (error:any) {
        return error.response;
    }
};

export const deleteMethod = async (url: string, param: any='', mock:boolean=false) => {
    let params = param ? param : '';

    try {
        let data = await axios({
            method: "DELETE",
            url: mock === true ? url : environment.baseUrl + url + params,
            headers: getHeaders(),
        });
        return data;
    } catch (error:any) {
        return error.response;
    }
};

const MockApi = {
    No: false,
    Yes: true
};

export { ApiEndPoint, MockApi };

