import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, LinearProgress, MenuItem} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {ObjectDetails} from "../../../../../../common/interfaces";
import Select from "@material-ui/core/Select/Select";
import {
    DEFAULT_WAITLIST_CONFIGURE,
    WAITLIST_ERROR_MESSAGE,
    WAITLIST_LIMIT,
    WAITLIST_PLUG_TIME,
    WAITLIST_STATUS
} from "../../waitListHelper";
import {manageWaitListData} from "../../../../../../services/waitListService";
import {SUCCESS} from "../../../../../../common";
import {showErrorMessages} from "../../../../../../redux/actions/errorMessages";

const EditWaitListConfiguration = (props: ObjectDetails) => {

    let {t} = useTranslation();
    let dispatch = useDispatch();
    const {waitListConfigure, openWaitListConfigurationEdit, locationId} = props;
    const [spinnerOff, setSpinnerOff] = useState<boolean>(false);
    const [editWaitListConfigure, setEditWaitListConfigure] = useState(DEFAULT_WAITLIST_CONFIGURE);

    useEffect(() => {
        if(waitListConfigure) {
            setSpinnerOff(false);
            setEditWaitListConfigure(waitListConfigure) ;
        }
    }, [waitListConfigure, openWaitListConfigurationEdit]);

    const waitLIstConfigurationClose = () => {
        props.setOpenWaitListConfigurationEdit(false);
        props.dataRefresh();
    };

    const handleSaveWaitListConfiguration = () => {
        setSpinnerOff(true);
        let params = {locationId: locationId};
        manageWaitListData(params, editWaitListConfigure).then((res: any) => {
            if(res.status === SUCCESS) {
                props.dataRefresh();
                props.setOpenWaitListConfigurationEdit(false);
            } else {
                dispatch(showErrorMessages({error: true, message: t(WAITLIST_ERROR_MESSAGE[res.data.error_code]), type: "error", operation: "",}));
            }
            setSpinnerOff(false);
        }, (error: any) => {
            setSpinnerOff(false);
        });
    };

    const handleChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        setEditWaitListConfigure({ ...editWaitListConfigure, [name]: value });
    };

    return (
        <div className="dialog_box ">
            <Dialog
                className="dialog_container edit_detail_section edit_waitlist_config updated_pop_up sm_530 dialog_container_center station_details"
                open={openWaitListConfigurationEdit}
                onClose={waitLIstConfigurationClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{t("waitlist.editconfiguration")}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme" data-testid="btn-cancel"
                                onClick={waitLIstConfigurationClose}>{t("common.cancelbutton")}</Button>
                        <Button className={`btn_white blue_bg`} data-testid="btn-save"
                                onClick={handleSaveWaitListConfiguration}>{t("common.savechanges")}</Button>
                    </div>
                </div>
                {spinnerOff && (<LinearProgress/>)}
                <DialogContent>
                    <div className='information_section defult_form'>
                        <div className="info_detail depotstation_infolist">
                            <div className="tb_info_section mt-13 edit_detail_section">
                                <div className="row mb-20">
                                    <span className="label">{t("fleet.delegate.status")}</span>
                                    <span className="result form_col">
                                         <Select
                                             labelId="demo-simple-select-label"
                                             data-testid="status-select"
                                             variant="outlined"
                                             onChange={handleChange}
                                             name="status"
                                             value={editWaitListConfigure?.status}>
                                             {WAITLIST_STATUS.map((status: any) => (
                                                 <MenuItem className="defult_select_option" key={status.label} value={status.value}>
                                                     {t(status.label)}
                                                 </MenuItem>
                                             ))}
                                         </Select>
                                    </span>
                                </div>
                                <div className="row mb-20">
                                    <span className="label">{t("waitlist.plugtime")}</span>
                                    <span className="result form_col">
                                         <Select
                                             labelId="demo-simple-select-label"
                                             variant="outlined"
                                             onChange={handleChange}
                                             name="minutes_to_plugin"
                                             value={editWaitListConfigure?.minutes_to_plugin}>
                                           {WAITLIST_PLUG_TIME.map((value: any) => (
                                               <MenuItem className="defult_select_option" key={value} value={value}>
                                                   {`${value} ${ t("common.minutes").toLocaleLowerCase()}`}
                                               </MenuItem>
                                           ))}
                                         </Select>
                                    </span>
                                </div>
                                <div className="row mb-20">
                                    <span className="label">{t("waitlist.limit")}</span>
                                    <span className="result form_col">
                                         <Select
                                             labelId="demo-simple-select-label"
                                             variant="outlined"
                                             onChange={handleChange}
                                             name="max_waitlist_allowed"
                                             value={editWaitListConfigure?.max_waitlist_allowed}>
                                             {WAITLIST_LIMIT.map((value: any) => (
                                                 <MenuItem className="defult_select_option" key={value} value={value}>
                                                     {value}
                                                 </MenuItem>
                                             ))}
                                         </Select>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
};


export default EditWaitListConfiguration;