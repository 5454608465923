import React from "react";
import {
    DialogContent,
    DialogContentText,
    Grid,
    MenuItem,
    Select
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DECLINED } from "../hostMembershipHelper";

const SelectApproveReject = (props: any) => {

    const { t } = useTranslation();

    return (
        <>
            <DialogContent>
                <DialogContentText id="scroll-dialog-description">
                    <div className="information_section delegates_details">
                        <div className="defult_form mt-25">
                            <div className="section_group">
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={4}
                                >
                                    <Grid item xs={12}>
                                        <div className="form_col">
                                            <Grid
                                                className="single_line_delete"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={0}
                                            >
                                                <Grid item xs={4}>
                                                    <label className="w_100per full_w">
                                                        {t("membership.membership")}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Select
                                                        className="w_100per full_w"
                                                        labelId="demo-simple-select-label"
                                                        variant="outlined"
                                                        name={"type"}
                                                        onChange={props.handleChange}
                                                        value={props.membershipRequestStatus}
                                                        onFocus={() => props.setPlaceHolder(true)}
                                                        defaultValue={" "}
                                                    >
                                                        <MenuItem
                                                            className={`${props.showPlaceHolder()}`}
                                                            value=" ">{t("membership.selectaction")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            className="optionlist"
                                                            value={t("membership.approve")}>{t("membership.approve")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            className="optionlist"
                                                            disabled={props.memberStatus === DECLINED}
                                                            value={t("membership.decline")}>{t("membership.decline")}
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    )
}

export default SelectApproveReject;