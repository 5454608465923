import React from "react";
import "../../../fleetManagement.scss";
import { useHistory } from "react-router-dom";
import { FLEET_GROUP_INFO_PATH } from "pages/fleet/fleetManagementHelper";

const FleetVehicleHeader = (props: any) => {
    const history = useHistory();
    const {vehicleinfo} = props;

    const navigateToDetailPage = (value: any) => {
        history.push({
            pathname: FLEET_GROUP_INFO_PATH,
            search: "?hostId=" + value.host_id + "&groupId=" + value.fleet_groups_id,
            hash: "#" + 0,
        });
    };

    return (
        <>
            <div className="fleet_information">
                {vehicleinfo &&
                    vehicleinfo?.map((value: any) => (
                        <div className="fleet_info" key={value?.id}>
                            <div className="fleet_group">{value?.vehicleName}</div>
                            <div className="group_addr group_nav" onClick={() => navigateToDetailPage(value)}>{value?.fleet_groups_name}</div>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default FleetVehicleHeader;
