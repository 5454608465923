import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { getMethod, ApiEndPoint } from 'services';
import { pageDetails, HandlePaginationLabel, urlReplace } from 'common';
import { DataGrid } from '@material-ui/data-grid';
import CommonDownloadCsv from 'components/commonDownloadCsv';
import CommonPagination from 'components/commonPagination'
import CommonChargeSessionDetails from 'components/commonChargeSessionDetails'
import { ChargeHistoryColumnsComponent } from 'pages/tableColumns';
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

const EvseDepotChargeHistory = (props: any) => {
    const { t } = useTranslation();
    const pageSize = pageDetails.pageSize;
    const [depotChargeHistory, setDepotChargeHistory] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [depotChargeSessionInfo, setDepotChargeSessionInfo] = useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [depotDownloadCsv, setDepotDownloadCsv] = useState([]);
    const [loadingPage, setLoadingPage]  = useState(false);
    const currentLocationId = props?.locationId;
    const isLocationSubscribedToPricing = props?.isLocationSubscribedToPricing;

    /*const fileHeader =[
        {label: toUpper(t("common.station")), key: 'portInformation'},
        {label: toUpper(t("common.name")), key: 'chargerName'},
        {label: toUpper(t('common.serialnumber')), key: 'serialNumber'},
        {label: toUpper(t('chargesessiondetail.startcharge')), key: 'startChargeTime'},
        {label: toUpper(t('chargesessiondetail.endcharge')), key: 'endChargeTime'},
        {label: toUpper(t('common.blinkcodecard')), key: 'rfId'},
        {label: t('chargesessiondetail.energy'), key: 'energy'},
        {label: toUpper(t('common.amount')), key: 'totalCost'}, 
    ];*/

    const fileName: string = `${t('filename.locationchargehistory')}_${moment().format('MMDDYYYYHHmmss')}.csv`;
    const historyColumns = ChargeHistoryColumnsComponent();

    useEffect(() => {
        getDepotChargeHistory()
    }, [currentPage])

    const getDepotChargeHistory = async () => {
        const url = urlReplace(ApiEndPoint.locationChargeHistory, "{LOCATION-ID}", currentLocationId);
        const paramDetails = '?page=' + currentPage + '&size=' + pageDetails.pageSize;

        setLoadingPage(true);
        await getMethod(url, paramDetails).then((res) => {
            setDepotChargeHistory(res.data)
        }).catch((err) => {
            console.error("Error", err)
        })
        setLoadingPage(false);
    }

    const getDepotDownloadCsv = async () => {
        const url = ApiEndPoint.downloadLocationChargeHistory;
        const paramDetails = '?locationId=' + currentLocationId;

        await getMethod(url, paramDetails).then((res) => {
            setDepotDownloadCsv(res?.data);
          }).catch((err) => {
            console.log('Error', err);
          });
      };


    const depotHistoryDataSet = (value: any) => {
        let historyTableData = []
        if (value && value.chargeHistoryList) {
            historyTableData = value.chargeHistoryList.map((val: any, index:number) => ({ ...val, id: index }))
        }
        return historyTableData
    }

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)    
    }

    const handleClickOpen = (scrollType: any, chargeData: any) => () => {
        if (chargeData && chargeData.chargeHistoryList && chargeData?.chargeHistoryList?.length >= 1) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDepotChargeHistoryClick = (e: any) => {
        if (e && e.row) {
            setDepotChargeSessionInfo(e.row);
        }
    }

    return (
        <>
            {loadingPage ? <CircularProgress className="inner_progress_userGroup"/> : 
            <div className="table_scroll" data-testid="evse_depot_charge_history">
                <div className="default_table" onClick={handleClickOpen('paper', depotChargeHistory)}>
                    <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={depotHistoryDataSet(depotChargeHistory)}
                        columns={historyColumns}
                        pageSize={pageSize}
                        onRowClick={onDepotChargeHistoryClick}
                        disableSelectionOnClick />
                </div>
                {open &&
                <CommonChargeSessionDetails {...props}
                    open={open}
                    setHandleClose ={handleClose}
                    historyId={depotChargeSessionInfo?.historyId}
                    sessionInfo={depotChargeSessionInfo}
                    isLocationSubscribedToPricing = {isLocationSubscribedToPricing}

                />}
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                            {depotChargeHistory && depotChargeHistory?.totalChargeHistory > pageSize ?
                               <CommonPagination 
                               currentPage = {currentPage}
                               totalCount = {depotChargeHistory?.totalChargeHistory}
                               callBackCurrentPage = {handleCurrentPage}
                            /> : <></>}
                        {depotChargeHistory && depotChargeHistory?.totalChargeHistory &&
                            <CommonDownloadCsv {...props}
                            datas={depotDownloadCsv}
                            filename={fileName}
                            callBackDownloadCsv={getDepotDownloadCsv}/>
                        }
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">
                                {HandlePaginationLabel(depotChargeHistory?.totalChargeHistory, pageSize, currentPage, "common.chargesession")}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            }
        </>
    );
}
export default EvseDepotChargeHistory;