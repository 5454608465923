import React from "react";

export const TripHistoryColumns = [

    {
      field: "e",
      headerName: "e",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },

    
  
    {
      field: 'start',
      headerName: 'START',
      sortable: false,
      flex: 0.1,
      minWidth: 150,
      disableColumnMenu: true,
      },
    {
      field: 'end',
      headerName: 'END',
      sortable: false,
      minWidth: 150,
      flex: 0.1,
      disableColumnMenu: true
    },
    {
        field: 'vehicleId',
        headerName: 'VEHICLE ID',
        sortable: false,
        flex: 0.1,
        disableColumnMenu: true
      },
      {
        field: 'distance',
        headerName: 'DISTANCE (MI)',
        sortable: false,
        flex: 0.1,
        disableColumnMenu: true
      },
    
    {
      field: 'odometer',
      headerName: 'ODOMETER',
      sortable: false,
      flex: 0.1,
      disableColumnMenu: true
    },
    {
      field: 'energy',
      headerName: 'ENERGY (kWh)',
      sortable: false,
      width: 120,
      disableColumnMenu: true
    },
  
    
    
    {
      field: "ee",
      headerName: "ee",
      headerClassName: "empty",
      sortable: false,
      hasFocus: false,
      cellClassName: "empty",
      width: 15,
      maxWidth: 25,
      minWidth: 28,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <div className="empty">
            {cellValues.value}
          </div>
        );
      }
    },
  
  ];