import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, LinearProgress} from "@material-ui/core";
import {DataGrid, GridRowId} from "@material-ui/data-grid";
import {useTranslation} from "react-i18next";
import {ObjectDetails} from "../../../../common/interfaces";
import '../../fleetManagement.scss'
import {showErrorMessages} from "../../../../redux/actions/errorMessages";
import {useDispatch} from "react-redux";
import {FleetAvailableStationListColumnsComponent} from "../../../tableColumns/fleetChargerListColumns";
import {fetchFleetAvailableStation, manageFleetData} from "../../../../services/fleetManagementService";
import {setClassName} from "../../../evseLocations/evseDepotDetail/semaConnectChargeFee/pricingPlanHelpers";
import {columnNameTranslate} from "../../../../common/methodHelpers";
import { keyCloakConfig } from "common";


const FleetAssignStation = (props: ObjectDetails) => {

    let {t} = useTranslation();
    let dispatch = useDispatch();
    
    const hostId = keyCloakConfig?.tokenParsed?.hostId;
    const {groupId, vehicleId, assignStation } = props;
    const stationListColumn = FleetAvailableStationListColumnsComponent();
    const [spinnerOff, setSpinnerOff] = useState<boolean>(true);
    const [stationList, setStationList] = useState<any>({});
    const [selectedStations, setSelectedStations] = useState<any[]>([]);
    const [cancelRequest, setCancelRequest] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const [disableAssignButton, setDisableAssignButton] = useState(true);

    const fetchStationList = () => {
        setSpinnerOff(true);
        setSelectedStations([]);
        setStationList([]);
        setSelectionModel([]);
        let params: ObjectDetails =  {hostId: hostId, groupId: groupId};
        if(vehicleId) {
            params['vehicleId'] = vehicleId;
        }
        fetchFleetAvailableStation(params, cancelRequest, false).then((res: any) => {
            if(res) {
                setStationList(res);
            }
            setSpinnerOff(false);
        }).catch((err: any) => {
            console.error("Error", err);
        });
        setCancelRequest(true);
    };


    useEffect(() => {
        if (assignStation) {
            setDisableAssignButton(true);
            fetchStationList();
        }
    }, [assignStation, props?.groupId]);

    const addStationClose = () => {
        setDisableAssignButton(true);
        setCancelRequest(true);
        props.setAssignStationOpen(false);
    };

    const checkboxClickCallback = (ids: any) => {
        setSelectedStations([]);
        let selectedIDs = new Set(ids);
        let selectedRowData = stationList.filter((row: any) => {
            return selectedIDs.has(row.id);
        });
        if(vehicleId) {
            let length = ids.length;
            setSelectionModel([ids[length - 1]]);
            setSelectedStations([selectedRowData[length - 1]]);
        } else {
            setDisableAssignButton(false);
            setSelectionModel(ids);
            setSelectedStations(selectedRowData);
        }
        let error = stationList.length > 0 && ids.length === 0;
        setDisableAssignButton(error);
    };

    const assignStationToFleet = () => {
        if (stationList.length > 0) {
            if (selectedStations.length > 0) {
                setSaving(true);
                let body: ObjectDetails = {charger_id: selectionModel, fleet_group_id: groupId};
                if (vehicleId) {
                    body.vehicle_id = vehicleId;
                }
                let params = { action: 'createFleetStations'};
                manageFleetData(params, body).then((res: any) => {
                    if (!res.status) {
                        dispatch(
                            showErrorMessages({
                                error: true,
                                message: res.message,
                                type: "error",
                                operation: "",
                            })
                        );
                    }
                    setSaving(false);
                    props.setAssignStationOpen(false);
                    props.setRefreshData();
                }, (error: any) => {
                    setSaving(false);
                    dispatch(
                        showErrorMessages({
                            error: true,
                            message: error.message,
                            type: "error",
                            operation: "",
                        })
                    );
                });
            }
        }
    };


    return (
        <div className="dialog_box">
            <Dialog
                className="dialog_container edit_detail_section xxlg  dialog_container_center chargers"
                open={assignStation}
                onClose={addStationClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{`${t("fleet.assignStation")} ${t('common.to')} ${vehicleId ? t('fleet.vehicles') : t('common.fleet')}`}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button className="btn_white text_btn_theme" onClick={addStationClose}>{t("common.cancelbutton")}</Button>
                        <Button className={`btn_white ${setClassName(false,false,disableAssignButton)}`} onClick={assignStationToFleet} disabled={disableAssignButton}>{t("hostsetting.assign")}</Button>
                    </div>
                </div>
                {saving && (<LinearProgress/>)}
                <DialogContent>
                    <div className="table_scroll assign_stations">
                        <div className={`default_table sorting_table ${vehicleId && ' hide_select_all'}` }>
                            <DataGrid
                                autoHeight
                                vertical-align="top"
                                className="table information_section"
                                rowHeight={59}
                                headerHeight={35}
                                rows={stationList}
                                columns={columnNameTranslate(stationListColumn)}
                                checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={checkboxClickCallback}
                                loading={spinnerOff}
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
};


export default FleetAssignStation;