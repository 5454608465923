import React, {useState,useEffect} from 'react';
import {Grid,Card,CardContent} from '@material-ui/core';
import './depotHealthCard.scss';
import { useTranslation } from "react-i18next";
import {toUpper} from 'lodash';
import {HealthDetails} from 'common/interfaces'

const DepotHealthCard = (props:HealthDetails) => {
    
    const { t } = useTranslation();
    const [progressList, seProgressList] = useState<any>([]);
    
    const depotHealthCount = (detail:HealthDetails) => {
        let totalhealth = (detail?.availablePorts ? detail.availablePorts : 0) + 
        (detail?.chargingPorts ? detail.chargingPorts : 0) + 
        (detail?.offlinePorts ? detail.offlinePorts : 0)+
        (detail?.connectedPorts ? detail.connectedPorts : 0)+
        (detail?.completedPorts ? detail.completedPorts : 0)+
        (detail?.errorPorts ? detail.errorPorts : 0)+
        (detail?.reservedPorts ? detail.reservedPorts : 0)
        return totalhealth;
    }
    
    
    useEffect(() => {
        progressData(props);
    }, [props]);

    const progressData = (response:HealthDetails) => {
        const progressBar =[];
        for (const [key, value] of Object.entries(response)) {
            if (key === 'chargingPorts' ||
                key === 'availablePorts' ||
                key === 'reservedPorts' ||
                key === 'offlinePorts' ||
                key === 'errorPorts' ||
                key === 'completedPorts' ||
                key === 'connectedPorts') {
                const name = key.replace('Ports', '')
                const val: any = value === 0 ? null : value;
                if (val) {
                    const percentage = getPercentage(val,response)
                    progressBar.push({ name, percentage })
                    seProgressList(progressBar)
                }
            }
        }
    }
   
    const getPercentage = (val: number, total: HealthDetails) => {
        let calculateHealth = Math.fround((val / depotHealthCount(total)) * 100);
        if (calculateHealth === 0) {
            calculateHealth = 1;
        }
        return calculateHealth;
    };

    return (
        <>
            <div className="section_title mt-20">
                <Grid container>
                    <Grid item xs={12}>
                        <h3>{props?.depotLabel}</h3>
                    </Grid>
                </Grid>
            </div>
            <Card className="small_card" variant="outlined">
                <CardContent className="card_content">
                    <div className="shift_group_row section_content">
                        <div className="depot_bar">
                            {progressList.map((value: any, index: any) =>
                                <span key={(value.name+'_'+index).toString()} className={value.name}
                                    style={{ width: `calc(${value.percentage}% - 0px)` }}>&nbsp;</span>
                            )}
                        </div>
                        <div className="depot_state ">
                            <Grid container justifyContent="space-between" alignItems="flex-start">
                            <Grid item xs={4} >
                            {depotHealthCount(props) > 0 ?
                            <div className="state">
                                    <div className="state_text">
                                    {toUpper(t("common.total"))}
                                    </div>
                                    <div className="state_number">
                                    {depotHealthCount(props)}
                                    </div>  
                                </div>
                            : '' }
                             </Grid>  

                             <Grid item xs={8} className="evse_health">
                             {props?.availablePorts > 0 ?
                             <div className="state">
                                    <div className="evse_health_bar">
                                        <span className="available" style={{ width: props?.availablePorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.available"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.availablePorts}
                                    </div>
                                </div>
                                : ''}
                                {props?.connectedPorts > 0 ?
                                <div className="state">
                                <div className="evse_health_bar">
                                    <span className="connected" style={{ width: props?.connectedPorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.connected"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.connectedPorts}
                                    </div>
                                </div>
                                : ''}
                                {props?.reservedPorts > 0 ?
                                <div className="state">
                                <div className="evse_health_bar">
                                    <span className="reserved" style={{ width: props?.reservedPorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.reserved"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.reservedPorts}
                                    </div>
                                </div>
                                : ''}
                                {props?.chargingPorts > 0 ?
                                <div className="state">
                                <div className="evse_health_bar">
                                    <span className="charging" style={{ width: props?.chargingPorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.charging"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.chargingPorts}
                                    </div>
                                </div>
                                : ''}
                                {props?.completedPorts > 0 ?
                                <div className="state">
                                <div className="evse_health_bar">
                                    <span className="completed" style={{ width: props?.completedPorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.complete"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.completedPorts}
                                    </div>
                                </div>
                                : ''}
                                {props?.errorPorts > 0 ?
                                <div className="state">
                                <div className="evse_health_bar">
                                    <span className="fault" style={{ width: props?.errorPorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.fault"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.errorPorts}
                                    </div>
                                </div>
                                : ''}
                                {props?.offlinePorts > 0 ?
                                <div className="state">
                                <div className="evse_health_bar">
                                    <span className="offline" style={{ width: props?.offlinePorts + '%'  }}>&nbsp;</span>
                                    </div>
                                    <div className="state_text">
                                    {toUpper(t("evselocations.health.offline"))}
                                    </div>
                                    <div className="state_number">
                                    {props?.offlinePorts}
                                    </div>
                                </div>
                                : ''}
                              </Grid>    
                                
                               
                            </Grid>
                        </div>
                    </div>



                </CardContent>
            </Card>
        </>
    )
}

export default DepotHealthCard;
