import React, { useState } from "react";
import Images from "assets/images";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { toUpper } from 'lodash';
import CommonGoogleMap from 'components/commonGoogleMap';
import { depotGoogleMap, setAddressFormat } from "common";

export const ZoneListColumnsComponent = () => {

    const { t } = useTranslation();
    const zonesListColumns = [
        {
            field: "firstCell",
            headerName: toUpper(t("common.firstcell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "mapDetails",
            headerName: toUpper(t("common.map")),
            sortable: false,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="table_pic listMapThumb">
                        <CommonGoogleMap
                            center={{
                                lat: cellValues?.value?.latitude,
                                lon: cellValues?.value?.longitude,
                            }}
                            zoom={
                                depotGoogleMap.zoom
                            }
                            markers={[
                                {
                                    lat: cellValues?.value?.latitude,
                                    lon: cellValues?.value?.longitude
                                },

                            ]}

                        />
                    </div>
                );
            },
        },
        {
            field: "locationName",
            headerName: toUpper(t("common.locations")),
            sortable: false,
            cellClassName: "",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "address",
            headerName: toUpper(t("common.address")),
            sortable: false,
            hasFocus: false,
            cellClassName: "t",
            className: "port",
            minWidth: 100,
            flex: 0.3,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <span className="line-height">
                        {setAddressFormat(cellValues.value)}
                    </span>
                );
            },
        },
        {
            field: "chargingStations",
            headerName: toUpper(t("common.stations")),
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "totalPorts",
            headerName: toUpper(t("common.ports")),
            sortable: false,
            minWidth: 110,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "numberOfZones",
            headerName: toUpper(t("depotinformation.zones")),
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "lastCell",
            headerName: toUpper(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ]
    return zonesListColumns;
}

export const ZonesAssignStationComponent = () => {
   
    const { t } = useTranslation();
    const [headerCheck, setHeaderCheck] = useState<any>(false);
    const zonesAssignStation = [
        {
            field: "firstCell",
            headerName: toUpper(t("common.firstcell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "name",
            headerName:  toUpper(t("common.stations")),
            sortable: false,
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="table_pic">
                        <img
                            className="mr-5"
                            src={Images.ic_evse_port2}
                            alt="port"
                        />
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: "portCount",
            headerName: toUpper(t("common.ports")),
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "zoneId",
            headerName: '<div className="defult_form">',
            sortable: false,
            width: 50,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                setHeaderCheck(cellValues?.row?.headerCheck)
                return (
                    <div className="defult_form">
                        <div className="form_col checkbox">
                            <Checkbox
                                checked={cellValues?.row?.zoneChecked}
                                color="primary"
                                disabled={cellValues?.row?.zoneDisabled}
                            />
                        </div>
                    </div>
                );
            },
            
            renderHeader: () => (
                <div className="defult_form">
                    <div className="form_col checkbox">
                        <Checkbox checked={headerCheck} color="primary" />
                    </div>
                </div>
            ),
        },
        {
            field: "lastCell",
            headerName: toUpper(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ];

    return zonesAssignStation;
};


export const assignStationColumns = [
    {
        field: "e",
        headerName: "e",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
    {
        field: "stations",
        headerName: "STATIONS",
        sortable: false,
        flex: 0.2,
        minWidth: 100,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="table_pic">
                    <img
                        className="mr-5"
                        src={Images.ic_evse_port2}
                        alt="port"
                    />
                    {cellValues.value}
                </div>
            );
        },
    },
    {
        field: "ports",
        headerName: "PORTS",
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        disableColumnMenu: true,
    },
    {
        field: "checkbox",
        headerName: '<div className="defult_form">',
        sortable: false,
        width: 50,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return (
                <div className="defult_form">
                    <div className="form_col checkbox">
                        <Checkbox defaultChecked disabled  color="primary" />
                    </div>
                </div>
            );
        },
        renderHeader: () => (
            <div className="defult_form">
                <div className="form_col checkbox">
                    <Checkbox defaultChecked color="primary" />
                </div>
            </div>
        ),
    },
    {
        field: "ee",
        headerName: "ee",
        headerClassName: "empty",
        sortable: false,
        hasFocus: false,
        cellClassName: "empty",
        width: 15,
        maxWidth: 25,
        minWidth: 28,
        disableColumnMenu: true,
        renderCell: (cellValues: any) => {
            return <div className="empty">{cellValues.value}</div>;
        },
    },
];
