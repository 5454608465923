import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toUpper} from "lodash";
import {Grid, CardContent, Card, IconButton, CircularProgress} from "@material-ui/core";
import Images from "assets/images";
import {useHistory} from "react-router-dom";
import {GetUrlDetails, BLANK_DATA_PLACEHOLDER, CheckAccess, claims, BLANK, keyCloakConfig} from "common";
import AddFleetVehicle from "../../addFleetVehicle";
import {FLEET_VEHICLE_INFO_PATH} from "pages/fleet/fleetManagementHelper";

const FleetVehicleInformation = (props: any) => {
  const history = useHistory();
  const {t} = useTranslation();
  const {vehicleId} = GetUrlDetails(history, FLEET_VEHICLE_INFO_PATH);
  const [openAddVehicle, setAddVehicleOpen] = useState(false);
  const {getvehicleinfo, vehicleinfo, editableinfo, spinnerOff} = props;
  const showVehicleEditButton = CheckAccess([claims.VEHICLE_UPDATE]);
  const hostId = keyCloakConfig?.tokenParsed?.hostId;

  const addVehicleOpen = () => {
    setAddVehicleOpen(true);
  };

  const addVehicleClose = () => {
    setAddVehicleOpen(false);
  };

  const getVehicleInfo = () => {
    getvehicleinfo();
  };

  return (
    <>
      <div className="information_section mt-40">
        <Grid container spacing={2}>
          <Grid className="mid_section_left" item xs={12} sm={12} md={12} lg={12}>
            <Card variant="outlined" className="basic_card">
              <CardContent>
                {spinnerOff && <CircularProgress className="inner_progress_userGroup" />}
                {/*----Title Start----*/}
                <div className="title_row">
                  <h2>{toUpper(t("fleet.vehicledetail"))}</h2>
                  {showVehicleEditButton && (
                    <div className="action_icon">
                      <IconButton className="top_action_btn" onClick={addVehicleOpen}>
                        <img src={Images.ic_edit} alt="edit" />
                      </IconButton>
                    </div>
                  )}
                  <div className="clearfix"></div>
                </div>

                {/*----Title End----*/}

                {/*----Information Start----*/}

                <div className="info_detail">
                  <Grid container>
                    <Grid className="" item xs={12}>
                      <div className="tb_info_section mt-13">
                        <div className="row">
                          <span className="label">{t("fleet.vehiclename")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => <span className="result" key={value.id}>{value?.vehicleName}</span>)}
                        </div>
                        <div className="row">
                          <span className="label">{t("fleet.vin")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => (
                              <span className="result" key={value.id}>{value?.vin ? value?.vin : BLANK_DATA_PLACEHOLDER}</span>
                            ))}
                        </div>
                        <div className="row">
                          <span className="label">{t("common.make")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => <span className="result" key={value.id}>{value?.make}</span>)}
                        </div>
                        <div className="row">
                          <span className="label">{t("common.model")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => <span className="result" key={value.id}>{value?.model}</span>)}
                        </div>
                        <div className="row">
                          <span className="label">{t("fleet.year")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => <span className="result" key={value.id}>{value?.year}</span>)}
                        </div>

                        <div className="row">
                          <span className="label">{t("fleet.efficiency")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => (
                              <span className="result" key={value.id}>
                                {value?.milege
                                  ? Number(value?.milege) + BLANK + t("fleet.efficiency.description")
                                  : BLANK_DATA_PLACEHOLDER}
                              </span>
                            ))}
                        </div>
                        <div className="row">
                          <span className="label">{t("common.fleet")}</span>
                          {vehicleinfo &&
                            vehicleinfo?.map((value: any) => (
                              <span className="result" key={value.id}>
                                {value?.fleet_groups_name ? value?.fleet_groups_name : BLANK_DATA_PLACEHOLDER}
                              </span>
                            ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/*----Information End----*/}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <AddFleetVehicle
        openAddVehicle={openAddVehicle}
        addVehicleClose={addVehicleClose}
        hostId={hostId}
        edit={true}
        vehicleInfo={editableinfo}
        vehicleId={vehicleId}
        dataRefreshFunction={getVehicleInfo}
        locationName={vehicleinfo[0]?.div_name}
        groupName={vehicleinfo[0]?.fleet_groups_name}
        addGroupVehicleStatus={false}
        hostUserId={vehicleinfo[0]?.host_user_id}
        setGroupId={props?.setGroupId}
      />
    </>
  );
};

export default FleetVehicleInformation;
