import {MapDetails} from 'common/interfaces';

export const getMapOptions = (maps: any) => {
    return {
        streetViewControl: false,
        scaleControl: true,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.TOP_LEFT,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },
        zoomControl: true,
        clickableIcons: true,
        controlSize: 25
    };
}

export const getMyOptions = (maps:MapDetails) =>{
    return {
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_LEFT,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },
        fullscreenControl:true,
        fullscreenControlOptions:{
            position: maps.ControlPosition.BOTTOM_RIGHT,

        },
        zoomControl: false,
        clickableIcons: false,
        controlSize: 25
    };
}

export const getAnotherOptions = (maps:MapDetails) =>{
    return {
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_LEFT,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },
        fullscreenControl:true,
        fullscreenControlOptions:{
            position: maps.ControlPosition.UPPER_RIGHT,

        },
        zoomControl: true,
        clickableIcons: false,
        controlSize: 25
    };
}

export const getAutoSearchMapOptions = (maps: MapDetails) => {
    return {
        streetViewControl: false,
        scaleControl: true,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        fullscreenControl:false,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_LEFT,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },
        zoomControl: true,
        clickableIcons: true,
        controlSize : 25
    };
}
