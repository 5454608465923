import { IconButton } from "@material-ui/core";
import Images from "assets/images";
import { CheckAccess, claims, renderCellExpand } from "common";
import React from "react";
import { useTranslation } from "react-i18next";

export const FleetVehicleColumns = () => {
    
    return [
        {
            field: "firstCell",
            headerName: "firstcell",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "vehicleName",
            headerName: "fleet.vehiclename",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "vin",
            headerName: "fleet.vin",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "provider",
            headerName: "fleet.api_provider",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "make",
            headerName: "common.make",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "model",
            headerName: "common.model",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "year",
            headerName: "fleet.year",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "milege",
            headerName: "fleet.rangecaps",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            type: "number",
            minWidth: 100,
            flex: 0.4,
            align: 'left',
            headerAlign: 'left',
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "pass_nos",
            headerName: "fleet.auth_card",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "fleet_groups_name",
            headerName: "common.fleet",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return renderCellExpand(cellValues, cellValues.value);
            },
        },
        {
            field: "lastCell",
            headerName: "common.lastcell",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        }
    ];
}

export const GroupVehicleColumnsComponent = () => {
    const { t } = useTranslation();
    const showRemoveVehicleButton = CheckAccess([claims.VEHICLE_REMOVE]);
    return [
        {
            field: "vehicleName",
            headerName: "fleet.vehiclename",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCellExpand: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "vin",
            headerName: "fleet.vin",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCellExpand: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "make",
            headerName: "fleet.make",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCellExpand: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "model",
            headerName: "fleet.model",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            disableColumnMenu: true,
            renderCellExpand: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "year",
            headerName: "fleet.make_model_year",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            type: "number",
            align:"left",
            headerAlign: 'left',
            disableColumnMenu: true,
            renderCellExpand: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "milege",
            headerName: "fleet.rangecaps",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.4,
            type: "number",
            align:"left",
            headerAlign: 'left',
            disableColumnMenu: true,
            renderCellExpand: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },

        {
            field: "Delete",
            headerName: "",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            width: 100,
            flex: 0.2,
            align: "center",
            hide: !showRemoveVehicleButton,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="cancel-row mt-20">
                        <img src={Images.ic_close} />
                    </div>
                );
            },
        },
    ];
};

export const VehicleAuthCardColComponent = () => {
    const showRemoveAuthCardAddButton = CheckAccess([claims.VEHICLE_REMOVE]);
    return [
        {
            field: "pass_no",
            headerName: "fleet.cardnumber",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "assigned_date",
            headerName: "fleet.assignedon",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },        
        {
            field: "status",
            headerName: "common.status",
            headerClassName: "action_columns",
            cellClassName: "cellValue",
            sortable: true,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="cellValue">{cellValues.value}</div>;
            },
        },
        {
            field: "Delete",
            headerName: "",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            width: 10,
            flex: 0.1,
            align: "right",
            hide: !showRemoveAuthCardAddButton,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div>
                        {cellValues?.row?.status === "ACTIVE" && (
                            <IconButton className="top_action_btn">
                                <img
                                    src={
                                        Images.ic_close
                                    }
                                />
                            </IconButton>
                        )}
                    </div>

                );
            },
        }
    ];
};
