import { Paper, Popper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { DataGridCell } from "common/interfaces"
import React, { useEffect, useRef, useState } from "react";
import './dataGridCellExpand.scss';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            },
        },
    }),
);

const DataGridCellExpand = (props: DataGridCell) => {
    const { width, value, render, itmeOverflow = false, setStyle = '' } = props;
    const setRender = (render ? render : value);
    const displayValue = (itmeOverflow === true) ? value : setRender;
    const wrapper = useRef<HTMLDivElement | null>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const isOverflown = (element: Element): boolean => {

        return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth ||
            itmeOverflow === true
        );
    }

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        const handleKeyDown = (nativeEvent: KeyboardEvent) => {
            if(nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={`hovered_item ${setStyle}`}
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0
                }}
            />
            <div ref={cellValue} className={`cellValue ${setStyle}`}>
                { render ? render : value }
            </div>
            { showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, zIndex: 1300, marginLeft: -17, wordWrap: 'break-word' }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight -3 }}
                    >
                        <Typography variant="body2" className={`${setStyle}`} style={{ padding: 8 }}>
                            { displayValue }
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );

}

export default DataGridCellExpand;