import React, { useState, useEffect, useRef } from 'react';
import { GetUrlDetails, DEFAULT_CLOSE, DEFAULT_OPEN } from "common";
import { useHistory } from "react-router-dom";
import { putMethod, ApiEndPoint, postMethod } from "services";
import { convertTime12to24, isHoursOperation} from "common/methodHelpers";
import { Dialog } from '@material-ui/core';
import EditLocationPermission from './editLocationPermission';
import SimpleReactValidator from "simple-react-validator";
import EditLocationContact from './editLocationContact';
import EditOperationalHours from './editOperationalHours';

const EditDepotInformation = (props: any) => {
    const history = useHistory();
    const { locationId } = GetUrlDetails(history, 'locationId');
    const [weekArrayState, setWeekArrayState] = useState([]);
    const [initialDetails, setInitialDetails] = useState<any>();
    const [fleetContact, setFleetContact] = useState<any>();
    const simpleValidator = useRef(new SimpleReactValidator());
    const tempWeekState = useRef();
    const DEFAULT_LOCATION_PERMISSION = "locPermission";
    const DEFAULT_CONTACT = "contact";
    const DEFAULT_OPERATION_HOURS = "operationalHours";

    const initialData = async () => {
        let initialDta = {
          name: props.depotDetailsData?.name,
          organizationName: props.depotDetailsData?.organizationName,
          isPublic: props.depotDetailsData?.isPublic,
        };
        setInitialDetails(initialDta);
      };

      const makeContactData = async () => {
        let contactInfo = {
          id: props.contactInfo?.id,
          firstName: props.contactInfo?.firstName,
          lastName: props.contactInfo?.lastName,
          phone: props.contactInfo?.phone,
          mobile: props.contactInfo?.mobile,
          fax: props.contactInfo?.fax,
          email: props.contactInfo?.email,
          notes: props.contactInfo?.notes,
        };
        setFleetContact(contactInfo);
      };

      const makeHoursData = async () => {
        const tempOpenData = props?.depotDetailsData?.locationScheduleInfoDTO?.map(
          (ele: any) => {
            if (ele.startTime && ele.startTime.length === 3) {
              ele.startTime = 0 + ele.startTime;
            }
            if (ele.endTime && ele.endTime.length === 3) {
              ele.endTime = 0 + ele.endTime;
            }
            let startingTime: string;
            if (!ele.isOpen && ele.isOpen !== null) {
              startingTime = DEFAULT_CLOSE;
            } else if (ele.isOpen) {
              startingTime = DEFAULT_OPEN;
            } else {
              startingTime = isHoursOperation(ele.startTime);
            }
            return {
              ...ele,
              startTime: startingTime,
              endTime: isHoursOperation(ele.endTime),
            };
          }
        );
        tempWeekState.current = JSON.parse(JSON.stringify(tempOpenData))
        setWeekArrayState(tempOpenData);
      };

      const getHoursDetails = () => {
        const tempArr = weekArrayState.map((ele: any) => {
          return {
            ...ele,
            startTime:
              ele.startTime === DEFAULT_CLOSE || ele.startTime === DEFAULT_OPEN
                ? ele.startTime
                : convertTime12to24(ele.startTime),
            endTime:
              ele.startTime === DEFAULT_CLOSE || ele.startTime === DEFAULT_OPEN
                ? ele.endTime
                : convertTime12to24(ele.endTime),
          };
        });
    
        return tempArr;
      };

      const submitEditForm = () => {
        if (simpleValidator.current.allValid()) {
            postData();
        }
        else {
            simpleValidator.current.showMessages();
        }
    };

    const postData = async () => {
        const locationSchedule = getHoursDetails();
        locationSchedule.forEach((ele) => {
          if (ele.startTime === DEFAULT_CLOSE) {
            ele.isOpen = false;
            ele.startTime = "00:00";
            ele.endTime = "00:01";
          } else if (ele.startTime === DEFAULT_OPEN) {
            ele.isOpen = true;
            ele.startTime = "00:00";
            ele.endTime = "23:59";
          } else {
            ele.isOpen = undefined;
          }
        });

        let paramObj = {
            id: locationId,
            name: initialDetails.name,
            addressId: locationId,
            organizationName: initialDetails.organizationName,
            organizationId: props.depotDetailsData.organizationId,
            isPublic: initialDetails.isPublic,
            address: props.depotDetailsData?.address,
            locationScheduleInfoDTO: locationSchedule,
            description: props.depotDetailsData?.description,
            notes: props.depotDetailsData?.notes,
        }

        const url = ApiEndPoint.locationData.replace("{LOCATION-ID}", locationId);
        const response = await putMethod(url, "", paramObj);
        if (response.status === 200) {
          props?.locationPermissionClose();
          let contactUrl;
          let contactResponse;
          let obj = {
            ...fleetContact,
          };
          if (props.contactInfo && Object.keys(props.contactInfo).length) {
            contactUrl = ApiEndPoint.updateLocationContactInformation.replace(
              "{CONTACT-ID}",
              props.contactInfo.id
            );
            contactResponse = await putMethod(contactUrl, "", obj);
          } else {
            obj.id = locationId;
            obj.locationId = locationId;
            obj.fleetId = locationId;
            obj.organizationId = props.depotDetailsData.organizationId;
            contactUrl = ApiEndPoint.saveLocationContactInformation.replace(
              "{LOCATION-ID}",
              locationId
            );
            contactResponse = await postMethod(contactUrl, "", obj);
          }
          if (contactResponse.status === 200) {
            props.getContactInformation();
            props.getHostLocations(locationId);
            props.organizationDetailclose();
          } else {
            console.error(response.data);
          }
        } else {
          console.error(response.data);
        }
      };
      useEffect(() => {
        makeHoursData();
        initialData();
    }, [props?.depotDetailsData, props?.openLocationPermission]);

    useEffect(() => {
        makeContactData();
    }, [props?.contactInfo, props?.openLocationPermission])

  return (
    <div>
         <div className="dialog_box">
            <Dialog
                className="dialog_container edit_detail_section edit-user-info updated_pop_up sm_450 dialog_container_center"
                {...props}
                open={props?.openLocationPermission}
                onClose={props?.locationPermissionClose}
                scroll={props?.scrollLocationPermission}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                    {props?.editType === DEFAULT_LOCATION_PERMISSION &&
                        <EditLocationPermission
                            locationPermissionClose={props?.locationPermissionClose}
                            depotDetailsData={props?.depotDetailsData}
                            initialDetails={initialDetails}
                            setInitialDetails={setInitialDetails}
                            submitEditForm={submitEditForm}
                            getHostLocations={props.getHostLocations}
                            contactInfo={props.contactInformation}
                            getContactInformation={props.getContactInformation}
                        />
                    }
                    {props?.editType === DEFAULT_CONTACT &&
                        <EditLocationContact
                            locationContactClose={props?.locationPermissionClose}
                            depotDetailsData={props.depotDetailsData}
                            contactInfo={props?.contactInfo}
                            infoDetails={props?.infoDetails}
                            getLocationData={props?.getLocationData}
                            getContactInformation={props?.getContactInformation}
                            fleetContact={fleetContact}
                            setFleetContact={setFleetContact}
                            submitEditForm={submitEditForm}
                            getHostLocations={props?.getHostLocations}
                            makeContactData={makeContactData}
                        />
                    }
                    {props?.editType === DEFAULT_OPERATION_HOURS &&
                        <EditOperationalHours
                            operationalHoursClose={props?.locationPermissionClose}
                            depotDetailsData={props?.depotDetailsData}
                            weekArrayState={weekArrayState}
                            setWeekArrayState={setWeekArrayState}
                            submitEditForm={submitEditForm}
                            tempWeekState={tempWeekState}
                            contactInfo={props.contactInformation}
                            getContactInformation={props?.getContactInformation}
                            getHostLocations={props?.getHostLocations}
                            makeHoursData={makeHoursData}
                        />
                    }
            </Dialog>
         </div>
    </div>
  )
}

export default EditDepotInformation