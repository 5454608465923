export const dateFormats: any= {
    dateTimeFormat: "MMM D, YYYY, h:mm A",
    dateFormmat: 'YYYY-MM-DD',
    chargeTimeFormat: 'YYYY-MM-DD hh:mm:ss',
    dayTypeFormat: 'd',
    timeFormat: 'h:mm A',
    hourTypeFormat: 'hours',
    check24TimeFormat: '12:00 AM',
    set24TimeFormat: '11:59 PM',
    check12TimeFormat: "00:00",
    set12TimeFormat: "23:59",
    chargingTimeFormat: "HH:mm:ss",
    infoTimeFormat: "h:mm:ss",
    check12HourFormat: "hA",
    charger24TimeFormat: 'YYYY-MM-DD HH:mm:ss',
    MonthdateFormat:"MMM D, YYYY",
    check12HourSpaceFormat: "h A",
    csvTimeFormat: "MMDDYYYYHHmmss",
    defaultDateTimeFormat:'YYYY-MM-DD hh:mm:ss A zz'
  }
