import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import { CsvFileDownload } from "common/interfaces";
import { t } from "i18next";

const CommonDownloadCsv = (props: CsvFileDownload) => {
    const { datas = [], headers = undefined, filename = "" ,disableValue = false} = props;
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    
    const callDwonloadCsvFile = async () => {
        await Promise.resolve(props?.callBackDownloadCsv());
        csvLinkRef?.current?.link.click();
    };
    
    return (
        <>
            {
                props.children ? 
                <div onClick={callDwonloadCsvFile}>
                    {props.children}
                </div> : <Button
                    className={`btn_white ${props?.blueclass}`}
                    onClick={callDwonloadCsvFile}   
                    disabled={disableValue}                 
                >
                    {t("common.downloadcsv")}
                </Button>
            }
            <CSVLink
                className="csv_download"
                data={datas}
                headers={headers}
                filename={filename}
                ref={csvLinkRef}
                target="_blank"
            ></CSVLink>
        </>
    );
};

export default CommonDownloadCsv;
