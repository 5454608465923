import React, { useState, useEffect } from 'react';
import Images from 'assets/images';
import { pageDetails } from 'common';

const CommonPagination = (props: any) => {
  const pageSize = props?.pageSize ? props.pageSize : pageDetails.pageSize;
  const [lastPage, setLastPage] = useState(0);
  const currentPage = props?.currentPage;
  const totalCount = props?.totalCount;

  useEffect(() => {
    if (totalCount > 0) {
      setLastPage(Math.ceil(totalCount / pageSize))
    }
  }, [totalCount])

  const handleMoveStart = () => {
    props?.callBackCurrentPage(0);
  }

  const handleMoveLeft = () => {
    if (currentPage > 0) {
      props?.callBackCurrentPage(currentPage - 1);
    }
  }

  const handleMoveRight = () => {
    let totalPage = totalCount ? totalCount : 0;
    if (totalPage > 0) {
      setLastPage(Math.ceil(totalPage / pageSize))
      if ((currentPage + 1) < Math.ceil(totalPage / pageSize)) {
        props?.callBackCurrentPage(currentPage + 1)
      }
    }
  }

  const handleMoveEnd = () => {
    props?.callBackCurrentPage(lastPage ? lastPage - 1 : 0);
  }


  return (
    <>
      {totalCount &&
        totalCount > pageSize ? (
        <div className="pagination" data-testid="pagination_component">
        {currentPage && currentPage >= 1 ? (
          <>
          <span onClick={handleMoveStart}>
            <img src={Images.ic_first_page} alt="first page" />
          </span>
          <span onClick={handleMoveLeft}>
            <img src={Images.ic_chevron_left} alt="left page" />
          </span>
          </>
           ):(
          <></>
        )}
          <span>{currentPage + 1}</span>
          {currentPage >= 0 &&
            currentPage + 1 < lastPage ? (
            <>
              <span onClick={handleMoveRight}>
                <img src={Images.ic_chevron_right} alt="right page" />
              </span>
              <span onClick={handleMoveEnd}>
                <img src={Images.ic_last_page} alt="last page" />
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default CommonPagination;