import environment from "../../environment";
import {commonRoutes} from "../../common";

export const trainingUrl = environment.staticSiteUrl + '/trainings/';

export const TRAINING_IMAGES: any = {
    CLICK_MENU : trainingUrl + 'training_clickmenu.png',
    CLICK_ANY_QUESTION : trainingUrl + 'training_clickanyquestion.png',
    STEP_BY_STEP : trainingUrl + 'training_stepbystep.png',
    CLICK_BLINK_CARD : trainingUrl + 'training_blink_click_blink.png',
    BLINK_REQUEST_CARD : trainingUrl + 'training_blink_requestcard.png',
    BLINK_FILL_FORM : trainingUrl + 'training_blink_fillform.png',
    BLINK_SAVE_BUTTON : trainingUrl + 'training_blink_savebutton.png',
    LIST_BLINK_CARD : trainingUrl + 'training_blink_listblinkcard.png',
    HOST_SETTING_MENU : trainingUrl + 'training_host_setting_menu.png',
    HOST_EVSE_LOCATION : trainingUrl + 'training_host_evselocation.png',
    HOST_LOCATION_LIST: trainingUrl + 'training_host_evselocation.png',
    HOST_CREATE_ZONE: trainingUrl + 'training_host_create_zone.png',
    HOST_FILL_FORM: trainingUrl + 'training_host_fill_form.png',
    HOST_CLICK_NEXT: trainingUrl + 'training_host_click_next.png',
    HOST_SELECT_STATION: trainingUrl + 'training_host_select_station.png',
    HOST_STATION_NEXT: trainingUrl + 'training_host_assign_station_next.png',
    HOST_DROW_MAP: trainingUrl + 'training_host_drowmap.png',
    HOST_DROW_MAP_SAVE: trainingUrl + 'training_host_drowmap_save.png',
    HOST_SAVE_ZONE: trainingUrl + 'training_host_save_zone.png',
    HOST_ZONE_CREATED: trainingUrl + 'training_host_zonecreated.png',
    EVSE_INFO_SELECT_MENU: trainingUrl + 'training_evseinfo_selectmenu.png',
    EVSE_INFO_CLICK_LOCATION: trainingUrl + 'training_evseinfo_clicklocation.png',
    HOST_PORT_LIST: trainingUrl + 'training_host_port_list.png',
    STATION_EDIT: trainingUrl + 'training_station_edit.png',
    RENAME_STATION_NAME: trainingUrl + 'training_rename_stationname.png',
    STATION_INFO: trainingUrl + 'training_station_info.png',
    CREAT_DELEGATE: trainingUrl + 'training_createdelegate.png',
    HOST_DELEGATE_INFO: trainingUrl + 'training_host_setting_delegate_info.png',
    HOST_SAVE_DELEGATES: trainingUrl + 'training_host_setting_savedelegates.png',
    EVSE_SELECT_MENU: trainingUrl + 'training_evseinfo_selectmenu.png',
    EVSE_INFO_CLICK_MORE: trainingUrl + 'training_evseinfo_clickmoreinfo.png',
    EVSE_INFO_EDIT: trainingUrl + 'training_evseinfo_edit.png',
    EVSE_INFO_UPDATE: trainingUrl + 'training_evseinfo_update.png',
    EVSE_INFO_CLICKLOCATION: trainingUrl + 'training_evseinfo_clicklocation.png',
    EVSE_INFO_CLICKMOREINFO: trainingUrl + 'training_evseinfo_clickmoreinfo.png',
    EVSE_VIEW_INFO: trainingUrl + 'training_evseinfo_viewinfo.png',
    EVSE_LOCATION: trainingUrl + 'training_evselocation.png',
    EVSE_ANY_LOCATION: trainingUrl + 'training_evseanylocation.png',
    USAGE_RATE_TAB: trainingUrl + 'training_usageratetab.png',
    REAL_TIME_CALENDER: trainingUrl + 'training_realtimecalender.png',
    SET_RATE_TIME: trainingUrl + 'training_setratetime.png',
    RESET_SCHEDULE: trainingUrl + 'training_resetschedule.png',
    ADJUST_RATE: trainingUrl + 'training_adjustrates.png',
    UPDATE_LOCATION_RATE: trainingUrl + 'training_updatelocationrate.png',
    APPY_CHANGES_FEE: trainingUrl + 'training_applychangesfee.png',
    CHARGE_FEE_UPDATE: trainingUrl + 'training_chargefeeupdated.png',
    CHARGE_HISTORY: trainingUrl + 'training_chargehistory.png',
    CHARGE_HISTORY_FEE: trainingUrl + 'training_chargehistorycsv.png',
    SAVE_CSV_FILE: trainingUrl + 'training_savecsvfile.png',
    STATEMENT: trainingUrl + 'training_statement.png',
    MONTHLY_STATEMENT: trainingUrl + 'training_monthlystatement.png',
    SAVE_FILE: trainingUrl + 'training_savefile.png',
    INFORMATION: trainingUrl + 'training_information.png',
    HOST_DEATIL_EDIT: trainingUrl + 'training_hostdetailedit.png',
    UPDATE_EVSE_INFORMATION: trainingUrl + 'training_updateevseinformation.png',
    EVSE_INFO_UPDATED: trainingUrl + 'training_evseinfo_updated.png',
    EVSE_INFO_SAVE: trainingUrl + 'evse_info_save.png',
    CHARGER_RATE_SETTING: trainingUrl + 'training_chargeratesetting.png',
    DELEGATE_LIST: trainingUrl + 'training_delegatelist.png',
    EDIT_LOCATION_PERMISSION: trainingUrl + 'training_editlocationpermission.png',
    ASSIGN_UNASSIGN_DELEGATE_PERMISSION: trainingUrl + 'training_assignandunassigndelegatepermission.png',
    ADD_LOCATION_SAVE: trainingUrl + 'training_addlocationsave.png',
    LOCATION_PERMISSION_UPDATED: trainingUrl + 'training_locationpermissionupdated.png',
    LOCATION_HOUR_INFORMATION: trainingUrl + 'training_locationhourinformation.png',
    UPDATE_EVSE_LOCATION_HOUR_INFORMATION: trainingUrl + 'training_updateevselocationhourinformation.png',
    HOSTSETTING_USERGROUP_TAB: trainingUrl + 'training_hostsettingusergrouptab.png',
    HOSTSETTING_USERGROUP_BUTTON: trainingUrl + 'training_hostsettingusergroupbutton.png',
    HOSTSETTING_CREATE_NEWUSERGROUP: trainingUrl + 'training_hostsettingcreatenewusergroup.png',
    HOSTSETTING_CREATE_BUTTON: trainingUrl + 'training_hostsettingcreatebutton.png',
    MANAGE_USER_BUTTON: trainingUrl + 'training_manageuser.png',
    WHERE_ADD_NEW_MEMBER: trainingUrl + 'training_whereyouaddnewmember.png',
    MANAGE_ADD_USER: trainingUrl + 'training_manageadduser.png',
    MANAGE_ADD_USER_POPUP: trainingUrl + 'training_manageadduserpopup.png',
    ADD_MULTIPLE_MEMBER: trainingUrl + 'training_adddmultiplemembers.png',
    SERVICE_DISCOUNT_FEE: trainingUrl + 'training_set_discounted_fee.png',
    BLINK_CARD_REQUEST_TAB: trainingUrl + 'training_blink_card_request_tab.png',
    POP_EDIT_STATION_INFO: trainingUrl + 'training_popeditstationifo.png',
    STATION_INFO_UPDATE_BUTTON: trainingUrl + 'training_popeditstationifoupdatebutton.png',
    HOSTSETTING_DELEGATE_LIST: trainingUrl + 'training_hostsettingdelegatelist.png',
    MANAGE_USER: trainingUrl + 'training_manageuser.png',
    MANAGE_USER_GROUP: trainingUrl + 'training_manageusergroup.png',
    MANAGE_GROUP_ADD_USER: trainingUrl + 'training_manageusergroup_adduser.png',
    MANAGE_GROUP_ADD_EMAIBLINKCARD: trainingUrl + 'training_manageusergroup_emailorblinkcard.png',
    MANAGE_GROUP_USER_LIST: trainingUrl + 'training_manageusergroup_userlist.png',
    EVSE_LOCATION_CARD: trainingUrl + 'training_evselocation_card.png',
    MEMBERSHIP_REQUEST_VIEW_ALL_BUTTON: trainingUrl + 'manager_users_viewAll.png',
    SELECT_MEMBER_REQUEST_LIST: trainingUrl + 'select_member_Request_list.png',
    SELECT_APPROVE: trainingUrl + 'select_approve_1.png',
    SELECT_REJECT: trainingUrl + 'select_reject.png',
    SELECT_APPROVE_NEXT_BUTTON: trainingUrl + 'select_approve_nextButton.png',
    SELECT_REJECT_NEXT_BUTTON: trainingUrl + 'reject_select_next.png',
    SELECT_GROUP: trainingUrl + 'select_group.png',
    GROUP_CLICK_APPROVE: trainingUrl + 'group_click_approve.png',
    USER_GROUP_LIST: trainingUrl + 'user_group_list.png',
    USER_GROUP_LIST_ADD_USER: trainingUrl + 'user_group_list_add_user.png',
    ADD_NEW_USER: trainingUrl + 'add_new_user.png',
    ADD_NEW_USER_SAVE: trainingUrl + 'add_new_user_save.png',
    CONFIRM_REJECT: trainingUrl + 'confirm_reject.png',
    REJECT_USER: trainingUrl + 'rejected_user.png',
    EVSE_LOCATION_NEW: trainingUrl + 'host_dashboard.png',
    SELECT_EVSE_LOCATION: trainingUrl + 'host_evse_locations.png',
    LOCATION_USAGE_TAB: trainingUrl + 'select_usage_rate.png',
    USAGE_CREATE_NEW_PLAN: trainingUrl + 'usage_create_new_plan.png',
    CREATE_NEW_PLAN_FORM: trainingUrl + 'create_new_plan_popup.png',
    CREATE_NEW_PLAN_CLICK_NEXT: trainingUrl + 'create_new_plan_next_button.png',
    CREATE_PLAN_SELECT_TYPE: trainingUrl + 'select_pricing_type.png',
    CREATE_PLAN_SET_PARKING_FEES: trainingUrl + 'set_parking_energy_fee.png',
    CREATE_PLAN_SET_ENERGY_FEES: trainingUrl + 'set_energy_fee.png',
    PRICING_PLAN_LIST: trainingUrl + 'pricing_plans_list.png',
    CREATE_PLAN_SET_PARKING_ENERGYFEES_SAVE: trainingUrl + 'set_energyFee_click_Save.png',
    USAGE_TAB_APPLY_PRICING: trainingUrl + 'changeAccess_pricing.png',
    CHANGE_ACCESS_TO_STATION: trainingUrl + 'select_access_toStation.png',
    CHANGE_ACCESS_PRICING: trainingUrl + 'select_pricing_plans.png',
    CHANGE_ACCESS_PRICING_SAVE: trainingUrl + 'changeAcces_pricing_clickApply.png',
    CTEP_CREATE_NEW_PLAN: trainingUrl + 'create_new_plan_popup.png',
    CTEP_CREATE_NEW_PLAN_NEXT: trainingUrl + 'CTEP_clickNext.png',
    CTEP_CREATE_NEW_PLAN_PARKING_FEE: trainingUrl + 'CTEP_configure_ParkingFee.png',
    CTEP_CREATE_NEW_PLAN_ENERGY_FEE: trainingUrl + 'CTEP_configure_energyFee.png',
    MANAGER_USERS_TAB: trainingUrl + 'manager_user_Tab.png',
    HOST_SELECT_STATION_NEW: trainingUrl + 'host_select_station.png',
    HOST_STATION_DISPLAY: trainingUrl + 'host_station_display.png',
    HOST_DISPLAY_TAB: trainingUrl + 'host_station_display_tab.png',
    HOST_DISPLAY_EDIT: trainingUrl + 'host_display_edit_icon.png',
    HOST_DISPLAY_VIEW: trainingUrl + 'host_display_message_window.png',
    HOST_DISPLAY_EDITED: trainingUrl + 'host_display_message_window2.png',
    HOST_DISPLAY_REMOVE: trainingUrl + 'host_display_message_window3.png',
    HOST_DISPLAY_ADD: trainingUrl + 'host_display_message_window4.png',
    HOST_DISPLAY_SAVE: trainingUrl + 'host_display_message_window5.png',
    HOST_DISPLAY_SAVE_ALL_STATIONS: trainingUrl + 'host_display_message_window6.png',
    PRIVATE_ACCESS_VIEW_ALL_BUTTON: trainingUrl + 'view_all_button.png',
    USER_GROUPS: trainingUrl + 'user_groups.png',
    SELECT_USER_GROUP: trainingUrl + 'select_user_group.png',
    SELECT_REQUEST_ACTION: trainingUrl + 'select_request_action.png',
    REQUEST_LIST: trainingUrl + 'request_list.png',
    GROUP_USER_LIST: trainingUrl + 'group_user_list..png',
    CLICK_NEXT_BUTTON: trainingUrl + 'click_next_button.png',
    CLICK_APPROVE_REQUEST: trainingUrl + 'click_approve_request.png',
    APPROVE_REQUEST: trainingUrl + 'approve_request.png',
    DECLINED_USERS: trainingUrl + 'declined_users.png',
    DECLINED_PRIVATE_ACCESS: trainingUrl + 'decline_private_access_request.png',
    DECLINED_NEXT_BUTTON: trainingUrl + 'decline_next_button.png',
    WAITLIST_VIEW_CONFIGURATION: trainingUrl + 'Display_waitlist_configuration.png',
    WAITLIST_EDIT_CONFIGURATION: trainingUrl + 'Edit_configuration.png',
    WAITLIST_EDIT_STATUS: trainingUrl + 'Edit_status_plugTime_limit.png',
    WAITLIST_CLICK_SAVE_BUTTON: trainingUrl + 'Click_save_button.png',
    WAITLIST_TAB: trainingUrl + 'Waitlist_tab.png',
    WAITLIST_MANAGE_STATIONS: trainingUrl + 'Manage_stations.png',
    WAITLIST_ASSIGN_STATION: trainingUrl + 'Assign_station_waitlist.png',
    WAITLIST_ASSIGN_STATION_SAVE_BUTTON: trainingUrl + 'Click_save_changes_button.png',
    DISPLAY_WAITLIST_STATIONS: trainingUrl + 'Display_waitlist_stations.png',
    FLEET_TAB: trainingUrl + 'fleet_tab.png',
    ADD_NEW_FLEET_BUTTON: trainingUrl + 'add_new_fleet_button.png',
    ADD_FLEET_DETAILS: trainingUrl + 'add_fleet_details.png',
    NEW_FLEET_SAVE_BUTTON: trainingUrl + 'Add_new_fleet_popup_save.png',
    SELECT_VEHICLES_TAB: trainingUrl + 'fleet_vehicle_tab.png',
    ADD_NEW_VEHICLES_BUTTON: trainingUrl + 'fleet_add_new_vehicle_button.png',
    FILL_OUT_ADD_NEW_VEHICLE_FORM: trainingUrl + 'add_vehicle_to_fleet_popup.png',
    NEW_VEHICLE_SAVE_BUTTON: trainingUrl + 'add_vehicle_save.png',
    VIEW_VEHICLES: trainingUrl + 'view_fleet_vehicle.png',
    FLEET_CONSTRAINTS_TAB: trainingUrl + 'fleet_constraints.png',
    FLEET_CONSTRAINTS_ENERGY_EDIT_ICON: trainingUrl + 'fleet_constraints_energylevels.png',
    FLEET_CONSTRAINTS_ENERGY_FORM: trainingUrl + 'fleet_energy_levels_savebutton.png',
    FLEET_CONSTRAINTS_ENERGY_FORM_SAVE: trainingUrl + 'fleet_energy_levels_save.png',
    FLEET_CONSTRAINTS_SCHEDULE_EDIT_ICON: trainingUrl + 'fleet_constraints_scheduke.png',
    FLEET_CONSTRAINTS_SCHEDULE_FORM: trainingUrl + 'schedule_constraints_edited.png', 
    FLEET_CONSTRAINTS_SCHEDULE_FORM_SAVE: trainingUrl + 'schedule_constraints_edited_button.png',
    VIEW_FLEET_CONSTRAINTS: trainingUrl + 'view_fleet_constraints.png',
    VEHICLE_CONSTRAINTS_TAB: trainingUrl + 'vehicle_constraints_tab.png',
    VEHICLE_CONSTRAINTS_ENERGY_EDIT_ICON: trainingUrl + 'vehicle_edit_energyLevels.png',
    VEHICLE_CONSTRAINTS_ENERGY_FORM: trainingUrl + 'vehicle_configure_energy_levels.png',
    VEHICLE_CONSTRAINTS_ENERGY_FORM_SAVE: trainingUrl + 'vehicle_energyLevel_SaveButton.png',
    VEHICLE_CONSTRAINTS_SCHEDULE_EDIT_ICON: trainingUrl + 'vehicle_edit_schedule.png',
    VEHICLE_CONSTRAINTS_SCHEDULE_FORM: trainingUrl + 'vehicle_configure_schedule.png', 
    VEHICLE_CONSTRAINTS_SCHEDULE_FORM_SAVE: trainingUrl + 'vehicle_configureSchedule_saveButton.png',
    VIEW_VEHICLE_CONSTRAINTS: trainingUrl + 'view_vehicle_constraints.png',
    SELECT_VEHICLE_INFORMATION: trainingUrl + 'vehicle_information_tab.png',
    EDIT_VEHICLE_INFORMATION: trainingUrl + 'edit_vehicle_information.png',
    DELETE_VEHICLE_BUTTON: trainingUrl + 'vehicle_deleteVehicleButton.png',
    VEHICLE_DELETE_BUTTON: trainingUrl + 'vehicle_DeleteButton.png',    
    MANAGE_FLEET: trainingUrl + 'manage_fleet.png',
    FLEET_INFO_TAB: trainingUrl + 'fleet_information_tab.png',
    EDIT_FLEET_INFO: trainingUrl + 'fleet_edit_information.png',
    FLEET_INFO_DELETE: trainingUrl + 'fleet_information_delete_button.png',
    FLEET_INFO_DELETE_CONFIRM: trainingUrl + 'fleet_information_confirm_delete.png',
    FLEET_MANAGEMENT: trainingUrl + 'Manage_fleet_tab.png',
    SELECT_VEHICLE_TAB: trainingUrl + 'vehicle_tab.png',
    SELECT_ADD_NEW_VEHICLE: trainingUrl + 'add_new_vehicle.png',
    ADD_VEHICLE_INFO: trainingUrl + 'add_new_vehicle_form.png',
    SAVE_VEHICLE_INFO: trainingUrl + 'add_new_vehicle_click_save.png',
    VIEW_FLEET_VEHICLES: trainingUrl + 'view_all_Vehicles.png',
    ASSIGN_NEW_CARD: trainingUrl + 'vehicle_assignCard_button.png',
    ADD_CARD_NUMBER: trainingUrl + 'enter_card_number_vehicle.png',
    ASSIGN_BLINK_CARD: trainingUrl + 'assign_blinkCard_assignButton.png',
    VIEW_BLINK_CARDS: trainingUrl + 'veiw_vehicle_blinkCards.png',
    SELECT_EVSE_TAB: trainingUrl + 'vehicle_evse_tab.png',
    ADD_EVSE_STATION: trainingUrl + 'vehicle_assign_station_button.png',
    SELECT_EVSE_STATION: trainingUrl + 'select_vehicle_checkbox.png',
    ASSIGN_EVSE_STATION: trainingUrl + 'assign_evse_button_after_selection.png',
    VIEW_EVSE_STATIONS: trainingUrl + 'view_vehicle_eves.png',
    FLEET_NOTIFICATION_TAB: trainingUrl + 'fleet_notifications_tab.png',
    EDIT_FLEET_NOTIFICATION: trainingUrl + 'edit_fleet_notifications.png',
    FLEET_NOTIFICATION_SELECT: trainingUrl + 'configure_notification_radio_buttons.png',
    FLEET_NOTIFICATION_SAVE: trainingUrl + 'fleet_notifications_saveButton.png',
    FLEET_NOTIFICATION_VIEW: trainingUrl + 'View_updated_notifications.png',
    VEHICLE_NOTIFICATION_TAB: trainingUrl + 'vehicle_notifications_tab.png',
    EDIT_VEHICLE_NOTIFICATION: trainingUrl + 'edit_vehicle_notifications.png',
    VEHICLE_NOTIFICATION_SELECT: trainingUrl + 'set_vehicle_notifications.png',
    VEHICLE_NOTIFICATION_SAVE: trainingUrl + 'vehicle_notifications_SaveButton.png',
    VEHICLE_NOTIFICATION_VIEW: trainingUrl + 'vehicle_view_notifications.png',
    SELECT_FLEET_EVSE_TAB: trainingUrl + 'fleet_EVSE_tab.png',
    VIEW_FLEET_EVSE_STATION: trainingUrl + 'fleet_assign_Stations.png',
    ASSIGN_FLEET_EVSE_STATION: trainingUrl + 'assign_stations_clickAssign.png',
    VIEW_FLEET_ASSIGNED_EVSE_STATIONS: trainingUrl + 'view_assigned_stations.png',
};

export const TRAINING_TYPE: any =  {
    SET_CHARGE_RATE: 'Set Charge Rate',
    DISCOUNTED_CHARGE: 'Discounted Charge',
    EVSE_INFORMATION: 'Evse Information',
    SET_ZONE: 'Set Zone',
    RENAME_STATION_NAME: 'Rename Station Name',
    EVSE_LOCATION_HOUR: 'Evse Location Hour',
    UPDATE_EVSE_LOCATION: 'Update Evse Location',
    BLINK_CARDS: 'Blink Cards',
    NEW_DELEGATE: 'New Delegate',
    UPDATE_DELEGATE_PERMISSIONS: 'Update Delegate Permissions',
    CREATE_USER_GROUP: 'Create User Group',
    MONTHLY_STATEMENT: 'Monthly Statement',
    HOST_TRAINING_MATERIALS: 'Host Training Materials',
    LOCATION_CHARGE_HISTORY: 'Location Charge History',
    SET_CHARGE_RATE_SEMA: 'Set Charge Rate Sema',
    SET_CHARGE_RATE_CTEP_SEMA: 'Set Charge Rate Ctep Sema',
    APPLY_PRICING_TO_LOCATION: 'Apply pricing to location',
    CREATE_CUSTOM_DISPLAY_MESSAGES: 'Create Custom Display Messages',
    ACCEPT_PRIVATE_ACCESS_REQUEST: 'Accept Private Access Request',
    DECLINE_PRIVATE_ACCESS_REQUEST: 'Decline Private Access Request',
    CREATE_USER_GROUP_SEMA: 'Create User Group Sema',
    CONFIGURE_WAITLIST: 'Configure Waitlist',
    FLEET_NOTIFICATIONS: 'Configure Fleet Notifications',
    DELETE_FLEET: 'Delete Fleet',
    ADD_NEW_VEHICLE: 'Add New Vehicle',
    ADD_BLINKCARD_TO_VEHICLE: 'Add Blink Cards To Vehicle',
    ASSIGN_EVSE_TO_VEHICLE: 'Assign EVSE To Vehicle',
    VEHICLE_NOTIFICATIONS: 'Configure Vehicle Notifications',
    ASSIGN_EVSE_TO_FLEET: 'Assign EVSE To Fleet',
    NEW_FLEET: 'New Fleet',
    ADD_VEHICLES_TO_FLEET: 'Add Vehicles To Fleet',
    CONFIGURE_FLEET_SCHEDULE_CONSTRAINTS: 'Configure Fleet Schedule Constraint',
    CONFIGURE_VEHICLE_SCHEDULE_CONSTRAINTS: 'Configure Vehicle Schedule Constraint',
    DELETE_VEHICLE: 'Delete Vehicle',
};

export const HOST_TRAINING_CATEGORY: any = [
    {

        label: 'training.manageevsestation',
        cards: [{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.SET_CHARGE_RATE,
            label: 'training.howdoisetmychargerate',
            description: 'training.chargeratetext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.SET_CHARGE_RATE_SEMA,
            label: 'training.howdoisetmychargeratesema',
            description: 'training.chargerateSematext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.SET_CHARGE_RATE_CTEP_SEMA,
            label: 'training.howdoisetmychargeratesemactep',
            description: 'training.chargerateSemacteptext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.APPLY_PRICING_TO_LOCATION,
            label: 'training.howdoiapplypricingplans',
            description: 'training.applypricingtolocationtext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.DISCOUNTED_CHARGE,
            label: 'training.howdoiaassignuserstodiscountedchargesession',
            description: 'training.chargeseesiontext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.EVSE_INFORMATION,
            label: 'training.howdoseeevseinformation',
            description: 'training.evseinformationtext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.RENAME_STATION_NAME,
            label: 'training.howdoirenamethestationname',
            description: 'training.stationtext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.UPDATE_EVSE_LOCATION,
            label: 'training.howdoupdateevseinformation',
            description: 'training.evselocationinformationtext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.CREATE_CUSTOM_DISPLAY_MESSAGES,
            label: 'training.howdoicreatecustomdisplaymessages',
            description: 'training.customdisplaymessagestext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.CONFIGURE_WAITLIST,
            label: 'training.howdoisetwaitlistforalocation',
            description: 'training.waitlistdisplaytext'
        }
            //{
            //     pathname: commonRoutes.trainingDetailsRoute,
            //     trainingType: TRAINING_TYPE.EVSE_LOCATION_HOUR,
            //     label: 'training.howdochangeevselocationhours',
            //     description: ''
            // }
        ]
    },
    {
        label: 'training.manageusersdelegatesblinkcards',
        cards: [{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.NEW_DELEGATE,
            label: 'training.howdoicreatenewdelegate',
            description: 'training.newdelegatetext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.UPDATE_DELEGATE_PERMISSIONS,
            label: 'training.howdoiupdatedelegatepermissions',
            description: 'training.updatedelegatetext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.CREATE_USER_GROUP,
            label: 'training.howdoicreateusergroupandaddmember',
            description: 'training.addmembertext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.CREATE_USER_GROUP_SEMA,
            label: 'training.howdoicreateusergroupandaddmembersema',
            description: 'training.addmembersematext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.ACCEPT_PRIVATE_ACCESS_REQUEST,
            label: 'training.howdoisacceptmembershiprequest',
            description: 'training.acceptMembershipRequesttext'
        }, {
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.DECLINE_PRIVATE_ACCESS_REQUEST,
            label: 'training.howdoisrejectmembershiprequest',
            description: 'training.rejectMembershipRequesttext'
        }]
    },
    {
        label: 'training.viewchargehistorymonthlystatementsandtrainnewhostportal',
        cards: [{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.LOCATION_CHARGE_HISTORY,
            label: 'training.howdoidownloadlocationscharge',
            description: 'training.locationchargehistorytext'
        },
            // {
            //     pathname: commonRoutes.trainingDetailsRoute,
            //     trainingType: TRAINING_TYPE.MONTHLY_STATEMENT,
            //     label: 'training.howdoiviewmymonthlystatement',
            //     description: ''
            // }, {
            //     pathname: commonRoutes.trainingDetailsRoute,
            //     trainingType: TRAINING_TYPE.HOST_TRAINING_MATERIALS,
            //     label: 'training.howdoiviewhosttrainingmaterials',
            //     description: ''
            // }
        ]
    },
    {
        label: 'training.managefleetvehiclesandschedules',
        cards: [{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.NEW_FLEET,
            label: 'training.howdoiaddnewfleet',
            description: 'training.howdoiaddnewfleetdescription'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.ADD_VEHICLES_TO_FLEET,
            label: 'training.howdoiaddvehiclestofleet',
            description: 'training.howdoiaddvehiclestofleetdescription'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.ASSIGN_EVSE_TO_FLEET,
            label: 'training.howdoiassignevsetofleet',
            description: 'training.assignEvseToFleettext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.CONFIGURE_FLEET_SCHEDULE_CONSTRAINTS,
            label: 'training.howdoiconfigurefleetscheduleconstraints',
            description: 'training.howdoiconfigurefleetscheduleconstraintsdescription'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.FLEET_NOTIFICATIONS,
            label: 'training.howdoiconfigurefleetnotifications',
            description: 'training.configureFleetNotificationstext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.DELETE_FLEET,
            label: 'training.howdoideletefleet',
            description: 'training.deleteFleettext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.ADD_NEW_VEHICLE,
            label: 'training.howdoiaddnewvehicle',
            description: 'training.addNewVehicletext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.ADD_BLINKCARD_TO_VEHICLE,
            label: 'training.howdoiaddblinkcardtovehicle',
            description: 'training.addBlinkCardToVehicletext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.ASSIGN_EVSE_TO_VEHICLE,
            label: 'training.howdoiassignevsetovehicle',
            description: 'training.assignEvseToVehicletext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.CONFIGURE_VEHICLE_SCHEDULE_CONSTRAINTS,
            label: 'training.howdoiconfigurevehiclescheduleconstraints',
            description: 'training.howdoiconfigurevehiclescheduleconstraintsdescription'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.VEHICLE_NOTIFICATIONS,
            label: 'training.howdoiconfigurevehiclenotifications',
            description: 'training.configureVehicleNotificationstext'
        },{
            pathname: commonRoutes.trainingDetailsRoute,
            trainingType: TRAINING_TYPE.DELETE_VEHICLE,
            label: 'training.howdoideleteVehicle',
            description: 'training.howdoideleteVehicleDescription'
        }]
    },
  
];

export const HOST_TRAINING_LAYOUT: any = [
    {
        type: TRAINING_TYPE.HOST_TRAINING_MATERIALS,
        header: {label1: "training.howviewtrainingmaterials", label2: "training.hosttrainingmaterials"},
        steps: [
            {image: TRAINING_IMAGES.CLICK_MENU, description: "training.heading.clickontraining"},
            {image: TRAINING_IMAGES.CLICK_ANY_QUESTION, description: "training.heading.clickanyquestions"},
            {image: TRAINING_IMAGES.STEP_BY_STEP, description: "training.heading.stepscreenflow"}
        ]
    },
    {
        type: TRAINING_TYPE.BLINK_CARDS,
        header: {label1: "training.howdorequestblinkcode", label2: "training.blinkcardtext"},
        steps: [
            {image: TRAINING_IMAGES.CLICK_BLINK_CARD, description: "training.heading.clickblinkcard"},
            {image: TRAINING_IMAGES.BLINK_CARD_REQUEST_TAB, description: "training.heading.blinkcardrequesttab"},
            {image: TRAINING_IMAGES.BLINK_REQUEST_CARD, description: "training.heading.clickrequestblinkcard"},
            {image: TRAINING_IMAGES.BLINK_FILL_FORM, description: "training.heading.filltheform"},
            {image: TRAINING_IMAGES.BLINK_SAVE_BUTTON, description: "training.heading.clicksendbutton"},
            {image: TRAINING_IMAGES.LIST_BLINK_CARD, description: "training.heading.showrequestblinkcard"},
        ]
    },
    {
        type: TRAINING_TYPE.SET_ZONE,
        header: {label1: "training.createzonesatlocation", label2: "training.createzoneatlocation"},
        steps: [
            {image: TRAINING_IMAGES.HOST_SETTING_MENU, description: "training.heading.clickhostsetting"},
            {image: TRAINING_IMAGES.HOST_EVSE_LOCATION, description: "training.heading.clickevseLocationstab"},
            {image: TRAINING_IMAGES.HOST_LOCATION_LIST, description: "training.heading.clickanystation"},
            {image: TRAINING_IMAGES.HOST_CREATE_ZONE, description: "training.heading.clickcreatzone"},
            {image: TRAINING_IMAGES.HOST_FILL_FORM, description: "training.heading.filltheform"},
            {image: TRAINING_IMAGES.HOST_CLICK_NEXT, description: "training.heading.clickonnextbutton"},
            {image: TRAINING_IMAGES.HOST_SELECT_STATION, description: "training.heading.selectthestation"},
            {image: TRAINING_IMAGES.HOST_STATION_NEXT, description: "training.heading.clickonnextbutton"},
            {image: TRAINING_IMAGES.HOST_DROW_MAP, description: "training.heading.drawingpolygonlocation"},
            {image: TRAINING_IMAGES.HOST_DROW_MAP_SAVE, description: "training.heading.clickonnextbutton"},
            {image: TRAINING_IMAGES.HOST_SAVE_ZONE, description: "training.heading.clickonsavetocreatezone"},
            {image: TRAINING_IMAGES.HOST_ZONE_CREATED, description: "training.heading.createzone"},
        ]
    },
    {
        type: TRAINING_TYPE.RENAME_STATION_NAME,
        header: {label1: "training.howdoirenamethestationname", label2: "training.stationtext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION, description: "training.heading.clickevselocationnav"},
            {image: TRAINING_IMAGES.EVSE_INFO_CLICK_LOCATION, description: "training.heading.clickevselocationrow"},
            {image: TRAINING_IMAGES.HOST_PORT_LIST, description: "training.heading.clickportrow"},
            {image: TRAINING_IMAGES.STATION_EDIT, description: "training.heading.stationinfoedit"},
            {image: TRAINING_IMAGES.POP_EDIT_STATION_INFO, description: "training.heading.popeditstationinfochangestationname"},
            {image: TRAINING_IMAGES.STATION_INFO_UPDATE_BUTTON, description: "training.heading.clicktoupdatebuttontorenamestationname"},
            {image: TRAINING_IMAGES.STATION_INFO, description: "training.heading.stationinfo"}
        ]
    },
    {
        type: TRAINING_TYPE.NEW_DELEGATE,
        header: {label1: "training.heading.howcreatedelegate", label2: "training.newdelegatetext"},
        steps: [
            {image: TRAINING_IMAGES.HOST_SETTING_MENU, description: "training.heading.clickhostsettingmenu"},
            {image: TRAINING_IMAGES.CREAT_DELEGATE, description: "training.heading.clickcreatenewdelegate"},
            {image: TRAINING_IMAGES.HOST_DELEGATE_INFO, description: "training.heading.enterdelegateinfo"},
            {image: TRAINING_IMAGES.HOST_SAVE_DELEGATES, description: "training.heading.savedelegateinfo"},
            {image: TRAINING_IMAGES.HOSTSETTING_DELEGATE_LIST, description: "training.heading.delegatelist"}
        ]
    },
    {
        type: TRAINING_TYPE.EVSE_LOCATION_HOUR,
        header: {label1: "training.heading.changeevselocationhours", label2: "training.evselocationhours"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_SELECT_MENU, description: "training.heading.clickevselocationmenu"},
            {image: TRAINING_IMAGES.EVSE_INFO_CLICK_LOCATION, description: "training.heading.clickevselocation"},
            {image: TRAINING_IMAGES.EVSE_INFO_CLICK_MORE, description: "training.heading.clickinfotab"},
            {image: TRAINING_IMAGES.EVSE_INFO_EDIT, description: "training.heading.clickhostdetailediticon"},
            {image: TRAINING_IMAGES.LOCATION_HOUR_INFORMATION, description: "training.heading.popupupdatelocationhourinformation"},
            {image: TRAINING_IMAGES.UPDATE_EVSE_LOCATION_HOUR_INFORMATION, description: "training.heading.clicksavechangesupdateevselocationhour"}
        ]
    },
    {
        type: TRAINING_TYPE.EVSE_INFORMATION,
        header: {label1: "training.heading.evseinformation", label2: "training.evseinformationtext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION, description: "training.heading.clickevselocationmenu"},
            {image: TRAINING_IMAGES.EVSE_INFO_CLICKLOCATION, description: "training.heading.clickevselocation"},
            {image: TRAINING_IMAGES.EVSE_INFO_CLICKMOREINFO, description: "training.heading.clickevsestation"},
            {image: TRAINING_IMAGES.EVSE_VIEW_INFO, description: "training.heading.viewevsestation"}
        ]
    },
    {
        type: TRAINING_TYPE.SET_CHARGE_RATE,
        header: {label1: "training.howdoisetmychargerate", label2: "training.chargeratetext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION, description: "training.heading.clickevselocationsidemenu"},
            {image: TRAINING_IMAGES.EVSE_ANY_LOCATION, description: "training.heading.clickevselocation"},
            {image: TRAINING_IMAGES.USAGE_RATE_TAB, description: "training.heading.clickusageratetab"},
            {image: TRAINING_IMAGES.UPDATE_LOCATION_RATE, description: "training.heading.updatelocationrate"},
            {image: TRAINING_IMAGES.APPY_CHANGES_FEE, description: "training.heading.clickapplychangesfee"},
            {image: TRAINING_IMAGES.CHARGE_FEE_UPDATE, description: "training.heading.chargefeeupdate"}
        ]
    },
    {
        type: TRAINING_TYPE.LOCATION_CHARGE_HISTORY,
        header: {label1: "training.howdoidownloadlocationscharge", label2: "training.locationchargehistorytext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION, description: "training.heading.clickevselocationsidemenu"},
            {image: TRAINING_IMAGES.EVSE_LOCATION_CARD, description: "training.heading.clickevselocationcard"},
            {image: TRAINING_IMAGES.CHARGE_HISTORY, description: "training.heading.clickchargehistorytab"},
            {image: TRAINING_IMAGES.CHARGE_HISTORY_FEE, description: "training.heading.clickchagrehistorycsv"},
            {image: TRAINING_IMAGES.SAVE_CSV_FILE, description: "training.heading.savecsvfilelocalefolder"},
        ]
    },
    {
        type: TRAINING_TYPE.MONTHLY_STATEMENT,
        header: {label1: "training.howdoiviewmymonthlystatement", label2: "training.viewmonthlystatement"},
        steps: [
            {image: TRAINING_IMAGES.STATEMENT, description: "training.heading.clickstatementmenu"},
            {image: TRAINING_IMAGES.MONTHLY_STATEMENT, description: "training.heading.clickmonthlystatement"},
            {image: TRAINING_IMAGES.SAVE_FILE, description: "training.heading.savefilelocalfolder"}
        ]
    },
    {
        type: TRAINING_TYPE.UPDATE_EVSE_LOCATION,
        header: {label1: "training.howdoupdateevseinformation", label2: "training.evselocationinformationtext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION, description: "training.heading.clickevselocationsidemenu"},
            {image: TRAINING_IMAGES.EVSE_ANY_LOCATION, description: "training.heading.clickevselocation"},
            {image: TRAINING_IMAGES.INFORMATION, description: "training.heading.clickoninformationbutton"},
            {image: TRAINING_IMAGES.HOST_DEATIL_EDIT, description: "training.heading.clickonhostdetailediticon"},
            {image: TRAINING_IMAGES.UPDATE_EVSE_INFORMATION, description: "training.heading.updateevseinformation"},
            {image: TRAINING_IMAGES.EVSE_INFO_SAVE, description: "training.heading.clicktosaveevseinformation"},
            {image: TRAINING_IMAGES.EVSE_INFO_UPDATED, description: "training.heading.updatedevseinformation"}
        ]
    },
    {
        type: TRAINING_TYPE.DISCOUNTED_CHARGE,
        header: {label1: "training.howdoiaassignuserstodiscountedchargesession", label2: "training.chargeseesiontext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION, description: "training.heading.clickevselocationsidemenu"},
            {image: TRAINING_IMAGES.EVSE_ANY_LOCATION, description: "training.heading.clickevselocation"},
            {image: TRAINING_IMAGES.USAGE_RATE_TAB, description: "training.heading.clickusageratetab"},
            {image: TRAINING_IMAGES.CHARGER_RATE_SETTING, description: "training.heading.selectdropdownchageratesetting"},
            {image: TRAINING_IMAGES.SERVICE_DISCOUNT_FEE, description: "training.heading.setdicountfee"},
            {image: TRAINING_IMAGES.APPY_CHANGES_FEE, description: "training.heading.clickapplychangesfee"}
        ]
    },
    {
        type: TRAINING_TYPE.UPDATE_DELEGATE_PERMISSIONS,
        header: {label1: "training.howdoiupdatedelegatepermissions", label2: "training.updatedelegatetext"},
        steps: [
            {image: TRAINING_IMAGES.HOST_SETTING_MENU, description: "training.heading.clickhostsettingmenu"},
            {image: TRAINING_IMAGES.DELEGATE_LIST, description: "training.heading.clickondelagtelist"},
            {image: TRAINING_IMAGES.EDIT_LOCATION_PERMISSION, description: "training.heading.clickoneditlocationpermission"},
            {image: TRAINING_IMAGES.ASSIGN_UNASSIGN_DELEGATE_PERMISSION, description: "training.heading.clickonassignandunassigndelegatepermissions"},
            {image: TRAINING_IMAGES.ADD_LOCATION_SAVE, description: "training.heading.clickonsavebutton"},
            {image: TRAINING_IMAGES.LOCATION_PERMISSION_UPDATED, description: "training.heading.locationpermissionisnowupdated"}
        ]
    },
    {
        type: TRAINING_TYPE.CREATE_USER_GROUP,
        header: {label1: "training.howdoicreateusergroupandaddmember", label2: "training.addmembertext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGE_USER, description: "training.heading.clickonmanageuser"},
            {image: TRAINING_IMAGES.MANAGE_USER_GROUP, description: "training.heading.clickonusergroupaddnewmember"},
            {image: TRAINING_IMAGES.MANAGE_GROUP_ADD_USER, description: "training.heading.adduseruploadusermemberuse"},
            {image: TRAINING_IMAGES.MANAGE_GROUP_ADD_EMAIBLINKCARD, description: "training.heading.addnewuseremailorblinkcard"},
            {image: TRAINING_IMAGES.MANAGE_GROUP_USER_LIST, description: "training.heading.manageuserlist"}
        ]
    },
    {
        type: TRAINING_TYPE.SET_CHARGE_RATE_SEMA,
        header: {label1: "training.howdoisetmychargeratesema", label2: "training.chargerateSematext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION_NEW, description: "training.heading.evselocations"},
            {image: TRAINING_IMAGES.SELECT_EVSE_LOCATION, description: "training.heading.selectalocation"},
            {image: TRAINING_IMAGES.LOCATION_USAGE_TAB, description: "training.heading.clickusagerate"},
            {image: TRAINING_IMAGES.USAGE_CREATE_NEW_PLAN, description: "training.heading.createnewplan"},
            {image: TRAINING_IMAGES.CREATE_NEW_PLAN_FORM, description: "training.heading.fillformpricing"},
            {image: TRAINING_IMAGES.CREATE_NEW_PLAN_CLICK_NEXT, description: "training.heading.nextbtn"},
            {image: TRAINING_IMAGES.CREATE_PLAN_SELECT_TYPE, description: "training.heading.selectpricingtype"},
            {image: TRAINING_IMAGES.CREATE_PLAN_SET_PARKING_FEES, description: "training.heading.setparkingfee"},
            {image: TRAINING_IMAGES.CREATE_PLAN_SET_ENERGY_FEES, description: "training.heading.setenergyfee"},
            {image: TRAINING_IMAGES.CREATE_PLAN_SET_PARKING_ENERGYFEES_SAVE, description: "training.heading.savebtn"},
            {image: TRAINING_IMAGES.PRICING_PLAN_LIST, description: "training.heading.pricingplanlist"}
        ]
    },
    {
        type: TRAINING_TYPE.SET_CHARGE_RATE_CTEP_SEMA,
        header: {label1: "training.howdoisetmychargeratesemactep", label2: "training.chargerateSemacteptext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION_NEW, description: "training.heading.evselocations"},
            {image: TRAINING_IMAGES.SELECT_EVSE_LOCATION, description: "training.heading.selectalocation"},
            {image: TRAINING_IMAGES.LOCATION_USAGE_TAB, description: "training.heading.clickusagerate"},
            {image: TRAINING_IMAGES.USAGE_CREATE_NEW_PLAN, description: "training.heading.createnewplan"},
            {image: TRAINING_IMAGES.CTEP_CREATE_NEW_PLAN, description: "training.heading.fillformpricingctep"},
            {image: TRAINING_IMAGES.CTEP_CREATE_NEW_PLAN_NEXT, description: "training.heading.nextbtn"},
            {image: TRAINING_IMAGES.CTEP_CREATE_NEW_PLAN_PARKING_FEE, description: "training.heading.setparkingfeectep"},
            {image: TRAINING_IMAGES.CTEP_CREATE_NEW_PLAN_ENERGY_FEE, description: "training.heading.setenergyfeectep"},
            {image: TRAINING_IMAGES.CREATE_PLAN_SET_PARKING_ENERGYFEES_SAVE, description: "training.heading.savebtn"},
            {image: TRAINING_IMAGES.PRICING_PLAN_LIST, description: "training.heading.pricingplanlist"}
        ]
    },
    {
        type: TRAINING_TYPE.ACCEPT_PRIVATE_ACCESS_REQUEST,
        header: {label1: "training.howdoisacceptmembershiprequest", label2: "training.acceptMembershipRequesttext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGER_USERS_TAB, description: "training.heading.clickmanageusers"},
            {image: TRAINING_IMAGES.PRIVATE_ACCESS_VIEW_ALL_BUTTON, description: "training.heading.clickviewlallbtn"},
            {image: TRAINING_IMAGES.REQUEST_LIST, description: "training.heading.clickeditapprovememberrequest"},
            {image: TRAINING_IMAGES.SELECT_REQUEST_ACTION, description: "training.heading.selectapprove"},
            {image: TRAINING_IMAGES.CLICK_NEXT_BUTTON, description: "training.heading.clicknextbtn"},
            {image: TRAINING_IMAGES.SELECT_USER_GROUP, description: "training.heading.selectgroup"},
            {image: TRAINING_IMAGES.CLICK_APPROVE_REQUEST, description: "training.heading.clickapprovebtn"},
            {image: TRAINING_IMAGES.USER_GROUPS, description: "training.heading.viewapprovememberingroup"},
            {image: TRAINING_IMAGES.GROUP_USER_LIST, description: "training.heading.viewalistofusers"}
        ]
    },
    {
        type: TRAINING_TYPE.DECLINE_PRIVATE_ACCESS_REQUEST,
        header: {label1: "training.howdoisrejectmembershiprequest", label2: "training.rejectMembershipRequesttext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGER_USERS_TAB, description: "training.heading.clickmanageusers"},
            {image: TRAINING_IMAGES.PRIVATE_ACCESS_VIEW_ALL_BUTTON, description: "training.heading.clickviewlallbtn"},
            {image: TRAINING_IMAGES.REQUEST_LIST, description: "training.heading.clickeditrejectmemberrequest"},
            {image: TRAINING_IMAGES.SELECT_REQUEST_ACTION, description: "training.heading.selectreject"},
            {image: TRAINING_IMAGES.DECLINED_NEXT_BUTTON, description: "training.heading.clicknextbtn"},
            {image: TRAINING_IMAGES.DECLINED_PRIVATE_ACCESS, description: "training.heading.clicktherejectbtn"},
            {image: TRAINING_IMAGES.DECLINED_USERS, description: "training.heading.viewrejectmemberlist"}
        ]
    },
    {
        type: TRAINING_TYPE.CREATE_CUSTOM_DISPLAY_MESSAGES,
        header: {label1: "training.howdoicreatecustomdisplaymessages", label2: "training.customdisplaymessagestext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION_NEW, description: "training.heading.evselocations"},
            {image: TRAINING_IMAGES.SELECT_EVSE_LOCATION, description: "training.heading.selectalocation"},
            {image: TRAINING_IMAGES.HOST_SELECT_STATION_NEW, description: "training.heading.stationoverview"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_TAB, description: "training.heading.selectdisplaytab"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_EDIT, description: "training.heading.stationdisplayediticon"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_VIEW, description: "training.heading.displaymessage"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_EDITED, description: "training.heading.updatedisplaymessage"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_ADD, description: "training.heading.addanothermessage"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_REMOVE, description: "training.heading.deletelcdmessage"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_SAVE, description: "training.heading.savelcdmessagebtn"},
            {image: TRAINING_IMAGES.HOST_DISPLAY_SAVE_ALL_STATIONS, description: "training.heading.savetoallstationsatlocation"}
        ]
    },
    {
        type: TRAINING_TYPE.CREATE_USER_GROUP_SEMA,
        header: {label1: "training.howdoicreateusergroupandaddmembersema", label2: "training.addmembersematext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGER_USERS_TAB, description: "training.heading.clickmanageusers"},
            {image: TRAINING_IMAGES.USER_GROUPS, description: "training.heading.clickonsemagroup"},
            {image: TRAINING_IMAGES.USER_GROUP_LIST_ADD_USER, description: "training.heading.adduserbtn"},
            {image: TRAINING_IMAGES.ADD_NEW_USER, description: "training.heading.enteruseremailaddress"},
            {image: TRAINING_IMAGES.ADD_NEW_USER_SAVE, description: "training.heading.savebtn"},
            {image: TRAINING_IMAGES.USER_GROUP_LIST, description: "training.heading.viewmember"}
        ]
    },
    {
        type: TRAINING_TYPE.CONFIGURE_WAITLIST,
        header: {label1: "training.howdoisetwaitlistforalocation", label2: "training.waitlistdisplaytext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION_NEW, description: "training.heading.evselocations"},
            {image: TRAINING_IMAGES.SELECT_EVSE_LOCATION, description: "training.heading.selectalocation"},
            {image: TRAINING_IMAGES.WAITLIST_TAB, description: "training.heading.selectwaitlist"},
            {image: TRAINING_IMAGES.WAITLIST_EDIT_CONFIGURATION, description: "training.heading.clickwaitlistedit"},
            {image: TRAINING_IMAGES.WAITLIST_EDIT_STATUS, description: "training.heading.updatewaitlist"},
            {image: TRAINING_IMAGES.WAITLIST_CLICK_SAVE_BUTTON, description: "training.heading.clickonwaitlistsave"},
            {image: TRAINING_IMAGES.WAITLIST_VIEW_CONFIGURATION, description: "training.heading.viewwaitlistconfiguration"},
            {image: TRAINING_IMAGES.WAITLIST_MANAGE_STATIONS, description: "training.heading.managewaitliststn"},
            {image: TRAINING_IMAGES.WAITLIST_ASSIGN_STATION_SAVE_BUTTON, description: "training.heading.selectstationforwaitlist"},
            {image: TRAINING_IMAGES.DISPLAY_WAITLIST_STATIONS, description: "training.heading.viewassignstationwaitlist"}
        ]
    },
    {
        type: TRAINING_TYPE.APPLY_PRICING_TO_LOCATION,
        header: {label1: "training.howdoiapplypricingplans", label2: "training.applypricingtolocationtext"},
        steps: [
            {image: TRAINING_IMAGES.EVSE_LOCATION_NEW, description: "training.heading.evselocations"},
            {image: TRAINING_IMAGES.SELECT_EVSE_LOCATION, description: "training.heading.selectalocation"},
            {image: TRAINING_IMAGES.LOCATION_USAGE_TAB, description: "training.heading.clickusagerate"},
            {image: TRAINING_IMAGES.USAGE_TAB_APPLY_PRICING, description: "training.heading.clickchangeaccess"},
            {image: TRAINING_IMAGES.CHANGE_ACCESS_TO_STATION, description: "training.heading.selectaccesstostn"},
            {image: TRAINING_IMAGES.CHANGE_ACCESS_PRICING, description: "training.heading.selectpricingplan"},
            {image: TRAINING_IMAGES.CHANGE_ACCESS_PRICING_SAVE, description: "training.heading.clickonapplybtn"},
            {image: TRAINING_IMAGES.PRICING_PLAN_LIST, description: "training.heading.appliedpricingplandisplay"},
        ]
    },
    {
        type: TRAINING_TYPE.NEW_FLEET,
        header: {label1: "training.howdoiaddnewfleet", label2: "training.howdoiaddnewfleetdescription"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.FLEET_TAB, description: "training.heading.fleettab"},
            {image: TRAINING_IMAGES.ADD_NEW_FLEET_BUTTON, description: "training.heading.addnewfleetbutton"},
            {image: TRAINING_IMAGES.ADD_FLEET_DETAILS, description: "training.heading.addfleetdetails"},
            {image: TRAINING_IMAGES.NEW_FLEET_SAVE_BUTTON, description: "training.heading.savebutton"},
            {image: TRAINING_IMAGES.MANAGE_FLEET, description: "training.heading.viewfleets"},
        ]
    },
    {
        type: TRAINING_TYPE.ADD_VEHICLES_TO_FLEET,
        header: {label1: "training.howdoiaddvehiclestofleet", label2: "training.howdoiaddvehiclestofleetdescription"},
        steps: [
            {image: TRAINING_IMAGES.MANAGE_FLEET, description: "training.heading.selectfleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLES_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.ADD_NEW_VEHICLES_BUTTON, description: "training.heading.clickaddnewvehiclesbutton"},
            {image: TRAINING_IMAGES.FILL_OUT_ADD_NEW_VEHICLE_FORM, description: "training.heading.fillouttheaddnewvehicleform"},
            {image: TRAINING_IMAGES.NEW_VEHICLE_SAVE_BUTTON, description: "training.heading.savebutton"},
            {image: TRAINING_IMAGES.VIEW_VEHICLES, description: "training.heading.viewfleetvehicles"},
        ]
    },
    {
        type: TRAINING_TYPE.CONFIGURE_FLEET_SCHEDULE_CONSTRAINTS,
        header: {label1: "training.howdoiconfigurefleetscheduleconstraints", label2: "training.howdoiconfigurefleetscheduleconstraintsdescription"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.FLEET_TAB, description: "training.heading.fleettab"},
            {image: TRAINING_IMAGES.MANAGE_FLEET, description: "training.heading.selectfleet"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_TAB, description: "training.heading.selectconstraintstab"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_ENERGY_EDIT_ICON, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_ENERGY_FORM, description: "training.heading.enterenergylevels"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_ENERGY_FORM_SAVE, description: "training.heading.clickthesavechangesbutton"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_SCHEDULE_EDIT_ICON, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_SCHEDULE_FORM, description: "training.heading.selectcheckboxconfigschedule"},
            {image: TRAINING_IMAGES.FLEET_CONSTRAINTS_SCHEDULE_FORM_SAVE, description: "training.heading.clickthesavechangesbutton"},
            {image: TRAINING_IMAGES.VIEW_FLEET_CONSTRAINTS, description: "training.heading.viewfleetconstraints"}, 
        ]
    },
    {
        type: TRAINING_TYPE.CONFIGURE_VEHICLE_SCHEDULE_CONSTRAINTS,
        header: {label1: "training.howdoiconfigurevehiclescheduleconstraints", label2: "training.howdoiconfigurevehiclescheduleconstraintsdescription"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.selectvehiclefleet"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_TAB, description: "training.heading.selectconstraintstab"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_ENERGY_EDIT_ICON, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_ENERGY_FORM, description: "training.heading.enterenergylevels"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_ENERGY_FORM_SAVE, description: "training.heading.clickthesavechangesbutton"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_SCHEDULE_EDIT_ICON, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_SCHEDULE_FORM, description: "training.heading.selectcheckboxconfigschedule"},
            {image: TRAINING_IMAGES.VEHICLE_CONSTRAINTS_SCHEDULE_FORM_SAVE, description: "training.heading.clickthesavechangesbutton"},
            {image: TRAINING_IMAGES.VIEW_VEHICLE_CONSTRAINTS, description: "training.heading.viewvehicleconstraints"}, 
        ]
    },
    {
        type: TRAINING_TYPE.DELETE_VEHICLE,
        header: {label1: "training.howdoideleteVehicle", label2: "training.howdoideleteVehicleDescription"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.selectvehiclefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_INFORMATION, description: "training.heading.selectinformationtab"},
            {image: TRAINING_IMAGES.EDIT_VEHICLE_INFORMATION, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.DELETE_VEHICLE_BUTTON, description: "training.heading.clickthedeletevehiclebutton"},
            {image: TRAINING_IMAGES.VEHICLE_DELETE_BUTTON, description: "training.heading.clickthedeletebutton"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.deletednolongervehicle"},
            ]
    },
    {
        type: TRAINING_TYPE.FLEET_NOTIFICATIONS,
        header: {label1: "training.howdoiconfigurefleetnotifications", label2: "training.configureFleetNotificationstext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGE_FLEET, description: "training.heading.selectfleet"},
            {image: TRAINING_IMAGES.FLEET_NOTIFICATION_TAB, description: "training.heading.selectnotificationtab"},
            {image: TRAINING_IMAGES.EDIT_FLEET_NOTIFICATION, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.FLEET_NOTIFICATION_SELECT, description: "training.heading.selectnotificationonoff"},
            {image: TRAINING_IMAGES.FLEET_NOTIFICATION_SAVE, description: "training.heading.clickthesavechangesbutton"},
            {image: TRAINING_IMAGES.FLEET_NOTIFICATION_VIEW, description: "training.heading.viewnotificationsettings"},
        ]
    },
    {
        type: TRAINING_TYPE.DELETE_FLEET,
        header: {label1: "training.howdoideletefleet", label2: "training.deleteFleettext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGE_FLEET, description: "training.heading.selectfleet"},
            {image: TRAINING_IMAGES.FLEET_INFO_TAB, description: "training.heading.selectinformationtab"},
            {image: TRAINING_IMAGES.EDIT_FLEET_INFO, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.FLEET_INFO_DELETE, description: "training.heading.clickdeletefleetbutton"},
            {image: TRAINING_IMAGES.FLEET_INFO_DELETE_CONFIRM, description: "training.heading.clickthedeletebutton"},
        ]
    },
    {
        type: TRAINING_TYPE.ADD_NEW_VEHICLE,
        header: {label1: "training.howdoiaddnewvehicle", label2: "training.addNewVehicletext"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.SELECT_ADD_NEW_VEHICLE, description: "training.heading.clickaddnewvehicle"},
            {image: TRAINING_IMAGES.ADD_VEHICLE_INFO, description: "training.heading.addvehicleinfo"},
            {image: TRAINING_IMAGES.SAVE_VEHICLE_INFO, description: "training.heading.savebutton"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.viewfleetvehicles"},
        ]
    },
    {
        type: TRAINING_TYPE.ADD_BLINKCARD_TO_VEHICLE,
        header: {label1: "training.howdoiaddblinkcardtovehicle", label2: "training.addBlinkCardToVehicletext"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.selectfleetvehicle"},
            {image: TRAINING_IMAGES.ASSIGN_NEW_CARD, description: "training.heading.clickassignnewcard"},
            {image: TRAINING_IMAGES.ADD_CARD_NUMBER, description: "training.heading.addcardnumber"},
            {image: TRAINING_IMAGES.ASSIGN_BLINK_CARD, description: "training.heading.assigncard"},
            {image: TRAINING_IMAGES.VIEW_BLINK_CARDS, description: "training.heading.viewblinkcards"},
        ]
    },
    {
        type: TRAINING_TYPE.ASSIGN_EVSE_TO_VEHICLE,
        header: {label1: "training.howdoiassignevsetovehicle", label2: "training.assignEvseToVehicletext"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.selectfleetvehicle"},
            {image: TRAINING_IMAGES.SELECT_EVSE_TAB, description: "training.heading.selectevsetab"},
            {image: TRAINING_IMAGES.ADD_EVSE_STATION, description: "training.heading.selectevsesave"},
            {image: TRAINING_IMAGES.SELECT_EVSE_STATION, description: "training.heading.selectevsestation"},
            {image: TRAINING_IMAGES.ASSIGN_EVSE_STATION, description: "training.heading.assignevsestation"},
            {image: TRAINING_IMAGES.VIEW_EVSE_STATIONS, description: "training.heading.viewevsestations"},
        ]
    },
    {
        type: TRAINING_TYPE.VEHICLE_NOTIFICATIONS,
        header: {label1: "training.howdoiconfigurevehiclenotifications", label2: "training.configureVehicleNotificationstext"},
        steps: [
            {image: TRAINING_IMAGES.FLEET_MANAGEMENT, description: "training.heading.managefleet"},
            {image: TRAINING_IMAGES.SELECT_VEHICLE_TAB, description: "training.heading.selectvehicletab"},
            {image: TRAINING_IMAGES.VIEW_FLEET_VEHICLES, description: "training.heading.selectfleetvehicle"},
            {image: TRAINING_IMAGES.VEHICLE_NOTIFICATION_TAB, description: "training.heading.selectnotificationtab"},
            {image: TRAINING_IMAGES.EDIT_VEHICLE_NOTIFICATION, description: "training.heading.clicktheediticon"},
            {image: TRAINING_IMAGES.VEHICLE_NOTIFICATION_SELECT, description: "training.heading.selectnotificationonoff"},
            {image: TRAINING_IMAGES.VEHICLE_NOTIFICATION_SAVE, description: "training.heading.clickthesavechangesbutton"},
            {image: TRAINING_IMAGES.VEHICLE_NOTIFICATION_VIEW, description: "training.heading.viewvehiclenotificationsettings"},
        ]
    },
    {
        type: TRAINING_TYPE.ASSIGN_EVSE_TO_FLEET,
        header: {label1: "training.howdoiassignevsetofleet", label2: "training.assignEvseToFleettext"},
        steps: [
            {image: TRAINING_IMAGES.MANAGE_FLEET, description: "training.heading.selectfleet"},
            {image: TRAINING_IMAGES.SELECT_FLEET_EVSE_TAB, description: "training.heading.selectevsetab"},
            {image: TRAINING_IMAGES.VIEW_FLEET_EVSE_STATION, description: "training.heading.selectfleetevsestation"},
            {image: TRAINING_IMAGES.ASSIGN_FLEET_EVSE_STATION, description: "training.heading.assignfleetevsestation"},
            {image: TRAINING_IMAGES.VIEW_FLEET_ASSIGNED_EVSE_STATIONS, description: "training.heading.viewfleetassignedevsestation"},
            
        ]
    },
];
