import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Report = (props: any) => {
    const { t } = useTranslation();

    const handleClick = () => {
        window.close()
    }

    return (
        <>
            <div className="dialog_box">
                <Dialog className="dialog_container edit_detail_section  report_height sm dialog_container_center " {...props}
                    open={props?.openReportAvailable}
                    onClose={props?.reportAvailableClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                        <h4>{t("common.downloaded")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={handleClick}
                            >
                                {t("common.cancelbutton")}
                            </Button>

                        </div>
                    </div>
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description"
                        className="text_center">
                            <Typography className="plain_text">
                                <div className="mt-40">{t("common.available")}</div>
                            </Typography>
                            <div className="mt-30">
                            <Button onClick={props?.getDwonlaodData}
                                className="btn_white blue_bg"
                            >
                                {t("common.downloadreport")}
                            </Button>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};

export default Report;
