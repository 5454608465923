export const locations = {
    evse: "common.evse",
    evseLocations: "common.evse_locations",
    manageUsers: "common.manageusers",
    training: "dashboard.training",
    details: "common.details",
    driverInfo: "common.driverInfo",
    profile: "common.profile",
    privateAccessRequests: "membership.requests",
    manageUsersMembershipDetail: "usergroupdetail.userinfo"
}

export const routes = [
    {
        pathname: "/evseLocations/evseDepotDetail",
        displayOptions : ["common.overview", "chargehistory", "common.usagerate", "common.information"]
    },
    {
        pathname: "/evseLocations/depotStationDetails",
        displayOptions : ["stationdetails","chargehistory","lcdDisplay.display"]
    },
    {
        pathname: "/manageUsers/manageUsersDetail",
        displayOptions: ["usergroupdetail.userinfo","common.location"]
    },
    {
        pathname: "/hostSettings",
        displayOptions: ["hostsetting.delegates","evselocations.label","dashboard.card.evsevehicles"]
    },
    {
        pathname: "/fleet/fleetInfo",
        displayOptions: ["fleet.vehicles", "common.evse",  "fleet.delegate",  "fleet.constraints",  "fleet.notifications", "common.information"]
    },
    {
        pathname: "/fleet/fleetVehicleInfo",
        displayOptions: ["common.evse", "common.blinkcards",  "fleet.constraints",  "fleet.notifications",  "common.information"]
    },
    {
        pathname: "/fleet",
        displayOptions: ["fleet" , "fleet.vehicles"]
    }
]