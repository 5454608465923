import { SUCCESS } from "common";
import { toLower } from "lodash";
import {getMethod, ApiEndPoint, postMethod} from "services";

export const fetchTotalMembershipRequests = async (parameter: any, count:boolean = false, mock=false, downloadCsv: boolean = false) => {

    let params = downloadCsv ? `?host_id=${parameter.hostId}` : (count ? `?count=${parameter.count}&host_id=${parameter.hostId}` : `?page=${parameter.currentPage}&size=${parameter.size}&host_id=${parameter.hostId}`);

    let membershipData = await getMethod(ApiEndPoint.SemaMembershipTotalRequests, params, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData;
};

export const fetchOrganizationDetails = async (hostUserId: any) => {
    let url = ApiEndPoint.hostDetail.replace("{{hostId}}", hostUserId);
    await getMethod(url, '', false, false)
        .then((res) => {
            if (res?.status === SUCCESS) {
                if(res.data.hostUserGroupEnum) {
                    sessionStorage.setItem('hostUserGroupEnum', res?.data?.hostUserGroupEnum);
                    sessionStorage.setItem('invitationCode', res?.data?.invitationCode);
                }
                if (res.data.isMembershipAccessAllowed) {
                    sessionStorage.setItem('memberShipSubscribtion', "true");
                } else {
                    sessionStorage.setItem('memberShipSubscribtion', "false");
                }
                if (res.data.isFleetManagement) {
                    sessionStorage.setItem('fleetSubscribtion', "true");
                } else {
                    sessionStorage.setItem('fleetSubscribtion', "false");
                }
                sessionStorage.setItem('hostSource', res.data.source);
            }
        })
        .catch((err) => {
            console.error("Error", err);
        })
};

export const submitmembershipApproveOrReject = async (data: any, parameter: any) => {

    let params = `?action=${toLower(parameter.action)}`;
    let membershipData = await postMethod(ApiEndPoint.semaMembershipManageMember, params, data, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData
};

export const fetchUserGroupDetails = async (parameter: any, mock=false) => {

    let params = `?host_id=${parameter.orgId}`;
    let membershipData = await getMethod(ApiEndPoint.semaMembershipUserGroupDetails, params, mock, false, true)
        .then((res) => {
            if (res && res.status === SUCCESS) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData;
};

export const fetchUserGroupListing = async (parameter: any, mock=false) => {

    let params = `?host_id=${parameter.hostId}&size=${parameter.pageSize}&page=${parameter.currentPage}`;
    let membershipData = await getMethod(ApiEndPoint.MembershipUserGroupListing, params, mock, false, true)
        .then((res) => {
            if (res && res.status === SUCCESS) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData;
};

export const fetchUserGroupGridList = async (parameter: any, mock=false, downloadCsv?:boolean) => {

    let params = !downloadCsv ? `?host_id=${parameter.hostId}&user_group_id=${parameter.userGroupId}&size=${parameter.size}&page=${parameter.currentPage}` : 
                                `?host_id=${parameter.hostId}&user_group_id=${parameter.userGroupId}`;
    let membershipData = await getMethod(ApiEndPoint.MembershipUserGroupGridList, params, mock, false, true)
        .then((res) => {
            if (res && res.status === SUCCESS) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData;
};

export const addMembershipUserGroup = async (data: any, parameter: any) => {

    let params = `?host_id=${parameter.hostId}&user_group_id=${parameter.userGroupId}`;
    let membershipData = await postMethod(ApiEndPoint.MembershipAddUserGroup, params, data, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData;
};

export const deleteMembershipUserGroup = async (data: any) => {

    let membershipData = await postMethod(ApiEndPoint.MembershipDeleteUserGroup, "", data, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return membershipData;
};