import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    CardContent,
    Card,
    IconButton,
    TextField,
    ListSubheader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";
import delegationData from "data/delegatesListData.json";
import Images from "assets/images";
import { ApiEndPoint, getMethod } from 'services';
import {pageDetails, claims, CheckAccess, rolePermissionCheck, getFormattedDateTimeWithTZ, GetUrlDetails, defaultVariable, keyCloakConfig} from 'common';
import {assignStationColumns, DelegatesListColumnsComponent} from "pages/tableColumns";
import { useTranslation } from "react-i18next";
import "../../App.scss";
import "./hostSettings.scss";
import { toUpper } from "lodash";
import CommonPagination from 'components/commonPagination';
import DelegatesForm, { ViewDelegate, EditDelegate, DeleteDelegate } from 'pages/hostSettings/delegates';
import { useHistory } from 'react-router-dom';
import UserGroupTab from "./userGroup";
import PolygonMap from "../../components/polygonMap";
import EvseLocationsList from './evseLocationsList';
import LocationsDetails from 'pages/hostSettings/evseLocationsList/locationDetails';


const assignStation = assignStationColumns;
const drawerWidth = 200;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const HostSettings = (props: any) => {
  const { t } = useTranslation();
  
  const userRoles = keyCloakConfig?.realmAccess?.roles;
  const getLocationPermission: any[] = keyCloakConfig?.tokenParsed?.locations?.allowed?.data;
  const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);

  const [openEditDelegate, setEditDelegateOpen] = useState(false);
  const [scrollEditDelegate, ] = useState("paper");
  const [openDeleteaccount, setDeleteaccountOpen] = useState(false);
  const [openAssignstation, setAssignstationOpen] = useState(false);
  const [scrollAssignstation, ] = useState("paper");
  const [openCapturezoneMap, setCapturezoneMapOpen] = useState(false);
  const [scrollCapturezoneMap, ] = useState("paper");
  const [openCreatenewZone, setCreateNewzoneOpen] = useState(false);
  const [scrollCreatenewZone, ] = useState("paper");
  const [openCreatenewZonefield, setCreateNewzonefieldOpen] = useState(false);
  const [scrollCreatenewZonefield, ] = useState("paper");
  const [openDelegateAccount, setDelegateAccountOpen] = useState(false);
  const [scrollDelegateDetail, ] = useState("paper");

  const [createUserGroup, setUserGroupOpen] = useState(false);
  const [scrollUserGroupDetail] = useState("paper");

  const [editUserGroup, setEditGroupOpen] = useState(false);
  const [scrollEditGroupDetail] = useState("paper");

  const [deleteUserGroup, setDeleteUserOpen] = useState(false);
  const [scrollUserDetail] = useState("paper");

  const [isVisible, setIsVisible] = useState(true);
  const [delegatesInfo, setDelegates] = useState<any>({});
  const [delegatesPageData, setDelegatesPageData] = useState('');
  const pageSize = pageDetails.pageSize;
  const [currentPage, setCurrentPage] = useState(0);
  const [listDelegatetable, setListDelegatetable] = useState(true);
  const [currentDelegate, setCurrentDelegate] = useState({});
  const [currentDelegateId, setCurrentDelegateId] = useState<any>();
  const [, setuserGroupView] = useState(true);
  const history = useHistory();
  let { pathname, hash, tab, viewType, delegateId, defaultTab } = GetUrlDetails(history, 'hostSettings');
  const columnDelegates = DelegatesListColumnsComponent();
  const [hasAssignPermission, setHasAssignPermission] = useState(false);
  const [locationAssignPermission, setLocationAssignPermission] = useState<any>([]);
  const [loadingPage, setLoadingPage]  = useState(false);
  const [loading, setLoading] = useState(false);

  const userGroupTabDefault = () => {
    setuserGroupView(true);
  };

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage)     
  }
  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const editDelegateOpen = () => {
    setEditDelegateOpen(true);
  };

  const editDelegateClose = () => {
    setEditDelegateOpen(false);
  };

  const deleteAccountOpen = () => {
    setDeleteaccountOpen(true);
  };

  const deleteAccountClose = () => {
    setDeleteaccountOpen(false);
    setListDelegatetable(true);
    handleCurrentPage(0);
    getDelegatesData();
  };

  const assignstationClose = () => {
    setAssignstationOpen(false);
  };
  const captureZonemapClose = () => {
    setCapturezoneMapOpen(false);
  };
  const createNewzoneClose = () => {
    setCreateNewzoneOpen(false);
  };
  const createNewzonefieldClose = () => {
    setCreateNewzonefieldOpen(false);
  };

  const delegateAccountOpen = () => {
    setLoading(true);
    setDelegateAccountOpen(true);
    setLoading(false);
  };

  const delegateDetailclose = () => {
    setDelegateAccountOpen(false);
  };

  const handleClickOpenRow = (row:any) =>{
    let delegateId = row.id 
    let searchParams = "?delegate="+ delegateId + "&view=info";

    setCurrentDelegateId(delegateId);
    setCurrentDelegate(row);
    setListDelegatetable(false);
    
    history.push({
      pathname:  pathname,
      search: searchParams,
      hash: hash 
   });   
  }

  const userGroupclose = () => {
    setUserGroupOpen(false);
  };

  const editGroupclose = () => {
    setEditGroupOpen(false);
  };

  const deleteUser = () => {
    setDeleteUserOpen(true);
  };

  const deleteUserClose = () => {
    setDeleteUserOpen(false);
  };

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  useEffect(() => {
    if (tab !== '' ){
      setValue(parseInt(tab));
    } else if (defaultTab !== ''){
      setValue(parseInt(defaultTab));
    } 
    
    if (viewType === 'info') {
      setCurrentDelegateId(delegateId);
      getDelegatesData(delegateId);
      setListDelegatetable(false);
    }else{
      setuserGroupView(true);
      setListDelegatetable(true);
    }
  }, [viewType,tab]);

  useEffect(() => {
    if(!delegateId){
      if(tab === '0' || tab === '') {
        getDelegatesData()
      }
    }
  }, [currentPage, tab, delegateId])

  useEffect(() => {
    handleDeligatePageData()
  }, [delegatesInfo])

  useEffect(() => {
    checkLocationPermission();
  },[])

  useEffect(() => {
    if(isDelegate) {
      handleChange(()=>{}, 1);
    }
  }, [isDelegate])

  const checkLocationPermission = () => {
    // if role exist for restriction then verify its permission
    if(isDelegate)
    {
      let locations: any = [];
      if(getLocationPermission !== undefined && getLocationPermission !== null) {
        getLocationPermission.forEach((val:any)=> {
            val?.permissions.forEach((permissionVal:any)=> {
              if(rolePermissionCheck(permissionVal, [defaultVariable.LOCATION_PERMISSIONS.delegateAccess, defaultVariable.LOCATION_PERMISSIONS.delegate])){              
                locations.push(val?.locationId);
              }
            })
        });
        setLocationAssignPermission(locations);
      }
    }
    else
    {
      setHasAssignPermission(true);
    }
  }

  const getDelegatesData = async (id?:string) => {
    const page = currentPage;
    const size = pageSize;
    let queryParams = '?page=' + page + '&size=' + size;

    if(id){
      queryParams = '/' + id;
    }

    if(!isDelegate) {
      setLoadingPage(true);
      await getMethod(ApiEndPoint.delegatesData, queryParams, false).then((res) => {

        if(id){
          setCurrentDelegate(res.data);
        }else{
          setDelegates(res.data);

          //Setting delegates based on role access
          if(isDelegate){
            let anyPermission = false;
            setDelegates(res.data);
            
            getLocationPermission?.forEach((val:any)=> {
              val?.permissions?.forEach((permissionVal:any)=> {                
                let hasPermission: boolean = rolePermissionCheck(permissionVal, [defaultVariable.LOCATION_PERMISSIONS.delegateAccess, defaultVariable.LOCATION_PERMISSIONS.delegate]);      
                anyPermission = hasPermission;
              });
            });

            if(!anyPermission){
              setPendingDelegates(res.data)
            }

          }
        }

      }).catch((err) => {
        console.error("Error", err)
      })
      setLoadingPage(false);
    }
  }

  const setPendingDelegates = (allDelegates: any, ) => {
      let fdata = allDelegates;
      let ldata = allDelegates.delegateDetails.filter((val: any)=>  val.status === defaultVariable.STATUS.PENDING );
      fdata['delegateDetails'] = ldata;
      setDelegates(fdata);
  }

  const getDelegateDataById = async () => {
    let { delegateId } = GetUrlDetails(history,'hostSettings');
    await getMethod(ApiEndPoint.delegatesData, '/' + delegateId, false).then((res) => {
        setCurrentDelegate(res.data);
        }).catch((err) => {
            console.error("Error", err)
    })
  }

  const handleDeligatePageData = () => {
    let totalPage = delegatesInfo && delegatesInfo.totalDelegates ? delegatesInfo.totalDelegates : 0;
    if (totalPage > 0) {
      let setPageValue = (currentPage >= 1 ? (pageSize*currentPage) + 1 : 1) + ' - ' + ((pageSize*(currentPage+1)) < totalPage ? (pageSize*(currentPage+1)) : totalPage  ) + t("common.of")
      setDelegatesPageData(setPageValue)
    }
  }

  const deligatesDataSet = (value: any) => {
    let overviewTableData = []
    if (value && value.delegateDetails) {
      overviewTableData = value.delegateDetails.map((val: any) => ({ 
        ...val, 
        id: val.id, 
        fullName: val.firstName ? (val.firstName + ' ' + val.lastName): '',
        createdTimestamp: getFormattedDateTimeWithTZ(val.createdTimestamp)
       }))
    }
    return overviewTableData
  }

  const classes = useStyles();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);

    history.push({
      pathname:  pathname,
      hash: '#' + newValue
    });

  };

  const hideDelegates = isDelegate ? 'hide': '';
  const hideDelegatesTab = isDelegate ? { display: 'none'}: {};
  
  return (
    <>
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog edit_detail_section  dialog_container_center"
          {...props}
          open={openCreatenewZonefield}
          onClose={createNewzonefieldClose}
          scroll={scrollCreatenewZonefield}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("common.createnewzone")}</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={createNewzonefieldClose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white blue_bg">
                {t("common.nextbutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="information_section delegates_details">
                <div className="defult_form mt-10">
                  <div className="section_group">
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={4}
                    >
                      <Grid item xs={12}>
                        <div className="form_col error">
                          <Grid
                            className="single_line"
                            container
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                          >
                            <Grid item xs={12}>
                              <label>{t("common.zonename")} *</label>
                              <TextField
                                variant="outlined"
                                defaultValue="Santa Monica Zone - A"
                              />
                              <span className="error_msg">
                                {t("common.errorrequiredfield")}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="form_col">
                          <Grid
                            className="single_line"
                            container
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                          >
                            <Grid item xs={12}>
                              <label>{t("common.access")} *</label>
                              <Select
                                labelId="demo-simple-select-label"
                                variant="outlined"
                              >
                                <MenuItem
                                  className="defult_select_option"
                                  value={1}
                                >
                                  California
                                </MenuItem>
                                <MenuItem
                                  className="defult_select_option"
                                  value={2}
                                >
                                  California
                                </MenuItem>
                                <MenuItem
                                  className="defult_select_option"
                                  value={3}
                                >
                                  California
                                </MenuItem>
                              </Select>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="section_group">
                    <div className="form_col">
                      <Grid
                        className="single_line textarea"
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                      >
                        <Grid item xs={12}>
                          <label>{t("common.notes")}</label>
                          <TextField
                            multiline
                            rows={4}
                            helperText={t("common.charlengthmsg")}
                            defaultValue="Food Prep facility for pick up"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog  dialog_container_center"
          {...props}
          open={openCreatenewZone}
          onClose={createNewzoneClose}
          scroll={scrollCreatenewZone}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("common.createnewzone")}</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={createNewzoneClose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white outline_btn">
                {t("common.backbutton")}
              </Button>
              <Button className="btn_white blue_bg">
                {t("common.savebutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="information_section delegates_details">
                <div className="title_row">
                  <h2>SANTA MONICA ZONE - A</h2>
                  <div className="clearfix"></div>
                </div>
                <div className="info_detail">
                  <div className="zone_section  mt-13">
                    <Grid container>
                      <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                        <div className="zone_detail">
                          <div className="zone_map">
                            <img
                              className="img_res"
                              src={Images.zone_map}
                              alt="zone map"
                            />
                          </div>
                          <div className="tb_zone_section">
                            <div className="label">
                              {toUpper(t("common.notes"))}
                            </div>
                            <div className="result">
                              Change from public to private facility.
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid className="" item xs={12} sm={6} md={12} lg={6}>
                        <div className="zone_floor_info">
                          <List component="nav" className="z_floor_list">
                            <ListSubheader className="list_head">
                              <Grid container className="list_sub_head">
                                <Grid item xs={12} sm={6} md={12} lg={6}>
                                  7 {t("common.stations")}{" "}
                                </Grid>
                                <Grid item xs={12} sm={6} md={12} lg={6}>
                                  11 {t("common.ports")}
                                </Grid>
                              </Grid>
                            </ListSubheader>
                            <ListItem>
                              <ListItemIcon className="zone_icon">
                                <img src={Images.ic_evse_port2} alt="edit" />
                              </ListItemIcon>
                              <ListItemText>
                                AMT53-2515-030 - Port 2
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon className="zone_icon">
                                <img src={Images.IC_EVSE_BLUE} alt="edit" />
                              </ListItemIcon>
                              <ListItemText>
                                MAMM34-2515-023 - Port 1
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon className="zone_icon">
                                <img src={Images.IC_EVSE_BLUE} alt="edit" />
                              </ListItemIcon>
                              <ListItemText>Sunset Blvd - Port 1</ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon className="zone_icon">
                                <img src={Images.IC_EVSE_BLUE} alt="edit" />
                              </ListItemIcon>
                              <ListItemText>
                                Electro Chile SPA - Port 1
                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon className="zone_icon">
                                <img src={Images.ic_evse_port2} alt="edit" />
                              </ListItemIcon>
                              <ListItemText>Dunkin Donut - Port 2</ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemIcon className="zone_icon">
                                <img src={Images.ic_evse_port2} alt="edit" />
                              </ListItemIcon>
                              <ListItemText>
                                Dunkin Donuts - Port 2
                              </ListItemText>
                            </ListItem>
                          </List>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog  dialog_container_center"
          {...props}
          open={openCapturezoneMap}
          onClose={captureZonemapClose}
          scroll={scrollCapturezoneMap}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("common.capturezone")}</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={captureZonemapClose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white outline_btn">
                {t("common.backbutton")}
              </Button>
              <Button className="btn_white blue_bg">
                {t("common.nextbutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <PolygonMap />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <div className="dialog_box">
        <Dialog
          className="dialog_container zone_settings_dialog  dialog_container_center"
          {...props}
          open={openAssignstation}
          onClose={assignstationClose}
          scroll={scrollAssignstation}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("hostsetting.assignlocation")} </h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={assignstationClose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white outline_btn">
                {t("common.backbutton")}
              </Button>
              <Button className="btn_white blue_bg">
                {t("common.nextbutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="table_scroll">
                <div className="default_table background-remove">
                  <DataGrid
                    autoHeight
                    vertical-align="top"
                    className="table"
                    {...props}
                    rowHeight={59}
                    headerHeight={30}
                    rows={delegationData.assignStationRows}
                    columns={assignStation}
                    pageSize={12}
                  />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <DeleteDelegate 
      openDeleteaccount = {openDeleteaccount}
      deleteAccountClose = {deleteAccountClose}
      currentDelegate = {currentDelegate}   
      editDelegateClose = {editDelegateClose}  
      />
      <EditDelegate
      openEditDelegate = {openEditDelegate}
      editDelegateClose = {editDelegateClose}
      getDelegateDataById = {getDelegateDataById}
      scrollEditDelegate = {scrollEditDelegate}
      currentDelegate = {currentDelegate}
      deleteAccountOpen = {deleteAccountOpen}
      />
      
      <div className="dialog_box">
        <Dialog className="dialog_container delegate_account dialog_container_center" {...props}
          open={openDelegateAccount}
          onClose={delegateDetailclose}
          scroll={scrollDelegateDetail}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DelegatesForm 
            delegateDetailclose = {delegateDetailclose} 
            getDelegatesData = {getDelegatesData}
            loading = {loading}
          />
        </Dialog>
      </div>
      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_detail_section zone_settings_dialog dialog_container_center"
          {...props}
          open={createUserGroup}
          onClose={userGroupclose}
          scroll={scrollUserGroupDetail}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("hostsetting.createnewuserbutton")} </h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={userGroupclose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white blue_bg">
                {t("common.savechangesbutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="defult_form">
                <div className="section_group">
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                  >
                    <Grid item xs={12}>
                      <div className="form_col">
                        <Grid
                          className="single_line"
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={0}
                        >
                          <Grid item xs={12}>
                            <label>{t("common.groupname")} *</label>
                            <TextField
                              variant="outlined"
                              defaultValue="Group Name"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="section_group">
                  <div className="form_col">
                    <Grid
                      className="single_line textarea"
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={0}
                    >
                      <Grid item xs={12}>
                        <label>{t("common.notes")}</label>
                        <TextField
                          multiline
                          rows={4}
                          helperText={t("common.charlengthmsg")}
                          defaultValue="Food Prep facility for pick up"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_detail_section edit_photo_gallery zone_settings_dialog dialog_container_center"
          {...props}
          open={editUserGroup}
          onClose={editGroupclose}
          scroll={scrollEditGroupDetail}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("hostsetting.editusergroup")}</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={editGroupclose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white blue_bg">
                {t("common.savechangesbutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="defult_form">
                <div className="section_group">
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                  >
                    <Grid item xs={12}>
                      <div className="form_col">
                        <Grid
                          className="single_line"
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={0}
                        >
                          <Grid item xs={12}>
                            <label>{t("common.groupname")} *</label>
                            <TextField
                              variant="outlined"
                              defaultValue="Contractors"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="section_group">
                  <div className="form_col">
                    <Grid
                      className="single_line textarea"
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={0}
                    >
                      <Grid item xs={12}>
                        <label>{t("common.notes")}</label>
                        <TextField
                          multiline
                          rows={4}
                          helperText={t("common.charlengthmsg")}
                          defaultValue="Food Prep facility for pick up"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div className="gallery_photo_upload">
                  <Card>
                    <CardContent>
                      <div className="dragdrop">
                        <img
                          src={Images.up_image}
                          alt="upimg"
                          className="up-icon"
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Drag and drop or click here
                            <cite>
                              Maximum photo upload size 4MB . Accepted JPEG and
                              PNG
                            </cite>
                          </Button>
                        </label>
                        <input
                          accept="image/*"
                          className=""
                          id="icon-button-file"
                          type="file"
                        />
                      </div>
                      <div className="progresbar">
                        <label>
                          Port.jpeg<span>(2 MB)</span>
                        </label>
                        <div className="range">
                          <div className="upper-range"></div>
                        </div>
                        <IconButton className="top_action_btn cancel">
                          <img
                            src={Images.cancel_icon}
                            alt="del"
                            className="cancel-img"
                          />
                        </IconButton>
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div
                  className="button_row mt-0 full_right"
                  onClick={deleteUser}
                >
                  <Button className="delete_usergroup">
                    {t("common.deleteusergroupbutton")}
                  </Button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      <div className="dialog_box">
        <Dialog
          className="dialog_container account_delete dialog_container_center"
          {...props}
          open={deleteUserGroup}
          onClose={deleteUserClose}
          scroll={scrollUserDetail}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("common.deleteusergroupbutton")}</h4>
            <div className="button_row mt-0 full_right">
              <Button
                className="btn_white text_btn_theme"
                onClick={deleteUserClose}
              >
                {t("common.cancelbutton")}
              </Button>
              <Button className="btn_white delet_acct">
                {t("common.deletebutton")}
              </Button>
            </div>
          </div>
          <DialogContent dividers="paper" {...props}>
            <DialogContentText id="scroll-dialog-description">
              <div className="action_msg">
                Are you sure you want to delete this User Group?
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/*----Page Mid Section Start----*/}
      <main className={classes.content} data-testid="host_settings">
        <div className={isVisible ? "" : "scroll_effect"}>
          <div className="host_settings">
            <div className="detail_page_tab position_fixed_nav">
              <AppBar
                className="defult_tab"
                position="static"
                color="default"
              >
                <Tabs
                  className="tab_nav"
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example"
                >
                  <Tab 
                    className={`tab_nav_item ${hideDelegates}`}
                    label={t("hostsetting.delegates")}
                    style={hideDelegatesTab}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tab_nav_item"
                    label={t("common.evse_locations")}
                    {...a11yProps(1)}
                  />
                  {
                    CheckAccess([claims.DRIVER_READ, claims.FORBIDDEN_ACCESS]) &&
                    <Tab
                      className="tab_nav_item"
                      label={t("hostsetting.usergroups")}
                      {...a11yProps(2)}
                      onClick={userGroupTabDefault}
                    />
                  }    
                </Tabs>
              </AppBar>
                <TabPanel
                  className={`tab_detail ${hideDelegates}`}
                  {...props}
                  value={value}
                  index={0}
                >
                  {/*Host  Setting-  Delegate Start*/}
                  {loadingPage ? <CircularProgress className="inner_progress_userGroup"/> : (listDelegatetable && <>
                  <div className="button_row text_right">
                    <Button
                      className="btn_white blue_bg ml_0"
                      onClick={delegateAccountOpen}
                    >
                      {t("hostsetting.invitedelegate")}
                    </Button>
                  </div>
                  <div className="table_scroll">
                    <div className="default_table delegate">
                      <DataGrid
                        autoHeight
                        vertical-align="top"
                        className="table"
                        rowHeight={80}
                        headerHeight={30}
                        rows={deligatesDataSet(delegatesInfo)}
                        columns={columnDelegates}
                        pageSize={12}
                        onRowClick={(e) => handleClickOpenRow(e?.row)}
                      />
                    </div>
                    <div className="table_footer">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={0}
                      >
                        <Grid className="text_left" item xs={8}>
                        {delegatesInfo && delegatesInfo.totalDelegates > pageSize ?
                        <CommonPagination 
                          currentPage = {currentPage}
                          totalCount = {delegatesInfo?.totalDelegates}
                          callBackCurrentPage = {handleCurrentPage}
                        /> : <></>}
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                          <div className="list_number">{delegatesPageData} {delegatesInfo.totalDelegates ? (delegatesInfo.totalDelegates + ' ' +  t("hostsetting.delegates")) : '' } </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  </>
                  )}
                  {delegateId &&
                    <ViewDelegate 
                    deleteAccountOpen = {deleteAccountOpen}
                    editDelegateOpen = {editDelegateOpen}
                    currentDelegate = {currentDelegate}
                    setCurrentDelegate = {setCurrentDelegate}
                    currentDelegateId = {currentDelegateId}
                    openEditDelegate = {openEditDelegate}
                    hasAssignPermission = {hasAssignPermission}
                    locationAssignPermission = {locationAssignPermission}
                    userRoles = {userRoles}
                    />
                  }
                  {/*Host  Setting-  Delegate END*/}
                </TabPanel>
                <TabPanel
                  className="zones_sattings tab_detail"
                  {...props}
                  value={value}
                  index={1}
                >
                  <EvseLocationsList />
                </TabPanel>
              {  
                CheckAccess([claims.DRIVER_READ, claims.FORBIDDEN_ACCESS]) &&
                <TabPanel
                  className="zones_sattings tab_detail"
                  {...props}
                  value={value}
                  index={2}
                >
                  <UserGroupTab />          
                </TabPanel>
              }
            </div>
          </div>
        </div>
      </main>
      {/*----Page Mid Section End----*/}
    </>
  );
};
export {LocationsDetails}
export default HostSettings;
