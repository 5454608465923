export const statusCheck = (value: any) => {
    if (typeof value === "undefined" || value === null) {
        return 0;
    } else {
        return value;
    }
};

export const chartRendered = (chartData:any) => {
    let searchData = chartData.find((val:any) => Number(val.actualLoad) > 0 || Number(val.peakLoad) > 0)
    return !!searchData;
}