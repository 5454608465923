import { Button, CircularProgress, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import {
  pageDetails,
  dateFormats,
  defaultVariable,
  keyCloakConfig,
} from "common";
import { ObjectDetails } from "common/interfaces";
import {
  columnNameTranslate,
  HandlePaginationLabel,
  rolePermissionCheck,
} from "common/methodHelpers";
import CommonDownloadCsv from "components/commonDownloadCsv";
import CommonPagination from "components/commonPagination";
import moment from "moment";
import {
  FleetVehicleColumns,
  GroupVehicleColumnsComponent,
} from "pages/tableColumns/fleetVehicleColumns";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  fetchVehicleInfo,
  fetchGroupInfo,
} from "services/fleetManagementService";
import AddFleetVehicle from "./addFleetVehicle";
import DeleteGroupVehicle from "./deleteGroupVehicle";
import {
  FILE_NAME_FLEET_VEHICLES,
  FileHeaderFleetComponent,
  FLEET_VEHICLES,
  GROUP,
  DELETE,
  FLEET_VEHICLE_INFO_PATH,
  FLEET_GROUP_VEHICLES,
} from "../fleetManagementHelper";

const FleetVehicles = (props: any) => {
  
  const history = useHistory();
  const { t } = useTranslation();
  const groupVehiclesStatus = props?.type === GROUP;
  const pageSize = pageDetails.pageSize;
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [loadingPage, setLoadingPage] = useState(false);
  const [fleetVehicleDetails, setFleetVehicleDetails] = useState<any>({});
  const [fleetVehicleDetailsCsvData, setFleetVehicleDetailsCsvData] =
    useState<any>([]);
  const FileHeaderFleet = FileHeaderFleetComponent(
    groupVehiclesStatus ? FLEET_GROUP_VEHICLES : FLEET_VEHICLES
  );
  const hostId = keyCloakConfig?.tokenParsed?.hostId;
  const fileName =
    FILE_NAME_FLEET_VEHICLES +
    `${moment().format(dateFormats.csvTimeFormat)}.csv`;
  const [openAddVehicle, setAddVehicleOpen] = useState(false);
  const [deleteVehicle, setDeleteVehicle] = useState({ id: "" });
  const [openDeleteVehicle, setDeleteVehicleOpen] = useState(false);
  const [removableVehicle, setRemovableVehicle] = useState("");
  const [informationLoader, setInfoLoader] = useState(false);
  const [groupinfo, setGroupInfo] = useState<any>([]);
  const { groupId } = props;
  const fleetVehicleColumns = groupVehiclesStatus
    ? GroupVehicleColumnsComponent()
    : FleetVehicleColumns();
  const userRoles = keyCloakConfig?.realmAccess?.roles;
  const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
  const delegateId =  sessionStorage.getItem('delegateId');

  useEffect(() => {
    getFleetVehicleDetails();
  }, [currentPage]);

  const addVehicleOpen = () => {
    setAddVehicleOpen(true);
  };

  const addVehicleClose = () => {
    setAddVehicleOpen(false);
  };

  const deleteVehicleclose = () => {
    setDeleteVehicleOpen(false);
    setRemovableVehicle("");
  };
  const deleteVehicleopen = () => {
    setDeleteVehicleOpen(true);
  };

  const navigateToDetailPage = (event: ObjectDetails) => {
      if(!groupVehiclesStatus){
        history.push({
            pathname: FLEET_VEHICLE_INFO_PATH,
            search:
              "?vehicleId=" + event.id,
          });
      }
  };

  const FleetVehicleDetailsCsvDataSet = async () => {
    let params = getVehicleParamsForCsv();
    await fetchVehicleInfo(params, false, true, false, isDelegate).then((res: any) => {
      let fleetVehicleDetailsCsvDatas = [];
      if (res.fleetVehicles.length > 0) {
        fleetVehicleDetailsCsvDatas = res.fleetVehicles.map((val: any) => ({
          ...val,
        }));
      }
      setFleetVehicleDetailsCsvData(fleetVehicleDetailsCsvDatas);
    });
  };

  const getVehicleParamsForCsv = () => {
    if(isDelegate) {
      return  {
        delegateId: delegateId,
        groupId: groupId
      }
    } else if (groupVehiclesStatus) {
      return {
        hostId: hostId, groupId: groupId
      }
    } else {
      return {
        hostId: hostId
      }
    }
  }

  const getVehicleParamsForListing = () => {
    if(isDelegate) {
      return  {
        currentPage: currentPage,
        size: pageDetails.pageSize,
        delegateId: delegateId,
        groupId: groupId,
      }
    } else if (groupVehiclesStatus) {
      return {
        currentPage: currentPage,
        size: pageDetails.pageSize,
        hostId: hostId,
        groupId: groupId,
      }
    } else {
      return {
        currentPage: currentPage,
        size: pageDetails.pageSize,
        hostId: hostId,
      }
    }
  }

  const getFleetVehicleDetails = async () => {
    setLoadingPage(true);
    let params = getVehicleParamsForListing();
    await fetchVehicleInfo(params, false, false, false, isDelegate).then((res: any) => {
      setLoadingPage(false);
      setFleetVehicleDetails(res);
    });
  };

  const handleCurrentPage = (setPage: any) => {
    setCurrentPage(setPage);
  };

  const fleetVehicleDataSet = (value: any) => {
    let fleetVehicleTableData = [];
    if (value && value.fleetVehicles) {
      fleetVehicleTableData = value.fleetVehicles.map((val: any) => ({
        ...val,
      }));
    }
    return fleetVehicleTableData;
  };

  const dataRefresh = () => {
    if (groupVehiclesStatus) {
      props.getUpdatedInfo();
    }
    getFleetVehicleDetails();
  };

  const handleDeletebutton = (params: any) => {
    if (params.field === DELETE) {
      setDeleteVehicle({
        ...deleteVehicle,
        id: params.id ? params.id : "",
      });
      deleteVehicleopen();
      setRemovableVehicle(params?.row?.vin);
    }
  };

  const getGroupInfo = () => {
    let params = { groupId: groupId };
    setInfoLoader(true);
    fetchGroupInfo(params, false)
      .then((res: any) => {
        if (res) {
          let initialValue = {
            host_id: groupVehiclesStatus ? res[0]?.host_id : "",
            vehicleName: "",
            vin: "",
            make: "",
            model: "",
            year: "",
            efficiency: "",
            fleetGroup: groupVehiclesStatus ? res[0]?.id : "",
          };
          setGroupInfo(initialValue);
          setInfoLoader(false);
        }
      })
      .catch((err: any) => {
        console.error("Error", err);
      });
  };

  useEffect(() => {
    if (groupVehiclesStatus) {
      getGroupInfo();
    }
  }, []);

  return (
    <>
      {loadingPage ? (
        <CircularProgress className="inner_progress_userGroup" />
      ) : (
        <>
          {props?.isFleetGroupsAvilable && (
            <div className="button_row text_right">
              <Button className="btn_white blue_bg ml_0" onClick={addVehicleOpen}>
                {t("fleet.addnewvehicle")}
              </Button>
            </div>
          )}
          <div className="table_scroll mt-10">
            <div className="default_table">
              <DataGrid
                autoHeight
                className="table"
                rowHeight={59}
                headerHeight={30}
                rows={fleetVehicleDataSet(fleetVehicleDetails)}
                columns={columnNameTranslate(fleetVehicleColumns)}
                pageSize={pageSize}
                onRowClick={navigateToDetailPage}
                onCellClick={handleDeletebutton}
                disableSelectionOnClick
              />
            </div>
            <div className="table_footer mt-10">
              <Grid container direction="row" alignItems="center" spacing={0}>
                <Grid className="text_left" item xs={8}>
                  {fleetVehicleDetails &&
                  fleetVehicleDetails?.totalCount > pageSize ? (
                    <CommonPagination
                      currentPage={currentPage}
                      totalCount={fleetVehicleDetails?.totalCount}
                      callBackCurrentPage={handleCurrentPage}
                    />
                  ) : (
                    <></>
                  )}
                  {fleetVehicleDetails &&
                    fleetVehicleDetails?.totalCount > 0 && (
                      <CommonDownloadCsv
                        datas={fleetVehicleDetailsCsvData}
                        filename={fileName}
                        headers={FileHeaderFleet}
                        blueclass={"border"}
                        callBackDownloadCsv={FleetVehicleDetailsCsvDataSet}
                      />
                    )}
                </Grid>
                <Grid className="text_right" item xs={4}>
                  <div className="list_number">
                    {HandlePaginationLabel(
                      fleetVehicleDetails?.totalCount,
                      pageSize,
                      currentPage,
                      fleetVehicleDetails?.totalCount > 1
                        ? "fleet.vehicles"
                        : "fleet.vehicle"
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <AddFleetVehicle
            openAddVehicle={openAddVehicle}
            addVehicleClose={addVehicleClose}
            edit={false}
            hostId={hostId}
            vehicleInfo={groupVehiclesStatus ? groupinfo : ""}
            vehicleId={""}
            dataRefreshFunction={dataRefresh}
            informationLoader={informationLoader}
            locationName={""}
            groupName={""}
            addGroupVehicleStatus={groupVehiclesStatus}
            hostUserId={""}
            setGroupId={""}
          />
          {groupVehiclesStatus && (
            <DeleteGroupVehicle
              groupVehicle={true}
              deleteVehicleId={deleteVehicle}
              openDeleteVehicle={openDeleteVehicle}
              deleteVehicleClose={deleteVehicleclose}
              dataRefreshFunction={dataRefresh}
              vehiclePass={removableVehicle}
            />
          )}
        </>
      )}
    </>
  );
};

export default FleetVehicles;
