import React from "react";
import {useTranslation} from "react-i18next";
import { toUpper } from 'lodash';
import Images from "../../assets/images";
import {CheckAccess, claims} from "../../common";
import FleetAutoAuthorize from "../fleet/fleetChargers/fleetAssignStation/fleetAutoAuthorize";

export const FleetAssignedStationListColumnsComponent = (props: any) => {
    const { t } = useTranslation();
    const showChargerUpdateButton= props.hideAutoAuthorise ? CheckAccess([claims.VEHICLE_UPDATE]) : CheckAccess([claims.FLEET_UPDATE]);
    const showChargerRemoveButton = props.hideAutoAuthorise ? CheckAccess([claims.VEHICLE_REMOVE]) : CheckAccess([claims.FLEET_REMOVE]);
    const chargerListColumns = [
        {
            field: "firstCell",
            headerName: toUpper(t("common.firstcell")),
            headerClassName: "empty",
            sortable: true,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "name",
            headerName: toUpper(t("common.stationname")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "serial_number",
            headerName: toUpper(t("common.serialNo")),
            headerClassName: "action_columns",
            sortable: true,
            hasFocus: false,
            cellClassName: "",
            className: "port",
            flex: 0.2,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "power_loss_restart",
            headerName: toUpper(t("fleet.autoAuthorize")),
            headerClassName: "action_columns",
            hasFocus: false,
            sortable: false,
            minWidth: 100,
            disableColumnMenu: true,
            flex: 0.3,
            hide: props.hideAutoAuthorise,
            renderCell: (cellValues: any) => {
                return (
                    <FleetAutoAuthorize row={cellValues.row} showChargerUpdateButton={showChargerUpdateButton}/>
                );
            },
        },
        {
            field: "delete",
            headerName: "",
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            width: 10,
            align: "left",
            disableColumnMenu: true,
            flex: 0.1,
            hide: !showChargerRemoveButton,
            renderCell: () => {
                return (
                    <div className="cancel-row button_pointer mt-20">
                        <img src={Images.ic_close}  alt=""/>
                    </div>
                );
            },
        },
        {
            field: "lastCell",
            headerName: toUpper(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        }
    ];
    return chargerListColumns;
};

export const FleetAvailableStationListColumnsComponent = () => {
    const { t } = useTranslation();
    const stationListColumns = [
        {
            field: "serial_number",
            headerName: toUpper(t("common.serialNo")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            width: 80,
            flex: 0.2,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: toUpper(t("common.stationname")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            flex: 0.5,
            width: 400,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="line-height">
                        <div>{cellValues?.row?.name}</div>
                        <div className="fleet-warning-msg">
                            {cellValues?.row?.load_flag === 1 && (t('fleet.station.warning'))}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "location_name",
            headerName: toUpper(t("common.location")),
            headerClassName: "action_columns",
            sortable: true,
            cellClassName: "",
            className: "port",
            minWidth: 100,
            flex: 0.3,
            disableColumnMenu: true,
        },
        {
            field: "lastCell",
            headerName: toUpper(t("common.lastCell")),
            headerClassName: "empty",
            sortable: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        }
    ];
    return stationListColumns;
};