import React, {useEffect, useState} from "react";
import { Grid } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { toUpper } from 'lodash';
import {HOST_TRAINING_LAYOUT} from "../trainingHelper";


const TrainingDetail = (props:any) => {
    const { t } = useTranslation();
    const [trainingType, setTrainingType] = useState<any>({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
         if(props?.location?.state) {
             sessionStorage.setItem('trainingType', props?.location?.state?.trainingType);
             setTrainingType(props?.location?.state?.trainingType);
         } else {
             let trainingTypeValue = sessionStorage.getItem('trainingType');
             setTrainingType(trainingTypeValue);
         }
    }, [props?.location?.state]);

    return (
        <>
            <main data-testid="training_details">
                {HOST_TRAINING_LAYOUT.map((layout : any) => (
                    <div key={layout.type}>
                        { layout.type === trainingType &&
                          <div className="training_detail">
                            {layout.steps.map((step: any, index: number) => (
                                <>
                                    <div className="section_group">
                                        {index === 0 &&
                                            <h1>
                                                {t(layout.header.label1)}
                                                <span>{t(layout.header.label2)}</span>
                                                <cite>{toUpper(t("training.stepbystep"))}</cite>
                                            </h1>
                                        }
                                        <Grid container spacing={2} className="training-background">
                                            <Grid item xs={9}><img className="step_images" src={step.image} alt={t("common.alt.trainingimage")} /></Grid>
                                            <Grid item xs={3}>
                                                <div className="training-content mt-30">
                                                    <h2>{toUpper(t(`training.step${index+1}`))}</h2>
                                                    <p>{<Trans t={t} i18nKey={step.description}/>}</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>
                            ))}
                        </div>
                        }
                    </div>
                ))}
            </main>

        </>
    );
};
export default TrainingDetail;