import React from "react";
import {
    Card,
    CardContent,
    Grid,
    Button,
    Typography,
} from "@material-ui/core";
import Images from "assets/images";
import { useTranslation } from "react-i18next";
import { toLower } from "lodash";
import { defaultVariable, keyCloakConfig, supportConfigData } from "common";

import { fetchLoginTime } from "redux/actions/loginTime";
import { useDispatch } from "react-redux";

const ForbiddenPage = (props:any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isExpired = props?.isExpired
    
    const handleLogOut = () => {
        keyCloakConfig.logout();
        dispatch(fetchLoginTime(new Date()));
    }

    return (
        <>
          <div className="blink-account error-page" data-testid="forbidden_page">
              <div className="blink-image">
                  <img src={Images.blink} alt={t("common.blink")} />
              </div>
              <Card className="small_card">
                  <CardContent className="card_content">
                      <h2>{defaultVariable.FORBIDDEN_ERROR_CODE} {t("common.error")}</h2>
                      <div className="section_group">
                          <Grid container spacing={2}>
                              <Grid item xs={12}>
                              <Typography  component="p">
                            { isExpired ? t("forbiddenPage.sessionexpiremsg") :  t("forbiddenPage.errorMsg") }
                                </Typography>
                                <a className="mail_link" href={'mailto:' +  toLower(supportConfigData.email)}>{toLower(supportConfigData.email)}</a>
                                  <Button className="block_btn" onClick={handleLogOut}>
                                  <img className="mr_10" src={Images.ic_log_out_w} alt="" />
                                  {t("common.signout")}
                                  </Button>
                              </Grid>
                          </Grid>
                      </div>
                  </CardContent>
              </Card>
          </div>
        </>
    );
};
export default ForbiddenPage;
