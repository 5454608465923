import React from "react";
import { urlReplace } from "common";
import { ApiEndPoint, deleteMethod } from "services";
import ConfirmDialogPopupComponent from "../../../../components/confirmDialogPopupComponent";

const UserGroupDelete = (props: any) => {
    const userGroupDeleteFieldDetails: any = [{id: 1, columnLabel: "common.deleteusergroupmsg", LabelFormat: false, columnVisible: true, classFormat: ''}];
    const userGroupDeleteCancelButtonAction = {isVisible: true, buttonName: 'common.cancelbutton', buttonPermssion: true, classFormat: ''};
    const userGroupDeleteRemoveButtonAction = {isVisible: true, buttonName: 'common.deletebutton', buttonPermssion: true, classFormat: 'red_bg', disabled: false };

    const deleteUserGroup = async () => {
        if (props?.deleteUserGroupId) {
            const url = urlReplace(ApiEndPoint.groupDetailsUserEdit, "{USERGROUP-ID}", props?.deleteUserGroupId);
            await deleteMethod(url, '').then((res) => {
                    props.deleteUserGroupSave(true);
                }).catch((err) => {
                    props.deleteUserGroupClose(true);
                    console.error("Error", err);
                });
        }
    };


  return (
    <>
      {/* Delete New User Dialog Start*/}

      <div className="dialog_box" data-testid="user_group_delete">
          <ConfirmDialogPopupComponent
              popUpPageOpen={props?.deleteUserGroupClose}
              popUpPageClose={props?.deleteAccountClose}
              headerName={"common.deleteusergroupbutton"}
              fieldDetails={userGroupDeleteFieldDetails}
              submitAction={userGroupDeleteRemoveButtonAction}
              cancelAction={userGroupDeleteCancelButtonAction }
              submitData={deleteUserGroup}
          />
      </div>
      {/* Delete New User Dialog End*/}
    </>
  );
};

export default UserGroupDelete;
