import React from "react";
import {
    Button,
    Checkbox,
    LinearProgress,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Dialog
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PRICING_PLAN_TYPES, setClassName, CTEP_NOT_ENABLED, SIMPLE_VIEW } from "../pricingPlanHelpers";
import DayDurationPricingPlan from "../dayDurationPricingPlan";
import HybridpricingPlan from "../hybridPricingPlan";
import { BLANK } from "common";
import DayDurationDetailedPricingPlan from "../dayDurationDetailedPricingPlan";

const AddPricingPlanStepTwo = (props: any) => {

    const { t } = useTranslation();

    const addSecondpopupClose = () => {
        if (props.savedparkingFeeOnChange) {
            props.addPricingPlanClose(true, true);
        }
        else {
            props.createSecondPopupClose();
        }
    }

    const navigateToFirstStep = () => {
        props.navigateBacktoFirstStep();
    }

    const savePricingPlanData = () => {
        props.savePricingPlan();
    }

    return (
        <div className="dialog_box">
            <Dialog
                className="dialog_container_pricing dialog_container_center_pricing sm"
                {...props}
                open={props.createPlanSecondPopup}
                onClose={props.createSecondPopupClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{props.secondPopupName}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button
                            className="btn_white text_btn_theme"
                            data-testId="cancel_second_popup"
                            onClick={addSecondpopupClose}
                        >
                            {t("common.cancelbutton")}
                        </Button>
                        <Button className="btn_white outline_btn"
                            data-testId="back_second_popup"
                            onClick={navigateToFirstStep}>
                            {t("common.backbutton")}
                        </Button>
                        <Button className={`btn_white ${setClassName(false, false, props.disableSaveButton)}`}
                            data-testId="save_second_popup"
                            onClick={savePricingPlanData}
                            disabled={props.disableSaveButton}>
                            {t("common.save")}
                        </Button>
                    </div>
                </div>
                {props.spinnerOff && <LinearProgress />}
                <DialogContent>
                    <DialogContentText id="scroll-dialog-description">
                        <div className="information_section delegates_details">
                            <div className="defult_form mt-25">
                                <div className="section_group">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={12}>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={3}>
                                                        <label className="w_100per full_w">
                                                            {t("pricing.type")} {t("common.required")}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Select
                                                            className={"w_100per full_w"}
                                                            labelId="demo-simple-select-label"
                                                            variant="outlined"
                                                            name={"type"}
                                                            value={props.pricingDetails?.type ? props.pricingDetails?.type : BLANK}
                                                            onChange={(e) => props.handleChange(e, true)}
                                                        >
                                                            <MenuItem
                                                                className="defult_select_option"
                                                                key={0}
                                                                value={BLANK}
                                                                disabled
                                                            >
                                                                {t("pricing.selecttype")}
                                                            </MenuItem>
                                                            {props.pricingPlanTypes &&
                                                                props.pricingPlanTypes.map((value: any) => (
                                                                    <MenuItem
                                                                        className="defult_select_option"
                                                                        key={value?.value}
                                                                        value={value?.value}
                                                                    >
                                                                        {value?.label}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {props.showBillEnergy && props.ctepValue === CTEP_NOT_ENABLED &&
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={3}>
                                                            <label>{t("pricing.billenergyfirst")}</label>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <div className="form_col_check checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={props.pricingDetails?.billEnergy}
                                                                    data-testId={"billEnergy-checkBox"}
                                                                    name={"billEnergy"}
                                                                    onChange={(event) => props.handleSelection(event)}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            {props.pricingDetails?.type !== " " &&
                                <>
                                    <div className="border_bottom"></div>
                                    <div className="defult_form_configure">
                                        <div className="section_group">
                                            <div className="form_col_configure">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>{t("pricing.configurefee")}</label>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col_button">
                                                <Grid item xs={9}
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={3}>
                                                        <div className="bottom-btn">
                                                            <button
                                                                className={!props.parkingFee ? "cursor_pointer create_pricing_btn" : "cursor_pointer create_pricing_btn_selected"}
                                                                onClick={props.openParkingFee}
                                                                disabled={props.parkingFee}
                                                            >
                                                                {t("pricing.parkingfeeheader")}
                                                            </button>
                                                        </div>
                                                    </Grid>
                                                    {props.pricingDetails?.type !== PRICING_PLAN_TYPES.HHYBRID && props.pricingDetails?.type !== PRICING_PLAN_TYPES.HYBRID_2 &&
                                                        <Grid item xs>
                                                            <div className="bottom-btn">
                                                                <div className="margin-left">
                                                                    <button
                                                                        className={!props.energyFee ? "cursor_pointer create_pricing_btn" : "cursor_pointer create_pricing_btn_selected"}
                                                                        onClick={props.openEnergyFee}
                                                                        disabled={props.energyFee}
                                                                    >
                                                                        {t("pricing.energyfeeheader")}
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </div>
                                            {props.energyFee &&
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <div className="form_col_check checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={props.pricingDetails?.durationBased}
                                                                data-testId={"durationBased"}
                                                                name={"durationBased"}
                                                                onChange={(event) => props.handleSelection(event)}
                                                            />
                                                        </div>
                                                        <Grid item xs className="check_box_label">
                                                            <label>{t("pricing.durationbasedenergyfee")}</label>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            }
                                            {props.pricingDetails?.type === PRICING_PLAN_TYPES.DURATION && props.parkingFee &&
                                                <div>
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={3}>
                                                            <label>{t("pricing.appliedtype")}</label>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <div className="form_col_radio radio_button">
                                                                <RadioGroup
                                                                    row
                                                                    aria-label="position"
                                                                    name="position"
                                                                    defaultValue="top"
                                                                    value={props.appliedValue}
                                                                    onChange={props.onChangeAppliedvalue}
                                                                >
                                                                    <FormControlLabel
                                                                        value="DAY"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        label={t("pricing.appliedday")}
                                                                    />
                                                                    <FormControlLabel
                                                                        value="SESSION"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        label={t("pricing.session")}
                                                                    />
                                                                </RadioGroup>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            }
                                            {(props.pricingDetails?.type === PRICING_PLAN_TYPES.DURATION || props.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY)
                                                && props.parkingFee &&
                                                <div>
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={5}>
                                                            <div className="form_col_radio radio_button">
                                                                <RadioGroup
                                                                    row
                                                                    aria-label="position"
                                                                    name="position"
                                                                    defaultValue="simple"
                                                                    value={props.view}
                                                                    onChange={props.onChangeView}
                                                                >
                                                                    <FormControlLabel
                                                                        value="simple"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        labelPlacement="start"
                                                                        label={t("pricing.simpleview")}
                                                                    />
                                                                    {props.ctepValue === CTEP_NOT_ENABLED &&
                                                                        <FormControlLabel
                                                                            value="detail"
                                                                            control={
                                                                                <Radio color="primary" />
                                                                            }
                                                                            labelPlacement="start"
                                                                            label={t("pricing.detailedview")}
                                                                        />
                                                                    }
                                                                </RadioGroup>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            }
                                            <div className="border_bottom"></div>
                                            {props.view === SIMPLE_VIEW && (props.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY || props.pricingDetails?.type === PRICING_PLAN_TYPES.DURATION) &&
                                                <DayDurationPricingPlan
                                                    parkingFee={props.parkingFee}
                                                    energyFee={props.energyFee}
                                                    pricingPlanData={props.pricingPlanData}
                                                    setPricingPlanData={props.setPricingPlanData}
                                                    pricingDetails={props.pricingDetails}
                                                    ctepValue={props.ctepValue}
                                                    setDetailedViewobject={props.setDetailedViewobject}
                                                    detailedViewobject={props.detailedViewobject}
                                                    timeArrayDetail={props.timeArrayDetail}
                                                    setTimeArrayDetailedKeys={props.setTimeArrayDetailedKeys}
                                                />
                                            }
                                            {props.view !== SIMPLE_VIEW && (props.pricingDetails?.type === PRICING_PLAN_TYPES.TIME_OF_DAY || props.pricingDetails?.type === PRICING_PLAN_TYPES.DURATION) &&
                                                <DayDurationDetailedPricingPlan
                                                    pricingDetails={props.pricingDetails}
                                                    pricingPlanData={props.pricingPlanData}
                                                    timeArrayDetailedKeys={props.timeArrayDetailedKeys}
                                                    timeArrayDetail={props.timeArrayDetail}
                                                    expandPricingPlan={props.expandPricingPlan}
                                                    detailedViewobject={props.detailedViewobject}
                                                    setDetailedViewobject={props.setDetailedViewobject}
                                                />
                                            }
                                            {(props.pricingDetails?.type === PRICING_PLAN_TYPES.HHYBRID || props.pricingDetails?.type === PRICING_PLAN_TYPES.HYBRID_2) &&
                                                <HybridpricingPlan
                                                    parkingFee={props.parkingFee}
                                                    energyFee={props.energyFee}
                                                    pricingPlanData={props.pricingPlanData}
                                                    setPricingPlanData={props.setPricingPlanData}
                                                    pricingDetails={props.pricingDetails}
                                                    ctepValue={props.ctepValue}
                                                    setDetailedViewobject={props.setDetailedViewobject}
                                                    detailedViewobject={props.detailedViewobject}
                                                    timeArrayDetail={props.timeArrayDetail}
                                                    setTimeArrayDetailedKeys={props.setTimeArrayDetailedKeys}
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddPricingPlanStepTwo;