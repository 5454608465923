export const claimsData = {
    MANAGER_HOST: [{
        "scopes": ["read", "update", "create", "remove"],
        "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
        "rsname": "pricing"
    },
        {
            "scopes": ["read"],
            "rsid": "da1e4433-cf51-4a96-a21d-2307eec32eb0",
            "rsname": "driver"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "membership"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "fleet"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "vehicle"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "lcd:display"
        }, {
            scopes: ["read", "update"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "waitlist"
        }],
    MANAGER_BLINK: [{
        "scopes": ["read",],
        "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
        "rsname": "pricing"
    },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "fleet"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "vehicle"
        },
        {
            "scopes": ["read"],
            "rsid": "da1e4433-cf51-4a96-a21d-2307eec32eb0",
            "rsname": "driver"
        }],
    BLINK_OWNED_MANAGER: [{
        "scopes": [
            "read",
        ],
        "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
        "rsname": "pricing"
    },
        {
            "scopes": ["read",],
            "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
            "rsname": "location"
        },
        {
            "scopes": ["read", "update"],
            "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
            "rsname": "station"
        },
        {
            "scopes": ["read"],
            "rsid": "da1e4433-cf51-4a96-a21d-2307eec32eb0",
            "rsname": "driver"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "membership"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "lcd:display"
        }, {
            scopes: ["read"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "waitlist"
        }],
    DELEGATE: [{
        "scopes": ["read",],
        "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
        "rsname": "pricing"
    },
        {
            "scopes": ["read"],
            "rsid": "da1e4433-cf51-4a96-a21d-2307eec32eb0",
            "rsname": "driver"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "vehicle"
        }, 
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "fleet"
        }, {
            scopes: ["read", "update"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "waitlist"
        }],
    DELEGATE_BLINK: [{
        "scopes": ["read",],
        "rsid": "618872f2-5fed-41ab-a10e-bbce6f250989",
        "rsname": "pricing"
    },
        {
            "scopes": ["read"],
            "rsid": "da1e4433-cf51-4a96-a21d-2307eec32eb0",
            "rsname": "driver"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "vehicle"
        },
        {
            scopes: ["read", "update", "create", "remove"],
            rsid: "618872f2-5fed-41ab-a10e-bbce6f250989",
            rsname: "fleet"
        }]
}