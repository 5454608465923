import { Button, Card, CardContent, Dialog, DialogContent, DialogContentText, FormControl, Grid, IconButton, InputAdornment, LinearProgress, OutlinedInput} from "@material-ui/core";
import Images from "../../../../assets/images";
import {toUpper} from "lodash";
import {CheckAccess, claims} from "../../../../common";
import {checkTwoDigitBeforeAfterDecimal} from "../../../../common/methodHelpers";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import {ObjectDetails} from "../../../../common/interfaces";
import {manageFleetData} from "services/fleetManagementService";
import {showErrorMessages} from "../../../../redux/actions/errorMessages";
import {useDispatch} from "react-redux";
import { checkForDisableClass, validateError } from "pages/fleet/fleetManagementHelper";
import '../../fleetManagement.scss';

const FleetConstraintsEnergyLevel = (props: ObjectDetails) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  let {energyLevelDetails, groupId, vehicleId, fetchFleetConstrainsDetails, isLoading} = props;
  const [energyLevelDetailsEdit, setEnergyLevelDetailsEdit] = useState<any>();
  const [energyLevelDetailsView, setEnergyLevelDetailsView] = useState<any>();
  const simpleValidator = useRef(new SimpleReactValidator());
  const showConstraintsUpdateButton = vehicleId
    ? CheckAccess([claims.VEHICLE_UPDATE])
    : CheckAccess([claims.FLEET_UPDATE]);
  const [, forceUpdate] = useState<any>();
  const [editEnergyLevelModal, setEnergyLevelModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableSaveBtn, setSaveDisable] = useState(true);

  useEffect(() => {
    if (energyLevelDetails) {
      setEnergyLevelDetailsEdit(energyLevelDetails);
      setEnergyLevelDetailsView(energyLevelDetails);
      checkPrevState();
    }
  }, [energyLevelDetails]);

  const showErrorsForEnergyLevel = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  const handleEnergyLevelValueChange = (event: any) => {
    if (checkTwoDigitBeforeAfterDecimal(event)) {
      let name = event.target.name;
      let value = event.target.value;
      setEnergyLevelDetailsEdit({...energyLevelDetailsEdit, [name]: value});
      if (value === "" && simpleValidator.current.messagesShown) {
        showErrorsForEnergyLevel();
      }
    }
    checkPrevState();
  };

  const checkPrevState = () => {
    setEnergyLevelDetailsEdit((prevState: any) => {
      if (prevState.maximum !== "" && prevState.minimum !== "" && prevState.target !== "") {
        setSaveDisable(false);
      } 
      return prevState;
    });
  };

  const cancelEnergyEvent = (isEnergyLevelsEditCancel: boolean) => {
    if (isEnergyLevelsEditCancel) {
      if(energyLevelDetailsView.maximum === "" && energyLevelDetailsView.minimum === "" && energyLevelDetailsView.target === "") {
        setSaveDisable(true);
      }
      setEnergyLevelDetailsEdit({...energyLevelDetailsView});
      checkPrevState();
      setEnergyLevelModal(false);
      simpleValidator.current.hideMessages();
      forceUpdate(0);
    }
  };

  const saveEnergyLevel = () => {
    const flag = simpleValidator.current.allValid();
    if (flag) {
      setLoading(true);
      let body: ObjectDetails = {
        energy_constraints: {
          max_energy: Number(energyLevelDetailsEdit.maximum),
          min_energy: Number(energyLevelDetailsEdit.minimum),
          target_energy: Number(energyLevelDetailsEdit.target),
        },
        group_id: groupId,
      };
      if (vehicleId) {
        body.vehicle_id = vehicleId;
      }
      let params = {action: "createFleetConstraints"};
      manageFleetData(params, body).then(
        (res: any) => {
          if (!res.status) {
            dispatch(
              showErrorMessages({
                error: true,
                message: res.message,
                type: "error",
                operation: "",
              })
            );
          }
          setEnergyLevelDetailsView({...energyLevelDetailsEdit});
          fetchFleetConstrainsDetails();
          setEnergyLevelModal(false);
          setLoading(false);
        },
        (error) => {
          dispatch(
            showErrorMessages({
              error: true,
              message: error.message,
              type: "error",
              operation: "",
            })
          );
        }
      );
    } else {
      showErrorsForEnergyLevel();
    }
  };

  return (
    <>
      <div className="information_section">
        <Card variant="outlined" className="basic_card">
          <CardContent>
            <Card variant="outlined" className="basic_card">
              {/*----Title Start----*/}
              <div className="title_row">
                <h2>{toUpper(t("fleet.energylevels"))}</h2>
                <>
                  { showConstraintsUpdateButton && (
                    <div className={`action_icon`}>
                      <IconButton className="top_action_btn" onClick={() => setEnergyLevelModal(true)}>
                        <img src={Images.ic_edit} alt={t("common.edit")} />
                      </IconButton>
                    </div>
                  )}
                </>
                <div className="clearfix" />
              </div>
              {/*----Title End----*/}

              {/*----Information Start----*/}
              { !isLoading && (
              <div className="info_detail common_form">
                <div className="default_form mt-30">
                  <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={6}>
                    <Grid item className="col-md-12">
                      <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                        <Grid item className="form_col col-md-6">
                          <label>
                            {t("fleet.minimum")}
                          </label>
                        </Grid>
                        <Grid item className="col-md-6">
                          <div className="result">
                            {energyLevelDetailsView?.minimum ? energyLevelDetailsView?.minimum + "kWh" : "0.00kWh"}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                        <Grid item className="form_col">
                          <label>{t("fleet.maximum")}</label>
                        </Grid>
                        <Grid item>
                          <div className="result">
                            {energyLevelDetailsView?.maximum ? energyLevelDetailsView?.maximum + "kWh" : "0.00kWh"}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                        <Grid item className="form_col">
                          <label>{t("fleet.target")}</label>
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <div className="result">
                              {energyLevelDetailsView?.target ? energyLevelDetailsView?.target + "kWh" : "0.00kWh"}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
              )}
              {/*----Information End----*/}
              {/*  Dialog for Edit Energy level constraint*/}
              <div className="dialog_box">
                <Dialog
                  className="dialog_container edit_detail_section edit-user-info sm dialog_container_center constraints_energy_levels"
                  open={editEnergyLevelModal}
                  scroll={"paper"}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <div className="dialog_title">
                  <h4>{t("fleet.energylevels")}</h4>
                  <div className="button_row mt-0 full_right">
                  <Button className="btn_white text_btn_theme" onClick={() => cancelEnergyEvent(true)}>{t("common.cancelbutton")}
                   </Button>
                    <Button onClick={() => saveEnergyLevel()}  className={`btn_white ${checkForDisableClass(disableSaveBtn)}`}  disabled={disableSaveBtn} type="submit">
                      {t("common.savechanges") }
                    </Button>
                    </div>
                  </div>
                  {loading && <LinearProgress/>}
                  <DialogContent>
                         <DialogContentText id="scroll-dialog-description">
                            <div className="information_section delegates_details">
                                <div className="defult_form mt-25">
                                    <div className="section_group">
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <label>
                                                                {t("fleet.minimum")}{" "}
                                                                {t("common.required")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8} className="text_right">
                                                            <FormControl className="form_control f_md">
                                                            <div className={`form_col-12 ${validateError(simpleValidator, "minimum")}`}>
                                                                <OutlinedInput
                                                                    className="input_text_300"
                                                                    id="outlined-guest-standard"
                                                                    name={`minimum`}
                                                                    type="number"
                                                                    value={energyLevelDetailsEdit?.minimum}
                                                                    placeholder='0.00'
                                                                    error={!simpleValidator.current.fields.minimum && simpleValidator.current.messagesShown}
                                                                    required={true}
                                                                    inputProps={{maxLength: 5}}
                                                                    onChange={handleEnergyLevelValueChange}
                                                                    endAdornment={<InputAdornment
                                                                    position="end">kWh</InputAdornment>}
                                                                />
                                                            </div>
                                                            <cite className="fleet_error_msg">
                                                                {simpleValidator.current.message("minimum", energyLevelDetailsEdit?.minimum, "required")}
                                                            </cite>
                                                        </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <label className="w_100per full_w">
                                                                {t("fleet.maximum")}{" "}
                                                                {t("common.required")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8} className="text_right">
                                                        <FormControl className="form_control f_md">
                                                            <div
                                                                className={`form_col-12 ${validateError(simpleValidator, "maximum")}`}>
                                                                <OutlinedInput
                                                                    className={`input_text_300`}
                                                                    id="outlined-guest-standard"
                                                                    name={`maximum`}
                                                                    type="number"
                                                                    value={energyLevelDetailsEdit?.maximum}
                                                                    placeholder='0.00'
                                                                    required={true}
                                                                    error={!simpleValidator.current.fields.maximum && simpleValidator.current.messagesShown}
                                                                    inputProps={{maxLength: 5}}
                                                                    onChange={handleEnergyLevelValueChange}
                                                                    endAdornment={<InputAdornment
                                                                        position="end">kWh</InputAdornment>}
                                                                />
                                                            </div>
                                                            <cite className="fleet_error_msg">
                                                                {simpleValidator.current.message("maximum", energyLevelDetailsEdit?.maximum, "required")}
                                                            </cite>
                                                        </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <label>
                                                                {t("fleet.target")}{" "}
                                                                {t("common.required")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8} className="text_right">
                                                        <FormControl className="form_control f_md">
                                                            <div
                                                                className={`form_col-12 ${validateError(simpleValidator, "target")}`}>
                                                                <OutlinedInput
                                                                    className={`input_text_300`}
                                                                    id="outlined-guest-standard"
                                                                    name={`target`}
                                                                    type="number"
                                                                    value={energyLevelDetailsEdit?.target}
                                                                    error={!simpleValidator.current.fields.target && simpleValidator.current.messagesShown}
                                                                    placeholder='0.00'
                                                                    inputProps={{maxLength: 5}}
                                                                    onChange={handleEnergyLevelValueChange}
                                                                    endAdornment={<InputAdornment
                                                                    position="end">kWh</InputAdornment>}
                                                                />
                                                            </div>
                                                            <cite className="fleet_error_msg">
                                                                {simpleValidator.current.message("target", energyLevelDetailsEdit?.target, "required")}
                                                            </cite>
                                                        </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
              </div>
              {/* Dialog Add End */}
            </Card>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
export default FleetConstraintsEnergyLevel;