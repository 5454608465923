const currentStationId = '';

export const fetchCurrentStationReducer = (state = currentStationId, action: any) => {

    if(action.type === 'FETCH_CURRENT_STATION_ID') {
        state = action.payload;
        return state;
    } else {
        return state;
    }
}
