import React, { useState, useEffect } from "react";
import {
    Button,
    makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { validateButtonClass, changeButtonName, navigateToNextStep, setFirstPopupDetails, MEMBERSHIP_APPROVE, MEMBERSHIP_DECLINE } from "../hostMembershipHelper"
import UserGroupDetails from "./userGroupDetails";
import RejectRequest from "./rejectRequest";
import SelectApproveReject from "./selectApproveReject";
import { BLANK } from "common";
import { fetchUserGroupDetails, submitmembershipApproveOrReject } from "services/membershipService";

const useStyles = makeStyles(theme => ({
    menuitemhidden: {
        display: "none"
    }
}),
);

const HostMembershipRequestPopup = (props: any) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const memberStatus = props.membershipStatus;
    const [membershipRequestStatus, setMembershipRequestStatus] = useState(" ");
    const [placeHolder, setPlaceHolder] = useState(false);
    const [approveButton, setApproveButton] = useState(false);
    const [rejectButton, setRejectButton] = useState(false);
    const [nextButton, setNextButton] = useState(true);
    const [buttonName, setButtonName] = useState(t("common.nextbutton"))
    const [userGroupDetails, setuserGroupDetails] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [secondPopup, setSecondPopup] = useState(false);
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [userGroupArray, setUserGroupArray] = useState([]);
    const [userGroupName, setUserGroupName] = useState("");
    const [userGroupId, setUserGroupId] = useState("");
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        if(nextButton && membershipRequestStatus === BLANK) {
            setDisableButton(true);
        }
    })

    const showPlaceHolder = () => {
        if (placeHolder) {
            return classes.menuitemhidden;
        } else {
            return ""
        }
    }

    const setClassName = () => {
        return validateButtonClass(approveButton, rejectButton, nextButton, secondPopup, spinnerOff, userGroupName, userGroupDetails);
    }

    const handleChange = (event: any) => {
        let membershipStatus = event.target.value;
        setMembershipRequestStatus(membershipStatus); 
        setDisableButton(false);        
        changeButtonName(membershipStatus, setNextButton, setApproveButton, setRejectButton, setButtonName);  
    }

    const memberRequestPopupClose = () => {
        props.membershipRequestPopupClose();
    }  
    
    const navigateToApproveRejectOrConfirm = () => {
        navigateToNextStep(setSecondPopup, setuserGroupDetails, setDeleteConfirmation, setNextButton, setDisableButton, approveButton, rejectButton);
        if(approveButton && userGroupName === "") {
            getUserGroupDetails();  
        } else if(secondPopup && approveButton && userGroupName !== "") {
            submitmembershiprequest(t("membership.approve"));
        } else if (secondPopup && rejectButton) {
            submitmembershiprequest(t("membership.decline"));
        }        
    }

    const navigateToFirstStep = () => {
        setFirstPopupDetails(setNextButton, setDisableButton, setSecondPopup, setDeleteConfirmation, setuserGroupDetails, setUserGroupName);
    }

    const getUserGroupDetails = () => {
        let params = {"orgId": props.hostId }
        setSpinnerOff(true);
        fetchUserGroupDetails(params).then((res: any) => {
            if (res) {
                setSpinnerOff(false);   
                setUserGroupArray(res);                    
            }
        }).catch((err: any) => {
            setSpinnerOff(false);
            console.error("Error", err) 
        })
    }

    const submitmembershiprequest = (submitType: string = "") => {
        setSpinnerOff(true);
        let body = {
            "group_id" : userGroupId,
            "status" : submitType === t("membership.approve") ? MEMBERSHIP_APPROVE : MEMBERSHIP_DECLINE,
            "user_id" : props.userId,
            "host_id" : props.hostId
        }
        let params = { 'action': submitType};
        submitmembershipApproveOrReject(body, params).then((res: any) => {
            if (res?.status === true) {
                setSpinnerOff(false);
                props.membershipRequestPopupClose();
                props.getMembershipRequestDetails();
            }});
    }
    

    return (
        <>
            <div className="dialog_title">
                <h4>{t("membership.request")}</h4>
                <div className="button_row mt-0 full_right">
                    <Button
                        className="btn_white text_btn_theme"
                        onClick={memberRequestPopupClose}
                    >
                        {t("common.cancelbutton")}
                    </Button>
                    {(userGroupDetails || deleteConfirmation) &&
                        <Button className="btn_white outline_btn"
                            onClick={navigateToFirstStep}>
                            {t("common.backbutton")}
                        </Button>
                    }
                    <Button 
                        className={`btn_white ${setClassName()}`}
                        onClick={navigateToApproveRejectOrConfirm}
                        variant="contained"
                        disabled = {disableButton || spinnerOff}>                            
                        {secondPopup ?  buttonName : t("common.nextbutton")}
                    </Button>
                </div>
            </div>
            {(nextButton || !secondPopup) &&
                <SelectApproveReject
                    handleChange={handleChange}
                    membershipRequestStatus={membershipRequestStatus}
                    setPlaceHolder={setPlaceHolder}
                    showPlaceHolder={showPlaceHolder}
                    memberStatus={memberStatus}
                    nextButton = {nextButton}
                    approveButton = {approveButton}
                    rejectButton = {rejectButton}
                />
            }
            {!nextButton && userGroupDetails && secondPopup &&
                <UserGroupDetails
                    setPlaceHolder={setPlaceHolder}
                    showPlaceHolder={showPlaceHolder}
                    spinnerOff = {spinnerOff}
                    userGroupArray = {userGroupArray}
                    setUserGroupName = {setUserGroupName}
                    setUserGroupId = {setUserGroupId}
                    setDisableButton = {setDisableButton}
                />
            }
            {!nextButton && deleteConfirmation && secondPopup &&
                <RejectRequest
                    spinnerOff={spinnerOff}
                />
            }
        </>
    )
}

export default HostMembershipRequestPopup;