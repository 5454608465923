import React from "react";
import Button from "@material-ui/core/Button";
import Images from "assets/images";
import Checkbox from "@material-ui/core/Checkbox";
import CommonGoogleMap from 'components/commonGoogleMap';
import { depotGoogleMap, defaultVariable, CheckAccess, claims } from 'common';
import { useTranslation } from "react-i18next";
import { toUpper, upperFirst, lowerCase } from "lodash";

const cellStatus = (value: boolean, locationId: string, arrId: number, disable: boolean, field: string) => {
    return (
        <div className="defult_form">
            <div className="form_col checkbox">
                {/* Added defaultChecked to handle the case if not refresh on click and hadle data in state of parent component */}
                {value &&
                    <Checkbox defaultChecked color="primary" id={locationId + '_' + arrId} name={locationId + '_' + field} disabled={!disable} />
                }
                {!value &&
                    <Checkbox color="primary" id={locationId + '_' + arrId} name={locationId + '_' + field} disabled={!disable} />
                }
            </div>
        </div>
    );

}

export const DelegatesListColumnsComponent = () => {
    const { t } = useTranslation();

    const delegatesListColumns: any[] = [
        {
            field: "firstCell",
            headerName: t("common.firstcell"),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "",
            headerName: "",
            sortable: false,
            hide: true,
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                const toRender = CheckAccess(claims.FORBIDDEN_ACCESS) &&
                    <div className="table_pic">
                        <img src={Images.default_avtar} alt="delegates" />
                        {cellValues.value}
                    </div>
                return toRender;
            },
        },
        {
            field: "fullName",
            headerName: (toUpper(t("common.name"))),
            sortable: false,
            cellClassName: "",
            flex: 0.1,
            minWidth: 100,
            disableColumnMenu: true,
        },
        {
            field: "email",
            headerName: (toUpper(t("common.emailid"))),
            sortable: false,
            hasFocus: false,
            cellClassName: "t",
            className: "port",
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "createdTimestamp",
            headerName: (toUpper(t("common.creationdate"))),
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: (toUpper(t("common.status"))),
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <Button className={`table_action_btn ${cellValues.value === defaultVariable.STATUS.ACTIVE ? 'active_btn' : 'pending_btn'} `}>{upperFirst(lowerCase(cellValues.value))}</Button>
                );
            },
        },
        {
            field: "lastCell",
            headerName: t("common.lastcell"),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ];
    return delegatesListColumns;
};

export const EditLocationListColumnsComponent = () => {
    const { t } = useTranslation();
    
    const locationListColumns: any[] = [
        {
            field: "firstCell",
            headerName: t("common.firstcell"),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "locationDetailsDTO",
            headerName: (toUpper(t("common.locations"))),
            sortable: false,
            flex: 0.2,
            minWidth: 250,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                const address = cellValues?.value?.address;
                const cval = cellValues?.value;
                return (
                    <div className="table_locaion_details">
                        <div className="table_pic listMapThumb">
                            <CommonGoogleMap
                                center={{
                                    lat: address?.latitude,
                                    lon: address?.longitude,
                                }}
                                zoom={
                                    depotGoogleMap.zoom
                                }
                                markers={[
                                    {
                                        lat: address?.latitude,
                                        lon: address?.longitude,
                                    },
                                ]}
                            />
                        </div>
                        <div className="depot_info">
                            <div className="depot_name">{cval.locationName}</div>
                            <div className="depot_addr">
                                {address.addressLine1} {address.addressLine2}
                                <br />
                                {address.city}, {address.state} {address.postalCode}, {address.country}
                            </div>
                            <div className="port_details">
                                <span className="port">
                                    <img
                                        src={Images.IC_PORT_12x14}
                                        alt="Depot Port"
                                    />
                                    {cellValues.value.totalPorts} {t("common.station", { count: cellValues.value.totalPorts })}{", "}
                                    {cval?.chargingStations} {t("common.port", { count: cval?.chargingStations })}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "assignDelegates",
            headerName: (toUpper(t("common.assigndelegates"))),
            hide: true,
            sortable: false,
            cellClassName: "",
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="done_mark">
                        {cellValues.value &&
                            <img src={Images.ic_done} alt="Done" />
                        }
                    </div>
                );
            },
        },
        {
            field: "reports",
            headerName: (toUpper(t("common.reports&guages"))),
            hide:true,
            sortable: false,
            hasFocus: false,
            cellClassName: "t",
            className: "port",
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="done_mark">
                        {cellValues.value &&
                            <img src={Images.ic_done} alt="Done" />
                        }
                    </div>
                );
            },
        },
        {
            field: "address",
            headerName: (toUpper(t("common.location.information"))),
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="done_mark">
                        {cellValues.value &&
                            <img src={Images.ic_done} alt="Done" />
                        }
                    </div>
                );
            },
        },
        {
            field: "schedule",
            headerName: (toUpper(t("common.usagerate"))),
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="done_mark">
                        {cellValues.value &&
                            <img src={Images.ic_done} alt="Done" />
                        }
                    </div>
                );
            },
        },
        {
            field: "commands",
            headerName: (toUpper(t("common.commands"))),
            sortable: false,
            headerClassName: "action_columns",
            hide:true,
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return (
                    <div className="done_mark">
                        {cellValues.value &&
                            <img src={Images.ic_done} alt="Done" />
                        }
                    </div>
                );
            },
        },
        {
            field: "lastCell",
            headerName: t("common.lastcell"),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ]
    return locationListColumns;
};
export const EditLocationPermissionColumnsComponent = () => {
    const { t } = useTranslation();

    const editLocationpermission: any[] = [
        {
            field: "firstCell",
            headerName: t("common.firstcell"),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
        {
            field: "haveAccess",
            headerName: (toUpper(t("hostsetting.assign"))),
            headerClassName: "action_columns",
            sortable: false,
            width: 80,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return cellStatus(cellValues.value, cellValues.row.locationDetailsDTO.locationId, cellValues.row.id, cellValues.row.haveAccess, 'haveAccess');
            },
        },
        {
            field: "locationDetailsDTO",
            headerName: (toUpper(t("common.locations"))),
            headerClassName: "action_columns",
            sortable: false,
            flex: 0.2,
            minWidth: 250,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                const address = cellValues?.value?.address;
                const cellVal = cellValues?.value;
                return (
                    <div className="table_locaion_details">
                        <div className="table_pic listMapThumb">
                            <CommonGoogleMap
                                center={{
                                    lat: address?.latitude,
                                    lon: address?.longitude,
                                }}
                                zoom={
                                    depotGoogleMap.zoom
                                }
                                markers={[
                                    {
                                        lat: address?.latitude,
                                        lon: address?.longitude,
                                    },
                                ]}
                            />
                        </div>
                        <div className="depot_info">
                            <div className="depot_name">{cellVal?.locationName}</div>
                            <div className="depot_addr">
                                {address.addressLine1}
                                <br />
                                {address.city}, {address.state} {address.postalCode}, {address.country}
                            </div>
                            <div className="port_details">
                                <span className="port">
                                    <img
                                        src={Images.IC_PORT_12x14}
                                        alt="Depot Port"
                                    />
                                    {cellValues.value.totalPorts} {t("common.station", { count: cellValues.value.totalPorts })}{", "}
                                    {cellVal?.chargingStations} {t("common.port", { count: cellVal?.chargingStations })}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "assignDelegates",
            headerName: (toUpper(t("common.assigndelegates"))),
            hide: true,
            headerClassName: "action_columns",
            sortable: false,
            hasFocus: false,
            cellClassName: "t",
            className: "port",
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return cellStatus(cellValues.value, cellValues.row.locationDetailsDTO.locationId, cellValues.row.id, cellValues.row.haveAccess, 'assignDelegates');
            },
        },
        {
            field: "reports",
            headerName: (toUpper(t("common.reports&guages"))),
            hide:true,
            headerClassName: "action_columns",
            sortable: false,
            minWidth: 110,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return cellStatus(cellValues.value, cellValues.row.locationDetailsDTO.locationId, cellValues.row.id, cellValues.row.haveAccess, 'reports');
            },
        },
        {
            field: "address",
            headerName: (toUpper(t("common.location.information"))),
            headerClassName: "action_columns",
            sortable: false,
            width: 220,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return cellStatus(cellValues.value, cellValues.row.locationDetailsDTO.locationId, cellValues.row.id, cellValues.row.haveAccess, 'address');
            },
        },
        {
            field: "schedule",
            headerName: (toUpper(t("common.usagerate"))),
            headerClassName: "action_columns",
            sortable: false,
            width: 110,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return cellStatus(cellValues.value, cellValues.row.locationDetailsDTO.locationId, cellValues.row.id, cellValues.row.haveAccess, 'schedule');
            },
        },
        {
            field: "commands",
            headerName: (toUpper(t("common.commands"))),
            headerClassName: "action_columns",
            hide:true,
            sortable: false,
            minWidth: 100,
            flex: 0.1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return cellStatus(cellValues.value, cellValues.row.locationDetailsDTO.locationId, cellValues.row.id, cellValues.row.haveAccess, 'commands');
            },
        },
        {
            field: "lastCell",
            headerName: t("common.lastcell"),
            headerClassName: "empty",
            sortable: false,
            hasFocus: false,
            cellClassName: "empty",
            width: 15,
            maxWidth: 25,
            minWidth: 28,
            disableColumnMenu: true,
            renderCell: (cellValues: any) => {
                return <div className="empty">{cellValues.value}</div>;
            },
        },
    ]
    return editLocationpermission;
}
