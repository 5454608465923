import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UserInfoList from "../userInfoList";
import UserInfoAdd from "../userInfoAdd";
import UserInfoEdit from "../userInfoEdit";
import {
  ManageUserInfoList,
  UserDeleteInfoOpen,
  UserEditInfoOpen,
} from "common/interfaces/manageuser";
import UserInfoListView from "../userInfoListView";
import { UserGroupInfo } from "common/interfaces/userGroupInfo";
import UserInfoUpload from "../uploadUser";
import { useTranslation } from "react-i18next";
import { GetUrlDetails } from "common";
import { useHistory } from "react-router-dom";
import UserInfoDelete from "../userInfoDelete";

const UserInfo = (props: any) => {
  const { t } = useTranslation();
  const [openAddNewUser, setAddnewUserOpen] = useState(false);
  const [uploadUserGroup, setUploadgroupOpen] = useState(false);
  const [manageUserInfoListView, setManageUserInfoListView] = useState<UserGroupInfo>({});
  const history = useHistory();
  let { pathname, hash, viewType } = GetUrlDetails(history, 'userGroup');
  let userGroupId = GetUrlDetails(history,'userGroup');
  const VIEW_GROUP_INFO = 'info';

  useEffect(()=>{
    if(userGroupId.viewType === VIEW_GROUP_INFO ){
      setManageUserInfoListView({
        showInfo: true,
      }); 
    }
  },[viewType]);

  useEffect(() => {
    if(userGroupId.userDetailsId){
      if(!manageUserInfoListView?.userData?.info){
        history.goBack();
      }
    }
 },[userGroupId]);

  const manageUserEvent = (value: ManageUserInfoList) => {
    let userDetailsId = value.id 
    let searchParams = "?userGroup="+ userGroupId.userGroup + "&userDetailsId=" + userDetailsId;
    setManageUserInfoListView({
      showInfo: true,
      userData: { info: value, edit: {}, delete: {} },
    });
    history.push({
      pathname:  pathname,
      search: searchParams,
      hash: hash 
   });
  };

  const addnewUserOpen = () => {
    setAddnewUserOpen(true);
  };
  const addNewUserClose = () => {
    setAddnewUserOpen(false);
  };


const uploadUserGroupOpen = () => {
    setUploadgroupOpen(true);
};
const uploadUserGroupClose = () => {
    setUploadgroupOpen(false);
};


  const userEditEventOpen = (value: UserEditInfoOpen) => {
    setManageUserInfoListView({
      showEdit: true,
      showInfo: true,
      userData: { info: value.manageUserInfoListView, edit: value.manageUserInfoListView },
    });
  };

  const userDeleteEventOpen = (value: UserDeleteInfoOpen) => {
    setManageUserInfoListView({
      showDelete: true,
      showInfo: true,
      userData: { info: value.manageUserInfoListView, delete: value.manageUserInfoListView },
    });
  };

  const manageUserDeleteEventClose = (data: any) => {
    setManageUserInfoListView({ 
      showDelete: false,
      showInfo: true,
      userData: { info: data, delete: data },
     });
  };

  const manageUserEditEventClose = (data: any) => {
    setManageUserInfoListView({ 
      showEdit: false,
      showInfo: true,
      userData: { info: data, edit: data },
     });
  };

  const userDeleteSave = (data: any) => {
    manageUserDeleteEventClose(data);
    refreshListAfterDelete();
  }

  const refreshListAfterDelete = () => {
    setManageUserInfoListView({
      showEdit: false,
      showDelete: false,
      showInfo: false,
    });
    setAddnewUserOpen(false)
  }

  return (
    <>
    <div data-testid="user_info_page">
      {/*----Page Mid Section Start----*/}
      {!manageUserInfoListView.userData?.info && (
        <div className="button_row text_right">
          <Button className="btn_white blue_bg m_w_157" onClick={addnewUserOpen}>
            {t("usergroupdetail.adduser")}
          </Button>
          <Button className="btn_white blue_bg m_w_157" onClick={uploadUserGroupOpen}>
            {t("usergroupdetail.uploaduser")}
          </Button>
          
        </div>
      )}

      {/* User Info Upload Start */}
      <UserInfoUpload 
        uploadUserGroup={uploadUserGroup}
        uploadUserGroupClose={uploadUserGroupClose}
        refreshDetails={props?.refreshDetails}        
      />
      {/* User Info Upload End */}
                  
      {/* User Info Add Start */}
      <UserInfoAdd
        openAddNewUser={openAddNewUser}
        addNewUserClose={addNewUserClose}
        refreshDetails={props?.refreshDetails}        
      />
      {/* User Info Add End */}

      {/* User Info List and View Start */}
      {manageUserInfoListView.showInfo &&
      manageUserInfoListView.userData?.info ? (
        <UserInfoListView
          manageUserEditEvent={userEditEventOpen}
          manageUserDeleteEvent={userDeleteEventOpen}
          manageUserInfoListView={manageUserInfoListView.userData.info}
        />
      ) : (
        <UserInfoList  
          refreshList={openAddNewUser} 
          refreshUpload={uploadUserGroup}
          manageUserEvent={manageUserEvent} />
      )}
      {/* User Info List and View End */}

      {/* User Info Delete Start */}
      {manageUserInfoListView.showDelete && 
        manageUserInfoListView.userData?.delete && (
          <UserInfoDelete 
            userDeleteEventOpen={manageUserInfoListView.showDelete}
            userDeleteEventClose={manageUserDeleteEventClose}
            manageUserInfoEditData={manageUserInfoListView.userData?.delete}
            userGroupId={userGroupId.userGroup}
            userDeleteSave={userDeleteSave}
            refreshDetails={props?.refreshDetails}
          />
        )}
      {/* User Info Delete End */}

      {/* User Info Add Start */}
      {manageUserInfoListView.showEdit &&
        manageUserInfoListView.userData?.edit && (
          <UserInfoEdit
            setManageUserInfoListView={userEditEventOpen}
            manageUserInfoEditData={manageUserInfoListView.userData?.edit}
            openEditUser={manageUserInfoListView.showEdit}
            userGroupId={userGroupId.userGroup}
            refreshListAfterDelete={refreshListAfterDelete}
            closeEditUser={manageUserEditEventClose}
          />
        )}
      {/* User Info Add End */}
    </div>
    </>
  );
};
export default UserInfo;
