import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {DELETE_FIELD} from "../fleetManagementHelper";
import {
    CheckAccess,
    claims,
    defaultVariable,
    HandlePaginationLabel,
    keyCloakConfig,
    pageDetails,
    rolePermissionCheck
} from "../../../common";
import {ObjectDetails} from "../../../common/interfaces";
import {fetchFleetAssignedStation} from "../../../services/fleetManagementService";
import {Button, CircularProgress} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {columnNameTranslate} from "../../../common/methodHelpers";
import Grid from "@material-ui/core/Grid/Grid";
import CommonPagination from "../../../components/commonPagination";
import {FleetAssignedStationListColumnsComponent} from "../../tableColumns/fleetChargerListColumns";
import FleetAssignStation from "./fleetAssignStation";
import FleetChargerRemove from "./fleetChargerRemove";


const FleetChargers = (props: any) => {
    const {t} = useTranslation();
    
    const {groupId, vehicleId, isGroupLoading} = props;
    const pageSize = pageDetails.pageSize;
    const [fleetChargerList, setFleetChargerList] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState(false);
    const [removeCharger, setRemoveCharger] = useState(false);
    const [assignStation, setAssignStationOpen] = useState(false);
    const [selectedCharger, setSelectedCharger] = useState<any>();
    const fleetChargerAssignedColumn = FleetAssignedStationListColumnsComponent({hideAutoAuthorise: vehicleId});
    const userRoles = keyCloakConfig?.realmAccess?.roles;
    const isDelegate = rolePermissionCheck(userRoles, [defaultVariable.ROLES.delegate]);
    const showAssignStationButton = (vehicleId ? CheckAccess([claims.VEHICLE_CREATE]) : CheckAccess([claims.FLEET_CREATE])) && !isDelegate;


    const dataRefresh = () => {
        getFleetChargerList();
        props.dataRefresh();
    };

    const assignStationToFleetOpen = () => {
        setAssignStationOpen(true);
    };

    const getFleetChargerList = () => {
        let params: any = {page: currentPage, size: pageDetails.pageSize, groupId: groupId};
        if (vehicleId) {
            params['vehicleId'] = vehicleId;
        }
        setLoadingPage(true);
        fetchFleetAssignedStation(params, false)
            .then((res: any) => {
                setFleetChargerList(res);
                setLoadingPage(false);
            })
            .catch((err: any) => {
                setLoadingPage(false);
                console.error("Error", err);
            });
    };

    const fleetChargersListDataSet = (value: any) => {
        let fleetTableData = [];
        if (value?.stations) {
            fleetTableData = value.stations?.map((val: any, index: any) => ({
                ...val,
                id: val?.id ? val.id : index
            }))
        }
        return fleetTableData
    };

    const handleCellSelectionModelChange = (event: ObjectDetails) => {
        if(event.field === DELETE_FIELD) {
            setSelectedCharger(event.row);
            setRemoveCharger(true);
        }
    };

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)
    };

    useEffect(() => {
        if(groupId !== ''){
            getFleetChargerList();  
        }
    }, [currentPage, groupId, vehicleId]);


    return (
        <>
            <div className="information_section">
                <div className="chargers">
                    {loadingPage || isGroupLoading ? <CircularProgress className="inner_progress_userGroup"/> :
                        <div className="host_settings">
                            <div className="button_row text_right">
                                {showAssignStationButton &&
                                <Button className="btn_white blue_bg" onClick={assignStationToFleetOpen}>
                                    {vehicleId ? t("fleet.assignStation") : t("fleet.assignStations")}
                                </Button>
                                }
                            </div>
                            <div className="table_scroll" data-testid="evse_depot_charge_history">
                                <div className="default_table">
                                    <DataGrid autoHeight className="table"
                                              rowHeight={59}
                                              headerHeight={30}
                                              rows={fleetChargersListDataSet(fleetChargerList)}
                                              columns={columnNameTranslate(fleetChargerAssignedColumn)}
                                              pageSize={pageSize}
                                              onCellClick={handleCellSelectionModelChange}
                                              disableSelectionOnClick/>
                                </div>
                                <div className="table_footer">
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                        <Grid className="text_left" item xs={8}>
                                            {fleetChargerList && fleetChargerList?.totalCount > pageSize ?
                                                <CommonPagination
                                                    currentPage={currentPage}
                                                    totalCount={fleetChargerList?.totalCount}
                                                    callBackCurrentPage={handleCurrentPage}
                                                /> : <></>}
                                        </Grid>
                                        <Grid className="text_right" item xs={4}>
                                            <div className="list_number">
                                                {HandlePaginationLabel(fleetChargerList?.totalCount, pageSize, currentPage, fleetChargerList?.totalCount > 1 ? "common.stations" : "common.station")}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <FleetChargerRemove removeCharger={removeCharger}
                                selectedCharger={selectedCharger}
                                setRemoveCharger={setRemoveCharger}
                                setRefreshData={dataRefresh}/>
            <FleetAssignStation assignStation={assignStation}
                                setAssignStationOpen={setAssignStationOpen}
                                groupId={groupId}
                                setRefreshData={dataRefresh}
                                vehicleId={vehicleId}/>
        </>
    );
};
export default FleetChargers;