import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toUpper } from "lodash";
import {
    Grid,
    CardContent,
    Card,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { GROUP } from "../fleetManagementHelper";
import Images from "assets/images";
import { CheckAccess, claims} from "common";
import { fetchAlertInfo, manageFleetData } from "services/fleetManagementService";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import UpdateFleetAlert from "./updateFleetAlert";
import AlertDetails from "./alertDetails";

const FleetCommonAlert = (props: any) => {
    const { t } = useTranslation();
    let initialValue: any = {
        DEPARTURE_BELOW_MIN: false,
        RETURN_LATE: false,
        DEPARTURE_EARLY: false,
    };
    const [alertInfo, setAlertInfo] = useState<any>(initialValue);
    const [alertInfoEdit, setAlertInfoEdit] = useState<any>(initialValue);
    const dispatch = useDispatch();
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [showData, setShowData] = useState(false);
    const showAlertEditButton = props.type === GROUP ? CheckAccess([claims.FLEET_UPDATE]) : CheckAccess([claims.VEHICLE_UPDATE]);
    const {groupId, vehicleId} = props;

    const getAlertData = () => {
        setSpinnerOff(true);
        setShowData(false);
        let params =
            props.type === GROUP
                ? { groupId: groupId }
                : { groupId: groupId, vehicleId: vehicleId };
        fetchAlertInfo(params, false)
            .then((res: any) => {
                if (res) {
                    setAlertInfo(res);
                    setAlertInfoEdit(res);
                    setShowData(true);
                    setSpinnerOff(false);
                }
            })
            .catch((err: any) => {
                console.error("Error", err);
            });
    };

    const enableEdit = () => {
        setEditStatus(true);
    };

    useEffect(() => {
      if(props?.groupId !== ''){
          getAlertData();
      }
    }, [props?.groupId]);

    const updateAlerts = () => {
        setSpinnerOff(true);
        let paramObj = { action: "createFleetAlerts" };
        let body = {
            code: alertInfoEdit,
            group_id: groupId,
            vehicle_id: vehicleId,
        };
        manageFleetData(paramObj, body).then((res: any) => {
            if (res?.status === true) {
                setEditStatus(false);
                getAlertData();
            } else {
                dispatch(
                    showErrorMessages({
                        error: true,
                        message: res.message,
                        type: "error",
                        operation: "",
                    })
                );
                setSpinnerOff(false);
            }
        });
    };

    const closeAlertPopup = () => {
        setAlertInfoEdit(alertInfo);
        setEditStatus(false);
    }

    const setAlertStatus = (event: any, label: any) => {
        let status = event.target.value === 'true';
        setAlertInfoEdit({ ...alertInfoEdit, [label]: status });
    };

    return (
        <>
            <UpdateFleetAlert
                openAlert={editStatus}
                setEditStatus={setEditStatus}
                closeAlert={closeAlertPopup}
                saveAlert={updateAlerts}
                spinnerOff={spinnerOff}
                editStatus={editStatus}
                alertInfoEdit={alertInfoEdit}
                showData={showData}
                setAlertStatus={setAlertStatus}
            />
            <div className="information_section">
                <Grid container spacing={2}>
                    <Grid className="mid_section_left" item xs={12}>
                        <Card variant="outlined" className="basic_card">
                            <CardContent>
                                {spinnerOff && (
                                    <CircularProgress className="inner_progress_userGroup" />
                                )}
                                {/*----Title Start----*/}
                                <div className="title_row">
                                    <h2>{toUpper(t("fleet.notifications"))}</h2>
                                    {showAlertEditButton &&
                                        <div className="action_icon">
                                            <IconButton
                                                className="top_action_btn"
                                                onClick={enableEdit}>
                                                <img
                                                    src={Images.ic_edit}
                                                    alt="edit"
                                                />
                                            </IconButton>
                                        </div>
                                    }
                                </div>
                                {/*----Title End----*/}

                                {/*----Information Start----*/}
                                <AlertDetails
                                    editStatus={editStatus}
                                    alertInfoEdit={alertInfo}
                                    showData={showData}
                                    setAlertStatus={setAlertStatus}
                                    dialogContent = {false}
                                />
                                {/*----Information End----*/}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default FleetCommonAlert;
