import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
import { useTranslation } from "react-i18next";
import { upperCase } from 'lodash';
import { BlinkMyCardComponent } from "pages/tableColumns";
import { CheckAccess, claims, ColumnNameTranslate, HandlePaginationLabel, pageDetails, urlReplace } from 'common';
import CommonDownloadCsv from "components/commonDownloadCsv";
import CommonPagination from "components/commonPagination";
import { InfoList } from "common/interfaces/manageuser";
import moment from "moment";
import { getMethod, ApiEndPoint } from "services";
import ActivateBlinkCard from "./activateBlinkCard";
import ManageBlinkCard from "./manageBlinkCard";

const BlinkMyCard = (props: any) => {
    const { t } = useTranslation();
    const columnValues = BlinkMyCardComponent();
    const pageSize = pageDetails.pageSizeTwenty;
    const [currentPage, setCurrentPage] = useState(0);
    const [blinkMyCardInfo, setBlinkMyCardInfo] = useState<any>([]);
    const [openActivateBlinkCard, setActivateBlinkCardOpen] = useState(false);
    const [openManageBlinkCard, setManageBlinkCardOpen] = useState(false);
    const [manageBlinkCardInfo, setManageBlinkCardInfo] = useState();

    const getBlinkMyCardInfoData = (rowData: any) => {
        setManageBlinkCardOpen(true);
        setManageBlinkCardInfo(rowData)

    };

    const getGroupDetailsList = async () => {
        const url = urlReplace(ApiEndPoint.blinkMyCard, "", "");
        const paramDetails = '?page=' + currentPage + '&size=' + pageSize;
        
        await getMethod(url, paramDetails).then((res) => {
                setBlinkMyCardInfo(res.data);
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    const fileHeader: any[] = [
        { label: upperCase(t('common.name')), key: t('blinkcard.fileheader.userName') },
        { label: upperCase(t('common.email')), key: t('common.email') },
        { label: upperCase(t('common.rfidcard')), key: t('blinkcard.fileheader.rfids') },
    ];

    const fileName: string = `${t('common.rfidcard')}_${moment().format('MMDDYYYYHHmmss')}.csv`;

    const DownloadCsvBlinkMyCardInfoListDataSet = () => {
        let chargeHistoryTableData: InfoList[] = [];
        if (blinkMyCardInfo && blinkMyCardInfo.users) {
            chargeHistoryTableData = blinkMyCardInfo.users.map(
                (val: InfoList) => ({
                    ...val,
                    id: val.id
                })
            );
        }
        return chargeHistoryTableData;
    };

    const handleCurrentPage = (setPage: number) => {
        setCurrentPage(setPage);
    };

    useEffect(() => {
        setBlinkMyCardInfo([])
        getGroupDetailsList();
    }, [currentPage])

    const activateBlinkCardOpen = () => {
    setActivateBlinkCardOpen(true);
    };
    const activateBlinkCardClose = () => {
    setActivateBlinkCardOpen(false);
    };

    
    const manageBlinkCardClose = () => {
    setManageBlinkCardOpen(false);
    };

    return (
        <>
        {
            CheckAccess([claims.FORBIDDEN_ACCESS]) && <div className="mt-0">
                <div className="button_row text_right" onClick={activateBlinkCardOpen}>
                    <button className=" btn_white blue_bg">
                        {t("common.activateblinkcard")}
                    </button>
                </div>
            </div>
        }

        <ActivateBlinkCard openActivateBlinkCard={openActivateBlinkCard} activateBlinkCardClose={activateBlinkCardClose} />
                    
        <ManageBlinkCard openManageBlinkCard={openManageBlinkCard} manageBlinkCardClose={manageBlinkCardClose} ManageBlinkCardInfo={manageBlinkCardInfo} />
            
            <div className="table_scroll mt-30" data-testid="blink_card_table">
                <div className="default_table">
                    <DataGrid autoHeight className="table"
                        rowHeight={59}
                        headerHeight={30}
                        rows={blinkMyCardInfo}
                        disableSelectionOnClick
                        vertical-align="top"
                        columns={ColumnNameTranslate(columnValues)}
                        pageSize={pageSize}
                        onRowClick={(rowdata: any) => getBlinkMyCardInfoData(rowdata)}
                    />
                </div>
                <div className="table_footer">
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid className="text_left" item xs={8}>
                            {blinkMyCardInfo && blinkMyCardInfo.totalUsers > pageSize ? (
                                <CommonPagination
                                    currentPage={currentPage}
                                    totalCount={blinkMyCardInfo?.totalUsers}
                                    callBackCurrentPage={handleCurrentPage}
                                />
                            ) : (
                                    <></>
                                )}
                            {blinkMyCardInfo && blinkMyCardInfo.totalUsers ? (
                                <CommonDownloadCsv
                                    {...props}
                                    datas={DownloadCsvBlinkMyCardInfoListDataSet()}
                                    headers={fileHeader}
                                    filename={fileName}
                                    callBackDownloadCsv={getGroupDetailsList}
                                />
                            ) : (
                                    <></>
                                )}
                        </Grid>
                        <Grid className="text_right" item xs={4}>
                            <div className="list_number">
                                {HandlePaginationLabel(blinkMyCardInfo?.totalUsers, pageSize, currentPage, 'common.rfidcard')}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};
export default BlinkMyCard;