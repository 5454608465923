import React, { useState } from "react";
import './index.scss';
import { KeyboardArrowRight } from '@material-ui/icons';
import { PaginationCardDetails } from 'common/interfaces'

const CommonPaginationCard = (props: PaginationCardDetails) => {
    const defaultPage = 0;
    const totalPages = (props?.data?.length - 1);
    const [currentPage, setCurrentPage] = useState(defaultPage);

    const goToNextPage = () => {
        if (totalPages > currentPage) {
            let setPage = (currentPage + 1);
            setCurrentPage(setPage);
        } else {
            setCurrentPage(defaultPage);
        }
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage * props.dataLimit);
        const endIndex = (startIndex + props.dataLimit);
        return props.data.slice(startIndex, endIndex);
    };

    const iconButton = (val: boolean) => {
        if (val) {
            return <button onClick={goToNextPage} className={`icon next`}>
                <KeyboardArrowRight />
            </button>;
        } else {
            return "";
        }
    }

    return (
        <div data-testid="pagination_card">
            <div className="pagination-card">
                {iconButton(props.showIcons)}
            </div>
            <div className="dataContainer">
                {getPaginatedData().map((d: any) => (
                    <props.RenderComponent key={d?.id} data={d} />
                ))}
            </div>
        </div>
    );
};

export default CommonPaginationCard;