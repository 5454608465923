import { DataGrid } from "@material-ui/data-grid";
import Images from "assets/images";
import { EDIT, HandlePaginationLabel, keyCloakConfig, pageDetails, useStyles } from "common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MemberRequestColumnsComponent } from "pages/tableColumns/hostMemberRequestColumns";
import { CircularProgress, Dialog, Grid } from "@material-ui/core";
import CommonPagination from "components/commonPagination";
import CommonDownloadCsv from "components/commonDownloadCsv";
import { FileHeaderMembershipComponent, fileNameMembershiprequest } from "./hostMembershipHelper";
import { fetchTotalMembershipRequests } from "services/membershipService";
import HostMembershipRequestPopup from "./hostMembershipRequestPopup";

import { columnNameTranslate, getFormattedDateTimeWithTZ } from "common/methodHelpers";


const HostMemberRequest = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    
    const memberRequestColumns = MemberRequestColumnsComponent();
    const pageSize = pageDetails.pageSize;
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState(false);
    const [membershipDetails, setMembershipDetails] = useState<any>({});
    const [membershipRequestCsvData, setMembershipRequestCsvData] = useState<any>([]);
    const FileHeaderMembership = FileHeaderMembershipComponent();
    const [membershipStatus, setMembershipStatus] = useState("");
    const [openMembershiprequestPopup, setOpenMembershiprequestPopup] = useState(false);
    const [userId, setUserId] = useState("");
    const hostId = keyCloakConfig?.tokenParsed?.hostId;

    useEffect(() => {
        getMembershipRequestDetails()
    }, [currentPage])


    const membershipRequestCsvDataSet = async() => {
        let params = {"hostId": hostId }
        await fetchTotalMembershipRequests(params, false, false, true).then((res: any) => {
            let membershipRequestCsvDatas = [];
            if (res.users.length > 0) {
                 membershipRequestCsvDatas = res.users.map(
                    (val: any, index: any) => ({
                        ...val,
                        requested_date: getFormattedDateTimeWithTZ(val?.requested_date),
                    })
                );
            }
            setMembershipRequestCsvData(membershipRequestCsvDatas);
        })   
    };

    const handleCellClick = (params: any) => {
        if (params.field === EDIT) {
            setMembershipStatus(params.row.status);
            setOpenMembershiprequestPopup(true);
            setUserId(params.row.user_id);
        }
    }

    const membershipRequestPopupClose = () => {
        setOpenMembershiprequestPopup(false);
    }

    const getMembershipRequestDetails = async () => {
        setLoadingPage(true);
        let params = { "currentPage": currentPage, "size": pageDetails.pageSize, "hostId": hostId }
        fetchTotalMembershipRequests(params, false).then((res: any) => {
            setLoadingPage(false);
            setMembershipDetails(res)
        })
    }

    const handleCurrentPage = (setPage: any) => {
        setCurrentPage(setPage)
    }

    const MembershipDataSet = (value: any) => {
        let membershipTableData = []
        if (value && value.users) {
            membershipTableData = value.users.map((val: any, index: number) => ({ ...val, id: index }))
        }
        return membershipTableData
    }

    return (
        <>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info sm dialog_container_center"
                    open={openMembershiprequestPopup}
                    onClose={membershipRequestPopupClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <HostMembershipRequestPopup
                        membershipRequestPopupClose={membershipRequestPopupClose}
                        openMembershiprequestPopup={openMembershiprequestPopup}
                        membershipStatus={membershipStatus}
                        hostId={hostId}
                        userId={userId}
                        getMembershipRequestDetails={getMembershipRequestDetails}
                    />
                </Dialog>
            </div>
            <main className={classes.content}>
                <div className="host_membership_settings">
                    <div className="depot_details members station_details">
                        <div className="depot_detail_head position_fixed_head">
                            <div className="depot_information">
                                <div className="depot_location_map">
                                    <img src={Images.shift_driver_group} alt="Port" />
                                </div>
                                <div className="depot_info">
                                    <div className="depot_name">
                                        {t("membership.requests")}
                                    </div>
                                    <div className="member_deails">
                                        <div className="member_info ">
                                            <div className="port_details">
                                                <span className="alert">
                                                    <img
                                                        src={
                                                            Images.ic_driver_group
                                                        }
                                                        alt={t('membership.requests')}
                                                    />
                                                    {
                                                        membershipDetails?.totalCount
                                                    }{" "}
                                                    {t("membership.requests")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loadingPage ? <CircularProgress className="inner_progress_userGroup" /> :
                            <div className="table_scroll mt-10">
                                <div className="default_table">
                                    <DataGrid autoHeight className="table"
                                        rowHeight={59}
                                        headerHeight={30}
                                        rows={MembershipDataSet(membershipDetails)}
                                        columns={columnNameTranslate(memberRequestColumns)}
                                        pageSize={pageSize}
                                        onCellClick={handleCellClick}
                                        disableSelectionOnClick />
                                </div>
                                <div className="table_footer mt-10">
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                        <Grid className="text_left" item xs={8}>
                                            {membershipDetails && membershipDetails?.totalCount > pageSize ?
                                                <CommonPagination
                                                    currentPage={currentPage}
                                                    totalCount={membershipDetails?.totalCount}
                                                    callBackCurrentPage={handleCurrentPage}
                                                /> : <></>}
                                            {membershipDetails && membershipDetails?.totalCount > 0 &&
                                                <CommonDownloadCsv
                                                    datas={membershipRequestCsvData}
                                                    filename={fileNameMembershiprequest}
                                                    headers={FileHeaderMembership}
                                                    blueclass={"border"}
                                                    callBackDownloadCsv={membershipRequestCsvDataSet} />
                                            }
                                        </Grid>
                                        <Grid className="text_right" item xs={4}>
                                            <div className="list_number">
                                                {HandlePaginationLabel(membershipDetails?.totalCount, pageSize, currentPage, "membership.membershiprequests")}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
        </>
    );
};

export default HostMemberRequest;
