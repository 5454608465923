import React, { useState } from "react";
import {DialogContentText, DialogContent, Button} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { depotGoogleMap } from "common";
import CommonGoogleMap from "components/commonGoogleMap";

const PolygonMapDetails = (props: any) => {
  const { address } = props?.locationInformationDetails || {};
  const editFormType = 'Edit';
  const { t } = useTranslation();
  const [coordinates, setCoordinates] = useState(props?.pathCoord ?? '');

  const callbackMethod = () => {
    props?.viewAssignedStationMethod(coordinates)
  }

  const callbackStations = () => {
    props?.assignedStationMethod(props?.zoneStationsPost)
  }

  const getcoordinates = (value: any) => {
    setCoordinates(value)
  }

  const polygonTypeValue = (polygonTypeVal: string) => {
    return polygonTypeVal === editFormType
      ? t("common.editcapturezone")
      : t("common.capturezone");
  };


  return <>
    <div className="dialog_title">
      <h4>{polygonTypeValue(props?.formType)}</h4>
      <div className="button_row mt-0 full_right">
        <Button
          className="btn_white text_btn_theme"
          onClick={props?.zoneClose}
        >
          {t("common.cancelbutton")}
        </Button>
        <Button className="btn_white outline_btn increase_padding" onClick={callbackStations}>
          {t("common.backbutton")}
        </Button>
        <Button className="btn_white blue_bg increase_padding" onClick={callbackMethod}>
          {t("common.nextbutton")}
        </Button>
      </div>
    </div>
    <DialogContent dividers="paper" {...props}>
      <DialogContentText id="scroll-dialog-description">
        <div className="capture_zone">
          <div className="inpr_text">
            {t("hostsetting.zone.polygoninfo")}
          </div>
          <div className="polygon_zone_map_height zone_map">
            <CommonGoogleMap
              coordinates={getcoordinates}
              center={{
                lat: address?.latitude,
                lon: address?.longitude,
              }}
              zoom={
                depotGoogleMap.zoom
              }
              markers={[
                {
                  lat: address?.latitude,
                  lon: address?.longitude
                },
              ]}
              polygon={true}
              pathCoord={props.pathCoord}
              clearPolygone={props.clearPolygone}
            />
          </div>
        </div>
      </DialogContentText>
    </DialogContent>

  </>;
};

export default PolygonMapDetails;
