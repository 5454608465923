import React, {useEffect, useRef, useState} from "react";
import {Dialog, Button, DialogContent, DialogContentText} from "@material-ui/core";
import {ApiEndPoint, getIpAddressMethod, postMethod} from "services";
import {useTranslation} from "react-i18next";
import WelcomeMessage from "components/welcomeMessage";
import { keyCloakConfig } from "common";


const TermsConditions = (props: any) => {

    const listInnerRef = useRef<any>();
    const [, setButtonStatus] = useState(false);
    const [ip, setIp] = useState<any>('');
    const termsWebAgreementId =  "a276e94f-4ad8-201b-2e82-520000000009";
    const termsStatus = "Accepted";
    const { t } = useTranslation();
    const [openWelcomeMessage, setWelcomeMessage] = useState(false);
  

    useEffect(() => {
        getIpData();
    }, []);

    const termsConditionsClose = (newValue?: any) => {
        props?.setTermsConditions(false);
        if (newValue) {
            props?.setTermsConditions(newValue);
        }    
      }

    const serverErrorClose = (newValue?: any) => {
        props?.setOpenServerError(false);
        if (newValue) {
            props?.setOpenServerError(newValue);
        }
        keyCloakConfig.logout();
    }

    const declineCondition = () => {
        props?.setTermsConditions(false);
        keyCloakConfig.logout();
    }

    //creating function to load ip address from the API
    const getIpData = async () => {

        await getIpAddressMethod(ApiEndPoint.ipAddress)
            .then((res) => {
                if (res && res?.data && res?.data && res?.data?.IPv4) {
                    setIp(res.data.IPv4);
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });

    }
 
  const updateAgreementStatus = async () => {
    let obj = {
      webAgreementId: termsWebAgreementId,
      ipAddress: ip,
      status: termsStatus,
    };
    let response = await postMethod(ApiEndPoint.saveAgreement, "", obj);
    if (response.status === 200) {
      termsConditionsClose();
      setWelcomeMessage(true);
    }
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      setButtonStatus(true);
    }
  };

  return (
    <>
      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_detail_section terms_conditions dialog_container_center"
          open={props?.openTermsConditions}
          onClose={termsConditionsClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("dashboard.termsconditions")}</h4>
            <div className="button_row mt-0 full_right">
              <Button className="btn_white text_btn_theme" onClick={declineCondition}>
                {t("dashboard.decline")}{" "}
              </Button>
              <Button className="btn_white blue_bg" onClick={updateAgreementStatus}>
                {t("dashboard.accepttermsandconditions")}{" "}
              </Button>
            </div>
          </div>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description">
              <div className="terms_conditions_inside" ref={listInnerRef} onPointerMove={onScroll}>
                <iframe id="websiteIframe" src={ApiEndPoint.termsUrl} title="description"></iframe>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <div className="dialog_box">
        <Dialog
          className="dialog_container edit_detail_section terms_conditions dialog_container_center"
          open={props?.openServerError}
          onClose={serverErrorClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <div className="dialog_title">
            <h4>{t("common.error")}</h4>
            <div className="button_row mt-0 full_right">
              <Button className="btn_white blue_bg" onClick={serverErrorClose}>
                {t("common.ok")}{" "}
              </Button>
            </div>
          </div>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description">
              <div>{t("common.servererror")}</div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      <WelcomeMessage setWelcomeMessage={setWelcomeMessage} openWelcomeMessage={openWelcomeMessage} />
    </>
  );
};

export default TermsConditions;
