import React from "react";
import {Grid, Card, CardContent, Typography, CardActionArea,} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Images from "assets/images";
import { NavLink } from "react-router-dom";
import {HOST_TRAINING_CATEGORY} from "./trainingHelper";

const Training = () => {
    const { t } = useTranslation();
    const NAVLINK_STYLE = { textDecoration: 'none' };
    return (
        <>
            <main className="training_questions">
                {HOST_TRAINING_CATEGORY.map((value: any) => (
                    <div className="training_questions_section" key={value.label}>
                        <h2>{t(value.label)}</h2>
                        <Grid className="questions_row" container justifyContent="flex-start" alignItems="stretch" spacing={3}>
                            {value?.cards && value?.cards?.map((card: any) => (    
                                <Grid item xs={12} sm={6} lg={4}  key={card?.label}>
                                    <NavLink to={{ pathname: card?.pathname, state: {trainingType: card?.trainingType} }} style={NAVLINK_STYLE}>
                                        <Card className="questions_column">
                                            <CardActionArea>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h2" component="h2">
                                                        <span className="subheading">{t(card?.label)}</span>
                                                        <span className="link_icon">
                                                            <img src={Images.ic_link_arrow}
                                                                 alt={t("training.moredetail")}/>
                                                        </span>
                                                    </Typography>
                                                    <Typography color="textSecondary" component="p">
                                                        {t(card?.description)}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </NavLink>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ))}
            </main>
        </>
    );
};
export default Training;
