import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    LinearProgress
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { prepareBodyForDelete } from "../pricingPlanHelpers";
import { deletePricingPlan } from "services/pricingPlanService";
import { showErrorMessages } from "redux/actions/errorMessages";
import { useDispatch } from "react-redux";
import "../semaConnectChargeFee.scss";
import { MultiSelectDropdownComponent } from "components/multiSeclectDropdownComponent";

const DeletePricingPlan = (props: any) => {

    const { t } = useTranslation();
    const [unAssignPricingPlans, setUnAssignPricingPlans] = useState(props.unAssignPricingPlanList);
    const [selectedPricingPlans, setSelectedPricingPlans] = React.useState<any>([t("pricing.selectpricingplan")]);
    const [selectedPricingPlansArray, setSelectedPricingPlansArray] = useState([]);
    const [spinnerOff, setSpinnerOff] = useState(false);
    const [deleteButton, setDeleteButton] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let stationsArray = unAssignPricingPlans.map((stations: any) => {
            return stations;
        })
        setSelectedPricingPlansArray(stationsArray);
    }, [])

    const deletePopupClose = () => {
        props.deletePricingplanClose(false);
    }

    const deletePricingPlanData = () => {
        let body = prepareBodyForDelete(unAssignPricingPlans, selectedPricingPlans);
        setSpinnerOff(true);
        deletePricingPlan(body).then((res: any) => {
            if (res?.status === true) {
                setSpinnerOff(false);
                props.deletePricingplanClose(true);
            }
            else {
                setSpinnerOff(false);
                dispatch(showErrorMessages({ error: true, message: res.message, type: "error", operation: "" }));
            }
        });
    }

    return (
        <>
            <div className="dialog_box">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info md dialog_container_center"
                    {...props}
                    open={props.deletePricingOpen}
                    onClose={deletePopupClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >                    
                    <div className="dialog_title">
                        <h4>{t("pricing.deleteplan")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={deletePopupClose} data-testid={`action-button-cancel`}
                            >
                                {t("common.cancelbutton")}
                            </Button>
                            <Button className={deleteButton ? "btn_white blue_bg" : "btn_white disable"} onClick={deletePricingPlanData}
                                disabled={!deleteButton} data-testid={`action-button-delete`}>
                                {t("common.deletebutton")}
                            </Button>
                        </div>
                    </div>
                    {spinnerOff && <LinearProgress/>}
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="information_section delegates_details">
                                <div className="defult_form mt-25">
                                    <div className="section_group">
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <div className="form_col">
                                                    <Grid
                                                        className="single_line_delete"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <label className="w_100per full_w">
                                                                {t("pricing.selectplantodelete")}
                                                            </label>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <MultiSelectDropdownComponent 
                                                            selectedValues = {selectedPricingPlans}
                                                            defaultName = {t("pricing.selectplantodelete")}
                                                            selectedValuesArray = {selectedPricingPlansArray}
                                                            deletePricing = {true}
                                                            setSubmitButton = {setDeleteButton}
                                                            setSelectedValues = {setSelectedPricingPlans}
                                                            defaultPlaceHolder = {t("pricing.selectpricingplan")}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default DeletePricingPlan;