import React  from "react";
import { useTranslation } from "react-i18next";
 import "../../../fleetManagement.scss";

const FleetGroupHeader = (props: any) => {
    const { t } = useTranslation();
    const { groupinfo } = props;

    return (
        <>
            <div className="fleet_information">
                {groupinfo &&
                    groupinfo?.map((value: any) => (
                        <div className="fleet_info" key={value?.id}>
                            <div className="fleet_group"> {value?.group}</div>
                            <div className="group_addr"> {value?.div_name}</div>
                            <div className="group_details">
                                <span className="fleet_span">
                                {t("fleet.noofvehicles")}: {value?.vehicles_count}
                                </span>
                                <span className="fleet_span">
                                {t("fleet.noOfChargers")}: {value?.station_count}
                                </span>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default FleetGroupHeader;
