import React from "react";
import {DialogContent, DialogContentText, Grid, Select, MenuItem, Button, LinearProgress,} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "../semaConnectChargeFee.scss";
import { BLANK } from "common";
import { getAccessTypeValue, setClassName } from "../pricingPlanHelpers";

export const Access = (props: any) => {
    const {selectedEdit, applyPricingDetails, accessToStation, selectedAccessDescription, disableAccessSaveButton, selectedSave, simpleValidator, loadingPage} = props;
    const { t } = useTranslation();
    return (
        <>
            <div className="dialog_title">
                <h4>{selectedEdit ? t("pricing.changepricingstepone") : t("pricing.applypricingstepone")}</h4>
                <div className="button_row mt-0 full_right">
                    <Button className="btn_white text_btn_theme" onClick={props.handlechangeCancel}>{t("common.cancelbutton")}</Button>
                    {selectedSave ? (
                        <>
                            <Button className={`btn_white ${setClassName(loadingPage, false, disableAccessSaveButton)}`}
                                    disabled={loadingPage ||disableAccessSaveButton}
                                    onClick={props.navigateToApplyPricingSecondStepSave}
                            >
                                {t("common.savechanges")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button className={`btn_white ${setClassName(loadingPage, false, disableAccessSaveButton)}`}
                                    disabled={loadingPage ||disableAccessSaveButton}
                                    onClick={props.navigateToApplyPricingSecondStepNext}
                            >
                                {t("common.nextbutton")}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {loadingPage && <LinearProgress />}
            <DialogContent {...props}>
                <DialogContentText id="scroll-dialog-description">
                    <div className="defult_form mt-10">
                        <div className="section_group">
                            <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                <Grid item xs={12}>
                                    <div className="form_col">
                                        <Grid className="single_line" container justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                            <Grid item xs={4}>
                                                <label>{t("pricing.accesstostation")}{BLANK}{t("common.required")}</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    className="w_100per full_w"
                                                    labelId="demo-simple-select-label"
                                                    variant="outlined"
                                                    name="accessToStations"
                                                    value={`${getAccessTypeValue(applyPricingDetails)}`}
                                                    onChange={(e) => props.handleChange(e)}
                                                >
                                                    <MenuItem className="defult_select_option" key={0} value={BLANK} disabled>
                                                        {t("pricing.selectaccesstostation")}
                                                    </MenuItem>
                                                    {accessToStation && accessToStation.map((access: any) => (
                                                        <MenuItem className="defult_select_option" key={access.id} value={access.value}>
                                                            <Grid>{access.name}</Grid>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <Grid>
                                                    <span className="description">
                                                        {selectedAccessDescription !== "" ? selectedAccessDescription : ""}
                                                    </span>
                                                </Grid>
                                                <Grid>
                                                    <cite className="srv-validation-message">
                                                        {simpleValidator.current.message(t("pricing.accesstostation"), applyPricingDetails?.accessToStations, "required")}
                                                    </cite>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    );
};
export default Access;
