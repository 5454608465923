import { getMethod, ApiEndPoint, postMethod } from "services";

export const fetchFleetList = async (params: any, mock = false) => {
    let queryParams = '';
     if(params?.isDelegate === true){
        queryParams =  params?.csvDownload === true  ?
        `?delegateId=${params?.delegateId}&csvDownload=${params?.csvDownload}`:
        `?page=${params.currentPage}&size=${params.size}&delegateId=${params.delegateId}`;
     } else {
        queryParams = params?.csvDownload === true ?
        `?hostId=${params?.hostId}&csvDownload=${params?.csvDownload}`:
        `?page=${params.currentPage}&size=${params.size}&hostId=${params.hostId}`;
     }
    const data = await getMethod(
        ApiEndPoint.fleetGetGroupsList,queryParams,mock,false,true
    )
    .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const manageFleetData = async (params: any, body: any ) => {
    let queryParams = `?action=${params.action}`;
    const data = await postMethod(ApiEndPoint.fleetManageData, queryParams, body ,false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const checkGroupExist = async (params: any, mock = false) => {
    let queryParams =  `?locationId=${params.locationId}&fleetGroup=${params.fleetGroup}`;
    const result = await getMethod(
        ApiEndPoint.fleetCheckGroupExist,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return result;
};

export const fetchZoneList = async (params: any, mock = false) => {
    let queryParams = `?locationId=${params.locationId}`;
    const data = await getMethod(
        ApiEndPoint.fleetGetZones,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchLocationList = async (params: any, mock = false) => {
    let queryParams = `?hostId=${params.hostId}`;
    const data = await getMethod(
        ApiEndPoint.fleetGetLocations,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};


export const fetchGroupInfo = async (params: any, mock = false) => {
    let queryParams =  `?rowId=${params.groupId}`;
    const data = await getMethod(
        ApiEndPoint.fleetGetGroupsList,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchVehicleInfo = async (params: any, mock = false, downloadCsv = false, vehicleId = false, isDelegate = false) => {
    let queryParams = "";
    if (vehicleId) {
        queryParams = `?rowId=${params.vehicleId}`;
    } else if (isDelegate && (params?.groupId === "" || params?.groupId === undefined)) {
        queryParams = downloadCsv ? `?delegateId=${params.delegateId}&csvDownload=true` : `?page=${params.currentPage}&size=${params.size}&delegateId=${params.delegateId}`;
    } else if (params?.groupId) {
        queryParams = downloadCsv ? `?groupId=${params.groupId}&csvDownload=true` : `?page=${params.currentPage}&size=${params.size}&groupId=${params.groupId}`;
    } else {
        queryParams = downloadCsv ? `?hostId=${params.hostId}&csvDownload=true` : `?page=${params.currentPage}&size=${params.size}&hostId=${params.hostId}`;
    }
    const data = await getMethod(ApiEndPoint.fleetGetVehicles, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchFleetConstrainsData = async (params: any, mock = false) => {
    let queryParams = `?group_id=${params.groupId}`;
    queryParams = params.hasOwnProperty('vehicleId') ? queryParams + `&vehicle_id=${params.vehicleId}`: queryParams;
    const data = await getMethod(ApiEndPoint.fleetConstraintsDetails, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchFleetAvailableStation = async (params: any, cancelRequest= false, mock = false) => {
    let queryParams = `?group_id=${params.groupId}&host_id=${params.hostId}`;
    queryParams = params.hasOwnProperty('vehicleId') ? queryParams + `&vehicle_id=${params.vehicleId}`: queryParams;
    const data = await getMethod(ApiEndPoint.fleetAvailableStationList, queryParams, mock, cancelRequest, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchFleetAssignedStation = async (params: any, cancelRequest= false, mock = false) => {
    let queryParams = `?group_id=${params.groupId}`;
    queryParams = params.hasOwnProperty('vehicleId') ? queryParams + `&vehicle_id=${params.vehicleId}`: queryParams;
    queryParams = params.hasOwnProperty('page') ? queryParams + `&page=${params.page}`: queryParams;
    queryParams = params.hasOwnProperty('size') ? queryParams + `&size=${params.size}`: queryParams;
    const data = await getMethod(ApiEndPoint.fleetAssignedStationList, queryParams, mock, cancelRequest, true)
        .then((res) => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchAlertInfo = async (params: any, mock = false) => {

    let queryParams = `?group_id=${params.groupId}`;
    queryParams =params.hasOwnProperty('vehicleId') ? queryParams + `&vehicle_id=${params.vehicleId}`: queryParams;
    const data = await getMethod(ApiEndPoint.fleetGetGroupAlert, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchFleetVehicleAuth = async (params: any, mock = false) => {
    let queryParams = `?page=${params.currentPage}&size=${params.size}&vehicleId=${params.vehicleId}`;
    const data = await getMethod(ApiEndPoint.fleetVehicleAuth, queryParams, mock, false, true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const activateCard = async (data: any) => {
    let result = await postMethod(ApiEndPoint.assignPass,'',data,false,true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error(err);
        });
    return result;
};
export const fetchGroupsList = async (params: any, mock = false) => {
    let queryParams = params.isDelegate ? `?delegateId=${params.delegateId}&isDelegate=${params.isDelegate}` : `?hostId=${params.host_id}`;
    const data = await getMethod(
        ApiEndPoint.fleetGetGroupsList,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const fetchVinInfo = async (params: any, mock = false) => {
    let queryParams =  `?vin=${params.vin}`;
    const data = await getMethod(
        ApiEndPoint.fleetGetFromVin,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};

export const deactivateCard = async (params: any, data: any) => {
    let qParams = `?pass=${params.pass}`;
    let result = await postMethod(ApiEndPoint.fleetDeactivatePass,qParams,data,false,true)
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error(err);
        });
    return result;
};
export const fetchDelegates = async (params: any, mock = false) => {
    let queryParams =  `?fleetId=${params.groupId}`;
    const data = await getMethod(
        ApiEndPoint.fleetGetDelegatesList,
        queryParams,
        mock,
        false,
        true
    )
        .then((res) => {
            if (res && res.data) {
                return res.data;
            }
        })
        .catch((err) => {
            console.error("Error", err);
        });
    return data;
};