import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { MenuItem, Button, Dialog } from "@material-ui/core";
import { putMethod, ApiEndPoint } from "services";
import Select from "@material-ui/core/Select";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import SimpleReactValidator from "simple-react-validator";
import { EditStationInfo, Station } from "common/interfaces";
import { useTranslation } from "react-i18next";
import { urlReplace, isStationEmpty, rolePermissionCheck, defaultVariable, keyCloakConfig } from "common";
import { restrictedLocation, stationAcess , localauthorization} from "data/stationDropdown";


const EditDepotStationInformation = (props: EditStationInfo) => {

    
    const { t } = useTranslation();
    const [stationDetails, setStationDetails] = useState<any>({});
    const [, forceUpdate] = useState<any>();
    const simpleValidator = useRef(new SimpleReactValidator());
    const isDelegate = rolePermissionCheck( keyCloakConfig?.realmAccess?.roles, [defaultVariable.ROLES.delegate]);

    useEffect(() => {
        const stationDetail = props?.details;
        let initialValue: Station = {
            name: stationDetail?.name,
            isPublic: props?.isPublic,
            isRestricted:stationDetail?.isRestricted,
            isLocalAuthorizationEnabled:stationDetail?.isLocalAuthorizationEnabled
        };
        setStationDetails(initialValue)
    }, [props?.details]);
        

    const submitEditForm = () => {
        if (simpleValidator.current.allValid()) {
            const url = urlReplace(ApiEndPoint.editStationInformation, "{STATION-ID}", props?.stationId);
            putMethod(url, '', stationDetails)
                .then((res) => {
                    setStationDetails(res.data);
                    props?.onClose();
                    props?.getDepotStationData();
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };
    
    const showErrors = () =>{
        simpleValidator.current.showMessages();
        forceUpdate(1);
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setStationDetails({ ...stationDetails, [name]: value });
        if(value === ''){
            showErrors();
        }
    };
 
    return (
        <>
            <div className="dialog_box" data-testid="edit_depot_station_info">
                <Dialog
                    className="dialog_container edit_detail_section edit-user-info updated_pop_up sm_450 dialog_container_center"
                    open={props?.openStationDetail}
                    scroll={"paper"}
                    onClose={props?.onClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <div className="dialog_title">
                    <h4>{t("editstation.stationInfo")}</h4>
                        <div className="button_row mt-0 full_right">
                            <Button
                                className="btn_white text_btn_theme"
                                onClick={props?.onClose}
                            >
                                {t("common.cancelbutton")}
                            </Button>
                            <Button className="btn_white blue_bg"
                                onClick={submitEditForm}
                            >
                                {t("common.station.update")}
                            </Button>
                        </div>
                    </div>
                    <DialogContent>
                        <DialogContentText id="scroll-dialog-description">
                            <div className="defult_form mt-30">
                                <div className="section_group">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={6}>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <label>
                                                        {t("common.stationaccess")}
                                                        </label>
                                                        <Select
                                                        labelId="demo-simple-select-label"
                                                        variant="outlined"
                                                        onChange={handleChange}                                                  
                                                        name="isPublic"
                                                        className={`disable`}
                                                        disabled={true}
                                                        value={stationDetails?.isPublic ?? true}
                                                    >
                                                        {stationAcess &&
                                                                    stationAcess.map(
                                                                        (value: any) => (
                                                        <MenuItem
                                                            className="defult_select_option"
                                                            key={value?.id}
                                                            value={value?.value}
                                                        >
                                                            {value?.access}
                                                        </MenuItem>))}
                                                    </Select>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <label>
                                                        {t("common.restrict")}
                                                        </label>
                                                        <Select
                                                        labelId="demo-simple-select-label"
                                                        variant="outlined"
                                                        onChange={handleChange}                                                  
                                                        name="isRestricted"
                                                        value={stationDetails?.isRestricted ?? true}
                                                    >
                                                        {restrictedLocation &&
                                                                    restrictedLocation.map(
                                                                        (value: any) => (
                                                        <MenuItem
                                                            className="defult_select_option"
                                                            key={value?.id}
                                                            value={value?.value}
                                                        >
                                                            {value?.label}
                                                        </MenuItem>))}
                                                    </Select>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {!isDelegate &&
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <label>
                                                        {t("usps.localauthorization")}
                                                        </label>
                                                        <Select
                                                        labelId="demo-simple-select-label"
                                                        variant="outlined"
                                                        onChange={handleChange}                                                  
                                                        name="isLocalAuthorizationEnabled"
                                                        value={stationDetails?.isLocalAuthorizationEnabled ?? true}
                                                    >
                                                        {localauthorization &&
                                                                    localauthorization.map(
                                                                        (localAuthorizationValue: any) => (
                                                        <MenuItem
                                                            className="defult_select_option"
                                                            key={localAuthorizationValue?.id}
                                                            value={localAuthorizationValue?.value}
                                                        >
                                                            {localAuthorizationValue?.label}
                                                        </MenuItem>))}
                                                    </Select>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            }
                                            <div className="form_col">
                                            <Grid
                                                className="single_line"
                                                container
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={0}
                                            >
                                                <Grid item xs={12}>
                                                    <label>
                                                        {t("common.stationname")}
                                                    </label>
                                                    <TextField
                                                        variant="outlined"
                                                        name="name"
                                                        defaultValue={ isStationEmpty(stationDetails?.name, stationDetails?.serialNumber )}
                                                        onChange={handleChange}>
                                                            {stationDetails.name}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};

export default EditDepotStationInformation;
