import React, { useState, useEffect, useRef } from "react";
import {
    MenuItem,
    Grid,
    TextField,
    Button,
    DialogContent,
    DialogContentText,
    Select,
    CircularProgress,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {
    DelegatesFormType,
} from "common/interfaces/delegatesInterface";
import SimpleReactValidator from "simple-react-validator";
import { postMethod, ApiEndPoint } from "services";
import { useTranslation } from "react-i18next";
import { defaultVariable, handleErrorOnBlur } from "common";
import { capitalize } from "lodash";
import { fetchDelegatesDetails, fetchDelegateTypesData } from "services/delegateService";

const DelegatesForm = (props: DelegatesFormType) => {
    let initialValue = {
        firstName: "",
        lastName: "",
        email: "",
        delegateType: ""
    };

    const scroll = "paper";
    const [delegatesDetails, setDelegatesDetails] = useState(initialValue);
    const [blockEditNames, setBlockEditNames] = useState(true);
    const [alreadyExist, setAlreadyExist] = useState<string>("");
    const [serverError, setServerError] = useState<string>("");
    const [, forceUpdate] = useState<any>();
    const { t } = useTranslation();
    const simpleValidator = useRef(new SimpleReactValidator({
        messages: {
            email: t("validation.email"),
        }
    }));
    const [delegateAlreadyExist, setDelegateAlreadyExist] = useState<string>("");
    const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true);

    const clearDelegateDetails = () => {
        setAlreadyExist("")
    }
    

    const clearErrorMessages = () => {
        setServerError('');
        setAlreadyExist('');
        setDelegateAlreadyExist('');
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;

        setDelegatesDetails({ ...delegatesDetails, [name]: value === undefined ? '' : value });

        // resetting already exist email error message to blank
        if (name === defaultVariable.fieldName.email) {
            clearErrorMessages();
        }
        setServerError('');
        setDelegateAlreadyExist('');
        setDelegatesDetails((prevState) => {
            if (prevState.firstName !== "" && prevState.lastName !== "" && prevState.email !== "" && prevState.delegateType !== "") {
                setInviteButtonDisabled(false);
            }else {
                setInviteButtonDisabled(true);
            }
            return prevState
        })
    };
    const searchEmailforDeleagte = async () => {
        clearDelegateDetails();
        clearErrorMessages();
        if (simpleValidator.current.fieldValid("email")) {
            await fetchDelegatesDetails(delegatesDetails.email)
                .then((res) => {
                    switch (res.status) {
                        case 400:

                            break;

                        case 200:
                            if (res.data?.totalCount !== 0) {
                                setInviteButtonDisabled(false);
                                const repDelegateDetails = res.data?.users[0];
                                setDelegatesDetails({
                                    ...delegatesDetails,
                                    firstName: repDelegateDetails.firstName,
                                    lastName: repDelegateDetails.lastName,
                                    delegateType: repDelegateDetails.delegateType
                                });

                                setDelegateAlreadyExist(t("delegates.alreadyexits"));
                                setBlockEditNames(true);
                            }
                            else {
                                setInviteButtonDisabled(true);
                                setDelegatesDetails({ ...delegatesDetails,
                                                    firstName:"",
                                                    lastName:"",
                                                    delegateType: ""
                                                    });
                                setDelegateAlreadyExist(t("delegates.createnewone"));
                                setBlockEditNames(false);
                            }

                            break;
                    }

                })
                .catch((err) => {
                    console.error("Error", err);
                })

        } else {
            simpleValidator.current.showMessageFor("email")
            forceUpdate(1);
        }
    };

    const [delegateTypeList, setDelegateTypeList] = useState<any>([]);

    const getDelegateTypesData = async () => {
        await fetchDelegateTypesData().then((res) => {
                if (res && res?.data) {
                    setDelegateTypeList(res?.data);
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    useEffect(() => {
        getDelegateTypesData();
    }, [])

    const removeEmpty = (obj: any) => {
        let newObj: any = {};
        Object.keys(obj).forEach((key) => {
          if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
          else if (obj[key] !== undefined) newObj[key] = obj[key];
        });
        return newObj;
      };

    const submitDelegatesForm = () => {
        setDelegateAlreadyExist("");
        clearErrorMessages();
                
        let paramObj = removeEmpty(delegatesDetails);

        if (simpleValidator.current.allValid()) {
            postMethod(ApiEndPoint.newDelegates, '', paramObj)
                .then((res) => {
                    switch (res.status) {
                        case 400:
                            setServerError(res.data.message);
                            break;
                        case 409:
                            setAlreadyExist(res.data.message);
                            break;
                        case 201:
                            props?.delegateDetailclose();
                            props?.getDelegatesData();
                            break;
                        case 200:
                            console.error("success only, no save");
                            props?.delegateDetailclose();
                            break;
                        default:
                            console.error("something went wrong", res.status);
                    }
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const validateError = (fieldName: string) => {
        if (
            !simpleValidator.current.fields[fieldName] &&
            simpleValidator.current.messagesShown
        ) {
            return "error-show";
        } else {
            return "";
        }
    };

    return (
        <>
          <div data-testid="delegates_form">
            <div className="dialog_title">
                <h4>{t("newDelegate")}</h4>
                <div className="button_row mt-0 full_right">
                    <Button
                        className="btn_white text_btn_theme"
                        onClick={props?.delegateDetailclose}
                    >
                        {t("common.cancelbutton")}
                    </Button>
                    <Button
                        onClick={submitDelegatesForm}
                        className={`btn_white blue_bg ${inviteButtonDisabled ? 'disable' : ''}`}
                        disabled={inviteButtonDisabled}
                    >
                        {t("common.invitebutton")}
                    </Button>
                </div>
            </div>
            <DialogContent dividers={scroll === "paper"} {...props}>
                <DialogContentText id="scroll-dialog-description">
                    {props?.loading ? <CircularProgress /> :
                        <div className="defult_form">
                            <div className="section_group">
                                <div className="delegate_text">
                                    {t("delegateEmailInfo")}
                                </div>
                                <h6>{t("common.required")}{t("common.emailisrequired")}</h6>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <div className={`form_col-12 ${validateError("email")}`} >
                                            <TextField
                                                label=""
                                                variant="outlined"
                                                name="email"
                                                onChange={handleChange}
                                                placeholder={t("common.email")}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" className="searchicon_inputfield">
                                                        <IconButton onClick={searchEmailforDeleagte} data-testid="delegates_form_email_search_icon_button">
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>,

                                                }}
                                                onBlur={(e) => handleErrorOnBlur({ e: e, validator: simpleValidator, fieldName: 'email' })}
                                            />
                                            <cite className="cite_error" data-testid="delegates_form_email_cite_error">
                                                {simpleValidator.current.message(
                                                    "email",
                                                    delegatesDetails?.email,
                                                    "required|email"
                                                )}
                                            </cite>
                                            {alreadyExist !== "" || serverError !== "" ? <cite className="error_msg3">{alreadyExist} {serverError}</cite> 
                                            : <cite className="cite-success">{delegateAlreadyExist}</cite>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(!blockEditNames && (delegatesDetails.firstName === "" || delegatesDetails.lastName === "")) && <h6>{t("common.required")}{t("delegate.firstandlastnamerequired")}</h6>}
                                        <div className={`form_col-12 ${validateError("firstName")}`} >
                                            <TextField
                                                className={blockEditNames ? 'disable' : ''}
                                                label=""
                                                variant="outlined"
                                                name="firstName"
                                                value={delegatesDetails.firstName}
                                                onChange={handleChange}
                                                placeholder={t("common.firstname")}
                                                disabled={blockEditNames}
                                            />

                                            <cite className="cite_error">
                                                {simpleValidator.current.message(
                                                    "firstName",
                                                    delegatesDetails?.firstName,
                                                    "required"
                                                )}
                                            </cite>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <div className={`form_col-12 ${validateError("lastName")}`} >
                                            <TextField
                                                    className={blockEditNames ? 'disable' : ''}
                                                    label=""
                                                    variant="outlined"
                                                    name="lastName"
                                                    value={delegatesDetails.lastName}
                                                    onChange={handleChange}
                                                    placeholder={t("common.lastname")}
                                                    disabled={blockEditNames}
                                                />

                                            <cite className="cite_error">
                                                {simpleValidator.current.message(
                                                    "lastName",
                                                    delegatesDetails?.lastName,
                                                    "required"
                                                )}
                                            </cite>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <div className={`form_col-12 ${validateError("delegateType")}`} >
                                        <Grid
                                            className="single_line"
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                                <label
                                                    className="text-bold"
                                                > {t("common.delegateType")}</label>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    variant="outlined"
                                                    name="delegateType"
                                                    onChange={handleChange}
                                                    className={blockEditNames ? 'disable' : ''}
                                                    value={delegatesDetails.delegateType || "None"}
                                                    disabled={blockEditNames}
                                                >
                                                    <MenuItem disabled value='None'>{t('common.selectDelegate')}</MenuItem>
                                                    {delegateTypeList && delegateTypeList.length >= 1 &&
                                                        delegateTypeList.map((value: any, i: number) => (
                                                            <MenuItem className="defult_select_option"
                                                            key={value} value={value} >{capitalize(value)}</MenuItem>
                                                        ))}
                                                </Select>
                                                <cite className="cite_error">
                                                    {simpleValidator.current.message( "delegateType", delegatesDetails?.delegateType || "None", "required" )}
                                                </cite>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                </DialogContentText>
            </DialogContent>
          </div>
        </>
    );
};

export default DelegatesForm;
