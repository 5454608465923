import React from "react";
import {
    Button,
    LinearProgress,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Dialog
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { setClassName } from "../pricingPlanHelpers";

const AddPricingPlanStepOne = (props: any) => {

    const { t } = useTranslation();

    const addFirstPopupClose = () => {
        props.createFirstPopupClose();
    }

    const navigateToAddSecondStep = () => {
        props.navgateToPricingPlanSecondStep();
    }

    return (
        <div className="dialog_box">
            <Dialog
                className="dialog_container edit_detail_section edit-user-info sm dialog_container_center"
                {...props}
                open={props.addPricingOpen}
                onClose={props.createFirstPopupClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="dialog_title">
                    <h4>{props.firstPopupName}</h4>
                    <div className="button_row mt-0 full_right">
                        <Button
                            className="btn_white text_btn_theme"
                            onClick={addFirstPopupClose}
                            data-testId="cancel_btn"
                        >
                            {t("common.cancelbutton")}
                        </Button>

                        <Button className={`btn_white ${setClassName(props.spinnerOff, props.disableNextButton)}`}
                            onClick={navigateToAddSecondStep}
                            data-testId="next_btn"
                            disabled={props.spinnerOff || (!props.spinnerOff && props.disableNextButton)}>
                            {t("common.nextbutton")}
                        </Button>
                    </div>
                </div>
                {props.spinnerOff && <LinearProgress />}
                <DialogContent>
                    <DialogContentText id="scroll-dialog-description">
                        <div className="information_section delegates_details">
                            <div className="defult_form mt-25">
                                <div className="section_group">
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item xs={12}>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label className="w_100per full_w">
                                                            {t("pricing.name")} {t("common.required")}
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`form_col ${props.validateError(
                                                                t("pricing.name")
                                                            )}`}
                                                        >
                                                            <TextField
                                                                className="w_100per full_w"
                                                                variant="outlined"
                                                                name="name"
                                                                inputProps={{ "data-testid": "pricingName" }}
                                                                onChange={(e) => props.handleChange(e, false)}
                                                                value={
                                                                    props.pricingDetails?.name
                                                                }
                                                                placeholder={t("pricing.name")}
                                                            >
                                                            </TextField>
                                                            {props.nameExists ? <cite className="srv-validation-message">
                                                                <div>{t("pricing.tariffnamealreadyexists")}</div>
                                                            </cite> : <cite>
                                                                {props.simpleValidator.current.message(
                                                                    t("pricing.name"),
                                                                    props.pricingDetails?.name,
                                                                    "required"
                                                                )}
                                                            </cite>}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form_col">
                                                <Grid
                                                    className="single_line textarea"
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={0}
                                                >
                                                    <Grid item xs={6}>
                                                        <label>{t("pricing.description")}</label>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            className="w_100per full_w"
                                                            multiline
                                                            minRows={4}
                                                            variant="outlined"
                                                            inputProps={{ "data-testid": "pricingDesc" }}
                                                            onChange={(e) => props.handleChange(e, false)}
                                                            name="description"
                                                            value={props.pricingDetails?.description}
                                                            placeholder={t("pricing.description")}
                                                        >
                                                            { }
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {((!props.spinnerOff && props.editPopup && props.pricingDetails?.ctep) || (!props.editPopup && props.pricingDetails?.ctep)) &&
                                                <div>
                                                    <Grid
                                                        className="single_line textarea"
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={6} className="form_col">
                                                            <label>{t("pricing.ctepenabled")}</label>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <div className="form_col radio_button">
                                                                <RadioGroup
                                                                    aria-label="position"
                                                                    name="position"
                                                                    defaultValue="top"
                                                                    value={props.ctepValue}
                                                                    onChange={props.onChangeCtepValue}
                                                                    row
                                                                >
                                                                    <FormControlLabel
                                                                        value="ctepEnabled"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        label={t("common.yes")}
                                                                    />
                                                                    <FormControlLabel
                                                                        value="ctepNotEnabled"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        label={t("pricing.no")}
                                                                    />
                                                                </RadioGroup>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddPricingPlanStepOne;