import {ObjectDetails} from "../../../../common/interfaces";
import {useState} from "react";
import {Checkbox, CircularProgress} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {showErrorMessages} from "../../../../redux/actions/errorMessages";
import {useDispatch} from "react-redux";
import {manageFleetData} from "../../../../services/fleetManagementService";

const FleetAutoAuthorize = (props: ObjectDetails) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let [autoAuthorize, setAutoAuthorize] = useState(props?.row?.power_loss_restart);
    let [showLoader, setShowLoader] = useState(false);

    const handleChange = (event: any) => {
        updateAutoAuthorize(event.target.checked);
    };

    const updateAutoAuthorize =  (value: any) => {
        setShowLoader(true);
        let body: ObjectDetails = {id: props?.row?.id, power_loss_restart: value};
        let params = { action: 'changePowerLossAutoTrans'};
        manageFleetData(params, body).then((res: any) => {
            if (!res.status) {
                setShowLoader(false);
                dispatch(
                    showErrorMessages({
                        error: true,
                        message: res.message,
                        type: "error",
                        operation: "",
                    })
                );
            } else {
                setAutoAuthorize(value);
                setShowLoader(false);
            }
        }, (error) => {
            setShowLoader(false);
            dispatch(
                showErrorMessages({
                    error: true,
                    message: error.message,
                    type: "error",
                    operation: "",
                })
            );
        });
    };

    return (
        <div className="defult_form">
            <div className="form_col checkbox mt-10">
                {props?.showChargerUpdateButton ?
                    (showLoader ? <CircularProgress className="small_loader"/> : <Checkbox  checked={autoAuthorize} color="primary"  onChange={handleChange}/>)
                    :
                    (autoAuthorize ? t('common.yes') : t('common.no'))
                }
            </div>
        </div>
    );
};


export default FleetAutoAuthorize;