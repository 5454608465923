import { ApiEndPoint, postMethod } from "services";
import {defaultVariable} from "../../common";
const LOGIN_TIME = "LOGIN_TIME";
const MENU_ITEM_MANAGE_USERS = 'MENU_ITEM_MANAGE_USERS';

export const fetchLoginTime = (date: any) => {
    if(!date) {
        return async (dispatch: any) => {
            await postMethod(ApiEndPoint.loginTime, '', {})
            .then((res: any) => {
                if(res.status === 200) {
                    localStorage.setItem('routingDisabled',JSON.stringify(res?.data?.manageUsersEnabled));
                    if(res?.data?.role === defaultVariable.ROLES.delegate) {
                        sessionStorage.setItem('delegateId', res?.data?.id);
                    }
                    dispatch({
                        type: LOGIN_TIME,
                        payload: new Date()
                    });
                    dispatch({
                        type: MENU_ITEM_MANAGE_USERS,
                        payload: res?.data?.manageUsersEnabled
                    });                    
                }
            }).catch((err) => {
                console.log(err)
                dispatch({
                    type: LOGIN_TIME,
                    payload: ''
                });
            })
        };
    } else {
        return {
            type: LOGIN_TIME,
            payload: ''
        }
    }
};