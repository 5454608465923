import React, { useEffect, useState } from "react";
import "./polygonMap.scss";
import { useTranslation } from "react-i18next";
import { GoogleMap, Polygon, DrawingManager, Marker } from "@react-google-maps/api";
import { defaultVariable } from "common";
import Images from "assets/images";

const PolygonMap = (props: any) => {
    const { t } = useTranslation();

    const [coordinates, setCoordinates] = useState<any>([]);
    const [state, setState] = useState<any>(false);
    // const [overlay, setOverlay] = useState<any>();
    const [path, setPath] = useState<any>();
    const [center, setCenter] = useState<any>(defaultVariable.DEFAULT_CENTER);
    const [defaultMapOptions, setDefaultMapOptions] = useState<any>();
    
     
    const mapContainerStyle = {
        height: "400px",
        width: "100%",
    };

    const eveseLocationMapStyle = {
        height: "226px",
        width: "100%",

    }

    useEffect(() => {
        if (props?.pathCoord?.length > 0) {
            setCenter(props.pathCoord[0])   
            setPath(props?.pathCoord)
        } else {
            setCenter(props.center ?? defaultVariable.DEFAULT_CENTER)
        }
    }, [props.pathCoord])



    const drawShape = () => {
        setState(true);
        setPath([])
    };

    const deleteSelectedShape = () => {
        props.clearPolygone('clear')
        setCoordinates([]);
        setPath([])
    };

    const options = {
        fillColor: "$light-grayo1",
        fillOpacity: 0.5,
        strokeColor: "$dark-gray",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
    };


    const onPolygonComplete = (polygon: any) => {
        const polygonBounds = polygon.getPath();
        let updateCoordinates = [...coordinates];
        for (let i = 0; i < polygonBounds.length; i++) {
            updateCoordinates.push({
                lat: polygonBounds.getAt(i).lat(),
                lng: polygonBounds.getAt(i).lng(),
            });
            setCoordinates(updateCoordinates);
        }
        props.selectedCoordinates(coordinates);
    };
    const handleOverlayComplete = (e: any) => {
        setState(false);
    };

    useEffect(() => {
        if(props.mapsize){
            const smallMapOptions = {
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
                streetViewControl: false,
                controlSize:25,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.SATELLITE,
                        google.maps.MapTypeId.HYBRID
                    ]
                },    
            }; 
            setDefaultMapOptions(smallMapOptions)
        } else {
            const fullMapOptions = {
                mapTypeControl: true,
                fullscreenControl: true,
                zoomControl: true,
                streetViewControl: false,
                controlSize:25,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.SATELLITE,
                        google.maps.MapTypeId.HYBRID
                    ]
                },    
            }; 
            setDefaultMapOptions(fullMapOptions)
        }
    }, [props.mapsize]) 
    
    return (
        <>
            <div className="capture_zone" data-testid="polygon_map_component">
                <div className="polygonMap">
                    {!props.mapsize &&
                        <>
                            <button className="polygonMapBtn draw" onClick={drawShape}>
                                {t("common.draw")}
                            </button>
                            <button
                                className="polygonMapBtn clear"
                                onClick={deleteSelectedShape}
                            >
                                {t("common.clear")} 
                            </button>
                        </>
                    }
                    <GoogleMap
                        mapContainerStyle={props.mapsize ? eveseLocationMapStyle : mapContainerStyle}
                        center={center}
                        zoom={19}
                        options={defaultMapOptions}
                    > 
                        <Marker
                            position={center}
                            icon={{ url: Images.MAP_MARKER,anchor: new google.maps.Point(15, 20)}}
                        />
                        <Polygon
                            options={options}
                            paths={path}
                        />
                        <DrawingManager
                            onPolygonComplete={onPolygonComplete}
                            onOverlayComplete={handleOverlayComplete}
                            options={{
                                drawingControl: state,
                                drawingControlOptions: {
                                    position:
                                        google.maps.ControlPosition.TOP_CENTER,
                                    drawingModes: [
                                        google.maps.drawing.OverlayType.POLYGON,
                                    ],
                                },
                                polygonOptions: { editable: true },
                            }}
                        />
                        <></>
                    </GoogleMap>
                </div>
            </div>
        </>
    );
};
export default PolygonMap;
