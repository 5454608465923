import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {ContactDetails} from "common/interfaces";
import { LabelInfo } from "common";
import { useTranslation } from "react-i18next";
const ContactForm = (props: ContactDetails) => {
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    props.setContact({ ...props?.contactDetails, [name]: value });
    if(value === ''){
      props.showErrors();
    }
  };

  useEffect(()=>{
    props.showErrors();
  },[props.validator.current])

  return (
    <>
      <div className="section_group" data-testid="contact_form_component">
        <h3>{props.title}</h3>
        <Grid className="dobule_line" container justifyContent="center" alignItems="flex-start" spacing={0} >
          <Grid className="p_t_0" container justifyContent="center" alignItems="center" spacing={3}>
            <Grid container xs={12} spacing={3} justifyContent="center" alignItems="center" >
              <Grid item xs={6} spacing={0}>
                <div className={`form_col ${ props?.validateError("firstName")}`} >
                  <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                        <LabelInfo name= {t("common.firstname")} isMandatory={true}/>
                        <TextField
                          variant="outlined"
                          value={props?.contactDetails?.firstName}
                          name="firstName"
                          onChange={handleChange}
                          className="error"
                          onBlur={()=>props?.validator?.current.showMessageFor('firstName')}
                          inputProps={{ maxLength: 140 }}
                        />
                        <span className="error_msg">{props?.validator?.current.message('firstName', props?.contactDetails?.firstName, 'required')}</span>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}spacing={0} >
                <div className={`form_col ${ props?.validateError("lastName")}`} >
                  <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                        <LabelInfo name= {t("common.lastname")} isMandatory={true}/>
                        <TextField
                          variant="outlined"
                          value={props?.contactDetails?.lastName}
                          name="lastName"
                          onChange={handleChange}
                          className="error"
                          onBlur={()=>props?.validator?.current.showMessageFor('lastName')}
                          inputProps={{ maxLength: 140 }}
                        />
                        <span className="error_msg">{props?.validator?.current.message('lastName', props?.contactDetails?.lastName, 'required')}</span>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} spacing={0}>
              <div className={`form_col ${ props?.validateError("email")}`} >
                <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0} > 
                      <LabelInfo name={t("common.contacts.email")} isMandatory={true}/>
                      <TextField
                        variant="outlined"
                        value={props?.contactDetails?.email}
                        name="email"
                        onChange={handleChange}
                        className="error"
                        onBlur={()=>props?.validator.current.showMessageFor('email')}
                        inputProps={{ maxLength: 120 }}
                      />
                      <span className="error_msg">{props?.validator.current.message('email', props?.contactDetails?.email, 'required|email')}</span>
                </Grid>
            </div>
            </Grid>
            <Grid item xs={12} spacing={0}>
            <div className={`form_col  ${ props?.validateError("mobile")}`}>
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0}>
                  <LabelInfo name={t("common.contacts.mobile")} isMandatory={true}/>
                  <TextField
                    variant="outlined"
                    value={props?.contactDetails?.mobile}
                    name="mobile"
                    onChange={handleChange}
                    onBlur={()=>props?.validator.current.showMessageFor('mobile')}
                    className="error"
                    inputProps={{ maxLength: 15 }}
                  />
                  <span className="error_msg">{props?.validator?.current.message('mobile', props.contactDetails?.mobile, 'required|phone')}</span>
              </Grid>
            </div>
            </Grid>
            <Grid item xs={12} spacing={0}>
            <div className={`form_col ${ props?.validateError("phone")}`} >    
            <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0} >
                  <LabelInfo name={t("common.contacts.telephonefullform")} isMandatory={true}/>
                  <TextField
                    variant="outlined"
                    value={props?.contactDetails?.phone}
                    name="phone"
                    onChange={handleChange}
                    className="error"
                    onBlur={()=>props?.validator.current.showMessageFor('phone')}
                    inputProps={{ maxLength: 15 }}
                  />
                  <span className="error_msg">{props?.validator?.current.message('phone', props.contactDetails?.phone, 'required|phone')}</span>
              </Grid>
            </div>
            </Grid>
            <Grid item xs={12} spacing={0}>
            <div className="form_col">
              <Grid className="single_line" container justifyContent="flex-start" alignItems="center" spacing={0} >
                  <LabelInfo name={t("common.contacts.fax")} isMandatory={false}/>
                  <TextField
                    variant="outlined"
                    value={props?.contactDetails?.fax}
                    name="fax"
                    onChange={handleChange}
                    className="error"
                    inputProps={{ maxLength: 15 }}
                  />
              </Grid>
            </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ContactForm;
